import { LoadingOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Divider,
  Modal,
  Popover,
  Progress,
  Row,
  Spin,
} from "antd";
import {
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { Guard } from "src/components/Guard";
import { IPath } from "src/services/Identity/models";
export type PreviewFileType =
  | "pdf"
  | "doc"
  | "excel"
  | "zip"
  | "any"
  | "screenshot"
  | "xlsx"
  | "docx";
interface IActions {
  download?: IPath;
  edit?: IPath;
  delete?: IPath;
  editWithFile?: IPath;
}
interface IProps {
  referenceNumber?: string;
  title?: string;
  show: boolean;
  onCancel: () => void;
  footer?: ReactNode;
  closable?: boolean;
  width?: number;
  file: boolean | Blob;
  addFileOnChange: (file: Blob | null) => Promise<void>;
  progress?: number | undefined;
  loading?: boolean;
  fileType: PreviewFileType;
  onDownload?: () => void;
  screenShot?: string;
  fileBase64?: string;
  subTitle?: string;
  downloadLoading?: boolean;
  downloadProgress?: number;
  fileName: string;
  children?: ReactNode;
  hasEditButton?: boolean;
  onEdit?: () => void;
  editLoading?: boolean;
  actions: IActions;
}
export const FilePreview: FC<IProps> = ({
  referenceNumber,
  title = "Edit attachment files",
  onCancel,
  show,
  footer,
  closable = false,
  width = 755,
  file,
  addFileOnChange,
  progress,
  loading,
  fileType,
  onDownload,
  screenShot,
  fileBase64,
  subTitle,
  downloadLoading,
  downloadProgress,
  fileName,
  children,
  hasEditButton,
  onEdit,
  editLoading,
  actions,
}) => {
  const [hasFile, setHasFile] = useState<boolean>(!!file);
  useEffect(() => {
    if (!!file) {
      setHasFile(true);
    }
  }, [file]);
  const modalTitle = (
    <Row>
      <Col span={12}>
        <div className="flex justify-start items-center">
          <span className="material-icons text-[#A3AED0]">attach_file</span>
          <span className="text-[#1B2559] !text-[18px]">{title}</span>
        </div>
        {subTitle ? (
          subTitle.length > 36 ? (
            <Popover content={hasFile ? subTitle : "NO NAME"}>
              <div className="text-[#5D6881] !text-[12px] cursor-default ps-[24px]">
                {hasFile ? subTitle : "NO NAME".substring(0, 33).concat("...")}
              </div>
            </Popover>
          ) : (
            <div className="text-[#5D6881] !text-[12px] ps-[24px]">
              {hasFile ? subTitle : "NO NAME"}
            </div>
          )
        ) : null}
      </Col>
      <Col span={12} className="flex justify-end items-center">
        <span>{referenceNumber}</span>
      </Col>
      <Divider />
    </Row>
  );
  return (
    <Modal
      open={show}
      onCancel={onCancel}
      footer={
        footer || (
          <ModalFooter
            hasFile={hasFile}
            setHasFile={setHasFile}
            onCancel={onCancel}
            addFileOnChange={addFileOnChange}
            onDownload={onDownload}
            fileBase64={fileBase64}
            referenceNumber={referenceNumber}
            downloadLoading={downloadLoading}
            downloadProgress={downloadProgress}
            hasEditButton={hasEditButton}
            onEdit={onEdit}
            editLoading={editLoading}
            actions={actions}
          />
        )
      }
      title={modalTitle}
      closable={closable}
      width={width}
      maskClosable={closable}
    >
      <div className="bg-[#A3AED0] flex justify-center items-center p-[24px] rounded-[12px]">
        <div className="bg-[#F4F7FE] w-full h-[234px] rounded-[8px] flex flex-col justify-center items-center gap-[24px]">
          {loading || progress ? (
            <div className="flex flex-col items-center justify-center gap-[24px]">
              {loading ? <Spin size="large" /> : null}
              {progress ? (
                <div className="w-[200px]">
                  <Progress percent={progress} />
                </div>
              ) : null}
            </div>
          ) : hasFile ? (
            screenShot ? (
              <img src={screenShot} alt={fileType} />
            ) : (
              <img
                src={`/assets/images/filePreviewIcon/${fileType}.svg`}
                width={80}
                height={80}
                alt={fileType}
              />
            )
          ) : (
            <>
              <img
                src={`/assets/images/filePreviewIcon/${fileType}IsEmpty.svg`}
                alt={`${fileType}IsEmpty`}
              />
              <div className="text-[#5D6881] text-[24px]">
                Please attach new file
              </div>
            </>
          )}
          {hasFile && (
            <div className="text-center text-[14px] text-[#5D6881]">
              {fileName}
            </div>
          )}
        </div>
      </div>
      {children}
    </Modal>
  );
};
interface IModalFooterProps {
  hasFile: boolean;
  setHasFile: Dispatch<SetStateAction<boolean>>;
  onCancel: () => void;
  addFileOnChange: (file: Blob | null) => Promise<void>;
  onDownload?: () => void;
  fileBase64?: string;
  referenceNumber?: string;
  downloadLoading?: boolean;
  downloadProgress?: number;
  hasEditButton?: boolean;
  onEdit?: () => void;
  editLoading?: boolean;
  actions: IActions;
}

const ModalFooter: FC<IModalFooterProps> = ({
  hasFile,
  setHasFile,
  onCancel,
  addFileOnChange,
  onDownload,
  fileBase64,
  referenceNumber,
  downloadLoading,
  downloadProgress,
  hasEditButton,
  onEdit,
  editLoading,
  actions,
}) => {
  const ref = useRef<HTMLInputElement>(null);
  const loadingIcon = <LoadingOutlined style={{ fontSize: 16 }} spin />;

  return (
    <Row>
      <Col span={12} className="flex justify-start items-center gap-[24px]">
        <input
          type="file"
          style={{ display: "none" }}
          ref={ref}
          onChange={(e) => {
            addFileOnChange(e.target.files ? e.target.files[0] : null).then(
              () => {
                setHasFile(true);
              }
            );
          }}
        />

        <Button
          onClick={() => {
            onCancel();
          }}
        >
          Close
        </Button>
        {hasEditButton && (
          <Guard action={actions.edit}>
            <Button
              type="primary"
              disabled={!hasFile}
              onClick={() => {
                onEdit && onEdit();
              }}
              loading={editLoading}
            >
              Save edit
            </Button>
          </Guard>
        )}
      </Col>
      <Col span={12} className="flex justify-end items-center gap-[24px]">
        {downloadLoading ? (
          <div className="relative flex gap-[8px] items-center">
            <Spin size="default" indicator={loadingIcon} />
            {downloadProgress ? (
              <span className="absolute top-[8px] left-[4px] text-[8px]">
                {downloadProgress}
              </span>
            ) : null}
            <span className="text-[12px] text-[#2C73FC]">Downloading...</span>
          </div>
        ) : fileBase64 && hasFile ? (
          <Guard action={actions.download}>
            <a
              download={referenceNumber}
              href={fileBase64}
              className="flex justify-center items-center border-solid border rounded-[8px] border-[#2C73FC] px-[12px] py-[8px] hover:border-[#95B9FE] transition"
              target="_blank"
              rel="noreferrer"
            >
              <span
                className={`material-icons cursor-pointer mr-[8px]`}
                style={{ color: hasFile ? "#2C73FC" : "#A5C3FE" }}
              >
                browser_updated
              </span>
              Download file
            </a>
          </Guard>
        ) : (
          <Guard action={actions.download}>
            <Button
              onClick={() => {
                hasFile && onDownload && onDownload();
              }}
              type="primary"
              className="flex justify-center items-center px-[12px]"
              ghost
              disabled={!hasFile}
              icon={
                <span
                  className={`material-icons cursor-pointer`}
                  style={{ color: hasFile ? "#2C73FC" : "#A5C3FE" }}
                >
                  browser_updated
                </span>
              }
            >
              Download file
            </Button>
          </Guard>
        )}
        {hasFile ? (
          <Guard action={actions.delete}>
            <Button
              onClick={() => {
                setHasFile(false);
              }}
              danger
              icon={
                <span
                  className={`material-symbols-outlined cursor-pointer`}
                  style={{ color: hasFile ? "#FF1414" : "#FCC6C6" }}
                >
                  delete_forever
                </span>
              }
              className="bg-white flex justify-center items-center px-[12px]"
            >
              Delete file
            </Button>
          </Guard>
        ) : (
          <Guard action={actions.editWithFile}>
            <Button
              type="primary"
              disabled={hasFile}
              onClick={() => {
                ref.current?.click();
              }}
              className="flex justify-center items-center px-[8px]"
              icon={
                <span className={`material-icons cursor-pointer text-[#fff]`}>
                  attach_file
                </span>
              }
            >
              Upload attachment file
            </Button>
          </Guard>
        )}
      </Col>
    </Row>
  );
};
