import { FC } from "react";
import { HeaderShowShip } from "./components/header";
import { Form, Tabs } from "antd";
import { TabsProps } from "antd/lib";
import { IAddNewShip } from "../../models";
import { useAddShip } from "../../context";
import { ShowBasicInformation } from "./components/BasicInformation";
import { ShowTechnicalInformation } from "./components/TechnicalInformation";
import { ShowOwner } from "./components/Owner";
import { ShowInsurances } from "./components/Insurances";
import { ShowShipContainer } from "./style";
import { ShowShipPrice } from "./components/ShipPrice";
import { ShowShipManagement } from "./components/ShipManagement";

export const ShowShip: FC = () => {
  const {
    value: { activeKeyShowShip, tableCurrentValue },
    dispatch: { setActiveKeyShowShip, setIsEditMOde, setActiveKeyEditShip },
  } = useAddShip()!;
  const [form] = Form.useForm();
  const onFinish = async () => {
    try {
    } catch (err) {
      console.log(err);
    } finally {
    }
  };

  const items: TabsProps["items"] = [
    {
      key: IAddNewShip.basicInformation,
      label: "Basic Information",
      children: <ShowBasicInformation />,
      //   disabled: activeKeyAddShip !== IAddNewShip.basicInformation,
    },
    {
      key: IAddNewShip.technicalInformation,
      label: "Technical information",
      children: <ShowTechnicalInformation />,
      //   disabled: activeKeyAddShip !== IAddNewShip.technicalInformation,
    },
    {
      key: IAddNewShip.Owner,
      label: "Owner",
      children: <ShowOwner />,
      //   disabled: activeKeyAddShip !== IAddNewShip.Owner,
    },
    {
      key: IAddNewShip.ShipManagement,
      label: "Ship Management contract",
      children: <ShowShipManagement />,
      //   disabled: activeKeyAddShip !== IAddNewShip.Owner,
    },
    {
      key: IAddNewShip.shipPrice,
      label: "Ship price",
      children: <ShowShipPrice />,
      //   disabled: activeKeyAddShip !== IAddNewShip.Owner,
    },
    {
      key: IAddNewShip.insurances,
      label: "insurances",
      children: <ShowInsurances />,
      //   disabled: activeKeyAddShip !== IAddNewShip.insurances,
    },
    // {
    //   key: IAddNewShip.documentationFile,
    //   label: "Documentation file",
    //   children: <ShowDocumentationFile />,
    //   //   disabled: activeKeyAddShip !== IAddNewShip.documentationFile,
    // },
  ];

  const onChange = (key: string) => {
    setActiveKeyShowShip(key);
  };
  const TopBar = (
    <div className="flex justify-between items-center h-[37px]">
      <div className="flex flex-col">
        <div className="text-[#001377] text-[18px]">
          {tableCurrentValue?.name}
        </div>
        <div className="text-[#5D6881] text-[14px]">
          {tableCurrentValue?.abbreviation}
        </div>
      </div>
      <div>
        <span
          className="material-icons flex items-center cursor-pointer"
          onClick={() => {
            setIsEditMOde(true);
            setActiveKeyEditShip(IAddNewShip.basicInformation);
            setActiveKeyShowShip(IAddNewShip.basicInformation);
          }}
        >
          edit_note
        </span>
      </div>
    </div>
  );
  return (
    <Form form={form} onFinish={onFinish}>
      <HeaderShowShip />
      <ShowShipContainer>
        {TopBar}
        <Tabs
          items={items}
          onChange={onChange}
          activeKey={activeKeyShowShip}
          animated
        />
      </ShowShipContainer>
    </Form>
  );
};
