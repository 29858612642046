import { Row } from "antd";
import { styled } from "styled-components";

export const CommentContainer = styled(Row)`
  .description {
    color: var(--secondary-dark-grey-900, #1b2559);
    font-family: DM Sans;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: -0.48px;
  }
  .reference {
    position: absolute;
    top: 0;
    right: 0;
    color: var(--text, #001377);
    font-family: DM Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .titleHolder {
    border-bottom: 1px solid #a3aed0;
    margin-top: 12px;
  }
  .commentInput {
    display: flex;
    height: 176px !important;
    padding: 16px 12px;
    align-items: flex-start;
    gap: 8px;
    flex-shrink: 0;
    align-self: stretch;
    background: var(--3, #f4f7fe);
    border-radius: 12px;
  }
  .footerContainer {
    gap: 16px;
  }
  .invoices {
    color: var(--text-5, #3e4556);
    text-align: right;
    font-family: DM Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.32px;
  }
`;
