import { Row } from "antd";
import { breakPointsSm } from "src/constants/screen";
import { styled } from "styled-components";

export const LoginContainer = styled(Row)`
  margin-left: 157px;
  .input {
    border-radius: 8px;
    border: 2px solid #fff;
    background: rgba(0, 39, 143, 0.4);
    width: 100%;
    display: block;
    height: 56px;
    color: #fff;
  }
  .inputPassword {
    background: rgba(0, 39, 143, 0.4);
    border: 2px solid #fff;
    padding-top: 0;
    padding-bottom: 0;
    border-radius: 8px;
    height: 56px;
    input {
      background: transparent;
      width: 100%;
      display: block;
      color: #fff;
    }
    input::placeholder {
      color: #fff;
    }
    svg {
      fill: #fff;
    }
  }
  .input::placeholder {
    color: #fff;
  }
  .button {
    width: 100%;
    color: #024a94;
    height: 51px;
    margin-top: 8px;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-text-fill-color: #fff !important;
    -webkit-box-shadow: 0 0 0 1000px transparent inset !important;
    transition: background-color 5000s ease-in-out 0s !important;
  }

  @media only screen and (max-width: ${breakPointsSm}px) {
    & {
      margin-left: unset !important;
      padding: 40px 16px;
    }
  }
`;
