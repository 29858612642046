import { Form, FormInstance, message } from "antd";
import {
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useContext,
  useState,
} from "react";
import { IPublicCurrency } from "src/services/Public/models";
import { PublicService } from "src/services/Public/Public.service";
import { INewCurrency } from "src/services/System/models";
import { SystemService } from "src/services/System/System.service";
import { ICurrencyTabs } from "../models";

interface ICurrencyContext {
  value: {
    tableLoading: boolean;
    tableData: IPublicCurrency[] | undefined;
    addCurrencyLoading: boolean;
    activeKey: string;
    editLoading: boolean;
    editData: IPublicCurrency | undefined;
    currencyId: number | undefined;
    editMode: boolean;
  };
  dispatch: {
    setTableLoading: Dispatch<SetStateAction<boolean>>;
    setTableData: Dispatch<SetStateAction<IPublicCurrency[]>>;
    setAddCurrencyLoading: Dispatch<SetStateAction<boolean>>;
    setActiveKey: Dispatch<SetStateAction<string>>;
    setEditLoading: Dispatch<SetStateAction<boolean>>;
    setEditData: Dispatch<SetStateAction<IPublicCurrency | undefined>>;
    setCurrencyId: Dispatch<SetStateAction<number | undefined>>;
    setEditMode: Dispatch<SetStateAction<boolean>>;
  };
  func: {
    getCurrency: () => void;
    onFinish: (values: INewCurrency) => void;
    onBack: () => void;
  };
  form: { currencyForm: FormInstance<any> | undefined };
}

const defaultCtxValue: ICurrencyContext = {
  value: {
    tableLoading: false,
    tableData: [],
    addCurrencyLoading: false,
    activeKey: "",
    editLoading: false,
    editData: undefined,
    currencyId: undefined,
    editMode: false,
  },
  dispatch: {
    setTableLoading: () => {},
    setTableData: () => {},
    setAddCurrencyLoading: () => {},
    setActiveKey: () => {},
    setEditLoading: () => {},
    setEditData: () => {},
    setCurrencyId: () => {},
    setEditMode: () => {},
  },
  func: {
    getCurrency: () => {},
    onFinish: () => {},
    onBack: () => {},
  },
  form: { currencyForm: undefined },
};

export const CurrencyContext = createContext<ICurrencyContext>(defaultCtxValue);

export const CurrencyProvider: FC<PropsWithChildren> = ({ children }) => {
  //-----------------------form---------------------

  const [currencyForm] = Form.useForm();

  //-----------------------state--------------------

  const [tableLoading, setTableLoading] = useState<boolean>(false);

  const [tableData, setTableData] = useState<IPublicCurrency[]>([]);

  const [addCurrencyLoading, setAddCurrencyLoading] = useState<boolean>(false);

  const [editLoading, setEditLoading] = useState<boolean>(false);

  const [editData, setEditData] = useState<IPublicCurrency | undefined>(
    undefined
  );

  const [editMode, setEditMode] = useState<boolean>(false);

  const [currencyId, setCurrencyId] = useState<number | undefined>(undefined);

  const [activeKey, setActiveKey] = useState<string>(
    ICurrencyTabs.addNewCurrency || ICurrencyTabs.currencyTable
  );

  //-----------------------func---------------------

  const onBack = () => {
    setEditMode(false);
    setCurrencyId(undefined);
    setEditData(undefined);
    currencyForm.resetFields();
  };

  const getCurrency = useCallback(async () => {
    try {
      setTableLoading(true);
      const { AllCurrency } = new PublicService();
      const response = await AllCurrency();
      if (response && response.status === 200) {
        setTableData(response.data);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setTableLoading(false);
    }
  }, []);

  const onFinish = async (values: INewCurrency) => {
    if (editMode && currencyId) {
      setEditLoading(true);
      try {
        const { EditCurrency } = new SystemService();
        const response = await EditCurrency(values, currencyId);
        if (response && response.status === 200) {
          message.success("Edit currency successfully");
          getCurrency();
          setEditMode(false);
          setCurrencyId(undefined);
          currencyForm.resetFields();
          setActiveKey(ICurrencyTabs.currencyTable);
        }
      } catch (err) {
        console.log(err);
      } finally {
        setEditLoading(false);
      }
    } else {
      try {
        setAddCurrencyLoading(true);
        const { NewCurrency } = new SystemService();
        const response = await NewCurrency(values);
        if (response && response.status === 200) {
          message.success("currency created successfully");
          getCurrency();
        }
      } catch (error) {
        console.log(error);
      } finally {
        setAddCurrencyLoading(false);
        currencyForm?.resetFields();
      }
    }
  };

  const contextValue: ICurrencyContext = {
    value: {
      tableLoading,
      addCurrencyLoading,
      tableData,
      activeKey,
      editLoading,
      editData,
      currencyId,
      editMode,
    },
    dispatch: {
      setTableLoading,
      setAddCurrencyLoading,
      setTableData,
      setActiveKey,
      setEditLoading,
      setEditData,
      setCurrencyId,
      setEditMode,
    },
    func: { onFinish, getCurrency, onBack },
    form: { currencyForm },
  };

  return (
    <CurrencyContext.Provider value={contextValue}>
      {children}
    </CurrencyContext.Provider>
  );
};

export const useCurrency = () => useContext(CurrencyContext);
