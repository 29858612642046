import { FC, useEffect, useState } from "react";
import { SidebarContainer } from "../styles/sidebar";
import { Drawer, Flex, Menu } from "antd";
import { useLocation } from "react-router-dom";
import SideBarLogo from "./SideBarLogo";
import { useSidebarItems } from "../constant/sidebarItems";
import { useMediaQuery } from "src/hook";
import useGlobalStore from "src/store/global";
import { breakPointsSm } from "src/constants/screen";

interface Items {
  key: React.Key;
  icon: React.ReactNode;
  children: Items[] | undefined;
  label: React.ReactNode;
  type: "group" | undefined;
  disabled: boolean | undefined;
}
const Sidebar: FC = () => {
  let location = useLocation();
  const currentPath = location.pathname;
  const [activeKey, setActiveKey] = useState<string>(currentPath);
  const isDesktop = useMediaQuery(breakPointsSm);
  const items = useSidebarItems(activeKey);
  const { showDrawer, setShowDrawer } = useGlobalStore();
  const createItems = (item: Items) => {
    if (item && item.key) {
      if (item.children && item.children.length > 0) {
        item.children.forEach((i) => createItems(i));
      } else {
        if (currentPath === (item.key as string))
          setActiveKey(item.key as string);
      }
    } else {
      return setActiveKey(currentPath);
    }
  };
  useEffect(() => {
    (items as Items[]).forEach((item) => {
      createItems(item);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPath]);
  return isDesktop ? (
    <SidebarContainer width={290}>
      <div>
        <SideBarLogo />

        <Menu
          defaultSelectedKeys={["dashboard"]}
          mode="inline"
          items={items}
          className="menu"
          selectedKeys={[activeKey]}
        />
      </div>
    </SidebarContainer>
  ) : (
    <Drawer
      open={showDrawer}
      onClose={() => {
        setShowDrawer(false);
      }}
      title={
        <Flex justify="center">
          <SideBarLogo />
        </Flex>
      }
    >
      <Menu
        defaultSelectedKeys={["dashboard"]}
        mode="inline"
        items={items}
        className="menu !border-none"
        selectedKeys={[activeKey]}
        onSelect={() => {
          setShowDrawer(false);
        }}
      />
    </Drawer>
  );
};

export default Sidebar;
