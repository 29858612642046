import { FC } from "react";
import { VoyageForm } from "./VoyageForm";
import { VoyageInfoProvider } from "./context";

export const VoyageInfo: FC = () => {
  return (
    <VoyageInfoProvider>
      <VoyageForm />
    </VoyageInfoProvider>
  );
};
