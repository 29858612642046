import { Tabs, TabsProps } from "antd";
import { FC, useEffect, useState } from "react";
import { ProfitReportContainer } from "./style";
import { ProfitTabs } from "./constants/Tabs";
import { useProfitReport } from "./context";
import { AddNewProfit } from "./module/AddNewProfit";
import { ProfitTable } from "./module/ProftTable";
import { EditHeader } from "./module/editHeader";
import { useTableProfit } from "./module/ProftTable/context";

export const ProfitReport: FC = () => {
  const {
    value: { activeKey },
    dispatch: { setActiveKey },
  } = useProfitReport();
  const {
    value: { fromTable },
  } = useTableProfit();
  const [items, setItems] = useState<TabsProps["items"]>([]);
  const AddProfitTab = {
    key: ProfitTabs.Add,
    label: (
      <span>
        <span className="material-icons text-[10px] p-[8px]">lens</span>
        Add new report
      </span>
    ),
    children: <AddNewProfit />,
  };

  const ProfitTableTab = {
    key: ProfitTabs.Table,
    label: (
      <span>
        <span className="material-icons text-[10px] p-[10px]">lens</span>
        Reports table
      </span>
    ),
    children: <ProfitTable />,
  };
  useEffect(() => {
    setItems([AddProfitTab, ProfitTableTab]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (key: string) => {
    setActiveKey(key);
  };
  return (
    <ProfitReportContainer>
      {fromTable && <EditHeader />}
      <Tabs
        items={items}
        onChange={onChange}
        activeKey={activeKey}
        animated
        tabBarStyle={{
          display: fromTable ? "none" : "initial",
        }}
      />
    </ProfitReportContainer>
  );
};
