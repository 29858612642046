import { breakPointsSm } from "src/constants/screen";
import { styled } from "styled-components";

export const Container = styled.div<{ type: "primary" | "secondary" }>`
  width: 100%;
  ${({ type }) =>
    type === "primary"
      ? "background: #fff; border-radius: 16px; padding: 24px;"
      : ""}
  @media only screen and (max-width: ${breakPointsSm}px) {
    & {
      padding-inline: 8px;
    }
  }
  thead :is(td, th) {
    background-color: ${({ type }) =>
      type === "primary" ? "#dbe9ff !important" : "#dadeec !important"};
    color: #1b2559;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    text-align: left;
  }

  ${({ type }) =>
    type === "primary"
      ? ""
      : "tbody tr > td:first-child { background-color: #dadeec; }"}

  .ant-table-tbody td {
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    text-align: left;
    color: #5d6881;
    border-bottom: unset;
  }
  .ant-table-cell-row-hover {
    background: #f2f7ff !important;
  }
  .sc-bBbNsw {
    width: 80% !important;
  }
  .ant-tabs-tabpane {
    display: flex;
    justify-content: center;
  }

  /* tr:nth-child(odd) {
    background: #f2f7ff;
  }
  tr:nth-child(even) {
    background: white;
  } */

  ${({ type }) =>
    type === "secondary" ? "tr:nth-child(odd) { background-color: #fff;}" : ""}
`;
