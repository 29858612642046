import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { PlusOutlined } from "@ant-design/icons";
import type { InputRef } from "antd";
import { Input, message, Popconfirm, Space, Tag, theme, Tooltip } from "antd";

interface IProps {
  tags: string[];
  setTags: Dispatch<SetStateAction<string[]>>;
  selected: number | undefined;
  setSelected: Dispatch<SetStateAction<number | undefined>>;
  onChangeTag: (index: number) => boolean;
  placeholder: string;
  onConfirm?: () => void;
  beforeShowInput?: () => boolean;
  disabled?: boolean;
  onDelete: () => void;
}
const STag: React.FC<IProps> = ({
  tags,
  setTags,
  selected,
  setSelected,
  onChangeTag,
  placeholder,
  onConfirm,
  disabled,
  beforeShowInput,
  onDelete,
}) => {
  const { token } = theme.useToken();

  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [editInputIndex, setEditInputIndex] = useState(-1);
  const [editInputValue, setEditInputValue] = useState("");
  const inputRef = useRef<InputRef>(null);
  const editInputRef = useRef<InputRef>(null);

  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus();
    }
  }, [inputVisible]);

  useEffect(() => {
    editInputRef.current?.focus();
  }, [editInputValue]);
  const selectTag = (index: number) => {
    if (onChangeTag(index)) {
      setSelected(index);
    }
  };
  const showInput = () => {
    if (disabled) return;
    if (beforeShowInput && beforeShowInput()) {
      setInputVisible(true);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleInputConfirm = () => {
    if (inputValue) {
      if (!tags.includes(inputValue)) {
        setTags([...tags, inputValue]);
        setSelected([...tags, inputValue].length - 1);
        onConfirm && onConfirm();
      } else {
        message.error("Duplicate name");
      }
    }
    setInputVisible(false);
    setInputValue("");
  };

  const handleEditInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditInputValue(e.target.value);
  };

  const handleEditInputConfirm = () => {
    const newTags = [...tags];
    newTags[editInputIndex] = editInputValue;
    setTags(newTags);
    setEditInputIndex(-1);
    setEditInputValue("");
  };

  const tagInputStyle: React.CSSProperties = {
    width: 150,
    height: 40,
    marginInlineEnd: 16,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 8,
  };
  const tagInputSelectedStyle: React.CSSProperties = {
    width: 150,
    height: 40,
    marginInlineEnd: 16,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 8,
    color: "#create",
    border: "1px solid #2C73FC",
  };
  const tagPlusStyle: React.CSSProperties = {
    width: 150,
    height: 40,
    background: token.colorBgContainer,
    borderStyle: "dashed",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 8,
    border: "1px dashed #5D6881",
  };

  return (
    <Space size={[0, 8]}>
      {inputVisible ? (
        <Input
          ref={inputRef}
          type="text"
          size="small"
          style={tagInputStyle}
          value={inputValue}
          onChange={handleInputChange}
          onBlur={handleInputConfirm}
          onPressEnter={handleInputConfirm}
          disabled={false}
        />
      ) : (
        <Tag
          style={tagPlusStyle}
          icon={<PlusOutlined />}
          onClick={showInput}
          className={disabled ? "cursor-not-allowed" : "cursor-pointer"}
        >
          {placeholder}
        </Tag>
      )}
      {tags.map((tag, index) => {
        if (editInputIndex === index) {
          return (
            <Input
              ref={editInputRef}
              key={index}
              size="small"
              style={tagInputStyle}
              value={editInputValue}
              onChange={handleEditInputChange}
              onBlur={handleEditInputConfirm}
              onPressEnter={handleEditInputConfirm}
              disabled={false}
            />
          );
        }
        const isLongTag = tag.length > 20;
        const tagElem = (
          <div className="relative" key={index}>
            <Tag
              style={selected === index ? tagInputSelectedStyle : tagInputStyle}
              onClick={() => selectTag(index)}
              onDoubleClick={(e) => {
                setEditInputIndex(index);
                setEditInputValue(tag);
                e.preventDefault();
              }}
              className="cursor-pointer select-none px-[20px] "
            >
              <span>{isLongTag ? `${tag.slice(0, 16)}...` : tag}</span>
            </Tag>
            {index === selected && (
              <Popconfirm
                title="Are you sure want to delete this item?"
                onConfirm={() => {
                  onDelete();
                }}
                rootClassName="deletePopconfirm"
                okButtonProps={{
                  disabled: false,
                }}
                icon={null}
                cancelButtonProps={{ disabled: false }}
              >
                <div className="absolute top-[10px] right-[20px] flex justify-center items-center rounded-[12px] cursor-pointer select-none">
                  <span className="material-symbols-outlined text-[#F64C4C]  text-[18px] ">
                    delete_forever
                  </span>
                </div>
              </Popconfirm>
            )}
          </div>
        );
        return isLongTag ? (
          <Tooltip title={tag} key={tag}>
            {tagElem}
          </Tooltip>
        ) : (
          tagElem
        );
      })}
    </Space>
  );
};

export default STag;
