import {
  Button,
  Col,
  Divider,
  Flex,
  Form,
  Input,
  Popover,
  Row,
  Typography,
} from "antd";
import { FC, useEffect, useState } from "react";
import { ContentContainer } from "../style";
import { FormItem } from "src/components/UiKit/FormItem";
import { SInput } from "src/components/UiKit/Input";
import { useProfitReport } from "src/modules/ProfitReport/context";
import Upload, {
  UploadChangeParam,
  UploadFile,
  UploadProps,
} from "antd/es/upload";
import { dummyRequest } from "src/helpers/dummyRequest";
import { useAttachFile } from "../context";
import { useTableProfit } from "../../ProftTable/context";

export const AttachFileBaseInfoContent: FC = () => {
  const {
    dispatch: { setShowAttachFile },
  } = useProfitReport();

  const {
    value: { fileName, newFileLoading },
    dispatch: { setAttachFileLoading, setFileName, setTableDataSource },
    func: { NewProfitAttachFiles },
  } = useAttachFile();

  const {
    form: { attachFileBaseInfoForm },
  } = useTableProfit();

  const { TextArea } = Input;

  const [fileList, setFileList] = useState<UploadFile<any>[]>([]);

  const [submit, setSubmit] = useState<boolean>(false);

  const onCancel = () => {
    setShowAttachFile(false);
    setTableDataSource(undefined);
  };

  const onReset = () => attachFileBaseInfoForm?.resetFields();

  const formValue = Form.useWatch([], attachFileBaseInfoForm);

  useEffect(() => {
    attachFileBaseInfoForm
      ?.validateFields({ validateOnly: true })
      .then(() => {
        setSubmit(true);
      })
      .catch(() => {
        setSubmit(false);
      });
  }, [attachFileBaseInfoForm, formValue]);

  //------------------------attachFile------------------------------

  // const beforeUpload = (file: RcFile) => {
  //   const isExcelFile =
  //     file.type ===
  //     "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
  //   if (!isExcelFile) {
  //     message.error("You can only upload EXCEL file!");
  //   }
  //   return isExcelFile;
  // };

  const handleChange: UploadProps["onChange"] = (
    info: UploadChangeParam<UploadFile>
  ) => {
    setFileList([info.file]);

    if (info.file.status === "uploading") {
      setAttachFileLoading(true);
      return;
    }
    if (info.file.status === "done") {
      setFileName(info.file.originFileObj?.name || "");
      // readFile(info.file.originFileObj!);
    }
  };

  // const readFile = (f: RcFile) => {
  //   const reader = new FileReader();
  //   reader.onload = (evt: any) => {
  //     // evt = on_file_select event
  //     /* Parse data */
  //     const bstr = evt.target.result;
  //     const wb = XLSX.read(bstr, { type: "binary" });
  //     /* Get first worksheet */
  //     const wsname = wb.SheetNames[0];
  //     const ws = wb.Sheets[wsname];
  //     /* Convert array of arrays */
  //     const data = XLSX.utils.sheet_to_csv(ws);
  //     /* Update state */
  //     const convertedData: IExcelData[] = convertToJson(data);
  //     getCategoriesAndInsertToData(convertedData); // shows data in json format
  //   };
  //   reader.readAsBinaryString(f);
  // };

  const uploadButton = (
    <div>
      {/* {!attachFileLoading ? (
        <span className="material-icons text-[#A3AED0] ">attach_file</span>
      ) : (
        <LoadingOutlined color="#A3AED0" />
      )} */}
      <span className="material-icons text-[#A3AED0] ">attach_file</span>

      <div className="text-[#A3AED0]">Attach</div>
    </div>
  );
  return (
    <Form form={attachFileBaseInfoForm} onFinish={NewProfitAttachFiles}>
      <ContentContainer>
        <Col span={24}>
          <Flex align="center" justify="center">
            <Typography.Title className="title">
              Please attach document & insert its information.
            </Typography.Title>
          </Flex>
        </Col>
        <Col span={24} className="mt-[28px]">
          <Row>
            <Col span={8}>
              <FormItem
                name="name"
                label="File name"
                rules={[{ required: true }]}
              >
                <SInput />
              </FormItem>
            </Col>
            <Col span={16}>
              <div>
                <FormItem
                  name={"files"}
                  className="flex justify-end items-center"
                  rules={[{ required: true }]}
                >
                  <Upload
                    name="excel-file"
                    listType="picture-card"
                    className=" flex justify-center upload"
                    showUploadList={false}
                    // beforeUpload={beforeUpload}
                    onChange={handleChange}
                    customRequest={dummyRequest}
                    fileList={fileList}
                  >
                    {fileName ? (
                      <div className="flex flex-col justify-center items-center">
                        <span className="material-icons text-[32px] text-green-400">
                          check
                        </span>
                        {fileName.length > 10 ? (
                          <Popover placement="top" content={fileName}>
                            {fileName.substring(0, 7).concat("...")}
                          </Popover>
                        ) : (
                          <div className="text-[12px]">{fileName}</div>
                        )}
                      </div>
                    ) : (
                      uploadButton
                    )}
                  </Upload>
                </FormItem>
              </div>
            </Col>
            <Col span={24}>
              <FormItem name="description" label="Description">
                <TextArea
                  placeholder="Description"
                  rows={9}
                  style={{
                    resize: "none",
                    borderRadius: 12,
                    background: "#F4F7FE",
                  }}
                />
              </FormItem>
            </Col>
          </Row>
        </Col>
      </ContentContainer>
      <Row>
        <Col span={24} className="mt-[24px]">
          <Flex align="center" justify="space-between">
            <Flex gap={16}>
              <Button
                onClick={onCancel}
                type="text"
                className="text-[#4B4B4B] min-w-[85px] border-[1px] border-solid border-[#4B4B4B] rounded-[8px]"
              >
                Back
              </Button>
            </Flex>
            <Flex gap={16}>
              <Button className="min-w-[85px]" onClick={onReset}>
                Reset
              </Button>
              <Button
                className="min-w-[85px]"
                type="primary"
                htmlType="submit"
                disabled={!submit}
                loading={newFileLoading}
              >
                Add
              </Button>
              <Button
                onClick={onCancel}
                type="primary"
                ghost
                className="text-[#4B4B4B] min-w-[85px]"
              >
                Finish
              </Button>
            </Flex>
          </Flex>
        </Col>
        <Divider />
      </Row>
    </Form>
  );
};
