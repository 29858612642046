import { USER_ACCESS_PATH } from "src/constants";
import { IPath } from "src/services/Identity/models";

// export class GuardClass {

export const checkRoutePermission = (checkValue: IPath[]) => {
  const path = localStorage.getItem(USER_ACCESS_PATH);
  const accessPath: IPath[] = path ? JSON.parse(path) : [];
  let hasPermission = false;
  checkValue.forEach(({ path }) => {
    for (const urlInfo of accessPath) {
      if (path.includes(urlInfo.path)) {
        hasPermission = true;
        return;
      }
    }
  });
  return hasPermission;
};
export const checkActionPermission = (checkValue: IPath) => {
  const pathLocal = localStorage.getItem(USER_ACCESS_PATH);
  const accessPath: IPath[] = pathLocal ? JSON.parse(pathLocal) : [];
  let hasPermission: boolean = false;
  const { path, httpMethod } = checkValue;
  for (const urlInfo of accessPath) {
    if (path === urlInfo.path) {
      if (urlInfo.httpMethod) {
        if (urlInfo.httpMethod === httpMethod) {
          hasPermission = true;
          break;
        }
      } else {
        hasPermission = true;
        break;
      }
    }
  }

  return hasPermission;
};
// }
