import { FC, useEffect, useState } from "react";
import { ContentContainer } from "../style";
import { Button, Col, Flex, Form, Row } from "antd";
import { FormItem } from "src/components/UiKit/FormItem";
import { SInput } from "src/components/UiKit/Input";
import TextArea from "antd/es/input/TextArea";
import { usePayer } from "../context";

export const AddNewPayer: FC = () => {
  const {
    value: { payerLoading, editMode, editLoading, editData },
    form: { PayerForm },
    func: { onFinish, onBack },
  } = usePayer();

  const onReset = () => {
    PayerForm?.resetFields();
  };

  const [submit, setSubmit] = useState<boolean>(false);

  const formValue = Form.useWatch([], PayerForm);

  useEffect(() => {
    PayerForm?.validateFields({ validateOnly: true })
      .then(() => setSubmit(true))
      .catch(() => setSubmit(false));
  }, [PayerForm, formValue]);

  useEffect(() => {
    if (editMode) {
      PayerForm?.setFieldsValue(editData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editMode]);

  return (
    <Form form={PayerForm} onFinish={onFinish}>
      <Row justify="center">
        <Col span={15}>
          <ContentContainer>
            <Col span={24} className="text-[16px] font-medium text-[#001377]">
              Please complete the requested information.
            </Col>
            <Col span={24} className="py-[16px]">
              <div className="">
                <Row gutter={24}>
                  <Col span={12}>
                    <FormItem
                      name={"name"}
                      label="Name"
                      rules={[{ required: true }]}
                      required={true}
                    >
                      <SInput placeholder="insert" allowClear />
                    </FormItem>
                  </Col>
                  <Col span={24}>
                    <FormItem name={"description"} label="Description">
                      <TextArea
                        placeholder="Description"
                        rows={6}
                        autoSize={{ minRows: 6, maxRows: 6 }}
                        allowClear
                        className="p-[16px] resize-none rounded-[12px] bg-[#F4F7FE]"
                      />
                    </FormItem>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col span={24} className="button">
              <Flex justify="end" gap={16} align="center">
                <Button onClick={editMode ? onBack : onReset}>
                  {editMode ? "Cancel" : "Reset"}
                </Button>
                <Button
                  type="primary"
                  loading={editMode ? editLoading : payerLoading}
                  htmlType="submit"
                  disabled={!submit}
                >
                  {editMode ? "Save edit" : "Add"}
                </Button>
              </Flex>
            </Col>
          </ContentContainer>
        </Col>
      </Row>
    </Form>
  );
};
