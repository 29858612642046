import React, { FC, ReactNode } from "react";
import { LoginLayoutStyled } from "./styles";
import { Outlet } from "react-router-dom";
import { Layout } from "antd";

const LoginResponsiveLayout: FC<{ children?: ReactNode }> = ({ children }) => {
  return (
    <Layout style={{ background: "transparent" }}>
      <LoginLayoutStyled>
        <div className="content">
          <Outlet />
        </div>
      </LoginLayoutStyled>
    </Layout>
  );
};

export default LoginResponsiveLayout;
