import { FC } from "react";
import { HeaderEditCommercial } from "./style";
import { useCommercial } from "../../context";

export const HeaderEditShip: FC = () => {
  const {
    value: { currentEditVoyage },
    func: { cancelEditMode },
  } = useCommercial();
  return (
    <HeaderEditCommercial>
      <div
        className="flex flex-row gap-[12px] items-center cursor-pointer"
        onClick={cancelEditMode}
      >
        <span className="material-icons text-[#2C73FC] text-[32px]">
          keyboard_backspace
        </span>
        <div className=" text-[#2C73FC] text-[24px] ">
          Back to Voyage table{" "}
        </div>
      </div>
      <div className="flex flex-col">
        <div className="text-[#001377] text-[18px]">Edit voyage </div>
        <div className="text-[#5D6881] text-[14px]">
          {currentEditVoyage?.voyageNO}
        </div>
      </div>
    </HeaderEditCommercial>
  );
};
