import { Col, Row } from "antd";
import { FormItem } from "src/components/UiKit/FormItem";
import { FC } from "react";
import { SInput } from "src/components/UiKit/Input";
import { invoiceReportFormItemsName } from "../../constant/flterFormNames";
export const Attachment: FC = () => {
  return (
    <Row gutter={24}>
      <Col span={8}>
        <FormItem
          name={invoiceReportFormItemsName["Attachment code"]}
          label="Attachment code"
          className="!mb-0"
        >
          <SInput />
        </FormItem>
      </Col>
    </Row>
  );
};
