import {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  AddInvoiceTabEnums,
  AddInvoiceTabs,
  IBaseInformationValue,
  IExcelData,
  IPaymentInformation,
  ITotalInvoiceInfo,
} from "./models";
import { FormInstance, useForm } from "antd/es/form/Form";
import { AddInvoice } from ".";
import { IPDFFile, IZipFile } from "../Table/models";
import { UploadFile } from "antd";
import { PublicService } from "src/services/Public/Public.service";
import { IPublicCurrency } from "src/services/Public/models";
import { InvoiceService } from "src/services/Invoice/Invoice.service";
import {
  IFinallyInvoice,
  IInitInvoiceInof,
  IRelatedInvoice,
} from "src/services/Invoice/models";
import { SystemService } from "src/services/System/System.service";
import { IGetSupplier } from "src/services/System/models";

interface IContext {
  value: {
    activeTab: AddInvoiceTabs;
    showModal: boolean;
    fileIsUploaded: boolean;
    excelData: IExcelData[];
    BaseInformationValues: IBaseInformationValue | undefined;
    excelFile: UploadFile<any>[] | undefined;
    showInvoice: boolean;
    totalInvoiceInfo: ITotalInvoiceInfo | undefined;
    totalInvoiceAttach: Blob | undefined;
    paymentInfo: IPaymentInformation | undefined;
    shipManagementRate: number;
    BaseInformationForm: FormInstance<any>;
    PaymentInformationForm: FormInstance<any>;
    TotalInvoicesForm: FormInstance<any>;
    currentRequest: IZipFile | undefined;
    currentAttachment: IPDFFile | undefined;
    newInvoiceId: number | undefined;
    newArticle: number | undefined;
    fileName: string;
    allCurrency: IPublicCurrency[];
    finallyInvoice: IFinallyInvoice | undefined;
    invoiceInfo: IInitInvoiceInof | undefined;
    finallyInvoiceLoading: boolean;
    fetchRelatedInvoiceLoading: boolean;
    refetchAllRequestDependency: number;
    relatedInvoice: IRelatedInvoice | undefined;
    supplierList: IGetSupplier[] | undefined;
    supplierLoading: boolean;
  };
  dispatch: {
    setActiveTab: Dispatch<SetStateAction<AddInvoiceTabs>>;
    setShowModal: Dispatch<SetStateAction<boolean>>;
    setFileIsUploaded: Dispatch<SetStateAction<boolean>>;
    setShowInvoice: Dispatch<SetStateAction<boolean>>;
    setExcelData: Dispatch<SetStateAction<IExcelData[]>>;
    setExcelFile: Dispatch<SetStateAction<UploadFile<any>[]>>;
    setBaseInformationValues: Dispatch<
      SetStateAction<IBaseInformationValue | undefined>
    >;
    setTotalInvoiceInfo: Dispatch<
      SetStateAction<ITotalInvoiceInfo | undefined>
    >;
    setPaymentInfo: Dispatch<SetStateAction<IPaymentInformation | undefined>>;
    setTotalInvoiceAttach: Dispatch<SetStateAction<Blob | undefined>>;
    setShipManagementRate: Dispatch<SetStateAction<number>>;
    setCurrentRequest: Dispatch<SetStateAction<IZipFile | undefined>>;
    setCurrentAttachment: Dispatch<SetStateAction<IPDFFile | undefined>>;
    setNewInvoiceId: Dispatch<SetStateAction<number | undefined>>;
    setNewArticle: Dispatch<SetStateAction<number | undefined>>;
    setFileName: Dispatch<SetStateAction<string>>;
    setFinallyInvoice: Dispatch<SetStateAction<IFinallyInvoice | undefined>>;
    setInvoiceInfo: Dispatch<SetStateAction<IInitInvoiceInof | undefined>>;
  };
  func: {
    onCancelModal: () => void;
    reset: () => void;
    fetchSupplier: () => void;
    refetchAllRequest: () => void;
    fetchFinallyInvoice: (id: number) => Promise<void>;
    fetchRelatedInvoice: (id: number) => Promise<void>;
  };
}
export const AddInvoicesContext = createContext<IContext | undefined>(
  undefined
);
export const AddInvoicesProvider: FC<PropsWithChildren> = ({ children }) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [excelData, setExcelData] = useState<IExcelData[]>([]);
  const [activeTab, setActiveTab] = useState<AddInvoiceTabs>(
    AddInvoiceTabEnums.BaseInformation
  );
  const [fileIsUploaded, setFileIsUploaded] = useState<boolean>(false);
  const [excelFile, setExcelFile] = useState<UploadFile<any>[]>([]);
  const [fileName, setFileName] = useState<string>("");
  const [showInvoice, setShowInvoice] = useState<boolean>(false);
  const [BaseInformationValues, setBaseInformationValues] =
    useState<IBaseInformationValue>();

  const [totalInvoiceInfo, setTotalInvoiceInfo] = useState<ITotalInvoiceInfo>();
  const [paymentInfo, setPaymentInfo] = useState<IPaymentInformation>();
  const [totalInvoiceAttach, setTotalInvoiceAttach] = useState<Blob>();
  const [shipManagementRate, setShipManagementRate] = useState<number>(0);
  const [finallyInvoice, setFinallyInvoice] = useState<IFinallyInvoice>();
  const onCancelModal = () => {
    setShowModal(false);
    setActiveTab(AddInvoiceTabEnums.BaseInformation);
    reset();
  };
  const [BaseInformationForm] = useForm();
  const [PaymentInformationForm] = useForm();
  const [TotalInvoicesForm] = useForm();
  const [currentRequest, setCurrentRequest] = useState<IZipFile>();
  const [currentAttachment, setCurrentAttachment] = useState<IPDFFile>();
  const [newInvoiceId, setNewInvoiceId] = useState<number>();
  const [newArticle, setNewArticle] = useState<number>();
  const [allCurrency, setAllCurrency] = useState<IPublicCurrency[]>([]);
  const [invoiceInfo, setInvoiceInfo] = useState<IInitInvoiceInof>();
  const [refetchAllRequestDependency, setRefetchAllRequestDependency] =
    useState<number>(1);
  const [finallyInvoiceLoading, setFinallyInvoiceLoading] =
    useState<boolean>(false);
  const [fetchRelatedInvoiceLoading, setRelatedInvoiceLoading] =
    useState<boolean>(false);
  const [relatedInvoice, setRelatedInvoice] = useState<IRelatedInvoice>();
  const refetchAllRequest = () => {
    setRefetchAllRequestDependency((prev) => prev + 1);
  };
  const [supplierLoading, setSupplierLoading] = useState<boolean>(false);
  const [supplierList, setSupplierList] = useState<IGetSupplier[]>();

  //----------------------------------------------------------------Functions----------------------------------------------------------------
  const fetchAllCurrency = async () => {
    try {
      const { AllCurrency } = new PublicService();
      const res = await AllCurrency();
      if (res && res.status === 200 && res.data) {
        setAllCurrency(res.data);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const fetchFinallyInvoice = async (id: number) => {
    try {
      setFinallyInvoiceLoading(true);
      const { FinallyInvoice } = new InvoiceService();
      const res = await FinallyInvoice(id);
      if (res && res.status === 200 && res.data) {
        setFinallyInvoice(undefined);

        setFinallyInvoice(res.data);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setFinallyInvoiceLoading(false);
    }
  };
  const fetchRelatedInvoice = async (id: number) => {
    try {
      setRelatedInvoiceLoading(true);
      const { GetRelatedInvoice } = new InvoiceService();
      const res = await GetRelatedInvoice(id);
      if (res && res.status === 200 && res.data) {
        setRelatedInvoice(res.data);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setRelatedInvoiceLoading(false);
    }
  };
  const reset = () => {
    BaseInformationForm.resetFields();
    PaymentInformationForm.resetFields();
    TotalInvoicesForm.resetFields();
    setShowModal(false);
    setExcelData([]);
    setActiveTab(AddInvoiceTabEnums.BaseInformation);
    setFileIsUploaded(false);
    setExcelFile([]);
    setShowInvoice(false);
    setBaseInformationValues(undefined);
    setTotalInvoiceInfo(undefined);
    setPaymentInfo(undefined);
    setTotalInvoiceAttach(undefined);
    setShipManagementRate(0);
    setFileName("");
    refetchAllRequest();
  };
  useEffect(() => {
    fetchAllCurrency();
    fetchSupplier();
  }, []);
  const fetchSupplier = async () => {
    try {
      setSupplierLoading(true);
      const { GetSupplier } = new SystemService();
      const response = await GetSupplier();
      if (response && response?.status === 200 && response.data) {
        setSupplierList(response.data.records);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setSupplierLoading(false);
    }
  };
  const contextValue: IContext = {
    value: {
      activeTab,
      showModal,
      excelData,
      fileIsUploaded,
      BaseInformationValues,
      excelFile,
      showInvoice,
      totalInvoiceInfo,
      totalInvoiceAttach,
      paymentInfo,
      shipManagementRate,
      BaseInformationForm,
      PaymentInformationForm,
      TotalInvoicesForm,
      currentRequest,
      currentAttachment,
      newInvoiceId,
      fileName,
      newArticle,
      allCurrency,
      finallyInvoice,
      invoiceInfo,
      finallyInvoiceLoading,
      fetchRelatedInvoiceLoading,
      refetchAllRequestDependency,
      relatedInvoice,
      supplierList,
      supplierLoading,
    },
    dispatch: {
      setActiveTab,
      setShowModal,
      setExcelData,
      setFileIsUploaded,
      setBaseInformationValues,
      setExcelFile,
      setShowInvoice,
      setTotalInvoiceInfo,
      setTotalInvoiceAttach,
      setPaymentInfo,
      setShipManagementRate,
      setCurrentRequest,
      setCurrentAttachment,
      setNewInvoiceId,
      setFileName,
      setNewArticle,
      setFinallyInvoice,
      setInvoiceInfo,
    },
    func: {
      onCancelModal,
      reset,
      fetchFinallyInvoice,
      refetchAllRequest,
      fetchRelatedInvoice,
      fetchSupplier,
    },
  };
  return (
    <AddInvoicesContext.Provider value={contextValue}>
      {children}
      <AddInvoice />
    </AddInvoicesContext.Provider>
  );
};

export const useAddInvoices = () => useContext(AddInvoicesContext);
