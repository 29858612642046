import { Col } from "antd";
import { ColumnsType } from "antd/es/table";
import { FC, HTMLAttributes, useState } from "react";
import { SPopconfirm } from "src/components/UiKit/PopConfirm";
import { TableUI } from "src/components/UiKit/table";
import { getRowKey } from "src/helpers/getRowKey";
import { IRequester } from "src/services/Public/models";
import { AddNewRequesterTableContainer } from "../style";

import { useRequester } from "../context/context";
import { Guard } from "src/components/Guard";
import {
  DeleteRequesterPath,
  EditRequesterPath,
} from "src/services/Ship/guardPath";
import { IPagination } from "src/models/interfaces/pagination";
interface IRenderTableValue extends HTMLAttributes<HTMLDivElement> {
  text: string | number;
}

const Tools: FC<{ onDelete: () => void; onEdit: () => void }> = ({
  onDelete,
  onEdit,
}) => {
  return (
    <div className="flex justify-center items-center gap-[16px] text-[16px]">
      <Guard action={EditRequesterPath}>
        <span
          onClick={onEdit}
          className="material-icons !text-[#01C18F] text-[16px] cursor-pointer"
        >
          edit_note
        </span>
      </Guard>
      <Guard action={DeleteRequesterPath}>
        <SPopconfirm
          description="Are you sure you want to Delete this supplier?"
          onConfirm={onDelete}
          deleteClassName
        >
          <div>
            <span className="material-icons !text-[#FF1414] text-[16px] cursor-pointer">
              delete_forever
            </span>
          </div>
        </SPopconfirm>
      </Guard>
    </div>
  );
};

const RenderTableValue: FC<IRenderTableValue> = (props) => {
  return (
    <div
      className="text-center text-[14px] text-[#5D6881] font-[400]"
      {...props}
    >
      {props.text}
    </div>
  );
};

interface IAddNewRequesterTableProps {
  tableData: IRequester[];
  onDelete: (id: number) => void;
  loading: boolean | undefined;
}
export const AddNewRequesterTable: FC<IAddNewRequesterTableProps> = ({
  tableData,
  onDelete,
  loading,
}) => {
  const {
    dispatch: { setEditMode, setRequesterData },
    form: { formControllerAdd },
  } = useRequester()!;
  const [pagination, setPagination] = useState<IPagination>({
    Offset: 1,
    Limit: 10,
  });
  const columns: ColumnsType<IRequester> = [
    {
      title: "#",
      dataIndex: "index",
      key: "index",
      className: "!font-bold !text-center",
      width: 56,
      render: (text, record, index) => (
        <RenderTableValue
          text={(pagination.Offset - 1) * pagination.Limit + (index + 1)}
        />
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 502,
      className: "!font-bold text-center",
      render: (text) => <RenderTableValue text={text} />,
    },
    {
      title: "Abbreviation",
      dataIndex: "acronym",
      key: "acronym",
      width: 350,
      className: "!font-bold  !text-center",
      render: (text) => <RenderTableValue text={text} />,
    },
    {
      title: "Tools",
      dataIndex: "tools",
      key: "tools",
      width: 120,
      className: "!font-bold  !text-center",
      render: (text, record) => (
        <Tools
          onDelete={() => onDelete(record.id)}
          onEdit={() => onEdit(record)}
        />
      ),
    },
  ];
  const onEdit = (record: IRequester) => {
    setEditMode(true);
    setRequesterData(record);
    formControllerAdd?.setFieldsValue(record);
    formControllerAdd?.setFieldValue("acronymName", record.acronym);
  };
  return (
    <AddNewRequesterTableContainer>
      <Col span={24}>
        <TableUI
          columns={columns}
          dataSource={tableData}
          scroll={{ x: "auto" }}
          pagination={{
            position: ["bottomCenter"],
            onChange: (page, pageSize) => {
              setPagination((prev) => ({
                ...prev,
                Offset: pageSize !== prev.Limit ? 1 : page,
                Limit: pageSize,
              }));
            },
          }}
          loading={loading}
          rowKey={getRowKey}
        />
      </Col>
    </AddNewRequesterTableContainer>
  );
};
