export enum IShipManagementTabs {
  addNewShipManagement = "1",
  shipManagementTable = "2",
}
export interface IDataSource {
  key: number;
  companyName: string;
  abbreviation: string;
  rank: string;
  email: string;
  phoneNumber: string;
  country: string;
  city: string;
  budgetContract: string;
  agreementStartDate: string;
  agreementEndDate: string;
  website?: string;
}
