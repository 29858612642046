import { FC } from "react";
import { Button, Divider } from "antd";
import { BigCardContainer } from "../styles/bigCard.styles";
import { IShip } from "src/services/Public/models";
import SkeletonImage from "antd/es/skeleton/Image";
import { Guard } from "src/components/Guard";
import { AllRequestPath } from "src/services/Invoice/guardPath";
interface IProps extends IShip {
  title: string;
  addInvoiceFn: () => void;
}
export const BigCard: FC<IProps> = ({ title, addInvoiceFn, name, image }) => {
  return (
    <BigCardContainer
      styles={{
        body: { width: 245, display: "flex", flexDirection: "column" },
      }}
      hoverable
      cover={
        image ? (
          <img
            height={160}
            className="imageContainer"
            alt="ship"
            src={
              image === "noImage" ? "./assets/images/littleCardShip.svg" : image
            }
          />
        ) : (
          <SkeletonImage style={{ height: 160, width: "100%" }} active />
        )
      }
    >
      <div className="flex flex-col">
        <div className="flex flex-row  px[3px] items-center">
          <div className="py-[6px] ps-[15px] shipsName">Ship name</div>
          <Divider type="vertical" dashed className="divider1" />
          <div className="py-[6px] titleContainer  !w-[42px]"> {title}</div>
        </div>
        <div className="flex flex-row  px[3px] items-center">
          <div className="shipManagement w-full !items-center !text-[13px]">
            {name}
          </div>
        </div>
        {/* <div className="flex flex-row  px[3px] items-center">
        <div className="shipManagement w-[150px]">ship Management</div>
        <Divider type="vertical" dashed className="divider2" />
        <div className="shipManagement !px-0 !w-[42px]">OMGA</div>
      </div> */}
      </div>
      <Guard action={AllRequestPath}>
        <Button
          className="addInvoiceContainer mb-[16px]"
          onClick={addInvoiceFn}
          type="link"
        >
          Add invoice
        </Button>
      </Guard>
    </BigCardContainer>
  );
};
