import { FC } from "react";
import { EditNoonReport } from "./components/EditNoonReport";
import { useNoonReportCTX } from "./context";
import { ViewNoonReport } from "./components/ViewNoonReport";
import { Header } from "./components/Header";

export const NoonReport: FC = () => {
  const {
    value: { mode },
  } = useNoonReportCTX();

  return mode === "edit" ? (
    <EditNoonReport />
  ) : mode === "view" ? (
    <ViewNoonReport />
  ) : (
    <Header />
  );
};
