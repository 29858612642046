import { Row } from "antd";
import styled from "styled-components";

export const EditBaseInformationContainer = styled(Row)`
  margin-top: 8px;
  padding: 0 32px;
  .title {
    color: var(--text, #001377);
    font-family: "DM Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 24px;
  }
  .relatedInvoice {
    color: #001377;
    font-family: "DM Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .ant-select-disabled {
    .ant-select-selector {
      background: #dadeec !important;
    }
  }
`;
