import { Tabs, TabsProps } from "antd";
import { FC } from "react";
import { IAddNewShip } from "../../models";
import { AddNewShipContainer } from "../../styles";
import { BasicInformation } from "./components/BasicInformation";
import { TechnicalInformation } from "./components/TechnicalInformation";
import { Owner } from "./components/Owner";
import { Insurances } from "./components/Insurances";
import { useAddShip } from "../../context";
import { ShipsPicture } from "./components/ShipsPicture";
import { ShipPrice } from "./components/ShipPrice";
import { ShipManagement } from "./components/ShipManagement";

export const AddNewShip: FC = () => {
  const {
    value: { activeKeyAddShip },
    dispatch: { setActiveKeyAddShip },
  } = useAddShip()!;

  const items: TabsProps["items"] = [
    {
      key: IAddNewShip.basicInformation,
      label: "Basic Information",
      children: <BasicInformation />,
      disabled: activeKeyAddShip !== IAddNewShip.basicInformation,
    },
    {
      key: IAddNewShip.shipsPicture,
      label: "Ships picture",
      children: <ShipsPicture />,
      disabled: activeKeyAddShip !== IAddNewShip.shipsPicture,
    },
    {
      key: IAddNewShip.technicalInformation,
      label: "Technical information",
      children: <TechnicalInformation />,
      disabled: activeKeyAddShip !== IAddNewShip.technicalInformation,
    },
    {
      key: IAddNewShip.Owner,
      label: "Owner",
      children: <Owner />,
      disabled: activeKeyAddShip !== IAddNewShip.Owner,
    },
    {
      key: IAddNewShip.ShipManagement,
      label: "Ship Management contract",
      children: <ShipManagement />,
      disabled: activeKeyAddShip !== IAddNewShip.Owner,
    },
    {
      key: IAddNewShip.shipPrice,
      label: "Ship price",
      children: <ShipPrice />,
      disabled: activeKeyAddShip !== IAddNewShip.Owner,
    },
    {
      key: IAddNewShip.insurances,
      label: "insurances",
      children: <Insurances />,
      disabled: activeKeyAddShip !== IAddNewShip.insurances,
      className: "!h-[680px]",
    },
    // {
    //   key: IAddNewShip.documentationFile,
    //   label: "Documentation file",
    //   children: <DocumentationFile />,
    //   //   disabled: activeKeyAddShip !== IAddNewShip.documentationFile,
    // },
  ];

  const onChange = (key: string) => {
    setActiveKeyAddShip(key);
  };

  return (
    <AddNewShipContainer>
      <Tabs
        items={items}
        onChange={onChange}
        activeKey={activeKeyAddShip}
        animated
      />
    </AddNewShipContainer>
  );
};
