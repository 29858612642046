import { Row } from "antd";
import { styled } from "styled-components";

export const UploadEditedZipContainer = styled(Row)`
  margin-top: 8px;
  .attachFile {
    display: flex;
    padding: 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
    /* border-radius: 8px;
    border: 1px dashed #dadeec; */
  }
  .attachFileLabel {
    color: #001377;
    font-family: DM Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .ant-row {
    padding: 0 !important;
  }
  .ant-upload {
    width: 66px !important;
    height: 66px !important;
  }
  .ant-upload-list-item-container {
    width: 66px !important;
    height: 66px !important;
  }
`;
