import {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { Form, message } from "antd";
import { FormInstance } from "antd/lib";
import {
  IEditLOROBConsArgs,
  ILOROBCons,
  ILOROBConsArgs,
} from "src/services/NoonReport/models";
import { useNoonReportCTX } from "src/modules/NoonReport/context";
import { NoonReportService } from "src/services/NoonReport/NoonReport.service";
import { useAddNewNoonReportCTX } from "../../context";
import { NewNoonReportTabs } from "../../model/newNoonReportTabs";
interface IContext {
  value: {
    loading: boolean;
    formName: FormInstance;
    dataSource: ILOROBCons[];
    dataLoading: boolean;
    selectedRow: ILOROBCons | undefined;
  };
  dispatch: {
    setSelectedRow: Dispatch<SetStateAction<ILOROBCons | undefined>>;
  };
  func: {
    onFinishForm: (values: ILOROBConsArgs) => void;
    reset: () => void;
  };
}

export const LOROBConsInfoCTX = createContext<IContext | undefined>(undefined);
export const LOROBConsInfoProvider: FC<PropsWithChildren> = ({ children }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [dataSource, setDataSource] = useState<ILOROBCons[]>([]);
  const [dataLoading, setDataLoading] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<ILOROBCons | undefined>(
    undefined
  );
  const {
    value: { activeTab },
  } = useAddNewNoonReportCTX();

  const {
    value: { selectedReport },
  } = useNoonReportCTX();

  const [formName] = Form.useForm();

  const onFinishForm = async (values: ILOROBConsArgs) => {
    if (selectedRow) editData(values);
    else addData(values);
  };

  const addData = async (values: ILOROBConsArgs) => {
    if (!selectedReport) return;
    setLoading(true);
    try {
      const { NewLOROBCons } = new NoonReportService();
      const response = await NewLOROBCons(selectedReport.id, values);
      if (response && response.status === 200) {
        message.success("New LO, ROB, Cons created successfully.");
        getLOROBCons();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const editData = async (values: ILOROBConsArgs) => {
    if (!selectedRow) return;
    const editBody: IEditLOROBConsArgs = {
      noonReportId: selectedRow.id,
      ...values,
    };
    setLoading(true);
    try {
      const { EditLOROBCons } = new NoonReportService();
      const response = await EditLOROBCons(selectedRow.id, editBody);
      if (response && response.status === 200) {
        message.success("LO, ROB, Cons edited successfully.");
        getLOROBCons();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const getLOROBCons = useCallback(async () => {
    if (!selectedReport) return;
    try {
      const { GetLOROBCons } = new NoonReportService();
      const response = await GetLOROBCons(selectedReport.id);
      if (response && response.status === 200 && response.data) {
        setDataSource(response.data);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setDataLoading(false);
      setDataSource(dumpyData);
    }
  }, [selectedReport]);

  const reset = useCallback(() => {
    setSelectedRow(undefined);
    formName.resetFields();
  }, [formName]);

  useEffect(() => {
    if (activeTab === NewNoonReportTabs.LOROBCons) reset();
  }, [activeTab, reset]);

  useEffect(() => {
    if (selectedRow)
      formName.setFieldsValue({
        type: selectedRow.type,
        Cons: selectedRow.Cons,
        ROB: selectedRow.ROB,
      });
  }, [formName, selectedRow]);

  useEffect(() => {
    if (activeTab === NewNoonReportTabs.LOROBCons && selectedReport) {
      getLOROBCons();
    }
  }, [activeTab, getLOROBCons, selectedReport]);

  const contextValue: IContext = {
    value: { loading, formName, dataSource, dataLoading, selectedRow },
    dispatch: { setSelectedRow },
    func: {
      onFinishForm,
      reset,
    },
  };

  return (
    <LOROBConsInfoCTX.Provider value={contextValue}>
      {children}
    </LOROBConsInfoCTX.Provider>
  );
};

export const useLOROBConsInfo = () => useContext(LOROBConsInfoCTX)!;

const dumpyData: ILOROBCons[] = [
  { id: 1, noonReportId: 1, type: 1, Cons: "dassff ggg", ROB: "700" },
  { id: 2, noonReportId: 2, type: 3, Cons: "hjut", ROB: "400" },
];
