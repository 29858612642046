import { styled } from "styled-components";

export const Container = styled.div`
  padding: 16px 24px;
  background-color: #fff;
  border-radius: 12px;
  width: 100%;

  .tabs-list {
    .tab-item {
      &:not(.active) .tab-button {
        color: #a3aed0 !important;
      }

      &.active .tab-button {
        color: #001377 !important;
      }
    }

    .tab-button {
      padding: 12px 16px;
      font-size: 16px;
      font-weight: 700;
      line-height: 21px;
      letter-spacing: -0.02em;
      gap: 8px;

      .icon {
        font-size: 16px;
      }
    }
  }
`;
