import { FC, Suspense } from "react";
import { Category } from "src/modules/Category";
import { CategoryProvider } from "src/modules/Category/context";
import { PreLoading } from "src/routes/Loading";

export const CategoryPage: FC = () => {
  return (
    <Suspense fallback={<PreLoading />}>
      <CategoryProvider>
        <Category />
      </CategoryProvider>
    </Suspense>
  );
};
