export enum IncomeUrls {
  income = "Income/",
  NewPayer = "Income/NewPayer",
  AllPayers = "Income/AllPayers",
  DeletePayer = "/DeletePayer",
  UpdatePayer = "/UpdatePayer",
  NewReceiver = "Income/NewReceiver",
  AllReceivers = "Income/AllReceivers",
  DeleteReceiver = "/DeleteReceiver",
  UpdateReceiver = "/UpdateReceiver",
  NewIncome = "Income/NewIncome",
  AllIncomes = "Income/AllIncomes",
  DeleteIncome = "/DeleteIncome",
  UpdateIncome = "/UpdateIncome",
  AllVoyageIncomes = "Income/AllVoyageIncomes",
  NewIncomeAttachmentDetail = "/NewIncomeAttachmentDetail",
  AllIncomeAttachmentDetails = "/AllIncomeAttachmentDetails",
  DeleteIncomeAttachmentDetail = "/DeleteIncomeAttachmentDetail",
  UpdateIncomeAttachmentDetail = "/UpdateIncomeAttachmentDetail",
  NewIncomeAttachment = "/NewIncomeAttachment",
  GetIncomeAttachment = "/GetIncomeAttachment",
  UpdateIncomeFile = "/UpdateIncomeFile",
  DeleteIncomeAttachment = "/DeleteIncomeAttachment",
}
