import styled from "styled-components";

export const NewProfitContent = styled.div`
  display: flex;
  padding: 24px;
  flex-direction: column;
  /* align-items: center; */
  gap: 24px;
  align-self: stretch;
  border-radius: 0px 0px 20px 20px;
  background: var(--color-Base-Color-White, #fff);
  box-shadow: 0px 4px 6px 0px rgba(113, 130, 183, 0.25);
  min-height: 644px;
`;
