import { FC } from "react";
import useOwnerStore from "src/store/owner";
import { useGetDailyReport } from "../../hook";
import { CargosStyle } from "./style";
import { ReportComponent } from "../ReportComponent";

export const Cargos: FC = () => {
  const { dailyReport } = useOwnerStore();
  useGetDailyReport();
  return (
    <CargosStyle>
      <ReportComponent title="Cargo" content={dailyReport?.cargoDescription} />
    </CargosStyle>
  );
};
