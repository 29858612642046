import { Form, FormInstance, message } from "antd";
import {
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useContext,
  useState,
} from "react";
import { IGetCountry, INewCountry } from "src/services/System/models";
import { SystemService } from "src/services/System/System.service";
import { ICountryTabs } from "../models";

interface ICountryContext {
  value: {
    tableLoading: boolean;
    tableData: IGetCountry[] | undefined;
    addCountryLoading: boolean;
    editMode: boolean;
    countryId: number | undefined;
    editData: IGetCountry | undefined;
    editLoading: boolean;
    activeKey: string;
  };
  dispatch: {
    setTableLoading: Dispatch<SetStateAction<boolean>>;
    setTableData: Dispatch<SetStateAction<IGetCountry[]>>;
    setAddCountryLoading: Dispatch<SetStateAction<boolean>>;
    setEditMode: Dispatch<SetStateAction<boolean>>;
    setCountryId: Dispatch<SetStateAction<number | undefined>>;
    setEditData: Dispatch<SetStateAction<IGetCountry | undefined>>;
    setEditLoading: Dispatch<SetStateAction<boolean>>;
    setActiveKey: Dispatch<SetStateAction<string>>;
  };
  func: { getCountry: () => void; onFinish: (values: INewCountry) => void };
  form: { countryForm: FormInstance<any> | undefined };
}

const defaultCtxValue: ICountryContext = {
  value: {
    tableLoading: false,
    tableData: [],
    addCountryLoading: false,
    editMode: false,
    countryId: undefined,
    editData: undefined,
    editLoading: false,
    activeKey: "",
  },
  dispatch: {
    setTableLoading: () => {},
    setTableData: () => {},
    setAddCountryLoading: () => {},
    setEditMode: () => {},
    setCountryId: () => {},
    setEditData: () => {},
    setEditLoading: () => {},
    setActiveKey: () => {},
  },
  func: { getCountry: () => {}, onFinish: () => {} },
  form: { countryForm: undefined },
};

export const CountryContext = createContext<ICountryContext>(defaultCtxValue);

export const CountryProvider: FC<PropsWithChildren> = ({ children }) => {
  //-----------------------form---------------------

  const [countryForm] = Form.useForm();

  //-----------------------state--------------------

  const [tableLoading, setTableLoading] = useState<boolean>(false);

  const [tableData, setTableData] = useState<IGetCountry[]>([]);

  const [addCountryLoading, setAddCountryLoading] = useState<boolean>(false);

  const [editMode, setEditMode] = useState<boolean>(false);

  const [countryId, setCountryId] = useState<number | undefined>(undefined);

  const [editData, setEditData] = useState<IGetCountry | undefined>(undefined);

  const [editLoading, setEditLoading] = useState<boolean>(false);

  const [activeKey, setActiveKey] = useState<string>(
    ICountryTabs.addNewCountry || ICountryTabs.countryTable
  );

  //-----------------------func---------------------

  const getCountry = useCallback(async () => {
    setTableLoading(true);
    try {
      const { GetCountry } = new SystemService();
      const response = await GetCountry();
      if (response && response.status === 200) {
        setTableData(response.data);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setTableLoading(false);
    }
  }, []);

  const onFinish = async (values: INewCountry) => {
    if (editMode && countryId) {
      try {
        setEditLoading(true);
        const { EditCountry } = new SystemService();
        const response = await EditCountry(countryId, values);
        if (response && response.status === 200) {
          message.success("Edit country successfully");
          getCountry();
          setEditMode(false);
          setActiveKey(ICountryTabs.countryTable);
          setCountryId(undefined);
        }
      } catch (err) {
        console.log(err);
      } finally {
        setEditLoading(false);
      }
    } else {
      try {
        setAddCountryLoading(true);
        const { NewCountry } = new SystemService();
        const response = await NewCountry(values);
        if (response && response.status === 200) {
          message.success("Country created successfully");
          getCountry();
          countryForm.resetFields();
        }
      } catch (error) {
        console.log(error);
      } finally {
        setAddCountryLoading(false);
        countryForm?.resetFields();
      }
    }
  };

  const contextValue: ICountryContext = {
    value: {
      tableLoading,
      tableData,
      addCountryLoading,
      editMode,
      countryId,
      editData,
      editLoading,
      activeKey,
    },
    dispatch: {
      setTableLoading,
      setTableData,
      setAddCountryLoading,
      setEditMode,
      setCountryId,
      setEditData,
      setEditLoading,
      setActiveKey,
    },
    func: { getCountry, onFinish },
    form: { countryForm },
  };

  return (
    <CountryContext.Provider value={contextValue}>
      {children}
    </CountryContext.Provider>
  );
};

export const useCountry = () => useContext(CountryContext);
