import { Row } from "antd";
import styled from "styled-components";

export const OwnerDashboardContentContainer = styled(Row)`
  background: var(--3, #f2f7ff);
  width: 100vw;
  height: calc(100vh - 99px);
  z-index: 1;
  padding: 24px 16px;

  .ant-layout-sider,
  .ant-menu {
    background: var(--3, #f2f7ff);
    height: 100vh;
    overflow-y: auto;
  }

  .ant-menu-item {
    height: 45px;
  }
  .ant-menu-item-selected {
    background-color: #2c73fc;

    a,
    .material-icons {
      color: #fff !important;
    }
  }

  .header {
    margin-bottom: 32px;
    .year {
      color: var(--text-2, #a3aed0);
      font-family: Plus Jakarta Sans;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    .month {
      color: var(--text, #001377);
      font-family: Plus Jakarta Sans;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    .material-icons {
      color: var(--text, #001377);
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
  .footer-button {
    color: var(--2, #2c73fc);
    font-family: Plus Jakarta Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    cursor: pointer;
    user-select: none;
  }
  .footer-button-disabled {
    color: var(--shadow, #dadeec);
    font-family: Plus Jakarta Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    cursor: not-allowed;
  }
`;
