import { FC } from "react";
import { SpeedDistanceProvider } from "./context";
import { SpeedDistanceForm } from "./SpeedDistanceForm";

export const SpeedDistanceInfo: FC = () => {
  return (
    <SpeedDistanceProvider>
      <SpeedDistanceForm />
    </SpeedDistanceProvider>
  );
};
