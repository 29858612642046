import { FC, useCallback, useEffect, useState } from "react";
import { FlowTableContainer, ToolsContainer } from "./styles";
import { TableUI } from "src/components/UiKit/table";
import { ColumnProps } from "antd/lib/table";
import { Popconfirm, Tooltip } from "antd";
import { useFlowCTX } from "../../context";
import { getRowKey } from "src/helpers/getRowKey";
import { IWorkFlows } from "src/services/WorkFlow/models";
import { WorkFlowService } from "src/services/WorkFlow/WorkFlow.service";
import { FlowSections } from "../AddFlow/model/sectionTypes";
import { useAddNewFlowCTX } from "../AddFlow/context";
import { ActiveContent } from "../AddFlow/model/activeContent";
import { Guard } from "src/components/Guard";
import {
  DeleteWorkFlowPath,
  EditWorkFlowPath,
  EditWorkFlowStepPath,
  UpdateWorkFlowStepActionPath,
  WorkFlowPath,
} from "src/services/WorkFlow/guardPath";
interface IPagination {
  total: number;
  pageSize: number;
  currentPage: number;
}
export const FlowTable: FC = () => {
  const [dataSource, setDataSource] = useState<IWorkFlows[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [pagination, setPagination] = useState<IPagination>({
    total: 10,
    currentPage: 1,
    pageSize: 10,
  });
  const getWorkflow = useCallback(async () => {
    try {
      setLoading(true);
      const { GetWorkFlows } = new WorkFlowService();
      const res = await GetWorkFlows({
        Offset: pagination.currentPage.toString(),
        Limit: pagination.pageSize.toString(),
      });
      if (res && res.status === 200) {
        setDataSource(res.data.records);
        setPagination((prev) => ({ ...prev, total: res.data.count }));
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }, [pagination.currentPage, pagination.pageSize]);

  const columns: ColumnProps<IWorkFlows>[] = [
    {
      dataIndex: "#",
      key: "#",
      title: "#",
      render: (text, record, index) =>
        (pagination.currentPage - 1) * pagination.pageSize + (index + 1),
    },
    { dataIndex: "name", key: "name", title: "Flow name" },
    {
      dataIndex: "workFlowSectionType",
      key: "workFlowSectionType",
      title: "Section",
      render: (text) => {
        switch (text) {
          case FlowSections.start:
            return "Start";
          case FlowSections.inprogress:
            return "In progress";
          case FlowSections.inprogressEnd:
            return "In progress / End";
          case FlowSections.end:
            return "End";
          default:
            return "";
        }
      },
    },
    { dataIndex: "stepNumber", key: "stepNumber", title: "Step No." },
    {
      dataIndex: "tools",
      key: "tools",
      title: "Tools",
      render: (value, record) => (
        <Tools record={record} refetchTable={getWorkflow} />
      ),
    },
  ];

  useEffect(() => {
    getWorkflow();
  }, [getWorkflow]);
  return (
    <FlowTableContainer>
      <TableUI
        columns={columns}
        dataSource={dataSource}
        loading={loading}
        pagination={{
          position: ["bottomCenter"],
          total: pagination.total,
          current: pagination.currentPage,
          pageSize: pagination.pageSize,
          showSizeChanger: true,
          onChange: (page, pageSize) => {
            setPagination((prev) => ({
              ...prev,
              currentPage: pageSize !== prev.pageSize ? 1 : page,
              pageSize,
            }));
          },
        }}
        rowKey={getRowKey}
      />
    </FlowTableContainer>
  );
};

const Tools: FC<{ record: IWorkFlows; refetchTable: () => void }> = ({
  record,
  refetchTable,
}) => {
  const {
    func: { changeMode, OnChangeState },
  } = useFlowCTX();
  const {
    dispatch: { setNewWorkFlowId, setEditWorkFlowInfo },
    forms: { FlowInfoForm },
  } = useAddNewFlowCTX();
  const onDelete = async () => {
    try {
      const { DeleteWorkFlow } = new WorkFlowService();
      const res = await DeleteWorkFlow(record.id);
      if (res && res.status === 200) {
        refetchTable();
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <ToolsContainer>
      <Guard action={WorkFlowPath}>
        <Tooltip title="View">
          <span
            className="material-icons text-[#2C73FC]"
            onClick={() => {
              changeMode("view");
              OnChangeState(ActiveContent.Center);
              setNewWorkFlowId(record.id);
              setEditWorkFlowInfo(record);
              FlowInfoForm?.setFieldsValue(record);
            }}
          >
            schema
          </span>
        </Tooltip>
      </Guard>
      <Guard
        action={[
          EditWorkFlowPath,
          EditWorkFlowStepPath,
          UpdateWorkFlowStepActionPath,
        ]}
      >
        <Tooltip title="Edit">
          <span
            className="material-icons text-[#1DCF0E]"
            onClick={() => {
              OnChangeState(ActiveContent.Center);

              changeMode("edit");
              setNewWorkFlowId(record.id);
              setEditWorkFlowInfo(record);
              FlowInfoForm?.setFieldsValue(record);
            }}
          >
            edit_note
          </span>
        </Tooltip>
      </Guard>
      <Guard action={DeleteWorkFlowPath}>
        <Tooltip title="Delete">
          <Popconfirm
            title="Delete flow"
            description="Are you sure you want to delete this flow?"
            rootClassName="deletePopconfirm"
            icon={null}
            onConfirm={onDelete}
          >
            <span className="material-icons text-[#FF1414]">
              delete_forever
            </span>
          </Popconfirm>
        </Tooltip>
      </Guard>
    </ToolsContainer>
  );
};
