import { FC } from "react";
import { Layout, Skeleton } from "antd";
import { IAdminPanelLayoutProps } from "./interface";
import { ContentContainer } from "./styles/content";
import Header from "./components/header";
import Sidebar from "./components/sidebar";
import { LayoutContainer } from "./styles";
import { Outlet, useLocation } from "react-router-dom";
import { AppContextProviders } from "src/components/AppContextProviders";

const { Content } = Layout;

export const AdminPanelLayout: FC<IAdminPanelLayoutProps> = ({ children }) => {
  const { pathname } = useLocation();

  return (
    <AppContextProviders>
      <Skeleton loading={false} active avatar>
        <LayoutContainer path={pathname}>
          <Layout className="layout">
            <Sidebar />
            <Layout className="contentWrapper">
              <Header />
              <Content>
                <ContentContainer path={pathname}>
                  <Outlet />
                </ContentContainer>
              </Content>
            </Layout>
          </Layout>
        </LayoutContainer>
      </Skeleton>
    </AppContextProviders>
  );
};
