export enum SystemUrls {
  NewCategory = "System/NewCategory",
  AllCategory = "System/AllCategory",
  NewRole = "System/NewRole",
  System = "System/",
  AllSubCategory = "/AllSubCategory",
  AllActions = "System/AllActions",
  RolesOfAction = "/RolesOfAction",
  AssignRoleToAction = "System/AssignRoleToAction",
  TreeCategory = "System/TreeCategory",
  DeleteCategory = "/DeleteCategory",
  EditCategory = "/EditCategory",
  NewFrontPath = "System/NewFrontPath",
  GetFrontPath = "System/GetFrontPath",
  EditFrontPath = "/EditFrontPath",
  DeleteFrontPath = "/DeleteFrontPath",
  GetRoleOfFrontPath = "/GetRoleOfFrontPath",
  AddRolToFrontPath = "System/AddRolToFrontPath",
  DeleteFrontPathAccess = "/DeleteFrontPathAccess",
  GetCountry = "System/GetCountry",
  NewCountry = "System/NewCountry",
  EditCountry = "/EditCountry",
  NewCurrency = "System/NewCurrency",
  EditCurrency = "/EditCurrency",
  NewSupplier = "System/NewSupplier",
  EditSupplier = "/EditSupplier",
  DeletetSupplier = "/DeletetSupplier",
  GetSupplier = "System/GetSupplier",
}
