import { Col } from "antd";
import { FC } from "react";
import { EditedAttachZipFileContainer } from "../../styles/attachZipFile.styles";
import { UploadEditedZip } from "./uploadZip";
import { UploadEditedScreenshot } from "./uploadScreenshot";

export const AttachZipFile: FC = () => {
  return (
    <EditedAttachZipFileContainer gutter={32}>
      <Col span={24} className="flex justify-center !p-0 ">
        <div className="w-[96%] h-[208px] border-dashed border-[#DADEEC] rounded-lg border-[1px] overflow-x-auto">
          <UploadEditedZip />
        </div>
      </Col>
      <Col span={24} className="flex justify-center !p-0">
        <div className="w-[96%] h-[208px] border-dashed border-[#DADEEC] rounded-lg border-[1px]	overflow-x-auto">
          <UploadEditedScreenshot />
        </div>
      </Col>
    </EditedAttachZipFileContainer>
  );
};
