import { Suspense } from "react";
import Login from "src/modules/Login";
import { PreLoading } from "src/routes/Loading";

const LoginPage = () => (
  <Suspense fallback={<PreLoading />}>
    <Login />
  </Suspense>
);
export default LoginPage;
