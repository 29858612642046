import { Tabs, TabsProps } from "antd";
import { FC } from "react";
import { UseAgents } from "src/modules/agents/context";
import { IAddNewAgentTabs } from "src/modules/agents/models";
import { AddNewAgentTabs } from "src/modules/agents/style";
import { BasicInfoEdit } from "./basicInfoEdit";
import { ContactInfoEdit } from "./contactInfoEdit";

export const AgentEditTabs: FC = () => {
  const {
    value: { activeKeyAddAgent },
    dispatch: { setActiveKeyAddAgent },
  } = UseAgents()!;
  const item: TabsProps["items"] = [
    {
      key: IAddNewAgentTabs.basicInformation,
      label: "Basic Information",
      children: <BasicInfoEdit />,
    },
    {
      key: IAddNewAgentTabs.contactInformation,
      label: "Contact Information",
      children: <ContactInfoEdit />,
    },
  ];
  const onChange = (key: string) => {
    setActiveKeyAddAgent(key);
  };
  return (
    <AddNewAgentTabs>
      <Tabs
        items={item}
        activeKey={activeKeyAddAgent}
        onChange={onChange}
        animated
      />
    </AddNewAgentTabs>
  );
};
