import { Form } from "antd";
import styled from "styled-components";

export const FlowTableContainer = styled.div`
  .ant-table-cell {
    text-align: center !important;
  }
`;

export const ToolsContainer = styled.div`
  display: flex;
  gap: 16px;
  justify-content: center;
  .material-icons {
    font-size: 16px;
    cursor: pointer;
    user-select: none;
  }
`;

export const ShipTabContainer = styled.div`
  .checkboxGroup {
    border-top: 1px solid var(--strock, #f3f4f5);
    border-bottom: 1px solid var(--strock, #f3f4f5);
    overflow-y: auto;
  }
  .ant-checkbox-group {
    flex-wrap: unset !important;
  }
  .ant-input-affix-wrapper {
    border-radius: 8px;
    border: 1px solid var(--text-2, #a3aed0);
    background: var(--0, #fff);
  }
  .ant-checkbox-wrapper {
    color: var(--text-5, #3e4556);

    /* Body Text/Extra-Small/Body Extra-Small (Regular) */
    font-family: DM Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 166.667% */
    letter-spacing: -0.24px;
  }
`;

export const VoyageTabContainer = styled.div`
  .checkboxGroup {
    border-top: 1px solid var(--strock, #f3f4f5);
    border-bottom: 1px solid var(--strock, #f3f4f5);
    overflow-y: auto;
  }
  .ant-checkbox-wrapper {
    color: var(--text-5, #3e4556) !important;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 166.667% */
    letter-spacing: -0.24px;
  }
  .borderBottom {
    border-bottom: 1px solid var(--strock, #f3f4f5);
    padding-bottom: 12px;
  }
  .ant-form-item {
    margin-bottom: unset;
    min-height: 1px !important;
  }
  .ant-form-item-control-input {
    min-height: 1px;
  }
`;
export const ShipStateContainer = styled.div`
  .ant-checkbox-wrapper {
    color: var(--text-5, #3e4556);
    font-family: DM Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 166.667% */
    letter-spacing: -0.24px;
  }
  .checkboxGroup {
    border-bottom: 1px solid var(--strock, #f3f4f5);
  }
`;
export const DateContainer = styled(Form)`
  .dateTitle {
    color: var(--text, #001377);
    font-family: DM Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;
