import { Row } from "antd";
import styled from "styled-components";

export const AddNewRequesterContainer = styled.div`
  padding: 24px 32px;
  border-radius: 20px;
  background: var(--Secondary-Primary-White, #fff);
  /* height: 766px; */
  /* 2 */
  box-shadow: 0px 4px 6px 0px rgba(113, 130, 183, 0.25);
  .br {
    /* padding-bottom: 24px; */
    border-bottom: 1px solid #dadeec;
  }
  .titleHolder {
    color: var(--text, #001377);
    font-family: DM Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .footer {
    border-top: 1px solid var(--shadow, #dadeec);
    padding-top: 24px;
    flex-direction: row-reverse;
  }
  .tableTitle {
    color: #001377;
    font-family: DM Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding-top: 24px;
  }
  .efDKFW {
    padding: 24px 0px !important;
  }
  .footer {
    display: flex;
    justify-content: end;
    gap: 32px;
    width: 100%;
  }
  .saveButton {
    border: 1px solid #2c73fc;
    color: #2c73fc;
  }
  .cancelButton {
    border: 1px solid #4b4b4b;
    color: #4b4b4b;
    background: #ffff;
  }
  .saveEditButton {
    border: 1px solid #2c73fc;
    color: #fff;
    background: #2c73fc;
    width: 100px;
  }
`;

export const RequesterTableContainer = styled.div`
  .content {
    border-radius: 16px;
    background: #fff;
    /* width: 845px; */
    /* padding: 24px; */
  }
`;

export const EditRequesterContainer = styled.div`
  .borderBottom {
    border-bottom: 1px solid var(--shadow, #dadeec);
    margin-bottom: 24px;
  }
  .content {
    padding: 24px 32px;
    border-radius: 20px;
    background: var(--Secondary-Primary-White, #fff);

    /* 2 */
    box-shadow: 0px 4px 6px 0px rgba(113, 130, 183, 0.25);
  }
  .body {
    width: 100%;
    padding: 24px;
    border-radius: 20px;
    background: var(--secondary-primary-white, #fff);

    /* 2 */
    box-shadow: 0px 4px 6px 0px rgba(113, 130, 183, 0.25);
  }
  .saveButton {
    border: 1px solid #2c73fc;
    color: #2c73fc;
  }
  .cancelButton {
    border: 1px solid #4b4b4b;
    color: #4b4b4b;
    background: #ffff;
  }
`;
export const AddNewRequesterTableContainer = styled(Row)`
  .sc-Gqece {
    padding: 16px 0px 0px 0px !important;
  }
`;
