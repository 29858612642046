import { IPath } from "../Identity/models";

export const IncomePath: IPath[] = [
  {
    path: "api/Income/NewPayer",
    httpMethod: "POST",
  },
  {
    path: "api/Income/AllPayers",
    httpMethod: "GET",
  },
  {
    path: "api/Income/{PayerId}/DeletePayer",
    httpMethod: "DELETE",
  },
  {
    path: "api/Income/{PayerId}/UpdatePayer",
    httpMethod: "PUT",
  },
  {
    path: "api/Income/NewReceiver",
    httpMethod: "POST",
  },
  {
    path: "api/Income/AllReceivers",
    httpMethod: "GET",
  },
  {
    path: "api/Income/{ReceiverId}/DeleteReceiver",
    httpMethod: "DELETE",
  },
  {
    path: "api/Income/{ReceiverId}/UpdateReceiver",
    httpMethod: "PUT",
  },
  {
    path: "api/Income/NewIncome",
    httpMethod: "POST",
  },
  {
    path: "api/Income/AllIncomes",
    httpMethod: "GET",
  },
  {
    path: "api/Income/{IncomeId}/DeleteIncome",
    httpMethod: "DELETE",
  },
  {
    path: "api/Income/{IncomeId}/UpdateIncome",
    httpMethod: "PUT",
  },
  {
    path: "api/Income/AllVoyageIncomes",
    httpMethod: "GET",
  },
  {
    path: "api/Income/{IncomeId}/NewIncomeAttachmentDetail",
    httpMethod: "POST",
  },
  {
    path: "api/Income/{IncomeId}/AllIncomeAttachmentDetails",
    httpMethod: "GET",
  },
  {
    path: "api/Income/{IncomeAttachmentDetailId}/DeleteIncomeAttachmentDetail",
    httpMethod: "DELETE",
  },
  {
    path: "api/Income/{IncomeAttachmentDetailId}/UpdateIncomeAttachmentDetail",
    httpMethod: "PUT",
  },
  {
    path: "api/Income/{IncomeAttachmentDetailId}/NewIncomeAttachment",
    httpMethod: "POST",
  },
  {
    path: "api/Income/{IncomeAttachmentDetialId}/GetIncomeAttachment",
    httpMethod: "GET",
  },
  {
    path: "api/Income/{IncomeAttachmentDetailId}/UpdateIncomeFile",
    httpMethod: "PATCH",
  },
  {
    path: "api/Income/{IncomeAttachmentDetailId}/DeleteIncomeAttachment",
    httpMethod: "PATCH",
  },
];

export const NewPayerPath: IPath = {
  path: "api/Income/NewPayer",
  httpMethod: "POST",
};
export const AllPayersPath: IPath = {
  path: "api/Income/AllPayers",
  httpMethod: "GET",
};
export const DeletePayerPath: IPath = {
  path: "api/Income/{PayerId}/DeletePayer",
  httpMethod: "DELETE",
};
export const UpdatePayerPath: IPath = {
  path: "api/Income/{PayerId}/UpdatePayer",
  httpMethod: "PUT",
};
export const NewReceiverPath: IPath = {
  path: "api/Income/NewReceiver",
  httpMethod: "POST",
};
export const AllReceiversPath: IPath = {
  path: "api/Income/AllReceivers",
  httpMethod: "GET",
};
export const DeleteReceiverPath: IPath = {
  path: "api/Income/{ReceiverId}/DeleteReceiver",
  httpMethod: "DELETE",
};
export const UpdateReceiverPath: IPath = {
  path: "api/Income/{ReceiverId}/UpdateReceiver",
  httpMethod: "PUT",
};
export const NewIncomePath: IPath = {
  path: "api/Income/NewIncome",
  httpMethod: "POST",
};
export const AllIncomesPath: IPath = {
  path: "api/Income/AllIncomes",
  httpMethod: "GET",
};
export const DeleteIncomePath: IPath = {
  path: "api/Income/{IncomeId}/DeleteIncome",
  httpMethod: "DELETE",
};
export const UpdateIncomePath: IPath = {
  path: "api/Income/{IncomeId}/UpdateIncome",
  httpMethod: "PUT",
};
export const AllVoyageIncomesPath: IPath = {
  path: "api/Income/AllVoyageIncomes",
  httpMethod: "GET",
};
export const NewIncomeAttachmentDetailPath: IPath = {
  path: "api/Income/{IncomeId}/NewIncomeAttachmentDetail",
  httpMethod: "POST",
};
export const AllIncomeAttachmentDetailsPath: IPath = {
  path: "api/Income/{IncomeId}/AllIncomeAttachmentDetails",
  httpMethod: "GET",
};
export const DeleteIncomeAttachmentDetailPath: IPath = {
  path: "api/Income/{IncomeAttachmentDetailId}/DeleteIncomeAttachmentDetail",
  httpMethod: "DELETE",
};
export const UpdateIncomeAttachmentDetailPath: IPath = {
  path: "api/Income/{IncomeAttachmentDetailId}/UpdateIncomeAttachmentDetail",
  httpMethod: "PUT",
};
export const NewIncomeAttachmentPath: IPath = {
  path: "api/Income/{IncomeAttachmentDetailId}/NewIncomeAttachment",
  httpMethod: "POST",
};
export const GetIncomeAttachmentPath: IPath = {
  path: "api/Income/{IncomeAttachmentDetialId}/GetIncomeAttachment",
  httpMethod: "GET",
};
export const UpdateIncomeFilePath: IPath = {
  path: "api/Income/{IncomeAttachmentDetailId}/UpdateIncomeFile",
  httpMethod: "PATCH",
};
export const DeleteIncomeAttachmentPath: IPath = {
  path: "api/Income/{IncomeAttachmentDetailId}/DeleteIncomeAttachment",
  httpMethod: "PATCH",
};
