import { Row } from "antd";
import styled from "styled-components";

export const FrontAccessTableContainer = styled(Row)`
  padding: 24px 16px 32px 16px;
  gap: 16px;

  .titleHolder {
    color: var(--sidebar-icon, #a3aed0);
    font-family: DM Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .shipHolder {
    color: var(--1, #5d6881);
    font-family: DM Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .border {
    border-bottom: 1px solid #dadeec;
  }
  .ant-table-wrapper .ant-table-thead > tr > th {
    color: #1b2559;
    font-weight: 700;
    font-size: 14px;
  }
  .ant-table-wrapper .ant-table-tbody > tr > td.ant-table-cell {
    color: #5d6881;
    font-weight: 400;
    font-size: 14px;
  }
`;
