import { Button, Modal } from "antd";
import { FC } from "react";
import { DeletePopupModalContainer } from "src/modules/editZipFileInformation/styles/deletePopupModal";

interface IProps {
  onConfirmDelete: () => void;
  show: boolean;
  onCancel: () => void;
  uniqueCode?: string;
}
export const DeleteAllRequest: FC<IProps> = ({
  onConfirmDelete,
  onCancel,
  show,
  uniqueCode,
}) => {
  return (
    <Modal
      width={"490px"}
      centered
      open={show}
      closable={false}
      maskClosable={false}
      onCancel={() => {
        onCancel();
      }}
      title={uniqueCode}
      styles={{ body: { height: "148px" } }}
      footer={
        <div className="flex gap-[16px] justify-center">
          <Button
            onClick={() => {
              onCancel();
            }}
            htmlType="button"
            icon={<span className="material-icons"> close</span>}
            className="flex justify-center items-center rounded-[16px] "
          >
            Cancel
          </Button>
          <Button
            type="primary"
            danger
            htmlType="button"
            icon={
              <span className="material-symbols-outlined">delete_forever</span>
            }
            className="flex justify-center items-center rounded-[16px] "
            onClick={() => {
              onConfirmDelete();
            }}
          >
            Delete
          </Button>
        </div>
      }
    >
      <DeletePopupModalContainer className="flex justify-center my-[32px]">
        <div className="p-[47px] content">
          Are you sure you want to delete this Request?
        </div>
      </DeletePopupModalContainer>
    </Modal>
  );
};
