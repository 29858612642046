import { styled } from "styled-components";

export const LittleCardContainer = styled.div`
  width: 370px;
  border-radius: 20px;
  background: #fff;
  min-height: 97px;
  display: flex;
  align-items: center;
  gap: 18px;
  padding: 16px;
  .iconContainer {
    background: #f6f8fd;
    width: 56px !important;
    height: 56px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .titleHolder {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .desContainer {
    color: var(--text-2, #a3aed0);
    font-family: DM Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: -0.24px;
  }
  .titleContainer {
    font-weight: 700;
    line-height: 28px;
  }
  .material-icons {
    color: #2c73fc;
  }
  .addButton {
    border-radius: 10px;
    background: #e4f2ff;
    width: 44px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .addWrapper {
    transform: translateY(8px);
  }
`;
