export enum ProfitUrls {
  profit = "Profit/",
  newProfit = "/Profit/NewProfit",
  editProfit = "/EditProfit",
  newProfitTrade = "/NewProfitTrade",
  newProfitShipping = "/NewProfitShipping",
  allProfit = "Profit/AllProfit",
  GetProfitInfo = "/GetProfitInfo",
  DeleteProfit = "/DeleteProfit",
  NewProfitFile = "/NewProfitFile",
  NewProfitTradeFile = "/NewProfitTradeFile",
  NewProfitShippingFile = "/NewProfitShippingFile",
  GetProfitFile = "/GetProfitFile",
  GetProfitTradeFile = "/GetProfitTradeFile",
  GetProfitShippingFile = "/GetProfitShippingFile",
}
