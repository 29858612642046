import styled from "styled-components";

export const StateDescriptionStyle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;
  .content {
    border-radius: 8px;
    background: var(--0, #fff);
    display: flex;
    padding: 24px 16px;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    align-self: stretch;
    overflow-y: auto;
  }
`;
