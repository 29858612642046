import { FC } from "react";
import { CategoriesChildContainer } from "../style";
import { Col } from "antd";
import { CategoriesChildItems } from "./CategoriesChildItems";
import { ITreeCategory } from "src/services/System/models";

type ICategoriesChild = {
  title: string;
  subTitle?: string;
  itemsCount: number;
  children: ITreeCategory[];
  onClick?: (child: ITreeCategory) => void;
  selectedParentId?: number;
  selectedChildren?: ITreeCategory[];
};
export const CategoriesChild: FC<ICategoriesChild> = ({
  title,
  itemsCount,
  subTitle,
  children,
  onClick,
  selectedParentId,
  selectedChildren,
}) => {
  return (
    <CategoriesChildContainer>
      <Col span={24} className=" h-[63px] border">
        <div className="flex flex-row justify-between items-center !mt[12px] mb-[4px]">
          <div className="flex flex-col gap-[4px]">
            <div className="titleHolder">{title}</div>
            <div className="subTitleHolder">{subTitle}</div>
          </div>
          <div className="itemsCountHolder">({itemsCount} items)</div>
        </div>
      </Col>

      {children.map((item, index) => (
        <Col span={24} key={index}>
          <CategoriesChildItems
            {...item}
            title={item.name}
            itemsCount={item.childeren.length}
            onClick={() => onClick && onClick(item)}
            isSelected={
              selectedChildren
                ? !!selectedChildren.find((child) => child.id === item.id)
                : selectedParentId === item.id
            }
          />
        </Col>
      ))}
    </CategoriesChildContainer>
  );
};
