import { Button, Col, Divider, Form, Row, Select, Space, message } from "antd";
import { FC, useCallback, useEffect, useState } from "react";
import { FormItem } from "src/components/UiKit/FormItem";
import { SInput } from "src/components/UiKit/Input";
import { useAddShip } from "src/modules/AddShips/context";
import { BasicInformationContainer } from "../../AddNewShip/styles";
import { SDatePicker } from "src/components/UiKit/DatePicker";
import {
  IEditShipBasicInformation,
  OwnerShipType,
} from "src/services/Ship/models";
import { ShipService } from "src/services/Ship/Ship.service";
import { SystemService } from "src/services/System/System.service";
import { IGetCountry } from "src/services/System/models";
import { AddCountryModal } from "../../../../../components/UiKit/AddCountryModal/AddCountryModal";
import { SPopconfirm } from "../../AddNewShip/components/popconfirm";
import { usePublicApi } from "src/hook/publicApi";
import dayjs from "dayjs";

export const EditBasicInformation: FC = () => {
  const [countryList, setCountryList] = useState<IGetCountry[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const {
    dispatch: { setIsAddCountryModalOpen },
    forms: { BasicInformationForm },
    func: { createData, fetchShipInformation },
    value: { newData, tableCurrentValue, isAddCountryModalOpen },
  } = useAddShip()!;
  const { shipRefetch } = usePublicApi();

  const onFinish = async (values: IEditShipBasicInformation) => {
    if (!tableCurrentValue) {
      return;
    }
    setLoading(true);
    const service = new ShipService();
    const reqBody: IEditShipBasicInformation = {
      countryRegisterId: values.countryRegisterId,
      deliveryDate: values.deliveryDate
        ? dayjs(values.deliveryDate).format("YYYY-MM-DD")
        : null,
      name: values.name,
      ownerShipType: values.ownerShipType,
      pN1: values.pN1,
      purchaseDate: values.purchaseDate
        ? dayjs(values.purchaseDate).format("YYYY-MM-DD")
        : null,
      abbreviation: values.abbreviation,
    };
    try {
      const response = await service.EditShipBasicInformation(
        tableCurrentValue.id,
        reqBody
      );
      if (response && response.status === 200) {
        message.success("Basic information updated successfully");
        fetchShipInformation();
        shipRefetch();
        // createData();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  const getCountry = useCallback(async () => {
    try {
      const { GetCountry } = new SystemService();
      const response = await GetCountry();
      if (response && response.status === 200) {
        setCountryList(response.data);
      }
    } catch (err) {
      console.log(err);
    } finally {
    }
  }, []);
  useEffect(() => {
    getCountry();
  }, [getCountry]);
  useEffect(() => {
    createData();
  }, [createData]);

  return (
    <Form
      form={BasicInformationForm}
      onFinish={onFinish}
      className="flex flex-col justify-between h-[620px]"
    >
      <AddCountryModal
        isAddCountryModalOpen={isAddCountryModalOpen}
        getCountry={getCountry}
        setIsAddCountryModalOpen={setIsAddCountryModalOpen}
      />
      <BasicInformationContainer className="flex gap-[24px]">
        <Col span={24}>
          <div className="titleHolder pt-[16px]">
            Please insert Ship information.
          </div>
        </Col>
        <Col span={24} className="flex gap-[24px] pt-[16px]">
          <FormItem
            name={"pN1"}
            label="Previous names"
            // rules={[{ required: true }]}
            required={false}
            className="w-[33%] !mb-0"
          >
            <SInput placeholder="insert" />
          </FormItem>
          <FormItem
            name={"name"}
            label="Current name"
            // rules={[{ required: true }]}
            required={false}
            className="w-[33%] !mb-0"
          >
            <SInput placeholder="insert" />
          </FormItem>
          <FormItem
            name={"abbreviation"}
            label="Abbreviation"
            rules={[{ required: true }]}
            className="w-[33%] !mb-0"
          >
            <SInput />
          </FormItem>
        </Col>
        <Col span={24} className="flex gap-[24px] items-center">
          <FormItem
            name={"ownerShipType"}
            label="Ownership type"
            // rules={[{ required: true }]}
            required={false}
            className="w-[33%] !mb-0"
          >
            <Select
              style={{ width: "100%" }}
              placeholder="select"
              optionLabelProp="label"
              optionFilterProp="label"
              showSearch
              className="selectOption"
              options={OwnerShipType}
            />
          </FormItem>

          <FormItem
            name={"countryRegisterId"}
            label="Country of registration "
            // rules={[{ required: true }]}
            required={false}
            className="w-[33%] !mb-0"
          >
            <Select
              style={{ width: "100%" }}
              placeholder="select"
              showSearch
              optionFilterProp="label"
              className="selectOption"
              //   loading={shipLoading}
              dropdownRender={(menu) => (
                <>
                  <Space style={{ padding: "16px 8px 4px" }}>
                    <div
                      onClick={() => setIsAddCountryModalOpen(true)}
                      className="cursor-pointer flex justify-center items-center"
                    >
                      <span className="material-icons text-[16px] text-[#001377]">
                        add
                      </span>
                      <div className="addHolder"> Add Country</div>
                    </div>
                  </Space>
                  <Divider style={{ margin: "8px 0" }} />
                  {menu}
                </>
              )}
              options={countryList.map((country) => ({
                label: country.name,
                value: country.id,
              }))}
            />
          </FormItem>
          <FormItem
            name={"purchaseDate"}
            label="Purchase date"
            // rules={[{ required: true }]}
            required={false}
            className="w-[32%] !mb-0"
          >
            <SDatePicker
              placeholder="Select"
              className="w-[100%]"
              format={"YYYY.MM.DD"}
            />
          </FormItem>
        </Col>
        <Col span={24} className="flex gap-[24px] items-center">
          <FormItem
            name={"deliveryDate"}
            label="Delivery date"
            // rules={[{ required: true }]}
            required={false}
            className="w-[32%] !mb-0"
          >
            <SDatePicker
              placeholder="Select"
              className="w-[100%]"
              format={"YYYY.MM.DD"}
            />
          </FormItem>
        </Col>
      </BasicInformationContainer>
      <Row className="flex pt-[24px]">
        <Col span={24}>
          <div className=" flex gap-[16px] footer justify-end">
            <SPopconfirm
              description="Are you sure you want to reset all the information you filled out?"
              onConfirm={() => {
                BasicInformationForm?.setFieldsValue(newData);
                fetchShipInformation();
              }}
            >
              <Button className="w-[100px]">Reset</Button>
            </SPopconfirm>
            <Button
              type="primary"
              className="w-[100px]"
              onClick={BasicInformationForm?.submit}
              loading={loading}
            >
              Save Edit
            </Button>
          </div>
        </Col>
      </Row>
    </Form>
  );
};
