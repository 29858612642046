import styled from "styled-components";

export const ShipDailyReportDetailStyle = styled.div`
  height: calc(100vh - 76px - 90px);
  width: 100vw;
  background: #fff;
  overflow-y: auto;
  padding: 16px;

  .report-card {
    padding: 12px;
    border: solid 1px #dadeec;
    border-radius: 8px;
    overflow-y: auto;
  }
  .message {
    background-color: #fff;
    border-radius: 8px;
    padding: 16px 12px;
    margin-top: 10px;
  }
`;
