import { FC, useEffect, useState } from "react";
import { TotalInvoicesContainer } from "./styles";
import { App, Button, Col, Form, Row } from "antd";
import { useAddInvoices } from "../../context";
import { FormItem } from "src/components/UiKit/FormItem";
import { SInput } from "src/components/UiKit/Input";
import { AddInvoiceTabEnums, IPaymentInformation } from "../../models";
import { useWatch } from "antd/es/form/Form";
import { InvoiceService } from "src/services/Invoice/Invoice.service";
import { IPaymanetInformation } from "src/services/Invoice/models";
import moment from "moment";
import textAreaMaxLength from "src/constants/textAreaMaxLength";
import validator from "validator";
import TextArea from "antd/es/input/TextArea";
import { Guard } from "src/components/Guard";
import { PaymanetInformationPath } from "src/services/Invoice/guardPath";
export const PaymentInformation: FC = () => {
  const {
    value: {
      totalInvoiceInfo,
      shipManagementRate,
      PaymentInformationForm,
      newInvoiceId,
      invoiceInfo,
    },
    dispatch: {
      setActiveTab,
      setPaymentInfo,
      setShowInvoice,
      setShipManagementRate,
    },
    func: { refetchAllRequest },
  } = useAddInvoices()!;
  const TIASMPC = useWatch("TIASMPC", PaymentInformationForm);
  const SupervisorRate = useWatch("SupervisorRate", PaymentInformationForm);
  const [loading, setLoading] = useState<boolean>(false);
  const { message } = App.useApp();

  const onFinish = async (values: IPaymentInformation) => {
    if (!newInvoiceId) return message.error("cannot find invoice id");
    try {
      setLoading(true);
      const { PaymanetInformation } = new InvoiceService();
      const reqBody: IPaymanetInformation = {
        excAmount: +values.EXCAmount,
        rateState: +values.RateState,
        supervicorRate: +values.SupervisorRate,
        tiaShipManagementPCurreccny: +values.TIASMPC,
      };
      if (values.invoiceComment) reqBody.comment = values.invoiceComment;
      const res = await PaymanetInformation(newInvoiceId, reqBody);
      if (res && res.status === 200) {
        setPaymentInfo(values);
        setShowInvoice(true);
        setActiveTab(AddInvoiceTabEnums.PaymentInformation);
        refetchAllRequest();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    const isNumericTIASMPC =
      TIASMPC !== undefined && validator.isNumeric(TIASMPC.toString());
    const isNumericShipManagementTotalAmount =
      totalInvoiceInfo &&
      totalInvoiceInfo.ShipManagementTotalAmount !== undefined &&
      validator.isNumeric(
        totalInvoiceInfo.ShipManagementTotalAmount.toString()
      );
    if (isNumericTIASMPC && isNumericShipManagementTotalAmount) {
      setShipManagementRate(() => {
        const result = +TIASMPC / +totalInvoiceInfo?.ShipManagementTotalAmount;

        if (validator.isNumeric(result.toString())) {
          return +result;
        } else {
          return 0;
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [TIASMPC, totalInvoiceInfo]);

  useEffect(() => {
    const isNumericShipManagementRate =
      shipManagementRate !== undefined &&
      validator.isNumeric(shipManagementRate.toString());
    const isNumericSupervisorRate =
      SupervisorRate !== undefined &&
      validator.isNumeric(SupervisorRate.toString());
    if (isNumericShipManagementRate && isNumericSupervisorRate) {
      PaymentInformationForm.setFieldValue(
        "RateState",
        (+SupervisorRate - +shipManagementRate).toFixed(10)
      );
    } else {
      PaymentInformationForm.setFieldValue("RateState", null);
    }
  }, [SupervisorRate, PaymentInformationForm, shipManagementRate]);
  useEffect(() => {
    PaymentInformationForm.setFieldsValue({
      PaymentCurrency: invoiceInfo?.paymentCurrencyShortName,
      DueDate: invoiceInfo
        ? moment(invoiceInfo.dueDate).format("YYYY-MM-DD")
        : null,
    });
    const isNumericShipManagementRate =
      shipManagementRate !== undefined &&
      validator.isNumeric(shipManagementRate.toString());
    const isNumericFinallyTotalInvoiceAmount =
      totalInvoiceInfo &&
      totalInvoiceInfo.FinallyTotalInvoiceAmount !== undefined &&
      validator.isNumeric(
        totalInvoiceInfo.FinallyTotalInvoiceAmount.toString()
      );

    if (isNumericShipManagementRate && isNumericFinallyTotalInvoiceAmount) {
      PaymentInformationForm.setFieldValue(
        "EXCAmount",
        (
          +shipManagementRate * +totalInvoiceInfo.FinallyTotalInvoiceAmount
        ).toFixed(10)
      );
    } else {
      PaymentInformationForm.setFieldValue("EXCAmount", null);
    }
  }, [
    PaymentInformationForm,
    invoiceInfo,
    shipManagementRate,
    totalInvoiceInfo,
  ]);
  return (
    <Form onFinish={onFinish} form={PaymentInformationForm}>
      <TotalInvoicesContainer>
        <Col span={24}>
          <div className="invoice-base-info">
            Please insert invoice’s Payment information .
          </div>
          <Row gutter={32}>
            <Col span={12} className="mt-[16px]">
              <FormItem
                name={"TIASMPC"}
                label="Total inv. amount Req pay currency"
                className="TIASMPC"
                rules={[{ required: true }]}
              >
                <SInput placeholder="insert" numbermode />
              </FormItem>

              <FormItem name={"PaymentCurrency"} label="Payment currency">
                <SInput disabled className="text-center" />
              </FormItem>
              <FormItem name={"DueDate"} label="Due date">
                <SInput disabled className="text-center" />
              </FormItem>
            </Col>
            <Col span={12} className="mt-[16px]">
              <FormItem
                name={"SupervisorRate"}
                label="Supervisor Rate"
                className="supervisor"
                rules={[{ required: true }]}
              >
                <SInput placeholder="insert" numbermode />
              </FormItem>
              <FormItem name={"EXCAmount"} label="EXC amount">
                <SInput disabled className="text-center" />
              </FormItem>
              <FormItem name={"RateState"} label="Rate state">
                <SInput
                  placeholder="= Supervisor Rate  _ Ship management Rate"
                  disabled
                  className="text-center"
                />
              </FormItem>
            </Col>
            <Col span={24} className="ps-[16px] mt-[32px]">
              <FormItem
                name={"invoiceComment"}
                label="If this invoice uses comment you can write your description in this field."
              >
                <TextArea
                  placeholder="Comment"
                  maxLength={textAreaMaxLength}
                  rows={5}
                  style={{
                    height: 178,
                    resize: "none",
                    borderRadius: 12,
                    background: "#F4F7FE",
                  }}
                />
              </FormItem>
            </Col>
          </Row>
        </Col>
        <Col
          span={24}
          className="flex justify-end gap-[24px] mt-[100px] footer"
        >
          <Button
            onClick={() => setActiveTab(AddInvoiceTabEnums.Description)}
            htmlType="button"
          >
            Cancel
          </Button>
          <Guard action={PaymanetInformationPath}>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              disabled={!TIASMPC || !SupervisorRate}
            >
              Save
            </Button>
          </Guard>
        </Col>
      </TotalInvoicesContainer>
    </Form>
  );
};
