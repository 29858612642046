import styled from "styled-components";

export const SelectActionDateStyle = styled.div`
  padding: 24px 16px 0;

  .buttons {
    user-select: none;
    cursor: pointer;
    color: var(--2, #2c73fc);
    text-align: center;
    font-variant-numeric: lining-nums proportional-nums;
    font-feature-settings: "dlig" on;
    font-family: DM Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.28px;
  }
  .dateHolder::after {
    border-radius: 2px 2px 0px 0px;
    background: var(--text, #001377);
    height: 2px;
    align-self: stretch;
    content: "";
    display: block;
    margin-top: 12px;
  }
  .dateHolder {
    gap: 4px;
  }
  .dayAndMonth {
    color: var(--text, #001377);
    font-family: DM Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .year {
    color: var(--text, #001377);
    text-align: center;
    font-variant-numeric: lining-nums proportional-nums;
    font-feature-settings: "dlig" on;
    font-family: DM Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .wrapper {
    border-bottom: 1px solid var(--shadow, #dadeec);
  }
`;
