import { FC, Suspense } from "react";
import { AddNewRequester } from "src/modules/addNewRequester";
import { RequesterProvider } from "src/modules/addNewRequester/context/context";
import { PreLoading } from "src/routes/Loading";

export const RequesterPage: FC = () => {
  return (
    <Suspense fallback={<PreLoading />}>
      <RequesterProvider>
        <AddNewRequester />
      </RequesterProvider>
    </Suspense>
  );
};
