import { Button, Col, Divider, Flex, Form, Typography } from "antd";
import { FC, useEffect } from "react";
import { ProfitShippingWrapper } from "./style";
import { ProfitShippingFormItems } from "./ShippingFormItems";
import { useNewProfitReport } from "../../context";
import { AddNewProfitStatusTypeEnum } from "src/modules/ProfitReport/models";
import { useTableProfit } from "../../../ProftTable/context";
import { useProfitReport } from "src/modules/ProfitReport/context";
import dayjs from "dayjs";
export const ProfitShippingInformation: FC = () => {
  const {
    values: { finishLoadingShippingInfo },
    func: { onFinishShippingInfo },
  } = useNewProfitReport();

  const {
    value: { editData },
  } = useTableProfit();

  const {
    value: { editMode },
    dispatch: { setStatus, setStep, setShowAttachFile },
    form: { shippingInfoForm },
  } = useProfitReport();

  const onReset = () => {
    shippingInfoForm?.resetFields();
  };
  const addFile = () => {
    setShowAttachFile(true);
  };

  const onCancelAdd = () => {
    setStatus((prev) => ({
      ...prev,
      shipping:
        prev.shipping === "loading"
          ? AddNewProfitStatusTypeEnum.empty
          : AddNewProfitStatusTypeEnum.done,
    }));
    setStep(undefined);
  };

  useEffect(() => {
    if (editData && editMode) {
      const data = {
        ...editData,
        voyageDuration: [
          editData.startVoyageDate
            ? dayjs(editData.startVoyageDate)
            : undefined,
          editData.endVoyageDate ? dayjs(editData.endVoyageDate) : undefined,
        ],
      };
      shippingInfoForm?.setFieldsValue(data);
    }
  }, [shippingInfoForm, editData, editMode]);
  return (
    <Form form={shippingInfoForm} onFinish={onFinishShippingInfo}>
      <ProfitShippingWrapper>
        <Col span={24}>
          <Typography.Title className="title">
            Shipping information
          </Typography.Title>
          <Typography.Text className="subTitle">
            Please insert its information.
          </Typography.Text>
        </Col>
        <Col span={24} className="mt-[24px]">
          <ProfitShippingFormItems />
        </Col>
        <Divider />
        <Col span={24}>
          <Flex align="center" justify="space-between">
            <Flex gap={16}>
              <Button
                className="text-[#4B4B4B] min-w-[85px]"
                onClick={() => {
                  onCancelAdd();
                }}
              >
                Cancel
              </Button>
              <Button
                icon={<span className="material-icons">folder_open</span>}
                className="flex justify-center items-center gap-[8px] !text-[#4B4B4B] w-[85px]"
                ghost
                style={{
                  border: "1px solid var(--color-Gray-Gray-600, #4B4B4B)",
                }}
                onClick={addFile}
              >
                files
              </Button>
            </Flex>
            <Flex gap={16}>
              <Button className="min-w-[85px]" onClick={onReset}>
                Reset
              </Button>
              <Button
                className="min-w-[85px]"
                type="primary"
                htmlType="submit"
                loading={finishLoadingShippingInfo}
              >
                {editMode ? "Save edit" : "Add"}
              </Button>
            </Flex>
          </Flex>
        </Col>
      </ProfitShippingWrapper>
    </Form>
  );
};
