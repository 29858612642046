import { Row } from "antd";
import styled from "styled-components";

export const FlowAssignRoleContainer = styled(Row)`
  .content {
    /* border-radius: 16px;
    border: 2px solid var(--shadow, #dadeec);
    padding: 24px; */
  }
  .radio {
    .ant-form-item-label {
      padding-left: 0 !important;
    }
  }
  .step-action {
    border-top: 1px solid var(--shadow, #dadeec);
    padding: 24px 0 0 0;
    display: flex;
    justify-content: flex-end;
  }
  .create-button {
    border-radius: 12px;
    border: 1px solid var(--Info-Info-600, #3b82f6);
    background: transparent;
    color: #3b82f6;
    padding: 8px;
  }
  .footer {
    display: flex;
    justify-content: flex-end;
    gap: 24px;
  }
  .add-button {
    justify-content: center;
    align-items: center;
    display: flex;
    background: var(--Info-Info-200, #e4f2ff);
    color: var(--2, var(--Blue_VDR, #2c73fc));
    margin-top: 32px;
    width: 100%;
  }
  .title {
    font-size: 14px;
  }
  tbody {
    tr {
      td {
        text-align: center !important;
      }
    }
  }
`;

export const StepsCardStyle = styled.div`
  display: flex;
  padding: 12px;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid var(--shadow, #dadeec);

  &::before {
    content: "";
    padding: 2px;
    border-radius: 16px;
    background: var(--shadow, #dadeec);
    display: block;
    display: flex;
    align-self: stretch;
  }
  .wrapper {
    padding-left: 12px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
  }
  .name-status {
    display: flex;
    justify-content: flex-start;
  }
  .stepName {
    padding-right: 8px;
    color: var(--text, #001377);
    font-family: DM Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .status {
    padding-left: 8px;
    color: var(--text-3, #5d6881);
    font-family: DM Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-left: 1px solid #dadeec;
  }
  .description {
    display: flex;
    justify-content: space-between;
    gap: 12px;
  }
  .des-text {
    color: var(--text-2, #a3aed0);
    font-family: DM Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: flex;
    align-items: center;
    gap: 4px;
  }
`;
