import { forwardRef } from "react";
import { Col, Flex, Row } from "antd";
import { INewAllShipDailyReportResult } from "src/services/DailyReport/models/result.model";

interface IProps {
  reports: INewAllShipDailyReportResult[];
}
export const ReportPrint = forwardRef<HTMLDivElement | null, IProps>(
  ({ reports }, ref) => {
    return (
      <div ref={ref} className="">
        <Row align={"middle"} className="p-[24px] bg-[#F7F8FA]">
          <Col span={24} className="bg-[#fff] h-[72px] p-[16px] rounded-lg">
            <Flex align="center" justify="space-between" className="bg-[]">
              <p className="text-[#001377] text-[16px] font-bold">
                Vessel’s Daily Reports
              </p>
              <Flex
                align="center"
                justify="space-between"
                className="bg-[#F2F7FF] h-[40px] p-[12px] rounded-[10px]"
              >
                <span className="material-icons text-[14px]">
                  calendar_month
                </span>
                <span className="ml-[10px]">
                  {new Date().getDate()}{" "}
                  {new Date().toLocaleString("en-EN", { month: "long" })}{" "}
                  {new Date().getFullYear()}
                </span>
              </Flex>
            </Flex>
          </Col>
          <Col span={12}></Col>

          <Col span={24} className="mt-[40px]">
            <Row gutter={[16, 8]} justify={"space-around"}>
              <Col span={4}>
                <Flex vertical justify="center" align="center">
                  <span className="material-icons text-[14px]">sailing</span>
                  <span className="text-[#5D6881] text-center text-[14px] font-bold">
                    Vessel
                  </span>
                </Flex>
              </Col>
              <Col span={4} className="">
                <Flex vertical justify="center" align="center">
                  <span className="material-icons text-[14px]">
                    directions_boat
                  </span>
                  <span className="text-[#5D6881] text-center text-[14px] font-bold">
                    Voyage
                  </span>
                </Flex>
              </Col>
              <Col span={5}>
                <Flex vertical justify="center" align="center">
                  <span className="material-icons text-[14px]">
                    data_exploration
                  </span>
                  <span className="text-[#5D6881] text-center text-[14px] font-bold">
                    Current Situation Report
                  </span>
                </Flex>
              </Col>
              <Col span={5}>
                <Flex vertical justify="center" align="center">
                  <span className="material-icons text-[14px]">
                    pending_actions
                  </span>
                  <span className="text-[#5D6881] text-center text-[14px] font-bold">
                    Actions
                  </span>
                </Flex>
              </Col>
              <Col span={6}>
                <Flex vertical justify="center" align="center">
                  <span className="material-icons text-[14px]">
                    app_registration
                  </span>
                  <span className="text-[#5D6881] text-center text-[14px] font-bold">
                    Future plan
                  </span>
                </Flex>
              </Col>
            </Row>
          </Col>

          <Col span={24} className="mt-[20px]">
            {reports.map((report, index) => (
              <Row
                gutter={[16, 8]}
                justify={"space-between"}
                key={index}
                className={`p-[12px] rounded-lg mt-3 ${
                  index % 2 !== 0 ? "bg-[#FAFAFA]" : "bg-[#fff]"
                }`}
              >
                <Col span={4}>
                  <p className="text-[#2C73FC] text-center text-[12px] font-bold flex justify-center whitespace-pre-wrap">
                    {report.shipName}
                  </p>
                </Col>
                <Col span={4}>
                  <p className="text-[#3E4556] text-center text-[12px] font-medium flex justify-center whitespace-pre-wrap	">
                    {report.voyage}
                  </p>
                </Col>
                <Col span={5}>
                  <p className="text-[#3E4556] text-center text-[12px] font-medium flex justify-center whitespace-pre-wrap	">
                    {report.currentSituationReport}
                  </p>
                </Col>
                <Col span={5}>
                  <p className="text-[#3E4556] text-center text-[12px] font-medium flex justify-center whitespace-pre-wrap	">
                    {report.action}
                  </p>
                </Col>
                <Col span={6}>
                  <p className="text-[#3E4556] text-center text-[12px] font-medium flex justify-center whitespace-pre-wrap	">
                    {report.futurePlan}
                  </p>
                </Col>
              </Row>
            ))}
          </Col>
        </Row>
      </div>
    );
  }
);
