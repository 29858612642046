import { FC } from "react";
import { Button, Col } from "antd";
import { Icons } from "src/components/customIcons";
import { useNoonReportCTX } from "src/modules/NoonReport/context";
import { INoonReportTabs } from "src/modules/NoonReport/models/tabs";

interface IActionsProps {
  handleClick: () => void;
}

export const Actions: FC<IActionsProps> = ({ handleClick }) => {
  const {
    func: { changeMode },
    dispatch: { setActiveKeyNoonReport },
  } = useNoonReportCTX();
  return (
    <Col
      span={24}
      className="w-full flex justify-between items-center mt-[30px] actions"
    >
      <Button
        type="text"
        className="bg-[#fff] hover:!bg-[#fff]"
        onClick={handleClick}
      >
        <Icons name="pdfDownload" />
      </Button>
      <div className="pr-[24px]">
        <Button
          className="mr-[16px] edit-btn"
          onClick={() => changeMode("edit")}
        >
          Edit
        </Button>
        <Button
          type="primary"
          onClick={() => {
            changeMode("create");
            setActiveKeyNoonReport(INoonReportTabs.noonReportTable);
          }}
        >
          Close
        </Button>
      </div>
    </Col>
  );
};
