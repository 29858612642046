import { FC, useEffect, useState } from "react";
import { FlowStepsContainer } from "./styles";
import { App, Button, Col, Form, Radio, Row, Select } from "antd";
import { FormItem } from "src/components/UiKit/FormItem";
import { SInput } from "src/components/UiKit/Input";
import TextArea from "antd/es/input/TextArea";
import { FlowSections } from "../../model/sectionTypes";
import { useAddNewFlowCTX } from "../../context";
import { ActiveContent } from "../../model/activeContent";
import { NewFlowTabs } from "../../model/newFlowTabs";
import { useFlowCTX } from "src/modules/Flow/context";
import { WorkFlowService } from "src/services/WorkFlow/WorkFlow.service";

export const FlowSteps: FC = () => {
  const { message } = App.useApp();
  const {
    value: { newStepsLoading, selectedStep },
    func: {
      onFinishFlowStep,
      editStep,
      getWorkFlowSteps,
      onCancelAddNew,
      onCancelEditAndView,
    },
    dispatch: { setSelectedStep, setActiveTab },
    forms: { StepsForm },
  } = useAddNewFlowCTX();

  const attachmentRadio = Form.useWatch("hasAttachment", StepsForm);

  const {
    func: { OnChangeState },
    value: { mode },
  } = useFlowCTX();
  const [deleteLoading, setDeleteLoading] = useState<boolean>();
  useEffect(() => {
    if (selectedStep) {
      StepsForm?.setFieldsValue(selectedStep);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStep]);
  const onFinish = (values: any) => {
    if (selectedStep) {
      editStep(values).then((result) => {
        if (result) {
          setSelectedStep(undefined);
          StepsForm?.resetFields();
        }
      });
    } else {
      onFinishFlowStep(values).then((res) => {
        if (res) {
          StepsForm?.resetFields();
        }
      });
    }
  };
  const deleteStep = async () => {
    if (!selectedStep) return;
    try {
      setDeleteLoading(true);
      const { DeleteWorkFlowStep } = new WorkFlowService();
      const res = await DeleteWorkFlowStep(selectedStep.id);
      if (res && res.status === 200) {
        message.success("Step deleted successfully");
        setSelectedStep(undefined);
        StepsForm?.resetFields();
        getWorkFlowSteps();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setDeleteLoading(false);
    }
  };
  return (
    <FlowStepsContainer gutter={[0, 24]}>
      <Col span={24}>
        <div className="title pt-[16px]">
          Please insert the steps information.
        </div>
      </Col>
      <Col span={24} className="content">
        <Form form={StepsForm} onFinish={onFinish} disabled={mode === "view"}>
          <Row gutter={[32, 32]}>
            <Col span={12}>
              <FormItem
                name={"name"}
                label={"Name"}
                required
                rules={[{ required: true }]}
              >
                <SInput placeholder="insert" />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                label={"Section"}
                required
                rules={[{ required: true }]}
                name={"state"}
              >
                <Select className="selectOption" placeholder="select">
                  <Select.Option value={FlowSections.start}>
                    Start
                  </Select.Option>
                  <Select.Option value={FlowSections.inprogress}>
                    In progress
                  </Select.Option>
                  <Select.Option value={FlowSections.inprogressEnd}>
                    In progress / End
                  </Select.Option>
                  <Select.Option value={FlowSections.end}>End</Select.Option>
                </Select>
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                label={"Has attachment?"}
                className="radio"
                name={"hasAttachment"}
                initialValue={false}
              >
                <Radio.Group>
                  <Radio value={true}>Yes</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                label={"Comment is mandatory"}
                className="radio"
                initialValue={false}
                name={"commentMandatory"}
              >
                <Radio.Group>
                  <Radio value={true}>Yes</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </FormItem>
            </Col>
            <Col span={24}>
              <FormItem
                label={"Description about attachment"}
                name={"attachmentComment"}
                initialValue={""}
                rules={[{ required: !!attachmentRadio }]}
              >
                <TextArea
                  placeholder="Type description"
                  style={{
                    borderRadius: 12,
                    background: "#F4F7FE",
                    borderColor: "#F4F7FE",
                  }}
                  autoSize={{ maxRows: 5, minRows: 5 }}
                  disabled={!attachmentRadio || mode === "view"}
                />
              </FormItem>
            </Col>
            {mode !== "view" && (
              <Col span={24}>
                <div className="step-action">
                  {selectedStep && (
                    <div className=" me-[12px]">
                      <Button
                        onClick={() => {
                          setSelectedStep(undefined);
                          StepsForm?.resetFields();
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  )}
                  {selectedStep && (
                    <div className=" me-[12px]">
                      <Button
                        className="delete-button"
                        onClick={deleteStep}
                        loading={deleteLoading}
                      >
                        Delete step
                      </Button>
                    </div>
                  )}
                  <Button
                    className="create-button"
                    loading={newStepsLoading}
                    onClick={StepsForm?.submit}
                  >
                    {selectedStep ? "Edit step" : "Create step"}
                  </Button>
                </div>
              </Col>
            )}
          </Row>
        </Form>
      </Col>
      <Col span={24}>
        <div className="footer">
          <Button
            onClick={mode === "create" ? onCancelAddNew : onCancelEditAndView}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            onClick={() => {
              setActiveTab(NewFlowTabs.AssignRole);
              OnChangeState(ActiveContent.CenterAndLeft);
            }}
          >
            Next
          </Button>
        </div>
      </Col>
    </FlowStepsContainer>
  );
};
