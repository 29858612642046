import { FC } from "react";
import { LOROBConsInfoForm } from "./components/LOROBConsInfoForm";
import LOROBConsTable from "./components/LOROBConsTable";
import { LOROBConsInfoProvider } from "./context";
import { LOROBConsInfoStyle } from "./styles";

export const LOROBConsInfo: FC = () => {
  return (
    <LOROBConsInfoProvider>
      <LOROBConsInfoStyle gutter={[32, 24]}>
        <LOROBConsInfoForm />
        <LOROBConsTable />
      </LOROBConsInfoStyle>
    </LOROBConsInfoProvider>
  );
};
