import { FC } from "react";
import { StylesContent } from "./styles";
import { Tabs } from "antd";
import { TabsProps } from "antd/lib";
import { VoyageInfo } from "../VoyageInfo";
import { useNoonReportCTX } from "src/modules/NoonReport/context";
import { NewNoonReportTabs } from "../../model/newNoonReportTabs";
import { useAddNewNoonReportCTX } from "../../context";
import { FOMGOROBInfo } from "../FOMGOROB";
import { FOMGOCONSInfo } from "../FOMGOCONS";
import { LOROBConsInfo } from "../LOROBCons";
import { FWInfo } from "../FWInfo";
import { SpeedDistanceInfo } from "../SpeedDistance";
import { OthersInfo } from "../OthersInfo";

export const AddNewNoonReport: FC = () => {
  const {
    value: { activeTab },
    dispatch: { setActiveTab },
  } = useAddNewNoonReportCTX();
  const {
    value: { mode },
  } = useNoonReportCTX();

  const items: TabsProps["items"] = [
    {
      key: NewNoonReportTabs.Voyage,
      label: "Voyage Information",
      children: <VoyageInfo />,
      disabled: mode === "create" && activeTab !== NewNoonReportTabs.Voyage,
    },
    {
      key: NewNoonReportTabs.FOMGOROB,
      label: "FO,MGO ROB",
      children: <FOMGOROBInfo />,
      disabled: mode === "create" && activeTab !== NewNoonReportTabs.FOMGOROB,
    },
    {
      key: NewNoonReportTabs.FOMGOCONS,
      label: "FO, MGO Cons",
      children: <FOMGOCONSInfo />,
      disabled: mode === "create" && activeTab !== NewNoonReportTabs.FOMGOCONS,
    },
    {
      key: NewNoonReportTabs.LOROBCons,
      label: "LO, ROB, Cons",
      children: <LOROBConsInfo />,
      disabled: mode === "create" && activeTab !== NewNoonReportTabs.LOROBCons,
    },
    {
      key: NewNoonReportTabs.FW,
      label: "F.W information",
      children: <FWInfo />,
      disabled: mode === "create" && activeTab !== NewNoonReportTabs.FW,
    },
    {
      key: NewNoonReportTabs.Speed,
      label: "Speed & Distance",
      children: <SpeedDistanceInfo />,
      disabled: mode === "create" && activeTab !== NewNoonReportTabs.Speed,
    },
    {
      key: NewNoonReportTabs.Other,
      label: "Other details",
      children: <OthersInfo />,
      disabled: mode === "create" && activeTab !== NewNoonReportTabs.Other,
    },
  ];

  const onChange = (key: string) => {
    setActiveTab(key);
  };

  return (
    <StylesContent>
      <Tabs
        items={items}
        onChange={onChange}
        activeKey={activeTab}
        className="create-edit-tabs"
      />
    </StylesContent>
  );
};
