import { Row } from "antd";
import styled from "styled-components";

export const CommercialContainer = styled(Row)`
  .content {
    border-radius: 20px;
    background: var(--color-Base-Color-White, #fff);
    box-shadow: 0px 4px 6px 0px rgba(113, 130, 183, 0.25);
    padding: 24px 32px;
  }
  .title {
    color: var(--color-Light-Mode-Text-Title, #001377);
    /* En/Headline/H6.SemiBold */
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 24px;
  }
  .datePicker {
    background: #f4f7fe !important;
    border-color: #f4f7fe !important;
    border-radius: 12px !important;
    min-height: 48px !important;
    width: 100% !important;
    &.ant-picker-disabled {
      background: #dadeec !important;
      border-color: #dadeec !important;
    }
  }
  .ant-select-disabled {
    .ant-select-selector {
      background: #dadeec !important;
    }
  }
  .footer {
    border-top: 1px solid var(--color-Light-Mode-Border-4, #e5e7eb);
    padding-top: 24px;
  }
`;
