import {
  FC,
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { Form, message } from "antd";
import { FormInstance } from "antd/lib";
import Dayjs from "dayjs";
import { NoonReportService } from "src/services/NoonReport/NoonReport.service";
import { useNoonReportCTX } from "src/modules/NoonReport/context";
import {
  ISpeedDistanceInfo,
  ISpeedDistanceInfoArgs,
} from "src/services/NoonReport/models";
import { useAddNewNoonReportCTX } from "../../context";
import { NewNoonReportTabs } from "../../model/newNoonReportTabs";

interface IContext {
  value: {
    loading: boolean;
    speedDistanceIfo: ISpeedDistanceInfo | undefined;
    dataLoading: boolean;
  };
  dispatch: {};
  func: {
    onFinishInfo: (values: any) => void;
  };
  forms: {
    formName: FormInstance;
  };
}

export const SpeedDistanceCTX = createContext<IContext | undefined>(undefined);
export const SpeedDistanceProvider: FC<PropsWithChildren> = ({ children }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [speedDistanceIfo, setSpeedDistanceInfo] = useState<
    ISpeedDistanceInfo | undefined
  >(undefined);
  const [dataLoading, setDataLoading] = useState<boolean>(false);

  const {
    value: { selectedReport, mode },
  } = useNoonReportCTX();

  const {
    dispatch: { setActiveTab },
  } = useAddNewNoonReportCTX();

  const [formName] = Form.useForm();

  const onFinishInfo = async (values: ISpeedDistanceInfoArgs) => {
    if (!selectedReport) return;
    setLoading(false);
    try {
      const { SpeedDistanceInfo } = new NoonReportService();
      const response = await SpeedDistanceInfo(selectedReport.id, values);
      if (response && response.status === 200) {
        message.success("FW Info successfully registered.");
        if (mode === "create") setActiveTab(NewNoonReportTabs.Other);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const setFormFields = useCallback(() => {
    if (speedDistanceIfo)
      formName.setFieldsValue({
        DisRun24: speedDistanceIfo.DisRun24,
        DisRunTTL: speedDistanceIfo.DisRunTTL,
        toGo: speedDistanceIfo.toGo,
        AVSPD24: speedDistanceIfo.AVSPD24,
        AVSPDTTl: speedDistanceIfo.AVSPDTTl,
        POSN: speedDistanceIfo.POSN,
        ATD: Dayjs(speedDistanceIfo.ATD),
        ETA: Dayjs(speedDistanceIfo.ETA),
        port: speedDistanceIfo.port,
      });
  }, [formName, speedDistanceIfo]);

  const getData = useCallback(async () => {
    if (!selectedReport) return;
    setDataLoading(true);
    try {
      const { GetSpeedDistanceInfo } = new NoonReportService();
      const response = await GetSpeedDistanceInfo(selectedReport.id);
      if (response && response.status === 200) {
        setSpeedDistanceInfo(response.data);
        setFormFields();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setDataLoading(false);
    }
  }, [selectedReport, setFormFields]);

  useEffect(() => {
    if (mode === "edit") getData();
  }, [getData, mode]);

  const contextValue: IContext = {
    value: { loading, speedDistanceIfo, dataLoading },
    dispatch: {},
    func: {
      onFinishInfo,
    },
    forms: {
      formName,
    },
  };

  return (
    <SpeedDistanceCTX.Provider value={contextValue}>
      {children}
    </SpeedDistanceCTX.Provider>
  );
};

export const useSpeedDistance = () => useContext(SpeedDistanceCTX)!;
