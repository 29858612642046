import { Row } from "antd";
import { styled } from "styled-components";

export const AttachZipFileContainer = styled(Row)`
  margin-top: 8px;
  padding: 0 32px;
  .title {
    color: #001377;
    font-family: DM Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding-bottom: 32px;
    padding-top: 32px;
  }
  label[for="ship"] {
    color: #001377;
    font-family: DM Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .selectOption {
    .ant-select-selector {
      background: #f4f7fe !important;
      border-color: #f4f7fe !important;
      border-radius: 12px !important;
      min-height: 48px;
      display: flex;
      align-items: center;
    }
    input {
      /* margin-top: 8px !important; */
    }
  }
  .footer {
    border-top: 1px solid #dadeec;
    padding-top: 32px;
    padding-bottom: 16px;
  }
  .ant-row {
    display: flex;
    align-items: center;
  }
  .textArea {
    background: #f4f7fe !important;
    border-color: #f4f7fe !important;
    border-radius: 12px !important;
  }
  Modal[for="title"] {
    color: #001377;
    font-family: DM Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .ant-form-item-label {
    color: var(--text, #001377);
    font-family: DM Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;
