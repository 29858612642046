import { FC, Suspense } from "react";
import { ProfitReport } from "src/modules/ProfitReport";
import { ProfitReportProvider } from "src/modules/ProfitReport/context";
import { NewProfitReportProvider } from "src/modules/ProfitReport/module/AddNewProfit/context";
import { TableProfitProvider } from "src/modules/ProfitReport/module/ProftTable/context";
import { AttachFileProvider } from "src/modules/ProfitReport/module/attachFileBaseInfo/context";
import { PreLoading } from "src/routes/Loading";

export const ProfitReportPage: FC = () => {
  return (
    <Suspense fallback={<PreLoading />}>
      <ProfitReportProvider>
        <TableProfitProvider>
          <NewProfitReportProvider>
            <AttachFileProvider>
              <ProfitReport />
            </AttachFileProvider>
          </NewProfitReportProvider>
        </TableProfitProvider>
      </ProfitReportProvider>
    </Suspense>
  );
};
