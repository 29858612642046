import { Dispatch, FC, SetStateAction } from "react";
import { SelectActionDateStyle } from "./styles";
import { Flex } from "antd";
import dayjs from "dayjs";
interface IProps {
  selectedDate: dayjs.Dayjs;
  setSelectedDate: Dispatch<SetStateAction<dayjs.Dayjs>>;
}
export const SelectActionDate: FC<IProps> = ({
  selectedDate,
  setSelectedDate,
}) => {
  const onNextDate = () => {
    setSelectedDate(selectedDate.set("day", selectedDate.day() + 1));
  };
  const onPrevDate = () => {
    setSelectedDate(selectedDate.set("day", selectedDate.day() - 1));
  };
  return (
    <SelectActionDateStyle>
      <Flex justify="space-between" className="wrapper">
        <div className="buttons" onClick={onPrevDate}>
          Previous day
        </div>
        <div className="dateHolder">
          <div className="dayAndMonth">{selectedDate.format("DD MMMM")}</div>
          <div className="year">{selectedDate.format("YYYY")} </div>
        </div>
        <div className="buttons" onClick={onNextDate}>
          Next day
        </div>
      </Flex>
    </SelectActionDateStyle>
  );
};
