import { App, Button, Col, Divider, Form, Row, Select, Space } from "antd";
import TextArea from "antd/es/input/TextArea";
import { FC, useEffect, useState } from "react";
import { AddCountryModal } from "src/components/UiKit/AddCountryModal/AddCountryModal";
import { FormItem } from "src/components/UiKit/FormItem";
import { SInput } from "src/components/UiKit/Input";
import { UseAgents } from "src/modules/agents/context";
import { IAddNewAgentTabs } from "src/modules/agents/models";
import { BaseInfoService } from "src/services/BaseInfo/BaseInfo.service";
import { INewAgent } from "src/services/BaseInfo/models";

export const BasicInfo: FC = () => {
  const {
    value: { submitBasicInfo, countryList, portList },
    dispatch: { setSubmitBasicInfo, setActiveKeyAddAgent, setNewAgentId },
    form: { BasicInfoForm },
    func: { getCountry },
  } = UseAgents();

  const [isOpenModalAddCountry, setIsOpenModalAddCountry] =
    useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);
  const onOpenCountryModal = () => {
    setIsOpenModalAddCountry(true);
  };

  const onReset = () => {
    BasicInfoForm?.resetFields();
  };

  const { message } = App.useApp();

  const onFinish = async (value: INewAgent) => {
    try {
      setLoading(true);
      const { NewAgent } = new BaseInfoService();
      const response = await NewAgent(value);
      if (response && response.status === 200) {
        message.success("created successfully agent");
        BasicInfoForm?.resetFields();
        setNewAgentId(response.data);
        setActiveKeyAddAgent(IAddNewAgentTabs.contactInformation);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const formValue = Form.useWatch([], BasicInfoForm);

  useEffect(() => {
    BasicInfoForm?.validateFields({ validateOnly: true })
      .then(() => {
        setSubmitBasicInfo(true);
      })
      .catch(() => {
        setSubmitBasicInfo(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [BasicInfoForm, formValue]);

  return (
    <Form form={BasicInfoForm} onFinish={onFinish}>
      <div className="py-[16px] text-[16px] font-medium mb-[24px]">
        Please insert agent information.
      </div>
      <div className="flex flex-col justify-end">
        <Row gutter={24}>
          <Col span={8}>
            <FormItem
              name="name"
              label="Name"
              rules={[{ required: true }]}
              required={true}
              className="w-full"
            >
              <SInput placeholder="insert" />
            </FormItem>
          </Col>
          <Col span={8}>
            <FormItem name="company" label="Company" className="w-full">
              <SInput placeholder="insert" />
            </FormItem>
          </Col>
          <Col span={8}>
            <FormItem name="countryId" label="Country" className="w-full">
              <Select
                style={{ width: "100%" }}
                placeholder="select"
                optionLabelProp="label"
                showSearch
                allowClear
                optionFilterProp="label"
                className="selectOption"
                options={countryList.map((country) => ({
                  label: country.name,
                  value: country.id,
                }))}
                dropdownRender={(menu) => (
                  <>
                    <Space style={{ padding: "16px 8px 4px" }}>
                      <div
                        onClick={onOpenCountryModal}
                        className="cursor-pointer flex justify-center items-center"
                      >
                        <span className="material-icons text-[16px] text-[#001377]">
                          add
                        </span>
                        <div className="addHolder"> Add Country</div>
                      </div>
                    </Space>
                    <Divider style={{ margin: "8px 0" }} />
                    {menu}
                  </>
                )}
              />
            </FormItem>
          </Col>
          <Col span={8}>
            <FormItem name="portId" label="Port" className="w-full">
              <Select
                style={{ width: "100%" }}
                placeholder="select"
                optionLabelProp="label"
                showSearch
                allowClear
                optionFilterProp="label"
                className="selectOption"
                options={portList.map((ports) => ({
                  label: ports.name,
                  value: ports.id,
                }))}
              />
            </FormItem>
          </Col>
          <Col span={24}>
            <FormItem name="description" label="Services" className="w-full">
              <TextArea
                placeholder="Description"
                rows={8}
                autoSize={{ minRows: 8, maxRows: 8 }}
                className="p-[16px] resize-none rounded-[12px] bg-[#F4F7FE]"
              />
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <div className="flex gap-[16px] justify-end footer">
              <Button onClick={onReset} className="w-[100px]">
                Reset
              </Button>
              <Button
                onClick={BasicInfoForm?.submit}
                disabled={!submitBasicInfo}
                loading={loading}
                className="w-[100px]"
                type="primary"
              >
                Next
              </Button>
            </div>
          </Col>
        </Row>
      </div>
      <AddCountryModal
        getCountry={getCountry}
        isAddCountryModalOpen={isOpenModalAddCountry}
        setIsAddCountryModalOpen={setIsOpenModalAddCountry}
      />
    </Form>
  );
};
