import axios from "axios";
import {
  IAllAgentContact,
  IAllAgents,
  IAllAgentsParams,
  IAllPortsParams,
  INewAgent,
  INewAgentContact,
  INewPort,
  IPort,
  IUpdateAgent,
  NewAgentContactType,
} from "./models";
import { BaseInfoUrls } from "./urls";
import { IDataModel } from "../models";
import { checkActionPermission } from "src/helpers/checkPermissionFunc";
import * as GuardPaths from "./guardPath";
import { permissionLog } from "src/helpers/permissionLog";
export class BaseInfoService {
  async NewPort(data: INewPort) {
    if (!checkActionPermission(GuardPaths.NewPortPath)) {
      permissionLog(GuardPaths.NewPortPath.path);
      return undefined;
    }
    try {
      const response = await axios.post<void>(BaseInfoUrls.NewPort, data);
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async GetPort(params?: IAllPortsParams) {
    if (!checkActionPermission(GuardPaths.AllPortsPath)) {
      permissionLog(GuardPaths.AllPortsPath.path);
      return undefined;
    }
    let endpoint: string = BaseInfoUrls.GetPort;
    if (params) {
      const searchParams = new URLSearchParams(params);
      endpoint = endpoint.concat(`?${searchParams}`);
    }
    try {
      const response = await axios.get<IDataModel<IPort[]>>(endpoint);
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async DeletePort(id: number) {
    if (!checkActionPermission(GuardPaths.DeletePortPath)) {
      permissionLog(GuardPaths.DeletePortPath.path);
      return undefined;
    }
    try {
      const response = await axios.delete<void>(
        BaseInfoUrls.BaseInfo.concat(`${id}${BaseInfoUrls.DeletePort}`)
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async EditPort(id: number, data: INewPort) {
    if (!checkActionPermission(GuardPaths.UpdatePortPath)) {
      permissionLog(GuardPaths.UpdatePortPath.path);
      return undefined;
    }
    try {
      const response = await axios.put<void>(
        BaseInfoUrls.BaseInfo.concat(`${id}${BaseInfoUrls.EditPort}`),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async NewAgent(data: INewAgent) {
    if (!checkActionPermission(GuardPaths.NewAgentPath)) {
      permissionLog(GuardPaths.NewAgentPath.path);
      return undefined;
    }
    try {
      const response = await axios.post(BaseInfoUrls.NewAgent, data);
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async NewAgentContact(id: number, data: INewAgentContact) {
    if (!checkActionPermission(GuardPaths.NewAgentContactInfoPath)) {
      permissionLog(GuardPaths.NewAgentContactInfoPath.path);
      return undefined;
    }
    try {
      const response = await axios.post(
        BaseInfoUrls.BaseInfo.concat(`${id}${BaseInfoUrls.NewAgentContact}`),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async AllAgentContact(id: number, type: NewAgentContactType) {
    if (!checkActionPermission(GuardPaths.AllAgentContactInfoPath)) {
      permissionLog(GuardPaths.AllAgentContactInfoPath.path);
      return undefined;
    }
    try {
      const endpoint: string = BaseInfoUrls.BaseInfo.concat(`${id}`).concat(
        BaseInfoUrls.AllAgentContact.concat(`?Type=${type}`)
      );
      const response = await axios.get<IAllAgentContact[]>(endpoint);
      return response;
    } catch (err) {
      console.log(err);
    }
  }
  async AllAgents(params?: IAllAgentsParams) {
    if (!checkActionPermission(GuardPaths.AllAgentsPath)) {
      permissionLog(GuardPaths.AllAgentsPath.path);
      return undefined;
    }
    let endpoint: string = BaseInfoUrls.AllAgents;
    if (params) {
      const searchParams = new URLSearchParams(params);
      endpoint = endpoint.concat(`?${searchParams}`);
    }
    try {
      const response = await axios.get<IDataModel<IAllAgents[]>>(endpoint);
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async EditAgent(id: number, data: IUpdateAgent) {
    if (!checkActionPermission(GuardPaths.UpdateAgentPath)) {
      permissionLog(GuardPaths.UpdateAgentPath.path);
      return undefined;
    }
    try {
      const endpoint = BaseInfoUrls.BaseInfo.concat(`${id}`).concat(
        BaseInfoUrls.editAgent
      );
      const response = await axios.put<void>(endpoint, data);
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async DeleteAgentContact(id: number) {
    if (!checkActionPermission(GuardPaths.DeleteAgentContactInfoPath)) {
      permissionLog(GuardPaths.DeleteAgentContactInfoPath.path);
      return undefined;
    }
    try {
      const endpoint = BaseInfoUrls.BaseInfo.concat(`${id}`).concat(
        BaseInfoUrls.DeleteAgentContactInfo
      );
      const response = await axios.delete<void>(endpoint);
      if (response && response.status === 200) {
        return response;
      }
    } catch (err) {}
  }
  async DeleteAgent(id: number) {
    if (!checkActionPermission(GuardPaths.DeleteAgentPath)) {
      permissionLog(GuardPaths.DeleteAgentPath.path);
      return undefined;
    }
    try {
      const endpoint = BaseInfoUrls.BaseInfo.concat(`${id}`).concat(
        BaseInfoUrls.DeleteAgent
      );
      const response = await axios.delete<void>(endpoint);
      if (response && response.status === 200) {
        return response;
      }
    } catch (err) {
      throw console.log(err);
    }
  }
}
