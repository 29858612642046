export enum InvoiceUrls {
  AllRequest = "Invoice/AllRequest",
  NewAllRequest = "Invoice/NewAllRequest",
  Invoice = "Invoice/",
  AttachToAllRequest = "/AttachToAllRequest",
  AllRequestZipDetail = "/AllRequestZipDetail",
  ZipFile = "/ZipFile",
  AllRequestZipAttachment = "/AllRequestZipAttachment",
  NewInvoice = "Invoice/NewInvoice",
  InvoiceByInvoiceNumber = "Invoice/InvoiceByInvoiceNumber",
  NewInvoiceArticle = "/NewInvoiceArticle",
  TotlaInvoice = "/TotlaInvoice",
  PaymanetInformation = "/PaymanetInformation",
  InvoiceAttqachment = "/InvoiceAttqachment",
  CloseAllRequestAttachment = "/CloseAllRequestAttachment",
  InvoiceFileDetail = "/InvoiceFileDetail",
  FinallyInvoice = "/FinallyInvoice",
  GetRelatedInvoice = "/GetRelatedInvoice",
  InitInvoiceInof = "Invoice/InitInvoiceInof",
  AllRequestZipAttachmentId = "/File",
  ScreenFile = "/ScreenFile",
  UpdateInvoice = "/UpdateTotalInformationInvoice",
  UpdateBasicInformationInvoice = "/UpdateBasicInformationInvoice",
  GetInvoice = "/GetInvoice",
  AllArticle = "/AllArticle",
  UpdateArticle = "/UpdateArticle",
  DeleteArticle = "/DeleteArticle",
  AllRequestFileChecker = "/AllRequestFileChecker",
  InvoiceAttachment = "/InvoiceAttachment",
  DeleteAllRequest = "/DeleteAllRequest",
  DeleteInvoice = "/DeleteInvoice",
  UpdateAllRequest = "/UpdateAllRequest",
  AttachScreenShotToAllRequest = "/V3/AttachScreenShotToAllRequest",
  DeleteAllRequestAttachment = "/DeleteAllRequestAttachment",
  AttachZipToAllRequest = "/V3/AttachZipToAllRequest",
  UpdateAllRequestZipDetail = "/V3/UpdateAllRequestZipDetail",
  UpdateAllRequestZipDetailInfo = "/V3/UpdateAllRequestZipDetailInfp",
  AllRequestZipDetailV3 = "/V3/AllRequestZipDetail",
  UpdateInvoiceComment = "/UpdateInvoiceComment",
  AllRequestOPT = "Invoice/AllRequestOPT",
}
