import { Col, Form, Input, Select, Space } from "antd";
import { FC, useEffect } from "react";
import { ZipInformationContainer } from "../../styles/zipInformation.styles";
import { FormItem } from "src/components/UiKit/FormItem";
import { SDatePicker } from "src/components/UiKit/DatePicker";
import { IZipInformation } from "../../models";
import { useEditZipFileContext } from "../../context";
import { useAddInvoices } from "src/modules/addInvoice/context";
import { batchArr } from "src/constants/batch";
import { requesterTypeArr } from "src/services/Invoice/models";
import dayjs from "dayjs";
import textAreaMaxLength from "src/constants/textAreaMaxLength";

export const ZipInformation: FC<IZipInformation> = ({ onEdit }) => {
  const {
    value: { editMode, currentRequest, updateInfoForm: form },
    func: { onFinishUpdateZipInfo },
  } = useEditZipFileContext()!;
  const {
    value: { allCurrency },
  } = useAddInvoices()!;
  const { Option } = Select;

  useEffect(() => {
    if (currentRequest) {
      form?.setFieldsValue({
        ...currentRequest,
        dueDate: dayjs(currentRequest.dueDate),
        requestDate: dayjs(currentRequest.requestDate),
      });
    }
  }, [currentRequest, form]);
  return (
    <Form form={form} onFinish={onFinishUpdateZipInfo}>
      <ZipInformationContainer gutter={32}>
        <Col span={24}>
          <div className="title">Please insert zip file’s information .</div>
        </Col>
        <Col span={12}>
          <FormItem name={"shipName"} label="Ship">
            <Select
              style={{ width: "100%" }}
              placeholder="select"
              optionLabelProp="label"
              showSearch
              className="selectOption"
              disabled
            >
              {/* <Option
                value={currentRequest?.shipName}
                label={currentRequest?.shipName}
              >
                <Space>{currentRequest?.shipName}</Space>
              </Option> */}
            </Select>
          </FormItem>
          <FormItem name={"requesterName"} label={"Requester"}>
            <Select
              style={{ width: "100%" }}
              placeholder="select"
              optionLabelProp="label"
              showSearch
              className="selectOption"
              disabled
            >
              {/* {requester?.records?.map((item, index) => (
                <Option key={index} value={item.id} label={item.acronym}>
                  <Space>{item.name}</Space>
                </Option>
              ))} */}
            </Select>
          </FormItem>
          <FormItem name={"requesterType"} label="Requester Type">
            <Select
              style={{ width: "100%" }}
              placeholder="select"
              optionLabelProp="label"
              showSearch
              className="selectOption"
              disabled
            >
              {requesterTypeArr.map((item, index) => (
                <Option key={index} value={item.value} label={item.name}>
                  <Space>{item.name}</Space>
                </Option>
              ))}
            </Select>
          </FormItem>

          <FormItem
            name={"paymentCurrencyId"}
            label="Payment currency"
            rules={[{ required: true }]}
            required={false}
          >
            <Select
              style={{ width: "100%" }}
              placeholder="select"
              optionLabelProp="label"
              showSearch
              className="selectOption"
              disabled={!editMode}
            >
              {allCurrency.map((item, index) => (
                <Option key={index} value={item.id} label={item.shortName}>
                  <Space>{item.name}</Space>
                </Option>
              ))}
            </Select>
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem name={"requestDate"} label="Requester Date">
            <SDatePicker
              style={{ width: "100%" }}
              placeholder="select"
              disabled
            />
          </FormItem>
          <FormItem
            name={"dueDate"}
            label="Due Date"
            rules={[{ required: true }]}
            required={false}
          >
            <SDatePicker
              style={{ width: "100%" }}
              placeholder="select"
              disabled={!editMode}
            />
          </FormItem>
          <FormItem name={"batch"} label="Batch">
            <Select
              style={{ width: "100%" }}
              placeholder="select"
              optionLabelProp="label"
              showSearch
              className="selectOption"
              disabled
            >
              {batchArr.map((item) => (
                <Option value={item} key={item} label={item}>
                  <Space>{item}</Space>
                </Option>
              ))}
            </Select>
          </FormItem>
        </Col>
        <Col span={24}>
          <FormItem
            name={"comment"}
            label="If this file uses description you can write your description in this field."
          >
            <Input.TextArea
              style={{ width: "100%" }}
              placeholder="Description"
              className="textArea"
              autoSize={{ maxRows: 8, minRows: 8 }}
              disabled={!editMode}
              maxLength={textAreaMaxLength}
              showCount
            />
          </FormItem>
        </Col>
      </ZipInformationContainer>
    </Form>
  );
};
