import { Tabs } from "antd";
import { styled } from "styled-components";

export const TableTabsStyles = styled(Tabs)`
  .ant-tabs-nav::before,
  .ant-tabs-bottom > .ant-tabs-nav::before {
    border-bottom: unset;
  }
  .ant-tabs-ink-bar {
    display: none !important;
  }

  .ant-tabs-tab {
    .ant-tabs-tab-btn {
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px;

      &::before {
        content: "";
        display: inline-block;
        width: 10px;
        height: 10px;
        border-radius: 100%;
        transform: translateY(-2px);
        margin-right: 8px;
      }
    }

    &:not(.ant-tabs-tab-active) .ant-tabs-tab-btn {
      color: #a3aed0;

      &::before {
        background-color: #a3aed0;
      }
    }

    &.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: #2c73fc;

      &::before {
        background-color: #2c73fc;
      }
    }
  }

  .ant-tabs-nav-wrap {
    justify-content: center;
    padding: 16px 0;
  }
  .sc-bBbNsw {
    width: 80% !important;
  }
  .sc-bjEwCx {
    width: 80% !important;
  }
  .sc-cwKisF {
    width: 80% !important;
  }
  .ant-tabs-tabpane {
    /* display: flex; */
    justify-content: center;
  }
`;
