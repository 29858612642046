import { App, Button, Col, Divider, Form, Row, Select, Space } from "antd";
import TextArea from "antd/es/input/TextArea";
import { FC, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { AddCountryModal } from "src/components/UiKit/AddCountryModal/AddCountryModal";
import { FormItem } from "src/components/UiKit/FormItem";
import { SInput } from "src/components/UiKit/Input";
import { UseAgents } from "src/modules/agents/context";
import { BaseInfoService } from "src/services/BaseInfo/BaseInfo.service";
import { IAllAgents, IUpdateAgent } from "src/services/BaseInfo/models";

export const BasicInfoEdit: FC = () => {
  const {
    value: { submitBasicInfoEdit, countryList, portList },
    dispatch: { setSubmitBasicInfoEdit },
    form: { BasicInfoEditForm },
    func: { getCountry },
  } = UseAgents();
  const location = useLocation();
  const [isModalOpenAddCountry, setIsOpenModalAddCountry] =
    useState<boolean>(false);
  const { message } = App.useApp();

  const onOpenModalAddCountry = () => {
    setIsOpenModalAddCountry(true);
  };

  const onReset = () => {
    BasicInfoEditForm?.resetFields();
  };

  const onFinish = async (value: IUpdateAgent) => {
    try {
      if (!location.state) return;
      const { EditAgent } = new BaseInfoService();
      const response = await EditAgent(
        (location.state as IAllAgents).id,
        value
      );
      if (response && response.status === 200) {
        message.success("agent successfully updated");
      }
    } catch (err) {}
  };

  const FormValue = Form.useWatch([], BasicInfoEditForm);

  useEffect(() => {
    BasicInfoEditForm?.validateFields({})
      .then(() => {
        setSubmitBasicInfoEdit(true);
      })
      .catch(() => {
        setSubmitBasicInfoEdit(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [BasicInfoEditForm, FormValue]);
  useEffect(() => {
    if (location.state) {
      BasicInfoEditForm?.setFieldsValue(location.state);
    }
  }, [BasicInfoEditForm, location.state]);
  return (
    <Form form={BasicInfoEditForm} onFinish={onFinish}>
      <div className="text-[16px] font-medium text-[#001377] my-[40px]">
        You can choose fields and edit their information.
      </div>
      <Row gutter={24}>
        <Col span={8}>
          <FormItem
            name="name"
            label="Name"
            rules={[{ required: true }]}
            required={true}
            className="w-full"
          >
            <SInput placeholder="insert" />
          </FormItem>
        </Col>
        <Col span={8}>
          <FormItem name="company" label="Company" className="w-full">
            <SInput placeholder="insert" />
          </FormItem>
        </Col>
        <Col span={8}>
          <FormItem name="countryId" label="Country" className="w-full">
            <Select
              style={{ width: "100%" }}
              placeholder="select"
              optionLabelProp="label"
              showSearch
              allowClear
              optionFilterProp="label"
              className="selectOption"
              options={countryList.map((country) => ({
                label: country.name,
                value: country.id,
              }))}
              dropdownRender={(menu) => (
                <>
                  <Space style={{ padding: "16px 8px 4px" }}>
                    <div
                      onClick={onOpenModalAddCountry}
                      className="cursor-pointer flex justify-center items-center"
                    >
                      <span className="material-icons text-[16px] text-[#001377]">
                        add
                      </span>
                      <div className="addHolder"> Add Country</div>
                    </div>
                  </Space>
                  <Divider style={{ margin: "8px 0" }} />
                  {menu}
                </>
              )}
            />
          </FormItem>
        </Col>
        <Col span={8}>
          <FormItem name="portId" label="Port" className="w-full">
            <Select
              style={{ width: "100%" }}
              placeholder="select"
              optionLabelProp="label"
              showSearch
              allowClear
              optionFilterProp="label"
              className="selectOption"
              options={portList.map((ports) => ({
                label: ports.name,
                value: ports.id,
              }))}
            />
          </FormItem>
        </Col>
        <Col span={24}>
          <FormItem name="description" label="Services" className="w-full">
            <TextArea
              placeholder="Description"
              rows={4}
              style={{
                height: 189,
                padding: 16,
                resize: "none",
                borderRadius: 12,
                background: "#F4F7FE",
              }}
            />
          </FormItem>
        </Col>
        <Col span={24}>
          <div className="flex justify-end gap-[16px] footer">
            <Button onClick={onReset} className="w-[140px]">
              Reset change
            </Button>
            <Button
              htmlType="submit"
              disabled={!submitBasicInfoEdit}
              className="w-[100px]"
              type="primary"
            >
              Save edit
            </Button>
          </div>
        </Col>
      </Row>
      <AddCountryModal
        getCountry={getCountry}
        isAddCountryModalOpen={isModalOpenAddCountry}
        setIsAddCountryModalOpen={setIsOpenModalAddCountry}
      />
    </Form>
  );
};
