import { Form, FormInstance } from "antd";
import {
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useState,
} from "react";
import { IAllVoyage } from "src/services/Operation/models/result.model";
import { ICommercialTabs } from "./models";
interface IContext {
  value: {
    activeKey: string | undefined;
    editMode: boolean;
    currentEditVoyage: IAllVoyage | undefined;
    form: FormInstance | undefined;
  };
  dispatch: {
    setActiveKey: Dispatch<SetStateAction<string | undefined>>;
    setEditMode: Dispatch<SetStateAction<boolean>>;
    setCurrentEditVoyage: Dispatch<SetStateAction<IAllVoyage | undefined>>;
  };
  func: {
    cancelEditMode: () => void;
  };
}
const defaultContext: IContext = {
  value: {
    activeKey: undefined,
    editMode: false,
    currentEditVoyage: undefined,
    form: undefined,
  },
  dispatch: {
    setActiveKey: () => {},
    setEditMode: () => {},
    setCurrentEditVoyage: () => {},
  },
  func: {
    cancelEditMode: () => {},
  },
};
export const CommercialCTX = createContext<IContext>(defaultContext);

export const CommercialProvider: FC<PropsWithChildren> = ({ children }) => {
  const [activeKey, setActiveKey] = useState<string>();
  const [editMode, setEditMode] = useState<boolean>(false);
  const [currentEditVoyage, setCurrentEditVoyage] = useState<IAllVoyage>();
  const [form] = Form.useForm();
  const cancelEditMode = () => {
    setEditMode(false);
    setActiveKey(ICommercialTabs.voyageTable);
    setCurrentEditVoyage(undefined);
    form?.resetFields();
  };
  const ctxValue: IContext = {
    value: { activeKey, editMode, currentEditVoyage, form },
    dispatch: { setActiveKey, setEditMode, setCurrentEditVoyage },
    func: {
      cancelEditMode,
    },
  };
  return (
    <CommercialCTX.Provider value={ctxValue}>{children}</CommercialCTX.Provider>
  );
};

export const useCommercial = () => useContext(CommercialCTX);
