import { FC, Suspense } from "react";
import { AddShips } from "src/modules/AddShips";
import { AddShipProvider } from "src/modules/AddShips/context";
import { PreLoading } from "src/routes/Loading";

export const AddShipsPage: FC = () => {
  return (
    <Suspense fallback={<PreLoading />}>
      <AddShipProvider>
        <AddShips />
      </AddShipProvider>
    </Suspense>
  );
};
