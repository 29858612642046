import { Tabs, TabsProps } from "antd";
import { ICountryTabs } from "../models";
import { FC } from "react";
import { TabsContainer } from "src/modules/Category/styles";
import { AddNewCountry } from "./addCountry";
import { CountryTable } from "./countryTable";
import { useCountry } from "../context";
import { Guard } from "src/components/Guard";
import { GetCountryPath, NewCountryPath } from "src/services/System/guardPath";

const item: TabsProps["items"] = [
  {
    key: ICountryTabs.addNewCountry,
    label: (
      <span>
        <span className="material-icons text-[10px] p-[8px]">lens</span>
        Add new country
      </span>
    ),
    children: (
      <Guard action={NewCountryPath}>
        <AddNewCountry />
      </Guard>
    ),
  },
  {
    key: ICountryTabs.countryTable,
    label: (
      <span>
        <span className="material-icons text-[10px] p-[10px]">lens</span>
        Country table
      </span>
    ),
    children: (
      <Guard action={GetCountryPath}>
        <CountryTable />
      </Guard>
    ),
  },
];

export const CountryTabs: FC = () => {
  const {
    value: { activeKey },
    dispatch: { setActiveKey },
  } = useCountry();

  const onChange = (key: string) => {
    setActiveKey(key);
  };

  return (
    <TabsContainer>
      <Tabs
        // defaultActiveKey={defaultKey}
        onChange={onChange}
        items={item}
        activeKey={activeKey}
      />
    </TabsContainer>
  );
};
