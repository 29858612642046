import {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from "react";
import {
  AttachZipFileKeys,
  IZipInformationInput,
} from "../models/AttachZipFileKeys";

import { IRequester, IShip } from "src/services/Public/models";
import { IDataModel } from "src/services/models";
import usePublicApiStore from "src/store/publicApi";
import { AttachZipFile } from "../components/AttachZipFile/attach-zip-file";
import { Form, FormInstance } from "antd";
import { useTable } from "src/modules/Table/context";
import { useAttachInvoiceFileContext } from "src/modules/AttachInvoiceFile/context";

interface IContext {
  value: {
    activeKey: string;
    isModalOpenAttachZip: boolean;
    zipInformationInfo: IZipInformationInput | undefined;
    fileIsUploaded: boolean;
    ship: IShip[] | undefined;
    shipLoading: boolean;
    requester: IDataModel<IRequester[]> | undefined;
    requesterLoading: boolean;
    newRequestId: number;
    zipFile: Blob | undefined;
    screenshots: Blob | undefined;
    zipInformationForm: FormInstance<any>;
    zipInformationLoading: boolean;
    attachmentLoading: boolean;
  };
  dispatch: {
    setActiveKey: Dispatch<SetStateAction<string>>;
    setIsModalOpenAttachZip: Dispatch<SetStateAction<boolean>>;
    setZipInformationInfo: Dispatch<
      SetStateAction<IZipInformationInput | undefined>
    >;
    setFileIsUploaded: Dispatch<SetStateAction<boolean>>;
    setAttachmentLoading: Dispatch<SetStateAction<boolean>>;
    setZipInformationLoading: Dispatch<SetStateAction<boolean>>;
    setNewRequestId: Dispatch<SetStateAction<number>>;
    setZipFile: Dispatch<SetStateAction<Blob | undefined>>;
    setScreenshots: Dispatch<SetStateAction<Blob | undefined>>;
  };
  func: {
    onFinishAttachment: () => void;
    onBackAttachment: () => void;
  };
}

export const AttachZipFileContext = createContext<IContext | undefined>(
  undefined
);

export const AttachZipFileProvider: FC<PropsWithChildren> = ({ children }) => {
  const { ships, requesters } = usePublicApiStore();

  const [activeKey, setActiveKey] = useState<string>(
    AttachZipFileKeys.ZipInformation
  );
  const [isModalOpenAttachZip, setIsModalOpenAttachZip] =
    useState<boolean>(false);
  const [zipInformationInfo, setZipInformationInfo] =
    useState<IZipInformationInput>();
  const [fileIsUploaded, setFileIsUploaded] = useState<boolean>(false);
  const [newRequestId, setNewRequestId] = useState<number>(1);
  const [zipFile, setZipFile] = useState<Blob>();
  const [screenshots, setScreenshots] = useState<Blob>();
  const [zipInformationForm] = Form.useForm();
  const [zipInformationLoading, setZipInformationLoading] =
    useState<boolean>(false);

  const {
    func: { refetchZipFile },
  } = useTable();
  const {
    func: { refetchAllRefNumber },
  } = useAttachInvoiceFileContext()!;
  const [attachmentLoading, setAttachmentLoading] = useState<boolean>(false);

  const onFinishAttachment = () => {
    setZipInformationInfo(undefined);
    setFileIsUploaded(true);
    setIsModalOpenAttachZip(false);
    refetchZipFile();
    setZipFile(undefined);
    setScreenshots(undefined);
    setActiveKey(AttachZipFileKeys.ZipInformation);
    refetchAllRefNumber();
    zipInformationForm.resetFields();
  };

  const onBackAttachment = () => {
    setActiveKey(AttachZipFileKeys.ZipInformation);
    setZipFile(undefined);
    setScreenshots(undefined);
    setZipInformationInfo(undefined);
  };
  const contextValue: IContext = {
    value: {
      activeKey,
      isModalOpenAttachZip,
      zipInformationInfo,
      fileIsUploaded,
      ship: ships?.data,
      shipLoading: !!ships?.isLoading,
      requester: requesters?.data,
      requesterLoading: !!requesters?.isLoading,
      newRequestId,
      zipFile,
      screenshots,
      zipInformationForm,
      zipInformationLoading,
      attachmentLoading,
    },
    dispatch: {
      setActiveKey,
      setIsModalOpenAttachZip,
      setZipInformationInfo,
      setFileIsUploaded,
      setNewRequestId,
      setZipFile,
      setScreenshots,
      setZipInformationLoading,
      setAttachmentLoading,
    },
    func: {
      onFinishAttachment,
      onBackAttachment,
    },
  };
  return (
    <AttachZipFileContext.Provider value={contextValue}>
      {children}
      <AttachZipFile />
    </AttachZipFileContext.Provider>
  );
};

export const useAttachZipFileContext = () => useContext(AttachZipFileContext);
