import { Modal, Tabs, TabsProps } from "antd";
import { FC } from "react";
import { EditInvoiceContainer } from "./styles/editInvoiceContainer";
import { EditInvoiceForm } from "./components/EditInvoice";
import { EditInvoiceKeys } from "./models";
import { EditBaseInformation } from "./components/EditBaseInformation";
interface IProps {
  showModal: boolean;
  onCancelModal: () => void;
  header: string;
}

export const EditInvoice: FC<IProps> = ({
  onCancelModal,
  showModal,
  header,
}) => {
  const items: TabsProps["items"] = [
    {
      key: EditInvoiceKeys.BasicInformation,
      label: "Basic information",
      children: <EditBaseInformation onCancelModal={onCancelModal} />,
    },
    {
      key: EditInvoiceKeys.TotalInformation,
      label: "Total information",
      children: (
        <EditInvoiceForm onCancelModal={onCancelModal} header={header} />
      ),
    },
  ];
  return (
    <Modal
      open={showModal}
      closable={false}
      onCancel={onCancelModal}
      footer={null}
      width={820}
      maskClosable={false}
      title={"Edit invoice"}
      style={{ top: 64, padding: 24 }}
    >
      <EditInvoiceContainer>
        <header className="header">{header}</header>
        <Tabs items={items} centered />
      </EditInvoiceContainer>
    </Modal>
  );
};
