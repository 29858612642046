import { FC, useState } from "react";
import { Button, Col, Divider, Row, TreeSelect, Form, message } from "antd";
import { SInput } from "src/components/UiKit/Input";
import { useFrontAccess } from "src/modules/frontAccess/context";

export const AssignRoleAdd: FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const {
    value: { treeProps, frontAccessForEdit },
    func: { addRoleToFront },
  } = useFrontAccess();

  const handleAddRoleToFrontPath = () => {
    setLoading(true);
    addRoleToFront()
      .then((result) => {
        if (!result) message.error("Select Role is required.");
      })
      .finally(() => setLoading(false));
  };

  return (
    <div className="mt-6">
      <p className="font-medium text-[14px] text-[#001377]">
        Please assign roles to this name.
      </p>

      <Row gutter={[8, 16]}>
        <Col xs={24} md={10}>
          <Form.Item label="Name" colon={false} labelCol={{ span: 24 }}>
            <SInput disabled={true} defaultValue={frontAccessForEdit?.name} />
          </Form.Item>
        </Col>
        <Col xs={24} md={10}>
          <Form.Item label="Role" colon={false} labelCol={{ span: 24 }}>
            <TreeSelect
              {...treeProps}
              multiple={false}
              allowClear
              className="selectOption"
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={3}>
          <Form.Item
            className="add-role-btn"
            label="fdddddd"
            colon={false}
            labelCol={{ span: 24 }}
          >
            <Button
              loading={loading}
              type="text"
              className="h-[48px] bg-[#E4F2FF] text-[16px] text-[#2C73FC] font-medium hover:!bg-[#E4F2FF] hover:!text-[#2C73FC]"
              icon={
                <span className="material-icons text-[16px] text-[#2C73FC] font-medium">
                  add
                </span>
              }
              onClick={handleAddRoleToFrontPath}
            >
              Add
            </Button>
          </Form.Item>
        </Col>
      </Row>
      <Divider className="p-0 mt-6" />
    </div>
  );
};
