import { FC, useState } from "react";
import { useLocation } from "react-router-dom";
import { PageTitleKeysType, usePageTitle } from "../hook/usePageTitle";
import { HeaderResponsiveContainer } from "../styles/header";
import { Avatar, Col, Dropdown, MenuProps, Row } from "antd";
import { ROUTES } from "src/models/enums";
import { UserOutlined } from "@ant-design/icons";
import useGlobalStore from "src/store/global";
import { useLogout } from "src/hook";
import { EditProfile } from "src/modules/editProfile";
import { ChangePassword } from "src/modules/changePassword";

const ResponsiveHeader: FC = () => {
  let location = useLocation();
  const pageTitle = usePageTitle(location.pathname as PageTitleKeysType);
  const [showEditProfile, setShowEditProfile] = useState<boolean>(false);
  const [showChangePassword, setShowChangePassword] = useState<boolean>(false);
  const { userInfo, setShowDrawer, showDrawer } = useGlobalStore();
  const { logoutHandler } = useLogout();
  const editProfileModalHandler = () => {
    setShowEditProfile(true);
  };
  const changePasswordModalHandler = () => {
    setShowChangePassword(true);
  };
  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <div
          className="flex gap-[5px] py-[10px]"
          onClick={editProfileModalHandler}
        >
          <span className="material-icons w-[24px] h-[24px] text-[#A3AED0]">
            person
          </span>
          <div> Edit Profile</div>
        </div>
      ),
    },
    {
      type: "divider",
    },
    {
      key: "2",
      label: (
        <div
          className="flex gap-[5px] py-[10px]"
          onClick={changePasswordModalHandler}
        >
          <span className="material-icons w-[24px] h-[24px] text-[#A3AED0]">
            lock
          </span>
          Change password
        </div>
      ),
    },
    {
      type: "divider",
    },
    {
      key: "3",
      label: (
        <div onClick={logoutHandler} className="flex gap-[5px] py-[10px]">
          <span className="material-icons w-[24px] h-[24px] text-[#A3AED0]">
            logout
          </span>
          Log Out
        </div>
      ),
    },
    {
      type: "divider",
    },
  ];

  return (
    <HeaderResponsiveContainer path={location.pathname}>
      <Row className="content">
        <Col span={24}>
          <span
            className="material-icons menu"
            onClick={() => setShowDrawer(!showDrawer)}
          >
            menu
          </span>
          {location.pathname === ROUTES.home && (
            <p className="hello">{`Hi ${userInfo?.firstName || ""} ${
              userInfo?.lastName || ""
            }`}</p>
          )}
          <h2 className="title">{pageTitle}</h2>
          <div className="notice">
            <Dropdown
              menu={{ items }}
              trigger={["hover"]}
              placement="bottomLeft"
            >
              <div className="avatar cursor-pointer">
                <Avatar size={25} icon={<UserOutlined />} />
              </div>
            </Dropdown>
          </div>
        </Col>
      </Row>
      <EditProfile
        isModalOpen={showEditProfile}
        setIsModalOpen={setShowEditProfile}
      />
      <ChangePassword
        isModalOpen={showChangePassword}
        setIsModalOpen={setShowChangePassword}
      />
    </HeaderResponsiveContainer>
  );
};

export default ResponsiveHeader;
