import styled from "styled-components";

export const ReportStyle = styled.div<{
  minHeight: string | undefined;
}>`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;
  padding: 24px 16px;

  .content {
    border-radius: 8px;
    background: var(--0, #fff);
    display: flex;
    padding: 24px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    height: ${({ minHeight }) =>
      minHeight
        ? minHeight
        : `calc(
      100vh - 72px - 91px - 16px - 24px - 16px - 24px - 24px - 76px
    )`};
    overflow-y: auto;
  }
`;
