import { DatePicker, DatePickerProps } from "antd";

import { FC } from "react";
import { styled } from "styled-components";

export const SDatePicker: FC<DatePickerProps> = (props) => {
  return <DatePickerStyles {...props} />;
};

const DatePickerStyles = styled(DatePicker)`
  background: #f4f7fe !important;
  border-color: #f4f7fe !important;
  border-radius: 12px !important;
  min-height: 48px !important;
  &.ant-picker-disabled {
    background: #dadeec !important;
    border-color: #dadeec !important;
  }
`;
