import {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { Form, message } from "antd";
import { FormInstance } from "antd/lib";
import Dayjs from "dayjs";
import { useNoonReportCTX } from "src/modules/NoonReport/context";
import { NoonReportService } from "src/services/NoonReport/NoonReport.service";
import { IVoyageInfoArg } from "src/services/NoonReport/models";
import { useAddNewNoonReportCTX } from "../../context";
import { NewNoonReportTabs } from "../../model/newNoonReportTabs";

interface IContext {
  value: { loading: boolean };
  dispatch: {};
  func: {
    onFinishFlowInfo: (values: any) => void;
  };
  forms: {
    voyageForm: FormInstance;
  };
}

export const VoyageInfoCTX = createContext<IContext | undefined>(undefined);
export const VoyageInfoProvider: FC<PropsWithChildren> = ({ children }) => {
  const [loading, setLoading] = useState<boolean>(false);

  const {
    value: { selectedReport, dataSource, mode },
    dispatch: { setSelectedReport },
  } = useNoonReportCTX();
  const {
    dispatch: { setActiveTab },
  } = useAddNewNoonReportCTX();

  const [voyageForm] = Form.useForm();

  const onFinishFlowInfo = (values: IVoyageInfoArg) => {
    setActiveTab(NewNoonReportTabs.FOMGOROB);
    if (selectedReport) editVoyage(values);
    else addVoyage(values);
  };

  const addVoyage = async (values: IVoyageInfoArg) => {
    setLoading(false);
    setSelectedReport({
      id: dataSource.length + 1,
      vessel: values.vessel,
      voyage: values.voyage,
      date: values.date,
      currentSituation: values.currentSituation || 0,
      cargo: values.cargo || [],
      vesselStatus: values.vesselStatus || 0,
    });
    try {
      const { NewVoyageInfo } = new NoonReportService();
      const response = await NewVoyageInfo(values);
      if (response && response.status === 200) {
        setSelectedReport({
          id: response.data,
          vessel: values.vessel,
          voyage: values.voyage,
          date: values.date,
          currentSituation: values.currentSituation || 0,
          cargo: values.cargo || [],
          vesselStatus: values.vesselStatus || 0,
        });
        message.success("New vessels created successfully.");
        setActiveTab(NewNoonReportTabs.FOMGOROB);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const editVoyage = async (values: IVoyageInfoArg) => {
    if (!selectedReport) return;
    setLoading(false);
    try {
      const { EditVoyageInfo } = new NoonReportService();
      const response = await EditVoyageInfo(selectedReport.id, values);
      if (response && response.status === 200) {
        message.success("New vessels created successfully.");
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedReport && mode === "edit") {
      voyageForm.setFieldsValue({
        vessel: selectedReport.vessel,
        voyage: selectedReport.voyage,
        date: Dayjs(selectedReport.date),
        currentSituation: selectedReport.currentSituation || 0,
        cargo: selectedReport.cargo || [],
        vesselStatus: selectedReport.vesselStatus || 0,
      });
    }
  }, [mode, selectedReport, voyageForm]);

  const contextValue: IContext = {
    value: { loading },
    dispatch: {},
    func: {
      onFinishFlowInfo,
    },
    forms: {
      voyageForm,
    },
  };

  return (
    <VoyageInfoCTX.Provider value={contextValue}>
      {children}
    </VoyageInfoCTX.Provider>
  );
};

export const useVoyageInfo = () => useContext(VoyageInfoCTX)!;
