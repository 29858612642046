import { ROUTES } from "src/models/enums/routes";

const pageTitles = {
  [ROUTES.home]: "Welcome to SHIPRA!",
  [ROUTES.login]: "login",
  [ROUTES.ships]: "Ships",
  [ROUTES.invoices]: "Invoices",
  [ROUTES.security]: "Security",
  [ROUTES.settings]: "Settings",
  [ROUTES.table]: "Table",
  [ROUTES.Report]: "Invoice report",
  [ROUTES.ShipDailyReport]: "Ship Daily Report",
  [ROUTES.voyage]: "Voyage",
  [ROUTES.cargos]: "Cargos",
  [ROUTES.actions]: "Actions",
  [ROUTES.futurePlan]: "Future plan",
  [ROUTES.instruction]: "Instruction",
  [ROUTES.CreateUser]: "System settings",
  [ROUTES.CreateRole]: "System settings",
  [ROUTES.AssignActionToRole]: "System settings",
  [ROUTES.AssignRoleToUser]: "System settings",
  [ROUTES.Category]: "Category",
  [ROUTES.Flow]: "Flow",
  [ROUTES.AddShips]: "AddShips",
  [ROUTES.ShipManagement]: "ship management",
  [ROUTES.Supplier]: "Supplier",
  [ROUTES.SupplierView]: "Supplier view",
  [ROUTES.SupplierEdit]: "Edit Supplier",
  [ROUTES.SupplierEditPage]: "Edit Supplier",
  [ROUTES.SupplierViewPage]: "Supplier view",
  [ROUTES.Requester]: "Requester",
  [ROUTES.EditRequester]: "Edit Requester",
  [ROUTES.EditRequesterPage]: "Edit Requester",
  [ROUTES.FrontAccess]: "System settings",
  [ROUTES.Documentation]: "Documentation",
  [ROUTES.ShipDailyReportOwner]: "Ship Daily Report/Today’s reports",
  [ROUTES.ShipDailyReportShips]: "Ship Daily Report/Vessels",
  [ROUTES.ShipDailyReportCalendar]: "Daily Report’s Calendar/",
  [ROUTES.ShipDailyReports]: "Ship Daily Report/ship-name",
  [ROUTES.ShipDailyReportDetail]: "Ship Daily Report/ship-name",
  [ROUTES.NoonReport]: "Noon Report",
  [ROUTES.Port]: "Ports",
  [ROUTES.Agent]: "Agents",
  [ROUTES.AgentView]: "Agents",
  [ROUTES.AgentEdit]: "Agents",
  [ROUTES.ProfitReport]: "Profit & Loss report",
  [ROUTES.Commercial]: "Commercial",
  [ROUTES.BaseInfo]: "Basic Information",
  [ROUTES.Country]: "Country",
  [ROUTES.Currency]: "Currency",
  [ROUTES.CargoGroup]: "Cargo group",
  [ROUTES.CargoType]: "Cargo type",
  [ROUTES.Charterer]: "Charterer",
  [ROUTES.Receiver]: "Receiver",
  [ROUTES.Payer]: "Payer",
};

type PageTitleKeys = keyof typeof ROUTES;
export type PageTitleKeysType = (typeof ROUTES)[PageTitleKeys];

export const usePageTitle = (path: PageTitleKeysType) => {
  return pageTitles[
    Object.keys(pageTitles).find((key) =>
      key.replace(/[^a-zA-Z]+/g, "").includes(path.replace(/[^a-zA-Z]+/g, ""))
    ) as PageTitleKeysType
  ];
};
