import { FC } from "react";
import { DirectionProgressContainer } from "./style";

interface IProps {
  percent: number;
}
export const DirectionProgress: FC<IProps> = ({ percent }) => {
  return (
    <DirectionProgressContainer
      percent={percent <= 0 ? 0 : percent >= 100 ? 100 : percent}
    >
      <div className={`circle ${percent > 0 ? "circle-active" : ""}`} />
      <div className={`circle ${percent >= 100 ? "circle-active" : ""}`} />
      <div className="dash-line" />
      <div className="active-line">
        <div
          className={`triangle ${
            percent <= 0 ? "hidden" : percent >= 100 ? "hidden" : ""
          }`}
        />
      </div>
    </DirectionProgressContainer>
  );
};
