import { Button, Col, Form, Input, Row, Select, Space } from "antd";
import { FC, useEffect, useState } from "react";
import { FormItem } from "src/components/UiKit/FormItem";
import { VoyageContainer } from "../../../styles";
import { INewDailyReportValues } from "../../../models";
import { useDailyReport } from "../../../context";
import usePublicApiStore from "src/store/publicApi";
import { SDatePicker } from "src/components/UiKit/DatePicker";
import {
  // PositionConstant,
  TradeStatus,
  shipStatus,
} from "src/modules/ShipDailyReport/constant/shipState";
import dayjs from "dayjs";
import { SPopconfirm } from "src/modules/AddShips/components/AddNewShip/components/popconfirm";
const { Option } = Select;
export const Voyage: FC = () => {
  const {
    func: { onFinishDailyReport },
    forms: { VoyageForm },
    value: { loading },
  } = useDailyReport()!;
  const { ships } = usePublicApiStore();
  const { TextArea } = Input;

  const onFinish = (values: INewDailyReportValues) => {
    onFinishDailyReport(values);
  };

  const [submittable, setSubmittable] = useState<boolean>(false);
  const values = Form.useWatch([], VoyageForm);
  useEffect(() => {
    VoyageForm?.validateFields({ validateOnly: true }).then(
      () => {
        setSubmittable(true);
      },
      () => {
        setSubmittable(false);
      }
    );
  }, [values, VoyageForm]);
  return (
    <Form form={VoyageForm} onFinish={onFinish}>
      <VoyageContainer className="flex gap-[24px]">
        <Col xs={24} span={24}>
          <div className="titleHolder pt-[16px]">
            Please insert the voyage ship information.
          </div>
        </Col>
        <Col span={24} className=" pt-[16px]">
          <Row gutter={[24, 24]}>
            <Col xs={24} md={8}>
              <FormItem
                name={"shipId"}
                label="Ship name"
                rules={[{ required: true }]}
                className=" !mb-0"
              >
                <Select
                  allowClear
                  style={{ width: "100%" }}
                  placeholder="select"
                  optionLabelProp="label"
                  showSearch
                  className="selectOption"
                >
                  {ships?.data?.map((item, index) => (
                    <Option
                      key={index}
                      value={item.id}
                      label={item.abbreviation}
                    >
                      <Space>{item.name}</Space>
                    </Option>
                  ))}
                </Select>
              </FormItem>
            </Col>
            <Col xs={24} md={8}>
              <FormItem
                name={"reportDate"}
                label="Report date"
                rules={[{ required: true }]}
                className=" !mb-0"
                initialValue={dayjs()}
              >
                <SDatePicker className="w-[100%]" />
              </FormItem>
            </Col>
            <Col xs={24} md={8}>
              <FormItem
                name={"tradeStatus"}
                label="Trade Status"
                className=" !mb-0"
              >
                <Select
                  allowClear
                  style={{ width: "100%" }}
                  placeholder="select"
                  optionLabelProp="label"
                  showSearch
                  className="selectOption"
                >
                  {TradeStatus?.map((item, index) => (
                    <Option key={index} value={item.id} label={item.name}>
                      <Space>{item.name}</Space>
                    </Option>
                  ))}
                </Select>
              </FormItem>
            </Col>
            <Col xs={24} md={8}>
              <FormItem
                name={"shipStatus"}
                label="Ship Status"
                className=" !mb-0"
              >
                <Select
                  allowClear
                  style={{ width: "100%" }}
                  placeholder="select"
                  optionLabelProp="label"
                  showSearch
                  className="selectOption"
                >
                  {shipStatus?.map((item, index) => (
                    <Option key={index} value={item.id} label={item.name}>
                      <Space>{item.name}</Space>
                    </Option>
                  ))}
                </Select>
              </FormItem>
            </Col>
            <Col xs={0} md={16} />
            <Col xs={24} md={12}>
              <FormItem name={"voyage"} label="Voyage" className=" !mb-0">
                <TextArea
                  placeholder="Description"
                  rows={4}
                  style={{
                    height: 94,
                    resize: "none",
                    borderRadius: 12,
                    background: "#F4F7FE",
                  }}
                />
              </FormItem>
            </Col>
            <Col xs={24} md={12}>
              <FormItem
                name={"currentSituationReport"}
                label="Current Situation Report"
                className=" !mb-0"
              >
                <TextArea
                  placeholder="Description"
                  rows={4}
                  style={{
                    height: 94,
                    resize: "none",
                    borderRadius: 12,
                    background: "#F4F7FE",
                  }}
                />
              </FormItem>
            </Col>
            <Col xs={24} md={12}>
              <FormItem name={"action"} label="Action" className=" !mb-0">
                <TextArea
                  placeholder="Description"
                  rows={4}
                  style={{
                    height: 94,
                    resize: "none",
                    borderRadius: 12,
                    background: "#F4F7FE",
                  }}
                />
              </FormItem>
            </Col>
            <Col xs={24} md={12}>
              <FormItem
                name={"futurePlan"}
                label="Future plan"
                className=" !mb-0"
              >
                <TextArea
                  placeholder="Description"
                  rows={4}
                  style={{
                    height: 94,
                    resize: "none",
                    borderRadius: 12,
                    background: "#F4F7FE",
                  }}
                />
              </FormItem>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <div className=" flex gap-[16px] footer">
            <SPopconfirm
              description="Are you sure you want to reset all the information you filled out?"
              onConfirm={() => {
                VoyageForm?.resetFields();
              }}
            >
              <Button className="w-[100px]">Reset</Button>
            </SPopconfirm>
            <Button
              type="primary"
              className="w-[100px] justify-center items-center"
              onClick={VoyageForm?.submit}
              disabled={!submittable}
              loading={loading}
            >
              Finish
            </Button>
          </div>
        </Col>
      </VoyageContainer>
    </Form>
  );
};
