import { FC, useCallback, useEffect, useState } from "react";
import { BaseInformationContainer } from "./styles";
import { Button, Col, Divider, Form, Radio, Row } from "antd";
import { useAddInvoices } from "../../context";
import { Select } from "antd";
import { FormItem } from "src/components/UiKit/FormItem";
import { SInput } from "src/components/UiKit/Input";
import { SDatePicker } from "src/components/UiKit/DatePicker";
import { AddInvoiceTabEnums, IBaseInformationValue } from "../../models";
import _ from "lodash";
import { InvoiceService } from "src/services/Invoice/Invoice.service";
import {
  IInvoiceByInvoiceNumber,
  IInvoiceType,
  INewInvoice,
  InvoiceType,
} from "src/services/Invoice/models";
import { EditOutlined } from "@ant-design/icons";
import { Guard } from "src/components/Guard";
import { NewInvoicePath } from "src/services/Invoice/guardPath";
import { IAllVoyage } from "src/services/Operation/models/result.model";
import { OperationService } from "src/services/Operation/Operation.service";
import { IAllVoyageArg } from "src/services/Operation/models/args.models";

export const BaseInformation: FC = () => {
  const { InvoiceByInvoiceNumber, NewInvoice, InitInvoiceInof } =
    new InvoiceService();
  const [preInvoices, setPerInvoices] = useState<IInvoiceByInvoiceNumber[]>([]);
  const [reservedInvoices, setReservedInvoices] = useState<
    IInvoiceByInvoiceNumber[]
  >([]);
  const [search, setSearch] = useState<string>("");

  const [searchLoading, setSearchLoading] = useState<{
    [key: number]: boolean;
  }>({
    [IInvoiceType.PreInvoice]: false,
    [IInvoiceType.ReserveFund]: false,
  });

  const [loading, setLoading] = useState<boolean>(false);
  const {
    dispatch: {
      setActiveTab,
      setBaseInformationValues,
      setNewInvoiceId,
      setInvoiceInfo,
    },
    func: { onCancelModal },
    value: {
      BaseInformationForm,
      currentAttachment,
      invoiceInfo,
      supplierList,
      supplierLoading,
      showModal,
    },
  } = useAddInvoices()!;

  const invoiceType = Form.useWatch("invoiceType", BaseInformationForm);
  const fetchInvoice = _.debounce(async function (
    text: string,
    options: { ShipId: number; InvoiceType: InvoiceType }
  ) {
    setSearchLoading({ [options.InvoiceType]: true });
    InvoiceByInvoiceNumber(text, options)
      .then((res) => {
        if (res && res.data) {
          if (options.InvoiceType === IInvoiceType.PreInvoice) {
            setPerInvoices(res.data);
          }
          if (options.InvoiceType === IInvoiceType.ReserveFund) {
            setReservedInvoices(res.data);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setSearchLoading({ [options.InvoiceType]: false });
      });
  },
  200);
  const [invoiceInfoLoading, setInvoiceInfoLoading] = useState<boolean>(false);
  const onFinish = async (values: IBaseInformationValue) => {
    if (!currentAttachment) return console.log("you have no attachments");
    try {
      setLoading(true);
      const parentId = [];
      if (values.preInvParentId && values.preInvParentId.length > 0) {
        values.preInvParentId.forEach((id) => {
          parentId.push(id);
        });
      }
      if (values.reserveParentId && values.reserveParentId > 0) {
        parentId.push(values.reserveParentId);
      }
      const reqBody: INewInvoice = {
        allRequestAttachmentId: currentAttachment.id,
        shmInvoiceDate: values.shmInvoiceDate
          ? values.shmInvoiceDate.format("YYYY-MM-DD")
          : null,
        suppliernvoiceDate: values.suppliernvoiceDate
          ? values.suppliernvoiceDate?.format("YYYY-MM-DD")
          : null,
        parentId,
        invoiceType: values.invoiceType,
        shmInvoiceNumber: values.shmInvoiceNumber,
        supplierInvoiceNumber: values.supplierInvoiceNumber,
        supplierId: values.supplierId,
        ourDate: values.ourDate ? values.ourDate.format("YYYY-MM-DD") : null,
        voyageId: values.voyageId,
      };
      const response = await NewInvoice(reqBody);
      if (response && response.data) {
        setBaseInformationValues(values);
        setActiveTab(AddInvoiceTabEnums.Description);
        setNewInvoiceId(response.data);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  const fetchInitInvoiceInof = useCallback(async () => {
    if (currentAttachment?.allRequestId) {
      try {
        setInvoiceInfoLoading(true);
        const res = await InitInvoiceInof(currentAttachment.id);
        if (res && res.data) {
          setInvoiceInfo(res.data);
          BaseInformationForm.setFieldsValue({
            ship: res.data.shipName,
            Requester: res.data.requesterName,
          });
          // voyageForm.setFieldsValue(res.data);
        }
      } catch (err) {
        console.log(err);
      } finally {
        setInvoiceInfoLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAttachment?.allRequestId, currentAttachment?.id]);
  const onSearch = (text: string, InvoiceType: InvoiceType) => {
    if (!invoiceInfo) return;
    setSearch(text);
    if (text) fetchInvoice(text, { ShipId: invoiceInfo?.shipId, InvoiceType });
  };
  useEffect(() => {
    if (showModal) fetchInitInvoiceInof();
  }, [fetchInitInvoiceInof, showModal]);

  // --------------------
  // voyage
  // --------------------

  const [voyageSearch, setVoyageSearch] = useState<string>("");
  const [voyageSearchLoading, setVoyageSearchLoading] =
    useState<boolean>(false);
  const [voyageForm] = Form.useForm();
  const [allVoyage, setAllVoyage] = useState<IAllVoyage[]>([]);

  const fetchVoyage = _.debounce(async function (VoyageNO?: string) {
    if (!invoiceInfo) return;
    setVoyageSearchLoading(true);
    const { AllVoyage } = new OperationService();
    const params: IAllVoyageArg = {
      VoyageNO,
      ShipId: invoiceInfo?.shipId,
    };
    AllVoyage(params)
      .then((res) => {
        if (res && res.data) {
          setAllVoyage(res.data.records);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setVoyageSearchLoading(false);
      });
  }, 200);

  const onSearchVoyage = (text: string) => {
    setVoyageSearch(text);
    if (text) fetchVoyage(text);
  };

  useEffect(() => {
    if (showModal) fetchVoyage(undefined);
    else voyageForm.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal, invoiceInfo]);

  return (
    <Form onFinish={onFinish} form={BaseInformationForm}>
      <BaseInformationContainer>
        <Col span={24} className="">
          <Row>
            <Col span={12}>
              <div className="title">Please select the kind of invoice.</div>
              <FormItem
                name={"invoiceType"}
                style={{ marginBottom: "unset", marginTop: "24px" }}
                initialValue={1}
                rules={[{ required: true }]}
                required={false}
              >
                <Radio.Group className="radio">
                  <Radio value={1}>invoice</Radio>
                  <Radio value={2}>pre-invoice</Radio>
                  <Radio value={3}>Reserve fund</Radio>
                </Radio.Group>
              </FormItem>
            </Col>
            {/* ////// voyage /////// */}
            <Col span={12} className=" ps-[12px]">
              <div className="relatedInvoice ps-[12px] pb-[8px]">
                Related Voyage No.
              </div>
              <FormItem style={{ marginBottom: "unset" }} name="voyageId">
                <Select
                  placeholder="insert"
                  onSearch={(text) => onSearchVoyage(text)}
                  optionLabelProp="label"
                  showSearch
                  allowClear
                  loading={voyageSearchLoading || invoiceInfoLoading}
                  className="selectOption"
                  filterOption={false}
                  options={allVoyage.map((voyage) => ({
                    label: voyage.voyageNO,
                    value: voyage.id,
                  }))}
                  notFoundContent={
                    !voyageSearch ? (
                      <div className="flex flex-col items-center justify-center py-[4px] gap-[16px]">
                        <EditOutlined className="text-[#00000030] text-[32px]" />
                        <div className="text-center text-[#00000030] text-[12px]">
                          Please write Related Voyage No. (Enter at least four
                          letters).
                        </div>
                      </div>
                    ) : undefined
                  }
                />
              </FormItem>
            </Col>
          </Row>
        </Col>
        <Col span={24} className="mt-[24px]">
          <Row gutter={24}>
            <Col span={12} className=" ">
              <div className="relatedInvoice ps-[12px] pb-[8px]">
                Related Reserve fund No.
              </div>
              <FormItem
                style={{ marginBottom: "unset" }}
                name="reserveParentId"
              >
                <Select
                  placeholder="insert"
                  onSearch={(text) => onSearch(text, IInvoiceType.ReserveFund)}
                  optionLabelProp="label"
                  showSearch
                  allowClear
                  loading={
                    searchLoading[IInvoiceType.ReserveFund] ||
                    invoiceInfoLoading
                  }
                  className="selectOption"
                  disabled={invoiceType !== 1}
                  filterOption={false}
                  options={reservedInvoices.map((invoice) => ({
                    label: invoice.invoiceNumber,
                    value: invoice.id,
                  }))}
                  notFoundContent={
                    !search ? (
                      <div className="flex flex-col items-center justify-center py-[4px] gap-[16px]">
                        <EditOutlined className="text-[#00000030] text-[32px]" />
                        <div className="text-center text-[#00000030] text-[12px]">
                          Please write Related Reserve fund No (Enter at least
                          four letters).
                        </div>
                      </div>
                    ) : undefined
                  }
                />
              </FormItem>
            </Col>
            <Col span={12} className=" ">
              <div className="relatedInvoice ps-[12px] pb-[8px]">
                Related pre-invoice (s) No.
              </div>
              <FormItem style={{ marginBottom: "unset" }} name="preInvParentId">
                <Select
                  placeholder="insert"
                  onSearch={(text) => onSearch(text, IInvoiceType.PreInvoice)}
                  optionLabelProp="label"
                  showSearch
                  allowClear
                  mode="multiple"
                  loading={
                    searchLoading[IInvoiceType.PreInvoice] || invoiceInfoLoading
                  }
                  className="selectOption"
                  disabled={invoiceType !== 1}
                  filterOption={false}
                  options={preInvoices.map((invoice) => ({
                    label: invoice.invoiceNumber,
                    value: invoice.id,
                  }))}
                  notFoundContent={
                    !search ? (
                      <div className="flex flex-col items-center justify-center py-[4px] gap-[16px]">
                        <EditOutlined className="text-[#00000030] text-[32px]" />
                        <div className="text-center text-[#00000030] text-[12px]">
                          Please write Related pre-invoice No (Enter at least
                          four letters).
                        </div>
                      </div>
                    ) : undefined
                  }
                />
              </FormItem>
            </Col>
          </Row>
        </Col>

        <Col span={24}>
          <Divider />
        </Col>
        <Col span={24}>
          <div className="invoice-base-info">
            Please insert invoice’s base information .
          </div>
          <Row gutter={32}>
            <Col span={12} className="mt-[16px]">
              <FormItem
                name={"ship"}
                label="Ship"
                rules={[{ required: true }]}
                required={false}
              >
                <SInput disabled={true} />
              </FormItem>
              <FormItem
                name={"Requester"}
                label="Requester"
                rules={[{ required: true }]}
                required={false}
              >
                <SInput disabled={true} />
              </FormItem>
              <FormItem
                name={"shmInvoiceNumber"}
                label="Requester Invoice number"
                // rules={[{ required: true }]}
                // required={false}
              >
                <SInput
                  style={{ width: "100%" }}
                  placeholder="insert"
                  // numbermode
                />
              </FormItem>
              <FormItem
                name={"supplierInvoiceNumber"}
                label="Supplier Invoice number"
                // required={false}
              >
                <SInput
                  style={{ width: "100%" }}
                  placeholder="insert"
                  // numbermode
                />
              </FormItem>
            </Col>
            <Col span={12} className="mt-[16px]">
              <FormItem
                name={"supplierId"}
                label="Supplier"
                // rules={[{ required: true }]}
                // required={false}
              >
                <Select
                  placeholder="select"
                  allowClear
                  className="selectOption"
                  showSearch
                  optionFilterProp="label"
                  options={supplierList?.map((supp) => ({
                    label: supp.name,
                    value: supp.id,
                  }))}
                  loading={supplierLoading}
                />
              </FormItem>
              <FormItem
                name={"shmInvoiceDate"}
                label="Requester invoice date"
                // rules={[{ required: true }]}
                // required={false}
              >
                <SDatePicker style={{ width: "100%" }} placeholder="select" />
              </FormItem>
              <FormItem
                name={"suppliernvoiceDate"}
                label="Supplier invoice date"
                // required={false}
              >
                <SDatePicker style={{ width: "100%" }} placeholder="select" />
              </FormItem>
              <FormItem name={"ourDate"} label="Our date">
                <SDatePicker style={{ width: "100%" }} placeholder="select" />
              </FormItem>
            </Col>
          </Row>
        </Col>
        <Col span={24} className="flex justify-end gap-[24px] mt-[24px] footer">
          <Button onClick={onCancelModal} htmlType="button">
            Cancel
          </Button>
          <Guard action={NewInvoicePath}>
            <Button type="primary" htmlType="submit" loading={loading}>
              Next
            </Button>
          </Guard>
        </Col>
      </BaseInformationContainer>
    </Form>
  );
};
