import { FC } from "react";
import { OwnerInstruction } from "src/modules/Owner";
import { OwnerHeader } from "src/modules/Owner/components/OwnerHeader";
import { OwnerContentStyle } from "./content.style";
import { SelectShip } from "src/modules/Owner/components/SelectShip";

export const InstructionPage: FC = () => {
  return (
    <>
      <OwnerHeader />
      <OwnerContentStyle>
        <SelectShip />

        <OwnerInstruction />
      </OwnerContentStyle>
    </>
  );
};
