import { forwardRef, useEffect, useMemo } from "react";
import { FinallyInvoiceContainer } from "./styles";
import { Col, Divider, Row, Skeleton, Table } from "antd";
import { useAddInvoices } from "../../context";
import moment from "moment";
import { getRowKey } from "src/helpers/getRowKey";
import { IInvoiceType } from "src/services/Invoice/models";
interface IProps {
  invoiceId?: number;
}
export const FinallyInvoice = forwardRef<HTMLDivElement | null, IProps>(
  (props, ref) => {
    const {
      value: {
        showInvoice,
        newInvoiceId,
        finallyInvoice,
        finallyInvoiceLoading,
        fetchRelatedInvoiceLoading,
        relatedInvoice,
      },
      func: { fetchFinallyInvoice, fetchRelatedInvoice },
    } = useAddInvoices()!;
    const { invoiceId } = props;
    useEffect(() => {
      if (showInvoice && newInvoiceId) {
        fetchFinallyInvoice(newInvoiceId);
        fetchRelatedInvoice(newInvoiceId);
      }
      if (invoiceId) {
        fetchFinallyInvoice(invoiceId);
        fetchRelatedInvoice(invoiceId);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newInvoiceId, showInvoice, invoiceId]);

    function numberWithCommas(x: number | undefined) {
      return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    const finallyTotalAmount = useMemo(() => {
      if (finallyInvoice) {
        const tax = finallyInvoice.tax;
        const discount = finallyInvoice.discount;
        const totalAmount = finallyInvoice.totalInvoiceAmount;
        return totalAmount - discount + tax;
      } else return 0;
    }, [finallyInvoice]);
    if (finallyInvoiceLoading || fetchRelatedInvoiceLoading) {
      return (
        <div>
          {Array(30)
            .fill(null)
            .map((_, i) => (
              <div key={i}>
                <Skeleton paragraph active key={i} />
                <br />
              </div>
            ))}
        </div>
      );
    }
    return (
      <FinallyInvoiceContainer ref={ref}>
        <Row align={"middle"} className="p-[24px]">
          <Col span={12}>
            <img src={"/assets/images/invoiceLogo.svg"} alt={"logo"} />
          </Col>
          <Col span={12}>
            {finallyInvoice?.createdDate && (
              <div className="headerInfo">
                <div className="headerText">Create date</div>
                <div className="headerValue">
                  {moment(finallyInvoice?.createdDate).format("YYYY.MM.DD")}
                </div>
              </div>
            )}
            {finallyInvoice?.ourDate && (
              <div className="headerInfo">
                <div className="headerText">Our date</div>
                <div className="headerValue">
                  {moment(finallyInvoice?.ourDate).format("YYYY.MM.DD")}
                </div>
              </div>
            )}
            {finallyInvoice?.shmInvoiceDate && (
              <div className="headerInfo">
                <div className="headerText">Req inv date</div>
                <div className="headerValue">
                  {moment(finallyInvoice?.shmInvoiceDate).format("YYYY.MM.DD")}
                </div>
              </div>
            )}
            {finallyInvoice?.suppliernvoiceDate && (
              <div className="headerInfo">
                <div className="headerText">Supp inv date</div>
                <div className="headerValue">
                  {moment(finallyInvoice?.suppliernvoiceDate).format(
                    "YYYY.MM.DD"
                  )}
                </div>
              </div>
            )}
          </Col>
        </Row>
        <Row className="mt-[8px]">
          <Col span={24} className="relative">
            <Divider
              orientation="right"
              className="!bg-[#2C73FC] h-[42px] !my-[5px]"
            >
              <span className="text-[60px] bg-[#fff] px-[10px] font-bold">
                {finallyInvoice?.invoiceType === IInvoiceType.Invoice
                  ? "INVOICE"
                  : finallyInvoice?.invoiceType === IInvoiceType.PreInvoice
                  ? "PRE-INVOICE"
                  : finallyInvoice?.invoiceType === IInvoiceType.ReserveFund
                  ? "RESERVE FUND"
                  : ""}
              </span>
            </Divider>
            <div className="absolute top-[12px] left-[32px]">
              <span className="text-[18px] text-white">
                {finallyInvoice?.shipAcronym}
              </span>

              <span className="text-[18px] text-white ms-[40px]">
                {finallyInvoice?.referenceNumber}
              </span>
            </div>
          </Col>
        </Row>
        <Row className="mt-[32px] px-[24px]">
          <Col span={24} className=" bg-[#F2F7FF] py-[8px] rounded-[8px]">
            <div className="p-[16px] flex justify-start">
              <div className="flex flex-col gap-[12px]">
                <div className="item">
                  <div className="itemKey">Requester inv No.</div>
                  <div className="itemValue w-[158px]">
                    {finallyInvoice?.shmInvoiceNumber}
                  </div>
                </div>
                <div className="item">
                  <div className="itemKey">Supp inv No.</div>
                  <div className="itemValue  w-[158px]">
                    {finallyInvoice?.supplierInvoiceNumber}
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-[12px]">
                <div className="item">
                  <div className="itemKey">Requester</div>
                  <div className="itemValue">
                    {finallyInvoice?.requesterAcronym}
                  </div>
                </div>
                <div className="item">
                  <div className="itemKey">Supplier</div>
                  <div className="itemValue">
                    {finallyInvoice?.supplierName}
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="mt-[32px] p-[24px]">
          <Col span={24} className="px-[24px]">
            <Table
              columns={tableColumn}
              dataSource={finallyInvoice?.articles.map((item, index) => {
                item["#"] = index + 1;
                return item;
              })}
              rowKey={getRowKey}
              pagination={false}
              className="article-table"
            />
            <Divider className="bg-[#000]" />
            <div className="p-[16px] ps-0 flex justify-between ">
              <div className="bg-[#F2F7FF] rounded-[4px] p-[16px]">
                <div className="flex">
                  <div className="p-[8px] text-[#1B2559] w-[153px] text-[12px]">
                    Supplier total amount
                  </div>
                  <div className="px-[16px] py-[8px] text-[#5D6881] text-[12px] w-[85px] text-right">
                    {numberWithCommas(finallyInvoice?.supplireToTalAmount)}
                  </div>
                  <div className="px-[16px] py-[8px] text-[#5D6881] text-[12px] w-[80px]">
                    {finallyInvoice?.invoiceCurrencyShortname}
                  </div>
                </div>
                <div className="flex">
                  <div className="p-[8px] text-[#1B2559] w-[153px] text-[12px]">
                    Difference
                  </div>
                  <div className="px-[16px] py-[8px] text-[#5D6881] text-[12px] w-[85px] text-right">
                    {numberWithCommas(
                      finallyInvoice?.diffrenceWithSupplierTotla
                    )}
                  </div>
                  <div className="px-[16px] py-[8px] text-[#5D6881] text-[12px] w-[80px]">
                    {finallyInvoice?.invoiceCurrencyShortname}
                  </div>
                </div>
                <div className="flex">
                  <div className="p-[8px] text-[#1B2559] w-[153px] text-[12px]">
                    Requester total amount
                  </div>
                  <div className="px-[16px] py-[8px] text-[#5D6881] text-[12px] w-[85px] text-right">
                    {numberWithCommas(
                      finallyInvoice?.shipManagementTotlaAmount
                    )}
                  </div>
                  <div className="px-[16px] py-[8px] text-[#5D6881] text-[12px] w-[80px]">
                    {finallyInvoice?.invoiceCurrencyShortname}
                  </div>
                </div>
                <div className="flex">
                  <div className="p-[8px] text-[#1B2559] w-[153px] text-[12px]">
                    Difference
                  </div>
                  <div className="px-[16px] py-[8px] text-[#5D6881] text-[12px] w-[85px] text-right">
                    {numberWithCommas(
                      finallyInvoice?.diffrenceWithShipManagementTotla
                    )}
                  </div>
                  <div className="px-[16px] py-[8px] text-[#5D6881] text-[12px] w-[80px]">
                    {finallyInvoice?.invoiceCurrencyShortname}
                  </div>
                </div>
              </div>
              <div className="min-w-[250px]">
                <div className="flex justify-between ps-[24px]">
                  <div className="p-[8px] text-[#1B2559]  text-[12px] font-bold">
                    SUBTOTAL
                  </div>
                  <div className="px-[16px] py-[8px] text-[#5D6881] text-[12px] w-[105px] text-right">
                    {numberWithCommas(finallyInvoice?.totalInvoiceAmount)}
                  </div>
                </div>
                <div className="flex justify-between ps-[24px]">
                  <div className="p-[8px] text-[#1B2559]  text-[12px] font-bold">
                    Discount
                  </div>
                  <div className="px-[16px] py-[8px] text-[#5D6881] text-[12px] w-[105px] text-right">
                    {numberWithCommas(finallyInvoice?.discount)}
                  </div>
                </div>
                <div className="flex justify-between ps-[24px]">
                  <div className="p-[8px] text-[#1B2559]  text-[12px] font-bold">
                    Tax
                  </div>
                  <div className="px-[16px] py-[8px] text-[#5D6881] text-[12px] w-[105px] text-right">
                    {numberWithCommas(finallyInvoice?.tax)}
                  </div>
                </div>
                <div className="flex bg-[#2C73FC] h-[46px] items-center absolute bottom-[18px] right-[-24px] w-[303px] ps-3">
                  <div className="p-[8px] text-[#fff]  text-[12px] font-bold">
                    TOTAL ({finallyInvoice?.invoiceCurrencyShortname})
                  </div>
                  <div className="px-[16px] py-[8px] text-[#fff] text-[12px] w-[105px] text-right">
                    {numberWithCommas(finallyTotalAmount)}
                  </div>

                  <div className="w-[40px] "></div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Divider />
        </Row>
        <Row className="px-[48px]" gutter={[0, 32]}>
          <Col
            span={24}
            className="bg-[#F2F7FF] rounded-[8px] p-[32px] flex justify-between"
          >
            <div className="w-[355px]">
              <div className="flex justify-between items-center">
                <div className="item !text-[14px] py-[6px] w-[300px]">
                  Total inv. amount Req pay currency
                </div>
                <div className=" w-[85px] totalInvValue flex justify-start">
                  {numberWithCommas(
                    finallyInvoice?.tiaShipManagementPCurreccny
                  )}
                </div>
              </div>
              <div className="flex justify-between items-center">
                <div className="item !text-[14px] py-[6px] w-[300px]">
                  Payment currency
                </div>
                <div className=" w-[85px] totalInvValue flex justify-start">
                  {finallyInvoice?.paymentCurrencyShortname}
                </div>
              </div>
              <div className="flex justify-between items-center">
                <div className="item !text-[14px] py-[6px] w-[300px]">
                  Due date
                </div>
                <div className=" w-[85px] totalInvValue flex justify-start">
                  {finallyInvoice?.dueDate
                    ? moment(finallyInvoice?.dueDate).format("YYYY.MM.DD")
                    : ""}
                </div>
              </div>
            </div>
            <div className="w-[260px]">
              <div className="flex justify-between items-center">
                <div className="item !text-[14px] py-[6px]">
                  Supervisor Rate
                </div>
                <div className=" w-[85px] totalInvValue">
                  {finallyInvoice?.supervicorRate}
                </div>
              </div>
              <div className="flex justify-between items-center ">
                <div className="item !text-[14px] py-[6px]">EXC amount</div>
                <div className=" w-[85px] totalInvValue">
                  {numberWithCommas(finallyInvoice?.excAmount)}
                </div>
              </div>
              <div className="flex justify-between items-center">
                <div className="item !text-[14px] py-[6px]">Rate state</div>
                <div className=" w-[85px] totalInvValue">
                  {finallyInvoice?.rateState}
                </div>
              </div>
            </div>
          </Col>

          {(finallyInvoice?.voyageNO ||
            (relatedInvoice &&
              (relatedInvoice.reservedFund.length > 0 ||
                relatedInvoice.preInvoices.length > 0))) && (
            <Col span={24}>
              <div className="comment mb-[12px]">Related to</div>
              <div className=" bg-[#F2F7FF] px-[22px] py-[16px] rounded-[8px] ">
                {finallyInvoice?.voyageNO && (
                  <div className="relatedItem mb-[16px]">
                    <div className="relatedKey ">Related Voyage No.</div>
                    <div className="flex flex-wrap gap-[10px]">
                      <div className="relatedValue">
                        {finallyInvoice?.voyageNO}
                      </div>
                    </div>
                  </div>
                )}
                {relatedInvoice && relatedInvoice.reservedFund.length > 0 && (
                  <div className="relatedItem mb-[16px]">
                    <div className="relatedKey ">Related Reserve fund No.</div>
                    <div className="flex flex-wrap gap-[10px]">
                      {relatedInvoice.reservedFund.map((item, index) => (
                        <div className="relatedValue" key={index}>
                          {item.invoiceNumber}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                {relatedInvoice && relatedInvoice.preInvoices.length > 0 && (
                  <div className="relatedItem">
                    <div className="relatedKey ">
                      Related pre-invoice (s) No.
                    </div>
                    <div className="flex flex-wrap gap-[10px]">
                      {relatedInvoice.preInvoices.map((item, index) => (
                        <div className="relatedValue" key={index}>
                          {item.invoiceNumber}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </Col>
          )}
          <Col span={24}>
            <div className="comment">Comment</div>
            <div className="mt-[16px] px-[12px] py-[16px] bg-[#F4F7FE] rounded-[12px] min-h-[170px]">
              {finallyInvoice?.invoiceComment}
            </div>
          </Col>
        </Row>
      </FinallyInvoiceContainer>
    );
  }
);

const tableColumn = [
  {
    key: "#",
    title: "#",
    dataIndex: "#",
  },
  {
    title: "Description",
    key: "comment",
    dataIndex: "comment",
    width: 200,
  },
  {
    title: "Quantity",
    key: "quantity",
    dataIndex: "quantity",
    // width: 91,
  },
  {
    title: "Unit Quantity",
    key: "unitQuantity",
    dataIndex: "unitQuantity",
    // width: 121,
  },
  {
    title: "Category",
    key: "categoryName",
    dataIndex: "categoryName",
    // width: 93,
  },
  {
    title: "CategoryCode",
    key: "costCode",
    dataIndex: "costCode",
    // width: 93,
  },
  {
    title: "Unit Price",
    key: "unitPrice",
    dataIndex: "unitPrice",
    // width: 94,
  },
  {
    title: "Amount",
    key: "amount",
    dataIndex: "amount",
    // width: 104,
  },
];
