import { FC } from "react";
import { Guard } from "src/components/Guard";
import { useShipManagement } from "src/modules/shipManagement/context";
import { EditShipManagementPath } from "src/services/Ship/guardPath";

export const TopBar: FC = () => {
  const {
    dispatch: { setEditMode, setTableCurrentValue, setShowMode },
    value: { tableCurrentValue },
  } = useShipManagement()!;
  return (
    <div className="flex justify-between items-center topBarHolder">
      <div className="flex flex-col">
        <div className="text-[#001377] text-[18px]">
          ship management information
        </div>
        <div className="text-[#5D6881] text-[14px]">
          {tableCurrentValue?.name}
        </div>
      </div>
      <div>
        <Guard action={EditShipManagementPath}>
          <span
            className="material-icons flex items-center cursor-pointer"
            onClick={() => {
              setEditMode(true);
              setShowMode(false);
              setTableCurrentValue(tableCurrentValue);
            }}
          >
            edit_note
          </span>
        </Guard>
      </div>
    </div>
  );
};
