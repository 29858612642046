import styled from "styled-components";

export const StylesContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  .ant-tabs-nav {
    height: 80px;
    padding-bottom: unset !important;
  }

  .ant-tabs-ink-bar {
    height: 2px !important;
  }
`;
