import {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { Form, message } from "antd";
import type { FormInstance } from "antd";
import { IGetCountry } from "src/services/System/models";
import { SystemService } from "src/services/System/System.service";
import { BaseInfoService } from "src/services/BaseInfo/BaseInfo.service";
import { INewPort, IPort } from "src/services/BaseInfo/models";
import { PortsTabs } from "../models";
import { IPagination } from "src/models/interfaces/pagination";
import { omit } from "lodash";
import dayjs, { Dayjs } from "dayjs";
import { checkActionPermission } from "src/helpers/checkPermissionFunc";
import { NewPortPath } from "src/services/BaseInfo/guardPath";

interface IFormValue {
  countryId: number;
  name: string;
  abbrivation: string;
  latLong: string;
  description: string;
  timeZone: Dayjs;
  localTimeS: boolean;
}
interface ISelectedPort extends IFormValue {
  id: number;
}
interface IContext {
  value: {
    formMode: "add" | "edit" | "view";
    tableData: IPort[];
    loadings: ILoadingsProps;
    countryList: IGetCountry[];
    activeKey: string;
    form: FormInstance;
    selectedPort: ISelectedPort | undefined;
    showConfirm: boolean;
    pagination: IPagination;
  };
  dispatch: {
    setFormMode: Dispatch<SetStateAction<"add" | "edit" | "view">>;
    setActiveKey: Dispatch<SetStateAction<string>>;
    setShowConfirm: Dispatch<SetStateAction<boolean>>;
    setPagination: Dispatch<SetStateAction<IPagination>>;
  };
  func: {
    onFinishForm: (values: IFormValue) => void;
    onEditView: (type: "edit" | "view", port: IPort) => void;
    handleBack: () => void;
    deletePort: (id: number) => void;
  };
}

interface ILoadingsProps {
  table: boolean;
  country: boolean;
  addEdit: boolean;
  delete: boolean;
}

const defaultLoadings: ILoadingsProps = {
  table: false,
  country: false,
  addEdit: false,
  delete: false,
};

export const PortsContext = createContext<IContext | undefined>(undefined);
const addNewPortAccess = checkActionPermission(NewPortPath);

export const PortsProvider: FC<PropsWithChildren> = ({ children }) => {
  const [formMode, setFormMode] = useState<"add" | "edit" | "view">("add");
  const [countryList, setCountryList] = useState<IGetCountry[]>([]);
  const [activeKey, setActiveKey] = useState<string>(
    addNewPortAccess ? PortsTabs.Add : PortsTabs.Table
  );
  const [loadings, setLoadings] = useState<ILoadingsProps>(defaultLoadings);
  const [tableData, setTableData] = useState<IPort[]>([]);

  const [pagination, setPagination] = useState<IPagination>({
    Limit: 10,
    Offset: 1,
  });
  const [selectedPort, setSelectedPort] = useState<ISelectedPort>();
  const [showConfirm, setShowConfirm] = useState<boolean>(false);

  const [form] = Form.useForm();

  const handleBack = () => {
    form.resetFields();
    setActiveKey(PortsTabs.Table);
    setFormMode("add");
    setSelectedPort(undefined);
  };

  const onEditView = (type: "edit" | "view", port: IPort) => {
    setActiveKey(PortsTabs.Add);
    setFormMode(type);
    const currentPort: ISelectedPort = {
      ...port,
      latLong: `${port.lat}/${port.long}`,
      timeZone: dayjs(`${port.localTimeH}:${port.localTimeM}`, "HH:mm"),
    };
    setSelectedPort(currentPort);
    form.setFieldsValue(currentPort);
  };

  const onFinishForm = async (values: IFormValue) => {
    if (formMode === "add") addPort(values);
    else if (formMode === "edit") editPort(values);
  };

  const editPort = async (values: IFormValue) => {
    if (!selectedPort) return;
    setLoadings((prev) => ({ ...prev, addEdit: true }));
    const { EditPort } = new BaseInfoService();
    const {
      abbrivation,
      countryId,
      description,
      latLong,
      name,
      localTimeS,
      timeZone,
    } = values;
    const latLongList = latLong.split("/");

    const reqBody: INewPort = {
      name,
      abbrivation,
      description,
      countryId,
      lat: latLongList.length > 0 ? +latLongList[0] : null,
      long: latLongList.length > 0 ? +latLongList[1] : null,
      localTimeH: timeZone.get("hour"),
      localTimeM: timeZone.get("minute"),
      localTimeS: localTimeS,
    };
    try {
      const response = await EditPort(selectedPort.id, reqBody);
      if (response && response.status === 200) {
        message.success("Edit Port successfully saved!");
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoadings((prev) => ({ ...prev, addEdit: false }));
    }
  };

  const deletePort = async (id: number) => {
    setLoadings((prev) => ({ ...prev, delete: true }));
    try {
      const { DeletePort } = new BaseInfoService();
      const response = await DeletePort(id);
      if (response && response.status === 200) {
        message.success("Port successfully deleted");
        fetchTableData();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoadings((prev) => ({ ...prev, delete: false }));
    }
  };

  const addPort = async (values: IFormValue) => {
    setLoadings((prev) => ({ ...prev, addEdit: true }));
    const { NewPort } = new BaseInfoService();
    const {
      abbrivation,
      countryId,
      description,
      latLong,
      name,
      localTimeS,
      timeZone,
    } = values;
    const latLongList = latLong.split("/");
    const reqBody: INewPort = {
      name,
      abbrivation,
      description,
      countryId,
      lat: latLongList.length > 0 ? +latLongList[0] : null,
      long: latLongList.length > 0 ? +latLongList[1] : null,
      localTimeH: timeZone.get("hour"),
      localTimeM: timeZone.get("minute"),
      localTimeS,
    };
    try {
      const response = await NewPort(reqBody);
      if (response && response.status === 200) {
        message.success("New Port successfully saved!");
        form.resetFields();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoadings((prev) => ({ ...prev, addEdit: false }));
    }
  };

  const fetchTableData = useCallback(async () => {
    setLoadings((prev) => ({ ...prev, table: true }));
    try {
      const { GetPort } = new BaseInfoService();
      const response = await GetPort(omit(pagination, "total"));
      if (response && response.status === 200 && response.data) {
        setTableData(response.data.records);
        setPagination((prev) => ({ ...prev, total: response.data.count }));
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoadings((prev) => ({ ...prev, table: false }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination.Limit, pagination.Offset]);

  const getCountry = async () => {
    setLoadings((prev) => ({ ...prev, country: true }));
    try {
      const { GetCountry } = new SystemService();
      const response = await GetCountry();
      if (response && response.status === 200) {
        setCountryList(response.data);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoadings((prev) => ({ ...prev, country: false }));
    }
  };

  useEffect(() => {
    if (activeKey === PortsTabs.Table) fetchTableData();
    else getCountry();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeKey, fetchTableData]);

  const ContextValue: IContext = {
    value: {
      formMode,
      tableData,
      loadings,
      countryList,
      activeKey,
      form,
      selectedPort,
      showConfirm,
      pagination,
    },
    dispatch: {
      setFormMode,
      setActiveKey,
      setShowConfirm,
      setPagination,
    },
    func: { onFinishForm, onEditView, handleBack, deletePort },
  };
  return (
    <PortsContext.Provider value={ContextValue}>
      {children}
    </PortsContext.Provider>
  );
};

export const usePorts = () => useContext(PortsContext)!;
