import { FC, useState } from "react";
import { Col, Flex } from "antd";
import { useDocumentation } from "../../context";
import DeleteFolderFileModal from "../modals/DeleteFolderFileModal";
import EditFolderFileModal from "../modals/EditFolderFileModal";
import { IDocument } from "src/services/Document/models";
import EditDescriptionModal from "../modals/EditDescriptionModal";

interface IDocumentItemsCardProps {
  item: IDocument;
}

const DocumentItemsCard: FC<IDocumentItemsCardProps> = ({ item }) => {
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const [openEditDescription, setOpenEditDescription] =
    useState<boolean>(false);
  const [downloadLoading, setDownloadLoading] = useState<boolean>(false);

  const {
    value: { selectedDoc, createdId, progress },
    dispatch: {
      setPath,
      setSelectedParentId,
      setSelectedDoc,
      setFilterValue,
      setCreatedId,
    },
    func: { downloadDocumentReq },
  } = useDocumentation();

  const handleDoubleClick = () => {
    setSelectedDoc(undefined);
    setCreatedId(0);
    if (item.isDirectory) {
      setFilterValue((prev) => ({ ...prev, ParentId: item.id }));
      setSelectedParentId((prev) => [
        ...prev,
        item.parentId === null ? 0 : item.parentId,
      ]);
      setPath((prev) => prev.concat("/" + item.name));
    }
  };

  const handleClick = () => {
    setCreatedId(0);
    setTimeout(() => {
      setSelectedDoc(item);
    }, 200);
  };

  const handleDownloadFile = () => {
    setDownloadLoading(true);
    downloadDocumentReq().finally(() => setDownloadLoading(false));
  };

  return (
    <>
      <Col
        xs={23}
        md={11}
        lg={7}
        className={`border border-solid rounded-xl h-[64px] p-3 ${
          selectedDoc && selectedDoc.id === item.id
            ? "border-[#2C73FC] bg-[#F2F7FF] relative"
            : "border-[#DADEEC]"
        } ${
          createdId === item.id
            ? "border-[#F2F7FF] bg-[#F2F7FF]"
            : "border-[#DADEEC]"
        }`}
        onDoubleClick={handleDoubleClick}
        onClick={handleClick}
      >
        <Flex align="center">
          <div className="h-[36px] w-[36px] flex items-center justify-center rounded-lg bg-[#F2F7FF]">
            {item.isDirectory ? (
              <span className="material-icons text-[#A3AED0]">folder</span>
            ) : (
              <span className="material-icons text-[#A3AED0]">article</span>
            )}
          </div>

          <div>
            <Flex vertical justify="center" className="h-[33px]">
              <span className="text-[#3E4556] text-[12px] font-bold ml-2 z-1">
                {item.name.concat(item.isDirectory ? "" : item?.extention)}
              </span>
              {item.isDirectory && (
                <Flex className="ml-2">
                  <div className="text-[10px] font-medium flex items-center">
                    <span className="material-icons text-[12px] text-[#A3AED0]">
                      folder
                    </span>
                    <span className="ml-1 text-[10px] font-medium text-[#A3AED0]">
                      ({item.directoryCount} Folder)
                    </span>
                  </div>
                  <div className="text-[10px] font-medium ml-2 flex items-center">
                    <span className="material-icons text-[12px] text-[#A3AED0]">
                      article
                    </span>
                    <span className="ml-1 text-[10px] font-medium text-[#A3AED0]">
                      ({item.fileCount} Document)
                    </span>
                  </div>
                </Flex>
              )}
            </Flex>
          </div>
        </Flex>
        <div
          className={`selected-actions-wrapper ${
            selectedDoc && selectedDoc.id === item.id ? "show" : "hide"
          }  `}
        >
          <span
            className="material-icons selected-actions text-[#1dcf0e]"
            onClick={(event) => {
              event.stopPropagation();
              setOpenEdit(true);
            }}
          >
            edit_note
          </span>
          <span
            className="material-icons selected-actions text-[#5D6881]"
            onClick={(event) => {
              event.stopPropagation();
              setOpenEditDescription(true);
            }}
          >
            message
          </span>
          <span
            className="material-icons selected-actions text-[#F64C4C]"
            onClick={(event) => {
              event.stopPropagation();
              setOpenDelete(true);
            }}
          >
            delete_sweep
          </span>
          {selectedDoc?.isDirectory === false && (
            <div className="w-[36px] h-[36px] flex justify-center items-center rounded-lg bg-[#fff] mr-[10px]">
              {downloadLoading ? (
                <span>{Math.round(progress)}%</span>
              ) : (
                <span
                  className="material-icons cursor-pointer text-[#001377] text-[16px]"
                  onClick={(event) => {
                    event.stopPropagation();
                    handleDownloadFile();
                  }}
                >
                  download
                </span>
              )}
            </div>
          )}
        </div>
      </Col>
      <DeleteFolderFileModal
        open={openDelete}
        onCancel={() => setOpenDelete(false)}
      />
      <EditFolderFileModal
        open={openEdit}
        onCancel={() => setOpenEdit(false)}
      />
      <EditDescriptionModal
        open={openEditDescription}
        onCancel={() => setOpenEditDescription(false)}
      />
    </>
  );
};

export default DocumentItemsCard;
