import { FC, ReactNode } from "react";
import { TotalCardStyle } from "./style";
import { Col, Flex } from "antd";
interface IItems {
  label: string;
  value: ReactNode;
  colors: string;
}

interface IProps {
  title: string;
  values: IItems[];
}
export const TotalCard: FC<IProps> = ({ title, values }) => {
  return (
    <TotalCardStyle>
      <Col span={24} className="title">
        {title}
      </Col>
      <Col span={24}>
        <Flex className="gap-[32px] py-[10px] mt-[16px]">
          {values.map((items, index) => (
            <Flex
              vertical
              className="items w-[33.33%] justify-center"
              key={index}
            >
              <div className="label">{items.label}</div>
              <div
                className={"value ".concat(
                  items.colors === "success"
                    ? "success"
                    : items.colors === "danger"
                    ? "danger"
                    : ""
                )}
              >
                {items.value}
              </div>
            </Flex>
          ))}
        </Flex>
      </Col>
    </TotalCardStyle>
  );
};
