import { Dayjs } from "dayjs";
import { IObject } from "src/models/interfaces";
import { InvoiceType } from "src/services/Invoice/models";
export enum AddInvoiceTabEnums {
  BaseInformation = "1",
  Description = "2",
  TotalInvoice = "3",
  PaymentInformation = "4",
}
export type AddInvoiceTabs = "1" | "2" | "3" | "4";
export type AddInvoiceTabsKey = keyof typeof AddInvoiceTabEnums;

export interface IExcelData extends IObject {
  "#": string;
  Description: string;
  Quantity: string;
  "Unit Quantity": string;
  "Unit Price": string;
  Amount: number;
  // Currency: string;
  Category: string;
  // "Sub category": string;
  "Cost code": string;
  costCodeId: number;
  // currencyId: number;
}

export interface IBaseInformationValue {
  invoiceType: InvoiceType;
  parentId: number;
  shmInvoiceNumber: string;
  supplierId: number;
  shmInvoiceDate: Dayjs;
  suppliernvoiceDate: Dayjs;
  supplierInvoiceNumber: string;
  reserveParentId: number;
  preInvParentId: number[];
  ourDate: Dayjs;
  voyageId?: number;
}

export interface ITotalInvoiceInfo {
  TotalInvoiceAmount: number;
  Discount: number;
  Tax: number;
  FinallyTotalInvoiceAmount: string;
  InvoiceCurrencyId: number;
  SupplierTotalAmount: number;
  DifferenceWithSupplierTotal: number;
  ShipManagementTotalAmount: number;
  DifferenceWithShipManagementTotal: number;
  usdRate: number;
}
export interface IPaymentInformation {
  TIASMPC: string;
  PaymentCurrency: string;
  DueDate: Dayjs;
  SupervisorRate: string;
  EXCAmount: string;
  RateState: string;
  invoiceComment: string;
}
