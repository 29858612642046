import {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { Form, message } from "antd";
import { FormInstance } from "antd/lib";
import {
  IEditFOMGOROBArgs,
  IFOMGOROB,
  IFOMGOROBArgs,
} from "src/services/NoonReport/models";
import { NoonReportService } from "src/services/NoonReport/NoonReport.service";
import { useNoonReportCTX } from "src/modules/NoonReport/context";
import { useAddNewNoonReportCTX } from "../../context";
import { NewNoonReportTabs } from "../../model/newNoonReportTabs";
interface IContext {
  value: {
    loading: boolean;
    formName: FormInstance;
    dataSource: IFOMGOROB[];
    dataLoading: boolean;
    selectedRow: IFOMGOROB | undefined;
  };
  dispatch: {
    setSelectedRow: Dispatch<SetStateAction<IFOMGOROB | undefined>>;
  };
  func: {
    onFinishForm: (values: IFOMGOROBArgs) => void;
    reset: () => void;
  };
}

export const FOMGOROBInfoCTX = createContext<IContext | undefined>(undefined);
export const FOMGOROBInfoProvider: FC<PropsWithChildren> = ({ children }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [dataSource, setDataSource] = useState<IFOMGOROB[]>([]);
  const [dataLoading, setDataLoading] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<IFOMGOROB | undefined>(
    undefined
  );

  const {
    value: { activeTab },
  } = useAddNewNoonReportCTX();

  const {
    value: { selectedReport },
  } = useNoonReportCTX();

  const [formName] = Form.useForm();

  const onFinishForm = (values: IFOMGOROBArgs) => {
    if (selectedRow) editData(values);
    else addData(values);
  };

  const addData = async (values: IFOMGOROBArgs) => {
    if (!selectedReport) return;
    alert(selectedReport.id);
    setLoading(true);
    setDataSource([
      ...dataSource,
      {
        id: dataSource.length + 1,
        noonReportId: selectedReport.id,
        type: values.type,
        LastDayROB: values.LastDayROB,
        ROB: values.ROB,
      },
    ]);
    try {
      const { NewFOMGOROB } = new NoonReportService();
      const response = await NewFOMGOROB(selectedReport.id, values);
      if (response && response.status === 200) {
        message.success("New FO,MGO ROB created successfully.");
        getFOMGOROB();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const editData = async (values: IFOMGOROBArgs) => {
    if (!selectedRow) return;
    const editBody: IEditFOMGOROBArgs = {
      noonReportId: selectedRow.id,
      ...values,
    };
    setLoading(true);
    try {
      const { EditFOMGOROB } = new NoonReportService();
      const response = await EditFOMGOROB(selectedRow.id, editBody);
      if (response && response.status === 200) {
        message.success("FO,MGO ROB edited successfully.");
        getFOMGOROB();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const getFOMGOROB = useCallback(async () => {
    if (!selectedReport) return;
    try {
      const { GetFOMGOROB } = new NoonReportService();
      const response = await GetFOMGOROB(selectedReport.id);
      if (response && response.status === 200 && response.data) {
        setDataSource(response.data);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setDataLoading(false);
      setDataSource(dumpyData);
    }
  }, [selectedReport]);

  const reset = useCallback(() => {
    setSelectedRow(undefined);
    formName.resetFields();
  }, [formName]);

  useEffect(() => {
    if (activeTab === NewNoonReportTabs.FOMGOROB) reset();
  }, [activeTab, reset]);

  useEffect(() => {
    if (selectedRow)
      formName.setFieldsValue({
        type: selectedRow.type,
        LastDayROB: selectedRow.LastDayROB,
        ROB: selectedRow.ROB,
      });
  }, [formName, selectedRow]);

  useEffect(() => {
    if (activeTab === NewNoonReportTabs.FOMGOROB && selectedReport) {
      getFOMGOROB();
    }
  }, [activeTab, getFOMGOROB, selectedReport]);

  const contextValue: IContext = {
    value: { loading, formName, dataSource, dataLoading, selectedRow },
    dispatch: { setSelectedRow },
    func: {
      onFinishForm,
      reset,
    },
  };

  return (
    <FOMGOROBInfoCTX.Provider value={contextValue}>
      {children}
    </FOMGOROBInfoCTX.Provider>
  );
};

export const useFOMGOROBInfo = () => useContext(FOMGOROBInfoCTX)!;

const dumpyData: IFOMGOROB[] = [
  { id: 1, noonReportId: 1, type: 1, LastDayROB: "dassff ggg", ROB: "700" },
];
