import { Row, Space } from "antd";
import { Form } from "react-router-dom";
import { styled } from "styled-components";

export const TopBarContainer = styled(Row)`
  .titleContainer {
    color: var(--secondary-dark-grey-900, #1b2559);
    font-family: DM Sans;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 133.333% */
    letter-spacing: -0.48px;
    position: relative;
    margin-bottom: 32px;
  }
  .ant-tabs-tab {
    margin-left: 24px !important;
    color: #a3aed0;
    color: var(--text-2, #a3aed0);
    font-family: DM Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 160% */
  }
  .ant-tabs-nav::before {
    border-bottom: 1px solid var(--shadow, #dadeec);
    /* padding-bottom: 32px !important; */
  }
  .ant-tabs-nav {
    margin-bottom: 32px !important;
  }
  .ant-tabs-nav-wrap {
    position: absolute !important;
    bottom: -2vh;
    right: 0;
    margin-bottom: 32px;
  }
  .ant-tabs-ink-bar {
    height: unset !important;
  }
`;
export const DropDownContainer = styled.div`
  display: flex;
  /* padding: 16px; */
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 8px;
  background: #d8e2ff;
  margin: 32px 0px 32px 0px;
  .title {
    color: var(--text, #001377);
    font-family: DM Sans;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .ant-collapse {
    background: #d8e2ff;
  }
  .ant-collapse-content {
    background: #fff !important;
  }
  .ant-collapse-header {
    padding: 16px !important;
    border-radius: 16px !important;
    display: flex !important;
    align-items: center !important;
  }
  .ant-collapse-expand-icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .ant-collapse-content-box {
    padding: 32px 16px 32px 16px !important;
  }
`;
export const InvoiceArticleContainer = styled(Row)`
  width: 100%;
  height: auto;
  background: #fff;
  padding: 24px;
  border-radius: 16px;
  background: var(--0, #fff);

  .footer {
    display: flex;
    padding: 32px 0px 16px 0px;
    justify-content: flex-end;
    align-items: center;
    gap: 24px;
    align-self: stretch;
    border-top: 1px solid var(--shadow, #dadeec);
  }
  .rangePicker {
    background: #f4f7fe !important;
    border-color: #f4f7fe !important;
    border-radius: 12px !important;
    height: 46px;
  }
  .rangePicker {
    input::placeholder {
      font-weight: 400;
    }
  }
`;

export const BaseInformationContainer = styled(Row)``;
export const GeneralContainer = styled(Row)`
  .rangePicker {
    background: #f4f7fe !important;
    border-color: #f4f7fe !important;
    border-radius: 12px !important;
    height: 46px;
  }
  .rangePicker {
    input::placeholder {
      font-weight: 400;
    }
  }
`;
export const SearchBoxStyles = styled(Space)`
  width: 100%;
  .ant-space-item {
    width: 100% !important;
  }
  .ant-input-affix-wrapper {
    width: 100%;
    height: 48px;
    border: unset;
    background: #f4f7fe;
    border-radius: 12px;
  }

  .ant-input,
  .ant-input-prefix {
    border: none;
    background: #f4f7fe !important;
  }

  .ant-input-prefix {
    padding: 0;
    border-radius: 10px 0 0 10px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;

    .material-icons {
      font-size: 20px;
      color: #1b2559;
    }
  }

  .ant-input {
    padding-left: 0;
    border-radius: 0 10px 10px 0;
  }
  .ant-input-affix-wrapper:focus {
    border: unset !important;
  }
`;
export const DescriptionContainer = styled(Row)`
  .inputTitle {
    color: var(--text, #001377);
    font-family: DM Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;
export const KindInvoiceContainer = styled(Row)`
  .radioInputTitle {
    color: var(--text, #001377);
    font-family: DM Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .radio {
    color: var(--text, #001377);
    font-family: DM Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .absoluteLabel {
    position: absolute;
    top: 6px;
    left: 24px;
    z-index: 10;
    white-space: nowrap;
    color: var(--text, #001377);
    font-family: DM Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;
export const DateContainer = styled(Form)`
  .dateTitle {
    color: var(--text, #001377);
    font-family: DM Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding-left: 12px;
    padding-bottom: 12px;
    margin-top: 32px;
  }
  .datePicker {
    background: #f4f7fe !important;
    border-color: #f4f7fe !important;
    border-radius: 12px !important;
    min-height: 48px !important;
    width: 100% !important;
    &.ant-picker-disabled {
      background: #dadeec !important;
      border-color: #dadeec !important;
    }
  }
  .borderBottom {
    border-bottom: 1px solid var(--strock, #f3f4f5);
    padding-bottom: 40px;
  }
`;
export const AmountInvoiceContainer = styled(Row)`
  .ant-select-arrow {
    color: #001377;
  }
  .inputTitle {
    color: var(--text, #001377);
    font-family: DM Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;
export const RateContainer = styled(Row)`
  .inputTitle {
    color: var(--text, #001377);
    font-family: DM Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;
export const InvoicesTableExpandContainer = styled.div`
  .ant-table-cell {
    background: var(--5, #f2f7ff);
  }
`;
