import { FC, useState } from "react";
import { useAssignRoleToUser } from "./context";
import { AssignRoleToUserContainer } from "./styles";
import { Col, Flex, Input, Popover } from "antd";
import { TableUI } from "src/components/UiKit/table";
import { getRowKey } from "src/helpers/getRowKey";
import { AssignRoleToUserModal } from "./components/AssignRoleToUserModal";
import { ResetPassword } from "../ResetPassword";
import "./styles/popconfirm.css";
import { FilterUserState } from "./components/FilterUserState";
import { useSearchParams } from "react-router-dom";
export const AssignRoleToUser: FC = () => {
  const {
    values: {
      tableColumns,
      dataSource,
      showAssign,
      treeData,
      currentCheckedKeys,
      loadingRolesOfAction,
      assignLoading,
      allUserQuery,
      showPassword,
      currentUser,
      pagination,
    },
    func: {
      onCancelAssign,
      onAssign,
      onCancelPassword,
      onChangeState,
      goToFirstPage,
    },
    dispatch: { setPagination },
  } = useAssignRoleToUser();
  const [openStatePopover, setOpenStatePopover] = useState<boolean>(false);
  let [searchParams] = useSearchParams();
  const [searchValue, setSearchValue] = useState<string>("");
  return (
    <AssignRoleToUserContainer justify={"center"} gutter={[0, 24]}>
      <Col span={24} className="header">
        <Flex align="center" justify="space-between">
          <div className="text-[24px] font-[700] leading-[32px] text-[#5d6881]">
            Assign role to user
          </div>
          <Flex justify="flex-start" align="center" gap={32}>
            <Input
              placeholder="Search User name then press Enter"
              prefix={
                <span className="material-icons text-[#1B2559]">search</span>
              }
              className="w-[341px] h-[46px] rounded-[10px]"
              onPressEnter={(event) => {
                const value = event.currentTarget.value;
                onChangeState("UserName", value.trim());
                goToFirstPage();
              }}
              onChange={(event) => {
                setSearchValue(event.target.value);
                if (!event.target.value.trim()) {
                  onChangeState("UserName");
                  goToFirstPage();
                }
              }}
              defaultValue={searchParams.get("UserName") || ""}
              suffix={
                <span
                  className="material-icons cursor-pointer select-none text-[#A3AED0] text-[20px]"
                  onClick={() => {
                    onChangeState("UserName", searchValue.trim());
                  }}
                >
                  subdirectory_arrow_left
                </span>
              }
            />
            <Popover
              title={"User state"}
              rootClassName="assign-role-to-user-filter-confirm"
              placement="bottom"
              content={
                <FilterUserState
                  onChange={onChangeState}
                  onCancel={() => setOpenStatePopover((prev) => !prev)}
                  goToFirstPage={goToFirstPage}
                />
              }
              zIndex={10000}
              open={openStatePopover}
            >
              <Flex
                justify="flex-start"
                align="center"
                gap={12}
                className="text-[24px] text-[#2C73FC] font-[700] select-none cursor-pointer"
                onClick={() => setOpenStatePopover((prev) => !prev)}
              >
                <span className="material-icons">filter_alt </span>
                <span className="text-[20px]">State</span>
              </Flex>
            </Popover>
          </Flex>
        </Flex>
      </Col>
      <Col span={20} order={2} className="content">
        <TableUI
          columns={tableColumns}
          dataSource={dataSource}
          loading={allUserQuery?.isFetching}
          rowKey={getRowKey}
          scroll={{ x: 800 }}
          rowClassName={(record, index) =>
            index % 2 !== 0 ? "bg-[#F2F7FF]" : ""
          }
          pagination={{
            position: ["bottomCenter"],
            showSizeChanger: true,
            total: pagination.total,
            current: pagination.current,
            pageSize: pagination.pageSize,
            onChange: (page: number, pageSize: number) => {
              onChangeState("Limit", pageSize.toString());
              onChangeState(
                "Offset",
                pageSize !== pagination.pageSize ? "1" : page.toString()
              );
              setPagination((prev) => ({
                ...prev,
                current: pageSize !== prev.pageSize ? 1 : page,
                pageSize,
              }));
            },
          }}
        />
      </Col>
      <AssignRoleToUserModal
        open={showAssign}
        onCancel={onCancelAssign}
        title="Assign Roll to user"
        treeData={treeData}
        onAssign={onAssign}
        currentCheckedKeys={currentCheckedKeys}
        loading={loadingRolesOfAction}
        confirmLoading={assignLoading}
      />
      <ResetPassword
        isModalOpen={showPassword}
        onCancel={onCancelPassword}
        currentUser={currentUser}
      />
    </AssignRoleToUserContainer>
  );
};
