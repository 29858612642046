import { Modal } from "antd";
import { FC } from "react";
import { useAddInvoiceModal } from "./context";
import { PdfFileInvoice, ZipFiles } from "../Table/components";
import { useTable } from "../Table/context";
import { AddInvoiceModalStyle } from "./style";
import { AllRequestZipAttachmentPath } from "src/services/Invoice/guardPath";

export const AddInvoiceModal: FC = () => {
  const {
    value: { isOpenModal },
    func: { closeModal },
  } = useAddInvoiceModal();
  const {
    value: { tableState, zipFileTableColumns, expandLoading },
    func: { resetTableState, onExpand, refetchZipFile },
  } = useTable();
  return (
    <Modal
      open={isOpenModal}
      onCancel={() => {
        closeModal();
        resetTableState();
      }}
      footer={null}
      title={
        tableState.activatedTabIndex === 0
          ? "Zip files table"
          : "Attachment files table"
      }
      width={"78vw"}
    >
      <AddInvoiceModalStyle>
        {tableState.activatedTabIndex === 0 ? (
          <>
            <ZipFiles
              columns={zipFileTableColumns}
              data={tableState.zipFiles}
              expandLoading={expandLoading}
              onExpand={onExpand}
              expandedActions={AllRequestZipAttachmentPath}
            />
            <span
              className="material-icons absolute top-[17px] right-[48px] text-[#ccc] hover:text-[#000] cursor-pointer  duration-[0.5s]"
              onClick={refetchZipFile}
            >
              refresh
            </span>
          </>
        ) : (
          <PdfFileInvoice showZipTabs={false} loading={expandLoading} />
        )}
      </AddInvoiceModalStyle>
    </Modal>
  );
};
