import { FC, Suspense } from "react";
import { AddNewSupplier } from "src/modules/addNewSupplier";
import { PreLoading } from "src/routes/Loading";

export const AddNewSupplierPage: FC = () => {
  return (
    <Suspense fallback={<PreLoading />}>
      <AddNewSupplier />
    </Suspense>
  );
};
