import { FC } from "react";
import { Tabs, TabsProps } from "antd";
import { HeaderContainer } from "../../styles";
import { INoonReportTabs } from "../../models/tabs";
import { AddNoonReport } from "../AddNoonReport";
import { NoonReportTable } from "../NoonReportTable";
import { useNoonReportCTX } from "../../context";
import { FilterPopUp } from "../NoonReportTable/components/FilterPopup";

export const Header: FC = () => {
  const {
    value: { activeKeyNoonReport },
    dispatch: { setActiveKeyNoonReport },
  } = useNoonReportCTX();

  const items: TabsProps["items"] = [
    {
      key: INoonReportTabs.addNewNoonReport,
      label: (
        <span>
          <span className="material-icons text-[10px] p-[8px]">lens</span>
          Add new noon report
        </span>
      ),
      children: <AddNoonReport />,
    },
    {
      key: INoonReportTabs.noonReportTable,
      label: (
        <span>
          <span className="material-icons text-[10px] p-[10px]">lens</span>
          Noon reports table
        </span>
      ),
      children: <NoonReportTable />,
    },
  ];

  const onChange = (key: string) => {
    setActiveKeyNoonReport(key);
  };

  return (
    <HeaderContainer className="relative">
      {activeKeyNoonReport === INoonReportTabs.noonReportTable ? (
        <div className="absolute right-[1%] z-10 cursor-pointer">
          <FilterPopUp>
            <div className="flex gap-[16px] items-end">
              <span className="material-icons text-[24px] text-[#2C73FC] mb-[5px]">
                filter_alt
              </span>
              <div className="text-[20px] text-[#2C73FC]">Filter</div>
            </div>
          </FilterPopUp>
        </div>
      ) : (
        ""
      )}
      <Tabs
        items={items}
        onChange={onChange}
        activeKey={activeKeyNoonReport}
        animated
        className="main-tabs"
      />
    </HeaderContainer>
  );
};
