import { Col, Form, Input, Select, Space } from "antd";
import { FC } from "react";
import { SDatePicker } from "src/components/UiKit/DatePicker";
import { useAttachZipFileContext } from "../../context/attachZipFile";
import {
  AttachZipFileKeys,
  IZipInformationInput,
} from "../../models/AttachZipFileKeys";
import { AttachZipFileContainer } from "../../styles/AttachZipFileStyles/attachzipfile.style";
import { FormItem } from "src/components/UiKit/FormItem";
import {
  INewAllRequestArgs,
  TRequesterType,
  requesterTypeArr,
} from "src/services/Invoice/models";
import { batchArr } from "src/constants/batch";
import { InvoiceService } from "src/services/Invoice/Invoice.service";
import { useAddInvoices } from "src/modules/addInvoice/context";
import textAreaMaxLength from "src/constants/textAreaMaxLength";
const { Option } = Select;
export const ZipInformation: FC = () => {
  const {
    value: {
      ship,
      shipLoading,
      requester,
      requesterLoading,
      zipInformationForm,
    },
    dispatch: {
      setZipInformationInfo,
      setActiveKey,
      setNewRequestId,
      setZipInformationLoading,
    },
  } = useAttachZipFileContext()!;
  const {
    value: { allCurrency },
  } = useAddInvoices()!;

  const onFinish = async (value: IZipInformationInput) => {
    try {
      setZipInformationLoading(true);
      const { NewAllRequest } = new InvoiceService();
      const reqBody: INewAllRequestArgs = {
        batch: value.Batch,
        comment: value.zipInformationDescription || "",
        requesterDate: value.RequesterDate?.format("YYYY-MM-DD"),
        requesterId: +value.shipManagement,
        requesterType: +value.requesterType as TRequesterType,
        shipId: +value.ship,
        dueDate: value.dueDate?.format("YYYY-MM-DD"),
        paymentCurrencyId: +value.paymentCurrencyId,
      };
      const res = await NewAllRequest(reqBody);
      if (res && res.data) {
        setNewRequestId(res.data);
        setZipInformationInfo(value);
        setActiveKey(AttachZipFileKeys.AttachZip);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setZipInformationLoading(false);
    }
  };

  return (
    <Form onFinish={onFinish} form={zipInformationForm}>
      <AttachZipFileContainer gutter={32}>
        <Col span={24}>
          <div className="title">Please insert zip file’s information .</div>
        </Col>
        <Col span={12}>
          <FormItem
            name={"ship"}
            label="Ship"
            rules={[{ required: true }]}
            required={false}
          >
            <Select
              style={{ width: "100%" }}
              placeholder="select"
              optionLabelProp="label"
              // showSearch
              className="selectOption"
              loading={shipLoading}
              allowClear
            >
              {ship?.map((item, index) => (
                <Option key={index} value={item.id} label={item.abbreviation}>
                  <Space>{item.name}</Space>
                </Option>
              ))}
            </Select>
          </FormItem>
          <FormItem
            name={"shipManagement"}
            label="Requester"
            rules={[{ required: true }]}
            required={false}
          >
            <Select
              style={{ width: "100%" }}
              placeholder="select"
              optionLabelProp="title"
              optionFilterProp="label"
              showSearch
              allowClear
              className="selectOption"
              loading={requesterLoading}
              options={requester?.records?.map((item) => ({
                title: item.acronym,
                value: item.id,
                label: item.name,
              }))}
            />
            {/* {requester?.records?.map((item, index) => (
                <Option key={index} value={item.id} label={item.acronym}>
                  <Space>{item.name}</Space>
                </Option>
              ))} */}
            {/* </Select> */}
          </FormItem>
          <FormItem
            name={"requesterType"}
            label="Requester Type"
            rules={[{ required: true }]}
            required={false}
          >
            <Select
              style={{ width: "100%" }}
              placeholder="select"
              optionLabelProp="label"
              showSearch
              allowClear
              className="selectOption"
            >
              {requesterTypeArr.map((item, index) => (
                <Option key={index} value={item.value} label={item.name}>
                  <Space>{item.name}</Space>
                </Option>
              ))}
            </Select>
          </FormItem>
          <FormItem
            name={"paymentCurrencyId"}
            label="Payment currency"
            rules={[{ required: true }]}
            required={false}
          >
            <Select
              style={{ width: "100%" }}
              placeholder="select"
              optionLabelProp="label"
              // showSearch
              className="selectOption"
              allowClear
            >
              {allCurrency.map((item, index) => (
                <Option key={index} value={item.id} label={item.shortName}>
                  <Space>{item.name}</Space>
                </Option>
              ))}
            </Select>
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem
            name={"RequesterDate"}
            label="Requester Date"
            rules={[{ required: true }]}
            required={false}
          >
            <SDatePicker style={{ width: "100%" }} placeholder="select" />
          </FormItem>
          <FormItem
            name={"dueDate"}
            label="Due date"
            rules={[{ required: true }]}
            required={false}
          >
            <SDatePicker style={{ width: "100%" }} placeholder="select" />
          </FormItem>
          <FormItem
            name={"Batch"}
            label="Batch"
            rules={[{ required: true }]}
            required={false}
          >
            <Select
              style={{ width: "100%" }}
              placeholder="select"
              optionLabelProp="label"
              showSearch
              className="selectOption"
              allowClear
            >
              {batchArr.map((item) => (
                <Option value={item} key={item} label={item}>
                  <Space>{item}</Space>
                </Option>
              ))}
            </Select>
          </FormItem>
        </Col>
        <Col span={24}>
          <FormItem
            name={"zipInformationDescription"}
            label="If this file uses description you can write your description in this field."
          >
            <Input.TextArea
              style={{ width: "100%" }}
              placeholder="Description"
              className="textArea"
              autoSize={{ maxRows: 4, minRows: 4 }}
              maxLength={textAreaMaxLength}
              showCount
            />
          </FormItem>
        </Col>
      </AttachZipFileContainer>
    </Form>
  );
};
