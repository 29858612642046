import { FC } from "react";
import { Divider } from "antd";
import { AddNoonReport } from "../AddNoonReport";
import { useNoonReportCTX } from "../../context";
import { useAddNewNoonReportCTX } from "../AddNoonReport/context";
import { INoonReportTabs } from "../../models/tabs";

export const EditNoonReport: FC = () => {
  const {
    value: { mode, selectedReport },
    func: { changeMode },
    dispatch: { setActiveKeyNoonReport },
  } = useNoonReportCTX();
  const {
    func: { reset },
  } = useAddNewNoonReportCTX();
  return (
    <div>
      <div className="flex justify-between items-center  py-[19px]">
        <div
          className="select-none cursor-pointer text-[#2C73FC] flex justify-start items-center gap-[12px] "
          onClick={() => {
            reset();
            changeMode("create");
            setActiveKeyNoonReport(INoonReportTabs.noonReportTable);
          }}
        >
          <span className="material-icons text-[24px]">keyboard_backspace</span>
          <span className="text-[18px]">Back to table</span>
        </div>
        <div>
          <div className="text-[18px] text-[#001377] font-[700] text-right">
            {mode === "edit" ? "Edit Noon Report" : "View Noon Report"}
          </div>
          <div className="text-[14px] text-[#5D6881] text-right">
            {selectedReport?.voyageName}
          </div>
        </div>
      </div>
      <Divider className="mb-[32px] mt-0" />
      <AddNoonReport />
    </div>
  );
};
