import { FC } from "react";
import { useDailyReport } from "src/modules/ShipDailyReport/context";
import {
  IEditNewDailyReport,
  IHeaderTabs,
} from "src/modules/ShipDailyReport/models";
import { EditNewDailyReportContainer } from "src/modules/ShipDailyReport/styles";
import { EditVoyage } from "./components/Voyage";

export const EditNewDailyReport: FC = () => {
  const {
    value: { dailyReportValues, tableCurrentValue, isEditClicked },
    dispatch: {
      setEditDailyReportActiveKey,
      setEditMode,
      setHeaderActiveKey,
      setIsEditClicked,
    },
  } = useDailyReport();
  // const items: TabsProps["items"] = [
  //   {
  //     key: IEditNewDailyReport.Voyage,
  //     label: "Voyage",
  //     children: <EditVoyage />,
  //     // disabled: editDailyReportActiveKey !== IEditNewDailyReport.Voyage,
  //   },
  //   {
  //     key: IEditNewDailyReport.Cargos,
  //     label: "Cargos",
  //     children: <EditCargos />,
  //     // disabled: editDailyReportActiveKey !== IEditNewDailyReport.Cargos,
  //   },
  //   {
  //     key: IEditNewDailyReport.Actions,
  //     label: "Actions",
  //     children: <EditActions />,
  //     // disabled: editDailyReportActiveKey !== IEditNewDailyReport.Actions,
  //   },
  //   {
  //     key: IEditNewDailyReport.FuturePlan,
  //     label: "Future plan",
  //     children: <EditFuturePlan />,
  //     // disabled: editDailyReportActiveKey !== IEditNewDailyReport.FuturePlan,
  //   },
  //   {
  //     key: IEditNewDailyReport.Instruction,
  //     label: "Instruction",
  //     children: <EditInstruction />,
  //     // disabled: editDailyReportActiveKey !== IEditNewDailyReport.Instruction,
  //   },
  // ];

  // const onChange = (key: string) => {
  //   setEditDailyReportActiveKey(key);
  // };
  return (
    <EditNewDailyReportContainer>
      <div className="flex justify-between py-[19px] px-[24px]">
        <div
          className="flex gap-[12px] items-center cursor-pointer mb-[32px]"
          onClick={() => {
            setIsEditClicked(false);

            setEditMode(false);
            setHeaderActiveKey(IHeaderTabs.dailyReportsTable);
            setEditDailyReportActiveKey(IEditNewDailyReport.Voyage);
          }}
        >
          <span className="material-icons text-[#2C73FC] text-[32px]">
            keyboard_backspace
          </span>
          <div className="text-[#2C73FC] text-[24px] ">
            Back to daily reports table
          </div>
        </div>
        <div className="flex flex-col">
          <div className={isEditClicked ? "shipName" : "shipNameDisable"}>
            {isEditClicked ? "Edit " : ""}
            {tableCurrentValue?.shipName} Report
          </div>
          <div
            className={isEditClicked ? "dateContainer" : "dateContainerDisable"}
          >
            {dailyReportValues.reportDate.format("YYYY-MM-DD")}
          </div>
        </div>
      </div>
      <div className="container">
        <EditVoyage />
      </div>
      {/* <Tabs
        items={items}
        onChange={onChange}
        activeKey={editDailyReportActiveKey}
        centered
        className="tabsContainer"
      /> */}
    </EditNewDailyReportContainer>
  );
};
