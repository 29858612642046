import { FC } from "react";
import { AttachFileBaseInfoContent } from "./components/content";
import { AttachFileBaseInfoContainer } from "./style";
import { AttachFileBaseInfoTable } from "./components/table";

export const AttachFileBaseInfo: FC = () => {
  return (
    <AttachFileBaseInfoContainer>
      <AttachFileBaseInfoContent />
      <AttachFileBaseInfoTable />
    </AttachFileBaseInfoContainer>
  );
};
