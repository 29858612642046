import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Row,
  Select,
  Space,
} from "antd";
import { FC, useEffect, useState } from "react";
import { useAddShip } from "src/modules/AddShips/context";
import { ShipManagementContainer } from "../styles";
import { FormItem } from "src/components/UiKit/FormItem";
import { SInput } from "src/components/UiKit/Input";
import TextArea from "antd/es/input/TextArea";
import { SPopconfirm } from "./popconfirm";
import { IAddNewShip } from "src/modules/AddShips/models";
import {
  IShipManagmentContract,
  IShipManagmentContractValues,
} from "src/services/Ship/models";
import { ShipService } from "src/services/Ship/Ship.service";
import { AddCurrencyModal } from "../../AddCurrencyModal/AddCurrencyModal";
const { RangePicker } = DatePicker;

export const ShipManagement: FC = () => {
  const [loading, setLoading] = useState<boolean>(false);

  const {
    dispatch: { setActiveKeyAddShip, setIsAddCurrencyModalOpen },
    forms: { ShipManagementForm },
    value: { allCurrency, shipManagement, shipId, currencyLoading },
    func: { fetchAllCurrency, fetchShipManagement, deleteShip },
  } = useAddShip()!;
  const [submittable, setSubmittable] = useState<boolean>(false);
  const values = Form.useWatch([], ShipManagementForm);

  useEffect(() => {
    ShipManagementForm?.validateFields({ validateOnly: true }).then(
      () => {
        setSubmittable(true);
      },
      () => {
        setSubmittable(false);
      }
    );
  }, [values, ShipManagementForm]);
  const nextTab = () => {
    setActiveKeyAddShip(IAddNewShip.shipPrice);
  };
  const onFinish = async (values: IShipManagmentContractValues) => {
    setLoading(true);
    const { ShipManagmentContract } = new ShipService();
    const reqBody: IShipManagmentContract = {
      budget: +values.budget,
      comment: values.comment,
      currencyId: values.currencyId,
      endDate: values.contractDate[1].format("YYYY-MM-DD"),
      startDate: values.contractDate[0].format("YYYY-MM-DD"),
      shipManagementId: values.shipManagementId,
    };
    try {
      const response = await ShipManagmentContract(shipId, reqBody);
      if (response && response.status === 200) {
        nextTab();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchAllCurrency();
    fetchShipManagement();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchAllCurrency]);
  return (
    <Form
      form={ShipManagementForm}
      onFinish={onFinish}
      className="flex flex-col justify-between h-[620px]"
    >
      <AddCurrencyModal />
      <ShipManagementContainer className="flex gap-[24px] content-start">
        <Col span={24}>
          <div className="titleHolder pt-[16px]">
            Please insert ship management information
          </div>
        </Col>
        <Col span={24} className="flex gap-[24px]">
          <FormItem
            name={"shipManagementId"}
            label="Ship Management"
            rules={[{ required: true }]}
            className="w-[33%] !mb-0"
          >
            <Select
              style={{ width: "100%" }}
              placeholder="select"
              optionLabelProp="title"
              optionFilterProp="label"
              showSearch
              className="selectOption"
              options={shipManagement?.map((management) => ({
                label: management.name,
                value: management.id,
                title: management.acronym,
              }))}
              allowClear
            />
          </FormItem>

          <FormItem
            name="contractDate"
            label="Start - End contract date "
            className="w-[33%]"
            rules={[{ required: true }]}
          >
            <RangePicker className="w-full" />
          </FormItem>
          <FormItem
            name={"budget"}
            label="Ship management Budget in contract "
            rules={[{ required: true }]}
            className="w-[32%] !mb-0"
          >
            <SInput placeholder="insert" numbermode />
          </FormItem>
        </Col>
        <Col span={24} className="flex gap-[24px] items-center">
          <FormItem
            name={"currencyId"}
            label="Currency"
            rules={[{ required: true }]}
            className="w-[32%] !mb-0"
          >
            <Select
              style={{ width: "100%" }}
              placeholder="select"
              optionLabelProp="label"
              showSearch
              className="selectOption"
              loading={currencyLoading}
              dropdownRender={(menu) => (
                <>
                  <Space style={{ padding: "16px 8px 4px" }}>
                    <div
                      onClick={() => setIsAddCurrencyModalOpen(true)}
                      className="cursor-pointer flex justify-center items-center"
                    >
                      <span className="material-icons text-[16px] text-[#001377]">
                        add
                      </span>
                      <div className="addHolder">Add Currency</div>
                    </div>
                  </Space>
                  <Divider style={{ margin: "8px 0" }} />
                  {menu}
                </>
              )}
              options={allCurrency.map((currency) => ({
                label: currency.shortName,
                value: currency.id,
              }))}
            />
          </FormItem>
        </Col>
        <Col span={24}>
          <FormItem name="comment" label="Description">
            <TextArea
              placeholder="description"
              rows={4}
              style={{
                height: 150,
                resize: "none",
                borderRadius: 12,
                background: "#F4F7FE",
              }}
            />
          </FormItem>
        </Col>
      </ShipManagementContainer>
      <Row>
        <Col span={24}>
          <div className="flex justify-between footer">
            <div>
              <Button
                onClick={() => deleteShip(shipId)}
                className="w-[148px] flex justify-center items-center"
              >
                Discard new ship
              </Button>
            </div>
            <div className="flex gap-[16px]">
              <SPopconfirm
                description="Are you sure you want to reset all the information you filled out?"
                onConfirm={() => {
                  ShipManagementForm?.resetFields();
                }}
              >
                <Button className="w-[100px]">Reset</Button>
              </SPopconfirm>

              <Button
                type="primary"
                className="w-[100px]"
                onClick={ShipManagementForm?.submit}
                loading={loading}
                disabled={!submittable}
              >
                Next
              </Button>
            </div>
          </div>
        </Col>
      </Row>
    </Form>
  );
};
