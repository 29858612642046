import { Button, Modal, Tabs } from "antd";
import { TabsProps } from "antd/lib";
import { FC, useState } from "react";
import { EditZipFileInformationKeys } from "./models";
import { EditZipFileContainer } from "./styles/editZipFile.styles";
import { ZipInformation } from "./components/ZipInformation/zipInformation";
import { useEditZipFileContext } from "./context";
import { AttachZipFile } from "./components/AttachZipFile";
import { DeleteAllRequest } from "./components/DeleteAllRequest";
import { Guard } from "src/components/Guard";
import {
  DeleteAllRequestPath,
  UpdateAllRequestPath,
} from "src/services/Invoice/guardPath";

export const EditZipFileInformation: FC = () => {
  const {
    value: {
      isModalOpen,
      activeKey,
      loading,
      editMode,
      updateInfoLoading,
      updateInfoForm,
      currentRequest,
      savedZipInfo,
    },
    dispatch: { setEditMode, setActiveKey, setDeleteButton },
    func: { onCancelModal, confirmDeleteAllRequest },
  } = useEditZipFileContext()!;
  const [showDeleteAllRequest, setShowDeleteAllRequest] =
    useState<boolean>(false);
  const submitEditMode = () => {
    updateInfoForm?.submit();
  };
  const changeToEditMode = () => {
    setEditMode(true);
  };

  const items: TabsProps["items"] = [
    {
      key: EditZipFileInformationKeys.ZipInformation,
      label: "Zip information",
      children: <ZipInformation />,
    },
    {
      key: EditZipFileInformationKeys.AttachZip,
      label: "Attach zip file",
      children: <AttachZipFile />,
    },
  ];
  const zipInformationFooter = (
    <div className="flex justify-end gap-[24px] w-[100%]">
      <div>
        <Guard action={UpdateAllRequestPath}>
          <Button
            type="primary"
            loading={updateInfoLoading}
            onClick={editMode ? submitEditMode : changeToEditMode}
            className={
              editMode
                ? "w-[100px] border-[#3B82F6] text-[#3B82F6] bg-[#fff] flex justify-center"
                : "w-[100px]"
            }
          >
            {editMode ? "Save Edit" : "Edit"}
          </Button>
        </Guard>
      </div>
      {!editMode && (
        <div>
          <Guard action={DeleteAllRequestPath}>
            <Button
              type="text"
              disabled={editMode}
              onClick={() => {
                setShowDeleteAllRequest(true);
                setDeleteButton(true);
              }}
              className={"w-[100px] bg-[#FF1414] text-[#ffff]"}
            >
              {editMode ? "" : "Delete"}
            </Button>
          </Guard>
        </div>
      )}
      <div>
        <Button
          disabled={updateInfoLoading}
          onClick={onCancelModal}
          className="w-[100px]"
        >
          {savedZipInfo ? "Done" : "Cancel"}
        </Button>
      </div>
    </div>
  );

  const attachZipFileFooter = (
    <div className="flex justify-end gap-[24px] w-[100%]">
      <div>
        <Button
          type="primary"
          loading={loading}
          onClick={editMode ? submitEditMode : changeToEditMode}
          className="w-[100px] border-[#3B82F6] text-[#3B82F6] bg-[#fff] flex justify-center"
        >
          Save Edit
        </Button>
      </div>
      <div>
        <Button
          disabled={loading}
          onClick={onCancelModal}
          className="w-[100px]"
        >
          {"Cancel"}
        </Button>
      </div>
    </div>
  );

  return (
    <>
      <EditZipFileContainer>
        <Modal
          width={"50%"}
          title={
            <div className="flex justify-between">
              <div className="flex">
                <span className="material-icons">attach_file</span>
                <div>Edit zip file information</div>
              </div>
              <div>{currentRequest?.uniqueCode}</div>
            </div>
          }
          open={isModalOpen}
          onCancel={onCancelModal}
          footer={
            activeKey === EditZipFileInformationKeys.ZipInformation
              ? zipInformationFooter
              : attachZipFileFooter
          }
          styles={{
            body: {
              height:
                activeKey === EditZipFileInformationKeys.ZipInformation
                  ? "825px"
                  : "625px",
            },
          }}
          closable={false}
          maskClosable={false}
        >
          <Tabs
            activeKey={activeKey}
            items={items}
            centered
            onChange={(key: string) => {
              setActiveKey(key);
            }}
          />
        </Modal>
        <DeleteAllRequest
          onCancel={() => setShowDeleteAllRequest(false)}
          show={showDeleteAllRequest}
          onConfirmDelete={() => {
            if (currentRequest) {
              confirmDeleteAllRequest(currentRequest?.id).then(() => {
                setShowDeleteAllRequest(false);
              });
            }
          }}
          uniqueCode={currentRequest?.uniqueCode || ""}
        />
      </EditZipFileContainer>
    </>
  );
};
