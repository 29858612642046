import { FC, useCallback, useState } from "react";
import { DescriptionContainer } from "./styles";
import { App, Button, Col } from "antd";
import { useAddInvoices } from "../../context";
import { AddInvoiceTabEnums, IExcelData } from "../../models";
import ExcelEditableTable from "./EditableTable";
import { INewInvoiceArticle } from "src/services/Invoice/models";
import { InvoiceService } from "src/services/Invoice/Invoice.service";
import { Guard } from "src/components/Guard";
import { NewInvoiceArticlePath } from "src/services/Invoice/guardPath";

export const ExcelFileTable: FC = () => {
  const {
    value: { excelData, newInvoiceId },
    dispatch: {
      setActiveTab,
      setFileIsUploaded,
      setExcelFile,
      setExcelData,
      setFileName,
      setNewArticle,
    },
  } = useAddInvoices()!;
  const { notification } = App.useApp();
  const [loading, setLoading] = useState<boolean>(false);
  const isValid = useCallback(() => {
    const data = excelData.filter((item) => !!item["#"]);
    const emptyFields: { row: string; field: string }[] = [];
    data.forEach((i) => {
      Object.keys(i).forEach((j) => {
        if (i[j] !== 0 && !i[j]) {
          emptyFields.push({ row: i["#"], field: j });
        }
      });
    });
    if (emptyFields.length > 0) {
      emptyFields.forEach((field) => {
        if (field.field === "costCodeId") {
          notification.error({
            message: `cannot find the cost code ID for row ${field.row}.`,
          });
          return;
        }

        notification.error({
          message: `The ${field.field} field in row ${field.row} is empty.`,
        });
      });
    }
    return emptyFields.length === 0;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [excelData]);

  const onConfirm = useCallback(async () => {
    const data: IExcelData[] = excelData.filter((item) => !!item["#"]);
    if (isValid() && newInvoiceId) {
      try {
        setLoading(true);
        const { NewInvoiceArticle } = new InvoiceService();
        const reqBody: INewInvoiceArticle = {
          articles: data.map((article) => ({
            amount: article["Amount"],
            comment: article["Description"],
            costCodeId: article["costCodeId"],
            quantity: +article["Quantity"],
            unitPrice: +article["Unit Price"],
            unitQuantity: article["Unit Quantity"],
          })),
        };
        const newInvoiceResult = await NewInvoiceArticle(newInvoiceId, reqBody);
        if (newInvoiceResult && newInvoiceResult.status === 200) {
          setNewArticle(newInvoiceResult.data);
          setActiveTab(AddInvoiceTabEnums.TotalInvoice);
        }
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [excelData, isValid, newInvoiceId]);
  return (
    <DescriptionContainer justify={"center"}>
      <Col span={24} className="flex flex-col items-center gap-[24px]   ">
        <ExcelEditableTable />
      </Col>

      <Col span={24} className="flex justify-end gap-[24px] mt-[100px] footer">
        <Button
          onClick={() => {
            setFileIsUploaded(false);
            setExcelData([]);
            setExcelFile([]);
            setFileName("");
          }}
          htmlType="button"
        >
          Back
        </Button>
        <Guard action={NewInvoiceArticlePath}>
          <Button
            type="primary"
            htmlType="button"
            onClick={() => {
              onConfirm();
            }}
            loading={loading}
            // disabled={}
          >
            confirmation
          </Button>
        </Guard>
      </Col>
    </DescriptionContainer>
  );
};
