import { IPath } from "../Identity/models";

export const BaseInfoPaths: IPath[] = [
  {
    path: "api/BaseInfo/NewPort",
    httpMethod: "POST",
  },
  {
    path: "api/BaseInfo/AllPorts",
    httpMethod: "GET",
  },
  {
    path: "api/BaseInfo/{PortId}/DeletePort",
    httpMethod: "DELETE",
  },
  {
    path: "api/BaseInfo/{PortId}/UpdatePort",
    httpMethod: "PUT",
  },
  {
    path: "api/BaseInfo/NewAgent",
    httpMethod: "POST",
  },
  {
    path: "api/BaseInfo/AllAgents",
    httpMethod: "GET",
  },
  {
    path: "api/BaseInfo/{AgentId}/DeleteAgent",
    httpMethod: "DELETE",
  },
  {
    path: "api/BaseInfo/{AgentId}/UpdateAgent",
    httpMethod: "PUT",
  },
  {
    path: "api/BaseInfo/{AgentId}/NewAgentContactInfo",
    httpMethod: "POST",
  },
  {
    path: "api/BaseInfo/{AgentId}/AllAgentContactInfo",
    httpMethod: "GET",
  },
  {
    path: "api/BaseInfo/{AgentContactInfoId}/DeleteAgentContactInfo",
    httpMethod: "DELETE",
  },
];

export const NewPortPath: IPath = {
  path: "api/BaseInfo/NewPort",
  httpMethod: "POST",
};
export const AllPortsPath: IPath = {
  path: "api/BaseInfo/AllPorts",
  httpMethod: "GET",
};
export const DeletePortPath: IPath = {
  path: "api/BaseInfo/{PortId}/DeletePort",
  httpMethod: "DELETE",
};
export const UpdatePortPath: IPath = {
  path: "api/BaseInfo/{PortId}/UpdatePort",
  httpMethod: "PUT",
};
export const NewAgentPath: IPath = {
  path: "api/BaseInfo/NewAgent",
  httpMethod: "POST",
};
export const AllAgentsPath: IPath = {
  path: "api/BaseInfo/AllAgents",
  httpMethod: "GET",
};
export const DeleteAgentPath: IPath = {
  path: "api/BaseInfo/{AgentId}/DeleteAgent",
  httpMethod: "DELETE",
};
export const UpdateAgentPath: IPath = {
  path: "api/BaseInfo/{AgentId}/UpdateAgent",
  httpMethod: "PUT",
};
export const NewAgentContactInfoPath: IPath = {
  path: "api/BaseInfo/{AgentId}/NewAgentContactInfo",
  httpMethod: "POST",
};
export const AllAgentContactInfoPath: IPath = {
  path: "api/BaseInfo/{AgentId}/AllAgentContactInfo",
  httpMethod: "GET",
};
export const DeleteAgentContactInfoPath: IPath = {
  path: "api/BaseInfo/{AgentContactInfoId}/DeleteAgentContactInfo",
  httpMethod: "DELETE",
};
