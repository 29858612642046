import { FC, useState } from "react";
import { Button, Col, Form, Row, Select } from "antd";
import { FormItem } from "src/components/UiKit/FormItem";
import { SInput } from "src/components/UiKit/Input";
import { useFOMGOCONSInfo } from "../context";
import SubmitButton from "../../SubmitButton";
import { SPopconfirm } from "src/components/UiKit/PopConfirm";
import { FOMGOType } from "src/models/enums";

const { Option } = Select;

export const FOMGOCONSInfoForm: FC = () => {
  const [submittable, setSubmittable] = useState<boolean>(false);
  const {
    value: { loading, formName, selectedRow },
    func: { onFinishForm, reset },
  } = useFOMGOCONSInfo();

  return (
    <Col span={24}>
      <Form form={formName} onFinish={onFinishForm}>
        <Row gutter={[32, 24]}>
          <Col span={24}>
            <div className="titleHolder pt-[16px]">
              Please insert FO, MGO Cons information.
            </div>
          </Col>
          <Col span={24} className="flex gap-[24px] pt-[16px] h-[103px]">
            <FormItem
              name={"type"}
              label="FO/MGO  type"
              rules={[{ required: true }]}
              className="w-[33%] !mb-0"
            >
              <Select
                style={{ width: "100%" }}
                placeholder="select"
                optionFilterProp="children"
                className="selectOption"
              >
                <Option value={FOMGOType.VLSHFO}>VLSHFO</Option>
                <Option value={FOMGOType.HSHFO}>HSHFO</Option>
                <Option value={FOMGOType.MGO}>MGO</Option>
                <Option value={FOMGOType.MDO}>MDO</Option>
              </Select>
            </FormItem>

            <FormItem name={"ME"} label="M/E" className="w-[33%] !mb-0">
              <SInput placeholder="Last day ROB" />
            </FormItem>
            <FormItem name={"GE"} label="G/E" className="w-[33%] !mb-0">
              <SInput placeholder="Insert" />
            </FormItem>
          </Col>

          <Col span={24} className="flex gap-[24px] pt-[16px] h-[103px]">
            <FormItem name={"AE2"} label="A/E2" className="w-[33%] !mb-0">
              <SInput placeholder="Last day ROB" />
            </FormItem>

            <FormItem name={"AE3"} label="A/E3" className="w-[33%] !mb-0">
              <SInput placeholder="Last day ROB" />
            </FormItem>
            <FormItem name={"Boiler"} label="Boiler" className="w-[33%] !mb-0">
              <SInput placeholder="Insert" />
            </FormItem>
          </Col>

          <Col span={24}>
            <div className="flex gap-[16px] justify-end form-table-tab-footer">
              <SPopconfirm
                description="Are you sure you want to reset all the information you filled out?"
                onConfirm={reset}
              >
                <Button className="w-[100px]">
                  {" "}
                  {selectedRow ? "Cancel" : "Reset"}
                </Button>
              </SPopconfirm>
              <SubmitButton
                submittable={submittable}
                setSubmittable={setSubmittable}
                form={formName}
                loading={loading}
                buttonTitle={selectedRow ? "Save edit" : "Add"}
              />
            </div>
          </Col>
        </Row>
      </Form>
    </Col>
  );
};
