import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { TableUI } from "src/components/UiKit/table";
import { useTable } from "../../context";
import { IPDFFile, IZipFile, IInvoice } from "../../models";
import { AddInvoiceButton, FileButton, SeeInvoiceButton } from "../../styles";
import { Tabs } from "./tabs";
import { Container } from "./styles";
import { Invoices } from "./invoices";
import { useAddInvoices } from "src/modules/addInvoice/context";
import { ColumnsType } from "antd/es/table";
import { InvoiceService } from "src/services/Invoice/Invoice.service";
import {
  Button,
  Col,
  Divider,
  Form,
  Modal,
  Popconfirm,
  Row,
  message,
} from "antd";
import moment from "moment";
import { FinallyInvoice } from "src/modules/addInvoice/components/FinallyInvoice";
import { CommentModal } from "../Comment-modal/Comment";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import { useEditInvoice } from "src/modules/EditInvoice/context";
import { FilePreview, PreviewFileType } from "src/components/UiKit/FilePreview";
import { RcFile } from "antd/es/upload";
import { uploadFile } from "src/helpers/uploadZipFile";
import { InvoiceUrls } from "src/services/Invoice/urls";
import { AxiosRequestConfig } from "axios";
import dayjs from "dayjs";
import { FormItem } from "src/components/UiKit/FormItem";
import { SInput } from "src/components/UiKit/Input";
import { SDatePicker } from "src/components/UiKit/DatePicker";
import {
  CloseAllRequestAttachmentPath,
  DeleteAllRequestAttachmentPath,
  FileGuardPath,
  FinallyInvoicePath,
  InvoiceAttqachmentPath,
  InvoiceFileDetailPath,
  NewInvoiceArticlePath,
  NewInvoicePath,
  PaymanetInformationPath,
  TotlaInvoicePath,
  UpdateAllRequestZipDetailInfpPath,
  UpdateAllRequestZipDetailPath,
  UpdateBasicInformationInvoicePath,
  UpdateInvoiceCommentPath,
  UpdateTotalInformationInvoicePath,
} from "src/services/Invoice/guardPath";
import { Guard } from "src/components/Guard";
import { checkActionPermission } from "src/helpers/checkPermissionFunc";
interface IProps {
  showZipTabs?: boolean;
  loading: boolean;
}
export const PdfFileInvoice: FC<IProps> = ({ showZipTabs = true, loading }) => {
  const [currentInvoiceId, setCurrentInvoiceId] = useState<number>();
  const [currentInvoice, setCurrentInvoice] = useState<string>("");
  const [currentRequestRecord, setCurrentRequestRecord] = useState<IPDFFile>();
  const [editLoading, setEditLoading] = useState<boolean>(false);
  const [showComment, setShowComment] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);
  const [uploadLoading, setUploadLoading] = useState<boolean>(false);
  const [showFinallyInvoiceData, setShowFinallyInvoiceData] =
    useState<boolean>(false);
  const [showFilePreview, setShowFilePreview] = useState<boolean>(false);
  const [currentSubtitle, setCurrentSubtitle] = useState<string | undefined>(
    currentRequestRecord?.fileName
  );
  const [currentCommentRecord, setCurrentCommentRecord] = useState<
    IInvoice | undefined
  >();
  const {
    value: {
      tableState: { zipFiles, selectedFileIndex },
      expandLoading,
      currentPdfFileDetail,
    },
    dispatches: { setCurrentPdfFileDetail },
    func: {
      onExpand,
      onExpandPdfFile,
      refetchZipFile,
      confirmDeleteAllRequest,
    },
  } = useTable();
  const {
    value: { currentRequest },
    dispatch: { setShowModal, setCurrentAttachment },
  } = useAddInvoices()!;
  const {
    dispatches: {
      setShowModal: editInvoiceModal,
      setHeader: editInvoiceHeader,
      setEditInvoiceId,
      setCurrentPdfFile,
    },
  } = useEditInvoice();

  const activatedTable: IZipFile | undefined = useMemo(
    () => zipFiles[selectedFileIndex],
    [selectedFileIndex, zipFiles]
  );
  const [editConfirmLoading, setEditConfirmLoading] = useState<boolean>(false);
  const [downloadLoading, setDownloadLoading] = useState<boolean>(false);
  const [downloadProgress, setDownloadProgress] = useState<number>(0);
  const downloadFileHandler = useCallback(async () => {
    try {
      setDownloadLoading(true);
      const { AllRequestZipAttachmentId } = new InvoiceService();
      if (!currentRequestRecord) return;
      const config: AxiosRequestConfig = {
        onDownloadProgress: (progressEvent) => {
          const percentage = Math.round(
            (progressEvent.loaded * 100) / (progressEvent.total || 0)
          );
          setDownloadProgress(percentage);
          if (percentage === 100) {
            setTimeout(() => setDownloadProgress(0), 400);
          }
        },
      };
      const res = await AllRequestZipAttachmentId(
        currentRequestRecord.id,
        config
      );
      if (res) {
        let fileFormat = currentRequestRecord.extention;
        const aEl = document.createElement("a");
        aEl.href = res.base64;
        aEl.download = currentRequestRecord.fileName + fileFormat;
        aEl.click();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setDownloadLoading(false);
    }
  }, [currentRequestRecord]);
  const closeInvoice = async (id: number) => {
    try {
      const { CloseAllRequestAttachment } = new InvoiceService();
      const res = await CloseAllRequestAttachment(id);
      if (res && res.status === 200) {
        if (currentRequest) onExpand(currentRequest);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const pdfFileColumns: ColumnsType<IPDFFile> = [
    {
      title: "Attachment code No.",
      dataIndex: "code",
      key: "code",
      align: "center",
      className: "!font-bold",
      width: 250,
      fixed: "left",
    },
    {
      title: "Req file name",
      dataIndex: "shipManagementFileName",
      key: "shipManagementFileName",
      align: "center",
      className: "!font-bold",
      width: 250,
    },

    {
      title: "File",
      dataIndex: "link",
      key: "link",
      align: "center",
      className: "!font-bold ",

      render: (text, record) => (
        <div className="w-full flex justify-center items-center">
          <FileButton
            type="text"
            onClick={() => {
              setCurrentRequestRecord(record);
              setShowFilePreview(true);
            }}
          >
            <span className="material-symbols-outlined icon !font-bold">
              folder
            </span>
          </FileButton>
        </div>
      ),
      width: 90,
    },
    {
      title: "Attach No.",
      dataIndex: "attachNumber",
      key: "attachNumber",
      align: "center",
      className: "!font-bold",
      width: 122,
    },
    {
      title: "Attach date",
      dataIndex: "attachDate",
      key: "attachDate",
      align: "center",
      className: "!font-bold",
      render: (text) => (text ? dayjs(text).format("YYYY-MM-DD") : ""),
      width: 122,
    },
    {
      title: "Invoice added",
      dataIndex: "invoiceAdded",
      key: "invoiceAdded",
      align: "center",
      className: "!font-bold",
      width: 130,
    },
    {
      title: "Due date",
      dataIndex: "dueDate",
      key: "dueDate",
      render: (text) => moment(text).format("YYYY-MM-DD"),
      align: "center",
      className: "!font-bold",
      width: 122,
    },
    {
      title: "Attachment Req total amount P.C",
      dataIndex: "reqTotalAmount",
      key: "reqTotalAmount",
      align: "center",
      className: "!font-bold",
      width: 170,
      render: (text) => text?.toLocaleString(),
    },
    {
      title: "Attachment total Exc amount",
      dataIndex: "totalExcAmount",
      key: "totalExcAmount",
      align: "center",
      className: "!font-bold",
      width: 170,
      render: (text) => text?.toLocaleString(),
    },
    {
      title: "Payment currency",
      dataIndex: "paymentCurrencyShortName",
      key: "paymentCurrencyShortName",
      align: "center",
      className: "!font-bold",
      width: 140,
    },
    {
      title: "Operation",
      dataIndex: "operation",
      key: "operation",
      align: "center",
      className: "!font-bold",
      fixed: "right",
      render: (_, RAttachment) =>
        RAttachment.status === 1 ? (
          <div className="flex justify-center items-center">
            <Guard
              action={[
                NewInvoicePath,
                NewInvoiceArticlePath,
                TotlaInvoicePath,
                InvoiceAttqachmentPath,
                PaymanetInformationPath,
              ]}
              multiPath
            >
              <AddInvoiceButton
                type="link"
                onClick={() => {
                  setShowModal(true);
                  setCurrentAttachment(RAttachment);
                }}
                className="!font-bold"
              >
                Add Invoice
              </AddInvoiceButton>
            </Guard>
            <Guard action={CloseAllRequestAttachmentPath}>
              <Popconfirm
                title="Finish"
                description="Are you sure to finish this zip attachment file?"
                onConfirm={() => {
                  closeInvoice(RAttachment.id);
                }}
                okText="Yes"
                cancelText="No"
              >
                <AddInvoiceButton type="link" className="!font-bold">
                  Finish
                </AddInvoiceButton>
              </Popconfirm>
            </Guard>
            <Guard action={DeleteAllRequestAttachmentPath}>
              <Popconfirm
                placement="topRight"
                title={"Are you sure to delete this invoice?"}
                description={"Delete the invoice"}
                onConfirm={() => confirmDeleteAllRequest(RAttachment)}
                okText="Yes"
                cancelText="No"
              >
                <span
                  className={`material-symbols-outlined cursor-pointer !font-[22px]`}
                  style={{ color: "#FF1414" }}
                >
                  delete_forever
                </span>
              </Popconfirm>
            </Guard>
          </div>
        ) : (
          <div className="flex ">
            <AddInvoiceButton type="link" onClick={() => {}} disabled>
              send to
            </AddInvoiceButton>
          </div>
        ),
      width: 180,
    },
  ];

  const invoiceFileColumns: ColumnsType<IInvoice> = [
    {
      title: "",
      dataIndex: "index",
      key: "index",
      width: "38px",
      align: "center",
      className: "!font-bold",

      render: (name: string, record: IInvoice, index: number) => index + 1,
    },
    {
      title: "Reference Number",
      dataIndex: "shmInvoiceNumber",
      key: "shmInvoiceNumber",
      width: "160px",
      align: "center",
      className: "!font-bold",
    },
    {
      title: "Record date",
      dataIndex: "createdDate",
      key: "createdDate",
      width: 140,
      align: "center",
      className: "!font-bold",

      render: (value: string) => moment(value).format("YYYY-MM-DD"),
    },

    {
      title: "Invoice Type",
      dataIndex: "invoiceType",
      key: "invoiceType",
      width: "92px",
      align: "center",
      className: "!font-bold",

      render: (value: number) => {
        switch (value) {
          case 1:
            return "inv";
          case 2:
            return "per-inv";
          case 3:
            return "Re.fund";
          default:
            return "";
        }
      },
    },
    {
      title: "Supp Name",
      dataIndex: "supplierName",
      key: "supplierName",
      align: "center",
      width: "150px",
      className: "!font-bold",
    },
    {
      title: "inv Total amount",
      dataIndex: "totalInvoiceAmount",
      key: "totalInvoiceAmount",
      align: "center",
      width: "90px",
      className: "!font-bold",
    },
    {
      title: "Dif Req Total & our amount",
      dataIndex: "diffrenceWithShipManagementTotla",
      key: "diffrenceWithShipManagementTotla",
      align: "center",
      width: "150px",
      className: "!font-bold",
    },
    {
      title: "Dif Supp Total & our amount",
      dataIndex: "diffrenceWithSupplierTotla",
      key: "diffrenceWithSupplierTotla",
      align: "center",
      width: "150px",
      className: "!font-bold",
    },
    {
      title: "Invoice currency",
      dataIndex: "currencyShortname",
      key: "currencyShortname",
      align: "center",
      width: "90px",
      className: "!font-bold",

      render: (text) => {
        if (text === "NON" || !text) {
          return "---";
        } else {
          return text;
        }
      },
    },
    {
      title: "Operation",
      dataIndex: "operation",
      key: "operation",
      width: "64px",
      align: "center",
      className: "!font-bold",

      render: (text: string, record: IInvoice) => (
        <div className="flex gap-[8px]">
          <SeeInvoiceButton
            type="text"
            onClick={() => {
              setCurrentCommentRecord(record);
              setShowComment(true);
            }}
          >
            <span className="material-symbols-outlined icon !font-bold">
              mode_comment
            </span>
          </SeeInvoiceButton>
          <Guard
            action={[
              UpdateBasicInformationInvoicePath,
              UpdateTotalInformationInvoicePath,
            ]}
            multiPath
          >
            <SeeInvoiceButton
              type="text"
              onClick={() => {
                editInvoiceModal(true);
                editInvoiceHeader(record.shmInvoiceNumber);
                setEditInvoiceId(record.id);
              }}
            >
              <span className="material-symbols-outlined icon !font-bold">
                edit_note
              </span>
            </SeeInvoiceButton>
          </Guard>
          <Guard action={FinallyInvoicePath}>
            <SeeInvoiceButton
              type="link"
              onClick={() => {
                setCurrentInvoiceId(record.id);
                setShowFinallyInvoiceData(true);
              }}
              className="!font-bold"
            >
              See
            </SeeInvoiceButton>
          </Guard>
        </div>
      ),
    },
  ];
  const componentRef = useRef<HTMLDivElement>(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const reactToPrintContent = useCallback(() => {
    return componentRef.current;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [componentRef.current]);
  const getRowKey = (record: IPDFFile) => record.id;
  const onCancelFinallyInvoice = () => {
    // setFinallyInvoice(undefined);
    setShowFinallyInvoiceData(false);
    setCurrentInvoiceId(undefined);
    setShowComment(false);
  };
  const onCurrentRequestAttachmentChangeHandler = async (
    file: RcFile | Blob | null
  ) => {
    if (!file || !currentRequestRecord) return;
    setUploadLoading(true);
    setCurrentSubtitle((file as RcFile).name);
    const formValues = attachmentForm.getFieldsValue();
    const extraData = {
      attachNumber: formValues.attachNumber,
      attachDate: formValues.attachDate
        ? dayjs(formValues.attachDate).format("YYYY-MM-DD")
        : null,
    };
    uploadFile({
      setProgress,
      callbackUrl: InvoiceUrls.Invoice.concat(
        `${currentRequestRecord?.id}`
      ).concat(InvoiceUrls.UpdateAllRequestZipDetail),
      method: "PUT",
      newFile: file || undefined,
      extraData,
    })
      .then(() => {
        refetchZipFile().then(() => {
          if (currentRequest) onExpand(currentRequest);
        });
      })
      .finally(() => {
        setUploadLoading(false);
      });
    return;
  };
  useEffect(() => {
    if (currentRequestRecord?.fileName) {
      setCurrentSubtitle(currentRequestRecord.fileName);
    }
  }, [currentRequestRecord]);
  const onEditComment = async (value: string) => {
    if (!currentCommentRecord) return;

    try {
      setEditConfirmLoading(true);
      const { UpdateInvoiceComment } = new InvoiceService();

      const updatedRes = await UpdateInvoiceComment(
        currentCommentRecord.id,
        value
      );
      if (updatedRes && updatedRes.status === 200) {
        message.success("Updated invoice comment successfully");
        refetchZipFile().then(() => {
          onExpand(activatedTable).then(() => {
            if (currentPdfFileDetail) onExpandPdfFile(currentPdfFileDetail);
          });
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      setEditConfirmLoading(false);
    }
  };
  const [attachmentForm] = Form.useForm();
  const onEdit = async () => {
    try {
      if (!currentRequestRecord) return;
      setEditLoading(true);
      const { UpdateAllRequestZipDetailInfo } = new InvoiceService();
      const values = attachmentForm.getFieldsValue();
      const res = await UpdateAllRequestZipDetailInfo(currentRequestRecord.id, {
        attachDate: values.attachDate
          ? dayjs(values.attachDate).format("YYYY-MM-DD")
          : undefined,
        attachNumber: values.attachNumber,
      });
      if (res && res.status === 200) {
        refetchZipFile().then(() => {
          if (currentRequest) onExpand(currentRequest);
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      setEditLoading(false);
    }
  };
  useEffect(() => {
    if (currentRequestRecord) {
      const attachDate = dayjs(currentRequestRecord.attachDate);
      const attachNumber = currentRequestRecord.attachNumber;
      attachmentForm.setFieldsValue({ attachDate, attachNumber });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRequestRecord]);
  return (
    <Container className="flex flex-col justify-start items-center ">
      {showZipTabs && <Tabs zipFiles={zipFiles} />}
      <TableUI
        tableType="primary"
        columns={pdfFileColumns}
        rowKey={getRowKey}
        dataSource={activatedTable?.files || []}
        pagination={false}
        loading={loading || expandLoading}
        scroll={{ x: 1000 }}
        expandable={{
          expandedRowRender: (record: IPDFFile) => (
            <Invoices
              invoices={record?.invoices || []}
              columns={invoiceFileColumns}
            />
          ),
          rowExpandable: (record: IPDFFile) =>
            checkActionPermission(InvoiceFileDetailPath) &&
            +record.invoiceAdded > 0,
          onExpand: (expanded: boolean, record: IPDFFile) => {
            if (expanded) {
              onExpandPdfFile(record);
              setCurrentInvoice(record.fileName);
              setCurrentPdfFileDetail(record);
              setCurrentPdfFile(record);
            }
          },
        }}
      />
      <CommentModal
        comment={currentCommentRecord?.invoiceComment || ""}
        show={showComment}
        onEdit={onEditComment}
        referenceNumber={currentCommentRecord?.shmInvoiceNumber || ""}
        onCancel={onCancelFinallyInvoice}
        invoices={currentInvoice}
        onEditLoading={editConfirmLoading}
        editAction={UpdateInvoiceCommentPath}
      />
      <FilePreview
        addFileOnChange={onCurrentRequestAttachmentChangeHandler}
        file={!!currentRequestRecord?.fileName}
        fileType={
          (currentRequestRecord?.extention.replace(
            ".",
            ""
          ) as PreviewFileType) || "pdf"
        }
        onCancel={() => {
          setShowFilePreview(false);
        }}
        fileName={currentRequestRecord?.fileName || ""}
        show={showFilePreview}
        title="Edit documentation files"
        subTitle={currentSubtitle}
        referenceNumber={currentRequestRecord?.code}
        onDownload={downloadFileHandler}
        closable={false}
        loading={uploadLoading}
        progress={progress}
        downloadLoading={downloadLoading}
        downloadProgress={downloadProgress}
        hasEditButton
        onEdit={() => {
          onEdit();
        }}
        editLoading={editLoading}
        actions={{
          editWithFile: UpdateAllRequestZipDetailPath,
          edit: UpdateAllRequestZipDetailInfpPath,
          delete: UpdateAllRequestZipDetailPath,
          download: FileGuardPath,
        }}
      >
        <Row gutter={[24, 24]} className="mt-[24px]">
          <Col span={24}>
            <div className="text-[#001377] text-[16px] py-[16px]">
              You can delete this file and add a new file or edit its
              information and click the "save edit" button at the end.
            </div>
          </Col>
          <Col span={24}>
            <Form form={attachmentForm}>
              <Row gutter={24}>
                <Col span={12}>
                  <FormItem label="Attach No." name={"attachNumber"}>
                    <SInput />
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem label="Attach date" name={"attachDate"}>
                    <SDatePicker className="w-full" />
                  </FormItem>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </FilePreview>
      <Modal
        open={showFinallyInvoiceData}
        onCancel={() => {
          setShowFinallyInvoiceData(false);
          setCurrentInvoiceId(undefined);
        }}
        footer={null}
        closable={false}
        maskClosable={false}
        width={870}
        styles={{ body: { padding: 0, marginBottom: 64 } }}
        title={null}
        style={{ top: 64, padding: 0 }}
        wrapClassName={"unset-modal-padding"}
      >
        <ReactToPrint
          content={reactToPrintContent}
          documentTitle="Invoice"
          removeAfterPrint
        />
        <FinallyInvoice invoiceId={currentInvoiceId} ref={componentRef} />
        <Divider />
        <div className="flex justify-center gap-[24px] pb-[32px] ">
          <Button
            onClick={handlePrint}
            icon={<span className="material-symbols-outlined">print</span>}
            className="flex items-center border-solid border-[#3B82F6]"
            type="link"
          >
            Print
          </Button>
          <Button
            onClick={onCancelFinallyInvoice}
            icon={<span className="material-icons">close</span>}
            className="flex items-center border-solid border-[#3B82F6]"
            type="link"
          >
            Close
          </Button>
        </div>
      </Modal>
    </Container>
  );
};
