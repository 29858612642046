import { styled } from "styled-components";

export const ShipTableContainer = styled.div`
  .ant-table-thead tr {
    th {
      color: #1b2559 !important;
      font-family: DM Sans;
      font-size: 12px;
      font-style: normal;
      line-height: normal;
      font-weight: bold !important;
    }
  }

  .ant-table-tbody td {
    color: var(--text-3, #5d6881);
    font-family: DM Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;
