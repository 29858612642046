import { FC } from "react";
import { CurrencyTabs } from "./components/currencyTabs";
import { CurrencyContainer } from "./style";
import { useCurrency } from "./context";
import { Col, Row } from "antd";
import { AddNewCurrency } from "./components/addCurrency";
import { Guard } from "src/components/Guard";
import {
  EditCurrencyPath,
  NewCurrencyPath,
} from "src/services/System/guardPath";
import { AllCurrencyPath } from "src/services/Public/guardPath";

export const Currency: FC = () => {
  const {
    value: { editMode },
    func: { onBack },
  } = useCurrency();

  return (
    <CurrencyContainer>
      {editMode ? (
        <Row>
          <Col span={24}>
            <div
              onClick={onBack}
              className="flex justify-between items-center py-[19px] px-[24px] borderBottom"
            >
              <div className="flex flex-row gap-[12px] items-center cursor-pointer">
                <span className="material-icons text-[#2C73FC] text-[32px]">
                  keyboard_backspace
                </span>
                <div className=" text-[#2C73FC] text-[18px] ">
                  Back to table
                </div>
              </div>
              <div className="flex flex-col ">
                <div className="text-[#001377] text-[18px] font-semibold">
                  Edit
                </div>
                <div className="text-[#5D6881] text-[14px] font-bold">
                  Currency
                </div>
              </div>
            </div>
          </Col>
          <Col span={24}>
            <Guard action={EditCurrencyPath}>
              <AddNewCurrency />
            </Guard>
          </Col>
        </Row>
      ) : (
        <Guard action={[NewCurrencyPath, AllCurrencyPath]}>
          <CurrencyTabs />
        </Guard>
      )}
    </CurrencyContainer>
  );
};
