import { FC } from "react";
import { useDocumentation } from "../../context";
import DocumentItemsCard from "./DocumentItemsCard";
import { Row } from "antd";

const DocumentsCard: FC = () => {
  const {
    value: { documents },
  } = useDocumentation();

  return (
    <Row
      gutter={[16, 16]}
      justify={"space-between"}
      align={"top"}
      className="w-full h-full"
    >
      {documents.map((doc) => (
        <DocumentItemsCard key={doc.id} item={doc} />
      ))}
    </Row>
  );
};

export default DocumentsCard;
