import { Navigate, RouteObject } from "react-router-dom";
import { ROUTES } from "src/models/enums/routes";
import LoginLayout from "src/templates/loginLayout";
import LoginPage from "src/pages/public/Login";
import { useMemo } from "react";
import { useMediaQuery } from "src/hook";
import { breakPointsSm } from "src/constants/screen";
import LoginResponsiveLayout from "src/templates/Responsive/LoginPage";

export const usePublicRoutes = () => {
  const isMobileDevice = !useMediaQuery(breakPointsSm);
  const publicRoutes: RouteObject[] = useMemo(
    () => [
      {
        path: ROUTES.login,
        element: isMobileDevice ? <LoginResponsiveLayout /> : <LoginLayout />,
        children: [
          {
            path: ROUTES.login,
            element: <LoginPage />,
          },
        ],
      },

      { path: "*", element: <Navigate to={ROUTES.login} /> },
    ],
    [isMobileDevice]
  );
  return publicRoutes;
};
