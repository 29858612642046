import { Col, TreeSelect, TreeSelectProps } from "antd";
import { FC, useEffect, useMemo, useState } from "react";
import { SInput } from "src/components/UiKit/Input";
import { KindInvoiceContainer } from "../../styles";
import { FormItem } from "src/components/UiKit/FormItem";
import { SystemService } from "src/services/System/System.service";
import { ITreeCategory } from "src/services/System/models";
import { DefaultOptionType } from "antd/es/select";
import { invoiceReportFormItemsName } from "../../constant/flterFormNames";
const { SHOW_PARENT } = TreeSelect;

export const Articles: FC = () => {
  const [category, setCategory] = useState<ITreeCategory[]>([]);
  const [categoryLoading, setCategoryLoading] = useState<boolean>(false);

  const getCategory = async () => {
    try {
      setCategoryLoading(true);
      const { TreeCategory } = new SystemService();
      const res = await TreeCategory();
      if (res && res.status === 200 && res.data) {
        setCategory(res.data);
      } else {
        setCategory([]);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setCategoryLoading(false);
    }
  };
  useEffect(() => {
    getCategory();
  }, []);
  const createTree = (category: ITreeCategory) => {
    const newCategory: DefaultOptionType = {
      label: category.name,
      value: category.id,
      key: category.id,
    };
    if (category.childeren.length > 0) {
      newCategory.children = category.childeren.map((item) => createTree(item));
    }
    return newCategory;
  };
  const data: DefaultOptionType[] = useMemo(
    () => category.map((item) => createTree(item)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [category]
  );
  const treeProps: TreeSelectProps<any, DefaultOptionType> = useMemo(
    () => ({
      treeData: data,
      treeCheckable: false,
      showCheckedStrategy: SHOW_PARENT,
      multiple: true,
      placeholder: "select",
      style: {
        width: "100%",
      },
    }),
    [data]
  );

  return (
    <KindInvoiceContainer gutter={[24, 24]}>
      <Col span={8}>
        <FormItem
          name={invoiceReportFormItemsName["Category code"]}
          label="Category code"
          className="!mb-0"
        >
          <SInput />
        </FormItem>
      </Col>
      <Col span={8}>
        <FormItem
          name={invoiceReportFormItemsName.Category}
          label="Category"
          className="!mb-0"
        >
          <TreeSelect
            {...treeProps}
            allowClear
            className="selectOption"
            showSearch
            treeNodeFilterProp="label"
            loading={categoryLoading}
          />
        </FormItem>
      </Col>
      <Col span={4}>
        <div className="absoluteLabel">Quantity</div>
        <FormItem
          name={invoiceReportFormItemsName["Quantity from"]}
          className="!mb-0 mt-[32px]"
        >
          <SInput placeholder="From" numbermode />
        </FormItem>
      </Col>
      <Col span={4}>
        <FormItem
          name={invoiceReportFormItemsName["Quantity to"]}
          className="!mb-0 mt-[32px]"
        >
          <SInput placeholder="To" numbermode />
        </FormItem>
      </Col>

      <Col span={4}>
        <div className="absoluteLabel">Unit price</div>

        <FormItem
          name={invoiceReportFormItemsName["Unit price from"]}
          className="!mb-0 "
        >
          <SInput placeholder="From" className="mt-[32px] " numbermode />
        </FormItem>
      </Col>
      <Col span={4}>
        <FormItem
          name={invoiceReportFormItemsName["Unit price to"]}
          className="!mb-0 mt-[32px]"
        >
          <SInput placeholder="To" numbermode />
        </FormItem>
      </Col>
      <Col span={4}>
        <div className="absoluteLabel">Amount</div>

        <FormItem
          name={invoiceReportFormItemsName["Amount from"]}
          className="!mb-0 "
        >
          <SInput placeholder="From" className="mt-[32px] " numbermode />
        </FormItem>
      </Col>
      <Col span={4}>
        <FormItem
          name={invoiceReportFormItemsName["Amount to"]}
          className="!mb-0 mt-[32px]"
        >
          <SInput placeholder="To" numbermode />
        </FormItem>
      </Col>
    </KindInvoiceContainer>
  );
};
