import { FC } from "react";
import { CountryTabs } from "./components/countryTabs";
import { CountryContainer } from "./style";
import { useCountry } from "./context";
import { Col, Row } from "antd";
import { AddNewCountry } from "./components/addCountry";
import { Guard } from "src/components/Guard";
import {
  EditCountryPath,
  GetCountryPath,
  NewCountryPath,
} from "src/services/System/guardPath";

export const Country: FC = () => {
  const {
    value: { editMode },
    dispatch: { setEditMode },
    func: { getCountry },
  } = useCountry();

  const onBack = () => {
    setEditMode(false);
    getCountry();
  };

  return (
    <CountryContainer>
      {editMode ? (
        <Row>
          <Col span={24}>
            <div
              onClick={onBack}
              className="flex justify-between items-center py-[19px] px-[24px] borderBottom"
            >
              <div className="flex flex-row gap-[12px] items-center cursor-pointer">
                <span className="material-icons text-[#2C73FC] text-[32px]">
                  keyboard_backspace
                </span>
                <div className=" text-[#2C73FC] text-[18px] ">
                  Back to table
                </div>
              </div>
              <div className="flex flex-col ">
                <div className="text-[#001377] text-[18px] font-semibold">
                  Edit
                </div>
                <div className="text-[#5D6881] text-[14px] font-bold">
                  Country
                </div>
              </div>
            </div>
          </Col>
          <Col span={24}>
            <Guard action={EditCountryPath}>
              <AddNewCountry />
            </Guard>
          </Col>
        </Row>
      ) : (
        <Guard action={[GetCountryPath, NewCountryPath]} multiPath>
          <CountryTabs />
        </Guard>
      )}
    </CountryContainer>
  );
};
