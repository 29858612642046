import { FC } from "react";
import { omit } from "lodash";
import { TextAreaProps } from "antd/es/input";
import { TextAreaStyled } from "./styled";

interface IProps extends TextAreaProps {
  numbermode?: boolean;
}
export const TextAreaUiKit: FC<IProps> = (props) => {
  return (
    <TextAreaStyled
      {...omit(props, "numbermode")}
      placeholder={props.placeholder ? props.placeholder : "insert"}
    />
  );
};
