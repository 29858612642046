import { FC, useEffect, useState } from "react";
import { Col, Modal, Row, Tooltip } from "antd";
import type { ColumnsType } from "antd/es/table";
import { SupplierTableContainer } from "../styles";
import { TableUI } from "src/components/UiKit/table";
import { ActionModal, ActionModalTitle } from "./actionModal";
import { useSearchParams } from "react-router-dom";
import { useChangeParams } from "src/hook/useParamsChange";
import { getRowKey } from "src/helpers/getRowKey";
import { IGetSupplier } from "src/services/System/models";
import { useSupplier } from "../context";

export const SupplierTable: FC = () => {
  const {
    value: { pagination, dataSource, tableLoading },
    dispatch: { setPagination },
    func: { getSupplierTableData },
  } = useSupplier()!;

  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

  let [searchParams, setSearchParams] = useSearchParams();
  const onChangeParams = useChangeParams();

  useEffect(() => {
    if (!searchParams.size) {
      setSearchParams({
        Limit: pagination.pageSize.toString(),
        Offset: pagination.current.toString(),
      });
    } else {
      const Limit = searchParams.get("Limit");
      const Offset = searchParams.get("Offset");
      setPagination((prev) => ({
        ...prev,
        pageSize: Limit ? +Limit : 10,
        current: Offset ? +Offset : 1,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams.size]);

  useEffect(() => {
    if (searchParams.size) {
      getSupplierTableData();
    }
  }, [getSupplierTableData, searchParams.size]);

  const columns: ColumnsType<IGetSupplier> = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
      className: "!font-bold !text-center ",
      fixed: "left",
      width: 61,
      render: (text, record, index) =>
        (pagination.current - 1) * pagination.pageSize + (index + 1),
    },
    {
      title: "Supplier name",
      dataIndex: "name",
      key: "name",
      fixed: "left",
      width: 154,
      className: "!font-bold !text-center",
    },
    {
      title: "Website",
      dataIndex: "webSite",
      key: "webSite",
      width: 155,
      className: "!font-bold !text-center",
    },
    {
      title: "Phone number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      width: 126,
      className: "!font-bold !text-center whitespace-nowrap",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: 212,
      className: "!font-bold !text-center",
    },
    {
      title: "Country",
      dataIndex: "countryName",
      key: "countryName",
      width: 90,
      className: "!font-bold !text-center whitespace-nowrap",
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
      width: 90,
      className: "!font-bold !text-center",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      width: 90,
      className: "!font-bold !text-center",
      render: (text, record) => (
        <Tooltip className="cursor-pointer" placement="bottom" title={text}>
          <span className="material-icons !text-[#5D6881] text-[16px]">
            directions
          </span>
        </Tooltip>
      ),
    },

    {
      title: "Tools",
      dataIndex: "tools",
      key: "tools",
      width: 132,
      className: "!font-bold  !text-center",
    },
  ];
  return (
    <SupplierTableContainer>
      <Row>
        <Col span={24} className="content">
          <TableUI
            columns={columns}
            tableType="primary"
            dataSource={dataSource}
            loading={tableLoading}
            scroll={{ x: 1210 }}
            rowKey={getRowKey}
            pagination={{
              position: ["bottomCenter"],
              showSizeChanger: true,
              total: pagination.total,
              pageSize: pagination.pageSize,
              current: pagination.current,
              onChange: (page: number, pageSize: number) => {
                onChangeParams("Limit", pageSize.toString());
                onChangeParams(
                  "Offset",
                  pageSize !== pagination.pageSize ? "1" : page.toString()
                );
                setPagination((prev) => ({
                  ...prev,
                  current: pageSize !== prev.pageSize ? 1 : page,
                  pageSize,
                }));
              },
            }}
          />
        </Col>
        <>
          <Modal
            title={<ActionModalTitle />}
            open={isOpenModal}
            onCancel={() => {
              setIsOpenModal(false);
            }}
            footer={null}
          >
            <ActionModal />
          </Modal>
        </>
      </Row>
    </SupplierTableContainer>
  );
};
