import { FC, useEffect, useState } from "react";
import { CommercialContainer } from "./style";
import { App, Button, Col, DatePicker, Flex, Form, Row } from "antd";
import { SelectUiKit } from "src/components/UiKit/select";
import { FormItem } from "src/components/UiKit/FormItem";
import { SInput } from "src/components/UiKit/Input";
import usePublicApiStore from "src/store/publicApi";
import { OperationService } from "src/services/Operation/Operation.service";
import {
  IEditVoyageArg,
  INewVoyageArg,
} from "src/services/Operation/models/args.models";
import { Dayjs } from "dayjs";
import { useCommercial } from "../../context";
import { Guard } from "src/components/Guard";
import {
  EditVoyagePath,
  NewVoyagePath,
} from "src/services/Operation/guardPath";
interface IFormValue {
  shipId: number;
  voyageNO: string;
  date: [Dayjs, Dayjs];
}
export const AddNewVoyage: FC = () => {
  const { ships } = usePublicApiStore();
  const { message } = App.useApp();
  const [loading, setLoading] = useState<boolean>(false);
  const [submittable, setSubmittable] = useState<boolean>(false);
  const {
    value: { editMode, form, currentEditVoyage },
    func: { cancelEditMode },
  } = useCommercial();
  const values = Form.useWatch([], form);

  useEffect(() => {
    form?.validateFields({ validateOnly: true }).then(
      () => {
        setSubmittable(true);
      },
      () => {
        setSubmittable(false);
      }
    );
  }, [values, form]);
  const onFinish = async (values: IFormValue) => {
    try {
      setLoading(true);
      const { NewVoyage } = new OperationService();
      const reqBody: INewVoyageArg = {
        shipId: values.shipId,
        voyageNO: values.voyageNO,
        startDate: values.date[0].format("YYYY-MM-DD"),
        endDate: values.date[1].format("YYYY-MM-DD"),
      };
      const res = await NewVoyage(reqBody);
      if (res && res.status === 200) {
        form?.resetFields();
        message.success("Voyage added successfully.");
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  const onFinishEdit = async (values: IFormValue) => {
    if (!currentEditVoyage) return;
    try {
      setLoading(true);
      const { EditVoyage } = new OperationService();
      const reqBody: IEditVoyageArg = {
        voyageNO: values.voyageNO,
        startDate: values.date[0].format("YYYY-MM-DD"),
        endDate: values.date[1].format("YYYY-MM-DD"),
      };
      const res = await EditVoyage(currentEditVoyage.id, reqBody);
      if (res && res.status === 200) {
        message.success("Voyage edited successfully.");
        cancelEditMode();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <CommercialContainer justify={"center"}>
      <Col span={14}>
        <Row className="content">
          <Col span={24}>
            <h3 className="title">Please insert the voyage information.</h3>
          </Col>
          <Col span={24} className="py-[16px]">
            <Form form={form} onFinish={editMode ? onFinishEdit : onFinish}>
              <Row gutter={[24, 24]}>
                <Col span={12}>
                  <FormItem
                    className="!mb-0"
                    rules={[{ required: true }]}
                    name={"shipId"}
                    label="Vessel"
                  >
                    <SelectUiKit
                      options={ships?.data?.map((ship) => ({
                        label: ship.name,
                        value: ship.id,
                        name: ship.abbreviation,
                      }))}
                      showSearch
                      allowClear
                      optionFilterProp="label"
                      optionLabelProp="name"
                      disabled={editMode}
                    />
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem
                    className="!mb-0"
                    rules={[{ required: true }]}
                    label="Voyage No."
                    name={"voyageNO"}
                  >
                    <SInput allowClear />
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem
                    className="!mb-0"
                    rules={[{ required: true }]}
                    label="Date"
                    name={"date"}
                  >
                    <DatePicker.RangePicker allowClear className="datePicker" />
                  </FormItem>
                </Col>
              </Row>
            </Form>
          </Col>
          <Col span={24} className="mt-[24px] footer">
            <Flex gap={16} justify="flex-end">
              <Button
                className="min-w-[85px] !h-[40px]"
                onClick={() => form?.resetFields()}
              >
                Reset
              </Button>
              <Guard action={[NewVoyagePath, EditVoyagePath]}>
                <Button
                  type="primary"
                  className="min-w-[85px] !h-[40px]"
                  disabled={!submittable}
                  onClick={form?.submit}
                  loading={loading}
                >
                  {editMode ? "Save edit" : "Add"}
                </Button>
              </Guard>
            </Flex>
          </Col>
        </Row>
      </Col>
    </CommercialContainer>
  );
};
