import React, { useContext, useEffect, useRef, useState } from "react";
import type { InputRef } from "antd";
import { App, Form, Input, Table } from "antd";
import type { FormInstance } from "antd/es/form";
import { IExcelData } from "../../models";
import { useAddInvoices } from "../../context";
import { TableContainer } from "./table.styles";
import { PublicService } from "src/services/Public/Public.service";

const EditableContext = React.createContext<FormInstance<any> | null>(null);

interface EditableRowProps {
  index: number;
}

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof IExcelData;
  record: IExcelData;
  handleSave: (record: IExcelData) => void;
}

const EditableCell: React.FC<EditableCellProps> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<InputRef>(null);
  const form = useContext(EditableContext)!;

  useEffect(() => {
    if (editing) {
      inputRef.current!.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();

      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{ margin: 0 }}
        name={dataIndex}
        // rules={[
        //   {
        //     required: true,
        //     message: `${title} is required.`,
        //     whitespace: true,
        //   },
        // ]}
      >
        <Input
          ref={inputRef}
          onPressEnter={save}
          onBlur={save}
          style={{ textAlign: "center" }}
        />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap min-w-[70px] min-h-[18.85px]"
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

type EditableTableProps = Parameters<typeof Table>[0];

interface DataType extends IExcelData {}

type ColumnTypes = Exclude<EditableTableProps["columns"], undefined>;

const ExcelEditableTable: React.FC = () => {
  const {
    value: { excelData },
    dispatch: { setExcelData },
  } = useAddInvoices()!;
  const { notification } = App.useApp();

  const [dataSource, setDataSource] = useState<DataType[]>(excelData);
  const defaultColumns: (ColumnTypes[number] & {
    editable?: boolean;
    dataIndex: string;
  })[] = [
    {
      key: "#",
      title: "#",
      dataIndex: "#",
      editable: false,
      // width: 53,
    },
    {
      key: "Description",
      title: "Description",
      dataIndex: "Description",
      editable: true,
      width: 200,
    },
    {
      key: "Quantity",
      title: "Quantity",
      dataIndex: "Quantity",
      editable: true,
      // width: 91,
    },
    {
      key: "Unit Quantity",
      title: "Unit Quantity",
      dataIndex: "Unit Quantity",
      editable: true,
      // width: 121,
    },
    {
      key: "Unit Price",
      title: "Unit Price",
      dataIndex: "Unit Price",
      editable: true,
      // width: 94,
    },
    {
      key: "Amount",
      title: "Amount",
      dataIndex: "Amount",
      // editable: true,
      render: (_, record) => {
        return +(+record["Quantity"] * +record["Unit Price"]).toFixed(4);
      },
      // width: 104,
    },

    // {
    //   key: "Currency",
    //   title: "Currency",
    //   dataIndex: "Currency",
    //   editable: true,
    //   // width: 93,
    // },
    {
      key: "Category",
      title: "Category",
      dataIndex: "Category",
      // editable: true,
      // width: 110,
    },
    // {
    //   key: "Sub category",
    //   title: "Sub category",
    //   dataIndex: "Sub category",
    //   // editable: true,
    //   // width: 110,
    // },
    {
      key: "Cost code",
      title: "Cost code",
      dataIndex: "Cost code",
      editable: true,
      // width: 110,
    },

    // {
    //   key: "operation",
    //   title: "operation",
    //   dataIndex: "operation",
    //   // width: 89,
    //   render: (_, record: any) =>
    //     dataSource.length >= 1 ? (
    //       // eslint-disable-next-line jsx-a11y/anchor-is-valid
    //       <a onClick={() => handleSave(record)}>save</a>
    //     ) : null,
    // },
  ];

  const handleSave = async (row: DataType) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row["#"] === item["#"]);
    const item = newData[index];
    row["Amount"] = +(+row["Quantity"] * +row["Unit Price"]).toFixed(4);
    const { Category } = new PublicService();
    const res = await Category([row["Cost code"]]);
    if (res && res.data && res.data[0] !== null) {
      const categories = res.data;
      row["Category"] = categories[0].fullName;
      // row["Sub category"] = categories[0].subCategory;
      row.costCodeId = categories[0].id;
    } else {
      notification.error({ message: `notfound ${row["Cost code"]} category` });
      row["Category"] = "";
      // row["Sub category"] = "";
      row.costCodeId = 0;
    }
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataSource(newData);
    setExcelData(newData);
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: DataType) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  return (
    <TableContainer>
      <Table
        components={components}
        rowClassName={() => "editable-row"}
        bordered
        dataSource={dataSource}
        columns={columns as ColumnTypes}
        scroll={{ x: 1000, y: 400 }}
        pagination={false}
        size="small"
      />
    </TableContainer>
  );
};

export default ExcelEditableTable;
