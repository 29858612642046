import { FC } from "react";
import { OwnerHeader } from "./components/Header";
import { OwnerDashboardContent } from "./components/Content";

export const OwnerDashboard: FC = () => {
  return (
    <>
      <OwnerHeader />
      <OwnerDashboardContent />
    </>
  );
};
