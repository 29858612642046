import { FC } from "react";
import { TableContainer } from "./styles";
import { useInvoiceReport } from "../../context";
import { TableUI } from "src/components/UiKit/table";
import { IReportInvoiceArticle } from "src/services/InvoiceReport/models";
import { ColumnsType } from "antd/es/table";
import moment from "moment";

export const ArticleReportTable: FC = () => {
  const {
    value: {
      loadings,
      ArticleData,
      currentRequestValue,
      currentInvoice,
      ArticlePagination,
    },
    dispatch: { setArticlePagination, setCommentModal },
    func: { fetchArticle },
  } = useInvoiceReport();
  const columns: ColumnsType<IReportInvoiceArticle> = [
    {
      title: "#",
      key: "key",
      className: "!font-bold",
      align: "center",
      render: (text, record, index) =>
        (ArticlePagination.Offset - 1) * ArticlePagination.Limit + (index + 1),
    },
    {
      title: "Invoice number",
      dataIndex: "invoiceNumber",
      key: "invoiceNumber",
      className: "!font-bold",
      align: "center",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      className: "!font-bold",
      align: "center",
      render: (text: number) => text?.toLocaleString("en-US"),
    },
    {
      title: "Unit Quantity",
      dataIndex: "unitQuantity",
      key: "unitQuantity",
      className: "!font-bold",
      align: "center",
    },
    {
      title: "Unit Price",
      dataIndex: "unitPrice",
      key: "unitPrice",
      className: "!font-bold",
      align: "center",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      className: "!font-bold",
      align: "center",
      render: (text: number) => text?.toLocaleString("en-US"),
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      className: "!font-bold",
      align: "center",
    },
    {
      title: "Category code",
      dataIndex: "categoryCode",
      key: "categoryCode",
      className: "!font-bold",
      align: "center",
    },
    {
      title: "Created date",
      dataIndex: "created",
      key: "created",
      className: "!font-bold",
      align: "center",
      render: (text) => moment(text).format("YYYY-MM-DD"),
    },
    {
      title: "Tools",
      key: "tools",
      className: "!font-bold ",
      align: "center",
      render: (text, record) => {
        return (
          <div className="flex justify-center items-center gap-[16px] ">
            <span
              className="material-symbols-outlined cursor-pointer text-[16px]"
              onClick={() => {
                setCommentModal({
                  showModal: true,
                  title: record.invoiceNumber,
                  description: record.comment,
                });
              }}
            >
              mode_comment
            </span>
          </div>
        );
      },
    },
  ];

  return (
    <TableContainer>
      <TableUI
        dataSource={ArticleData}
        columns={columns}
        scroll={{ x: "auto" }}
        loading={loadings.article}
        rowKey={(record: IReportInvoiceArticle) => record.invoiceArticleId}
        pagination={{
          position: ["bottomCenter"],
          pageSize: ArticlePagination.Limit,
          current: ArticlePagination.Offset,
          total: ArticlePagination.total,
          onChange: (page, pageSize) => {
            if (currentRequestValue) {
              fetchArticle(currentRequestValue, currentInvoice?.invoiceId, {
                Limit: pageSize,
                Offset: pageSize !== ArticlePagination.Limit ? 1 : page,
              });
            }
            setArticlePagination((prev) => ({
              ...prev,
              Limit: pageSize,
              Offset: pageSize !== prev.Limit ? 1 : page,
            }));
          },
        }}
      />
    </TableContainer>
  );
};
