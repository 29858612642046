import { Tabs, TabsProps } from "antd";
import { FC } from "react";
import { TabsContainer } from "src/modules/Category/styles";
import { IReceiverTabs } from "../models";
import { AddNewReceiver } from "./addReceiver";
import { ReceiverTable } from "./receiverTable";
import { useReceiver } from "../context";
import { Guard } from "src/components/Guard";
import {
  AllReceiversPath,
  NewReceiverPath,
} from "src/services/Income/guardPath";

const item: TabsProps["items"] = [
  {
    key: IReceiverTabs.addNewReceiver,
    label: (
      <span>
        <span className="material-icons text-[10px] p-[8px]">lens</span>
        Add new receiver
      </span>
    ),
    children: (
      <Guard action={NewReceiverPath}>
        <AddNewReceiver />
      </Guard>
    ),
  },
  {
    key: IReceiverTabs.receiverTable,
    label: (
      <span>
        <span className="material-icons text-[10px] p-[10px]">lens</span>
        Receiver table
      </span>
    ),
    children: (
      <Guard action={AllReceiversPath}>
        <ReceiverTable />
      </Guard>
    ),
  },
];

export const ReceiverTabs: FC = () => {
  const {
    value: { activeKey },
    dispatch: { setActiveKey },
  } = useReceiver();

  const onChange = (key: string) => {
    setActiveKey(key);
  };

  return (
    <TabsContainer>
      <Tabs items={item} onChange={onChange} activeKey={activeKey} animated />
    </TabsContainer>
  );
};
