import { FC } from "react";
import { Button, Col } from "antd";
import { ColumnsType } from "antd/es/table";
import { IFOMGOROB } from "src/services/NoonReport/models";
import { TableUI } from "src/components/UiKit/table";
import { useFOMGOROBInfo } from "../context";
import { useAddNewNoonReportCTX } from "../../../context";
import { NewNoonReportTabs } from "../../../model/newNoonReportTabs";
import { useNoonReportCTX } from "src/modules/NoonReport/context";
import { FOMGOTypeName } from "src/models/enums";

const FOMGOROBTable: FC = () => {
  const {
    dispatch: { setActiveTab },
  } = useAddNewNoonReportCTX();
  const {
    value: { dataSource, dataLoading },
    dispatch: { setSelectedRow },
  } = useFOMGOROBInfo();
  const {
    value: { mode },
  } = useNoonReportCTX();
  const columns: ColumnsType<IFOMGOROB> = [
    {
      title: "FO/MGO type",
      dataIndex: "type",
      key: "type",
      align: "center",
      render: (value) => FOMGOTypeName[value - 1],
    },
    {
      title: "Last day ROB",
      dataIndex: "LastDayROB",
      key: "LastDayROB",
      align: "center",
    },
    {
      title: "ROB",
      dataIndex: "ROB",
      key: "ROB",
      align: "center",
    },
  ];
  return (
    <Col span={24}>
      <div className="titleHolder pt-[16px]">Table of FO,MGO ROB</div>
      <TableUI
        columns={columns}
        tableType="primary"
        dataSource={dataSource}
        loading={dataLoading}
        rowClassName={"cursor-pointer"}
        onRow={(row) => ({
          onClick: () => setSelectedRow(row),
        })}
      />

      {mode === "create" && (
        <div className=" flex justify-between gap-[16px] footer">
          <Button
            className="w-[100px]"
            onClick={() => setActiveTab(NewNoonReportTabs.Voyage)}
          >
            Back
          </Button>
          <Button
            type="primary"
            className="w-[100px] justify-center items-center"
            disabled={dataSource.length === 0}
            onClick={() => setActiveTab(NewNoonReportTabs.FOMGOCONS)}
          >
            Next
          </Button>
        </div>
      )}
    </Col>
  );
};

export default FOMGOROBTable;
