import { Button, Col, Row, Spin } from "antd";
import { Dispatch, FC, SetStateAction, useRef, useState } from "react";
import { useEditZipFileContext } from "src/modules/editZipFileInformation/context";
import { DeletePopupModal } from "./DeletePopupModal";
import { LoadingOutlined } from "@ant-design/icons";

interface IModalFooterProps {
  hasFile: boolean;
  setHasFile?: Dispatch<SetStateAction<boolean>>;
  onCancel: () => void;
  addFileOnChange: (file: Blob | null) => Promise<void | boolean>;
  onDownload?: () => void;
  fileBase64?: string;
  file?: boolean | Blob;
  onDeleteConfirm?: () => void;
  downloadLoading?: boolean;
  downloadProgress?: number;
}

export const ModalFooter: FC<IModalFooterProps> = ({
  hasFile,
  setHasFile,
  onCancel,
  addFileOnChange,
  onDownload,
  fileBase64,
  onDeleteConfirm,
  downloadLoading,
  downloadProgress,
}) => {
  const ref = useRef<HTMLInputElement>(null);
  const loadingIcon = <LoadingOutlined style={{ fontSize: 16 }} spin />;
  const [uploadedFile, setUploadedFile] = useState<boolean>(false);

  const {
    dispatch: { setShowDeleteModal, setIsModalOpen, setIsModalOpenItems },
  } = useEditZipFileContext()!;

  const deleteConfirm = () => {
    setHasFile && setHasFile(false);
    onDeleteConfirm && onDeleteConfirm();
  };
  return (
    <Row>
      <Col span={12} className="flex justify-start items-center gap-[24px]">
        <Button
          type="primary"
          disabled={hasFile}
          onClick={() => {
            ref.current?.click();
          }}
        >
          Add file
        </Button>
        <input
          type="file"
          style={{ display: "none" }}
          ref={ref}
          onChange={(e) => {
            addFileOnChange(e.target.files ? e.target.files[0] : null).finally(
              () => {
                setUploadedFile(true);
              }
            );
          }}
        />

        <Button
          disabled={!hasFile}
          onClick={() => {
            onCancel();
            setTimeout(() => setUploadedFile(false), 300);
          }}
        >
          {uploadedFile ? "Done" : "Cancel"}
        </Button>
      </Col>
      <Col span={12} className="flex justify-end items-center gap-[24px]">
        {downloadLoading ? (
          <div className="relative">
            <Spin size="small" indicator={loadingIcon} />
            {downloadProgress ? (
              <span className="absolute top-[8px] left-[4px] text-[6px]">
                {downloadProgress}
              </span>
            ) : null}
          </div>
        ) : fileBase64 && hasFile ? (
          <a
            // download={referenceNumber}
            href={fileBase64}
            className="flex justify-center items-center"
          >
            <span
              className={`material-icons cursor-pointer`}
              style={{ color: hasFile ? "#2C73FC" : "#A5C3FE" }}
            >
              browser_updated
            </span>
          </a>
        ) : (
          <span
            className={`material-icons cursor-pointer`}
            style={{ color: hasFile ? "#2C73FC" : "#A5C3FE" }}
            onClick={() => {
              hasFile && onDownload && onDownload();
            }}
          >
            browser_updated
          </span>
        )}
        <span
          className={`material-symbols-outlined cursor-pointer`}
          style={{ color: hasFile ? "#FF1414" : "#FCC6C6" }}
          onClick={() => {
            setShowDeleteModal(true);
            setIsModalOpen(false);
            setIsModalOpenItems(false);
          }}
        >
          delete_forever
        </span>
      </Col>
      <DeletePopupModal onConfirmDelete={deleteConfirm} />
    </Row>
  );
};
