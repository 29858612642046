import { Col, DatePicker, Radio, Select } from "antd";
import { RadioChangeEvent } from "antd/lib";
import { FC, useEffect, useState } from "react";
import { SInput } from "src/components/UiKit/Input";
import { KindInvoiceContainer } from "../../styles";
import { FormItem } from "src/components/UiKit/FormItem";
import { IInvoiceType } from "src/services/Invoice/models";
import { SystemService } from "src/services/System/System.service";
import { IGetSupplier } from "src/services/System/models";
import usePublicApiStore from "src/store/publicApi";
import { invoiceReportFormItemsName } from "../../constant/flterFormNames";
const { RangePicker } = DatePicker;
export const KindInvoice: FC = () => {
  const [value, setValue] = useState(undefined);
  const [suppliers, setSuppliers] = useState<IGetSupplier[]>([]);
  const [suppLoading, setSuppLoading] = useState<boolean>(false);
  const { allCurrency } = usePublicApiStore();
  const onChangeRadio = (e: RadioChangeEvent) => {
    setValue(e.target.value);
  };
  const fetchSuppliers = async () => {
    try {
      setSuppLoading(true);
      const { GetSupplier } = new SystemService();
      const res = await GetSupplier();
      if (res && res.data) {
        setSuppliers(res.data.records);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setSuppLoading(false);
    }
  };
  useEffect(() => {
    fetchSuppliers();
  }, []);
  return (
    <KindInvoiceContainer gutter={[24, 24]}>
      <Col span={24} className="px-[16px]">
        <div className="flex flex-col gap-[40px]">
          <div className="radioInputTitle">
            Please select the kind of invoice.
          </div>
          <div>
            <FormItem name={invoiceReportFormItemsName["Invoice type"]}>
              <Radio.Group onChange={onChangeRadio} value={value}>
                <Radio className="radio" value={IInvoiceType.Invoice}>
                  invoice
                </Radio>
                <Radio className="radio" value={IInvoiceType.PreInvoice}>
                  pre-invoice
                </Radio>
                <Radio className="radio" value={IInvoiceType.ReserveFund}>
                  Reserve fund
                </Radio>
              </Radio.Group>
            </FormItem>
          </div>
        </div>
      </Col>
      <Col span={8}>
        <FormItem
          name={invoiceReportFormItemsName["Invoice number"]}
          label="Invoice number"
          className="!mb-0"
        >
          <SInput />
        </FormItem>
      </Col>
      {/* <Col span={8}>
        <FormItem
          name={invoiceReportFormItemsName["Related Voyage No"]}
          label="Related Voyage No"
          className="!mb-0"
        >
          <SInput />
        </FormItem>
      </Col> */}
      <Col span={8}>
        <FormItem
          name={invoiceReportFormItemsName["Requester Invoice number"]}
          label="Requester Invoice number"
          className="!mb-0"
        >
          <SInput />
        </FormItem>
      </Col>
      <Col span={8}>
        <FormItem
          name={invoiceReportFormItemsName["Requester Invoice Date"]}
          label="Requester Invoice Date"
          className="!mb-0"
        >
          <RangePicker
            style={{ width: "100%" }}
            className="rangePicker"
            placeholder={["Start", "End"]}
          />
        </FormItem>
      </Col>
      <Col span={8}>
        <FormItem
          name={invoiceReportFormItemsName.Supplier}
          label="Supplier"
          className="!mb-0"
        >
          <Select
            className="selectOption"
            optionFilterProp="label"
            options={suppliers.map((supp) => ({
              label: supp.name,
              value: supp.id,
            }))}
            placeholder="Select"
            showSearch
            allowClear
            loading={suppLoading}
          />
        </FormItem>
      </Col>
      <Col span={8}>
        <FormItem
          name={invoiceReportFormItemsName["Supplier Invoice number"]}
          label="Supplier Invoice number"
          className="!mb-0"
        >
          <SInput />
        </FormItem>
      </Col>
      <Col span={8}>
        <FormItem
          name={invoiceReportFormItemsName["Supplier Invoice Date"]}
          label="Supplier Invoice Date"
          className="!mb-0"
        >
          <RangePicker
            style={{ width: "100%" }}
            className="rangePicker"
            placeholder={["Start", "End"]}
          />
        </FormItem>
      </Col>
      <Col span={8}>
        <FormItem
          name={invoiceReportFormItemsName["Invoice currency"]}
          label="Invoice currency"
          className="!mb-0"
        >
          <Select
            style={{ width: "100%" }}
            placeholder="Select"
            optionLabelProp="name"
            optionFilterProp="label"
            showSearch
            allowClear
            className="selectOption"
            options={allCurrency?.data?.map((currency) => ({
              value: currency.id,
              name: currency.shortName,
              label: currency.name,
            }))}
          />
        </FormItem>
      </Col>
      <Col span={4}>
        <div className="absoluteLabel">Supplier total Amount</div>
        <FormItem
          name={invoiceReportFormItemsName["Supplier total from amount"]}
          className="!mb-0 mt-[32px]"
        >
          <SInput placeholder="From" numbermode />
        </FormItem>
      </Col>
      <Col span={4}>
        <FormItem
          name={invoiceReportFormItemsName["Supplier total to amount"]}
          className="!mb-0 mt-[32px]"
        >
          <SInput placeholder="To" numbermode />
        </FormItem>
      </Col>
      <Col span={8}>
        <FormItem
          name={invoiceReportFormItemsName["Our date"]}
          label="Our date"
          className="!mb-0"
        >
          <RangePicker
            style={{ width: "100%" }}
            className="rangePicker"
            placeholder={["Start", "End"]}
          />
        </FormItem>
      </Col>

      <Col span={4}>
        <div className="absoluteLabel">Ship management total Amount</div>

        <FormItem
          name={invoiceReportFormItemsName["Ship management total from amount"]}
          className="!mb-0 "
        >
          <SInput placeholder="From" className="mt-[32px] " numbermode />
        </FormItem>
      </Col>
      <Col span={4}>
        <FormItem
          name={invoiceReportFormItemsName["Ship management total to amount"]}
          className="!mb-0 mt-[32px]"
        >
          <SInput placeholder="To" numbermode />
        </FormItem>
      </Col>
    </KindInvoiceContainer>
  );
};
