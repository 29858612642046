import { FC } from "react";
import { FOMGOCONSInfoForm } from "./components/FOMGOCONSInfoForm";
import FOMGOCONSTable from "./components/FOMGOCONSTable";
import { FOMGOCONSInfoProvider } from "./context";
import { FOMGOCONSInfoStyle } from "./styles";

export const FOMGOCONSInfo: FC = () => {
  return (
    <FOMGOCONSInfoProvider>
      <FOMGOCONSInfoStyle gutter={[32, 24]}>
        <FOMGOCONSInfoForm />
        <FOMGOCONSTable />
      </FOMGOCONSInfoStyle>
    </FOMGOCONSInfoProvider>
  );
};
