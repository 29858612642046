import { Row } from "antd";
import styled from "styled-components";

export const AssignRoleToFrontContainer = styled(Row)`
  .assign-col {
    background-color: #fff;
    border-radius: 20px;
    padding: 24px 32px;
  }
  .ant-form-item .ant-form-item-label > label {
    color: #001377;
    font-weight: 500;
    font-size: 14px;
    padding: 0;
    margin: 0;
    height: unset;
  }
  .add-role-btn.ant-form-item .ant-form-item-label > label {
    color: #fff;
    font-weight: 500;
    font-size: 14px;
    padding: 0;
    margin: 0;
    height: unset;
  }
`;
