import { FC } from "react";
import { ShipDailyReportDetailStyle } from "./style";
import { ShipDailyReportDetailProvider } from "./context";
import ReportDetail from "./components/reports";

export const ShipDailyReportDetail: FC = () => {
  return (
    <ShipDailyReportDetailProvider>
      <ShipDailyReportDetailStyle>
        <ReportDetail />
      </ShipDailyReportDetailStyle>
    </ShipDailyReportDetailProvider>
  );
};
