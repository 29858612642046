import { Tabs, TabsProps } from "antd";
import { FC, useState } from "react";
import { TabsContainer } from "./styles";
import { ICategoryTabs } from "./models";
import { AddFrontAccess } from "./components/AddFrontAccess";
import { FrontAccessTable } from "./components/FrontAccessTable";
import { EditFrontAccess } from "./components/EditFrontAccess";
import { useFrontAccess } from "./context";
import { TableHeaderItems } from "./components/Header";
import AssignRoleToFront from "./components/AssignRole";

export const FrontAccess: FC = () => {
  const [activeKey, setActiveKey] = useState<string>(ICategoryTabs.addCategory);
  const {
    value: { mode },
  } = useFrontAccess();

  const onChange = (key: string) => {
    setActiveKey(key);
  };

  const items: TabsProps["items"] = [
    {
      key: ICategoryTabs.addCategory,
      label: (
        <span>
          <span className="material-icons text-[10px] p-[8px]">lens</span>
          Create Front Access
        </span>
      ),
      children: <AddFrontAccess />,
    },
    {
      key: ICategoryTabs.categoriesTable,
      label: (
        <span>
          <span className="material-icons text-[10px] p-[10px]">lens</span>
          Front Access table
        </span>
      ),
      children: <FrontAccessTable />,
    },
  ];

  return mode === "edit" ? (
    <EditFrontAccess />
  ) : mode === "assign" ? (
    <AssignRoleToFront />
  ) : (
    <TabsContainer>
      {activeKey === ICategoryTabs.categoriesTable && <TableHeaderItems />}
      <Tabs items={items} onChange={onChange} activeKey={activeKey} animated />
    </TabsContainer>
  );
};
