import { styled } from "styled-components";

export const DeletePopupModalContainer = styled.div`
  border-radius: 12px;
  border: 1px solid var(--shadow, #dadeec);
  .content {
    color: var(--text, #001377);
    font-family: DM Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;
