import { MenuProps } from "antd";
import { ItemType, MenuItemType } from "antd/es/menu/interface";

type MenuItem = Required<MenuProps>["items"][number];

export const getItem = (
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: "group",
  disabled?: boolean
) => {
  return {
    key,
    icon,
    children,
    label,
    type,
    disabled,
  } as ItemType<MenuItemType>;
};
