import { Button, Col, Divider, Modal, Row, Tabs, TabsProps } from "antd";
import { FC, useMemo } from "react";
import { ZipInformation } from "./zip-information";
import { AttachZipFileKeys } from "../../models/AttachZipFileKeys";
import { useAttachZipFileContext } from "../../context/attachZipFile";
import { AttachZip } from ".";

export const AttachZipFile: FC = () => {
  const {
    value: {
      activeKey,
      isModalOpenAttachZip,
      zipInformationLoading,
      attachmentLoading,
      zipInformationForm,
      screenshots,
      zipFile,
    },
    dispatch: { setIsModalOpenAttachZip },
    func: { onBackAttachment, onFinishAttachment },
  } = useAttachZipFileContext()!;

  const handleOk = () => {
    setIsModalOpenAttachZip(false);
  };

  const handleCancel = () => {
    setIsModalOpenAttachZip(false);
  };
  const footers: { [key: string]: JSX.Element } = useMemo(
    () => ({
      [AttachZipFileKeys.ZipInformation]: (
        <Row>
          <Divider />

          <Col span={24} className="flex justify-end gap-[16px] footer">
            <Button
              onClick={() => {
                setIsModalOpenAttachZip(false);
                zipInformationForm.resetFields();
              }}
              htmlType="button"
              className="w-[100px]"
            >
              Cancel
            </Button>
            <Button
              type="primary"
              onClick={zipInformationForm.submit}
              htmlType="button"
              loading={zipInformationLoading}
              className="w-[100px]"
            >
              Next
            </Button>
          </Col>
        </Row>
      ),
      [AttachZipFileKeys.AttachZip]: (
        <Row>
          <Divider />
          <Col
            span={24}
            className="flex justify-end gap-[16px] footer  mt-[24px]"
          >
            <Button
              type="primary"
              htmlType="button"
              onClick={onFinishAttachment}
              loading={attachmentLoading}
            >
              Finish
            </Button>
          </Col>
        </Row>
      ),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      attachmentLoading,
      onBackAttachment,
      onFinishAttachment,
      screenshots,
      setIsModalOpenAttachZip,
      zipFile,
      zipInformationForm.submit,
      zipInformationLoading,
    ]
  );
  const items: TabsProps["items"] = [
    {
      key: AttachZipFileKeys.ZipInformation,
      label: "Zip information",
      children: <ZipInformation />,
      disabled: activeKey !== AttachZipFileKeys.ZipInformation,
    },
    {
      key: AttachZipFileKeys.AttachZip,
      label: "Attach zip file",
      children: <AttachZip />,
      disabled: activeKey !== AttachZipFileKeys.AttachZip,
    },
  ];

  return (
    <Modal
      width={"58%"}
      title="Attach Zip file"
      open={isModalOpenAttachZip}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={footers[activeKey]}
      styles={{ body: { height: "700px" } }}
      closable={false}
      maskClosable={false}
      style={{ top: 24 }}
    >
      <Tabs activeKey={activeKey} items={items} centered />
    </Modal>
  );
};
