import { FC } from "react";
import { Payer } from "src/modules/Payer";
import { PayerProvider } from "src/modules/Payer/context";

export const PayerPage: FC = () => {
  return (
    <PayerProvider>
      <Payer />
    </PayerProvider>
  );
};
