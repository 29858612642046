import { FC, useEffect } from "react";
import { TableContainer } from "../style";
import { TableUI } from "src/components/UiKit/table";
import { Col, Popconfirm, Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";
import { getRowKey } from "src/helpers/getRowKey";
import { useReceiver } from "../context";
import { IAllReceivers } from "src/services/Income/models";
import { Guard } from "src/components/Guard";
import {
  DeleteReceiverPath,
  UpdateReceiverPath,
} from "src/services/Income/guardPath";

export const ReceiverTable: FC = () => {
  const {
    value: { tableData, tableLoading },
    dispatch: { setEditMode, setReceiverId, setEditData },
    func: { getReceiver, onDelete },
  } = useReceiver();

  const onEdit = (id: number, record: IAllReceivers) => {
    setEditMode(true);
    setReceiverId(id);
    setEditData(record);
  };

  const columns: ColumnsType<IAllReceivers> = [
    {
      title: "#",
      key: "id",
      align: "center",
      width: "55px",
      render: (name, record, index) => index + 1,
      // (pagination.current - 1) * pagination.pageSize + (index + 1),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      align: "center",
      width: "264px",
    },
    {
      title: "Description",
      key: "description",
      align: "center",
      width: "264px",
      render: (name, record, index) =>
        record.description && record.description.length < 30 ? (
          <div>{record.description}</div>
        ) : (
          <Tooltip
            className="cursor-pointer"
            placement="bottom"
            title={record.description}
          >
            {record.description?.substring(0, 27).concat("...")}
          </Tooltip>
        ),
    },
    {
      title: "Tools",
      key: "tools",
      align: "center",
      width: "130px",
      render: (text, record) => (
        <div className="flex justify-center items-center gap-[16px] text-[16px]">
          <Guard action={UpdateReceiverPath}>
            <span
              onClick={() => {
                onEdit(record.id, record);
              }}
              className="material-icons !text-[#0C9D61] text-[16px] cursor-pointer"
            >
              edit_note
            </span>
          </Guard>

          <Guard action={DeleteReceiverPath}>
            <Popconfirm
              title="Delete country"
              onConfirm={async () => {
                await onDelete(record.id);
              }}
              description="Are you want to delete this?"
            >
              <span className="material-icons !text-[#EC2D30] text-[16px] cursor-pointer">
                delete_forever
              </span>
            </Popconfirm>
          </Guard>
        </div>
      ),
    },
  ];

  useEffect(() => {
    getReceiver();
  }, [getReceiver]);

  return (
    <TableContainer justify="center">
      <Col span={15}>
        <TableUI
          style={{ padding: "none" }}
          columns={columns}
          rowKey={getRowKey}
          dataSource={tableData}
          loading={tableLoading}
          pagination={false}
        />
      </Col>
    </TableContainer>
  );
};
