import { FC } from "react";
import { IWorkFlowStep } from "src/services/WorkFlow/models";
import { FlowSections } from "../../model/sectionTypes";
import { RolesCardStyle } from "./styles";
import { NewFlowTabs } from "../../model/newFlowTabs";
import { useAddNewFlowCTX } from "../../context";
interface IProps extends IWorkFlowStep {
  selected: boolean;
  onClick: () => void;
  focused: boolean;
}
export const RoleAndActionCard: FC<IProps> = (step) => {
  const {
    value: { activeTab },
  } = useAddNewFlowCTX();
  return (
    <RolesCardStyle
      $selected={step.selected}
      onClick={step.onClick}
      $focused={step.focused}
    >
      <div className="wrapper ">
        <div className="flex justify-between">
          <div className="name-status">
            <div className="stepName">{step.name}</div>
            <div className="status">
              {step.state === FlowSections.start
                ? "Start"
                : step.state === FlowSections.inprogressEnd
                ? "In Progress"
                : step.state === FlowSections.inprogress
                ? "In Progress"
                : step.state === FlowSections.end
                ? "End"
                : ""}
            </div>
          </div>
          <div className="flex gap-[8px]">
            {activeTab === NewFlowTabs.FlowAction &&
              ((step.actionNumber && step.actionNumber > 0) ||
                step.selected) && (
                <div className="actionsHolder">
                  {step.actionNumber || 0} Action
                </div>
              )}
            {(activeTab === NewFlowTabs.AssignRole ||
              activeTab === NewFlowTabs.FlowAction) &&
              ((step.roleNumber && step.roleNumber > 0) || step.selected) && (
                <div className="rolesHolder">{step.roleNumber} Role</div>
              )}
          </div>
        </div>
        <div className="description ">
          <div className="flex justify-start gap-[12px] ">
            <div className="des-text">
              <span
                className={`material-icons text-[12px] ${
                  step.hasAttachment ? "text-[#1DCF0E]" : ""
                }`}
              >
                check_box
              </span>
              <span className="whitespace-nowrap">Has attachment</span>
            </div>
            <div className="des-text">
              <span
                className={`material-icons text-[12px] ${
                  step.commentMandatory ? "text-[#1DCF0E]" : ""
                }`}
              >
                disabled_by_default
              </span>
              <span className="whitespace-nowrap">Comment is mandatory</span>
            </div>
          </div>
          <div className="des-text">
            <span
              className={`material-icons text-[12px] ${
                step.attachmentComment ? "text-[#1DCF0E]" : ""
              }`}
            >
              sms
            </span>
          </div>
        </div>
      </div>
    </RolesCardStyle>
  );
};
