import { useCallback, useEffect, useState } from "react";
import { DailyReportService } from "src/services/DailyReport/dailyReport.service";
import { PublicService } from "src/services/Public/Public.service";
import useOwnerStore from "src/store/owner";

export const useGetDailyReport = () => {
  const { dailyReport, ownerViewDate, selectedShip, setShips } =
    useOwnerStore();
  const [loading, setLoading] = useState<boolean>(false);
  const getShips = async () => {
    try {
      const { Ship } = new PublicService();
      const res = await Ship();
      if (res && res.status === 200 && res.data) {
        setShips(res.data);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const getDailyReport = useCallback(async () => {
    if (!selectedShip && !ownerViewDate) return;

    try {
      setLoading(true);
      const { ShipDailyReport } = new DailyReportService();
      const result = await ShipDailyReport(
        selectedShip!.id,
        ownerViewDate!.format("YYYY-MM-DD")
      );
      if (result && result.data) {
        // setDailyReport(result.data);
        getShips();
      }
    } catch (err) {
      console.log("Error getting daily report", err);
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ownerViewDate, selectedShip]);

  useEffect(() => {
    if (!dailyReport) {
      getDailyReport();
    } else {
      if (
        !dailyReport.reportDate.includes(
          ownerViewDate ? ownerViewDate.format("YYYY-MM-DD") : dayjs(new Date())
        ) ||
        dailyReport.shipId !== selectedShip?.id
      ) {
        getDailyReport();
      }
    }
  }, [dailyReport, getDailyReport, ownerViewDate, selectedShip?.id]);

  return { loading };
};
function dayjs(arg0: Date): string {
  throw new Error("Function not implemented.");
}
