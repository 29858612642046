import { Dispatch, FC, SetStateAction, useEffect } from "react";
import { Button, Form } from "antd";
import { usePorts } from "../../context";

interface SubmitButtonProps {
  submittable: boolean;
  setSubmittable: Dispatch<SetStateAction<boolean>>;
}

const SubmitButton: FC<React.PropsWithChildren<SubmitButtonProps>> = ({
  submittable,
  setSubmittable,
}) => {
  const {
    value: { form, formMode, loadings },
  } = usePorts();

  const values = Form.useWatch([], form);

  useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then(() => setSubmittable(true))
      .catch(() => setSubmittable(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, values]);

  return (
    <Button
      type="primary"
      disabled={!submittable}
      loading={loadings.addEdit}
      className="footerButton"
      onClick={() => form.submit()}
    >
      {formMode === "add" ? "Finish" : "Save Edit"}
    </Button>
  );
};

export default SubmitButton;
