import { FC, useCallback, useEffect, useState } from "react";
import { TableUI } from "src/components/UiKit/table";
import { IPDFFile } from "../../models";
import { IDocumentsTable } from "./model";
import { ColumnsType } from "antd/es/table";
import { AddInvoiceButton, FileButton } from "../../styles";
import { useAddInvoices } from "src/modules/addInvoice/context";
import { InvoiceService } from "src/services/Invoice/Invoice.service";
import { useTable } from "../../context";
import { Col, Form, Popconfirm, Row } from "antd";
import { RcFile } from "antd/es/upload";
import { FilePreview, PreviewFileType } from "src/components/UiKit/FilePreview";
import { uploadFile } from "src/helpers/uploadZipFile";
import { InvoiceUrls } from "src/services/Invoice/urls";
import { AxiosRequestConfig } from "axios";
import { FormItem } from "src/components/UiKit/FormItem";
import { SInput } from "src/components/UiKit/Input";
import { SDatePicker } from "src/components/UiKit/DatePicker";
import dayjs from "dayjs";
import { Guard } from "src/components/Guard";
import {
  CloseAllRequestAttachmentPath,
  DeleteAllRequestAttachmentPath,
  FileGuardPath,
  InvoiceAttqachmentPath,
  NewInvoiceArticlePath,
  NewInvoicePath,
  PaymanetInformationPath,
  TotlaInvoicePath,
  UpdateAllRequestZipDetailInfpPath,
  UpdateAllRequestZipDetailPath,
} from "src/services/Invoice/guardPath";
const getRowKey = (record: IPDFFile) => record.id;

export const PDFFiles: FC<IDocumentsTable> = ({ files, record }) => {
  const [currentRequestRecord, setCurrentRequestRecord] = useState<IPDFFile>();
  const [uploadLoading, setUploadLoading] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);
  const [currentSubtitle, setCurrentSubtitle] = useState<string | undefined>(
    currentRequestRecord?.fileName
  );
  const [showFilePreview, setShowFilePreview] = useState<boolean>(false);
  const [downloadLoading, setDownloadLoading] = useState<boolean>(false);
  const [downloadProgress, setDownloadProgress] = useState<number>(0);
  const [editLoading, setEditLoading] = useState<boolean>(false);
  const downloadFileHandler = useCallback(async () => {
    try {
      setDownloadLoading(true);
      const { AllRequestZipAttachmentId } = new InvoiceService();
      if (!currentRequestRecord) return;
      const config: AxiosRequestConfig = {
        onDownloadProgress: (progressEvent) => {
          const percentage = Math.round(
            (progressEvent.loaded * 100) / (progressEvent.total || 0)
          );
          setDownloadProgress(percentage);
          if (percentage === 100) {
            setTimeout(() => setDownloadProgress(0), 400);
          }
        },
      };
      const res = await AllRequestZipAttachmentId(
        currentRequestRecord.id,
        config
      );
      if (res) {
        let fileFormat = currentRequestRecord.extention;
        const aEl = document.createElement("a");
        aEl.href = res.base64;
        aEl.download = currentRequestRecord.fileName + fileFormat;
        aEl.click();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setDownloadLoading(false);
    }
  }, [currentRequestRecord]);
  const {
    value: { currentRequest },
    dispatch: { setShowModal, setCurrentAttachment },
  } = useAddInvoices()!;
  const {
    func: { onExpand, refetchZipFile, confirmDeleteAllRequest },
  } = useTable();

  const closeInvoice = async (id: number) => {
    try {
      const { CloseAllRequestAttachment } = new InvoiceService();
      const res = await CloseAllRequestAttachment(id);
      if (res && res.status === 200) {
        if (currentRequest) onExpand(currentRequest);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const pdfFilesColumns: ColumnsType<IPDFFile> = [
    {
      title: "",
      dataIndex: "index",
      key: "index",
      render: (name: string, record: any, index: number) => index + 1,
    },
    {
      title: "Attachment code No.",
      dataIndex: "code",
      key: "code",
      className: "!font-bold",
    },
    {
      title: "Req file name",
      dataIndex: "shipManagementFileName",
      key: "shipManagementFileName",
      className: "!font-bold",
    },
    {
      title: "File",
      dataIndex: "link",
      key: "link",
      className: "!font-bold",

      render: (text, record) => (
        <FileButton
          type="text"
          onClick={() => {
            setCurrentRequestRecord(record);
            setShowFilePreview(true);
            setCurrentSubtitle(record.fileName);
          }}
        >
          <span className="material-symbols-outlined icon !font-bold">
            folder
          </span>
        </FileButton>
      ),
    },
    {
      title: "Invoice added",
      dataIndex: "invoiceAdded",
      key: "invoiceAdded",
      className: "!font-bold",
    },
    {
      title: "Operation",
      dataIndex: "operation",
      key: "operation",
      className: "!font-bold",
      render: (_, RAttachment) =>
        RAttachment.status === 1 ? (
          <div className="flex justify-center items-center">
            <Guard
              action={[
                NewInvoicePath,
                NewInvoiceArticlePath,
                TotlaInvoicePath,
                InvoiceAttqachmentPath,
                PaymanetInformationPath,
              ]}
              multiPath
            >
              <AddInvoiceButton
                type="link"
                onClick={() => {
                  setShowModal(true);
                  setCurrentAttachment(RAttachment);
                }}
                className="!font-bold"
              >
                Add Invoice
              </AddInvoiceButton>
            </Guard>
            <Guard action={CloseAllRequestAttachmentPath}>
              <Popconfirm
                title="Finish"
                description="Are you sure to finish this zip attachment file?"
                onConfirm={() => {
                  closeInvoice(RAttachment.id);
                }}
                okText="Yes"
                cancelText="No"
              >
                <AddInvoiceButton type="link">Finish</AddInvoiceButton>
              </Popconfirm>
            </Guard>
            <Guard action={DeleteAllRequestAttachmentPath}>
              <Popconfirm
                placement="topRight"
                title={"Are you sure to delete this invoice?"}
                description={"Delete the invoice"}
                onConfirm={() => confirmDeleteAllRequest(RAttachment)}
                okText="Yes"
                cancelText="No"
              >
                <span
                  className={`material-symbols-outlined cursor-pointer !font-[22px]`}
                  style={{ color: "#FF1414" }}
                >
                  delete_forever
                </span>
              </Popconfirm>
            </Guard>
          </div>
        ) : (
          <div className="flex ">
            <AddInvoiceButton type="link" onClick={() => {}} disabled>
              send to
            </AddInvoiceButton>
          </div>
        ),
    },
  ];
  const [attachmentForm] = Form.useForm();
  const onCurrentRequestAttachmentChangeHandler = async (
    file: RcFile | Blob | null
  ) => {
    if (!file || !currentRequestRecord) return;
    setUploadLoading(true);
    setCurrentSubtitle((file as RcFile).name);
    const formValues = attachmentForm.getFieldsValue();
    const extraData = {
      attachNumber: formValues.attachNumber,
      attachDate: formValues.attachDate
        ? dayjs(formValues.attachDate).format("YYYY-MM-DD")
        : null,
    };
    uploadFile({
      setProgress,
      callbackUrl: InvoiceUrls.Invoice.concat(
        `${currentRequestRecord?.id}`
      ).concat(InvoiceUrls.UpdateAllRequestZipDetail),
      method: "PUT",
      newFile: file || undefined,
      extraData,
    })
      .then(() => {
        refetchZipFile().then(() => {
          if (currentRequest) onExpand(currentRequest);
        });
      })
      .finally(() => {
        setUploadLoading(false);
      });
    return;
  };
  useEffect(() => {
    if (currentRequestRecord) {
      const attachDate = dayjs(currentRequestRecord.attachDate);
      const attachNumber = currentRequestRecord.attachNumber;
      attachmentForm.setFieldsValue({ attachDate, attachNumber });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRequestRecord]);
  const onEdit = async () => {
    try {
      if (!currentRequestRecord) return;
      setEditLoading(true);
      const { UpdateAllRequestZipDetailInfo } = new InvoiceService();
      const values = attachmentForm.getFieldsValue();
      const res = await UpdateAllRequestZipDetailInfo(currentRequestRecord.id, {
        attachDate: values.attachDate
          ? dayjs(values.attachDate).format("YYYY-MM-DD")
          : undefined,
        attachNumber: values.attachNumber,
      });
      if (res && res.status === 200) {
        refetchZipFile().then(() => {
          if (currentRequest) onExpand(currentRequest);
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      setEditLoading(false);
    }
  };
  return (
    <div className="w-full">
      <TableUI
        tableType="secondary"
        columns={pdfFilesColumns}
        rowKey={getRowKey}
        dataSource={files}
        pagination={false}
        // loading={loading}
      />
      <FilePreview
        addFileOnChange={onCurrentRequestAttachmentChangeHandler}
        file={!!currentRequestRecord?.fileName}
        fileType={
          (currentRequestRecord?.extention.replace(
            ".",
            ""
          ) as PreviewFileType) || "pdf"
        }
        onCancel={() => {
          setShowFilePreview(false);
        }}
        show={showFilePreview}
        title="Edit attachment file information"
        // subTitle={currentSubtitle}
        referenceNumber={currentRequestRecord?.code}
        onDownload={downloadFileHandler}
        closable={false}
        loading={uploadLoading}
        progress={progress}
        fileName={currentSubtitle || ""}
        downloadLoading={downloadLoading}
        downloadProgress={downloadProgress}
        hasEditButton
        onEdit={() => {
          onEdit();
        }}
        editLoading={editLoading}
        actions={{
          editWithFile: UpdateAllRequestZipDetailPath,
          edit: UpdateAllRequestZipDetailInfpPath,
          delete: UpdateAllRequestZipDetailPath,
          download: FileGuardPath,
        }}
      >
        <Row gutter={[24, 24]} className="mt-[24px]">
          <Col span={24}>
            <div className="text-[#001377] text-[16px] py-[16px]">
              You can delete this file and add a new file or edit its
              information and click the "save edit" button at the end.
            </div>
          </Col>
          <Col span={24}>
            <Form form={attachmentForm}>
              <Row gutter={24}>
                <Col span={12}>
                  <FormItem label="Attach No." name={"attachNumber"}>
                    <SInput />
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem label="Attach date" name={"attachDate"}>
                    <SDatePicker className="w-full" />
                  </FormItem>
                </Col>
                <Col span={12}></Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </FilePreview>
    </div>
  );
};
