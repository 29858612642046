import { Popconfirm, message } from "antd";
import { FC } from "react";
import { useDailyReport } from "src/modules/ShipDailyReport/context";
import { IShipStatus } from "src/services/DailyReport/models/args.models";
import { INewAllShipDailyReportResult } from "src/services/DailyReport/models/result.model";
import dayjs from "dayjs";
import { DailyReportService } from "src/services/DailyReport/dailyReport.service";
import { Guard } from "src/components/Guard";
import {
  ConfirmDailyReportPath,
  DeleteDailyReportPath,
  EditDailyReportPath,
  RejectDailyReportPath,
} from "src/services/DailyReport/guardPath";
export const Tools: FC<{ report: INewAllShipDailyReportResult }> = ({
  report,
}) => {
  const {
    dispatch: {
      setEditMode,
      setTableCurrentValue,
      setReportId,
      setIsEditClicked,
    },
    func: { AllShipDailyReport },
  } = useDailyReport()!;
  //----------------------------------------------------------------async-func----------------------------------------------------------------
  const rejectReport = async (id: number) => {
    try {
      const { RejectDailyReport } = new DailyReportService();
      const response = await RejectDailyReport(id);
      if (response && response.status === 200) {
        message.success("Reject report successfully");
        AllShipDailyReport();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const confirmReport = async (id: number) => {
    try {
      const { ConfirmDailyReport } = new DailyReportService();
      const response = await ConfirmDailyReport(id);
      if (response && response.status === 200) {
        message.success("Confirm report successfully");
        AllShipDailyReport();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const deleteReport = async (id: number) => {
    try {
      const { DeleteDailyReport } = new DailyReportService();
      const response = await DeleteDailyReport(id);
      if (response && response.status === 200) {
        message.success("Delete report successfully");
        AllShipDailyReport();
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div className="flex gap-[16px] items-center justify-center">
      <Guard action={RejectDailyReportPath}>
        <Popconfirm
          disabled={
            // report.stauts === IShipStatus.Rejected ||
            // (report.stauts === IShipStatus.Accepted && report.isSeen === true)
            report.stauts === IShipStatus.Rejected
          }
          icon=""
          placement="topRight"
          title={<div>{report.shipName} report</div>}
          description={
            <div className="border-[1px] border-solid border-[#DADEEC] p-[48px] rounded-[12px]">
              Are you sure you want to Rejected this report?
            </div>
          }
          onConfirm={() => {
            rejectReport(report.id);
          }}
          rootClassName="rejectedPopconfirm"
          okText={
            <div className="flex gap-[12px] items-center">
              <span
                className={`material-icons text-[#Fff] text-[16px] cursor-pointer`}
              >
                remove_circle
              </span>
              <div className="text-[#fff]">Rejected</div>
            </div>
          }
          cancelText={
            <div className="flex gap-[12px] items-center">
              <span
                className={`material-icons text-[#8E8E8E] text-[16px] cursor-pointer`}
              >
                close
              </span>
              <div className="text-[#8E8E8E]">Cancel</div>
            </div>
          }
        >
          {report.stauts === IShipStatus.Rejected ? (
            <span className={`material-icons text-[#C9C9C9] text-[16px] `}>
              remove_circle
            </span>
          ) : (
            <div>
              <span
                className={`material-icons text-[#FD29B5] text-[16px] cursor-pointer`}
              >
                remove_circle
              </span>
            </div>
          )}
        </Popconfirm>
      </Guard>
      <Guard action={ConfirmDailyReportPath}>
        <Popconfirm
          disabled={report.stauts === IShipStatus.Accepted}
          icon=""
          placement="topRight"
          title={<div>{report.shipName} report</div>}
          description={
            <div className="border-[1px] border-solid border-[#DADEEC] p-[48px] rounded-[12px]">
              Are you sure you want to Accepted this report?
            </div>
          }
          onConfirm={() => {
            confirmReport(report.id);
          }}
          rootClassName="acceptedPopconfirm"
          okText={
            <div className="flex gap-[12px] items-center">
              <span
                className={`material-icons text-[#Fff] text-[16px] cursor-pointer`}
              >
                check_circle
              </span>
              <div className="text-[#fff]">Accepted</div>
            </div>
          }
          cancelText={
            <div className="flex gap-[12px] items-center">
              <span
                className={`material-icons text-[#8E8E8E] text-[16px] cursor-pointer`}
              >
                close
              </span>
              <div className="text-[#8E8E8E]">Cancel</div>
            </div>
          }
        >
          {report.stauts === IShipStatus.Accepted ? (
            <span
              className={`material-icons text-[#C9C9C9] text-[16px] cursor-not-allowed`}
            >
              check_circle
            </span>
          ) : (
            <span
              className={`material-icons text-[#1DCF0E] text-[16px] cursor-pointer`}
            >
              check_circle
            </span>
          )}
        </Popconfirm>
      </Guard>

      <div>
        <span
          className="material-icons text-[#5D6881] text-[16px] cursor-pointer mt-[5px]"
          onClick={() => {
            const newData: any = report;
            newData.reportDate = dayjs(report.reportDate);
            setReportId(report.id);
            setTableCurrentValue(newData);
            setEditMode(true);
          }}
        >
          remove_red_eye
        </span>
      </div>
      <Guard action={EditDailyReportPath}>
        <div
          onClick={() => {
            if (report.stauts !== IShipStatus.Accepted) {
              const newData: any = report;
              newData.reportDate = dayjs(report.reportDate);
              setReportId(report.id);
              setTableCurrentValue(newData);
              setEditMode(true);
              setIsEditClicked(true);
            }
          }}
        >
          {report.stauts === IShipStatus.Accepted ? (
            <span className="material-icons text-[16px] text-[#C9C9C9] mt-[5px] cursor-not-allowed">
              edit_note
            </span>
          ) : (
            <span className="material-icons text-[16px] text-[#2C73FC] cursor-pointer mt-[5px]">
              edit_note
            </span>
          )}
        </div>
      </Guard>
      <Guard action={DeleteDailyReportPath}>
        <Popconfirm
          disabled={report.stauts === IShipStatus.Accepted}
          icon=""
          placement="topRight"
          title={<div>{report.shipName} report</div>}
          description={
            <div className="border-[1px] border-solid border-[#DADEEC] p-[48px] rounded-[12px]">
              Are you sure you want to delete this report?
            </div>
          }
          onConfirm={() => {
            deleteReport(report.id);
          }}
          rootClassName="deletePopconfirm"
          okText={
            <div className="flex gap-[12px] items-center">
              <span
                className={`material-icons text-[#Fff] text-[16px] cursor-pointer`}
              >
                delete_forever
              </span>
              <div className="text-[#fff]">Delete</div>
            </div>
          }
          cancelText={
            <div className="flex gap-[12px] items-center">
              <span
                className={`material-icons text-[#8E8E8E] text-[16px] cursor-pointer`}
              >
                close
              </span>
              <div className="text-[#8E8E8E]">Cancel</div>
            </div>
          }
        >
          {report.stauts === IShipStatus.Accepted ? (
            <span
              className={`material-icons text-[#C9C9C9] text-[16px] cursor-not-allowed`}
            >
              delete_forever
            </span>
          ) : (
            <span
              className={`material-icons text-[#FF1414] text-[16px] cursor-pointer`}
            >
              delete_forever
            </span>
          )}
        </Popconfirm>
      </Guard>
    </div>
  );
};
