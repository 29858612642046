import { Position, ShipStatus, TradeStatus } from "src/models/enums";

export const tradeStatus = (tradeStatus: number) => {
  switch (tradeStatus) {
    case TradeStatus.NoVoyage:
      return "No Voyage";
    case TradeStatus.InVoyage:
      return "In Voyage";
    default:
      return "";
  }
};

export const shipStatusText = (shipStatus: number) => {
  switch (shipStatus) {
    case ShipStatus.Laden:
      return "Laden";
    case ShipStatus.Ballast:
      return "Ballast";
    default:
      return "";
  }
};

export const positionText = (position: number) => {
  switch (position) {
    case Position.POLAnchorage:
      return "POL_Anchorage";
    case Position.POLOPL:
      return "POL_OPL";
    case Position.POLBerthed:
      return "POL_Berthed";
    case Position.DryDock:
      return "Dry_Dock";
    case Position.PODAnchorage:
      return "POD_Anchorage";
    case Position.PODOPL:
      return "POD_OPL";
    case Position.PODBerthed:
      return "POD_Berthed";
    case Position.RepairAndMaintenance:
      return "Repair and Maintenance";
    default:
      return "";
  }
};
