import { FC, useEffect, useState } from "react";
import { useAddShip } from "../../context";
import { Button, Form, Modal, message } from "antd";
import { AddCountryModalContainer } from "../AddNewShip/styles";
import { FormItem } from "src/components/UiKit/FormItem";
import { SInput } from "src/components/UiKit/Input";
import { INewCurrency } from "src/services/System/models";
import { SystemService } from "src/services/System/System.service";
import { Guard } from "src/components/Guard";
import { NewCurrencyPath } from "src/services/System/guardPath";

export const AddCurrencyModal: FC = () => {
  const {
    value: { isAddCurrencyModalOpen },
    dispatch: { setIsAddCurrencyModalOpen },
    func: { fetchAllCurrency },
  } = useAddShip()!;
  const handleOk = () => {
    setIsAddCurrencyModalOpen(false);
  };

  const handleCancel = () => {
    setIsAddCurrencyModalOpen(false);
    form.resetFields();
  };
  const [loading, setLoading] = useState<boolean>(false);
  const [submittable, setSubmittable] = useState<boolean>(false);

  const [form] = Form.useForm();

  const values = Form.useWatch([]);

  useEffect(() => {
    form?.validateFields({ validateOnly: true }).then(
      () => {
        setSubmittable(true);
      },
      () => {
        setSubmittable(false);
      }
    );
  }, [values, form]);

  const onFinish = async (values: INewCurrency) => {
    setLoading(true);
    const reqBody: INewCurrency = {
      shortName: values.shortName,
      name: values.name,
    };
    try {
      const { NewCurrency } = new SystemService();
      const response = await NewCurrency(reqBody);
      if (response && response.status === 200) {
        message.success("Currency created successfully");
        setIsAddCurrencyModalOpen(false);
        setSubmittable(false);
        fetchAllCurrency();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Modal
      rootClassName="AddCountryModal"
      width={421}
      title={<div className="titleHolder">Add currency</div>}
      open={isAddCurrencyModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      closable={false}
      afterClose={() => {
        form.resetFields();
      }}
      footer={
        <div className="flex justify-center content-center gap-[16px] footerHolder">
          <Button className="w-[90px]" onClick={handleCancel}>
            Cancel
          </Button>
          <Guard action={NewCurrencyPath}>
            <Button
              className="w-[90px]"
              onClick={form.submit}
              loading={loading}
              rootClassName={submittable ? "disable" : "enable"}
            >
              Create
            </Button>
          </Guard>
        </div>
      }
    >
      <AddCountryModalContainer>
        <Form
          form={form}
          onFinish={onFinish}
          className="flex justify-center flex-col content-center items-center gap-[16px] pb-[24px]"
        >
          <FormItem
            name={"name"}
            label="Name"
            rules={[{ required: true }]}
            required={false}
            className="!mb-0 w-[75%]"
          >
            <SInput placeholder="insert" />
          </FormItem>
          <FormItem
            name={"shortName"}
            label="Short Name"
            rules={[{ required: true }]}
            required={false}
            className="!mb-0 w-[75%]"
          >
            <SInput placeholder="insert" />
          </FormItem>
        </Form>
      </AddCountryModalContainer>
    </Modal>
  );
};
