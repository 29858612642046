import { Dayjs } from "dayjs";

export enum AttachZipFileKeys {
  ZipInformation = "1",
  AttachZip = "2",
}

export interface IZipInformationInput {
  ship: string;
  shipManagement: string;
  requesterType: string;
  RequesterDate: Dayjs;
  Batch: string;
  zipInformationDescription: string;
  dueDate: Dayjs;
  paymentCurrencyId: number;
}

export interface IAttachZipFile {
  screenShotAttachment: Blob;
  zipAttachment: Blob;
}
