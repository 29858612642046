export enum reactQueryEndPoints {
  allRequest = "allRequest",
  publicShip = "publicShip",
  publicRequester = "publicRequester",
  publicAllCurrency = "publicAllCurrency",
  allReferenceNumber = "allReferenceNumber",
  shipsImage = "shipsImage",
  AllCategory = "allCategory",
  AllSubCategory = "allSubCategory",
  UserInfo = "userInfo",
  AttachZip = "attachZip",
  AllActions = "AllActions",
  AllUserReport = "AllUserReport",
}
