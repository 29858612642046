import { FC } from "react";
import { ShipDailyReportsStyle } from "./style";
import { ShipDailyReportsProvider } from "./context";
import Reports from "./components/reports";
import { ShipDailyReportsFilter } from "./components/Search";

export const ShipDailyReports: FC = () => {
  return (
    <ShipDailyReportsProvider>
      <ShipDailyReportsFilter />
      <ShipDailyReportsStyle>
        <Reports />
      </ShipDailyReportsStyle>
    </ShipDailyReportsProvider>
  );
};
