import { Col, DatePicker, Select, Row } from "antd";
import { FormItem } from "src/components/UiKit/FormItem";
import { FC } from "react";
import usePublicApiStore from "src/store/publicApi";
import { SInput } from "src/components/UiKit/Input";
import { invoiceReportFormItemsName } from "../../constant/flterFormNames";
const { RangePicker } = DatePicker;
export const AllRequest: FC = () => {
  const { requesters, allCurrency } = usePublicApiStore();

  return (
    <Row gutter={[24, 24]}>
      <Col span={8}>
        <FormItem
          name={invoiceReportFormItemsName.Requester}
          label="Requester"
          className="!mb-0"
        >
          <Select
            style={{ width: "100%" }}
            placeholder="Select"
            optionLabelProp="name"
            optionFilterProp="label"
            showSearch
            className="selectOption"
            allowClear
            //   loading={shipLoading}
            options={requesters?.data?.records.map((req) => ({
              value: req.id,
              label: req.name,
              name: req.acronym,
            }))}
          />
        </FormItem>
      </Col>
      <Col span={8}>
        <FormItem
          name={invoiceReportFormItemsName["Payment currency"]}
          label="Payment currency"
          className="!mb-0"
        >
          <Select
            style={{ width: "100%" }}
            placeholder="Select"
            optionLabelProp="name"
            optionFilterProp="label"
            showSearch
            allowClear
            className="selectOption"
            options={allCurrency?.data?.map((currency) => ({
              value: currency.id,
              name: currency.shortName,
              label: currency.name,
            }))}
          />
        </FormItem>
      </Col>
      <Col span={8}>
        <FormItem
          name={invoiceReportFormItemsName["Zip code"]}
          label="Zip code"
          className="!mb-0"
        >
          <SInput />
        </FormItem>
      </Col>
      <Col span={8}>
        <FormItem
          name={invoiceReportFormItemsName["Requester Date"]}
          label="Requester Date"
          className="!mb-0"
        >
          <RangePicker
            style={{ width: "100%" }}
            className="rangePicker"
            placeholder={["Start", "End"]}
          />
        </FormItem>
      </Col>
      <Col span={8}>
        <FormItem
          name={invoiceReportFormItemsName["Due Date"]}
          label="Due Date"
          className="!mb-0"
        >
          <RangePicker
            style={{ width: "100%" }}
            className="rangePicker"
            placeholder={["Start", "End"]}
          />
        </FormItem>
      </Col>
    </Row>
  );
};
