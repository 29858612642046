import { Button, Col, Form, Row, Select, message } from "antd";
import { FC, useEffect, useState } from "react";
import { FormItem } from "src/components/UiKit/FormItem";
import { SInput } from "src/components/UiKit/Input";
import { SDatePicker } from "src/components/UiKit/DatePicker";
import { useAddShip } from "src/modules/AddShips/context";
import { TechnicalInformationContainer } from "../../AddNewShip/styles";
import moment from "moment";
import {
  CargoType,
  IShipTechnicalInformation,
  SizeType,
} from "src/services/Ship/models";
import { ShipService } from "src/services/Ship/Ship.service";
import { SPopconfirm } from "../../AddNewShip/components/popconfirm";

export const EditTechnicalInformation: FC = () => {
  const {
    forms: { TechnicalInformationForm },
    func: { createData, fetchShipInformation },
    value: { newData, tableCurrentValue },
  } = useAddShip()!;
  const [loading, setLoading] = useState<boolean>(false);

  const onFinish = async (values: IShipTechnicalInformation) => {
    if (!tableCurrentValue) {
      return;
    }
    setLoading(true);
    const service = new ShipService();
    const reqBody: IShipTechnicalInformation = {
      beam: values.beam,
      builder: values.builder,
      callSign: values.callSign,
      cargoType: values.cargoType,
      draft: values.draft,
      dwt: values.dwt,
      grt: values.grt,
      imo: values.imo,
      loa: values.loa,
      mmsi: values.mmsi,
      sizeType: values.sizeType,
      yearBuilt: values.yearBuilt
        ? +moment(values.yearBuilt).format("YYYY")
        : null,
    };
    try {
      const response = await service.ShipTechnicalInformation(
        tableCurrentValue.id,
        reqBody
      );
      if (response && response.status === 200) {
        message.success("technical information update successfully");
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    createData();
  }, [createData]);
  return (
    <Form
      form={TechnicalInformationForm}
      onFinish={onFinish}
      className="flex flex-col h-[620px] justify-between"
    >
      <TechnicalInformationContainer className="flex gap-[24px]">
        <Col span={24}>
          <div className="titleHolder pt-[16px]">
            Please insert Technical information.
          </div>
        </Col>
        <Col span={24} className="flex gap-[24px] pt-[16px]">
          <FormItem
            name={"builder"}
            label="Builder"
            // rules={[{ required: true }]}
            required={false}
            className="w-[33%] !mb-0"
          >
            <SInput placeholder="insert" />
          </FormItem>
          <FormItem
            name={"yearBuilt"}
            label="Year built"
            // rules={[{ required: true }]}
            required={false}
            className="w-[33%] !mb-0"
          >
            <SDatePicker
              className="w-[100%]"
              placeholder="Select ( Just YEAR )"
              picker="year"
            />
          </FormItem>
          <FormItem
            name={"draft"}
            label="Draft"
            // rules={[{ required: true }]}
            required={false}
            className="w-[33%] !mb-0"
          >
            <SInput placeholder="insert" />
          </FormItem>
        </Col>
        <Col span={24} className="flex gap-[24px] items-center">
          <FormItem
            name={"beam"}
            label="Beam"
            // rules={[{ required: true }]}
            required={false}
            className="w-[33%] !mb-0"
          >
            <SInput placeholder="insert" />
          </FormItem>
          <FormItem
            name={"grt"}
            label={
              <div className="flex items-center">
                GRT
                <div className="!text-[#5D6881] !text-[12px]">
                  ( Gross Tonnage )
                </div>
              </div>
            }
            // rules={[{ required: true }]}
            required={false}
            className="w-[33%] !mb-0"
          >
            <SInput placeholder="insert" />
          </FormItem>
          <FormItem
            name={"loa"}
            label={
              <div className="flex items-center">
                LOA
                <div className="!text-[#5D6881] !text-[12px]">
                  ( Length Overall )
                </div>
              </div>
            }
            // rules={[{ required: true }]}
            required={false}
            className="w-[33%] !mb-0"
          >
            <SInput placeholder="insert" />
          </FormItem>
        </Col>
        <Col span={24} className="flex gap-[24px] items-center">
          <FormItem
            name={"dwt"}
            label={
              <div className="flex items-center">
                DWT
                <div className="!text-[#5D6881] !text-[12px]">
                  ( Deadweight Tonnage )
                </div>
              </div>
            }
            // rules={[{ required: true }]}
            required={false}
            className="w-[33%] !mb-0"
          >
            <SInput placeholder="insert" />
          </FormItem>
          <FormItem
            name={"cargoType"}
            label="Cargo Type"
            // rules={[{ required: true }]}
            required={false}
            className="w-[33%] !mb-0"
          >
            <Select
              style={{ width: "100%" }}
              placeholder="select"
              optionLabelProp="label"
              showSearch
              className="selectOption"
              optionFilterProp="label"
              options={CargoType}
              allowClear
            />
          </FormItem>
          <FormItem
            name={"sizeType"}
            label="Size Type"
            // rules={[{ required: true }]}
            required={false}
            className="w-[33%] !mb-0"
          >
            <Select
              style={{ width: "100%" }}
              placeholder="select"
              optionLabelProp="label"
              showSearch
              className="selectOption"
              optionFilterProp="label"
              options={SizeType}
              allowClear
            />
          </FormItem>
        </Col>
        <Col span={24} className="flex gap-[24px] items-center">
          <FormItem
            name={"imo"}
            label="IMO ID"
            // rules={[{ required: true }]}
            required={false}
            className="w-[33%] !mb-0"
          >
            <SInput placeholder="insert" />
          </FormItem>

          <FormItem
            name={"mmsi"}
            label="MMSI ID"
            // rules={[{ required: true }]}
            required={false}
            className="w-[33%] !mb-0"
          >
            <SInput placeholder="insert" />
          </FormItem>
          <FormItem
            name={"callSign"}
            label="Call Sign"
            // rules={[{ required: true }]}
            required={false}
            className="w-[33%] !mb-0"
          >
            <SInput placeholder="insert" />
          </FormItem>
        </Col>
      </TechnicalInformationContainer>
      <Row>
        <Col span={24}>
          <div className=" flex gap-[16px] footer justify-end">
            <SPopconfirm
              description="Are you sure you want to reset all the information you filled out?"
              onConfirm={() => {
                TechnicalInformationForm?.setFieldsValue(newData);
                fetchShipInformation();
              }}
            >
              <Button className="w-[100px]">Reset</Button>
            </SPopconfirm>
            <Button
              type="primary"
              className="w-[100px]"
              onClick={TechnicalInformationForm?.submit}
              loading={loading}
            >
              Save Edit
            </Button>
          </div>
        </Col>
      </Row>
    </Form>
  );
};
