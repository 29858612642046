import { IInvoice } from "src/services/Invoice/models";
import { Dayjs } from "dayjs";
export enum EditInvoiceKeys {
  BasicInformation = "1",
  TotalInformation = "2",
}

export interface IEditBaseInfoFormValue
  extends Omit<IInvoice, "suppliernvoiceDate" | "shmInvoiceDate" | "ourDate"> {
  suppliernvoiceDate: Dayjs;
  shmInvoiceDate: Dayjs;
  ourDate: Dayjs;
  reserveParentId: number | undefined;
  preInvParentId: number[] | undefined;
  voyageId: number | undefined;
  usdRate: number;
}
