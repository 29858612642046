import {
  App,
  Button,
  Col,
  DatePicker,
  Divider,
  Flex,
  Form,
  Row,
  Select,
  Skeleton,
  Space,
} from "antd";
import { FC, useEffect, useState } from "react";
import { FormItem } from "src/components/UiKit/FormItem";
import { SInput } from "src/components/UiKit/Input";
import TextArea from "antd/es/input/TextArea";
import { useAddShip } from "src/modules/AddShips/context";
import {
  IShipInsuranceInformation,
  IShipInsuranceValues,
} from "src/services/Ship/models";
import { ShipService } from "src/services/Ship/Ship.service";
import { IAddShipsTabs } from "src/modules/AddShips/models";
import { SPopconfirm } from "../../AddNewShip/components/popconfirm";
import { InsurancesContainer } from "../../AddNewShip/styles";
import STag from "./tag";
import { IObject } from "src/models/interfaces";
import dayjs from "dayjs";
import { ConfirmModal } from "src/components/UiKit/ConfirmModal";
const { RangePicker } = DatePicker;

interface IValues extends IShipInsuranceValues {
  id: number;
}
interface IFormValues {
  [key: string]: Partial<IValues>;
}
export const EditInsurances: FC = () => {
  const {
    dispatch: {
      setActiveKeyAddNewShip,
      setIsAddCurrencyModalOpen,
      setIsEditMOde,
      setIsShowMode,
    },
    forms: { InsurancesForm },
    value: { allCurrency, currencyLoading, tableCurrentValue },
    func: { fetchAllCurrency, fetchShipInformation, resetForms },
  } = useAddShip()!;
  const [submittable, setSubmittable] = useState<boolean>(false);
  const insurancesFormValues = Form.useWatch([], InsurancesForm);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  useEffect(() => {
    InsurancesForm?.validateFields({ validateOnly: true }).then(
      () => {
        setSubmittable(true);
      },
      () => {
        setSubmittable(false);
      }
    );
  }, [insurancesFormValues, InsurancesForm]);
  const { message } = App.useApp();
  const nextTab = () => {
    setActiveKeyAddNewShip(IAddShipsTabs.shipsTable);
    fetchShipInformation();
    setIsEditMOde(false);
    setIsShowMode(false);
    resetForms();
  };

  const [tags, setTags] = useState<string[]>([]);
  const [selectedTag, setSelectedTag] = useState<number>();
  const [values, setValues] = useState<IFormValues>({});
  // const [canAddNew, setCanAddNew] = useState<boolean>(true);
  const [addLoading, setAddLoading] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [isCreatedMode, setIsCreatedMode] = useState<boolean>(false);
  const onFinish = async (formValues: IShipInsuranceValues) => {
    if (selectedTag === undefined) {
      message.error("Please select a tag");
      return;
    }

    try {
      if (!tableCurrentValue) return;
      setAddLoading(true);
      const service = new ShipService();

      const reqBody: IShipInsuranceInformation = {
        comment: formValues.comment,
        currencyId: formValues.currencyId,
        insuranceCompany: formValues.insuranceCompany,
        price: formValues.price,
        tagName:
          selectedTag !== undefined && selectedTag >= 0
            ? tags[selectedTag]
            : "",
        startDate:
          formValues.insuranceDate?.length > 0
            ? formValues.insuranceDate[0]?.format("YYYY-MM-DD")
            : null,
        endDate:
          formValues.insuranceDate?.length >= 1
            ? formValues.insuranceDate[1]?.format("YYYY-MM-DD")
            : null,
      };

      const response = editMode
        ? await service.EditShipInsuranceInformation(
            values[tags[selectedTag]].id!,
            reqBody
          )
        : await service.ShipInsuranceInformation(tableCurrentValue.id, reqBody);
      if (response && response.status === 200) {
        message.success(
          "insurance information successfully".concat(
            editMode ? " edited." : " created."
          )
        );
        setIsCreatedMode(false);
        setEditMode(false);
        if (tableCurrentValue) {
          getInsurances(tableCurrentValue.id);
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      setAddLoading(false);
    }
  };
  const getInsurances = async (id: number) => {
    try {
      setLoading(true);
      const { GetShipInsuranceInformation } = new ShipService();
      const response = await GetShipInsuranceInformation(id);
      if (response) {
        setTags(response.data.map((tag) => tag.tagName));

        if (response.data.length > 0) {
          const obj: IObject = {};
          let firstTag = "";
          response.data.forEach((value, index) => {
            const val: IValues = {
              insuranceDate: [dayjs(value.startDate), dayjs(value.endDate)],
              comment: value.comment,
              currencyId: value.currencyId,
              id: value.shipInsuranceId,
              insuranceCompany: value.insuranceCompany,
              price: value.price,
              tagName: value.tagName,
            };
            obj[value.tagName] = val;
            if (index === 0) {
              firstTag = value.tagName;
            }
          });
          setValues(obj);
          setSelectedTag(0);
          InsurancesForm?.setFieldsValue(obj[firstTag]);
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  const onDeleteInsurance = async (id: number) => {
    try {
      const { DeleteShipInsuranceInformation } = new ShipService();
      const result = await DeleteShipInsuranceInformation(id);
      if (result && result.status === 200) {
        message.success("Successfully deleted insurance information");
        if (tableCurrentValue) {
          getInsurances(tableCurrentValue.id);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const onChangeTag = (selected: number) => {
    if (isCreatedMode) {
      setShowConfirmModal(true);
      return false;
    } else {
      if (editMode) {
        message.warning("Please save or cancel the selected edit mode");
        return false;
      }
      InsurancesForm?.setFieldsValue(values[tags[selected]]);
      setEditMode(false);
      setIsCreatedMode(false);
      return true;
    }
  };
  const onConformModal = () => {
    setEditMode(false);
    setIsCreatedMode(false);
    setShowConfirmModal(false);
  };
  const beforeShowInputTag = () => {
    if (isCreatedMode) {
      setShowConfirmModal(true);
      return false;
    } else {
      if (editMode) {
        message.warning("Please save or cancel the selected edit mode");
        return false;
      }
      return true;
    }
  };
  const cancelEditMode = () => {
    if (selectedTag !== undefined) {
      InsurancesForm?.setFieldsValue(values[tags[selectedTag]]);
    }
    setEditMode(false);
    setIsCreatedMode(false);
  };
  useEffect(() => {
    fetchAllCurrency();
  }, [fetchAllCurrency]);
  useEffect(() => {
    if (tableCurrentValue) {
      getInsurances(tableCurrentValue.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableCurrentValue]);
  return (
    <Skeleton loading={loading} className="min-h-[820px]" active={loading}>
      <Form
        form={InsurancesForm}
        onFinish={onFinish}
        className="flex flex-col justify-between"
        disabled={!editMode && !isCreatedMode}
      >
        <InsurancesContainer className="flex gap-[24px]  content-start">
          <Col span={24}>
            <div className="titleHolder pt-[16px]">
              Please insert ship owner’s information.
            </div>
          </Col>
          <Col
            span={24}
            className="pt-[12px] pb-[24px] borderBottom overflow-x-auto"
          >
            <STag
              selected={selectedTag}
              setSelected={setSelectedTag}
              beforeShowInput={beforeShowInputTag}
              tags={tags}
              setTags={setTags}
              onChangeTag={onChangeTag}
              placeholder="Add new insurance"
              onDelete={() =>
                selectedTag !== undefined
                  ? onDeleteInsurance(values[tags[selectedTag]].id!)
                  : null
              }
              onConfirm={() => {
                // setCanAddNew(false);
                setIsCreatedMode(true);
                InsurancesForm?.resetFields();
              }}
              // disabled={!canAddNew}
            />
          </Col>
          {tags.length > 0 ? (
            <Col span={24}>
              <Row gutter={[24, 12]}>
                <Col span={8}>
                  <FormItem
                    name={"insuranceCompany"}
                    label="Insurance company "
                    rules={[{ required: true }]}
                    className=" !mb-0"
                  >
                    <SInput placeholder="insert" />
                  </FormItem>
                </Col>
                <Col span={8}>
                  <FormItem
                    name="insuranceDate"
                    label="Start - End Insurance Date"
                    className=""
                    rules={[{ required: true }]}
                  >
                    <RangePicker className="w-full" />
                  </FormItem>
                </Col>
                <Col span={8}>
                  <FormItem
                    name={"price"}
                    label="Insurance price"
                    rules={[{ required: true }]}
                    className=" !mb-0"
                  >
                    <SInput placeholder="insert" numbermode />
                  </FormItem>
                </Col>
                <Col span={8}>
                  <FormItem
                    name={"currencyId"}
                    label="Currency"
                    rules={[{ required: true }]}
                    className=" !mb-0"
                  >
                    <Select
                      // style={{ width: "100%" }}
                      placeholder="select"
                      optionLabelProp="label"
                      showSearch
                      className="selectOption "
                      loading={currencyLoading}
                      dropdownRender={(menu) => (
                        <>
                          <Space style={{ padding: "16px 8px 4px" }}>
                            <div
                              onClick={() => setIsAddCurrencyModalOpen(true)}
                              className="cursor-pointer flex justify-center items-center"
                            >
                              <span className="material-icons text-[16px] text-[#001377]">
                                add
                              </span>
                              <div className="addHolder">Add Currency</div>
                            </div>
                          </Space>
                          <Divider style={{ margin: "8px 0" }} />
                          {menu}
                        </>
                      )}
                      options={allCurrency.map((currency) => ({
                        label: currency.shortName,
                        value: currency.id,
                      }))}
                    />
                  </FormItem>
                </Col>
                <Col span={24}>
                  <FormItem name="comment" label="Description">
                    <TextArea
                      placeholder="Type a description"
                      rows={4}
                      style={{
                        height: 150,
                        resize: "none",
                        borderRadius: 12,
                        background: "#F4F7FE",
                      }}
                    />
                  </FormItem>
                </Col>
                <Col span={24}>
                  {editMode && (
                    <Button
                      type={"default"}
                      className={"w-[100px] mt-[12px] me-[24px]"}
                      onClick={cancelEditMode}
                      disabled={false}
                    >
                      Cancel
                    </Button>
                  )}
                  <Button
                    type={!isCreatedMode && !editMode ? "text" : "primary"}
                    className={"w-[100px] mt-[12px]".concat(
                      !isCreatedMode && !editMode ? " enableEditMode" : ""
                    )}
                    onClick={() => {
                      if (isCreatedMode || editMode) {
                        InsurancesForm?.submit();
                        return;
                      } else {
                        setEditMode(true);
                      }
                    }}
                    loading={addLoading}
                    disabled={false}
                  >
                    {isCreatedMode ? "Add" : editMode ? "Save Edit" : "Edit"}
                  </Button>
                </Col>
              </Row>
            </Col>
          ) : (
            <Flex className="w-full" justify="center" align="center">
              Please create a new tag
            </Flex>
          )}
        </InsurancesContainer>
        <Row>
          <Col span={24}>
            <div className="flex justify-end footer mt-[32px]">
              {/* <div>
              <Button
                onClick={() => deleteShip(shipId)}
                className="w-[148px] flex justify-center items-center"
              >
                Discard new ship
              </Button>
            </div> */}
              <div className="flex gap-[16px] ">
                <SPopconfirm
                  description="Are you sure you want to reset all the information you filled out?"
                  onConfirm={() => {
                    InsurancesForm?.resetFields();
                  }}
                >
                  <Button htmlType="button" className="w-[100px]">
                    Reset
                  </Button>
                </SPopconfirm>

                <Button
                  type="primary"
                  className="w-[100px]"
                  onClick={nextTab}
                  disabled={!submittable || editMode}
                >
                  Finish
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Form>
      <ConfirmModal
        text="Do you want to save changes?"
        open={showConfirmModal}
        onCancel={() => setShowConfirmModal(false)}
        onOk={onConformModal}
      />
    </Skeleton>
  );
};
