import { IPath } from "../Identity/models";

export const DailyReportGuardPath: IPath[] = [
  {
    path: "api/DailyReport/NewDailyReport",
    httpMethod: "POST",
  },
  {
    path: "api/DailyReport/{DailyReportID}/ConfirmDailyReport",
    httpMethod: "PUT",
  },
  {
    path: "api/DailyReport/{DailyReportID}/RejectDailyReport",
    httpMethod: "PUT",
  },
  {
    path: "api/DailyReport/ShipDailyReport",
    httpMethod: "POST",
  },
  {
    path: "api/DailyReport/AllShipDailyReport",
    httpMethod: "GET",
  },
  {
    path: "api/DailyReport/{DailyReportID}/EditDailyReport",
    httpMethod: "PUT",
  },
  {
    path: "api/DailyReport/GetOnAction",
    httpMethod: "GET",
  },
  {
    path: "api/DailyReport/{ShipId}/UnreadDate",
    httpMethod: "GET",
  },
  {
    path: "api/DailyReport/{DailyReportID}/DeleteDailyReport",
    httpMethod: "DELETE",
  },
];

export const NewDailyReportPath: IPath = {
  path: "api/DailyReport/NewDailyReport",
  httpMethod: "POST",
};
export const ConfirmDailyReportPath: IPath = {
  path: "api/DailyReport/{DailyReportID}/ConfirmDailyReport",
  httpMethod: "PUT",
};
export const RejectDailyReportPath: IPath = {
  path: "api/DailyReport/{DailyReportID}/RejectDailyReport",
  httpMethod: "PUT",
};
export const ShipDailyReportPath: IPath = {
  path: "api/DailyReport/ShipDailyReport",
  httpMethod: "POST",
};
export const AllShipDailyReportPath: IPath = {
  path: "api/DailyReport/AllShipDailyReport",
  httpMethod: "GET",
};
export const EditDailyReportPath: IPath = {
  path: "api/DailyReport/{DailyReportID}/EditDailyReport",
  httpMethod: "PUT",
};
export const GetOnActionPath: IPath = {
  path: "api/DailyReport/GetOnAction",
  httpMethod: "GET",
};
export const UnreadDatePath: IPath = {
  path: "api/DailyReport/{ShipId}/UnreadDate",
  httpMethod: "GET",
};
export const DeleteDailyReportPath: IPath = {
  path: "api/DailyReport/{DailyReportID}/DeleteDailyReport",
  httpMethod: "DELETE",
};
