import { FC } from "react";
import { ReportComponent } from "../ReportComponent";
import useOwnerStore from "src/store/owner";
import { useGetDailyReport } from "../../hook";

export const OwnerFuturePlan: FC = () => {
  const { dailyReport } = useOwnerStore();
  useGetDailyReport();
  return (
    <ReportComponent title="Future plan" content={dailyReport?.futurePlan} />
  );
};
