import { FC, useEffect, useState } from "react";
import { AddNewSupplierContainer } from "../styles";
import { Col, Row, Select, Button, Form, App, Space, Divider } from "antd";
import { FormItem } from "src/components/UiKit/FormItem";
import { SInput } from "src/components/UiKit/Input";
import TextArea from "antd/es/input/TextArea";
import { useSupplier } from "../context";
import { INewSupplier } from "src/services/System/models";
import { SystemService } from "src/services/System/System.service";
import { SPopconfirm } from "src/components/UiKit/PopConfirm";
import { useAddInvoices } from "src/modules/addInvoice/context";
import { useEditInvoice } from "src/modules/EditInvoice/context";
import { AddCountryModal } from "src/components/UiKit/AddCountryModal/AddCountryModal";

export const AddNewSupplier: FC = () => {
  const {
    value: { countryList },
    func: { fetchCountry, getSupplierTableData },
  } = useSupplier()!;
  const [submittable, setSubmittable] = useState<boolean>(false);
  const [formController] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [isAddCountryModalOpen, setIsAddCountryModalOpen] =
    useState<boolean>(false);
  const values = Form.useWatch([], formController);
  const {
    func: { fetchSupplier },
  } = useAddInvoices()!;
  const {
    func: { fetchSupplierEitInvoice },
  } = useEditInvoice()!;
  useEffect(() => {
    formController.validateFields({ validateOnly: true }).then(
      () => {
        setSubmittable(true);
      },
      () => {
        setSubmittable(false);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);
  const { message } = App.useApp();

  const onFinish = async (values: INewSupplier) => {
    try {
      setLoading(true);
      const { NewSupplier } = new SystemService();
      const response = await NewSupplier(values);
      if (response && response.status === 200) {
        message.success("created successfully supplier");
        fetchSupplier();
        fetchSupplierEitInvoice();
        formController.resetFields();
        getSupplierTableData();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchCountry();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Form onFinish={onFinish} form={formController}>
      <AddNewSupplierContainer>
        <Row gutter={24}>
          <Col span={24} className="titleHolder pt-[16px]">
            Please insert Supplier information.
          </Col>

          <Col span={8} className="pt-[40px]">
            <FormItem
              name="name"
              label="Supplier name"
              rules={[{ required: true }]}
              required={true}
              className="w-full"
            >
              <SInput placeholder="insert" />
            </FormItem>
          </Col>
          <Col span={8} className="pt-[40px]">
            <FormItem name="webSite" label="Website" className="w-full">
              <SInput placeholder="e.g. website.com" />
            </FormItem>
          </Col>
          <Col span={8} className="pt-[40px]">
            <FormItem
              name="phoneNumber"
              label="Phone number"
              required={false}
              className="w-full"
            >
              <SInput placeholder="e.g. +971-4-2965595" />
            </FormItem>
          </Col>
          <Col span={8}>
            <FormItem
              name="email"
              label="Email"
              required={false}
              className="w-full"
            >
              <SInput placeholder="e.g. website.com" />
            </FormItem>
          </Col>
          <Col span={8}>
            <FormItem
              name="countryId"
              label="Country"
              required={false}
              className="w-full"
            >
              <Select
                style={{ width: "100%" }}
                placeholder="select"
                optionLabelProp="label"
                showSearch
                optionFilterProp="label"
                className="selectOption"
                options={countryList?.map((country) => ({
                  label: country.name,
                  value: country.id,
                }))}
                dropdownRender={(menu) => (
                  <>
                    <Space style={{ padding: "16px 8px 4px" }}>
                      <div
                        onClick={() => setIsAddCountryModalOpen(true)}
                        className="cursor-pointer flex justify-center items-center"
                      >
                        <span className="material-icons text-[16px] text-[#001377]">
                          add
                        </span>
                        <div className="addHolder"> Add Country</div>
                      </div>
                    </Space>
                    <Divider style={{ margin: "8px 0" }} />
                    {menu}
                  </>
                )}
              />
            </FormItem>
          </Col>
          <Col span={8}>
            <FormItem
              name="city"
              label="City"
              required={false}
              className="w-full"
            >
              <SInput placeholder="Insert" />
            </FormItem>
          </Col>
          <Col span={24}>
            <FormItem
              name="address"
              label="Address"
              required={false}
              className="w-full"
            >
              <SInput placeholder="Insert" />
            </FormItem>
          </Col>
          <Col span={24}>
            <FormItem name="comment" label="Description">
              <TextArea
                placeholder="Description"
                rows={4}
                style={{
                  height: 163,
                  resize: "none",
                  borderRadius: 12,
                  background: "#F4F7FE",
                }}
              />
            </FormItem>
          </Col>
          <Col span={24}>
            <div className=" flex gap-[16px] footer">
              <Button
                type="primary"
                className="w-[100px]"
                htmlType="submit"
                disabled={!submittable}
                loading={loading}
              >
                Finish
              </Button>
              <SPopconfirm
                description="Are you sure you want to reset all the information you filled out?"
                onConfirm={() => {
                  formController?.resetFields();
                }}
              >
                <Button className="w-[100px]">Reset</Button>
              </SPopconfirm>
            </div>
          </Col>
        </Row>
      </AddNewSupplierContainer>
      <AddCountryModal
        getCountry={fetchCountry}
        isAddCountryModalOpen={isAddCountryModalOpen}
        setIsAddCountryModalOpen={setIsAddCountryModalOpen}
      />
    </Form>
  );
};
