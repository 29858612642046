import { FC, useEffect, useState } from "react";
import { Button, Divider, Flex, Form, Modal } from "antd";
import { IObject } from "src/models/interfaces";
import { IModalProps } from "./interface";
import { useDocumentation } from "../../context";
import { TextAreaUiKit } from "src/components/UiKit/TextArea";

interface IEditDescriptionModalProps extends IModalProps {
  type?: "folder" | "file";
}

const EditDescriptionModal: FC<IEditDescriptionModalProps> = ({
  open,
  onCancel,
}) => {
  const [loading, setLoading] = useState<boolean>(false);

  const [editDocForm] = Form.useForm();

  const {
    value: { selectedDoc },
    func: { editDocumentReq },
  } = useDocumentation();

  useEffect(() => {
    editDocForm.setFieldsValue({ comment: selectedDoc?.comment });
  }, [editDocForm, selectedDoc]);

  const Title = () => {
    return (
      <>
        <>
          <Flex vertical>
            <Flex align="center">
              <span className="material-icons text-[#5D6881] text-[14px]">
                message
              </span>
              <span className="text-[#001377] text-[18px] font-medium ml-2">
                Description
              </span>
            </Flex>

            <Flex align="center">
              {selectedDoc?.isDirectory ? (
                <span className="material-icons text-[14px]">folder</span>
              ) : (
                <span className="material-icons text-[#878787] text-[14px]">
                  article
                </span>
              )}

              <span className="text-[#5D6881] text-[12px] font-normal ml-2">
                {selectedDoc?.name.concat(
                  selectedDoc?.isDirectory ? "" : selectedDoc?.extention
                )}
              </span>
            </Flex>
          </Flex>
          <Divider className="p-0" />
        </>
      </>
    );
  };

  const handleFinishCreateFolder = (values: IObject) => {
    setLoading(true);
    values.name = selectedDoc?.name;
    editDocumentReq(values)
      .then((res) => {
        if (res) {
          onCancel();
        }
      })
      .finally(() => setLoading(false));
  };
  return (
    <Modal
      open={open}
      closable={false}
      onCancel={onCancel}
      title={<Title />}
      footer={false}
    >
      <Form
        form={editDocForm}
        onFinish={handleFinishCreateFolder}
        className="w-full flex flex-col justify-center items-center"
      >
        <div className="w-full mt-6 flex flex-col justify-center items-center p-[16px] border border-solid border-[#DADEEC] rounded-xl">
          <Form.Item name="comment" className="w-full">
            <TextAreaUiKit rows={10} placeholder="Description"></TextAreaUiKit>
          </Form.Item>
        </div>

        <div className="mt-4 flex items-center justify-center">
          <Button
            className="mr-3 bg-[#F5F5F5] hover:!border-[#F5F5F5] text-[#4B4B4B] hover:!text-[#4B4B4B]"
            onClick={(event) => {
              event.stopPropagation();
              onCancel();
            }}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            className="text-[#fff] bg-[#2C73FC] hover:!text-[#fff] hover:!bg-[#2C73FC] border-[#2C73FC]"
          >
            Save Edit
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default EditDescriptionModal;
