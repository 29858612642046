import { FC } from "react";
import { ContentContainer } from "../style";
import { Button, Col, Flex, Form, Row, Select } from "antd";
import { FormItem } from "src/components/UiKit/FormItem";
import { SInput } from "src/components/UiKit/Input";
import TextArea from "antd/es/input/TextArea";

export const AddNewCargoType: FC = () => {
  return (
    <Form>
      <Row justify="center">
        <Col span={15}>
          <ContentContainer>
            <Col span={24} className="text-[16px] font-medium text-[#001377]">
              Please complete the requested information.
            </Col>
            <Col span={24} className="py-[16px]">
              <div className="">
                <Row gutter={24}>
                  <Col span={12}>
                    <FormItem
                      name={"name"}
                      label="Name"
                      rules={[{ required: true }]}
                      required={true}
                    >
                      <SInput placeholder="insert" allowClear />
                    </FormItem>
                  </Col>
                  <Col span={12}>
                    <FormItem
                      name={"cargoGroup"}
                      label="Cargo group"
                      rules={[{ required: true }]}
                      required={true}
                    >
                      <Select
                        style={{ width: "100%" }}
                        placeholder="select"
                        optionLabelProp="label"
                        showSearch
                        allowClear
                        optionFilterProp="label"
                        className="selectOption"
                        options={[
                          { value: "test1", label: "test1" },
                          { value: "test2", label: "test2" },
                        ]}
                      />
                    </FormItem>
                  </Col>
                  <Col span={24}>
                    <FormItem name={"description"} label="Description">
                      <TextArea
                        placeholder="Description"
                        rows={7.5}
                        autoSize={{ minRows: 7.5, maxRows: 7.5 }}
                        allowClear
                        className="p-[16px] resize-none rounded-[12px] bg-[#F4F7FE]"
                      />
                    </FormItem>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col span={24} className="button">
              <Flex justify="end" gap={16} align="center">
                <Button>Reset</Button>
                <Button
                  type="primary"
                  //   loading={addCountryLoading}
                  htmlType="submit"
                  //   disabled={!submit}
                >
                  Add
                </Button>
              </Flex>
            </Col>
          </ContentContainer>
        </Col>
      </Row>
    </Form>
  );
};
