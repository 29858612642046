export enum IAgentsTabs {
  addNewAgent = "1",
  agentsTable = "2",
}

export enum IAddNewAgentTabs {
  basicInformation = "1",
  contactInformation = "2",
}

export interface IAgentsPhoneNumber {
  id: number;
  phoneNumber: string;
}

export interface IAgentsEmail {
  id: number;
  email: string;
}

export interface IAgentsTable {
  id: number;
  name: string;
  company: string;
  country: string;
  port: string;
  phoneNumber: IAgentsPhoneNumber[];
  email: IAgentsEmail[];
  services: string;
}

export interface IContacts {
  email: boolean;
  phone: boolean;
}
