import { FC, useMemo } from "react";

import { CardStyle, CardWrapper } from "./style";
import { Flex, Form } from "antd";
import {
  AddNewProfitStatusTypeEnum,
  AddNewProfitStepEnum,
} from "src/modules/ProfitReport/models";
import { useProfitReport } from "src/modules/ProfitReport/context";
import { useTableProfit } from "../../../ProftTable/context";
import { useNewProfitReport } from "../../context";
import moment from "moment";
import { ProfitCharterType } from "src/services/profit/models";
import { Guard } from "src/components/Guard";
import {
  NewProfit,
  EditProfit,
  NewProfitTrade,
  NewProfitShipping,
} from "src/services/profit/guardPath";

export const AddInfoCards: FC = () => {
  const {
    value: { status },
    dispatch: { setStatus, setStep, setEditMode },
    form: { basicInfoForm },
  } = useProfitReport();

  const {
    dispatch: { setEditId },
    func: { getDataViewProfit },
    value: { editData },
  } = useTableProfit();

  const {
    values: { newBasicInfoId },
    dispatches: { setShowFinishButtonBaseInfo },
  } = useNewProfitReport();

  const charterType = Form.useWatch("charterType", basicInfoForm);

  const cards = useMemo(() => {
    const emptyDes = "No information has been entered yet.";
    const loadingDes = "Data registration is in progress.";
    const createdDate = moment().format("YYYY-MM-DD");
    const baseInfoCard = {
      guard: NewProfit,
      EditProfit,
      title: "Basic information",
      description:
        status.basic === AddNewProfitStatusTypeEnum.empty
          ? emptyDes
          : status.basic === AddNewProfitStatusTypeEnum.loading
          ? loadingDes
          : `was recorded on ${
              editData && editData.profitCreated
                ? moment(editData.profitCreated).format("YYYY-MM-DD")
                : createdDate
            }`,
      status: status.basic,
      step: AddNewProfitStepEnum.basic,
      disabled:
        status.shipping === AddNewProfitStatusTypeEnum.editing ||
        status.shipping === AddNewProfitStatusTypeEnum.loading ||
        status.trade === AddNewProfitStatusTypeEnum.loading ||
        status.trade === AddNewProfitStatusTypeEnum.editing,
      onEdit: () => {
        setEditId(newBasicInfoId);
        setStep("basic");
        setEditMode(true);
        setShowFinishButtonBaseInfo(true);
        setStatus((prev) => ({
          ...prev,
          basic: AddNewProfitStatusTypeEnum.editing,
        }));
        if (newBasicInfoId) getDataViewProfit(newBasicInfoId);
      },
      onAdd: () => {
        setStatus((prev) => ({
          ...prev,
          basic: AddNewProfitStatusTypeEnum.loading,
        }));
        setStep(AddNewProfitStepEnum.basic);
      },
    };
    const tradeCard = {
      guard: NewProfitTrade,
      title: "Trade information",
      description:
        status.trade === AddNewProfitStatusTypeEnum.empty
          ? emptyDes
          : status.trade === AddNewProfitStatusTypeEnum.loading
          ? loadingDes
          : `was recorded on ${
              editData && editData.profitTradeCreated
                ? moment(editData.profitTradeCreated).format("YYYY-MM-DD")
                : createdDate
            }`,
      status: status.trade,
      step: AddNewProfitStepEnum.trade,
      disabled:
        status.basic !== AddNewProfitStatusTypeEnum.done ||
        status.shipping === AddNewProfitStatusTypeEnum.editing ||
        status.shipping === AddNewProfitStatusTypeEnum.loading,
      onEdit: () => {
        setEditId(newBasicInfoId);
        setStep("trade");
        setEditMode(true);
        setShowFinishButtonBaseInfo(true);
        setStatus((prev) => ({
          ...prev,
          trade: AddNewProfitStatusTypeEnum.editing,
        }));
        if (newBasicInfoId) getDataViewProfit(newBasicInfoId);
      },
      onAdd: () => {
        setStatus((prev) => ({
          ...prev,
          trade: AddNewProfitStatusTypeEnum.loading,
        }));
        setStep(AddNewProfitStepEnum.trade);
      },
    };
    const shippingCard = {
      guard: NewProfitShipping,
      title: "Shipping information",
      description:
        status.shipping === AddNewProfitStatusTypeEnum.empty
          ? emptyDes
          : status.shipping === AddNewProfitStatusTypeEnum.loading
          ? loadingDes
          : `was recorded on ${
              editData && editData.profitShippingCreated
                ? moment(editData.profitShippingCreated).format("YYYY-MM-DD")
                : createdDate
            }`,
      status: status.shipping,
      step: AddNewProfitStepEnum.shipping,
      disabled:
        status.basic !== AddNewProfitStatusTypeEnum.done ||
        status.trade === AddNewProfitStatusTypeEnum.editing ||
        status.trade === AddNewProfitStatusTypeEnum.loading,
      onEdit: () => {
        setEditId(newBasicInfoId);
        setStep("shipping");
        setEditMode(true);
        setShowFinishButtonBaseInfo(true);
        setStatus((prev) => ({
          ...prev,
          shipping: AddNewProfitStatusTypeEnum.editing,
        }));
        if (newBasicInfoId) getDataViewProfit(newBasicInfoId);
      },
      onAdd: () => {
        setStatus((prev) => ({
          ...prev,
          shipping: AddNewProfitStatusTypeEnum.loading,
        }));
        setStep(AddNewProfitStepEnum.shipping);
      },
    };
    if (
      charterType === ProfitCharterType.TradeDepartman_TCH ||
      charterType === ProfitCharterType.TradeDepartman_VCH ||
      editData?.charterType === ProfitCharterType.TradeDepartman_TCH ||
      editData?.charterType === ProfitCharterType.TradeDepartman_VCH
    ) {
      return [baseInfoCard, tradeCard, shippingCard];
    } else return [baseInfoCard, shippingCard];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, charterType, editData]);
  return (
    <CardWrapper gap={24}>
      {cards.map((card, i) => {
        return (
          <Guard key={i} action={[card.guard]} multiPath>
            <CardStyle
              $disabled={card.disabled}
              $active={
                card.status === AddNewProfitStatusTypeEnum.loading ||
                card.status === AddNewProfitStatusTypeEnum.editing
              }
            >
              <div className="title">{card.title}</div>
              <div className="description">{card.description}</div>
              {card.status === AddNewProfitStatusTypeEnum.empty ? (
                <div
                  className="textButton"
                  onClick={() => !card.disabled && card.onAdd()}
                >
                  Add
                </div>
              ) : card.status === AddNewProfitStatusTypeEnum.loading ? (
                <div className="Adding">Adding</div>
              ) : card.status === AddNewProfitStatusTypeEnum.editing ? (
                <div className="Adding">Editing</div>
              ) : (
                <Flex gap={12} align="center">
                  <div
                    className="textButton "
                    onClick={() => !card.disabled && card.onEdit()}
                  >
                    Edit
                  </div>
                </Flex>
              )}
            </CardStyle>
          </Guard>
        );
      })}
    </CardWrapper>
  );
};
