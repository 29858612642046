import { FC } from "react";
import { IPort } from "src/services/BaseInfo/models";
import DeleteConfirmModal from "./DeleteConfirmModal";
import { usePorts } from "../../context";
import { Guard } from "src/components/Guard";
import {
  DeletePortPath,
  UpdatePortPath,
} from "src/services/BaseInfo/guardPath";

interface ITableActionsProps {
  item: IPort;
}

const TableActions: FC<ITableActionsProps> = ({ item }) => {
  const {
    func: { onEditView, deletePort },
    dispatch: { setShowConfirm },
  } = usePorts();

  return (
    <>
      <div className="flex gap-[16px] px-[24px] py-[16px] justify-center items-center">
        <Guard action={UpdatePortPath}>
          {" "}
          <span
            className="material-icons text-[#01C18F] text-[16px] cursor-pointer "
            onClick={() => onEditView("edit", item)}
          >
            edit_note
          </span>
        </Guard>
        <span
          className="material-icons text-[#2C73FC] text-[16px] cursor-pointer"
          onClick={() => {
            onEditView("view", item);
          }}
        >
          remove_red_eye
        </span>
        <Guard action={DeletePortPath}>
          <span
            className="material-icons text-[#FF1414] text-[16px] cursor-pointer"
            onClick={() => setShowConfirm(true)}
          >
            delete_forever
          </span>
        </Guard>
      </div>
      <DeleteConfirmModal onOk={() => deletePort(item.id)} />
    </>
  );
};

export default TableActions;
