import { create } from "zustand";
import { USER_JWT_TOKEN } from "src/constants";
import { IPath } from "src/services/Identity/models";

interface IStore {
  token?: string;
  userRole?: string[];
  accessPath: IPath[];
  setAccessPath: (accessPath: IPath[]) => void;
  login: (token: string) => void;
  setUserRole: (roles: string[]) => void;
  logout: () => void;
}

const useAuthStore = create<IStore>((set) => ({
  token: localStorage.getItem(USER_JWT_TOKEN) || undefined,
  accessPath: [],
  setAccessPath: (accessPath: IPath[]) => set(() => ({ accessPath })),
  login: (token) => set(() => ({ token })),
  logout: () => set({ token: undefined }),
  setUserRole: (roles) => set(() => ({ userRole: roles })),
}));

export default useAuthStore;
