import { FC, useEffect } from "react";
import { ColumnsType } from "antd/es/table";
import { TableUI } from "src/components/UiKit/table";
import { FrontAccessTableContainer } from "./style";
import { useFrontAccess } from "../../context";
import { useChangeParams } from "src/hook/useParamsChange";
import { IFrontPath } from "src/services/System/models";
import { FrontPathType } from "../../models";
import { useSearchParams } from "react-router-dom";
import { Tools } from "./TableTools";

export const FrontAccessTable: FC = () => {
  const {
    value: { pagination, frontPath, tableLoading },
    dispatch: { setPagination },
    func: { getFrontPath },
  } = useFrontAccess()!;

  let [searchParams, setSearchParams] = useSearchParams();
  const onChangeParams = useChangeParams();

  const columns: ColumnsType<IFrontPath> = [
    {
      title: " #",
      dataIndex: "#",
      key: "index",
      width: 38,
      align: "center",
      render: (name: string, record: any, index: number) => index + 1,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 88,
      align: "center",
    },
    {
      title: "Display name",
      dataIndex: "displayName",
      key: "displayName",
      width: 150,
      align: "center",
      className: "!px-2",
    },
    {
      title: "Path type",
      dataIndex: "pathType",
      key: "pathType",
      width: 100,
      align: "center",
      className: "!px-2",
      render: (value) => (value === FrontPathType.Page ? "Page" : "Modal"),
    },
    {
      title: "Tools",
      dataIndex: "tools",
      key: "tools",
      width: 160,
      align: "center",
      render: (_, record) => <Tools path={record} />,
    },
  ];

  useEffect(() => {
    if (!searchParams.size) {
      setSearchParams({
        Limit: pagination.pageSize.toString(),
        Offset: pagination.current.toString(),
      });
    } else {
      const Limit = searchParams.get("Limit");
      const Offset = searchParams.get("Offset");
      setPagination((prev) => ({
        ...prev,
        pageSize: Limit ? +Limit : 10,
        current: Offset ? +Offset : 1,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams.size]);
  useEffect(() => {
    if (searchParams.size) {
      getFrontPath();
    }
  }, [getFrontPath, searchParams.size]);

  return (
    <FrontAccessTableContainer>
      <TableUI
        columns={columns}
        tableType="primary"
        dataSource={frontPath}
        rowClassName={(record, index) =>
          index % 2 !== 0 ? "bg-[#F2F7FF]" : ""
        }
        pagination={{
          position: ["bottomCenter"],
          showSizeChanger: true,
          total: pagination.total,
          current: pagination.current,
          onChange: (page: number, pageSize: number) => {
            setPagination((prev) => ({ ...prev, current: page }));
            onChangeParams("Limit", pageSize.toString());
            onChangeParams("Offset", page.toString());
          },
          onShowSizeChange: (current: number, size: number) => {
            onChangeParams("Limit", size.toString());
            onChangeParams("Offset", current.toString());
            setPagination((prev) => ({ ...prev, current }));
          },
        }}
        scroll={{ x: 800 }}
        loading={tableLoading}
      />
    </FrontAccessTableContainer>
  );
};
