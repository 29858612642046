import { Button, Col, Form, Row, Select } from "antd";
import { FC, useEffect, useState } from "react";
import { TechnicalInformationContainer } from "../styles";
import { FormItem } from "src/components/UiKit/FormItem";
import { SInput } from "src/components/UiKit/Input";
import { SDatePicker } from "src/components/UiKit/DatePicker";
import { useAddShip } from "src/modules/AddShips/context";
import { IAddNewShip } from "src/modules/AddShips/models";
import { SPopconfirm } from "./popconfirm";
import {
  CargoType,
  IShipTechnicalInformation,
  SizeType,
} from "src/services/Ship/models";
import { ShipService } from "src/services/Ship/Ship.service";
import { Dayjs } from "dayjs";
export const TechnicalInformation: FC = () => {
  const {
    value: { shipId },
    dispatch: { setActiveKeyAddShip },
    forms: { TechnicalInformationForm },
    func: { deleteShip },
  } = useAddShip()!;
  const [submittable, setSubmittable] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const values = Form.useWatch([], TechnicalInformationForm);

  useEffect(() => {
    TechnicalInformationForm?.validateFields({ validateOnly: true }).then(
      () => {
        setSubmittable(true);
      },
      () => {
        setSubmittable(false);
      }
    );
  }, [values, TechnicalInformationForm]);
  const onCancel = () => {};

  const nextTab = () => {
    setActiveKeyAddShip(IAddNewShip.Owner);
  };

  const onFinish = async (
    values: IShipTechnicalInformation & { yearBuilt: Dayjs }
  ) => {
    setLoading(true);
    const service = new ShipService();
    const reqBody: IShipTechnicalInformation = {
      beam: values.beam,
      builder: values.builder,
      callSign: values.callSign,
      cargoType: values.cargoType,
      draft: values.draft,
      dwt: values.dwt,
      grt: values.grt,
      imo: values.imo,
      loa: values.loa,
      mmsi: values.mmsi,
      sizeType: values.sizeType,
      yearBuilt: (values.yearBuilt as Dayjs).year(),
    };
    try {
      const response = await service.ShipTechnicalInformation(shipId, reqBody);
      if (response && response.status === 200) {
        TechnicalInformationForm?.resetFields();
        nextTab();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Form form={TechnicalInformationForm} onFinish={onFinish}>
      <div className="flex flex-col h-[620px] justify-between">
        <TechnicalInformationContainer className="flex gap-[24px]">
          <Col span={24}>
            <div className="titleHolder pt-[16px]">
              Please insert Technical information.
            </div>
          </Col>
          <Col span={24} className="flex gap-[24px] pt-[16px]">
            <FormItem
              name={"builder"}
              label="Builder"
              className="w-[33%] !mb-0"
            >
              <SInput placeholder="insert" />
            </FormItem>
            <FormItem
              name={"yearBuilt"}
              label="Year Built"
              className="w-[33%] !mb-0"
            >
              <SDatePicker
                className="w-[100%]"
                placeholder="Select ( Just YEAR )"
                picker="year"
              />
            </FormItem>
            <FormItem name={"draft"} label="Draft" className="w-[33%] !mb-0">
              <SInput placeholder="insert" />
            </FormItem>
          </Col>
          <Col span={24} className="flex gap-[24px] items-center">
            <FormItem name={"beam"} label="Beam" className="w-[33%] !mb-0">
              <SInput placeholder="insert" />
            </FormItem>
            <FormItem
              name={"grt"}
              label={
                <div className="flex items-center">
                  GRT
                  <div className="!text-[#5D6881] !text-[12px]">
                    ( Gross Tonnage )
                  </div>
                </div>
              }
              className="w-[33%] !mb-0"
            >
              <SInput placeholder="insert" />
            </FormItem>
            <FormItem
              name={"loa"}
              label={
                <div className="flex items-center">
                  LOA
                  <div className="!text-[#5D6881] !text-[12px]">
                    ( Length Overall )
                  </div>
                </div>
              }
              className="w-[33%] !mb-0"
            >
              <SInput placeholder="insert" />
            </FormItem>
          </Col>
          <Col span={24} className="flex gap-[24px] items-center">
            <FormItem
              name={"dwt"}
              label={
                <div className="flex items-center">
                  DWT
                  <div className="!text-[#5D6881] !text-[12px]">
                    ( Deadweight Tonnage )
                  </div>
                </div>
              }
              className="w-[33%] !mb-0"
            >
              <SInput placeholder="insert" />
            </FormItem>
            <FormItem
              name={"cargoType"}
              label="Cargo Type"
              className="w-[33%] !mb-0"
            >
              <Select
                style={{ width: "100%" }}
                placeholder="select"
                optionLabelProp="label"
                showSearch
                className="selectOption"
                optionFilterProp="label"
                options={CargoType}
                allowClear
              />
            </FormItem>
            <FormItem
              name={"sizeType"}
              label="Size Type"
              className="w-[33%] !mb-0"
            >
              <Select
                style={{ width: "100%" }}
                placeholder="select"
                optionLabelProp="label"
                showSearch
                className="selectOption"
                optionFilterProp="label"
                options={SizeType}
                allowClear
              />
            </FormItem>
          </Col>
          <Col span={24} className="flex gap-[24px] items-center">
            <FormItem name={"imo"} label="IMO ID" className="w-[33%] !mb-0">
              <SInput placeholder="insert" />
            </FormItem>

            <FormItem name={"mmsi"} label="MMSI ID" className="w-[33%] !mb-0">
              <SInput placeholder="insert" />
            </FormItem>
            <FormItem
              name={"callSign"}
              label="Call Sign"
              className="w-[33%]  !mb-0"
            >
              <SInput placeholder="insert" />
            </FormItem>
          </Col>
        </TechnicalInformationContainer>
        <Row>
          <Col span={24}>
            <div className="flex justify-between footer">
              <div>
                <Button
                  onClick={() => deleteShip(shipId)}
                  className="w-[148px] flex justify-center items-center"
                >
                  Discard new ship
                </Button>
              </div>
              <div className="flex gap-[16px]">
                <SPopconfirm
                  description="Are you sure you want to reset all the information you filled out?"
                  onConfirm={() => {
                    TechnicalInformationForm?.resetFields();
                  }}
                >
                  <Button onClick={onCancel} className="w-[100px]">
                    Reset
                  </Button>
                </SPopconfirm>
                <Button
                  type="primary"
                  className="w-[100px]"
                  onClick={TechnicalInformationForm?.submit}
                  disabled={!submittable}
                  loading={loading}
                >
                  Next
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </Form>
  );
};
