import styled from "styled-components";

export const SelectShipStyle = styled.div`
  height: 90px;
  border-bottom: 1px solid var(--strock, #f3f4f5);
  background: var(--0, #fff);
  display: flex;
  padding: 24px 16px;
  align-items: center;
  gap: 12px;
  overflow-x: auto;
  .item {
    white-space: nowrap;
    border-radius: 8px;
    border: 1px solid var(--text-2, #a3aed0);
    display: flex;
    height: 42px;
    padding: 12px 14px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--text-3, #5d6881);
    font-family: DM Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    cursor: pointer;
  }
  .selected {
    border-radius: 8px;
    background: var(--2, #2c73fc);
    color: var(--0, #fff);
  }
`;
