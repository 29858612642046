import { Row } from "antd";
import styled from "styled-components";

export const FiltersContainer = styled(Row)`
  border-radius: 16px;
  background: var(--color-Base-Color-White, #fff);
  margin-bottom: 32px;
  overflow-x: auto;
  .filterIconHolder {
    height: 40px;
    width: 40px;
    border-radius: 8px;
    background: var(--color-Primary-Primary-600, #2c73fc);
    display: flex;
    padding: 8px;
    justify-content: center;
    align-items: center;
    gap: 12px;
  }
`;
