import { Row } from "antd";
import styled from "styled-components";

export const OwnerHeaderComponent = styled(Row)`
  display: flex;
  padding: 16px 24px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  box-shadow: 0px 4px 6px 0px rgba(113, 130, 183, 0.25);
  z-index: 5;
  height: 99px;
  position: relative;
  .avatar {
    /* width: 400px; */
    display: flex;
    gap: 16px;
    justify-content: flex-start;
    align-items: center;
  }
  .avatar-pic {
    border: 3px solid var(--8, #a3aed0);
  }
  .username {
    color: var(--gray-03, #6c7172);
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .userRole {
    color: #2c73fc;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
`;
