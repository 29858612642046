import { FC, Suspense } from "react";
import { SupplierEdit } from "src/modules/addNewSupplier/modules/supplierEdit";
import { PreLoading } from "src/routes/Loading";

export const SupplierEditPage: FC = () => {
  return (
    <Suspense fallback={<PreLoading />}>
      <SupplierEdit />
    </Suspense>
  );
};
