import { Modal, ModalProps } from "antd";
import { FC } from "react";
import { ConfirmContainer } from "./FrontAccessTable/TableTools/style";

export interface IProps extends ModalProps {
  text: string;
  confirmIcon?: string;
}
export const DeleteConfirmModal: FC<IProps> = (props) => {
  const { text } = props;

  return (
    <Modal
      okButtonProps={{
        icon: <span className="material-icons">delete_forever</span>,
        className:
          "flex justify-center items-center gap-[4px] bg-[#FF1414] hover:!bg-[#FF1414]",
      }}
      cancelButtonProps={{
        icon: <span className="material-icons">close</span>,
        className: "flex justify-center items-center gap-[4px]",
      }}
      rootClassName="confirm-modal"
      {...props}
      okText="Remove"
    >
      <ConfirmContainer>
        <div className="content">{text}</div>
      </ConfirmContainer>
    </Modal>
  );
};
