import { Row } from "antd";
import styled from "styled-components";

export const OwnerDashboardContentContainer = styled(Row)`
  background: var(--3, #f2f7ff);
  width: 100vw;
  height: calc(100vh - 72px - 90px);
  z-index: 1;
  padding: 24px;
  .content {
    padding: 16px 16px 0 16px;
    display: flex;
    justify-content: center;
    /* align-items: center; */
  }
  .calendar {
    /* width: 366px;
    height: 366px; */
  }
  .ant-picker-content {
    /* margin-top: 24px; */
  }
  .ant-picker-calendar {
    background-color: #f2f7ff !important;
  }
  .ant-picker-body {
    border-radius: 16px;
    border: 1px solid var(--text-2, #a3aed0);
    display: flex;
    align-items: center;
    padding: 24px !important;
    background-color: #f2f7ff !important;
    flex-direction: column;
  }
  .ant-picker-panel {
    border-radius: 16px;
  }
  .ant-picker-body {
    background-color: #f2f7ff !important;
  }
  .ant-picker-panel {
    border-top: unset !important;
  }
  .ant-picker-content {
    thead {
      color: var(--text-3, #5d6881);
      text-align: center;
      font-family: Plus Jakarta Sans;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      height: 32px;
    }
  }
  .ant-picker-cell-selected {
    .day {
      border: 3px solid #2c73fc;
    }
  }
  .header {
    margin-bottom: 32px;
    .year {
      color: var(--text-2, #a3aed0);
      font-family: Plus Jakarta Sans;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    .month {
      color: var(--text, #001377);
      font-family: Plus Jakarta Sans;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    .material-icons {
      color: var(--text, #001377);
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
  .footer-button {
    color: var(--2, #2c73fc);
    font-family: Plus Jakarta Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    cursor: pointer;
    user-select: none;
  }
  .footer-button-disabled {
    color: var(--shadow, #dadeec);
    font-family: Plus Jakarta Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    cursor: not-allowed;
  }
`;
