import { App, Button, Col } from "antd";
import { Dispatch, FC, SetStateAction, useRef } from "react";
import { useEditInvoice } from "../context";
import { Guard } from "src/components/Guard";
import {
  DeleteArticlePath,
  NewInvoiceArticlePath,
} from "src/services/Invoice/guardPath";

interface IEditFooterProps {
  onCancel: () => void;
  setShowAddAndEditRow: Dispatch<SetStateAction<boolean>>;
  setIsReadyToDelete: Dispatch<SetStateAction<boolean>>;
  articlesIsEmpty: boolean;
  uploadLoading: boolean;
  onUploadExcel: (file: Blob) => void;
}
export const EditArticlesFooter: FC<IEditFooterProps> = ({
  onCancel,
  setIsReadyToDelete,
  setShowAddAndEditRow,
  articlesIsEmpty,
  onUploadExcel,
  uploadLoading,
}) => {
  const {
    value: { showFinishEdit },
    dispatches: { setShowFinishEdit },
  } = useEditInvoice();
  const { message } = App.useApp();
  const beforeUpload = (file: Blob | null) => {
    if (!file) {
      message.error("File not found");
      return false;
    }

    const isExcelFile =
      file.type ===
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    if (!isExcelFile) {
      message.error("You can only upload EXCEL file!");
    }
    return isExcelFile;
  };
  const inputRef = useRef<HTMLInputElement>(null);
  return (
    <Col span={24} className="flex justify-end gap-[24px]  footer">
      <Button
        onClick={() => {
          onCancel();
          setShowFinishEdit(false);
        }}
        type={showFinishEdit ? "link" : "default"}
        style={{
          border: showFinishEdit
            ? "1px solid var(--info-info-600, #3B82F6)"
            : "unset",
          borderRadius: 16,
        }}
        htmlType="button"
        icon={
          <span className="material-icons">
            {showFinishEdit ? "check" : "close"}
          </span>
        }
        className="flex justify-center items-center "
      >
        {showFinishEdit ? "Finish edit" : "Cancel"}
      </Button>
      {articlesIsEmpty && (
        <Guard action={NewInvoiceArticlePath}>
          <input
            type="file"
            ref={inputRef}
            style={{ display: "none" }}
            onChange={(e) => {
              const file = e.target.files ? e.target.files[0] : null;
              if (beforeUpload(file)) {
                onUploadExcel(file!);
              }
            }}
          />
          <Button
            onClick={() => {
              inputRef.current?.click();
            }}
            type={"link"}
            style={{
              border: "1px solid var(--info-info-600, #3B82F6)",
              borderRadius: 16,
            }}
            htmlType="button"
            icon={<span className="material-icons">attach_file</span>}
            className="flex justify-center items-center "
            loading={uploadLoading}
          >
            Attach excel file
          </Button>
        </Guard>
      )}
      <Guard action={NewInvoiceArticlePath}>
        <Button
          type="primary"
          htmlType="button"
          icon={<span className="material-icons"> add</span>}
          className="flex justify-center items-center "
          onClick={() => {
            setShowAddAndEditRow(true);
            setShowFinishEdit(true);
          }}
        >
          Add row
        </Button>
      </Guard>
      {!articlesIsEmpty && (
        <Guard action={DeleteArticlePath}>
          <Button
            type="primary"
            danger
            htmlType="button"
            icon={
              <span className="material-symbols-outlined">delete_forever</span>
            }
            className="flex justify-center items-center "
            onClick={() => {
              setIsReadyToDelete((prev) => !prev);
              setShowFinishEdit(true);
            }}
          >
            Delete rows
          </Button>
        </Guard>
      )}
    </Col>
  );
};
