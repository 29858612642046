import {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useState,
} from "react";
import { SystemService } from "src/services/System/System.service";
import { IGetCountry, IGetSupplier } from "src/services/System/models";
import { TableData } from "../models";
import { Tools } from "../components/tools";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ITablePagination } from "src/models/interfaces/pagination";
import { message } from "antd";
import { ROUTES } from "src/models/enums";

interface IContext {
  value: {
    countryLoading: boolean;
    countryList: IGetCountry[] | undefined;
    pagination: ITablePagination;
    dataSource: TableData[];
    tableLoading: boolean;
    deleteLoading: boolean;
  };
  dispatch: { setPagination: Dispatch<SetStateAction<ITablePagination>> };
  func: {
    fetchCountry: () => void;
    getSupplierTableData: () => void;
    deleteSupplier: (id: number) => void;
  };
}

export const SupplierContext = createContext<IContext | undefined>(undefined);

export const SupplierProvider: FC<PropsWithChildren> = ({ children }) => {
  //----------------------------------------------------------------Functions----------------------------------------------------------------
  const [countryLoading, setCountryLoading] = useState<boolean>(false);
  const [countryList, setCountryList] = useState<IGetCountry[] | undefined>();
  const fetchCountry = async () => {
    try {
      setCountryLoading(true);
      const { GetCountry } = new SystemService();
      const response = await GetCountry();
      if (response && response.status === 200 && response.data) {
        setCountryList(response.data);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setCountryLoading(false);
    }
  };
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [query, setQuery] = useSearchParams();
  const createTableData = useCallback(
    (data: IGetSupplier[]) => {
      if (!data) return [];
      const newData: TableData[] = data.map((report, i) => {
        return {
          "#": i + 1,
          ...report,
          tools: (
            <Tools
              onEdit={() => {
                setQuery("");
                navigate(ROUTES.SupplierEdit, { state: report });
              }}
              onClickDelete={() => {
                deleteSupplier(report.id);
              }}
              onView={() => {
                setQuery("");

                navigate(ROUTES.SupplierView, { state: report });
              }}
            />
          ),
        };
      });
      return newData;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [navigate]
  );
  const [tableLoading, setTableLoading] = useState<boolean>(false);
  const [dataSource, setDataSource] = useState<TableData[]>([]);
  let [searchParams] = useSearchParams();
  const [pagination, setPagination] = useState<ITablePagination>({
    current: 1,
    pageSize: 10,
    total: 10,
  });
  const getSupplierTableData = useCallback(async () => {
    setTableLoading(true);
    try {
      const { GetSupplier } = new SystemService();
      const result = await GetSupplier(searchParams.toString());
      if (result && result.status === 200) {
        setDataSource(createTableData(result.data.records));

        setPagination((prev) => ({ ...prev, total: result.data.count }));
      }
    } catch (err) {
      console.log(err);
    } finally {
      setTableLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const deleteSupplier = async (id: number) => {
    try {
      setDeleteLoading(true);
      const { DeletetSupplier } = new SystemService();
      const resp = await DeletetSupplier(id);
      if (resp && resp.status === 200) {
        message.success("Deletet Supplier successfully");
        getSupplierTableData();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setDeleteLoading(false);
    }
  };
  const ContextValue: IContext = {
    value: {
      countryLoading,
      countryList,
      pagination,
      dataSource,
      tableLoading,
      deleteLoading,
    },
    dispatch: { setPagination },
    func: { fetchCountry, getSupplierTableData, deleteSupplier },
  };
  return (
    <SupplierContext.Provider value={ContextValue}>
      {children}
    </SupplierContext.Provider>
  );
};

export const useSupplier = () => useContext(SupplierContext);
