import { Popconfirm } from "antd";
import { FC, ReactElement } from "react";
interface ISPopconfirm {
  onConfirm: () => void;
  children: ReactElement;
  description: string;
  deleteClassName?: boolean;
}
export const SPopconfirm: FC<ISPopconfirm> = ({
  onConfirm,
  children,
  description,
  deleteClassName,
}) => {
  return (
    <Popconfirm
      icon=""
      placement="topRight"
      title={deleteClassName ? "Delete" : "Reset"}
      description={description}
      onConfirm={onConfirm}
      rootClassName={deleteClassName ? "deletePopconfirm" : "resetPopConfirm"}
      okText="Yes"
      cancelText="No"
    >
      <>{children}</>
    </Popconfirm>
  );
};
