import { FC, useState } from "react";
import { Button, Divider, Flex } from "antd";
import { SInput } from "src/components/UiKit/Input";
import CreateFolderModal from "../modals/CreateFolderModal";
import CreateFileModal from "../modals/CreateFileModal";
import { mainPath, useDocumentation } from "../../context";

const DocumentationActions: FC = () => {
  const [openCreateModal, setOpenCreateModal] = useState<boolean>(false);
  const [openCreateFileModal, setOpenCreateFileModal] =
    useState<boolean>(false);
  const {
    value: { path, selectedParentId, filterValue },
    dispatch: { setPath, setSelectedParentId, setSelectedDoc, setFilterValue },
  } = useDocumentation();

  const handleBack = () => {
    //setSelectedDoc(undefined);
    if (selectedParentId.length === 0) {
      setPath(mainPath);
      setFilterValue((prev) => ({ ...prev, ParentId: undefined }));
    } else {
      const pathArray = path.split("/");
      pathArray.splice(-1);
      setPath(pathArray.join("/"));
      const parent = selectedParentId[selectedParentId.length - 1];
      setSelectedParentId(selectedParentId.slice(0, -1));
      setFilterValue((prev) => ({
        ...prev,
        ParentId: parent === 0 ? undefined : parent,
      }));
    }
  };

  return (
    <>
      <CreateFolderModal
        open={openCreateModal}
        onCancel={() => setOpenCreateModal(false)}
      />
      <CreateFileModal
        open={openCreateFileModal}
        onCancel={() => setOpenCreateFileModal(false)}
      />
      <Flex justify="space-around" align="center" className="py-3 h-[88px]">
        <Button
          disabled={!filterValue.ParentId}
          className="h-[52px] w-[52px] flex items-center justify-center mr-2"
          onClick={handleBack}
        >
          <span className="material-icons">arrow_back</span>
        </Button>
        <SInput disabled={true} value={path} />
        <div className="flex items-center justify-between">
          <Button
            type="text"
            icon={<span className="material-icons">folder_open</span>}
            className="flex items-center text-[#2C73FC] text-[12px] hover:!text-[#2C73FC] hover:!text-[12px] hover:!bg-[#fff] font-medium"
            onClick={() => {
              setSelectedDoc(undefined);
              setOpenCreateModal(true);
            }}
          >
            Create Folder
          </Button>
          <Divider type="vertical" className="bg-[#A3AED0] w-[2px] h-[22px]" />

          <Button
            type="text"
            icon={<span className="material-icons">attachment</span>}
            className="flex items-center text-[#2C73FC] text-[12px] hover:!text-[#2C73FC] hover:!text-[12px] hover:!bg-[#fff] font-medium"
            onClick={() => {
              setSelectedDoc(undefined);
              setOpenCreateFileModal(true);
            }}
          >
            Attach File
          </Button>
        </div>
      </Flex>
    </>
  );
};

export default DocumentationActions;
