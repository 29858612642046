import { FC } from "react";
import { Receiver } from "src/modules/Receiver";
import { ReceiverProvider } from "src/modules/Receiver/context";

export const ReceiverPage: FC = () => {
  return (
    <ReceiverProvider>
      <Receiver />
    </ReceiverProvider>
  );
};
