import { FC, useEffect, useState } from "react";
import { ContentContainer } from "../style";
import { Button, Col, Flex, Form, Row } from "antd";
import { FormItem } from "src/components/UiKit/FormItem";
import { SInput } from "src/components/UiKit/Input";
import { useCountry } from "../context";

export const AddNewCountry: FC = () => {
  const {
    value: { addCountryLoading, editMode, editData, editLoading },
    func: { onFinish },
    form: { countryForm },
  } = useCountry();

  const [submit, setSubmit] = useState<boolean>(false);

  const formValue = Form.useWatch([], countryForm);

  useEffect(() => {
    countryForm
      ?.validateFields({ validateOnly: true })
      .then(() => setSubmit(true))
      .catch(() => setSubmit(false));
  }, [countryForm, formValue]);

  useEffect(() => {
    countryForm!.setFieldsValue(editData);
  }, [countryForm, editData]);

  useEffect(() => {
    if (!editMode) countryForm!.resetFields();
  }, [editMode, countryForm]);

  return (
    <Form form={countryForm} onFinish={onFinish}>
      <Row justify="center">
        <Col span={15}>
          <ContentContainer>
            <Col span={24} className="text-[16px] font-medium text-[#001377]">
              {editMode
                ? "You can choose any option; Edit its information."
                : "Please complete the requested information."}
            </Col>
            <Col span={24} className="py-[16px]">
              <div className="h-[227px]">
                <Row gutter={24}>
                  <Col span={12}>
                    <FormItem
                      name="name"
                      label="Name"
                      rules={[{ required: true }]}
                      required={true}
                    >
                      <SInput placeholder="insert" allowClear />
                    </FormItem>
                  </Col>
                  <Col span={12}>
                    <FormItem
                      name="abbrivation"
                      label="Short name"
                      rules={[{ required: true }]}
                      required={true}
                    >
                      <SInput placeholder="insert" allowClear />
                    </FormItem>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col span={24} className="button">
              <Flex justify="end" gap={16} align="center">
                <Button>{editMode ? "Cancel" : "Reset"}</Button>
                <Button
                  type="primary"
                  loading={editMode ? editLoading : addCountryLoading}
                  htmlType="submit"
                  disabled={!submit}
                >
                  {editMode ? "Save edit" : "Add"}
                </Button>
              </Flex>
            </Col>
          </ContentContainer>
        </Col>
      </Row>
    </Form>
  );
};
