import { FC, useEffect } from "react";
import { TableContainer } from "../style";
import { TableUI } from "src/components/UiKit/table";
import { ColumnsType } from "antd/es/table";
import { getRowKey } from "src/helpers/getRowKey";
import { useCurrency } from "../context";
import { IPublicCurrency } from "src/services/Public/models";
import { Guard } from "src/components/Guard";
import { EditCurrencyPath } from "src/services/System/guardPath";
import { Col } from "antd";

export const CurrencyTable: FC = () => {
  const {
    value: { tableLoading, tableData },
    dispatch: { setEditMode, setCurrencyId, setEditData },
    func: { getCurrency },
  } = useCurrency();

  const onEdit = (id: number, record: IPublicCurrency) => {
    setEditMode(true);
    setCurrencyId(id);
    setEditData(record);
  };

  const columns: ColumnsType<IPublicCurrency> = [
    {
      title: "#",
      key: "id",
      align: "center",
      width: "55px",
      render: (name, record, index) => index + 1,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      align: "center",
      width: "264px",
    },
    {
      title: "Short name",
      dataIndex: "shortName",
      key: "shortName",
      align: "center",
      width: "264px",
    },
    {
      title: "Tools",
      key: "tools",
      align: "center",
      width: "130px",
      render: (text, record) => (
        <div className="flex justify-center items-center gap-[16px] text-[16px]">
          <Guard action={EditCurrencyPath}>
            <span
              onClick={() => {
                onEdit(record.id, record);
              }}
              className="material-icons !text-[#0C9D61] text-[16px] cursor-pointer"
            >
              edit_note
            </span>
          </Guard>
        </div>
      ),
    },
  ];

  useEffect(() => {
    getCurrency();
  }, [getCurrency]);
  return (
    <TableContainer justify="center">
      <Col span={15}>
        <TableUI
          style={{ padding: "none" }}
          columns={columns}
          rowKey={getRowKey}
          dataSource={tableData}
          loading={tableLoading}
          pagination={false}
        />
      </Col>
    </TableContainer>
  );
};
