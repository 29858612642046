import { FC, Suspense } from "react";
import { Agents } from "src/modules/agents";
import { AgentsProvider } from "src/modules/agents/context";
import { PreLoading } from "src/routes/Loading";

export const AgentsPage: FC = () => {
  return (
    <Suspense fallback={<PreLoading />}>
      <AgentsProvider>
        <Agents />
      </AgentsProvider>
    </Suspense>
  );
};
