import { FC } from "react";
import { useAddShip } from "src/modules/AddShips/context";
import { HeaderEditShipContainer } from "../style/styles";
import { IAddShipsTabs } from "src/modules/AddShips/models";

export const HeaderEditShip: FC = () => {
  const {
    dispatch: { setIsEditMOde, setActiveKeyAddNewShip, setIsShowMode },
    func: { resetForms },
    value: { tableCurrentValue },
  } = useAddShip()!;
  return (
    <HeaderEditShipContainer>
      <div
        className="flex flex-row gap-[12px] items-center cursor-pointer"
        onClick={() => {
          setIsEditMOde(false);
          setActiveKeyAddNewShip(IAddShipsTabs.shipsTable);
          setIsShowMode(false);
          resetForms();
        }}
      >
        <span className="material-icons text-[#2C73FC] text-[32px]">
          keyboard_backspace
        </span>
        <div className=" text-[#2C73FC] text-[24px] ">Back to ship table </div>
      </div>
      <div className="flex flex-col">
        <div className="text-[#001377] text-[18px]">Edit ship </div>
        <div className="text-[#5D6881] text-[14px]">
          {tableCurrentValue?.name}
        </div>
      </div>
    </HeaderEditShipContainer>
  );
};
