export enum LoType {
  HSCYLOLTR = 1,
  LSCYLOLTR = 2,
  MELOLTR = 3,
  AELOLTR = 4,
}

export const LoTypeName = [
  "HSCYLO(LTR)",
  "LSCYLO(LTR)",
  "MELO(LTR)",
  "AELO(LTR)",
];
