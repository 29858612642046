import { Button, Col, Divider, Form, Row, Select, Space } from "antd";
import { FC, useEffect, useState } from "react";
import { FormItem } from "src/components/UiKit/FormItem";
import { SInput } from "src/components/UiKit/Input";
import { BasicInformationContainer } from "../styles";
import { useAddShip } from "src/modules/AddShips/context";
import { IAddNewShip } from "src/modules/AddShips/models";
import { SPopconfirm } from "./popconfirm";
import { SDatePicker } from "src/components/UiKit/DatePicker";
import { IShipBasicInformation, OwnerShipType } from "src/services/Ship/models";
import { ShipService } from "src/services/Ship/Ship.service";
import { AddCountryModal } from "../../../../../components/UiKit/AddCountryModal/AddCountryModal";
import { usePublicApi } from "src/hook/publicApi";
import dayjs from "dayjs";
export const BasicInformation: FC = () => {
  const [submittable, setSubmittable] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const {
    dispatch: { setActiveKeyAddShip, setShipId, setIsAddCountryModalOpen },
    forms: { BasicInformationForm },
    value: { countryList, isAddCountryModalOpen },
    func: { getCountry },
  } = useAddShip()!;
  const values = Form.useWatch([], BasicInformationForm);
  const { shipRefetch } = usePublicApi();
  useEffect(() => {
    BasicInformationForm?.validateFields({ validateOnly: true }).then(
      () => {
        setSubmittable(true);
      },
      () => {
        setSubmittable(false);
      }
    );
  }, [values, BasicInformationForm]);
  const onCancel = () => {};

  const nextTab = () => {
    setActiveKeyAddShip(IAddNewShip.shipsPicture);
  };

  const onFinish = async (values: IShipBasicInformation) => {
    setLoading(true);
    const service = new ShipService();
    const reqBody: IShipBasicInformation = {
      abbreviation: values.abbreviation,
      countryRegisterId: values.countryRegisterId,
      deliveryDate: values.deliveryDate
        ? dayjs(values.deliveryDate).format("YYYY-MM-DD")
        : null,
      name: values.name,
      ownerShipType: values.ownerShipType,
      pN1: values.pN1,
      purchaseDate: values.purchaseDate
        ? dayjs(values.purchaseDate).format("YYYY-MM-DD")
        : null,
    };
    try {
      const response = await service.ShipBasicInformation(reqBody);
      if (response && response.status === 200) {
        BasicInformationForm?.resetFields();
        setShipId(response.data);
        shipRefetch();
        nextTab();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getCountry();
  }, [getCountry]);
  return (
    <Form form={BasicInformationForm} onFinish={onFinish}>
      <div className="flex flex-col justify-between h-[620px]">
        <AddCountryModal
          getCountry={getCountry}
          isAddCountryModalOpen={isAddCountryModalOpen}
          setIsAddCountryModalOpen={setIsAddCountryModalOpen}
        />
        <BasicInformationContainer className="flex gap-[24px]">
          <Col span={24}>
            <div className="titleHolder pt-[16px]">
              Please insert Ship information.
            </div>
          </Col>
          <Col span={24} className="flex gap-[24px] pt-[16px]">
            <FormItem
              name={"pN1"}
              label="Previous names"
              className="w-[33%] !mb-0"
            >
              <SInput placeholder="insert" />
            </FormItem>
            <FormItem
              name={"name"}
              label="Current name"
              className="w-[33%] !mb-0"
            >
              <SInput placeholder="insert" />
            </FormItem>
            <FormItem
              name={"abbreviation"}
              label="Abbreviation "
              rules={[{ required: true }, { max: 10 }]}
              className="w-[33%] !mb-0"
            >
              <SInput placeholder="insert" />
            </FormItem>
          </Col>
          <Col span={24} className="flex gap-[24px] items-center">
            <FormItem
              name={"ownerShipType"}
              label="Ownership type"
              className="w-[33%] !mb-0"
            >
              <Select
                style={{ width: "100%" }}
                placeholder="select"
                optionLabelProp="label"
                optionFilterProp="label"
                showSearch
                className="selectOption"
                options={OwnerShipType}
              />
            </FormItem>

            <FormItem
              name={"countryRegisterId"}
              label="Country of registration "
              className="w-[33%] !mb-0"
            >
              <Select
                style={{ width: "100%" }}
                placeholder="select"
                showSearch
                optionFilterProp="label"
                className="selectOption"
                //   loading={shipLoading}
                dropdownRender={(menu) => (
                  <>
                    <Space style={{ padding: "16px 8px 4px" }}>
                      <div
                        onClick={() => setIsAddCountryModalOpen(true)}
                        className="cursor-pointer flex justify-center items-center"
                      >
                        <span className="material-icons text-[16px] text-[#001377]">
                          add
                        </span>
                        <div className="addHolder"> Add Country</div>
                      </div>
                    </Space>
                    <Divider style={{ margin: "8px 0" }} />
                    {menu}
                  </>
                )}
                options={countryList.map((country) => ({
                  label: country.name,
                  value: country.id,
                }))}
              />
            </FormItem>
            <FormItem
              name={"purchaseDate"}
              label="Purchase date"
              className="!mb-0 w-[33%] "
            >
              <SDatePicker placeholder="Select" className="w-[100%]" />
            </FormItem>
          </Col>
          <Col span={24} className="flex gap-[24px] items-center">
            <FormItem
              name={"deliveryDate"}
              label="Delivery date"
              className="!mb-0 w-[32%] "
            >
              <SDatePicker placeholder="Select" className="w-[100%]" />
            </FormItem>
          </Col>
        </BasicInformationContainer>
        <Row className="flex pt-[24px]">
          <Col span={24}>
            <div className="flex gap-[16px] footer justify-end">
              <SPopconfirm
                description="Are you sure you want to reset all the information you filled out?"
                onConfirm={() => {
                  BasicInformationForm?.resetFields();
                }}
              >
                <Button onClick={onCancel} className="w-[100px]">
                  Reset
                </Button>
              </SPopconfirm>
              <Button
                type="primary"
                className="w-[100px]"
                disabled={!submittable}
                onClick={BasicInformationForm?.submit}
                loading={loading}
              >
                Next
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    </Form>
  );
};
