import { styled } from "styled-components";

export const TopBarStyles = styled.div`
  display: flex;
  flex-direction: column;
  .littleCard {
    display: grid;
    grid-template-columns: auto auto auto auto auto auto;
    overflow-x: auto;
    padding: 0px 32px 24px 24px;
  }

  .sc-iUeHef {
    padding: 0 !important;
  }
`;
