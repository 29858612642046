import React, { FC, ReactNode } from "react";
import { LoginLayoutStyled } from "./styles";
import { Outlet } from "react-router-dom";
import { Layout } from "antd";

const LoginLayout: FC<{ children?: ReactNode }> = ({ children }) => {
  return (
    <Layout>
      <LoginLayoutStyled>
        <Outlet />
      </LoginLayoutStyled>
    </Layout>
  );
};

export default LoginLayout;
