import axios, { AxiosRequestConfig } from "axios";
import { DocumentUrls } from "./urls";
import { IDocument, IEditDocument, INewDirectory } from "./models";

export class DocumentService {
  async GetDocument(queries?: string) {
    let endpoint: string = DocumentUrls.GetDocument;
    if (queries) endpoint = endpoint.concat(`?${queries}`);
    try {
      const response = await axios.get<IDocument[]>(endpoint);
      return response;
    } catch (err) {
      return console.log(err);
    }
  }

  async NewDirectory(data: INewDirectory) {
    try {
      const response = await axios.post<number>(
        DocumentUrls.NewDirectory,
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async EditDocument(id: number, data: IEditDocument) {
    try {
      const response = await axios.put(
        DocumentUrls.Document.concat(`${id}${DocumentUrls.EditDocument}`),
        data
      );
      return response;
    } catch (error) {
      console.log(error);
    }
  }
  async DeleteDocument(id: number | string) {
    try {
      const response = await axios.delete<void>(
        DocumentUrls.Document.concat(`${id}${DocumentUrls.DeleteDocument}`)
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async NewFile(data: FormData, config: AxiosRequestConfig) {
    try {
      const response = await axios.post<number>(
        DocumentUrls.NewFile,
        data,
        config
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async DownloadDocument(id: number | string, config?: AxiosRequestConfig) {
    const newConfig: AxiosRequestConfig<any> = {
      responseType: "arraybuffer",
      headers: {
        accept: "*/*",
      },
      ...config,
    };
    try {
      const response = await axios.get<Buffer>(
        DocumentUrls.Document.concat(id.toString()).concat(
          DocumentUrls.GetFile
        ),
        newConfig
      );
      return { buffer: response.data, type: response.headers["content-type"] };
    } catch (err) {
      console.log(err);
    }
  }
}
