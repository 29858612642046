import {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { EditInvoice } from "..";
import { IPDFFile } from "src/modules/Table/models";
import { IFinallyArticle, IInvoice } from "src/services/Invoice/models";
import { InvoiceService } from "src/services/Invoice/Invoice.service";
import { SystemService } from "src/services/System/System.service";
import { IGetSupplier } from "src/services/System/models";
interface IContext {
  value: {
    header: string;
    showModal: boolean;
    editInvoiceId: number | undefined;
    refetchPdfZipFile: number;
    currentEditInvoice: IInvoice | undefined;
    currentPdfFile: IPDFFile | undefined;
    getCurrentInvoiceLoading: boolean;
    showEditArticles: boolean;
    currentArticle: IFinallyArticle | undefined;
    showDeleteArticle: boolean;
    selectedArticle: number[];
    showAddAndEditRow: boolean;
    showFinishEdit: boolean;
    currentInvoiceAttachmentLoading: boolean;
    currentInvoiceAttachment: string | undefined;
    supplierList: IGetSupplier[] | undefined;
    supplierLoading: boolean;
  };
  dispatches: {
    setHeader: Dispatch<SetStateAction<string>>;
    setShowModal: Dispatch<SetStateAction<boolean>>;
    setShowEditArticles: Dispatch<SetStateAction<boolean>>;
    setEditInvoiceId: Dispatch<SetStateAction<number | undefined>>;
    setCurrentEditInvoice: Dispatch<SetStateAction<IInvoice | undefined>>;
    setCurrentPdfFile: Dispatch<SetStateAction<IPDFFile | undefined>>;
    setCurrentArticle: Dispatch<SetStateAction<IFinallyArticle | undefined>>;
    setShowDeleteArticle: Dispatch<SetStateAction<boolean>>;
    setSelectedArticle: Dispatch<SetStateAction<number[]>>;
    setShowAddAndEditRow: Dispatch<SetStateAction<boolean>>;
    setShowFinishEdit: Dispatch<SetStateAction<boolean>>;
    setCurrentInvoiceAttachment: Dispatch<SetStateAction<string | undefined>>;
  };
  func: {
    refetchZipFileToggle: () => void;
    reset: () => void;
    fetchSupplierEitInvoice: () => void;
  };
}
const defaultContextValue: IContext = {
  value: {
    header: "",
    showModal: false,
    editInvoiceId: undefined,
    refetchPdfZipFile: 1,
    currentEditInvoice: undefined,
    currentPdfFile: undefined,
    getCurrentInvoiceLoading: false,
    showEditArticles: false,
    currentArticle: undefined,
    showDeleteArticle: false,
    selectedArticle: [],
    showAddAndEditRow: false,
    showFinishEdit: false,
    currentInvoiceAttachmentLoading: false,
    currentInvoiceAttachment: undefined,
    supplierList: undefined,
    supplierLoading: false,
  },
  dispatches: {
    setHeader: () => {},
    setShowModal: () => {},
    setEditInvoiceId: () => {},
    setCurrentEditInvoice: () => {},
    setCurrentPdfFile: () => {},
    setShowEditArticles: () => {},
    setCurrentArticle: () => {},
    setShowDeleteArticle: () => {},
    setSelectedArticle: () => {},
    setShowAddAndEditRow: () => {},
    setShowFinishEdit: () => {},
    setCurrentInvoiceAttachment: () => {},
  },
  func: {
    refetchZipFileToggle: () => {},
    reset: () => {},
    fetchSupplierEitInvoice: () => {},
  },
};
export const EditInvoiceCTX = createContext<IContext>(defaultContextValue);
export const EditInvoiceProvider: FC<PropsWithChildren> = ({ children }) => {
  const [header, setHeader] = useState<string>("");
  const [showModal, setShowModal] = useState<boolean>(false);
  const [editInvoiceId, setEditInvoiceId] = useState<number>();
  const [refetchPdfZipFile, setRefetchPdfZipFile] = useState<number>(1);
  const [currentPdfFile, setCurrentPdfFile] = useState<IPDFFile>();
  const [currentEditInvoice, setCurrentEditInvoice] = useState<IInvoice>();
  const [showEditArticles, setShowEditArticles] = useState<boolean>(false);
  const [currentArticle, setCurrentArticle] = useState<IFinallyArticle>();
  const [showDeleteArticle, setShowDeleteArticle] = useState<boolean>(false);
  const [selectedArticle, setSelectedArticle] = useState<number[]>([]);
  const [showAddAndEditRow, setShowAddAndEditRow] = useState<boolean>(false);
  const [showFinishEdit, setShowFinishEdit] = useState<boolean>(false);
  const [currentInvoiceAttachment, setCurrentInvoiceAttachment] =
    useState<string>();
  const [currentInvoiceAttachmentLoading, setCurrentInvoiceAttachmentLoading] =
    useState<boolean>(false);
  const [getCurrentInvoiceLoading, setCurrentInvoiceLoading] =
    useState<boolean>(false);
  const refetchZipFileToggle = () => {
    setRefetchPdfZipFile((prev) => prev + 1);
  };
  const getCurrentInvoice = async (id: number) => {
    try {
      setCurrentInvoiceLoading(true);
      const { GetInvoice } = new InvoiceService();
      const res = await GetInvoice(id);
      if (res && res.data) {
        setCurrentEditInvoice(res.data);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setCurrentInvoiceLoading(false);
    }
  };
  const getInvoiceAttachment = async (id: number) => {
    try {
      setCurrentInvoiceAttachmentLoading(true);
      const { GetInvoiceAttachment } = new InvoiceService();
      const res = await GetInvoiceAttachment(id);
      if (res) {
        setCurrentInvoiceAttachment(res);
      } else {
        setCurrentInvoiceAttachment("");
      }
    } catch (err) {
      console.log(err);
    } finally {
      setCurrentInvoiceAttachmentLoading(false);
    }
  };
  useEffect(() => {
    if (showModal && editInvoiceId) {
      getCurrentInvoice(editInvoiceId);
      getInvoiceAttachment(editInvoiceId);
    }
  }, [editInvoiceId, showModal]);
  useEffect(() => {
    fetchSupplierEitInvoice();
  }, []);
  const reset = () => {
    setCurrentEditInvoice(undefined);
    setRefetchPdfZipFile(1);
    setEditInvoiceId(undefined);
  };
  const [supplierLoading, setSupplierLoading] = useState<boolean>(false);
  const [supplierList, setSupplierList] = useState<IGetSupplier[]>();
  const fetchSupplierEitInvoice = async () => {
    try {
      setSupplierLoading(true);
      const { GetSupplier } = new SystemService();
      const response = await GetSupplier();
      if (response && response?.status === 200 && response.data) {
        setSupplierList(response.data.records);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setSupplierLoading(false);
    }
  };
  const contextValue: IContext = {
    value: {
      header,
      showModal,
      editInvoiceId,
      refetchPdfZipFile,
      currentEditInvoice,
      currentPdfFile,
      getCurrentInvoiceLoading,
      showEditArticles,
      currentArticle,
      showDeleteArticle,
      selectedArticle,
      showAddAndEditRow,
      showFinishEdit,
      currentInvoiceAttachmentLoading,
      currentInvoiceAttachment,
      supplierList,
      supplierLoading,
    },
    dispatches: {
      setHeader,
      setShowModal,
      setEditInvoiceId,
      setCurrentEditInvoice,
      setCurrentPdfFile,
      setShowEditArticles,
      setCurrentArticle,
      setShowDeleteArticle,
      setSelectedArticle,
      setShowAddAndEditRow,
      setShowFinishEdit,
      setCurrentInvoiceAttachment,
    },
    func: {
      refetchZipFileToggle,
      reset,
      fetchSupplierEitInvoice,
    },
  };

  return (
    <EditInvoiceCTX.Provider value={contextValue}>
      {children}
      <EditInvoice
        header={header}
        onCancelModal={() => {
          setShowModal(false);
        }}
        showModal={showModal}
      />
    </EditInvoiceCTX.Provider>
  );
};

export const useEditInvoice = () => useContext(EditInvoiceCTX);
