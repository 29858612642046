import { FC } from "react";
import { Popconfirm, Tooltip } from "antd";
import { useNoonReportCTX } from "src/modules/NoonReport/context";
import { INoonReport } from "src/services/NoonReport/models";
import { ToolsContainer } from "../styles";
import { useNoonReportTable } from "../context";

export const TableTools: FC<{ record: INoonReport }> = ({ record }) => {
  const {
    func: { changeMode },
    dispatch: { setSelectedReport },
  } = useNoonReportCTX();

  const {
    func: { deleteNoonReport },
  } = useNoonReportTable();

  return (
    <ToolsContainer>
      <Tooltip title="Edit">
        <span
          className="material-icons text-[#1DCF0E]"
          onClick={() => {
            changeMode("edit");
            setSelectedReport(record);
          }}
        >
          edit_note
        </span>
      </Tooltip>
      <Tooltip title="View">
        <span
          className="material-icons text-[#2C73FC]"
          onClick={() => {
            changeMode("view");
            setSelectedReport(record);
          }}
        >
          remove_red_eye
        </span>
      </Tooltip>
      <Tooltip title="Delete">
        <Popconfirm
          title="Delete noon report"
          description="Are you sure you want to delete this Noon Report?"
          rootClassName="deletePopconfirm"
          icon={null}
          onConfirm={() => deleteNoonReport(record.id)}
        >
          <span className="material-icons text-[#FF1414]">delete_forever</span>
        </Popconfirm>
      </Tooltip>
    </ToolsContainer>
  );
};
