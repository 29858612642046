import { LoadingOutlined } from "@ant-design/icons";
import { Col, Typography, Upload, message } from "antd";
import { RcFile, UploadFile } from "antd/es/upload";
import { UploadProps } from "antd/lib";
import { UploadChangeParam } from "antd/lib/upload";
import { FC, useState } from "react";
import { UploadZipContainer } from "../../styles/AttachZipFileStyles/uploadZip.style";
import { useAttachZipFileContext } from "../../context/attachZipFile";
import { uploadFile } from "src/helpers/uploadZipFile";
import { InvoiceUrls } from "src/services/Invoice/urls";

export const UploadZip: FC = () => {
  const [loading, setLoading] = useState(false);
  const {
    value: { zipFile, newRequestId },
    dispatch: { setZipFile },
  } = useAttachZipFileContext()!;
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [progress, setProgress] = useState<number>(0);
  const uploadButton = (
    <div>
      {loading ? (
        <>
          <LoadingOutlined color="#A3AED0" />
          <div>{Math.round(progress)}%</div>
        </>
      ) : (
        <>
          <span className="material-icons text-[#A3AED0] ">attach_file</span>
          <div className="text-[#A3AED0]">Attach</div>
        </>
      )}
    </div>
  );
  const handleChange: UploadProps["onChange"] = (
    info: UploadChangeParam<UploadFile>
  ) => {
    setFileList([info.file]);
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      setZipFile(info.file.originFileObj);
      setLoading(false);
      return;
    }
    if (info.file.status === "error") {
      setZipFile(undefined);
      setLoading(false);
      message.error("upload failed");
      return;
    }
  };
  const beforeUpload = (file: RcFile) => {
    const isZipFile =
      file.type.includes("zip") ||
      file.name.includes(".zip") ||
      file.name.includes(".rar");
    if (!isZipFile) {
      message.error("You can only upload zip file!");
    }
    setZipFile(undefined);

    return isZipFile;
  };

  return (
    <UploadZipContainer>
      <Col span={24}>
        <div className="attachFile">
          <Typography.Text className=" attachFileLabel">
            Attach Zip file.
          </Typography.Text>
          <div className="flex items-center gap-[32px]">
            <Upload
              name="zipAttachment"
              listType="picture-card"
              className=" flex justify-center upload"
              showUploadList={false}
              beforeUpload={beforeUpload}
              onChange={handleChange}
              fileList={fileList}
              customRequest={(options) =>
                uploadFile({
                  options,
                  setProgress,
                  callbackUrl: InvoiceUrls.Invoice.concat(
                    `${newRequestId}`
                  ).concat(InvoiceUrls.AttachZipToAllRequest),
                })
              }
              disabled={loading}
            >
              {zipFile ? (
                <div>
                  <span className="material-icons text-[42px] text-[green]">
                    download_done
                  </span>
                </div>
              ) : (
                uploadButton
              )}
            </Upload>
            {zipFile && <span>{(zipFile as RcFile).name}</span>}
          </div>
        </div>
      </Col>
    </UploadZipContainer>
  );
};
