import { Layout } from "antd";
import styled from "styled-components";
const { Content } = Layout;

export const LoginLayoutStyled = styled(Content)`
  background: url(/assets/images/loginBg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
