import { Tabs, TabsProps } from "antd";
import { FC } from "react";
import { IShipManagementTabs } from "./models";
import { ShipManagementContainer } from "./styles";
import { AddNewShipManagement } from "./components/AddNewManagement/addNewManagement";
import { ShipManagementTable } from "./components/ShipManagementTable/shipManagementTable";
import { useShipManagement } from "./context";
import { EditNewShipManagement } from "./components/EditShipManagement";
import { ShowShipManagement } from "./components/ShowShipManagement";
import { Guard } from "src/components/Guard";
import {
  GetShipManagementPath,
  NewShipManagementPath,
} from "src/services/Ship/guardPath";

export const ShipManagement: FC = () => {
  const {
    value: { editMode, showMode, activeKey },
    dispatch: { setActiveKey },
  } = useShipManagement()!;
  const items: TabsProps["items"] = [
    {
      key: IShipManagementTabs.addNewShipManagement,
      label: (
        <Guard action={NewShipManagementPath}>
          <span>
            <span className="material-icons text-[10px] p-[8px]">lens</span>
            Add new ship management
          </span>
        </Guard>
      ),
      children: (
        <Guard action={NewShipManagementPath}>
          <AddNewShipManagement />
        </Guard>
      ),
    },
    {
      key: IShipManagementTabs.shipManagementTable,
      label: (
        <Guard action={GetShipManagementPath}>
          <span>
            <span className="material-icons text-[10px] p-[10px]">lens</span>
            Ship management table
          </span>
        </Guard>
      ),
      children: (
        <Guard action={GetShipManagementPath}>
          <ShipManagementTable />
        </Guard>
      ),
    },
  ];
  const onChange = (key: string) => {
    setActiveKey(key);
  };
  return showMode ? (
    <ShowShipManagement />
  ) : editMode ? (
    <EditNewShipManagement />
  ) : (
    <ShipManagementContainer>
      <Tabs items={items} onChange={onChange} activeKey={activeKey} animated />
    </ShipManagementContainer>
  );
};
