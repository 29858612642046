import { FC } from "react";
import {
  AddFlowContainer,
  AddFlowLeftWrapper,
  AddFlowRightWrapper,
  AddFlowWrapper,
} from "./style";
import { useFlowCTX } from "../../context";
import { ActiveContent } from "./model/activeContent";
import { AddNewFlow } from "./components/Tabs";
import { StepList } from "./components/StepList";
import { RoleAndActionList } from "./components/RoleAndActionList";

export const AddFlow: FC = () => {
  const {
    value: { state },
  } = useFlowCTX();

  return (
    <AddFlowContainer>
      <div className="flex justify-center">
        <AddFlowLeftWrapper
          width={state === ActiveContent.CenterAndLeft ? "36.99469%" : "0"}
          className="left-active "
          $active={
            state === ActiveContent.CenterAndLeft
              ? "left"
              : state === ActiveContent.CenterAndRight
              ? "right"
              : undefined
          }
        >
          <RoleAndActionList />
        </AddFlowLeftWrapper>

        <AddFlowWrapper
          width={
            state === ActiveContent.Center
              ? "66.194690%"
              : state === ActiveContent.CenterAndLeft
              ? "63.362831%"
              : "54.247787%"
          }
          $active={
            state === ActiveContent.CenterAndLeft
              ? "left"
              : state === ActiveContent.CenterAndRight
              ? "right"
              : undefined
          }
          className="center"
        >
          <AddNewFlow />
        </AddFlowWrapper>

        <AddFlowRightWrapper
          width={state === ActiveContent.CenterAndRight ? "45.752212%" : "0"}
          className="right-active"
          $active={
            state === ActiveContent.CenterAndLeft
              ? "left"
              : state === ActiveContent.CenterAndRight
              ? "right"
              : undefined
          }
        >
          <StepList />
        </AddFlowRightWrapper>
      </div>
    </AddFlowContainer>
  );
};
