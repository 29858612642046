import { FC } from "react";
import Table, { ColumnsType } from "antd/es/table";
import { Container } from "./styles";
import { ITable } from "./model";
import { omit } from "lodash";

export const TableUI: FC<ITable> = (props) => {
  const { tableType = "primary" } = props;

  const newColumns: ColumnsType<typeof props.columns> = props.columns
    ? props.columns.map((column) => ({
        ...column,
        onCell: (record, index) => {
          const defaultOnCell = column.onCell
            ? column.onCell(record, index)
            : {};
          const defaultClassName = `${column.className} ${
            defaultOnCell.className || ""
          }`;
          return {
            ...omit(defaultOnCell, "className"),
            className:
              (index || 0) % 2 !== 0
                ? `${defaultClassName} !bg-[#F2F7FF]`
                : defaultClassName,
          };
        },
      }))
    : [];
  return (
    <Container type={tableType} style={props.containerStyle}>
      <Table {...omit(props, "columns")} columns={newColumns} />
    </Container>
  );
};
