import { App, Button, Col, Form, Popconfirm, Row } from "antd";
import { ColumnsType } from "antd/es/table";
import { FC, useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { FormItem } from "src/components/UiKit/FormItem";
import { SInput } from "src/components/UiKit/Input";
import { TableUI } from "src/components/UiKit/table";
import { getRowKey } from "src/helpers/getRowKey";
import { IContacts } from "src/modules/agents/models";
import { BaseInfoService } from "src/services/BaseInfo/BaseInfo.service";
import {
  IAllAgentContact,
  IAllAgents,
  INewAgentContact,
  NewAgentContactEnum,
  NewAgentContactType,
} from "src/services/BaseInfo/models";

export const ContactInfoEdit: FC = () => {
  const [phoneForm] = Form.useForm();
  const [emailForm] = Form.useForm();
  const [loading, setLoading] = useState<IContacts>({
    email: false,
    phone: false,
  });
  const [phoneData, setPhoneData] = useState<IAllAgentContact[]>([]);
  const [emailData, setEmailData] = useState<IAllAgentContact[]>([]);
  const [addEmailLoading, setAddEmailLoading] = useState<boolean>(false);
  const [addPhoneLoading, setAddPhoneLoading] = useState<boolean>(false);
  const location = useLocation();
  const { message } = App.useApp();
  const onAddPhoneNumber = async (value: { phoneNumber: string }) => {
    if (!location.state) return;
    try {
      setAddPhoneLoading(true);
      const { NewAgentContact } = new BaseInfoService();
      const reqBody: INewAgentContact = {
        type: NewAgentContactEnum.Phone,
        value: value.phoneNumber,
      };
      const response = await NewAgentContact(
        (location.state as IAllAgents).id,
        reqBody
      );
      if (response && response.status === 200) {
        message.success("phone number successfully added");
        phoneForm?.resetFields();
        getAgentPhoneOrEmailData(NewAgentContactEnum.Phone);
      }
    } catch (err) {
    } finally {
      setAddPhoneLoading(false);
    }
  };
  const onAddEmail = async (value: { email: string }) => {
    if (!location.state) return;
    try {
      setAddEmailLoading(true);
      const { NewAgentContact } = new BaseInfoService();
      const reqBody: INewAgentContact = {
        type: NewAgentContactEnum.Email,
        value: value.email,
      };
      const response = await NewAgentContact(
        (location.state as IAllAgents).id,
        reqBody
      );
      if (response && response.status === 200) {
        message.success("email successfully added");
        emailForm?.resetFields();
        getAgentPhoneOrEmailData(NewAgentContactEnum.Email);
      }
    } catch (err) {
    } finally {
      setAddEmailLoading(false);
    }
  };
  const deletePhone = async (id: number) => {
    try {
      const { DeleteAgentContact } = new BaseInfoService();
      const response = await DeleteAgentContact(id);
      if (response && response.status === 200) {
        message.success("Successfully deleted");
        getAgentPhoneOrEmailData(NewAgentContactEnum.Phone);
      }
    } catch (err) {}
  };
  const deleteEmail = async (id: number) => {
    try {
      const { DeleteAgentContact } = new BaseInfoService();
      const response = await DeleteAgentContact(id);
      if (response && response.status === 200) {
        message.success("Successfully deleted");
        getAgentPhoneOrEmailData(NewAgentContactEnum.Email);
      }
    } catch (err) {}
  };
  const columnsPhoneEdit: ColumnsType<IAllAgentContact> = [
    {
      title: "#",
      key: "id",
      align: "center",
      width: "56px",
      render: (name, record, index) => index + 1,
    },
    {
      title: "Phone",
      dataIndex: "value",
      key: "phoneNumber",
      align: "center",
      width: "270px",
    },
    {
      title: "Tools",
      key: "tools",
      align: "center",
      width: "187px",
      render: (text, record) => (
        <div className="flex justify-center items-center gap-[16px] text-[16px]">
          <Popconfirm
            title="Delete phone number"
            onConfirm={async () => {
              await deletePhone(record.id);
            }}
            description="Do you want to delete this?"
          >
            <span className="material-icons !text-[#EC2D30] text-[16px] cursor-pointer">
              delete_forever
            </span>
          </Popconfirm>
        </div>
      ),
    },
  ];

  const columnsEmailEdit: ColumnsType<IAllAgentContact> = [
    {
      title: "#",
      key: "id",
      align: "center",
      width: "56px",
      render: (name, record, index) => index + 1,
    },
    {
      title: "Email",
      dataIndex: "value",
      key: "email",
      align: "center",
      width: "270px",
    },
    {
      title: "Tools",
      key: "tools",
      align: "center",
      width: "187px",
      render: (text, record) => (
        <div className="flex justify-center items-center gap-[16px] text-[16px]">
          {/* <span className="material-icons !text-[#0C9D61] text-[16px] cursor-pointer">
            edit_note
          </span> */}
          <Popconfirm
            title="Delete phone number"
            onConfirm={async () => {
              await deleteEmail(record.id);
            }}
            description="Do you want to delete this?"
          >
            <span className="material-icons !text-[#EC2D30] text-[16px] cursor-pointer">
              delete_forever
            </span>
          </Popconfirm>
        </div>
      ),
    },
  ];

  const getAgentPhoneOrEmailData = useCallback(
    async (type: NewAgentContactType) => {
      if (!location.state) return;
      try {
        setLoading((prev) => ({
          ...prev,
          [type === NewAgentContactEnum.Phone ? "phone" : "email"]: true,
        }));
        const { AllAgentContact } = new BaseInfoService();
        const result = await AllAgentContact(
          (location.state as IAllAgents).id,
          type
        );
        if (result && result.data) {
          if (type === NewAgentContactEnum.Phone) {
            setPhoneData(result.data);
          } else {
            setEmailData(result.data);
          }
        }
      } catch (err) {
        throw console.log(err);
      } finally {
        setLoading((prev) => ({
          ...prev,
          [type === NewAgentContactEnum.Phone ? "phone" : "email"]: false,
        }));
      }
    },
    [location.state]
  );
  useEffect(() => {
    getAgentPhoneOrEmailData(NewAgentContactEnum.Phone);
    getAgentPhoneOrEmailData(NewAgentContactEnum.Email);
  }, [getAgentPhoneOrEmailData]);
  return (
    <>
      <div className="text-[16px] text-[#001377] font-medium my-[40px]">
        You can add or delete items.
      </div>
      <Row>
        <Col span={12} className="p-[24px]">
          <div className="text-[16px] text-[#001377] font-medium py-[16px]">
            <p>Add or delete phone number.</p>
          </div>
          <Form form={phoneForm} onFinish={onAddPhoneNumber}>
            <div className="middle">
              <FormItem
                name="phoneNumber"
                label="Phon number"
                rules={[{ required: true }]}
                required={true}
                className="w-full"
              >
                <SInput placeholder="Insert" />
              </FormItem>
              <Button
                htmlType="submit"
                loading={addPhoneLoading}
                className="w-[100px] mb-[23px] br"
                type="link"
              >
                Add
              </Button>
            </div>
          </Form>
          <TableUI
            columns={columnsPhoneEdit}
            dataSource={phoneData}
            loading={loading.phone}
            pagination={false}
            rowKey={getRowKey}
          />
        </Col>
        <Col span={12} className="p-[24px]">
          <div className="text-[16px] text-[#001377] font-medium py-[16px]">
            <p>Add or delete email.</p>
          </div>
          <Form form={emailForm} onFinish={onAddEmail}>
            <div className="middle">
              <FormItem
                name="email"
                label="Email"
                rules={[{ required: true }]}
                required={true}
                className="w-full"
              >
                <SInput placeholder="Insert" />
              </FormItem>
              <Button
                htmlType="submit"
                loading={addEmailLoading}
                className="w-[100px] mb-[23px] br"
                type="link"
              >
                Add
              </Button>
            </div>
          </Form>
          <TableUI
            columns={columnsEmailEdit}
            dataSource={emailData}
            loading={loading.email}
            pagination={false}
            rowKey={getRowKey}
          />
        </Col>
      </Row>
    </>
  );
};
