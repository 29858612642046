import { Button, Modal } from "antd";
import { FC } from "react";
import { useEditZipFileContext } from "src/modules/editZipFileInformation/context";
import { DeletePopupModalContainer } from "src/modules/editZipFileInformation/styles/deletePopupModal";

interface IProps {
  onConfirmDelete: () => void;
}
export const DeletePopupModal: FC<IProps> = ({ onConfirmDelete }) => {
  const {
    value: { showDeleteModal, currentRequest },
    dispatch: { setShowDeleteModal, setIsModalOpen, setIsModalOpenItems },
  } = useEditZipFileContext()!;

  return (
    <Modal
      width={"490px"}
      centered
      open={showDeleteModal}
      closable={false}
      maskClosable={false}
      onCancel={() => {
        setShowDeleteModal(false);
      }}
      title={currentRequest?.uniqueCode}
      styles={{ body: { height: "148px" } }}
      footer={
        <div className="flex gap-[16px] justify-center">
          <Button
            onClick={() => {
              setShowDeleteModal(false);
              setIsModalOpen(true);
              setIsModalOpenItems(true);
            }}
            htmlType="button"
            icon={<span className="material-icons"> close</span>}
            className="flex justify-center items-center rounded-[16px] "
          >
            Cancel
          </Button>
          <Button
            type="primary"
            danger
            htmlType="button"
            icon={
              <span className="material-symbols-outlined">delete_forever</span>
            }
            className="flex justify-center items-center rounded-[16px] "
            onClick={() => {
              setShowDeleteModal(false);
              setIsModalOpen(true);
              setIsModalOpenItems(true);
              onConfirmDelete();
            }}
          >
            Delete
          </Button>
        </div>
      }
    >
      <DeletePopupModalContainer className="flex justify-center my-[32px]">
        <div className="p-[47px] content">
          Are you sure you want to delete this file?
        </div>
      </DeletePopupModalContainer>
    </Modal>
  );
};
