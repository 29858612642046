import { Button, Col, Form } from "antd";
import { FC } from "react";
import { InvoiceArticleContainer } from "../../styles";
import { CollapseHandler } from "../Collapse";
import { KindInvoice } from "../kindInvoice";
import { General } from "../General";
import { AllRequest } from "../Allrequest";
import { Attachment } from "../Attachment";
import { Articles } from "../Articles";
import { useInvoiceReport } from "../../context";

export const InvoiceFilters: FC = () => {
  const {
    func: { setFilter },
    value: { invoiceReportForm },
  } = useInvoiceReport();

  return (
    <Form form={invoiceReportForm} onFinish={setFilter}>
      <InvoiceArticleContainer>
        <Col span={24}>
          <CollapseHandler key="General" icon="sailing" title="General">
            <General />
          </CollapseHandler>
          <CollapseHandler
            key="All request (zip file)"
            icon="sailing"
            title="All request (zip file)"
          >
            <AllRequest />
          </CollapseHandler>
          <CollapseHandler
            key="Attachment"
            icon="description"
            title="Attachment"
          >
            <Attachment />
          </CollapseHandler>
          <CollapseHandler
            key="Invoice"
            icon="production_quantity_limits"
            title="Invoice"
          >
            <KindInvoice />
          </CollapseHandler>

          <CollapseHandler key="Article" icon="calendar_month" title="Article">
            <Articles />
          </CollapseHandler>
        </Col>
        <Col span={24} className="footer">
          <Button
            onClick={() => invoiceReportForm?.resetFields()}
            className="text-[#93C8FF] bg-[#fff] round-[#93C8FF] border-[#93C8FF]"
          >
            Erase Filter
          </Button>
          <Button type="primary" onClick={invoiceReportForm?.submit}>
            Set Filter
          </Button>
        </Col>
      </InvoiceArticleContainer>
    </Form>
  );
};
