import { Rule } from "antd/es/form";

export const digitRule: Rule = {
  validator: (_, value) => {
    const newValue = value.toString().replaceAll(".", "");
    if (!/\D/.test(newValue)) {
      return Promise.resolve();
    } else {
      return Promise.reject(new Error("enter digits"));
    }
  },
};
