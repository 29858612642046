import { FC, useState } from "react";
import {
  Button,
  Divider,
  Flex,
  Form,
  Modal,
  Progress,
  Spin,
  Upload,
  message,
} from "antd";
import { UploadChangeParam } from "antd/lib/upload";
import { UploadFile, RcFile } from "antd/es/upload";
import { LoadingOutlined } from "@ant-design/icons";
import { UploadRequestOption } from "rc-upload/lib/interface";
import type { UploadProps } from "antd";
import { SInput } from "src/components/UiKit/Input";
import { IObject } from "src/models/interfaces";
import { TextAreaUiKit } from "src/components/UiKit/TextArea";
import { mainPath, useDocumentation } from "../../context";
import { ModalFormWrapper } from "../../styles";
import { IModalProps } from "./interface";
import { getBase64 } from "src/helpers/getBase64";
import { getFileExtension } from "src/helpers/getFileExtension";

const dummyRequest: (options: UploadRequestOption) => void = ({
  file,
  onSuccess,
}) => {
  setTimeout(() => {
    if (onSuccess) {
      onSuccess("done");
      return file;
    }
  }, 0);
};

const CreateFileModal: FC<IModalProps> = ({ open, onCancel }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [imageLoading, setImageLoading] = useState<boolean>(false);
  const [imageUrl, setImageUrl] = useState<string>("");

  const [createFileForm] = Form.useForm();

  const name = Form.useWatch("name", createFileForm);

  const {
    value: { path, fileList, uploadProgress },
    dispatch: { setFileList },
    func: { newFileReq },
  } = useDocumentation();

  const parentName = () => {
    if (path === mainPath) return path;
    const pathArray = path.split("/");
    return pathArray.slice(-1);
  };

  const Title = () => {
    return (
      <>
        <Flex vertical>
          <span className="text-[#001377] text-[18px] font-medium">
            Attach document
          </span>
          <span>
            <span className="text-[#001377] text-[12px] font-medium">in</span>{" "}
            <span className="text-[#5D6881] text-[12px] font-normal">
              {parentName()}
            </span>
          </span>
        </Flex>
        <Divider className="p-0" />
      </>
    );
  };

  const handleFinishCreateFolder = (values: IObject) => {
    if (fileList.length === 0) return message.error("Select file is required.");
    setLoading(true);
    newFileReq(values)
      .then((res) => {
        if (res) {
          closeModal();
        }
      })
      .finally(() => setLoading(false));
  };

  const uploadButton = (
    <div>
      {imageLoading ? (
        <>
          <LoadingOutlined color="#A3AED0" />
        </>
      ) : (
        <>
          <span className="material-icons text-[#A3AED0] text-[20px]">
            attach_file
          </span>
        </>
      )}
      <div className="text-[#A3AED0] text-[10px]">Attach</div>
    </div>
  );

  const handleChange: UploadProps["onChange"] = (
    info: UploadChangeParam<UploadFile>
  ) => {
    setFileList([info.file]);
    setImageLoading(true);
    if (info.file.status === "uploading") {
      return;
    }

    if (info.file.status === "done") {
      if (fileList[0].type?.split("/")[0] === "image") {
        getBase64(info.file.originFileObj as RcFile, (url) => {
          setImageLoading(false);
          setImageUrl(url);
        });
      } else {
        setImageUrl("");
        setImageLoading(false);
      }
    }
    if (info.file.status === "error") {
      setImageLoading(false);
    }
  };

  const beforeUpload = (file: RcFile) => {};

  const props: UploadProps = {
    showUploadList: {
      showPreviewIcon: false,
      showRemoveIcon: false,
    },
  };
  const closeModal = () => {
    setFileList([]);
    createFileForm.setFieldsValue({
      name: null,
      comment: null,
    });
    onCancel();
  };
  const previewFile = (type: string) => {
    let prevSrc = "/assets/images/file.svg";
    const getExtension = getFileExtension(type);
    if (getExtension !== "") {
      prevSrc = `/assets/images/filePreviewIcon/${getExtension.slice(1)}.svg`;
    }
    return prevSrc;
  };

  return (
    <Modal
      open={open}
      closable={false}
      onCancel={closeModal}
      title={<Title />}
      footer={false}
    >
      <ModalFormWrapper>
        <Form
          form={createFileForm}
          onFinish={handleFinishCreateFolder}
          layout="vertical"
          className="w-full flex flex-col justify-center items-center"
        >
          <div className="w-full p-[32px] mt-6 flex flex-col justify-center items-center border border-solid border-[#DADEEC] rounded-xl">
            <p className="text-[#001377] text-[16px] font-semibold">
              Please attach document & insert its information.
            </p>
            <div className="mt-3 w-full create-file-name relative">
              <Upload
                {...props}
                name="zipAttachment"
                listType="picture-card"
                className="flex justify-center"
                showUploadList={false}
                beforeUpload={beforeUpload}
                onChange={handleChange}
                customRequest={dummyRequest}
                maxCount={1}
                fileList={fileList}
              >
                {imageLoading ? (
                  <Spin size="large" />
                ) : imageUrl !== "" ? (
                  <img src={imageUrl} alt="file" width="90%" height="90%" />
                ) : fileList.length > 0 && imageUrl === "" ? (
                  <img
                    src={previewFile(fileList[0]?.type || "")}
                    alt="file"
                    width="90%"
                    height="90%"
                  />
                ) : (
                  uploadButton
                )}
              </Upload>

              <Form.Item
                name="name"
                label={
                  <span className="text-[#001377] text-left text-[14px] font-medium ml-3">
                    Document name
                  </span>
                }
                rules={[{ required: true }]}
                className="ml-1 w-full"
              >
                <SInput placeholder="Insert" />
              </Form.Item>
              {!!loading && (
                <Progress
                  percent={uploadProgress}
                  className="absolute bottom-0 w-[95%]"
                />
              )}
            </div>
            <Form.Item
              name="comment"
              label={
                <span className="text-[#001377] text-left text-[14px] font-medium ml-3">
                  Description
                </span>
              }
              colon={false}
              className="w-full mt-4"
            >
              <TextAreaUiKit
                rows={10}
                placeholder="Description"
              ></TextAreaUiKit>
            </Form.Item>
          </div>

          <div className="mt-8 flex items-center justify-center">
            <Button
              className="mr-3 bg-[#F5F5F5] hover:!border-[#F5F5F5] text-[#8E8E8E] hover:!text-[#8E8E8E] flex items-center"
              onClick={closeModal}
              icon={<span className="material-icons text-[14px]">cancel</span>}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              className="text-[#fff] bg-[#2C73FC] hover:!text-[#fff] hover:!bg-[#2C73FC] border-[#2C73FC] flex items-center"
              icon={
                <span className="material-icons text-[14px]">attach_file</span>
              }
              disabled={fileList.length === 0 || !name}
            >
              Attach
            </Button>
          </div>
        </Form>
      </ModalFormWrapper>
    </Modal>
  );
};

export default CreateFileModal;
