import { FC } from "react";
import { Link, useLocation } from "react-router-dom";
import { Col, Menu, MenuProps } from "antd";
import { OwnerDashboardContentContainer } from "./styles";
import { getItem } from "src/templates/mainLayout/helper/createItem";
import { ROUTES } from "src/models/enums";

type MenuItem = Required<MenuProps>["items"][number];

export const OwnerDashboardContent: FC = () => {
  let location = useLocation();
  const currentPath = location.pathname;

  return (
    <OwnerDashboardContentContainer>
      <Col className="content" span={24}>
        <Menu
          defaultSelectedKeys={["dashboard"]}
          mode="inline"
          items={items}
          className="menu"
          selectedKeys={[currentPath]}
        />
      </Col>
    </OwnerDashboardContentContainer>
  );
};

const items: MenuItem[] = [
  getItem(
    <Link to={ROUTES.home}>{"Dashboard"}</Link>,
    ROUTES.home,
    <span className="material-icons !text-[#a3aed0] !text-[24px]	">
      dashboard
    </span>
  ),

  getItem(
    <Link to={ROUTES.ShipDailyReportOwner}> {"Ship Daily Report"}</Link>,
    ROUTES.ShipDailyReportOwner,
    <span className="material-icons !text-[#a3aed0] !text-[24px]">
      file_copy
    </span>
  ),
];
