import { Form, UploadFile, message } from "antd";
import { FormInstance } from "antd/lib";
import {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useState,
} from "react";
import { IAddNewShip, IAddShipsTabs } from "../models";
import { IPublicCurrency, IShip } from "src/services/Public/models";
import { PublicService } from "src/services/Public/Public.service";
import { ShipService } from "src/services/Ship/Ship.service";
import {
  IGetShipManagement,
  IGetShipManagmentContract,
} from "src/services/Ship/models";
import { IObject } from "src/models/interfaces";
import Dayjs from "dayjs";
import { SystemService } from "src/services/System/System.service";
import { IGetCountry } from "src/services/System/models";
import { useCheckPermission } from "src/hook/checkRoutePermission";
import { AddShipsPath } from "src/components/Guard/permissions";

interface IContext {
  value: {
    activeKeyAddNewShip: string;
    activeKeyAddShip: string;
    isEditMode: boolean;
    isShowMode: boolean;
    activeKeyEditShip: string;
    activeKeyShowShip: string;
    pictureFileList: UploadFile[];
    allCurrency: IPublicCurrency[];
    shipId: number;
    shipManagement: IGetShipManagement[] | undefined;
    tableCurrentValue: IShip | undefined;
    newData: ICustomShipData | undefined;
    picLoading: boolean;
    tableData: IShip[] | undefined;
    tableLoading: boolean;
    shipManagementInfo: IGetShipManagmentContract | undefined;
    isAddCountryModalOpen: boolean;
    isAddCurrencyModalOpen: boolean;
    shipManagementLoading: boolean;
    countryList: IGetCountry[];
    currencyLoading: boolean;
  };
  dispatch: {
    setActiveKeyAddNewShip: Dispatch<SetStateAction<string>>;
    setActiveKeyAddShip: Dispatch<SetStateAction<string>>;
    setActiveKeyEditShip: Dispatch<SetStateAction<string>>;
    setActiveKeyShowShip: Dispatch<SetStateAction<string>>;
    setIsEditMOde: Dispatch<SetStateAction<boolean>>;
    setIsShowMode: Dispatch<SetStateAction<boolean>>;
    setPictureFileList: Dispatch<SetStateAction<UploadFile[]>>;
    setShipId: Dispatch<SetStateAction<number>>;
    setTableCurrentValue: Dispatch<SetStateAction<IShip | undefined>>;
    setIsAddCountryModalOpen: Dispatch<SetStateAction<boolean>>;
    setIsAddCurrencyModalOpen: Dispatch<SetStateAction<boolean>>;
  };
  func: {
    fetchAllCurrency: () => void;
    fetchShipManagement: () => void;
    createData: () => void;
    resetForms: () => void;
    fetchImage: () => void;
    fetchShipInformation: () => void;
    fetchShipManagementContract: () => void;
    deleteShip: (id: number) => void;
    getCountry: () => void;
  };
  forms: {
    BasicInformationForm: FormInstance<any> | undefined;
    ShipsPictureForm: FormInstance<any> | undefined;
    InsurancesForm: FormInstance<any> | undefined;
    OwnerForm: FormInstance<any> | undefined;
    TechnicalInformationForm: FormInstance<any> | undefined;
    DocumentationFileForm: FormInstance<any> | undefined;
    ShipPriceForm: FormInstance<any> | undefined;
    ShipManagementForm: FormInstance<any> | undefined;
  };
}
interface ICustomShipData
  extends Omit<
    IShip,
    "created" | "deliveryDate" | "purchaseDate" | "priceDate"
  > {
  created: Dayjs.Dayjs;
  deliveryDate: Dayjs.Dayjs;
  purchaseDate: Dayjs.Dayjs;
  priceDate: Dayjs.Dayjs;
}
export const AddShipContext = createContext<IContext | undefined>(undefined);
export const AddShipProvider: FC<PropsWithChildren> = ({ children }) => {
  //----------------------------------------------------------------Form----------------------------------------------------------------
  const [BasicInformationForm] = Form.useForm();
  const [ShipsPictureForm] = Form.useForm();
  const [InsurancesForm] = Form.useForm();
  const [OwnerForm] = Form.useForm();
  const [ShipManagementForm] = Form.useForm();
  const [ShipPriceForm] = Form.useForm();
  const [TechnicalInformationForm] = Form.useForm();
  const [DocumentationFileForm] = Form.useForm();
  const { checkRoutePermission } = useCheckPermission();
  const [activeKeyAddNewShip, setActiveKeyAddNewShip] = useState<string>(
    checkRoutePermission(AddShipsPath)
      ? IAddShipsTabs.addNewShip
      : IAddShipsTabs.shipsTable
  );
  const [activeKeyAddShip, setActiveKeyAddShip] = useState<string>(
    IAddNewShip.basicInformation
  );
  const [activeKeyEditShip, setActiveKeyEditShip] = useState<string>(
    IAddNewShip.basicInformation
  );
  const [activeKeyShowShip, setActiveKeyShowShip] = useState<string>(
    IAddNewShip.basicInformation
  );
  const [isEditMode, setIsEditMOde] = useState<boolean>(false);
  const [isShowMode, setIsShowMode] = useState<boolean>(false);
  const [pictureFileList, setPictureFileList] = useState<UploadFile[]>([]);
  const [allCurrency, setAllCurrency] = useState<IPublicCurrency[]>([]);
  const [shipId, setShipId] = useState<number>(-1);
  const [shipManagement, setShipManagement] = useState<
    IGetShipManagement[] | undefined
  >();
  const [tableCurrentValue, setTableCurrentValue] = useState<
    IShip | undefined
  >();
  const [newData, setNewData] = useState<ICustomShipData>();
  const [picLoading, setPicLoading] = useState<boolean>(false);
  const [tableLoading, setTableLoading] = useState<boolean>(false);
  const [tableData, setTableData] = useState<IShip[]>([]);
  const [shipManagementInfo, setShipManagementInfo] =
    useState<IGetShipManagmentContract>();
  const [isAddCountryModalOpen, setIsAddCountryModalOpen] =
    useState<boolean>(false);
  const [isAddCurrencyModalOpen, setIsAddCurrencyModalOpen] =
    useState<boolean>(false);

  //----------------------------------------------------------------func----------------------------------------------------------------
  const [currencyLoading, setCurrencyLoading] = useState<boolean>(false);
  const fetchAllCurrency = useCallback(async () => {
    setCurrencyLoading(true);
    try {
      const { AllCurrency } = new PublicService();
      const res = await AllCurrency();
      if (res && res.status === 200 && res.data) {
        setAllCurrency(res.data);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setCurrencyLoading(false);
    }
  }, []);
  const fetchShipManagement = async () => {
    try {
      const { GetShipManagement } = new ShipService();
      const res = await GetShipManagement();
      if (res && res.status === 200 && res.data) {
        setShipManagement(res.data);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const createData = useCallback(() => {
    const newD: IObject = { ...tableCurrentValue };
    newD.created = tableCurrentValue?.created
      ? Dayjs(tableCurrentValue?.created)
      : undefined;
    newD.purchaseDate = tableCurrentValue?.purchaseDate
      ? Dayjs(tableCurrentValue?.purchaseDate)
      : undefined;
    newD.deliveryDate = tableCurrentValue?.deliveryDate
      ? Dayjs(tableCurrentValue?.deliveryDate)
      : undefined;
    newD.yearBuilt = tableCurrentValue?.yearBuilt
      ? Dayjs().year(tableCurrentValue.yearBuilt)
      : undefined;
    newD.priceDate = tableCurrentValue?.priceDate
      ? Dayjs(tableCurrentValue.priceDate)
      : null;
    setNewData(newD as ICustomShipData);
    BasicInformationForm?.setFieldsValue(newD);
    TechnicalInformationForm?.setFieldsValue(newD);
    OwnerForm?.setFieldsValue(newD);
    ShipPriceForm?.setFieldsValue(newD);
  }, [
    BasicInformationForm,
    OwnerForm,
    ShipPriceForm,
    TechnicalInformationForm,
    tableCurrentValue,
  ]);

  const fetchImage = useCallback(async () => {
    setPicLoading(true);
    if (!tableCurrentValue) return;
    try {
      const { Image } = new ShipService();
      const response = await Image(tableCurrentValue.id);
      if (response) {
        setPictureFileList([{ name: "image", uid: "-1", url: response }]);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setPicLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableCurrentValue]);

  const fetchShipInformation = async () => {
    setTableLoading(true);
    const { Ship } = new PublicService();
    try {
      const response = await Ship();
      if (response && response.status === 200 && response.data) {
        setTableData(response.data);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setTableLoading(false);
    }
  };

  const [shipManagementLoading, setShipManagementLoading] =
    useState<boolean>(false);

  const fetchShipManagementContract = useCallback(async () => {
    if (!tableCurrentValue) return;
    setShipManagementLoading(true);
    try {
      const { GetShipManagmentContract } = new ShipService();
      const response = await GetShipManagmentContract(tableCurrentValue.id);
      if (
        response &&
        response.status === 200 &&
        response.data &&
        response.data.length > 0
      ) {
        setShipManagementInfo(response.data[0]);
        let newData = response.data[0];
        newData.insuranceDate = [
          Dayjs(newData.startDate),
          Dayjs(newData.endDate),
        ];
        ShipManagementForm.setFieldsValue(newData);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setShipManagementLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableCurrentValue]);

  const resetForms = () => {
    BasicInformationForm.resetFields();
    TechnicalInformationForm.resetFields();
    OwnerForm.resetFields();
    ShipManagementForm.resetFields();
    ShipPriceForm.resetFields();
    InsurancesForm.resetFields();
    setPictureFileList([]);
  };

  const deleteShip = async (id: number) => {
    try {
      const { DeleteShip } = new ShipService();
      const response = await DeleteShip(id);
      if (response && response.status === 200) {
        message.success("delete ship successfully");
        resetForms();
        setActiveKeyAddShip(IAddNewShip.basicInformation);
        fetchShipInformation();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const [countryList, setCountryList] = useState<IGetCountry[]>([]);
  const getCountry = useCallback(async () => {
    try {
      const { GetCountry } = new SystemService();
      const response = await GetCountry();
      if (response && response.status === 200) {
        setCountryList(response.data);
      }
    } catch (err) {
      console.log(err);
    } finally {
    }
  }, []);
  const contextValue: IContext = {
    value: {
      activeKeyAddShip,
      isEditMode,
      activeKeyEditShip,
      isShowMode,
      activeKeyShowShip,
      activeKeyAddNewShip,
      pictureFileList,
      allCurrency,
      shipId,
      shipManagement,
      tableCurrentValue,
      newData,
      picLoading,
      tableData,
      tableLoading,
      shipManagementInfo,
      isAddCountryModalOpen,
      isAddCurrencyModalOpen,
      shipManagementLoading,
      countryList,
      currencyLoading,
    },
    dispatch: {
      setActiveKeyAddShip,
      setIsEditMOde,
      setActiveKeyEditShip,
      setIsShowMode,
      setActiveKeyShowShip,
      setActiveKeyAddNewShip,
      setPictureFileList,
      setShipId,
      setTableCurrentValue,
      setIsAddCountryModalOpen,
      setIsAddCurrencyModalOpen,
    },
    func: {
      fetchAllCurrency,
      fetchShipManagement,
      createData,
      resetForms,
      fetchImage,
      fetchShipInformation,
      fetchShipManagementContract,
      deleteShip,
      getCountry,
    },
    forms: {
      BasicInformationForm,
      InsurancesForm,
      OwnerForm,
      TechnicalInformationForm,
      DocumentationFileForm,
      ShipsPictureForm,
      ShipPriceForm,
      ShipManagementForm,
    },
  };
  return (
    <AddShipContext.Provider value={contextValue}>
      {children}
    </AddShipContext.Provider>
  );
};
export const useAddShip = () => useContext(AddShipContext);
