import { Row } from "antd";
import styled from "styled-components";

export const LOROBConsInfoStyle = styled(Row)`
  .title {
    color: var(--text, #001377);
    font-family: DM Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .inputTitle {
    color: var(--text, #001377);
    font-family: DM Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .textarea {
    border-radius: 12px;
    background: #f4f7fe;
    border-color: #ffffff;
  }
`;
