import { Col, Row, Skeleton } from "antd";
import { FC } from "react";
import { ShowBasicInformationContainer } from "../style";
import { ShipInformationItems } from "./shipInformationItem";
import { useAddShip } from "src/modules/AddShips/context";
import moment from "moment";

export const ShowShipPrice: FC = () => {
  const {
    value: { tableCurrentValue, pictureFileList, picLoading },
  } = useAddShip()!;
  return (
    <ShowBasicInformationContainer>
      <Col span={24} className="pictureHolder">
        {picLoading ? (
          <Skeleton.Image active className="!w-[334px] !h-[165px]" />
        ) : (
          pictureFileList.map((item, index) => (
            <img
              src={item.url}
              key={index}
              width={334}
              height={165}
              alt={item.name}
              className="rounded-[16px]"
            />
          ))
        )}
      </Col>
      <Col span={24}>
        <Row gutter={24}>
          <Col span={12}>
            <div className="titleHolder">
              ship price information at thr time of purchase
            </div>
            <div className="dataHolder">
              <ShipInformationItems
                name="Ship price"
                value={tableCurrentValue?.price.toLocaleString("en-US")}
              />
              <ShipInformationItems
                name="Currency"
                value={tableCurrentValue?.currency}
              />
            </div>
          </Col>
          <Col span={12}>
            <div className="titleHolder">current ship price information</div>
            <div className="dataHolder">
              <ShipInformationItems
                name="Date"
                value={
                  tableCurrentValue?.priceDate
                    ? moment(tableCurrentValue?.priceDate).format("YYYY-MM-DD")
                    : ""
                }
              />
              <ShipInformationItems
                name="Ship price in date"
                value={tableCurrentValue?.datePrice.toLocaleString("en-US")}
              />
            </div>
          </Col>
        </Row>
      </Col>
    </ShowBasicInformationContainer>
  );
};
