import { IPath } from "../Identity/models";

export const OperationGuardPath: IPath[] = [
  { path: "api/Operation/NewVoyage", httpMethod: "POST" },
  { path: "api/Operation/AllVoyage", httpMethod: "GET" },
];

export const NewVoyagePath: IPath = {
  path: "api/Operation/NewVoyage",
  httpMethod: "POST",
};
export const AllVoyagePath: IPath = {
  path: "api/Operation/AllVoyage",
  httpMethod: "GET",
};
export const EditVoyagePath: IPath = {
  path: "api/Operation/{voyageId}/EditVoyage",
  httpMethod: "PUT",
};
export const DeleteVoyagePath: IPath = {
  path: "api/Operation/{voyageId}/DeleteVoyage",
  httpMethod: "DELETE",
};
