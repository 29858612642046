import { Tooltip } from "antd";
import { FC, useEffect, useState } from "react";
import { useAddShip } from "../../context";
import { IAddNewShip } from "../../models";
import { ColumnsType } from "antd/es/table";
import { TableUI } from "src/components/UiKit/table";
import { getRowKey } from "src/helpers/getRowKey";
import { ShipTableContainer } from "./style";
import { SPopconfirm } from "./popconfirm";
import { IShip } from "src/services/Public/models";
import { Guard } from "src/components/Guard";
import {
  DeleteShipPath,
  GetShipInsuranceInformationPath,
  GetShipManagmentContractPath,
} from "src/services/Ship/guardPath";
import { EditShipsPath } from "src/components/Guard/permissions";
import { IPagination } from "src/models/interfaces/pagination";

export const ShipTable: FC = () => {
  const {
    dispatch: { setTableCurrentValue },
    func: { fetchShipInformation, deleteShip },
    value: { tableData, tableLoading },
  } = useAddShip()!;
  const [pagination, setPagination] = useState<IPagination>({
    Limit: 10,
    Offset: 1,
    total: tableData?.length,
  });
  const {
    dispatch: {
      setIsEditMOde,
      setActiveKeyEditShip,
      setIsShowMode,
      setActiveKeyShowShip,
    },
  } = useAddShip()!;
  const changeEditMode = (record: IShip) => {
    setIsEditMOde(true);
    setActiveKeyEditShip(IAddNewShip.basicInformation);
    setTableCurrentValue(record);
  };
  const changeShowMode = (record: IShip) => {
    setIsShowMode(true);
    setTableCurrentValue(record);
    setActiveKeyEditShip(IAddNewShip.basicInformation);
    setActiveKeyShowShip(IAddNewShip.basicInformation);
  };

  useEffect(() => {
    fetchShipInformation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //--------------------------------------Table Columns-------------------------------------------
  const columns: ColumnsType<IShip> = [
    {
      title: "#",
      dataIndex: "#",
      key: "index",
      width: 61,
      align: "center",
      fixed: "left",
      render: (name: string, record: any, index: number) =>
        (pagination.Offset - 1) * pagination.Limit + (index + 1),
    },
    {
      title: "Current name",
      dataIndex: "name",
      key: "name",
      width: 105,
      align: "center",
      fixed: "left",
      className: "whitespace-nowrap",
    },
    {
      title: "Abbreviation",
      dataIndex: "abbreviation",
      key: "abbreviation",
      width: 105,
      align: "center",
      className: "whitespace-nowrap",
    },
    {
      title: "Cargo type",
      dataIndex: "cargoType",
      key: "cargoType",
      width: 96,
      align: "center",
      className: "whitespace-nowrap",
      render: (text) => {
        switch (text) {
          case 1:
            return "Bulker";
          case 2:
            return "Small dry";
          case 3:
            return "Tanker";
        }
      },
    },
    {
      title: "Size type",
      dataIndex: "sizeType",
      key: "sizeType",
      width: 96,
      align: "center",
      className: "whitespace-nowrap",
      render: (text) => {
        switch (text) {
          case 1:
            return "SMALL DIRTY TNKR";
          case 2:
            return "SMALL CLEAN TNKR";
          case 3:
            return "PANAMAX";
          case 4:
            return "VLCC";
          case 5:
            return "SUEZMAX TNKR";
          case 6:
            return "MR2 TNKR";
        }
      },
    },
    {
      title: "Ownership type",
      dataIndex: "ownerShipType",
      key: "ownerShipType",
      width: 118,
      align: "center",
      className: "whitespace-nowrap",
      render: (text) => {
        switch (text) {
          case 1:
            return "Own";
          case 2:
            return "Charterer";
        }
      },
    },
    {
      title: "Ship management",
      dataIndex: "shipManagement",
      key: "shipManagement",
      width: 130,
      align: "center",
      className: "whitespace-nowrap",
    },
    {
      title: "Owner’s/Charterer's company",
      dataIndex: "ownerCompany",
      key: "ownerCompany",
      width: 148,
      align: "center",
    },
    {
      title: "Owner’s/Charterer's name",
      dataIndex: "ownerName",
      key: "ownerName",
      width: 148,
      align: "center",
    },
    {
      title: "Country of registration ",
      dataIndex: "countryRegister",
      key: "countryRegister",
      width: 157,
      align: "center",
      className: "whitespace-nowrap",
    },

    {
      title: "Tools",
      dataIndex: "tools",
      key: "tools",
      width: 132,
      align: "center",
      fixed: "right",
      render: (text, record) => (
        <div className="flex gap-[16px] px-[24px] py-[16px]">
          <Guard
            action={[
              GetShipManagmentContractPath,
              GetShipInsuranceInformationPath,
            ]}
          >
            <Tooltip title={"View"} color="#5D6881" rootClassName="toolTip">
              <span
                className="material-icons text-[#5D6881] text-[16px] cursor-pointer"
                onClick={() => changeShowMode(record)}
              >
                remove_red_eye
              </span>
            </Tooltip>
          </Guard>
          <Guard action={EditShipsPath} multiPath>
            <Tooltip title={"Edit"} color="#5D6881" rootClassName="toolTip">
              <span
                className="material-icons text-[#2C73FC] text-[16px] cursor-pointer "
                onClick={() => changeEditMode(record)}
              >
                edit_note
              </span>
            </Tooltip>
          </Guard>
          <Guard action={DeleteShipPath}>
            <SPopconfirm onConfirm={() => deleteShip(record.id)}>
              <Tooltip title={"Delete"} color="#5D6881" rootClassName="toolTip">
                <span className="material-icons text-[#FF1414] text-[16px] cursor-pointer">
                  delete_forever
                </span>
              </Tooltip>
            </SPopconfirm>
          </Guard>
        </div>
      ),
    },
  ];

  return (
    <ShipTableContainer>
      <TableUI
        columns={columns}
        tableType="primary"
        dataSource={tableData}
        rowKey={getRowKey}
        pagination={{
          position: ["bottomCenter"],
          showSizeChanger: true,
          total: pagination.total,
          pageSize: pagination.Limit,
          current: pagination.Offset,
          onChange: (page: number, pageSize: number) => {
            setPagination((prev) => ({
              ...prev,
              Offset: pageSize !== prev.Limit ? 1 : page,
              Limit: pageSize,
            }));
          },
        }}
        scroll={{ x: 1058 }}
        loading={tableLoading}
      />
    </ShipTableContainer>
  );
};
