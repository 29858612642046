import { UseQueryResult } from "react-query";
import { IPublicCurrency, IRequester, IShip } from "src/services/Public/models";
import { IDataModel } from "src/services/models";
import { create } from "zustand";

interface IStore {
  ships: UseQueryResult<IShip[] | undefined> | undefined;
  setShips: (ships: UseQueryResult<IShip[] | undefined>) => void;
  requesters: UseQueryResult<IDataModel<IRequester[]> | undefined> | undefined;
  setRequesters: (
    requesters: UseQueryResult<IDataModel<IRequester[]> | undefined>
  ) => void;
  allCurrency: UseQueryResult<IPublicCurrency[] | undefined> | undefined;
  setAllCurrency: (
    requesters: UseQueryResult<IPublicCurrency[] | undefined>
  ) => void;
}

const usePublicApiStore = create<IStore>((set) => ({
  ships: undefined,
  setShips: (ships) => set({ ships }),
  requesters: undefined,
  setRequesters: (requesters) => set({ requesters }),
  allCurrency: undefined,
  setAllCurrency: (allCurrency) => set({ allCurrency }),
}));

export default usePublicApiStore;
