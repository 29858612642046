import { FC, useEffect, useState } from "react";
import { Button, Divider, Flex, Form, Modal } from "antd";
import { SInput } from "src/components/UiKit/Input";
import { IObject } from "src/models/interfaces";
import { IModalProps } from "./interface";
import { useDocumentation } from "../../context";
interface EditFolderFileModalProps extends IModalProps {
  type?: "folder" | "file";
}

const EditFolderFileModal: FC<EditFolderFileModalProps> = ({
  open,
  onCancel,
}) => {
  const [loading, setLoading] = useState<boolean>(false);

  const [editDocForm] = Form.useForm();

  const {
    value: { selectedDoc, path },
    func: { editDocumentReq },
  } = useDocumentation();

  useEffect(() => {
    editDocForm.setFieldsValue({ name: selectedDoc?.name });
  }, [editDocForm, selectedDoc]);

  const Title = () => {
    return (
      <>
        <>
          <Flex vertical>
            <span className="text-[#001377] text-[18px] font-medium">
              {`Rename ${selectedDoc?.isDirectory ? "Folder" : "Document"}`}
            </span>
            <span>
              <span className="text-[#001377] text-[12px] font-medium">
                {pathName()}
              </span>
              <Divider
                type="vertical"
                className="w-[2px] h-[19px] bg-[#DADEEC]"
              />
              <span className="text-[#5D6881] text-[12px] font-normal">
                {selectedDoc?.name.concat(
                  selectedDoc?.isDirectory ? "" : selectedDoc?.extention
                )}
              </span>
            </span>
          </Flex>
          <Divider className="p-0" />
        </>
      </>
    );
  };

  const handleFinishCreateFolder = (values: IObject) => {
    setLoading(true);
    values.comment = selectedDoc?.comment;
    editDocumentReq(values)
      .then((res) => {
        if (res) {
          onCancel();
        }
      })
      .finally(() => setLoading(false));
  };
  const pathName = () => {
    const pathArray = path.split("/");
    if (pathArray.length > 1) return pathArray[pathArray.length - 1];
    else return "Root";
  };

  return (
    <Modal
      open={open}
      closable={false}
      onCancel={onCancel}
      title={<Title />}
      footer={false}
    >
      <Form
        form={editDocForm}
        onFinish={handleFinishCreateFolder}
        className="w-full flex flex-col justify-center items-center"
      >
        <div className="w-[320px] mt-6 flex flex-col justify-center items-center">
          <p className="text-[#001377] text-left text-[14px] font-medium w-full">
            {`${selectedDoc?.isDirectory ? "Folder" : "Document"} name`}
          </p>
          <Form.Item
            name="name"
            rules={[{ required: true }]}
            className="w-full"
          >
            <SInput placeholder="Insert" />
          </Form.Item>
        </div>

        <div className="mt-4 w-full border-y border-solid border-[#F3F4F5]"></div>

        <div className="mt-4 flex items-center justify-center">
          <Button
            className="mr-3 bg-[#F5F5F5] hover:!border-[#F5F5F5] text-[#4B4B4B] hover:!text-[#4B4B4B]"
            onClick={(event) => {
              event.stopPropagation();
              onCancel();
            }}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            className="text-[#2C73FC] bg-[#fff] hover:!text-[#2C73FC] hover:!bg-[#fff] border-[#2C73FC]"
          >
            Save Edit
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default EditFolderFileModal;
