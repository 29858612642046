import { FC } from "react";
import { TableUI } from "src/components/UiKit/table";
import { ZipTableContainer } from "../../styles";
import { IZipFiles } from "./model";
import { PDFFiles } from "./pdf-files";
import { useAddInvoices } from "src/modules/addInvoice/context";
import { useTable } from "../../context";
import { checkActionPermission } from "src/helpers/checkPermissionFunc";

export const ZipFiles: FC<IZipFiles> = ({
  data,
  columns,
  onExpand,
  expandedActions,
}) => {
  const {
    dispatch: { setCurrentRequest },
  } = useAddInvoices()!;
  const {
    value: { totalZipFile, zipFileLoading, pagination },
    dispatches: { setPagination },
  } = useTable();
  return (
    <ZipTableContainer>
      <TableUI
        tableType="primary"
        loading={zipFileLoading}
        pagination={{
          position: ["bottomCenter"],
          total: totalZipFile,
          pageSize: pagination.Limit,
          current: pagination.total,
          onChange: (page, pageSize) => {
            setPagination((prev) => ({
              ...prev,
              Offset: prev.Limit !== pageSize ? 1 : page,
              Limit: pageSize,
            }));
          },
        }}
        rowClassName={(index, record) =>
          record % 2 !== 0 ? "bg-[#F2F7FF]" : ""
        }
        scroll={{ scrollToFirstRowOnChange: true, x: 1000 }}
        columns={columns}
        dataSource={data}
        expandable={{
          expandedRowRender: (record) => (
            <PDFFiles
              files={record?.files}
              // loading={expandLoading}
              record={record}
            />
          ),
          rowExpandable: (record) =>
            checkActionPermission(expandedActions) &&
            record.files !== undefined &&
            +record.fileNo > 0,
          onExpand: (expanded, record) => {
            if (expanded) {
              setCurrentRequest(record);
              onExpand && onExpand(record);
            }
          },
        }}
      />
    </ZipTableContainer>
  );
};
