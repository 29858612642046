import { FC, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { ViewDetailsContainer } from "../style";
import { Actions } from "./Actions";
import { Details } from "./details";

export const ViewDetails: FC = () => {
  const componentRef = useRef<HTMLDivElement>(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <ViewDetailsContainer>
      <Details ref={componentRef} />
      <Actions handleClick={handlePrint} />
    </ViewDetailsContainer>
  );
};
