import { FC, useCallback, useEffect, useState } from "react";
import { FiltersContainer } from "./style";
import { Col, Flex, Form } from "antd";
import { useInvoiceReport } from "../../context";
import { STag } from "src/components/UiKit/Tag";
import {
  invoiceReportFormItemsName,
  reportTypeKey,
} from "../../constant/flterFormNames";
import { IObject } from "src/models/interfaces";
import { INVOICE_REPORT_TYPES } from "../../models/reportTypes";

export const InvoiceReportFilters: FC = () => {
  const {
    value: {
      invoiceReportForm,
      reportType,
      defaultPagination,
      currentRequestValue,
      currentAllRequest,
      currentAttachment,
      currentInvoice,
    },
    dispatch: {
      setCurrentRequestValue,
      setAttachmentPagination,
      setArticlePagination,
      setInvoicePagination,
      setAllRequestPagination,
    },
    func: { fetchAllRequest, fetchArticle, fetchAttachment, fetchInvoice },
  } = useInvoiceReport();
  const [tags, setTags] = useState<string[]>([]);
  const formValues = Form.useWatch([], invoiceReportForm);
  const filters = useCallback(() => {
    if (!formValues) return;
    Object.keys(formValues).forEach((key) => {
      Object.keys(invoiceReportFormItemsName).forEach(
        // Object.keys((invoiceReportFormItemsName as IObject)[formName]).forEach(
        (itemName) => {
          if (itemName === reportTypeKey) {
            return;
          }
          if ((invoiceReportFormItemsName as IObject)[itemName] === key) {
            if (!!formValues[key]) {
              setTags((prev) => {
                if (!prev.includes(itemName)) {
                  return [...prev, itemName];
                } else {
                  return [...prev];
                }
              });
            } else {
              setTags((prev) => prev.filter((i) => i !== itemName));
            }
          }
        }
      );
      // });
    });
  }, [formValues]);

  const onCloseTag = (item: string) => {
    setTags((prev) => prev.filter((i) => i !== item));
    const newCurrentValue = { ...currentRequestValue };
    delete (newCurrentValue as IObject)[
      (invoiceReportFormItemsName as IObject)[item]
    ];
    console.log({ item });
    invoiceReportForm?.setFieldValue(
      (invoiceReportFormItemsName as IObject)[item],
      undefined
    );
    setCurrentRequestValue(newCurrentValue);
    switch (reportType) {
      case INVOICE_REPORT_TYPES.AllRequest:
        if (newCurrentValue) {
          setAllRequestPagination(defaultPagination);
          fetchAllRequest(newCurrentValue, defaultPagination);
        }
        break;
      case INVOICE_REPORT_TYPES.Attachment:
        if (newCurrentValue) {
          setAttachmentPagination(defaultPagination);
          fetchAttachment(
            newCurrentValue,
            currentAllRequest?.allRequestId,
            defaultPagination
          );
        }
        break;
      case INVOICE_REPORT_TYPES.Invoice:
        setInvoicePagination(defaultPagination);
        if (newCurrentValue) {
          fetchInvoice(
            newCurrentValue,
            currentAttachment?.attachmentId,
            defaultPagination
          );
        }
        break;
      case INVOICE_REPORT_TYPES.Article:
        setArticlePagination(defaultPagination);
        if (newCurrentValue) {
          fetchArticle(
            newCurrentValue,
            currentInvoice?.invoiceId,
            defaultPagination
          );
        }
        break;
    }
  };

  useEffect(() => {
    filters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues]);
  return (
    <FiltersContainer>
      <Col span={24}>
        <Flex className="p-[16px]" align="center" gap={12}>
          <div className="filterIconHolder">
            <span className="material-icons text-[24px] text-[#fff]">tune</span>
          </div>
          {tags.map((item, index) => (
            <STag
              title={item}
              onclose={() => {
                onCloseTag(item);
              }}
              key={index}
            />
          ))}
        </Flex>
      </Col>
    </FiltersContainer>
  );
};
