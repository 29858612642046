import { FC, useMemo } from "react";
import { AddNewProfitContainer } from "./style";
import { AddInformation } from "./components/AddInformation";
import { AddNewProfitStepEnum } from "../../models";
import { SelectStepContent } from "./components/SelectStepContent";
import { NewProfitContent } from "./style/content";
import { ProfitBasicInformation } from "./components/BasicInfomation";
import { ProfitTradeInformation } from "./components/TradeInformation";
import { ProfitShippingInformation } from "./components/ShippingInformation";
import { useProfitReport } from "../../context";
import { AttachFileBaseInfo } from "../attachFileBaseInfo";

export const AddNewProfit: FC = () => {
  const {
    value: { step, showAttachFile },
  } = useProfitReport();
  const content = useMemo(() => {
    switch (step) {
      case AddNewProfitStepEnum.basic:
        return <ProfitBasicInformation />;
      case AddNewProfitStepEnum.trade:
        return <ProfitTradeInformation />;
      case AddNewProfitStepEnum.shipping:
        return <ProfitShippingInformation />;
      default:
        return <SelectStepContent />;
    }
  }, [step]);
  return (
    <AddNewProfitContainer>
      <AddInformation />
      <NewProfitContent>
        {showAttachFile ? <AttachFileBaseInfo /> : content}
      </NewProfitContent>
    </AddNewProfitContainer>
  );
};
