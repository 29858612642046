import { FC, useState } from "react";
import { useShipDailyReport } from "../../context";
import { Flex, Row, Spin } from "antd";
import ReportCard from "../../../ShipDailyReports/components/reports/ReportCard";
// import ReportCard from "./ReportCard";

const Reports: FC = () => {
  const [openId, setOpenId] = useState<number>(0);

  const {
    value: { loading, reports },
    func: { changeDailyReportReadStatus, updateReports },
  } = useShipDailyReport();
  // const {
  //   func: { changeDailyReportReadStatus, updateReports },
  // } = useShipDailyReport();
  return (
    <Row justify={"center"} gutter={[0, 16]}>
      {loading ? (
        <Flex align="center" justify="center" className="w-full h-full">
          <Spin size="large" />
        </Flex>
      ) : (
        reports.map((report) => (
          <ReportCard
            key={report.id}
            report={report}
            openId={openId}
            setOpenId={setOpenId}
            onRead={changeDailyReportReadStatus}
            updateReports={updateReports}
          />
        ))
      )}
    </Row>
  );
};

export default Reports;
