import {
  IEditProfit,
  INewProfit,
  INewProfitShipping,
  INewProfitTrade,
} from "src/services/profit/models";
import { Dayjs } from "dayjs";
export type AddNewProfitStep = "basic" | "trade" | "shipping";
export enum AddNewProfitStepEnum {
  basic = "basic",
  trade = "trade",
  shipping = "shipping",
}
export type AddNewProfitStatusType = "empty" | "loading" | "done" | "editing";
export enum AddNewProfitStatusTypeEnum {
  empty = "empty",
  loading = "loading",
  done = "done",
  editing = "editing",
}
export interface AddNewProfitStatus {
  basic: AddNewProfitStatusType;
  trade: AddNewProfitStatusType;
  shipping: AddNewProfitStatusType;
}

export enum QuantityTypeEnum {
  MT = 1,
  BBL = 2,
  LTR = 3,
  KG = 4,
  CBM = 5,
  TANK = 6,
}
export const chooseQuantityType = (quantity?: number) => {
  switch (quantity) {
    case QuantityTypeEnum.BBL:
      return "BBL";
    case QuantityTypeEnum.MT:
      return "MT";
    case QuantityTypeEnum.LTR:
      return "LTR";
    case QuantityTypeEnum.KG:
      return "KG";
    case QuantityTypeEnum.CBM:
      return "CBM";
    case QuantityTypeEnum.TANK:
      return "TANK";
    default:
      return "";
  }
};
export enum CharterTypeEnum {
  TradeDepartman_TCH = 1,
  TradeDepartman_VCH = 2,
  Charter_VCH = 3,
  Charter_TCH = 4,
}
export const chooseCharterType = (charterType?: number) => {
  switch (charterType) {
    case CharterTypeEnum.Charter_TCH:
      return "Charter_TCH";
    case CharterTypeEnum.Charter_VCH:
      return "Charter_VCH";
    case CharterTypeEnum.TradeDepartman_TCH:
      return "TradeDepartman_TCH";
    case CharterTypeEnum.TradeDepartman_VCH:
      return "TradeDepartman_VCH";
    default:
      return "";
  }
};

export interface IBaseInfoFormValues
  extends Omit<INewProfit, "startLaycan" | "endLaycan"> {
  laycan: [Dayjs, Dayjs];
}

export interface ITradeInfoFormValues
  extends Omit<INewProfitTrade, "otherCost"> {
  tradeOtherCost: number;
}
export interface IShippingInfoFormValues
  extends Omit<
    INewProfitShipping,
    "startVoyageDate" | "endVoyageDate" | "otherCost"
  > {
  voyageDuration: [Dayjs, Dayjs];
  shippingOtherCost: number;
}

export interface IEditProfitFormValues
  extends Omit<IEditProfit, "startLaycan" | "endLaycan"> {
  laycan: [Dayjs, Dayjs];
}
