import {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from "react";
import {
  AddNewProfitStatusTypeEnum,
  IBaseInfoFormValues,
  IEditProfitFormValues,
  IShippingInfoFormValues,
  ITradeInfoFormValues,
} from "src/modules/ProfitReport/models";
import { omit } from "lodash";
import {
  IEditProfit,
  IGetProfit,
  INewProfit,
  INewProfitShipping,
  INewProfitTrade,
} from "src/services/profit/models";
import { ProfitService } from "src/services/profit/profit.service";
import { App } from "antd";
import { useTableProfit } from "../../ProftTable/context";
import { useProfitReport } from "src/modules/ProfitReport/context";

interface IContext {
  values: {
    finishLoadingBaseInfo: boolean;
    newBasicInfoId: number | undefined;
    finishLoadingTradeInfo: boolean;
    finishLoadingShippingInfo: boolean;
    editLoading: boolean;
    showFinishButtonBaseInfo: boolean;
  };
  dispatches: {
    setFinishLoadingBaseInfo: Dispatch<SetStateAction<boolean>>;
    setNewBasicInfoId: Dispatch<SetStateAction<number | undefined>>;
    setFinishLoadingTradeInfo: Dispatch<SetStateAction<boolean>>;
    setFinishLoadingShippingInfo: Dispatch<SetStateAction<boolean>>;
    setEditLoading: Dispatch<SetStateAction<boolean>>;
    setShowFinishButtonBaseInfo: Dispatch<SetStateAction<boolean>>;
  };
  func: {
    onFinishBasicInfo: (value: IBaseInfoFormValues) => void;
    onFinishTradeInfo: (value: ITradeInfoFormValues) => void;
    onFinishShippingInfo: (value: IShippingInfoFormValues) => void;
    onFinishEditProfit: (value: IEditProfitFormValues) => void;
    NewProfitTrade: (value: ITradeInfoFormValues) => void;
    NewProfitShipping: (value: IShippingInfoFormValues) => void;
  };
}

const defaultContextValue: IContext = {
  values: {
    finishLoadingBaseInfo: false,
    newBasicInfoId: undefined,
    finishLoadingTradeInfo: false,
    finishLoadingShippingInfo: false,
    editLoading: false,
    showFinishButtonBaseInfo: false,
  },
  dispatches: {
    setFinishLoadingBaseInfo: () => {},
    setNewBasicInfoId: () => {},
    setFinishLoadingTradeInfo: () => {},
    setFinishLoadingShippingInfo: () => {},
    setEditLoading: () => {},
    setShowFinishButtonBaseInfo: () => {},
  },
  func: {
    onFinishBasicInfo: () => {},
    onFinishTradeInfo: () => {},
    onFinishShippingInfo: () => {},
    onFinishEditProfit: () => {},
    NewProfitShipping: () => {},
    NewProfitTrade: () => {},
  },
};
export const AddNewProfitReportCTX =
  createContext<IContext>(defaultContextValue);
export const NewProfitReportProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const {
    value: { editId },
    func: { getDataTableProfit, getDataViewProfit },
    dispatch: { setEditId, setEditData },
  } = useTableProfit();

  const {
    dispatch: { setStatus, setStep, setEditMode },
    form: { basicInfoForm, shippingInfoForm, tradeInfoForm },
  } = useProfitReport();

  const { message, modal } = App.useApp();

  //--------------form---------------

  //--------------state---------------

  const [finishLoadingBaseInfo, setFinishLoadingBaseInfo] =
    useState<boolean>(false);

  const [newBasicInfoId, setNewBasicInfoId] = useState<number | undefined>(
    undefined
  );

  const [finishLoadingTradeInfo, setFinishLoadingTradeInfo] =
    useState<boolean>(false);

  const [finishLoadingShippingInfo, setFinishLoadingShippingInfo] =
    useState<boolean>(false);

  const [editLoading, setEditLoading] = useState<boolean>(false);
  const [showFinishButtonBaseInfo, setShowFinishButtonBaseInfo] =
    useState<boolean>(false);
  //--------------func---------------

  const onFinishBasicInfo = async (value: IBaseInfoFormValues) => {
    try {
      setFinishLoadingBaseInfo(true);
      const { NewProfit } = new ProfitService();
      const reqBody: INewProfit = {
        ...omit(value, "laycan"),
        startLaycan:
          value.laycan && value.laycan.length > 0
            ? value.laycan[0].format("YYYY-MM-DD")
            : null,
        endLaycan:
          value.laycan && value.laycan.length > 1
            ? value.laycan[1].format("YYYY-MM-DD")
            : null,

        hireRate: value.hireRate ? +value.hireRate : null,
        demurageRate: value.demurageRate ? +value.demurageRate : null,
        dischargeRate: value.dischargeRate ? +value.dischargeRate : null,
        freigth: +value.freigth,
        laytime: value.laytime ? +value.laytime : null,
        loadingRate: value.loadingRate ? +value.loadingRate : null,
        quantity: value.quantity,
      };
      const response = await NewProfit(reqBody);
      if (response && response.status === 200) {
        modal.success({
          content: "Successfully added you can attach file or edit",
          title: "Success",
        });
        setNewBasicInfoId(response.data);
        basicInfoForm?.resetFields();
        getDataTableProfit();
        setStatus((prev) => ({
          ...prev,
          basic: AddNewProfitStatusTypeEnum.editing,
        }));
        setEditId(response.data);
        setStep("basic");
        setEditMode(true);
        setShowFinishButtonBaseInfo(true);
        if (response.data) getDataViewProfit(response.data);
        // setStep(undefined);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setFinishLoadingBaseInfo(false);
    }
  };

  const onFinishTradeInfo = async (value: ITradeInfoFormValues) => {
    NewProfitTrade(value);
  };

  const NewProfitTrade = async (value: ITradeInfoFormValues) => {
    const id = editId || newBasicInfoId;
    if (!id) return;
    try {
      setFinishLoadingTradeInfo(true);
      const { NewProfitTrade } = new ProfitService();
      const reqBody: INewProfitTrade = {
        projectNumber: value.projectNumber,
        purchaseQuantity: value.purchaseQuantity
          ? value.purchaseQuantity
          : null,
        purchaseUnitPrice: value.purchaseUnitPrice
          ? value.purchaseUnitPrice
          : null,
        tTlPurchaseCargo: value.tTlPurchaseCargo
          ? +value.tTlPurchaseCargo
          : null,
        otherCost: value.tradeOtherCost ? +value.tradeOtherCost : null,
        saleQuantity: value.saleQuantity ? value.saleQuantity : null,
        saleUnitPrice: value.saleUnitPrice ? value.saleUnitPrice : null,
        ttlSaleCargo: value.ttlSaleCargo ? +value.ttlSaleCargo : null,
        loadingDemurage: value.loadingDemurage ? +value.loadingDemurage : null,
        dischargeDemurage: value.dischargeDemurage
          ? +value.dischargeDemurage
          : null,
      };
      const response = await NewProfitTrade(id, reqBody);
      if (response && response.status === 200) {
        message.success("Successfully added");
        tradeInfoForm?.resetFields();
        getDataTableProfit();
        setStatus((prev) => ({
          ...prev,
          trade: AddNewProfitStatusTypeEnum.done,
        }));
        setStep(undefined);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setFinishLoadingTradeInfo(false);
    }
  };

  const onFinishShippingInfo = async (value: IShippingInfoFormValues) => {
    NewProfitShipping(value);
  };

  const NewProfitShipping = async (value: IShippingInfoFormValues) => {
    const id = editId || newBasicInfoId;
    if (!id) return;
    try {
      setFinishLoadingShippingInfo(true);
      const reqBody: INewProfitShipping = {
        ...omit(value, "voyageDuration"),
        startVoyageDate:
          value.voyageDuration && value.voyageDuration[0]
            ? value.voyageDuration[0].format("YYYY-MM-DD")
            : null,
        endVoyageDate:
          value.voyageDuration && value.voyageDuration[1]
            ? value.voyageDuration[1].format("YYYY-MM-DD")
            : null,
        opexTCCost: value.opexTCCost ? +value.opexTCCost : null,
        bunkering: value.bunkering ? +value.bunkering : null,
        agencyCost: value.agencyCost ? +value.agencyCost : null,
        brokageCommission: value.brokageCommission
          ? +value.brokageCommission
          : null,
        heatingCost: value.heatingCost ? +value.heatingCost : null,
        otherCost: value.shippingOtherCost ? +value.shippingOtherCost : null,
        freightInvoice: value.freightInvoice ? +value.freightInvoice : null,
        demurageInvoice: value.demurageInvoice ? +value.demurageInvoice : null,
        heatingInvoie: value.heatingInvoie ? +value.heatingInvoie : null,
      };
      const { NewProfitShipping } = new ProfitService();
      const response = await NewProfitShipping(id, reqBody);
      if (response && response.status === 200) {
        message.success("Successfully added");
        shippingInfoForm?.resetFields();
        getDataTableProfit();
        setStatus((prev) => ({
          ...prev,
          shipping: AddNewProfitStatusTypeEnum.done,
        }));
        setStep(undefined);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setFinishLoadingShippingInfo(false);
    }
  };

  const onFinishEditProfit = async (value: IEditProfitFormValues) => {
    if (!editId) return;
    try {
      setEditLoading(true);
      const reqBody: IEditProfit = {
        ...omit(value, "laycan"),
        startLaycan:
          value.laycan && !!value.laycan[0]
            ? value.laycan[0]?.format("YYYY-MM-DD")
            : null,
        endLaycan:
          value.laycan && !!value.laycan[1]
            ? value.laycan[1]?.format("YYYY-MM-DD")
            : null,
        hireRate: value.hireRate ? +value.hireRate : null,
        demurageRate: value.demurageRate ? +value.demurageRate : null,
        dischargeRate: value.dischargeRate ? +value.dischargeRate : null,
        freigth: +value.freigth,
        laytime: value.laytime ? +value.laytime : null,
        loadingRate: value.loadingRate ? +value.loadingRate : null,
        quantity: value.quantity,
      };
      const { EditProfit } = new ProfitService();
      const response = await EditProfit(editId, reqBody);
      if (response && response.status === 200) {
        message.success("Successfully edited");
        setEditData((prev) => {
          if (prev) {
            return { ...prev, ...(reqBody as IGetProfit) };
          } else return prev;
        });
        getDataTableProfit();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setEditLoading(false);
    }
  };

  const contextValue: IContext = {
    values: {
      finishLoadingBaseInfo,
      newBasicInfoId,
      finishLoadingTradeInfo,
      finishLoadingShippingInfo,
      editLoading,
      showFinishButtonBaseInfo,
    },
    dispatches: {
      setFinishLoadingBaseInfo,
      setNewBasicInfoId,
      setFinishLoadingTradeInfo,
      setFinishLoadingShippingInfo,
      setEditLoading,
      setShowFinishButtonBaseInfo,
    },
    func: {
      onFinishBasicInfo,
      onFinishTradeInfo,
      onFinishShippingInfo,
      onFinishEditProfit,
      NewProfitShipping,
      NewProfitTrade,
    },
  };
  return (
    <AddNewProfitReportCTX.Provider value={contextValue}>
      {children}
    </AddNewProfitReportCTX.Provider>
  );
};
export const useNewProfitReport = () => useContext(AddNewProfitReportCTX);
