import { FC, Suspense } from "react";
import { CreateUser } from "src/modules/CreateUser";
import { CreateUserProvider } from "src/modules/CreateUser/context";
import { PreLoading } from "src/routes/Loading";

export const CreateUserPage: FC = () => {
  return (
    <Suspense fallback={<PreLoading />}>
      <CreateUserProvider>
        <CreateUser />
      </CreateUserProvider>
    </Suspense>
  );
};
