import { Button, Col, Divider, Flex, Form, Typography } from "antd";
import { FC, useEffect } from "react";
import { ProfitTradeWrapper } from "./style";
import { ProfitTradeFormItems } from "./TradeFormItems";
import { useNewProfitReport } from "../../context";
import { AddNewProfitStatusTypeEnum } from "src/modules/ProfitReport/models";
import { useTableProfit } from "../../../ProftTable/context";
import { useProfitReport } from "src/modules/ProfitReport/context";

export const ProfitTradeInformation: FC = () => {
  const {
    values: { finishLoadingTradeInfo },
    func: { onFinishTradeInfo },
  } = useNewProfitReport();

  const {
    value: { editData },
  } = useTableProfit();

  const {
    value: { editMode },
    dispatch: { setStatus, setStep, setShowAttachFile },
    form: { tradeInfoForm },
  } = useProfitReport();

  const onReset = () => {
    tradeInfoForm?.resetFields();
  };

  const addFile = () => {
    setShowAttachFile(true);
  };

  const onCancelAdd = () => {
    setStatus((prev) => ({
      ...prev,
      trade:
        prev.trade === "loading"
          ? AddNewProfitStatusTypeEnum.empty
          : AddNewProfitStatusTypeEnum.done,
    }));
    setStep(undefined);
  };

  useEffect(() => {
    if (editData) {
      tradeInfoForm?.setFieldsValue(editData);
    }
  }, [tradeInfoForm, editData]);

  return (
    <Form form={tradeInfoForm} onFinish={onFinishTradeInfo}>
      <ProfitTradeWrapper>
        <Col span={24}>
          <Typography.Title className="title">
            Trade information
          </Typography.Title>
          <Typography.Text className="subTitle">
            Please insert its information.
          </Typography.Text>
        </Col>
        <Col span={24} className="mt-[24px]">
          <ProfitTradeFormItems />
        </Col>
        <Divider />
        <Col span={24}>
          <Flex align="center" justify="space-between">
            <Flex gap={16}>
              <Button
                className="text-[#4B4B4B] min-w-[85px]"
                onClick={() => {
                  onCancelAdd();
                }}
              >
                Cancel
              </Button>
              <Button
                icon={<span className="material-icons">folder_open</span>}
                className="flex justify-center items-center gap-[8px] !text-[#4B4B4B] w-[85px]"
                ghost
                style={{
                  border: "1px solid var(--color-Gray-Gray-600, #4B4B4B)",
                }}
                onClick={addFile}
              >
                files
              </Button>
            </Flex>
            <Flex gap={16}>
              <Button className="min-w-[85px]" onClick={onReset}>
                Reset
              </Button>
              <Button
                className="min-w-[85px]"
                type="primary"
                htmlType="submit"
                loading={finishLoadingTradeInfo}
              >
                {editMode ? "Save edit" : "Add"}
              </Button>
            </Flex>
          </Flex>
        </Col>
      </ProfitTradeWrapper>
    </Form>
  );
};
