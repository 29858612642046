import styled from "styled-components";

export const EditInvoiceContainer = styled.div`
  .header {
    position: absolute;
    top: 24px;
    right: 24px;
    color: #001377;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  tr {
    th::before {
      content: unset !important;
    }
  }
  .ant-select-disabled {
    .ant-select-selector {
      background-color: #f3f4f5 !important;
    }
  }
`;
