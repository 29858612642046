import { FC } from "react";
import { OwnerHeader } from "src/modules/Owner/components/OwnerHeader";
import { Voyage } from "src/modules/Owner";
import { OwnerContentStyle } from "./content.style";
import { SelectShip } from "src/modules/Owner/components/SelectShip";

export const VoyagePage: FC = () => {
  return (
    <>
      <OwnerHeader />
      <OwnerContentStyle>
        <SelectShip />
        <Voyage />
      </OwnerContentStyle>
    </>
  );
};
