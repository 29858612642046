import { Flex } from "antd";
import styled from "styled-components";

export const ShipDailyReportHeaderStyle = styled(Flex)`
  height: 82px;
  background-color: #fff;
  padding: 0 12px;

  .ships-link {
    height: 40px;
    width: 40px;
    border-radius: 6px;
    background-color: #e5e7eb;
    gap: 4px;
    color: #5d6881;
    font-size: 10px;
    font-weight: 700;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
  .ship-report-header-date {
    border-radius: 10px;
    height: 40px;
    width: 146px;
    background-color: #f2f7ff;
    display: inline-flex;
    align-items: center;

    padding: 12px;
  }
  .current-date {
    color: #5d6881;
    font-weight: 500;
    font-size: 12px;
    padding-left: 5px;
  }
`;
