import { useAddInvoiceModal } from "src/modules/addInvoiceModal/context";
import { useAttachInvoiceFileContext } from "../../AttachInvoiceFile/context";
import { useAttachZipFileContext } from "../context/attachZipFile";

import { IPath } from "src/services/Identity/models";
import {
  AllRequestPath,
  AllRequestZipDetailPath,
  AttachZipToAllRequestPath,
  NewAllRequestPath,
  UpdateAllRequestZipDetailPath,
} from "src/services/Invoice/guardPath";

export interface IItemsLittleCard {
  icon: string;
  title: string;
  des: string;
  onClick: () => void;
  actions: IPath[];
}

export const useLittleCardItems = () => {
  const {
    dispatch: { setIsModalOpenAttachZip },
  } = useAttachZipFileContext()!;
  const {
    dispatch: { setIsModalOpenAttachInvoice },
  } = useAttachInvoiceFileContext()!;
  const {
    func: { openModal },
  } = useAddInvoiceModal();
  const littleCardItems: IItemsLittleCard[] = [
    {
      icon: "local_mall",
      title: "invoices",
      des: "Add new invoice",
      onClick: openModal,
      actions: [AllRequestPath],
    },
    {
      icon: "folder",
      title: "Attach invoice file",
      des: "PDF, Excel, Word",
      onClick: () => {
        setIsModalOpenAttachInvoice(true);
      },
      actions: [AllRequestZipDetailPath, UpdateAllRequestZipDetailPath],
    },
    {
      icon: "folder_zip",
      title: "Attach Zip file",
      des: "Create reference No. ",
      onClick: () => {
        setIsModalOpenAttachZip(true);
      },
      actions: [NewAllRequestPath, AttachZipToAllRequestPath],
    },
  ];

  return { littleCardItems };
};
