import { Col } from "antd";
import { FC } from "react";
import { EditHeaderContainer } from "./style";
import { useProfitReport } from "../../context";
import { ProfitTabs } from "../../constants/Tabs";
import { useTableProfit } from "../ProftTable/context";
import { AddNewProfitStatusTypeEnum } from "../../models";

export const EditHeader: FC = () => {
  const {
    dispatch: {
      setActiveKey,
      setEditMode,
      setStatus,
      setStep,
      setShowAttachFile,
    },
    form: { basicInfoForm, shippingInfoForm, tradeInfoForm },
  } = useProfitReport();

  const {
    dispatch: { setEditId, setEditData, setFromTable },
    form: { attachFileBaseInfoForm },
  } = useTableProfit();

  const onBack = () => {
    basicInfoForm?.resetFields();
    shippingInfoForm?.resetFields();
    tradeInfoForm?.resetFields();
    attachFileBaseInfoForm?.resetFields();
    setEditMode(false);
    setEditId(undefined);
    setEditData(undefined);
    setActiveKey(ProfitTabs.Table);
    setFromTable(false);
    setShowAttachFile(false);
    setStatus({
      basic: AddNewProfitStatusTypeEnum.empty,
      shipping: AddNewProfitStatusTypeEnum.empty,
      trade: AddNewProfitStatusTypeEnum.empty,
    });
    setStep(undefined);
  };

  return (
    <EditHeaderContainer>
      <Col span={24} className="mb-[32px]">
        <div className="flex justify-between items-center py-[19px] px-[24px] borderBottom">
          <div
            onClick={onBack}
            className="flex flex-row gap-[12px] items-center cursor-pointer"
          >
            <span className="material-icons text-[#2C73FC] text-[24px]">
              keyboard_backspace
            </span>
            <div className=" text-[#2C73FC] text-[18px] font-semibold ">
              Back to table
            </div>
          </div>
          <div className="flex flex-col">
            <div className="text-[#001377] text-[18px] font-semibold">Edit</div>
            <div className="text-[#5D6881] text-[14px] font-bold">
              {"Profit"}
            </div>
          </div>
        </div>
      </Col>
    </EditHeaderContainer>
  );
};
