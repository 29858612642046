import { Flex } from "antd";
import moment from "moment";
import { FC } from "react";
import { ShipStatus } from "src/models/enums";
import { useNoonReportCTX } from "src/modules/NoonReport/context";
import CurrentSituationText from "../../../NoonReportTable/components/CurrentSituation";

export const VoyageDetails: FC = () => {
  const {
    value: { selectedReport },
  } = useNoonReportCTX();

  return (
    <>
      <section className="py-[9px] pl-[32px] flex items-center bg-[#2c73fc] relative mt-[40px]">
        <div>
          <span className="text-[#fff]">{selectedReport?.vesselName}</span>
          <span className="ml-[40px] text-[#fff]">
            {selectedReport?.voyageName}
          </span>
        </div>

        <div className="w-[248px] h-[42px] flex items-center justify-center bg-[#fff] absolute right-[140px]">
          <span className="text-[#fe9b0e] text-[40px] font-bold">
            Noon Report
          </span>
        </div>

        <span className="absolute text-[#fff] right-[32px]">
          {moment(selectedReport?.date).format("YYYY.MM.DD")}
        </span>
      </section>

      <section className="px-[24px] mt-[30px]">
        <div className="flex justify-around bg-[#F2F7FF] py-[24px] px-[16px] rounded-lg">
          <Flex>
            <p className="text-[#001377] text-[14px] font-medium">
              Vessel status
            </p>
            <p className="ml-[15px]">
              {selectedReport?.vesselStatus === ShipStatus.Laden
                ? "Laden"
                : "Ballast"}
            </p>
          </Flex>
          <Flex>
            <p className="text-[#001377] text-[14px] font-medium">
              Current situation
            </p>
            <p className="ml-[15px]">
              <CurrentSituationText
                situation={selectedReport?.currentSituation || 0}
              />
            </p>
          </Flex>
        </div>
      </section>

      <section className="px-[24px] mt-[40px]">
        <Flex vertical>
          <p className="text-[14px] text-[#001377] font-bold">Cargo</p>
          <div className="flex items-center  bg-[#F2F7FF] py-[24px] px-[16px] rounded-lg">
            {selectedReport?.cargoName?.map((item, index) => (
              <p key={index} className="px-[10px]">
                {item}
              </p>
            ))}
          </div>
        </Flex>
      </section>
    </>
  );
};
