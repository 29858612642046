import { Row } from "antd";
import styled from "styled-components";

export const FlowStepsContainer = styled(Row)`
  .content {
    border-radius: 16px;
    border: 2px solid var(--shadow, #dadeec);
    padding: 24px;
  }
  .radio {
    .ant-form-item-label {
      padding-left: 0 !important;
    }
  }
  .step-action {
    border-top: 1px solid var(--shadow, #dadeec);
    padding: 24px 0 0 0;
    display: flex;
    justify-content: flex-end;
  }
  .create-button {
    border-radius: 12px;
    border: 1px solid var(--Info-Info-600, #3b82f6);
    background: transparent;
    color: #3b82f6;
    padding: 8px;
  }
  .delete-button {
    border-radius: 12px;
    border: 1px solid var(--Danger-Danger-600, #f64c4c);
    background: transparent;
    color: #f64c4c;
    padding: 8px;
  }
  .footer {
    display: flex;
    justify-content: flex-end;
    gap: 24px;
  }
`;
