import { FC } from "react";
import { TableContainer } from "./styles";
import { useInvoiceReport } from "../../context";
import { TableUI } from "src/components/UiKit/table";
import {
  IReportInvoice,
  InvoiceStatus,
} from "src/services/InvoiceReport/models";
import { ColumnsType } from "antd/es/table";
import moment from "moment";
import { INVOICE_REPORT_TYPES } from "../../models/reportTypes";
import { IInvoiceType } from "src/services/Invoice/models";

export const InvoiceReportTable: FC = () => {
  const {
    value: {
      loadings,
      InvoiceData,
      InvoicePagination,
      currentRequestValue,
      currentAttachment,
    },
    dispatch: {
      setInvoicePagination,
      setCommentModal,
      setReportType,
      setCurrentInvoice,
    },
    func: { fetchInvoice, fetchArticle },
  } = useInvoiceReport();
  const columns: ColumnsType<IReportInvoice> = [
    {
      title: "#",
      key: "key",
      className: "!font-bold",
      align: "center",
      render: (text, record, index) =>
        (InvoicePagination.Offset - 1) * InvoicePagination.Limit + (index + 1),
      fixed: "left",
      width: "61px",
    },
    {
      title: "Invoice number",
      dataIndex: "invoiceNumber",
      key: "invoiceNumber",
      className: "!font-bold",
      align: "center",
      width: "200px",
      fixed: "left",
    },
    {
      title: "Invoice type",
      dataIndex: "invoiceType",
      key: "invoiceType",
      className: "!font-bold",
      align: "center",
      render: (text) => {
        switch (text) {
          case IInvoiceType.Invoice:
            return "Inv";
          case IInvoiceType.PreInvoice:
            return "Per inv";
          case IInvoiceType.ReserveFund:
            return "Reserve fund";
          default:
            return "";
        }
      },
      width: "117px",
    },
    // {
    //   dataIndex: "voyageNO",
    //   title: "Voyage number",
    //   key: "voyageNO",
    //   className: "!font-bold",
    //   align: "center",
    //   width: "200px",
    // },
    {
      title: "invoice status",
      dataIndex: "invoiceStatus",
      key: "invoiceStatus",
      className: "!font-bold",
      align: "center",
      render: (text) => {
        switch (text) {
          case InvoiceStatus.Open:
            return "open";
          case InvoiceStatus.Close:
            return "closed";
          default:
            return "";
        }
      },
      width: "126px",
    },
    {
      title: "Supplier",
      dataIndex: "supplierName",
      key: "supplierName",
      className: "!font-bold",
      align: "center",
      width: "90px",
    },
    {
      title: "Requester invoice No.",
      dataIndex: "requesterInvoiceNumber",
      key: "requesterInvoiceNumber",
      className: "!font-bold",
      align: "center",
      width: "146px",
    },
    {
      title: "Supplier invoice No.",
      dataIndex: "supplierInvoiceNumber",
      key: "supplierInvoiceNumber",
      className: "!font-bold",
      align: "center",
      width: "109px",
    },
    {
      title: "Requester invoice date",
      dataIndex: "requesterInvoiceDate",
      key: "requesterInvoiceDate",
      className: "!font-bold",
      align: "center",
      width: "116px",
      render: (text) => (text ? moment(text).format("YYYY-MM-DD") : ""),
    },
    {
      title: "Supplier invoice date",
      dataIndex: "suppliernvoiceDate",
      key: "suppliernvoiceDate",
      className: "!font-bold",
      align: "center",
      width: "116px",
      render: (text) => (text ? moment(text).format("YYYY-MM-DD") : ""),
    },
    {
      title: "Total amount",
      dataIndex: "totalAmount",
      key: "totalAmount",
      className: "!font-bold",
      align: "center",
      render: (text: number) => text?.toLocaleString("en-US"),
      width: "86px",
    },
    {
      title: "Req total amount",
      dataIndex: "requesterTotlaAmount",
      key: "requesterTotlaAmount",
      className: "!font-bold",
      align: "center",
      render: (text: number) => text?.toLocaleString("en-US"),
      width: "94px",
    },
    {
      title: "Supp total amount",
      dataIndex: "supplireToTalAmount",
      key: "supplireToTalAmount",
      className: "!font-bold",
      align: "center",
      render: (text: number) => text?.toLocaleString("en-US"),
      width: "103px",
    },
    {
      title: "Inv currency",
      dataIndex: "invoiceCurrencyName",
      key: "invoiceCurrencyName",
      className: "!font-bold",
      align: "center",
      width: "116px",
    },

    {
      title: "our date",
      dataIndex: "ourDate",
      key: "ourDate",
      className: "!font-bold",
      align: "center",
      render: (text) => (text ? moment(text).format("YYYY-MM-DD") : ""),
      width: "97px",
    },
    {
      title: "Created date",
      dataIndex: "created",
      key: "created",
      className: "!font-bold",
      align: "center",
      render: (text) => (text ? moment(text).format("YYYY-MM-DD") : ""),
      width: "123px",
    },
    {
      title: "Tools",
      key: "tools",
      className: "!font-bold ",
      align: "center",
      width: "146px",
      fixed: "right",

      render: (text, record) => {
        return (
          <div className="flex justify-between items-center gap-[16px] w-[72px] ">
            <span
              className="material-symbols-outlined cursor-pointer text-[16px]"
              onClick={() => {
                setCommentModal({
                  showModal: true,
                  title: record.invoiceNumber,
                  description: record.comment,
                });
              }}
            >
              mode_comment
            </span>
            <div
              className="text-[#2C73FC] cursor-pointer select-none text-[12px]"
              onClick={() => {
                if (currentRequestValue) {
                  setCurrentInvoice(record);
                  fetchArticle(currentRequestValue, record.invoiceId);
                  setReportType(INVOICE_REPORT_TYPES.Article);
                }
              }}
            >
              Detail
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <TableContainer>
      <TableUI
        dataSource={InvoiceData}
        columns={columns}
        scroll={{ x: 1000 }}
        loading={loadings.invoice}
        rowKey={(record) => record.invoiceId}
        pagination={{
          position: ["bottomCenter"],
          pageSize: InvoicePagination.Limit,
          current: InvoicePagination.Offset,
          total: InvoicePagination.total,
          onChange: (page, pageSize) => {
            if (currentRequestValue) {
              fetchInvoice(
                currentRequestValue,
                currentAttachment?.attachmentId,
                {
                  Limit: pageSize,
                  Offset: pageSize !== InvoicePagination.Limit ? 1 : page,
                }
              );
            }
            setInvoicePagination((prev) => ({
              ...prev,
              Limit: pageSize,
              Offset: pageSize !== prev.Limit ? 1 : page,
            }));
          },
        }}
      />
    </TableContainer>
  );
};
