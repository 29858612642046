import { FC, useEffect, useState } from "react";
import { Tabs, TabsProps } from "antd";
import { PortsTabs } from "./models";
import { PortsContainer } from "./styles";
import { AddNewPort } from "./components/Add/addNewPort";
import { PortsTable } from "./components/Table/PortsTable";
import { usePorts } from "./context";
import { checkActionPermission } from "src/helpers/checkPermissionFunc";
import {
  AllPortsPath,
  NewPortPath,
  UpdatePortPath,
} from "src/services/BaseInfo/guardPath";

export const Ports: FC = () => {
  const {
    value: { activeKey, selectedPort, formMode },
    dispatch: { setActiveKey },
  } = usePorts()!;
  const [items, setItems] = useState<TabsProps["items"]>([]);
  const NewPortTab = {
    key: PortsTabs.Add,
    label: (
      <span>
        <span className="material-icons text-[10px] p-[8px]">lens</span>
        {formMode === "add"
          ? "Add new port"
          : formMode === "edit"
          ? "Edit port"
          : "View port"}
      </span>
    ),
    children: <AddNewPort />,
  };

  const PortTableTab = {
    key: PortsTabs.Table,
    label: (
      <span>
        <span className="material-icons text-[10px] p-[10px]">lens</span>
        Ports table
      </span>
    ),
    children: <PortsTable />,
    disabled: !!selectedPort,
  };

  useEffect(() => {
    const addNewPortAccess = checkActionPermission(NewPortPath);
    const editNewPortAccess = checkActionPermission(UpdatePortPath);
    const viewNewPortAccess = checkActionPermission(AllPortsPath);
    let newItems: TabsProps["items"] = [];
    switch (formMode) {
      case "add":
        if (addNewPortAccess) {
          newItems = [NewPortTab];
        }
        break;
      case "edit":
        if (editNewPortAccess) {
          newItems = [NewPortTab];
        }
        break;

      case "view":
        if (viewNewPortAccess) {
          newItems = [NewPortTab];
        }
        break;
    }
    if (viewNewPortAccess) {
      newItems.push(PortTableTab);
    }
    setItems(newItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formMode]);
  const onChange = (key: string) => {
    setActiveKey(key);
  };
  return (
    <PortsContainer>
      <Tabs items={items} onChange={onChange} activeKey={activeKey} animated />
    </PortsContainer>
  );
};
