import { Tabs, TabsProps } from "antd";
import { IAgentsTabs } from "../models";
import { AgentsTable } from "./agentsTable";
import { FC } from "react";
import { TabsContainer } from "src/modules/Category/styles";
import { useSearchParams } from "react-router-dom";
import { AddNewAgent } from "./addNewAgent";
import { Guard } from "src/components/Guard";
import { NewAgentPath } from "src/services/BaseInfo/guardPath";

const item: TabsProps["items"] = [
  {
    key: IAgentsTabs.addNewAgent,
    label: (
      <Guard action={NewAgentPath}>
        <span>
          <span className="material-icons text-[10px] p-[8px]">lens</span>
          Add new agent
        </span>
      </Guard>
    ),
    children: (
      <Guard action={NewAgentPath}>
        <AddNewAgent />
      </Guard>
    ),
  },
  {
    key: IAgentsTabs.agentsTable,
    label: (
      <span>
        <span className="material-icons text-[10px] p-[10px]">lens</span>
        Agents table
      </span>
    ),
    children: <AgentsTable />,
  },
];

export const AgentsTabs: FC = () => {
  const [params] = useSearchParams();
  const defaultKey =
    params.get("table") === "true"
      ? IAgentsTabs.agentsTable
      : IAgentsTabs.addNewAgent;
  return (
    <TabsContainer>
      <Tabs defaultActiveKey={defaultKey} items={item} />
    </TabsContainer>
  );
};
