import { Button, Col, Form, Row, Select, message } from "antd";
import { FC, useEffect, useState } from "react";
import { useAddShip } from "src/modules/AddShips/context";
import { FormItem } from "src/components/UiKit/FormItem";
import { SInput } from "src/components/UiKit/Input";
import { SDatePicker } from "src/components/UiKit/DatePicker";
import { ShipPriceContainer } from "../../AddNewShip/styles";
import { IShipPriceInformation } from "src/services/Ship/models";
import { ShipService } from "src/services/Ship/Ship.service";
import dayjs from "dayjs";
import { SPopconfirm } from "../../AddNewShip/components/popconfirm";
export const EditShipPrice: FC = () => {
  const {
    forms: { ShipPriceForm },
    func: { fetchAllCurrency, fetchShipInformation },
    value: { allCurrency, tableCurrentValue, newData },
  } = useAddShip()!;
  const [loading, setLoading] = useState<boolean>(false);

  const onFinish = async (values: IShipPriceInformation) => {
    if (!tableCurrentValue) return;
    setLoading(true);
    const service = new ShipService();
    const reqBody: IShipPriceInformation = {
      currencyId: values.currencyId,
      price: +values.price,
      date: values.priceDate
        ? (values.priceDate as dayjs.Dayjs).format("YYYY-MM-DD")
        : null,
      datePrice: +values.datePrice,
    };
    try {
      const response = await service.ShipPriceInformation(
        tableCurrentValue?.id,
        reqBody
      );
      if (response && response.status === 200) {
        message.success("ShipPriceInformation updated successfully");
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAllCurrency();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Form
      onFinish={onFinish}
      form={ShipPriceForm}
      className="flex flex-col h-[620px] justify-between"
    >
      <ShipPriceContainer gutter={24}>
        <Col span={24}>
          <div className="titleHolder pt-[16px]">
            Please insert the ship price information at the time of purchase.
          </div>
        </Col>
        <Col span={8}>
          <FormItem name={"price"} label="Ship price" className="!mb-0">
            <SInput placeholder="insert" />
          </FormItem>
        </Col>
        <Col span={8}>
          <FormItem name={"currencyId"} label="Currency" className="!mb-0">
            <Select
              style={{ width: "100%" }}
              placeholder="select"
              optionLabelProp="label"
              showSearch
              className="selectOption"
              //   loading={shipLoading}
              options={allCurrency.map((currency) => ({
                label: currency.shortName,
                value: currency.id,
              }))}
            />
          </FormItem>
        </Col>
        <Col span={24}>
          <div className="titleHolder pt-[16px]">
            Please insert current ship price information.
          </div>
        </Col>
        <Col span={8}>
          <FormItem name={"priceDate"} label="Date" className="!mb-0">
            <SDatePicker
              placeholder="Select"
              className="w-[100%]"
              format={"YYYY.MM.DD"}
            />
          </FormItem>
        </Col>
        <Col span={8}>
          <FormItem
            name={"datePrice"}
            label="Ship price in date"
            className="!mb-0"
          >
            <SInput placeholder="insert" />
          </FormItem>
        </Col>
      </ShipPriceContainer>
      <Row>
        <Col span={24}>
          <div className=" flex gap-[16px] footer justify-end">
            <SPopconfirm
              description="Are you sure you want to reset all the information you filled out?"
              onConfirm={() => {
                ShipPriceForm?.setFieldsValue(newData);
                fetchShipInformation();
              }}
            >
              <Button className="w-[100px]">Reset</Button>
            </SPopconfirm>
            <Button
              type="primary"
              className="w-[100px]"
              onClick={ShipPriceForm?.submit}
              loading={loading}
            >
              Save Edit
            </Button>
          </div>
        </Col>
      </Row>
    </Form>
  );
};
