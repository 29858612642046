import { create } from "zustand";
import dayjs from "dayjs";
import { IShip } from "src/services/Public/models";
import { IAllShipDailyReportResult } from "src/services/DailyReport/models/result.model";

interface IStore {
  ownerViewDate: dayjs.Dayjs | undefined;
  setOwnerViewDate: (newDate: dayjs.Dayjs | undefined) => void;
  ships: IShip[];
  setShips: (ship: IShip[]) => void;
  selectedShip: IShip | undefined;
  setSelectedShip: (ship: IShip | undefined) => void;
  dailyReport: IAllShipDailyReportResult | undefined;
  setDailyReport: (dailyReport: IAllShipDailyReportResult | undefined) => void;
  hasSelectShip: boolean;
  setHasSelectShip: (value: boolean) => void;
}

const useOwnerStore = create<IStore>((set) => ({
  ownerViewDate: dayjs(new Date()),
  setOwnerViewDate: (newDate) => set({ ownerViewDate: newDate }),
  ships: [],
  setShips: (newShips) => set({ ships: newShips }),
  selectedShip: undefined,
  setSelectedShip: (newSelectedShip) => set({ selectedShip: newSelectedShip }),
  dailyReport: undefined,
  setDailyReport: (data) => set({ dailyReport: data }),
  hasSelectShip: false,
  setHasSelectShip: (value) => set({ hasSelectShip: value }),
}));

export default useOwnerStore;
