import { FC, useEffect, useState } from "react";
import { Select } from "antd";
import { ShipDailyReportSearchStyle } from "./style";
import { PublicService } from "src/services/Public/Public.service";
import { IShip } from "src/services/Public/models";
interface IProps {
  title: string;
  onSearch: (value: string) => void;
  shipsData?: IShip[];
}

export const ShipDailyReportSearch: FC<IProps> = ({
  onSearch,
  title,
  shipsData,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [ships, setShips] = useState<IShip[]>(shipsData || []);
  const getShips = async () => {
    try {
      setLoading(true);
      const { Ship } = new PublicService();
      const res = await Ship();
      if (res && res.data) {
        setShips(res.data);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (!shipsData) getShips();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <ShipDailyReportSearchStyle justify="space-between" align="center">
      <span className="search-title">{title}</span>
      <div className="search-input">
        <Select
          allowClear
          placeholder="Search ship"
          suffixIcon={<span className="material-icons">search</span>}
          onChange={(value) => {
            onSearch(value);
          }}
          options={ships.map((i) => ({ label: i.name, value: i.abbreviation }))}
          loading={loading}
          optionFilterProp="label"
          className="w-full input"
        />
      </div>
    </ShipDailyReportSearchStyle>
  );
};
