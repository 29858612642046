import { FC } from "react";
import { OwnerDashboardContent } from "./components/Content";
import { SelectShip } from "../SelectShip";
import { OwnerHeader } from "../OwnerHeader";
import useOwnerStore from "src/store/owner";
import { ShipDailyReportsHeader } from "../ShipDailyReports/components/ShipDailyReportHeader";

export const DailyReportCalendar: FC = () => {
  const { hasSelectShip } = useOwnerStore();

  return (
    <>
      <OwnerHeader backWithDiscardShip={false} />
      {hasSelectShip ? <SelectShip /> : <ShipDailyReportsHeader />}
      <OwnerDashboardContent />
    </>
  );
};
