import { FC, useCallback, useEffect, useState } from "react";
import { AgentViewController } from "./style";
import { Col, Row } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "src/models/enums";
import { TableUI } from "src/components/UiKit/table";
import { ColumnsType } from "antd/es/table";
import { IAgentsEmail, IAgentsPhoneNumber, IContacts } from "../../models";
import {
  IAllAgentContact,
  IAllAgents,
  NewAgentContactEnum,
  NewAgentContactType,
} from "src/services/BaseInfo/models";
import { getRowKey } from "src/helpers/getRowKey";
import { BaseInfoService } from "src/services/BaseInfo/BaseInfo.service";

export const AgentView: FC = () => {
  const Navigate = useNavigate();
  const onBack = () => {
    Navigate(ROUTES.Agent.concat("?table=true"));
  };
  const [loadingEmailOrPhone, setLoadingEmailOrPhone] = useState<IContacts>({
    email: false,
    phone: false,
  });
  const [phoneDate, setPhoneDate] = useState<IAllAgentContact[]>([]);
  const [emailData, setEmailData] = useState<IAllAgentContact[]>([]);
  const location = useLocation();
  const items = location.state as IAllAgents;
  console.log(items);
  const columnsPhone: ColumnsType<IAgentsPhoneNumber> = [
    {
      title: "#",
      key: "id",
      align: "center",
      width: "61px",
      render: (name, record, index) => index + 1,
    },
    {
      title: "Phone",
      dataIndex: "value",
      key: "phoneNumber",
      align: "center",
    },
  ];
  const columnsEmail: ColumnsType<IAgentsEmail> = [
    {
      title: "#",
      key: "id",
      align: "center",
      width: "61px",
      render: (name, record, index) => index + 1,
    },
    {
      title: "Email",
      dataIndex: "value",
      key: "email",
      align: "center",
    },
  ];
  const getAgentPhoneOrEmailData = useCallback(
    async (type: NewAgentContactType) => {
      if (!items) return;
      try {
        setLoadingEmailOrPhone((prev) => ({
          ...prev,
          [type === NewAgentContactEnum.Phone ? "phone" : "email"]: true,
        }));
        const { AllAgentContact } = new BaseInfoService();
        const result = await AllAgentContact(items.id, type);
        if (result && result.data) {
          if (type === NewAgentContactEnum.Phone) {
            setPhoneDate(result.data);
          } else {
            setEmailData(result.data);
          }
        }
      } catch (err) {
        throw console.log(err);
      } finally {
        setLoadingEmailOrPhone((prev) => ({
          ...prev,
          [type === NewAgentContactEnum.Phone ? "phone" : "email"]: false,
        }));
      }
    },
    [items]
  );
  useEffect(() => {
    getAgentPhoneOrEmailData(NewAgentContactEnum.Email);
    getAgentPhoneOrEmailData(NewAgentContactEnum.Phone);
  }, [getAgentPhoneOrEmailData]);
  return (
    <AgentViewController>
      <Row>
        <Col span={24}>
          <div
            onClick={onBack}
            className="flex justify-between items-center py-[19px] px-[24px] borderBottom"
          >
            <div className="flex flex-row gap-[12px] items-center cursor-pointer">
              <span className="material-icons text-[#2C73FC] text-[24px]">
                keyboard_backspace
              </span>
              <div className=" text-[#2C73FC] text-[18px] font-semibold ">
                Back to table
              </div>
            </div>
            <div className="flex flex-col">
              <div className="text-[#001377] text-[18px] font-semibold">
                View
              </div>
              <div className="text-[#5D6881] text-[14px] font-bold">agent</div>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="body">
        <Col span={24}>
          <p className="text-[14px] text-[#001377] font-semibold">
            Basic Information
          </p>
          <Row className="bg-[#F2F7FF] rounded-[8px] py-[24px] px-[16px] flex justify-between">
            <Col span={12} className="mb-[24px]">
              <span className="inline-block w-[100px] text-[14px] font-medium text-[#001377]">
                Name
              </span>
              <span className="text-[14px] text-[#5D6881] font-normal">
                {items.name}
              </span>
            </Col>
            <Col span={12} className="mb-[24px]">
              <span className="inline-block w-[100px] text-[14px] font-medium text-[#001377]">
                Company
              </span>
              <span className="text-[14px] text-[#5D6881] font-normal">
                {items.company}
              </span>
            </Col>
            <Col span={12} className="">
              <span className="inline-block w-[100px] text-[14px] font-medium text-[#001377]">
                Country
              </span>
              <span className="text-[14px] text-[#5D6881] font-normal">
                {items.country}
              </span>
            </Col>
            <Col span={12} className="">
              <span className="inline-block w-[100px] text-[14px] font-medium text-[#001377]">
                Port
              </span>
              <span className="text-[14px] text-[#5D6881] font-normal">
                {items.port}
              </span>
            </Col>
          </Row>
        </Col>
        <Col span={24} className="mt-[32px]">
          <p className="text-[14px] text-[#001377] font-semibold">Services</p>
          <div className="py-[24px] px-[12px] bg-[#F2F7FF] text-[#3E4556] text-[12px] rounded-[8px] ">
            <p>{items.description}</p>
          </div>
        </Col>
        <div className="flex gap-[24px] justify-between w-[97%] mt-[32px]">
          <Col span={12}>
            <p className="text-[14px] text-[#001377] font-semibold">
              Phone number table
            </p>
            <TableUI
              columns={columnsPhone}
              dataSource={phoneDate}
              pagination={false}
              loading={loadingEmailOrPhone.phone}
              rowKey={getRowKey}
            />
          </Col>
          <Col span={12}>
            <p className="text-[14px] text-[#001377] font-semibold">
              Phone number table
            </p>
            <TableUI
              columns={columnsEmail}
              dataSource={emailData}
              pagination={false}
              loading={loadingEmailOrPhone.email}
              rowKey={getRowKey}
            />
          </Col>
        </div>
      </Row>
    </AgentViewController>
  );
};
