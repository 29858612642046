import { styled } from "styled-components";

export const TitleStyles = styled.div`
  gap: 12px;

  .icons,
  .text {
    color: #1b2559;
  }

  .material-symbols-outlined {
    font-size: 32px !important;
    color: #1b2559 !important;
  }

  .text {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: -0.48px;
    text-align: left;
  }
`;
