import { FC } from "react";
import { OwnerHeader } from "src/modules/Owner/components/OwnerHeader";
import {
  ShipDailyReportDetail,
  ShipDailyReportDetailHeader,
} from "src/modules/Owner";

export const ShipDailyReportDetailPage: FC = () => {
  return (
    <>
      <OwnerHeader backWithDiscardShip={false} />
      <ShipDailyReportDetailHeader />
      <ShipDailyReportDetail />
    </>
  );
};
