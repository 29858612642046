import { Col, Row, Skeleton } from "antd";
import { FC } from "react";
import { ShowBasicInformationContainer } from "../style";
import { ShipInformationItems } from "./shipInformationItem";
import { useAddShip } from "src/modules/AddShips/context";

export const ShowOwner: FC = () => {
  const {
    value: { tableCurrentValue, pictureFileList, picLoading },
  } = useAddShip()!;
  return (
    <ShowBasicInformationContainer>
      <Col span={24} className="pictureHolder">
        {picLoading ? (
          <Skeleton.Image active className="!w-[334px] !h-[165px]" />
        ) : (
          pictureFileList.map((item, index) => (
            <img
              src={item.url}
              key={index}
              width={334}
              height={165}
              alt={item.name}
              className="rounded-[16px]"
            />
          ))
        )}
      </Col>
      <Col span={24} className="titleHolder">
        Owner information
      </Col>
      <Col span={24} className="dataHolder">
        <Row gutter={60} justify={"space-between"}>
          <Col span={12}>
            <ShipInformationItems
              name="Owner’s company"
              value={tableCurrentValue?.ownerCompany}
            />
          </Col>
          <Col span={12}>
            <ShipInformationItems
              name="Owner’s name"
              value={tableCurrentValue?.ownerName}
            />
          </Col>
        </Row>
      </Col>
    </ShowBasicInformationContainer>
  );
};
