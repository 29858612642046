import { FC } from "react";
import { Currency } from "src/modules/Currency";
import { CurrencyProvider } from "src/modules/Currency/context";

export const CurrencyPage: FC = () => {
  return (
    <CurrencyProvider>
      <Currency />
    </CurrencyProvider>
  );
};
