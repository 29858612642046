import { FC, useEffect } from "react";
import { TableContainer } from "../style";
import { TableUI } from "src/components/UiKit/table";
import { ColumnsType } from "antd/es/table";
import { IGetCountry } from "src/services/System/models";
import { getRowKey } from "src/helpers/getRowKey";
import { useCountry } from "../context";
import { Guard } from "src/components/Guard";
import { EditCountryPath } from "src/services/System/guardPath";
import { Col } from "antd";

export const CountryTable: FC = () => {
  const {
    value: { tableData, tableLoading },
    dispatch: { setEditMode, setCountryId, setEditData },
    func: { getCountry },
  } = useCountry();

  const onEdit = (id: number, record: IGetCountry) => {
    setEditMode(true);
    setCountryId(id);
    setEditData(record);
  };

  const columns: ColumnsType<IGetCountry> = [
    {
      title: "#",
      key: "id",
      align: "center",
      width: "55px",
      render: (name, record, index) => index + 1,
      // (pagination.current - 1) * pagination.pageSize + (index + 1),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      align: "center",
      width: "264px",
    },
    {
      title: "Short name",
      dataIndex: "abbrivation",
      key: "abbrivation",
      align: "center",
      width: "264px",
    },
    {
      title: "Tools",
      key: "tools",
      align: "center",
      width: "130px",
      render: (text, record) => (
        <div className="flex justify-center items-center gap-[16px] text-[16px]">
          <Guard action={EditCountryPath}>
            <span
              onClick={() => {
                onEdit(record.id, record);
              }}
              className="material-icons !text-[#0C9D61] text-[16px] cursor-pointer"
            >
              edit_note
            </span>
          </Guard>
        </div>
      ),
    },
  ];

  useEffect(() => {
    getCountry();
  }, [getCountry]);

  return (
    <TableContainer justify="center">
      <Col span={15}>
        <TableUI
          style={{ padding: "none" }}
          columns={columns}
          rowKey={getRowKey}
          dataSource={tableData}
          loading={tableLoading}
          pagination={false}
        />
      </Col>
    </TableContainer>
  );
};
