import { FC, useState } from "react";
import { Button } from "antd";
import { ShipDailyReportFilterStyle } from "./style";
import { useShipDailyReports } from "../../context";
import { ROUTES } from "src/models/enums";
import { useNavigate } from "react-router-dom";
import useOwnerStore from "src/store/owner";

export const ShipDailyReportsFilter: FC = () => {
  const [filterType, setFilterType] = useState<string>("all");
  const {
    dispatch: { setFilterValue },
  } = useShipDailyReports();

  const { setHasSelectShip } = useOwnerStore();

  const navigate = useNavigate();

  const handleFilterReports = (type: string) => {
    setFilterType(type);
    const isSeen =
      type === "read" ? true : type === "unread" ? false : undefined;
    setFilterValue((prev) => ({ ...prev, IsSeen: isSeen }));
  };

  const handleClickDate = () => {
    setHasSelectShip(false);
    navigate(ROUTES.ShipDailyReportCalendar);
  };

  return (
    <ShipDailyReportFilterStyle>
      <div className="filter-btn">
        <Button
          type="text"
          className={`${
            filterType === "all" ? "active-filter-btn" : "inactive-filter-btn"
          }`}
          onClick={() => handleFilterReports("all")}
        >
          All
        </Button>
        <Button
          type="text"
          className={`center-btn ${
            filterType === "unread"
              ? "active-filter-btn"
              : "inactive-filter-btn"
          }`}
          onClick={() => handleFilterReports("unread")}
        >
          Unread
        </Button>
        <Button
          type="text"
          className={`${
            filterType === "read" ? "active-filter-btn" : "inactive-filter-btn"
          }`}
          onClick={() => handleFilterReports("read")}
        >
          Read
        </Button>
      </div>
      <div className="ship-reports-filter-divider"></div>
      <div className="date" onClick={handleClickDate}>
        <span className="material-icons text-[14px]">calendar_month</span>
      </div>
    </ShipDailyReportFilterStyle>
  );
};
