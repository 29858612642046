import styled from "styled-components";

export const AgentViewController = styled.div`
  .borderBottom {
    border-bottom: 1px solid var(--color-Light-Mode-Border-3, #dadeec);
  }
  .body {
    width: 850px;
    margin: 32px auto;
    padding: 32px 24px;
    border-radius: 20px;
    background: var(--secondary-primary-white, #fff);

    /* 2 */
    box-shadow: 0px 4px 6px 0px rgba(113, 130, 183, 0.25);
    p {
      margin-bottom: 10px !important;
      padding-left: 12px;
    }
    .KNnDb {
      padding: 0px !important;
    }
  }
`;
