import { App, Popconfirm, Tabs, TabsProps } from "antd";
import { FC, useEffect, useState } from "react";
import { TabsContainer } from "./styles";
import { ICategoryTabs } from "./models";
import { AddCategory } from "./components/AddCategory";
import { CategoriesTable } from "./components/CategoriesTable";
import { EditCategory } from "./components/EditCategory/index";
import { useCategory } from "./context";
import { SystemService } from "src/services/System/System.service";
import { ITreeCategory } from "src/services/System/models";
import { useCheckPermission } from "src/hook/checkRoutePermission";
import { Guard } from "src/components/Guard";
import {
  DeleteCategoryPath,
  EditCategoryPath,
  NewCategoryPath,
  TreeCategoryPath,
} from "src/services/System/guardPath";

export const Category: FC = () => {
  const [items, setItems] = useState<TabsProps["items"]>([]);

  const [activeKey, setActiveKey] = useState<string>(ICategoryTabs.addCategory);
  const [isCategoryCode] = useState<boolean>(true);
  const { message } = App.useApp();
  const { checkActionPermission } = useCheckPermission();
  const {
    value: { isEditMode, selectedCategories, category },
    dispatch: {
      setIsEditMode,
      setSelectedCategories,
      setLevels,
      setCategoryForEdit,
    },
    func: { getCategory },
  } = useCategory()!;
  const onChange = (key: string) => {
    setActiveKey(key);
  };
  const onClickEdit = () => {
    setIsEditMode(true);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const checkLevel = (cat: ITreeCategory) => {
    if (cat.childeren.length > 0) {
      cat.childeren.map((item) => checkLevel(item)).includes(true);
    }
    if (category.find((c) => c.id === cat.id)) {
      return true;
    } else return false;
  };
  const deleteCategory = async (id: number) => {
    try {
      const { DeleteCategory } = new SystemService();
      const res = await DeleteCategory(id);
      if (res && res.status === 200) {
        setLevels((prev) => []);

        setSelectedCategories([]);
        await getCategory();
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    const NewCategoryPathHasPermission = checkActionPermission(NewCategoryPath);
    const TreeCategoryPathHasPermission =
      checkActionPermission(TreeCategoryPath);
    if (NewCategoryPathHasPermission) {
      setItems((prev) => {
        const newItem = {
          key: ICategoryTabs.addCategory,
          label: (
            <span>
              <span className="material-icons text-[10px] p-[8px]">lens</span>
              Add category
            </span>
          ),
          children: <AddCategory />,
        };
        return prev ? [newItem, ...prev] : [newItem];
      });
      setActiveKey(ICategoryTabs.addCategory);
    }
    if (TreeCategoryPathHasPermission) {
      setItems((prev) => {
        const newTab = {
          key: ICategoryTabs.categoriesTable,
          label: (
            <span>
              <span className="material-icons text-[10px] p-[10px]">lens</span>
              Categories table
            </span>
          ),
          children: <CategoriesTable />,
        };
        return prev ? [...prev, newTab] : [newTab];
      });
      !NewCategoryPathHasPermission &&
        setActiveKey(ICategoryTabs.categoriesTable);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const tableHeaderItems = (
    <div className=" absolute right-[24px] top-[138px] z-10 flex gap-[24px]">
      <div className="flex gap-[16px] items-center">
        <div className="titleHolder">Category Code</div>
        <div className="boxHolder  h-[34px]">
          {selectedCategories[selectedCategories.length - 1]?.fullCode}
        </div>
      </div>
      <div className="flex gap-[16px] items-center justify-center">
        <span
          className={`material-icons text-[16px] ${
            isCategoryCode ? "text-[#2C73FC] cursor-pointer" : "text-[#A5C3FE]"
          }`}
          onClick={async () => {
            await navigator.clipboard
              .writeText(
                selectedCategories[selectedCategories.length - 1]?.fullCode
              )
              .finally(() => {
                message.success("Copied!");
              });
          }}
        >
          copy_all
        </span>
        <Guard action={EditCategoryPath}>
          <span
            className={`material-icons text-[16px] ${
              isCategoryCode
                ? "text-[#1DCF0E] cursor-pointer"
                : "text-[#B5F8AF]"
            }`}
            onClick={() => {
              if (selectedCategories[selectedCategories.length - 1]) {
                setCategoryForEdit(
                  selectedCategories[selectedCategories.length - 1]
                );
                onClickEdit();
              }
            }}
          >
            edit_note
          </span>
        </Guard>
        <Guard action={DeleteCategoryPath}>
          <Popconfirm
            icon=""
            placement="bottom"
            title={<div></div>}
            disabled={!selectedCategories[selectedCategories.length - 1]?.id}
            description={
              <div className="border-[1px] border-solid border-[#DADEEC] p-[48px] rounded-[12px]">
                Are you sure you want to delete this category?
              </div>
            }
            onConfirm={() => {
              if (selectedCategories[selectedCategories.length - 1]) {
                deleteCategory(
                  selectedCategories[selectedCategories.length - 1].id
                );
              }
            }}
            rootClassName="deletePopconfirm"
            okText={
              <div className="flex gap-[12px] items-center">
                <span
                  className={`material-icons text-[#Fff] text-[16px] cursor-pointer`}
                >
                  delete_forever
                </span>
                <div className="text-[#fff]">Delete</div>
              </div>
            }
            cancelText={
              <div className="flex gap-[12px] items-center">
                <span
                  className={`material-icons text-[#8E8E8E] text-[16px] cursor-pointer`}
                >
                  close
                </span>
                <div className="text-[#8E8E8E]">Cancel</div>
              </div>
            }
          >
            <span
              className={`material-icons text-[16px] ${
                selectedCategories[selectedCategories.length - 1]?.id
                  ? "text-[#FF1414] cursor-pointer"
                  : "text-[#FD7373] cursor-not-allowed"
              }`}
            >
              delete_forever
            </span>
          </Popconfirm>
        </Guard>
      </div>
    </div>
  );
  return isEditMode ? (
    <EditCategory />
  ) : (
    <TabsContainer>
      {activeKey === ICategoryTabs.categoriesTable ? tableHeaderItems : ""}
      <Tabs items={items} onChange={onChange} activeKey={activeKey} animated />
    </TabsContainer>
  );
};
