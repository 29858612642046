import { FC, PropsWithChildren } from "react";
import { Form, Popconfirm } from "antd";
import { SelectUiKit } from "src/components/UiKit/select";
import { FrontPathType } from "src/modules/frontAccess/models";
import { SInput } from "src/components/UiKit/Input";
import { FormInstance } from "antd/lib";
import { useFrontAccess } from "src/modules/frontAccess/context";
import { IObject } from "src/models/interfaces";

export const FilterPopUp: FC<PropsWithChildren> = ({ children }) => {
  const [frontAccessFilterForm] = Form.useForm();
  const {
    func: { getFrontPath },
  } = useFrontAccess();

  const cancel = (e: React.MouseEvent<HTMLElement> | undefined) => {
    frontAccessFilterForm.resetFields();
    getFrontPath();
  };

  return (
    <Popconfirm
      placement="bottomLeft"
      title=""
      description={<FilterForm formName={frontAccessFilterForm} />}
      onConfirm={() => frontAccessFilterForm.submit()}
      onCancel={cancel}
      okText="ok"
      cancelText="Reset"
      icon={""}
      rootClassName="frontAccessFilterPopUp"
    >
      {children}
    </Popconfirm>
  );
};

const FilterForm: FC<{ formName: FormInstance }> = ({ formName }) => {
  const {
    func: { getFrontPath },
  } = useFrontAccess();

  const onFinishFilter = async (values: IObject) => {
    getFrontPath(values);
  };

  return (
    <Form form={formName} onFinish={onFinishFilter}>
      <Form.Item name="Name">
        <SInput placeholder="name" />
      </Form.Item>
      <Form.Item name="PathType">
        <SelectUiKit
          allowClear
          options={[
            { label: "Page", value: FrontPathType.Page },
            { label: "Modal", value: FrontPathType.Modal },
          ]}
        />
      </Form.Item>
    </Form>
  );
};
