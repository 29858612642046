import { Row } from "antd";
import { styled } from "styled-components";

export const AttachZipFinallyContainer = styled(Row)`
  margin-top: 8px;
  padding: 0 32px;
  .title {
    color: #001377;
    font-family: DM Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding-bottom: 32px;
    padding-top: 32px;
  }
  .footer {
    border-top: 1px solid #dadeec;
    padding-top: 32px;
    padding-bottom: 16px;
    margin-top: 80px;
  }
`;
