import styled from "styled-components";

export const AgentEditContainer = styled.div`
  .borderBottom {
    border-bottom: 1px solid var(--color-Light-Mode-Border-3, #dadeec);
  }
  .middle {
    display: flex;
    /* padding-bottom: 16px; */
    justify-content: space-between;
    align-items: flex-end;
    align-self: stretch;
    gap: 41px;
    margin: 24px 0px;
    .br {
      border-radius: 8px;
      border: 1px solid var(--color-Primary-Primary-600, #2c73fc);
    }
  }
`;
