import { FC, Suspense } from "react";
import { CreateRole } from "src/modules/CreateRole";
import { CreateRoleProvider } from "src/modules/CreateRole/context";
import { PreLoading } from "src/routes/Loading";
export const CreateRolePage: FC = () => {
  return (
    <Suspense fallback={<PreLoading />}>
      <CreateRoleProvider>
        <CreateRole />
      </CreateRoleProvider>
    </Suspense>
  );
};
