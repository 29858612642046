import {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { AttachInvoiceFile } from ".";
import { InvoiceService } from "src/services/Invoice/Invoice.service";
import { UploadFile } from "antd/lib";
import { Form, FormInstance, Popconfirm, TableProps } from "antd";
import {
  IAllRequestOPT,
  IAllRequestZipAttachment,
} from "src/services/Invoice/models";
import dayjs from "dayjs";
import _ from "lodash";
import { Guard } from "src/components/Guard";
import {
  DeleteAllRequestAttachmentPath,
  UpdateAllRequestZipDetailPath,
} from "src/services/Invoice/guardPath";
import { ITablePagination } from "src/models/interfaces/pagination";
interface IContext {
  value: {
    isModalOpenAttachInvoice: boolean;
    zipFileCode: string | undefined;
    currentRefNumber: number | undefined;
    defaultFileList: UploadFile[];
    refetchZipFileDependency: number;
    form: FormInstance | undefined;
    tableColumn: TableProps<IAllRequestZipAttachment>["columns"];
    dataSource: IAllRequestZipAttachment[];
    editMode: boolean;
    tableLoading: boolean;
    currentEditRecord: IAllRequestZipAttachment | undefined;
    // searchRefNo: string;
    allReferenceNumber: IAllRequestOPT[];
    referenceNumberLoading: boolean;
    pagination: ITablePagination;
  };
  dispatch: {
    setIsModalOpenAttachInvoice: Dispatch<SetStateAction<boolean>>;
    setZipFileCode: Dispatch<SetStateAction<string | undefined>>;
    setCurrentRefNumber: Dispatch<SetStateAction<number | undefined>>;
    setDefaultFileList: Dispatch<SetStateAction<UploadFile[]>>;
    setEditMode: Dispatch<SetStateAction<boolean>>;
    setCurrentEditRecord: Dispatch<
      SetStateAction<IAllRequestZipAttachment | undefined>
    >;
    setPagination: Dispatch<SetStateAction<ITablePagination>>;
  };
  func: {
    refetchZipFile: () => void;
    fetchAllRequestZipAttachment: () => void;
    refetchAllRefNumber: () => void;
    onSearch: (text: string) => void;
  };
}

export const AttachInvoiceFileContext = createContext<IContext | undefined>(
  undefined
);

export const AttachInvoiceFileProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const [isModalOpenAttachInvoice, setIsModalOpenAttachInvoice] =
    useState<boolean>(false);
  const [zipFileCode, setZipFileCode] = useState<string>();
  const [currentRefNumber, setCurrentRefNumber] = useState<number>();
  const [defaultFileList, setDefaultFileList] = useState<UploadFile[]>([]);
  const [dataSource, setDataSource] = useState<IAllRequestZipAttachment[]>([]);
  const [tableLoading, setTableLoading] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [pagination, setPagination] = useState<ITablePagination>({
    current: 1,
    pageSize: 10,
    total: dataSource.length,
  });
  const [form] = Form.useForm();
  // const [searchRefNo, setSearchRefNo] = useState<string>("");
  const [currentEditRecord, setCurrentEditRecord] =
    useState<IAllRequestZipAttachment>();
  const [allReferenceNumber, setAllReferenceNumber] = useState<
    IAllRequestOPT[]
  >([]);
  const [referenceNumberLoading, setReferenceNumberLoading] =
    useState<boolean>(false);
  const [refetchZipFileDependency, setRefetchZipFileDependency] =
    useState<number>(0);

  const fetchAllRequestOPT = _.debounce(async (referenceNumber?: string) => {
    try {
      setReferenceNumberLoading(true);
      const { AllRequestOPT } = new InvoiceService();
      const res = await AllRequestOPT(referenceNumber);
      if (res && res.status === 200 && res.data) {
        setAllReferenceNumber(res.data);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setReferenceNumberLoading(true);
    }
  }, 200);
  const onSearch = (text: string) => {
    fetchAllRequestOPT(text);
  };
  const refetchZipFile = () => {
    setRefetchZipFileDependency((prev) => prev + 1);
  };

  const fetchAllRequestZipAttachment = useCallback(async () => {
    if (!currentRefNumber) return;
    try {
      setTableLoading(true);
      const { AllRequestZipAttachment } = new InvoiceService();
      const res = await AllRequestZipAttachment(currentRefNumber);
      if (res && res.data) {
        setDataSource(res.data);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setTableLoading(false);
    }
  }, [currentRefNumber]);
  const onDeleteRecord = async (id: number) => {
    try {
      const { DeleteAllRequestAttachment } = new InvoiceService();
      const res = await DeleteAllRequestAttachment(id);
      if (res && res.status === 200) {
        fetchAllRequestZipAttachment();
      }
    } catch (err) {}
  };
  const tableColumn: TableProps<IAllRequestZipAttachment>["columns"] = [
    {
      key: "#",
      title: "#",
      render: (text, record, index) =>
        (pagination.current - 1) * pagination.pageSize + (index + 1),
      align: "center",
    },
    {
      key: "code",
      dataIndex: "code",
      title: "zip file reference No.",
      align: "center",
    },
    {
      key: "attachNumber",
      dataIndex: "attachNumber",
      title: "Attach No.",
      align: "center",
    },
    {
      key: "attachDate",
      dataIndex: "attachDate",
      title: "Attach date",
      align: "center",
      render: (text) => dayjs(text).format("YYYY-MM-DD"),
    },
    {
      key: "fileName",
      dataIndex: "fileName",
      title: "Reqester file name",
      align: "center",
    },
    {
      key: "tools",
      title: "Tools",
      render: (text, record) => (
        <div className="flex items-center justify-center gap-[16px]">
          <Guard action={UpdateAllRequestZipDetailPath}>
            <span
              className="material-icons text-[#01C18F] text-[16px] cursor-pointer select-none"
              onClick={() => {
                const attachDate = dayjs(record.attachDate);
                const attachNumber = record.attachNumber;
                form.setFieldsValue({ attachDate, attachNumber });
                setEditMode(true);
                setDefaultFileList([
                  {
                    name: record.fileName,
                    uid: record.id.toString(),
                  },
                ]);
                setCurrentEditRecord(record);
              }}
            >
              edit
            </span>
          </Guard>
          <Guard action={DeleteAllRequestAttachmentPath}>
            <Popconfirm
              title="Are you sure to delete ?"
              rootClassName="deletePopconfirm"
              onConfirm={() => onDeleteRecord(record.id)}
              icon={null}
            >
              <span className="material-icons text-[#F64C4C] text-[16px] cursor-pointer select-none">
                delete_forever
              </span>
            </Popconfirm>
          </Guard>
        </div>
      ),
    },
  ];
  useEffect(() => {
    fetchAllRequestZipAttachment();
  }, [fetchAllRequestZipAttachment]);
  useEffect(() => {
    fetchAllRequestOPT();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const contextValue: IContext = {
    value: {
      isModalOpenAttachInvoice,
      zipFileCode,
      currentRefNumber,
      defaultFileList,
      refetchZipFileDependency,
      form,
      tableColumn,
      dataSource,
      editMode,
      tableLoading,
      currentEditRecord,
      allReferenceNumber,
      referenceNumberLoading,
      pagination,
    },
    dispatch: {
      setIsModalOpenAttachInvoice,
      setZipFileCode,
      setCurrentRefNumber,
      setDefaultFileList,
      setEditMode,
      setCurrentEditRecord,
      setPagination,
    },
    func: {
      refetchZipFile,
      refetchAllRefNumber: fetchAllRequestOPT,
      fetchAllRequestZipAttachment,
      onSearch,
    },
  };
  return (
    <AttachInvoiceFileContext.Provider value={contextValue}>
      {children}
      <AttachInvoiceFile />
    </AttachInvoiceFileContext.Provider>
  );
};

export const useAttachInvoiceFileContext = () =>
  useContext(AttachInvoiceFileContext);
