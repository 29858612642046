import { FC } from "react";
import { Divider, Flex } from "antd";
import { useFrontAccess } from "src/modules/frontAccess/context";

export const AssignRoleHeader: FC = () => {
  const {
    value: { frontAccessForEdit },
  } = useFrontAccess();

  return (
    <>
      <Flex justify="space-between">
        <Flex vertical>
          <span className="font-bold text-[18px] text-[#001377]">
            Assign role
          </span>
          <span className="font-bold text-[14px] text-[#5D6881]">
            {frontAccessForEdit?.name}
          </span>
        </Flex>
      </Flex>
      <Divider className="p-0 mt-2" />
    </>
  );
};
