import { FC, useState } from "react";
import { App, Button, Col, Form, Row } from "antd";
import { FormItem } from "src/components/UiKit/FormItem";
import { SInput } from "src/components/UiKit/Input";
import { SystemService } from "src/services/System/System.service";
import { INewFrontAccess } from "src/services/System/models";
import { SelectUiKit } from "src/components/UiKit/select";
import { AddFrontAccessContainer } from "./style";
import { useFrontAccess } from "../../context";
import { FrontPathType } from "../../models";

export const AddFrontAccess: FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const { message } = App.useApp();
  const [form] = Form.useForm();
  const {
    func: { getFrontPath },
  } = useFrontAccess()!;

  const onFinish = async (value: INewFrontAccess) => {
    setLoading(true);
    const service = new SystemService();
    const reqBody: INewFrontAccess = {
      name: value.name,
      displayName: value.displayName,
      pathType: value.pathType,
    };
    try {
      const response = await service.NewFrontPath(reqBody);
      if (response && response.status === 200) {
        await getFrontPath().finally(() => {
          form.resetFields();
          message.success("Front access create successfully");
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form form={form} onFinish={onFinish}>
      <AddFrontAccessContainer>
        <Row gutter={[8, 16]}>
          <Col span={24}>
            <div className="pt-[16px] titleHolder">
              Please insert the information.
            </div>
          </Col>
          <Col xs={24} md={12}>
            <FormItem
              name="name"
              label="Name"
              rules={[{ required: true }]}
              required={true}
            >
              <SInput placeholder="insert" />
            </FormItem>
          </Col>
          <Col xs={24} md={12}>
            <FormItem
              name="displayName"
              label="Display name"
              rules={[{ required: true }]}
              required={true}
            >
              <SInput placeholder="insert" />
            </FormItem>
          </Col>
          <Col xs={24} md={12}>
            <FormItem
              name="pathType"
              label="Path type"
              rules={[{ required: true }]}
              required={true}
            >
              <SelectUiKit
                options={[
                  { label: "Page", value: FrontPathType.Page },
                  { label: "Modal", value: FrontPathType.Modal },
                ]}
              />
            </FormItem>
          </Col>

          <Col span={24} className="flex justify-end footer">
            <Button
              type="primary"
              className="footerButton"
              onClick={form.submit}
              loading={loading}
            >
              Create
            </Button>
          </Col>
        </Row>
      </AddFrontAccessContainer>
    </Form>
  );
};
