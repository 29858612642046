import axios from "axios";
import {
  IAddAction,
  IAddRoleUser,
  IDeleteWorkFlowStepRoleUserArg,
  IEditWorkFlowStep,
  INewWorkFlowArg,
  INewWorkFlowStep,
  IWorkFlowParams,
  IWorkFlowStep,
  IWorkFlowStepAction,
  IWorkFlowStepParams,
  IWorkFlowStepRoleUser,
  IWorkFlows,
} from "./models";
import { WorkFlowUrls } from "./urls";
import { IDataModel } from "../models";
import { checkActionPermission } from "src/helpers/checkPermissionFunc";
import * as GuardPaths from "./guardPath";
import { permissionLog } from "src/helpers/permissionLog";
export class WorkFlowService {
  async NewWorkFlow(data: INewWorkFlowArg) {
    if (!checkActionPermission(GuardPaths.NewWorkFlowPath)) {
      permissionLog(GuardPaths.NewWorkFlowPath.path);
      return undefined;
    }
    try {
      const response = await axios.post<number>(WorkFlowUrls.NewWorkFlow, data);
      return response;
    } catch (err) {
      console.log(err);
    }
  }
  async EditWorkFlow(id: number, data: INewWorkFlowArg) {
    if (!checkActionPermission(GuardPaths.EditWorkFlowPath)) {
      permissionLog(GuardPaths.EditWorkFlowPath.path);
      return undefined;
    }
    try {
      const response = await axios.put<number>(
        WorkFlowUrls.WorkFlow.concat(id.toString()).concat(
          WorkFlowUrls.EditWorkFlow
        ),
        data
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
  async NewWorkFlowStep(data: INewWorkFlowStep) {
    if (!checkActionPermission(GuardPaths.NewWorkFlowStepPath)) {
      permissionLog(GuardPaths.NewWorkFlowStepPath.path);
      return undefined;
    }
    try {
      const response = await axios.post<number>(
        WorkFlowUrls.NewWorkFlowStep,
        data
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
  async DeleteWorkFlow(WorkFlowId: number) {
    if (!checkActionPermission(GuardPaths.DeleteWorkFlowPath)) {
      permissionLog(GuardPaths.DeleteWorkFlowPath.path);
      return undefined;
    }
    try {
      const response = await axios.delete<void>(
        WorkFlowUrls.WorkFlow.concat(WorkFlowId.toString()).concat(
          WorkFlowUrls.DeleteWorkFlow
        )
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
  async WorkFlowStep(id: number, params?: IWorkFlowStepParams) {
    if (!checkActionPermission(GuardPaths.WorkFlowStepPath)) {
      permissionLog(GuardPaths.WorkFlowStepPath.path);
      return undefined;
    }
    let searchParams = "";
    if (params) {
      searchParams =
        "?" + new URLSearchParams(JSON.stringify(params)).toString();
    }
    try {
      const response = await axios.get<IDataModel<IWorkFlowStep[]>>(
        WorkFlowUrls.WorkFlow.concat(id.toString())
          .concat(WorkFlowUrls.WorkFlowStep)
          .concat(searchParams)
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
  async EditWorkFlowStep(workFlowStepId: number, data: IEditWorkFlowStep) {
    if (!checkActionPermission(GuardPaths.EditWorkFlowStepPath)) {
      permissionLog(GuardPaths.EditWorkFlowStepPath.path);
      return undefined;
    }
    try {
      const response = await axios.put<void>(
        WorkFlowUrls.WorkFlow.concat(workFlowStepId.toString()).concat(
          WorkFlowUrls.EditWorkFlowStep
        ),
        data
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
  async AddRoleUser(WorkFlowStepId: number, data: IAddRoleUser) {
    if (!checkActionPermission(GuardPaths.AddRoleUserPath)) {
      permissionLog(GuardPaths.AddRoleUserPath.path);
      return undefined;
    }
    try {
      const response = await axios.post<number>(
        WorkFlowUrls.WorkFlow.concat(WorkFlowStepId.toString()).concat(
          WorkFlowUrls.AddRoleUser
        ),
        data
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
  async AddAction(WorkFlowStepId: number, data: IAddAction) {
    if (!checkActionPermission(GuardPaths.AddActionPath)) {
      permissionLog(GuardPaths.AddActionPath.path);
      return undefined;
    }
    try {
      const response = await axios.post<number>(
        WorkFlowUrls.WorkFlow.concat(WorkFlowStepId.toString()).concat(
          WorkFlowUrls.AddAction
        ),
        data
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
  async WorkFlowStepRoleUser(WorkFlowStepId: number) {
    if (!checkActionPermission(GuardPaths.WorkFlowStepRoleUserPath)) {
      permissionLog(GuardPaths.WorkFlowStepRoleUserPath.path);
      return undefined;
    }
    try {
      const response = await axios.get<IWorkFlowStepRoleUser[]>(
        WorkFlowUrls.WorkFlow.concat(WorkFlowStepId.toString()).concat(
          WorkFlowUrls.WorkFlowStepRoleUser
        )
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
  async GetWorkFlowActions(WorkFlowStepId: number) {
    if (!checkActionPermission(GuardPaths.GetWorkFlowActionsPath)) {
      permissionLog(GuardPaths.GetWorkFlowActionsPath.path);
      return undefined;
    }
    try {
      const response = await axios.get<IWorkFlowStepAction[]>(
        WorkFlowUrls.WorkFlow.concat(WorkFlowStepId.toString()).concat(
          WorkFlowUrls.GetWorkFlowActions
        )
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
  async DeleteWorkFlowStep(WorkFlowStepId: number) {
    if (!checkActionPermission(GuardPaths.DeleteWorkFlowStepPath)) {
      permissionLog(GuardPaths.DeleteWorkFlowStepPath.path);
      return undefined;
    }
    try {
      const response = await axios.delete<void>(
        WorkFlowUrls.WorkFlow.concat(WorkFlowStepId.toString()).concat(
          WorkFlowUrls.DeleteWorkFlowStep
        )
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
  async DeleteWorkFlowStepRoleUser(
    WorkFlowStepId: number,
    data: IDeleteWorkFlowStepRoleUserArg
  ) {
    if (!checkActionPermission(GuardPaths.DeleteWorkFlowStepRoleUserPath)) {
      permissionLog(GuardPaths.DeleteWorkFlowStepRoleUserPath.path);
      return undefined;
    }
    try {
      const response = await axios.delete<void>(
        WorkFlowUrls.WorkFlow.concat(WorkFlowStepId.toString()).concat(
          WorkFlowUrls.DeleteWorkFlowStepRoleUser
        ),
        { data }
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
  async DeleteWorkFlowStepAction(WorkFlowStepActionId: number) {
    if (!checkActionPermission(GuardPaths.DeleteWorkFlowStepActionPath)) {
      permissionLog(GuardPaths.DeleteWorkFlowStepActionPath.path);
      return undefined;
    }
    try {
      const response = await axios.delete<void>(
        WorkFlowUrls.WorkFlow.concat(WorkFlowStepActionId.toString()).concat(
          WorkFlowUrls.DeleteWorkFlowStepAction
        )
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
  async GetWorkFlows(params?: IWorkFlowParams) {
    if (!checkActionPermission(GuardPaths.WorkFlowPath)) {
      permissionLog(GuardPaths.WorkFlowPath.path);
      return undefined;
    }
    let searchParams = "";
    if (params) {
      const searchToString = new URLSearchParams(params);
      searchParams = "?".concat(searchToString.toString());
    }
    try {
      const response = await axios.get<IDataModel<IWorkFlows[]>>(
        WorkFlowUrls.GetWorkFlows.concat(searchParams)
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
}
