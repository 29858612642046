import { Button, Modal } from "antd";
import { FC } from "react";
import { DeleteConfirmWrapper } from "../styles/deleteConfirm";
import { IPath } from "src/services/Identity/models";
import { Guard } from "src/components/Guard";

interface IProps {
  open: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  loading: boolean;
  title: string;
  deleteAction: IPath;
}
export const DeleteArticleConfirm: FC<IProps> = ({
  onCancel,
  open,
  onConfirm,
  loading,
  title,
  deleteAction,
}) => {
  return (
    <Modal
      title={title}
      open={open}
      onCancel={onCancel}
      footer={
        <div className="flex justify-center items-center gap-[16px]">
          <Button
            className="flex justify-center items-center gap-[12px]"
            icon={
              <span className="material-symbols-outlined text-[#8E8E8E] text-[20px] cursor-pointer">
                close
              </span>
            }
            onClick={onCancel}
          >
            close
          </Button>
          <Guard action={deleteAction}>
            <Button
              className="flex justify-center items-center gap-[12px]"
              type="primary"
              danger
              icon={
                <span className="material-symbols-outlined text-[#fff] text-[20px] cursor-pointer">
                  delete_forever
                </span>
              }
              onClick={onConfirm}
              loading={loading}
            >
              Delete
            </Button>
          </Guard>
        </div>
      }
    >
      <DeleteConfirmWrapper>
        <div className="content">Are you sure you want to delete?</div>
      </DeleteConfirmWrapper>
    </Modal>
  );
};
