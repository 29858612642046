import { FC, Suspense } from "react";
import { AssignActionToRole } from "src/modules/AssignActionToRole";
import { AssignActionToRoleProvider } from "src/modules/AssignActionToRole/context";
import { PreLoading } from "src/routes/Loading";

export const AssignActionToRolePage: FC = () => {
  return (
    <Suspense fallback={<PreLoading />}>
      <AssignActionToRoleProvider>
        <AssignActionToRole />
      </AssignActionToRoleProvider>
    </Suspense>
  );
};
