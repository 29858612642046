import { FC } from "react";
import { OthersInfoProvider } from "./context";
import { OthersInfoForm } from "./OthersInfoForm";

export const OthersInfo: FC = () => {
  return (
    <OthersInfoProvider>
      <OthersInfoForm />
    </OthersInfoProvider>
  );
};
