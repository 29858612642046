import { Row } from "antd";
import { styled } from "styled-components";

export const CardContainer = styled(Row)`
  padding-inline: unset;
  display: flex;
  .littleCard {
    display: grid;
    grid-template-columns: auto auto auto auto auto auto;
    overflow-x: auto;
    padding: 0px 32px 24px 24px;
  }

  .shipContainer {
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    gap: 20px;
    width: 100%;
  }

  .shiplistContainer {
    background: #ecf1fd;
    width: 100%;
    height: 100%;
    margin-top: 24px;
  }
  .buttonContainer {
    display: flex;
    flex-direction: row;
    align-content: end;
    border: none !important;
    background: none;
    color: #4318ff;
  }
  .addInvoiceContainer {
    margin-top: 32px;
    border: none;
    border: none !important;
    background: none;
    color: #4318ff;
    align-items: center;
    box-shadow: none;
  }
  .imageContainer {
    margin-top: 0px;
    margin-bottom: 24px;
  }
  .ant-card-body {
    padding-top: 0;
    padding-bottom: 0;
  }
  .ant-divider {
    color: #0000;
    font-weight: 100;
  }
  .shipList {
    margin-top: 24px;
    margin-bottom: 24px;
    font-size: 24px;
    font-weight: 700;
  }
  .addContainer {
    background: #f6f8fd;
    width: 56px;
    height: 56px;
    border-radius: 10px;
    margin-right: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .addButton {
    color: #3b82f6;
    border: none;
    background: #f6f8fd;
    box-shadow: none;
  }
  .addFont {
    color: #a3aed0;
    display: flex;
    justify-content: center;
  }
  .clickedStyle {
    display: flex;
    flex-wrap: wrap;
  }
`;
