import { FC } from "react";
import { Divider, Flex, Spin } from "antd";
import _ from "lodash";
import { SInput } from "src/components/UiKit/Input";
import { IDocument } from "src/services/Document/models";
import DocumentsCard from "./DocumentsCard";
import { FoldersFilesContainer } from "./styles";
import { mainPath, useDocumentation } from "../../context";

const FoldersFiles: FC = () => {
  const {
    value: { documents, path, filterValue, dataLoading },
    dispatch: { setFilterValue },
  } = useDocumentation();

  const parentName = () => {
    if (path === mainPath) return path;
    const pathArray = path.split("/");
    return pathArray.slice(-1);
  };

  const countItems = () => {
    const filteredItems = documents.filter(
      (item: IDocument) => item.parentId === filterValue.ParentId
    );
    return filteredItems.length;
  };

  var debounce_fun = _.debounce(function (value: string) {
    setFilterValue((prev) => ({
      ...prev,
      Name: value && value !== "" ? value.toUpperCase() : undefined,
    }));
  }, 700);

  return (
    <>
      <FoldersFilesContainer>
        <Flex justify="space-between">
          <Flex vertical={true}>
            <span className="text-[#5D6881] text-[16px] font-bold">
              {parentName()}
            </span>
            <span className="text-[#878787] text-[12px] font-normal">
              ({path === mainPath ? documents.length : countItems()} Items)
            </span>
          </Flex>
          <div className="w-[300px]">
            <SInput
              allowClear
              placeholder="Search folder or file"
              prefix={
                <span className="material-icons text-[14px] text-[#878787]">
                  search
                </span>
              }
              onChange={(event) => {
                debounce_fun(event.target.value);
              }}
            />
          </div>
        </Flex>
        <Divider />

        <div className="flex justify-center min-h-[346px] flex-wrap">
          {dataLoading ? (
            <Spin size="large" />
          ) : documents.length > 0 ? (
            <DocumentsCard />
          ) : (
            <p className="w-[378px] text-center text-[#A3AED0] text-[32px] font-medium">
              You have not added any folder or file yet.
            </p>
          )}
        </div>
      </FoldersFilesContainer>
    </>
  );
};

export default FoldersFiles;
