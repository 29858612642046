import { FC } from "react";
import { TableContainer } from "./styles";
import { useInvoiceReport } from "../../context";
import { TableUI } from "src/components/UiKit/table";
import { IReportAllRequestAttachment } from "src/services/InvoiceReport/models";
import { ColumnsType } from "antd/es/table";
import moment from "moment";
import { INVOICE_REPORT_TYPES } from "../../models/reportTypes";

export const AttachmentReportTable: FC = () => {
  const {
    value: {
      loadings,
      AttachmentData,
      AttachmentPagination,
      currentRequestValue,
      currentAllRequest,
    },
    dispatch: {
      setAttachmentPagination,
      setCommentModal,
      setReportType,
      setCurrentAttachment,
    },
    func: { fetchInvoice, fetchAttachment },
  } = useInvoiceReport();
  const columns: ColumnsType<IReportAllRequestAttachment> = [
    {
      title: "#",
      key: "key",
      className: "!font-bold",
      align: "center",
      render: (text, record, index) =>
        (AttachmentPagination.Offset - 1) * AttachmentPagination.Limit +
        (index + 1),
    },
    {
      title: "Attachment code",
      dataIndex: "attachmentCode",
      key: "attachmentCode",
      className: "!font-bold",
      align: "center",
    },
    {
      title: "Ship",
      dataIndex: "shipName",
      key: "shipName",
      className: "!font-bold",
      align: "center",
    },
    {
      title: "Requester File name",
      dataIndex: "requesterFileName",
      key: "requesterFileName",
      className: "!font-bold",
      align: "center",
    },
    {
      title: "Invoice added",
      dataIndex: "invoiceCount",
      key: "invoiceCount",
      className: "!font-bold",
      align: "center",
    },
    {
      title: "Created date",
      dataIndex: "created",
      key: "created",
      className: "!font-bold",
      align: "center",
      render: (text) => moment(text).format("YYYY-MM-DD"),
    },
    {
      title: "Tools",
      key: "tools",
      className: "!font-bold ",
      align: "center",
      render: (text, record) => {
        return (
          <div className="flex justify-between items-center gap-[16px] w-[72px]">
            <span
              className="material-symbols-outlined cursor-pointer text-[16px]"
              onClick={() => {
                setCommentModal({
                  showModal: true,
                  title: record.attachmentCode,
                  description: record.comment,
                });
              }}
            >
              mode_comment
            </span>
            <div
              className="text-[#2C73FC] cursor-pointer select-none text-[12px]"
              onClick={() => {
                if (currentRequestValue) {
                  setCurrentAttachment(record);
                  fetchInvoice(currentRequestValue, record.attachmentId);
                  setReportType(INVOICE_REPORT_TYPES.Invoice);
                }
              }}
            >
              Detail
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <TableContainer>
      <TableUI
        dataSource={AttachmentData}
        columns={columns}
        scroll={{ x: "auto" }}
        loading={loadings.attachment}
        rowKey={(record) => record.attachmentId}
        pagination={{
          position: ["bottomCenter"],
          pageSize: AttachmentPagination.Limit,
          current: AttachmentPagination.Offset,
          total: AttachmentPagination.total,
          onChange: (page, pageSize) => {
            if (currentRequestValue) {
              fetchAttachment(
                currentRequestValue,
                currentAllRequest?.allRequestId,
                {
                  Limit: pageSize,
                  Offset: pageSize !== AttachmentPagination.Limit ? 1 : page,
                }
              );
            }
            setAttachmentPagination((prev) => ({
              ...prev,
              Limit: pageSize,
              Offset: pageSize !== prev.Limit ? 1 : page,
            }));
          },
        }}
      />
    </TableContainer>
  );
};
