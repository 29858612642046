import { FC } from "react";
import { PayerTabs } from "./components/payerTabs";
import { PayerContainer } from "./style";
import { usePayer } from "./context";
import { Col, Row } from "antd";
import { AddNewPayer } from "./components/addPayer";
import { Guard } from "src/components/Guard";
import {
  AllPayersPath,
  NewPayerPath,
  UpdatePayerPath,
} from "src/services/Income/guardPath";

export const Payer: FC = () => {
  const {
    value: { editMode },
    func: { onBack },
  } = usePayer();

  return (
    <PayerContainer>
      {editMode ? (
        <Row>
          <Col span={24}>
            <div
              onClick={onBack}
              className="flex justify-between items-center py-[19px] px-[24px] borderBottom"
            >
              <div className="flex flex-row gap-[12px] items-center cursor-pointer">
                <span className="material-icons text-[#2C73FC] text-[32px]">
                  keyboard_backspace
                </span>
                <div className=" text-[#2C73FC] text-[18px] ">
                  Back to table
                </div>
              </div>
              <div className="flex flex-col ">
                <div className="text-[#001377] text-[18px] font-semibold">
                  Edit
                </div>
                <div className="text-[#5D6881] text-[14px] font-bold">
                  Receiver
                </div>
              </div>
            </div>
          </Col>
          <Col span={24}>
            <Guard action={UpdatePayerPath}>
              <AddNewPayer />
            </Guard>
          </Col>
        </Row>
      ) : (
        <Guard action={[AllPayersPath, NewPayerPath]} multiPath>
          <PayerTabs />
        </Guard>
      )}
    </PayerContainer>
  );
};
