import { Row } from "antd";
import styled from "styled-components";

export const ViewDetailsContainer = styled(Row)`
  background-color: #fff;
  padding: 32px 0;

  .actions {
    border-top: solid 1px #dadeec;
    padding-top: 24px;
  }
  .edit-btn {
    border: solid 1px #2c73fc;
    color: #2c73fc;
    font-weight: 500;
    font-size: 12px;
  }
  .header {
    width: 100%;
    height: 41px;
    padding: 9px 32px;
    span {
      color: #fff;
      font-weight: 600;
      font-size: 18px;
    }

    .date {
      position: absolute;
      right: 15px;
    }
    .title {
      display: inline-flex;
      justify-content: center;
      align-items: center;
    }
  }

  .voyage-section {
    background-color: #f2f7ff;
    padding: 24px 16px;
    border-radius: 8px;
  }
  .info-key {
    font-weight: 500;
    font-size: 14px;
    color: #001377;
    text-align: right;
    width: 50%;
    text-align: left;
  }
  .info-value {
    font-weight: 400;
    font-size: 14px;
    color: #5d6881;
    width: 50%;
    text-align: left;
  }
`;
