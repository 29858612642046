import { Flex, Row } from "antd";
import styled from "styled-components";

export const CategoryContent = styled.div`
  max-width: calc(100vw - 290px - 32px - 16px);
  min-width: 332px;
  border-radius: 16px;
  background: var(--0, #fff);
  box-shadow: 0px 4px 6px 0px rgba(113, 130, 183, 0.25);
  overflow-x: auto;
  height: 596px;
  transition: all 0.5s;
`;
export const CategoryWrapper = styled(Flex)`
  display: inline-flex;
  height: 596px;
`;

export const CategoriesTableContainer = styled(Row)`
  /* display: flex;
  align-content: flex-start; */
  padding: 24px 16px 32px 16px;
  /* flex-direction: row; */
  height: 596px;

  gap: 16px;
  overflow-y: auto;
  width: 333px;
  height: 100%;

  .titleHolder {
    color: var(--sidebar-icon, #a3aed0);
    font-family: DM Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .shipHolder {
    color: var(--1, #000);
    font-family: DM Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .border {
    border-bottom: 1px solid #dadeec;
  }
`;
export const CategoriesChildContainer = styled(Row)`
  .titleHolder {
    color: var(--text-3, #5d6881);
    font-family: DM Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .subTitleHolder {
    color: var(--text-2, #a3aed0);
    font-family: DM Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .itemsCountHolder {
    color: var(--text-6, #878787);
    font-family: DM Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .border {
    border-bottom: 1px solid #dadeec;
  }
`;
export const CategoriesChildItemsContainer = styled.div`
  display: flex;
  width: 300px;
  padding: 12px;
  align-items: center;
  gap: 12px;
  border-radius: 4px;
  justify-content: space-between;
  margin-top: 16px;
  user-select: none;
  cursor: pointer;
  .codeHolder {
    color: var(--text, #001377);
    font-family: DM Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 56px;
    height: 38px;
    border-radius: 6px;
    background: var(--3, #f2f7ff);
    padding: 11px 12px;
    justify-content: center;
    align-items: center;
  }
  .titleHolder {
    color: var(--text-5, #3e4556);
    font-family: DM Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .subTitleHolder {
    color: var(--text-6, #878787);
    font-family: DM Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;
