import { FC } from "react";
import { Country } from "src/modules/country";
import { CountryProvider } from "src/modules/country/context";

export const CountryPage: FC = () => {
  return (
    <CountryProvider>
      <Country />
    </CountryProvider>
  );
};
