import { FC, useEffect } from "react";
import { UseAgents } from "../../context";
import { Button, Modal } from "antd";
import { TableUI } from "src/components/UiKit/table";
import { ColumnsType } from "antd/es/table";
import { getRowKey } from "src/helpers/getRowKey";
import {
  IAllAgentContact,
  NewAgentContactEnum,
} from "src/services/BaseInfo/models";

export const EmailModal: FC = () => {
  const {
    value: { isOpenModalEmail, loadingEmailOrPhone, email, currentAgentId },
    func: { closeModalEmail, getAgentPhoneOrEmailData },
  } = UseAgents();

  useEffect(() => {
    if (isOpenModalEmail) {
      getAgentPhoneOrEmailData(NewAgentContactEnum.Email);
    }
  }, [getAgentPhoneOrEmailData, isOpenModalEmail]);
  const columns: ColumnsType<IAllAgentContact> = [
    {
      title: "#",
      key: "id",
      align: "center",
      width: "61px",
      render: (name, record, index) => index + 1,
    },
    {
      title: "Email",
      dataIndex: "value",
      key: "email",
      align: "center",
    },
  ];
  return (
    <Modal
      open={isOpenModalEmail}
      onCancel={closeModalEmail}
      closeIcon={false}
      title={
        <div className="pb-[16px] border-0 border-b-[1px] border-solid border-[#DADEEC]">
          <p className="text-[18px] font-semibold text-[#001377] mb-[2px] mt-[0px]">
            Agent
          </p>
          <span className="text-[12px] text-[#3E4556] font-medium pr-[8px] border-0 border-r-[2px] border-solid border-[#DADEEC]">
            {currentAgentId?.name}
          </span>
          <span className="text-[12px] text-[#878787] font-normal ml-[8px]">
            Email
          </span>
        </div>
      }
      footer={() => (
        <div className="flex justify-center ">
          <Button
            type="default"
            onClick={closeModalEmail}
            className="flex gap-[12px] justify-center font-semibold text-[#4B4B4B] text-[16px]"
          >
            <span className="material-icons text-[24px]">highlight_off</span>
            Cancel
          </Button>
        </div>
      )}
    >
      <div className="flex justify-center items-center rounded-[12px] border border-solid border-[#DADEEC] my-[32px]">
        <TableUI
          columns={columns}
          dataSource={email}
          pagination={false}
          loading={loadingEmailOrPhone.email}
          rowKey={getRowKey}
        />
      </div>
    </Modal>
  );
};
