import styled from "styled-components";

export const AddNoonReportContainer = styled.div`
  background: var(--secondary-primary-white, #fff);
  border-radius: 20px;
  padding: 24px 32px;
  box-shadow: 0px 4px 6px 0px rgba(113, 130, 183, 0.25);
  transition: all 0.5s;
  .footer {
    border-top: 1px solid var(--shadow, #dadeec);
    padding-top: 24px;
  }
  .form-table-tab-footer {
    border-bottom: 1px solid var(--shadow, #dadeec);
    padding-bottom: 16px;
  }
  .create-edit-tabs .ant-tabs-ink-bar {
    height: 2px !important;
    color: #2c73fc !important;
  }
`;
