import { Input } from "antd";
import styled from "styled-components";

const { TextArea } = Input;

export const TextAreaStyled = styled(TextArea)`
  background: #f4f7fe;
  border-color: #f4f7fe;
  border-radius: 12px;
  font: inherit;
  letter-spacing: inherit;
  box-sizing: content-box;
  margin: 0;
  resize: none !important;
  width: 95% !important;
  &:focus {
    box-shadow: none !important;
    border: 0 !important;
    background: #f4f7fe;
    border-color: #f4f7fe;
  }
  &:hover {
    box-shadow: none !important;
    border: 0 !important;
    background: #f4f7fe;
    border-color: #f4f7fe;
  }

  .ant-form-item-explain-error {
  }
`;
