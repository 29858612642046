import { FC } from "react";
import { FWInfoForm } from "./FWInfoForm";
import { FWInfoProvider } from "./context";

export const FWInfo: FC = () => {
  return (
    <FWInfoProvider>
      <FWInfoForm />
    </FWInfoProvider>
  );
};
