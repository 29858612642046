import { FC } from "react";
import { ShipDirectionContainer } from "./styles";
import { Icons } from "src/components/customIcons";
import { DirectionProgress } from "src/components/directionProgress";
import useOwnerStore from "src/store/owner";
import {
  DailyReportDestinationTime,
  DailyReportOriginTime,
} from "src/modules/ShipDailyReport/constant/options";

export const ShipDirection: FC = () => {
  const { dailyReport } = useOwnerStore();

  return (
    <ShipDirectionContainer>
      <label className="text-[#000] text-[16px] font-[700]">
        Ship Direction
      </label>
      <div className="content">
        <div className="flex justify-between items-center w-full text-[#5D6881] text-[18px]">
          <span>{dailyReport?.originPossition}</span>
          <span>{dailyReport?.destinationLocation}</span>
        </div>
        <div className="flex justify-between items-center w-full">
          <div>
            <div className="text-[#001377] text-[14px]">
              {dailyReport?.dailyReportOriginTime === DailyReportOriginTime.ETD
                ? "ETD"
                : "ATD"}
            </div>
            <div className="text-[#3E4556] text-[11px]">
              {dailyReport?.originTime}
            </div>
          </div>
          <div className="text-right">
            <div className="text-[#001377] text-[14px]">
              {dailyReport?.dailyReportDestinationTime ===
              DailyReportDestinationTime.ATA
                ? "ATA"
                : "ETA"}
            </div>
            <div className="text-[#3E4556] text-[11px]">
              {dailyReport?.destinationTime}
            </div>
          </div>
        </div>
        <div className="w-full px-[16px]">
          <DirectionProgress percent={dailyReport?.distanceProgress || 0} />
        </div>
        <div>
          <div className="flex gap-[16px] items-center">
            <Icons name="situation" />
            <div>
              <div className="text-[#001377] text-[12px]">
                Current Situation
              </div>
              <div className="text-[#5D6881] text-[18px]">
                {dailyReport?.currenctSituation}
              </div>
            </div>
          </div>
        </div>
      </div>
    </ShipDirectionContainer>
  );
};
