import styled from "styled-components";

export const AddFlowWrapper = styled.div<{
  width: string;
  $active?: "left" | "right" | undefined;
}>`
  border-radius: 20px;
  background: var(--secondary-primary-white, #fff);

  /* 2 */
  box-shadow: 0px 4px 6px 0px rgba(113, 130, 183, 0.25);
  transition: all 0.5s;
  overflow: hidden;
  width: ${({ width }) => width};
  padding: 24px 32px;

  ${({ $active }) =>
    $active === "right"
      ? ` border-radius: 20px 0 0 20px;
  box-shadow: 0px 4px 6px 0px rgba(113, 130, 183, 0.25);
  `
      : ""}
  ${({ $active }) =>
    $active === "left"
      ? ` border-radius: 0 20px 20px 0;
  box-shadow: 0px 4px 6px 0px rgba(113, 130, 183, 0.25);
  `
      : ""}
`;
export const AddFlowLeftWrapper = styled.div<{
  width: string;
  $active?: "left" | "right" | undefined;
}>`
  border-radius: ${({ $active }) =>
    $active === "left" ? "20px 0 0 20px" : "20px"};
  background: var(--secondary-primary-white, #fff);
  box-shadow: 0px 4px 6px 0px rgba(113, 130, 183, 0.25);
  transition: all 0.5s;
  overflow: hidden;
  box-shadow: 0px 4px 6px 0px rgba(113, 130, 183, 0.25);
  width: ${({ width, $active }) => ($active === "left" ? width : 0)};
  padding: ${({ $active }) => ($active === "left" ? "24px 16px" : "0px")};
  opacity: ${({ $active }) => ($active === "left" ? "1" : "0")};
`;
export const AddFlowRightWrapper = styled.div<{
  width: string;
  $active?: "left" | "right" | undefined;
}>`
  border-radius: ${({ $active }) =>
    $active === "right" ? "0px 20px 20px 0px" : "20px"};
  background: var(--secondary-primary-white, #fff);
  box-shadow: 0px 4px 6px 0px rgba(113, 130, 183, 0.25);
  transition: all 0.5s;
  overflow: hidden;
  width: ${({ width, $active }) => ($active === "right" ? width : 0)};
  padding: ${({ $active }) => ($active === "right" ? "24px" : "0px")};
  opacity: ${({ $active }) => ($active === "right" ? "1" : "0")};
  .content {
    ${({ $active }) => ($active !== "right" ? "height:0; !important" : "")}
  }
`;
export const AddFlowContainer = styled.div`
  /* .right-active {
    border-radius: 0 20px 20px 0;
    box-shadow: 0px 4px 6px 0px rgba(113, 130, 183, 0.25);
  }
  .left-active {
    border-radius: 20px 0 0 20px;
    box-shadow: 0px 4px 6px 0px rgba(113, 130, 183, 0.25);
  } */
  .footer {
    border-top: 1px solid var(--shadow, #dadeec);
    padding-top: 24px;
    justify-content: flex-end;
  }
`;
