import { Form, FormInstance, message } from "antd";
import {
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useContext,
  useState,
} from "react";
import { IReceiverTabs } from "../models";
import { IncomeService } from "src/services/Income/Income.service";
import { IAllReceivers, INewReceiver } from "src/services/Income/models";

interface IReceiverCTX {
  value: {
    tableLoading: boolean;
    addReceiverLoading: boolean;
    editLoading: boolean;
    tableData: IAllReceivers[];
    receiverId: number | undefined;
    editData: IAllReceivers | undefined;
    editMode: boolean;
    activeKey: string;
  };
  dispatch: {
    setTableLoading: Dispatch<SetStateAction<boolean>>;
    setAddReceiverLoading: Dispatch<SetStateAction<boolean>>;
    setEditLoading: Dispatch<SetStateAction<boolean>>;
    setTableData: Dispatch<SetStateAction<IAllReceivers[]>>;
    setReceiverId: Dispatch<SetStateAction<number | undefined>>;
    setEditData: Dispatch<SetStateAction<IAllReceivers | undefined>>;
    setEditMode: Dispatch<SetStateAction<boolean>>;
    setActiveKey: Dispatch<SetStateAction<string>>;
  };
  func: {
    getReceiver: () => void;
    onFinish: (value: INewReceiver) => void;
    onBack: () => void;
    onDelete: (id: number) => void;
  };
  form: { ReceiverForm: FormInstance<any> | undefined };
}

const defaultValueContext: IReceiverCTX = {
  value: {
    tableLoading: false,
    addReceiverLoading: false,
    editLoading: false,
    tableData: [],
    receiverId: undefined,
    editData: undefined,
    editMode: false,
    activeKey: "",
  },
  dispatch: {
    setTableLoading: () => {},
    setAddReceiverLoading: () => {},
    setEditLoading: () => {},
    setTableData: () => {},
    setReceiverId: () => {},
    setEditData: () => {},
    setEditMode: () => {},
    setActiveKey: () => {},
  },
  func: {
    getReceiver: () => {},
    onFinish: () => {},
    onBack: () => {},
    onDelete: () => {},
  },
  form: { ReceiverForm: undefined },
};

export const ReceiverContext = createContext<IReceiverCTX>(defaultValueContext);

export const ReceiverProvider: FC<PropsWithChildren> = ({ children }) => {
  //--------------------------form-------------------------

  const [ReceiverForm] = Form.useForm();

  //--------------------------state------------------------

  const [tableLoading, setTableLoading] = useState<boolean>(false);

  const [addReceiverLoading, setAddReceiverLoading] = useState<boolean>(false);

  const [editLoading, setEditLoading] = useState<boolean>(false);

  const [tableData, setTableData] = useState<IAllReceivers[]>([]);

  const [receiverId, setReceiverId] = useState<number | undefined>(undefined);

  const [editData, setEditData] = useState<IAllReceivers | undefined>(
    undefined
  );

  const [editMode, setEditMode] = useState<boolean>(false);

  const [activeKey, setActiveKey] = useState<string>(
    IReceiverTabs.addNewReceiver || IReceiverTabs.receiverTable
  );

  //--------------------------func-------------------------

  const getReceiver = useCallback(async () => {
    setTableLoading(true);
    try {
      const { AllReceivers } = new IncomeService();
      const response = await AllReceivers();
      if (response && response.status === 200) {
        setTableData(response.data.records);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setTableLoading(false);
    }
  }, []);

  const onFinish = async (value: INewReceiver) => {
    if (editMode && receiverId) {
      setEditLoading(true);
      try {
        const { UpdateReceiver } = new IncomeService();
        const response = await UpdateReceiver(receiverId, value);
        if (response && response.status === 200) {
          message.success("Receiver created successfully");
          setEditMode(false);
          setReceiverId(undefined);
          getReceiver();
          ReceiverForm.resetFields();
          setActiveKey(IReceiverTabs.receiverTable);
        }
      } catch (err) {
        console.log(err);
      } finally {
        setEditLoading(false);
      }
    } else {
      setAddReceiverLoading(true);
      try {
        const { NewReceiver } = new IncomeService();
        const response = await NewReceiver(value);
        if (response && response.status === 200) {
          message.success("Receiver created successfully");
          getReceiver();
          ReceiverForm.resetFields();
        }
      } catch (err) {
        console.log(err);
      } finally {
        setAddReceiverLoading(false);
      }
    }
  };

  const onDelete = async (id: number) => {
    try {
      const { DeleteReceiver } = new IncomeService();
      const response = await DeleteReceiver(id);
      if (response && response.status === 200) {
        message.success("Delete receiver successfully");
        getReceiver();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const onBack = () => {
    setEditMode(false);
    setReceiverId(undefined);
    setEditData(undefined);
    ReceiverForm?.resetFields();
  };

  const defaultValueCtx: IReceiverCTX = {
    value: {
      tableLoading,
      addReceiverLoading,
      editLoading,
      tableData,
      receiverId,
      editData,
      editMode,
      activeKey,
    },
    dispatch: {
      setTableLoading,
      setAddReceiverLoading,
      setEditLoading,
      setTableData,
      setReceiverId,
      setEditData,
      setEditMode,
      setActiveKey,
    },
    func: { getReceiver, onFinish, onBack, onDelete },
    form: { ReceiverForm },
  };

  return (
    <ReceiverContext.Provider value={defaultValueCtx}>
      {children}
    </ReceiverContext.Provider>
  );
};

export const useReceiver = () => useContext(ReceiverContext);
