import { Row } from "antd";
import { styled } from "styled-components";

export const TotalInvoicesContainer = styled(Row)`
  margin-top: 8px;
  padding: 0 32px;

  .title {
    color: #001377;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .invoice-base-info {
    color: #001377;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 24px;
  }
  .formItem {
    label {
      color: #001377;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      height: unset;
    }
  }
  .footer {
    border-top: 1px solid #dadeec;
    padding-top: 32px;
    padding-bottom: 16px;
  }
  .inputTextArea {
    background: #f4f7fe !important;
    border-color: #f4f7fe !important;
    border-radius: 12px !important;
    height: 178px !important;
  }
  /* .TIASMPC {
    label {
      margin-bottom: 8px;
    }
  }
  .supervisor {
    label {
      margin-top: 8px;
    }
  } */
`;
