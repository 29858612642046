import { Tabs, TabsProps } from "antd";
import { useSearchParams } from "react-router-dom";
import { FC } from "react";
import { TabsContainer } from "src/modules/Category/styles";
import { ICargoGroupTabs } from "../models";
import { AddNewCargoGroup } from "./addCargoGroup";
import { CargoGroupTable } from "./cargoGroupTable";

const item: TabsProps["items"] = [
  {
    key: ICargoGroupTabs.addNewCargoGroup,
    label: (
      <span>
        <span className="material-icons text-[10px] p-[8px]">lens</span>
        Add new cargo group
      </span>
    ),
    children: <AddNewCargoGroup />,
  },
  {
    key: ICargoGroupTabs.cargoGroupTable,
    label: (
      <span>
        <span className="material-icons text-[10px] p-[10px]">lens</span>
        Cargo group table
      </span>
    ),
    children: <CargoGroupTable />,
  },
];

export const CargoGroupTabs: FC = () => {
  const [params] = useSearchParams();
  const defaultKey =
    params.get("table") === "true"
      ? ICargoGroupTabs.cargoGroupTable
      : ICargoGroupTabs.addNewCargoGroup;
  return (
    <TabsContainer>
      <Tabs defaultActiveKey={defaultKey} items={item} />
    </TabsContainer>
  );
};
