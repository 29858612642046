import { FC } from "react";
import { useAddShip } from "src/modules/AddShips/context";
import { HeaderEditShipContainer } from "../../EditShip/style/styles";
import { IAddNewShip, IAddShipsTabs } from "src/modules/AddShips/models";

export const HeaderShowShip: FC = () => {
  const {
    dispatch: { setIsShowMode, setActiveKeyAddNewShip, setActiveKeyShowShip },
    func: { resetForms },
    value: { tableCurrentValue },
  } = useAddShip()!;
  return (
    <HeaderEditShipContainer>
      <div
        className="flex flex-row gap-[12px] items-center cursor-pointer"
        onClick={() => {
          setIsShowMode(false);
          setActiveKeyAddNewShip(IAddShipsTabs.shipsTable);
          setActiveKeyShowShip(IAddNewShip.basicInformation);
          resetForms();
        }}
      >
        <span className="material-icons text-[#2C73FC] text-[32px]">
          keyboard_backspace
        </span>
        <div className=" text-[#2C73FC] text-[24px] ">Back to ship table </div>
      </div>
      <div className="flex flex-col">
        <div className="text-[#001377] text-[18px]">Ship information</div>
        <div className="text-[#5D6881] text-[14px]">
          {tableCurrentValue?.name}
        </div>
      </div>
    </HeaderEditShipContainer>
  );
};
