import { Collapse, CollapseProps } from "antd";
import { FC, ReactNode } from "react";
import { DropDownContainer } from "../../styles";

interface IDropDownHandler {
  title: string;
  icon: string;
  children: ReactNode;
}
export const CollapseHandler: FC<IDropDownHandler> = ({
  title,
  icon,
  children,
}) => {
  const labelDesign = (
    <div
      className="flex justify-between items-center w-[100%]"
      onClick={(e) => e.preventDefault()}
    >
      <div>
        <div className="flex gap-[16px]">
          <span className="material-icons text-[32px] text-[#001377]">
            {icon}
          </span>
          <div className="title">{title}</div>
        </div>
      </div>
    </div>
  );
  const items: CollapseProps["items"] = [
    {
      key: title,
      label: labelDesign,
      children: children,
    },
  ];

  return (
    <DropDownContainer>
      <Collapse
        items={items}
        expandIcon={({ isActive }) => {
          return (
            <div className=" flex items-center bg-[#fff] rounded-[8px] p-[4px]">
              <span
                style={{
                  transform: isActive ? "rotate(180deg)" : "rotate(0deg)",
                  transition: "0.4s",
                }}
                className={`material-icons text-[24px] text-[#5D6881]`}
              >
                keyboard_arrow_down
              </span>
            </div>
          );
        }}
        expandIconPosition="end"
        bordered={false}
        className="w-[100%]"
        defaultActiveKey={"General"}
      />
    </DropDownContainer>
  );
};
