import { Tabs, TabsProps } from "antd";
import { FC } from "react";
import { TabsContainer } from "src/modules/Category/styles";
import { ICurrencyTabs } from "../models";
import { AddNewCurrency } from "./addCurrency";
import { CurrencyTable } from "./currencyTable";
import { useCurrency } from "../context";
import { Guard } from "src/components/Guard";
import { NewCurrencyPath } from "src/services/System/guardPath";
import { AllCurrencyPath } from "src/services/Public/guardPath";

const item: TabsProps["items"] = [
  {
    key: ICurrencyTabs.addNewCurrency,
    label: (
      <span>
        <span className="material-icons text-[10px] p-[8px]">lens</span>
        Add new currency
      </span>
    ),
    children: (
      <Guard action={NewCurrencyPath}>
        <AddNewCurrency />
      </Guard>
    ),
  },
  {
    key: ICurrencyTabs.currencyTable,
    label: (
      <span>
        <span className="material-icons text-[10px] p-[10px]">lens</span>
        Currency table
      </span>
    ),
    children: (
      <Guard action={AllCurrencyPath}>
        <CurrencyTable />
      </Guard>
    ),
  },
];

export const CurrencyTabs: FC = () => {
  const {
    value: { activeKey },
    dispatch: { setActiveKey },
  } = useCurrency();

  const onChange = (key: string) => {
    setActiveKey(key);
  };

  return (
    <TabsContainer>
      <Tabs onChange={onChange} items={item} activeKey={activeKey} animated />
    </TabsContainer>
  );
};
