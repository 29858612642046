import { Button, Col, Divider, Flex, Form, Typography } from "antd";
import { FC, useEffect, useState } from "react";
import { ProfitBasicWrapper } from "./style";
import { ProfitBasicFormItems } from "./BasicFormItems";
import { useNewProfitReport } from "../../context";
import { AddNewProfitStatusTypeEnum } from "src/modules/ProfitReport/models";
import { useTableProfit } from "../../../ProftTable/context";
import { useProfitReport } from "src/modules/ProfitReport/context";
import dayjs from "dayjs";

export const ProfitBasicInformation: FC = () => {
  const {
    values: {
      finishLoadingBaseInfo,
      editLoading,
      showFinishButtonBaseInfo,
      newBasicInfoId,
    },
    func: { onFinishBasicInfo, onFinishEditProfit },
    dispatches: { setShowFinishButtonBaseInfo },
  } = useNewProfitReport();

  const {
    value: { editData },
    func: { onCancelEdit },
  } = useTableProfit();

  const {
    value: { editMode },
    dispatch: { setStatus, setStep, setShowAttachFile },
    form: { basicInfoForm },
  } = useProfitReport();

  const formValue = Form.useWatch([], basicInfoForm);

  const [basicInfoSubmit, setBasicInfoSubmit] = useState<boolean>(false);

  const addFile = () => {
    setShowAttachFile(true);
  };

  const onCancelAdd = () => {
    setStatus((prev) => ({
      ...prev,
      basic: newBasicInfoId
        ? AddNewProfitStatusTypeEnum.done
        : AddNewProfitStatusTypeEnum.empty,
    }));
    setStep(undefined);
  };

  const onReset = () => {
    basicInfoForm?.resetFields();
  };

  useEffect(() => {
    basicInfoForm
      ?.validateFields({ validateOnly: true })
      .then(() => setBasicInfoSubmit(true))
      .catch(() => setBasicInfoSubmit(false));
  }, [basicInfoForm, formValue]);

  useEffect(() => {
    if (editData && editMode) {
      const data = {
        ...editData,
        laycan: [
          editData.startLaycan ? dayjs(editData.startLaycan) : undefined,
          editData.endLaycan ? dayjs(editData.endLaycan) : undefined,
        ],
      };
      basicInfoForm?.setFieldsValue(data);
    } else {
      basicInfoForm?.resetFields();
    }
  }, [basicInfoForm, editData, editMode]);

  return (
    <Form
      form={basicInfoForm}
      onFinish={editMode ? onFinishEditProfit : onFinishBasicInfo}
    >
      <ProfitBasicWrapper>
        <Col span={24}>
          <Typography.Title className="title">
            Basic information
          </Typography.Title>
          <Typography.Text className="subTitle">
            Please insert its information.
          </Typography.Text>
        </Col>
        <Col span={24} className="mt-[24px]">
          <ProfitBasicFormItems />
        </Col>
        <Divider />
        <Col span={24}>
          <Flex align="center" justify="space-between">
            <Flex gap={16}>
              {!editMode && (
                <Button
                  onClick={() => {
                    onCancelAdd();
                  }}
                  className="text-[#4B4B4B] min-w-[85px]"
                >
                  Cancel
                </Button>
              )}
              {editMode && (
                <Button
                  icon={<span className="material-icons">folder_open</span>}
                  className="flex justify-center items-center gap-[8px] !text-[#4B4B4B] w-[85px]"
                  ghost
                  style={{
                    border: "1px solid var(--color-Gray-Gray-600, #4B4B4B)",
                  }}
                  onClick={addFile}
                >
                  files
                </Button>
              )}
            </Flex>
            <Flex gap={16}>
              {!editMode ? (
                <Button onClick={onReset} className="min-w-[85px]">
                  Reset
                </Button>
              ) : (
                <Button
                  onClick={() => {
                    onCancelAdd();
                  }}
                  className="text-[#4B4B4B] min-w-[85px]"
                >
                  Cancel
                </Button>
              )}
              <Button
                className="min-w-[85px]"
                type="primary"
                loading={editMode ? editLoading : finishLoadingBaseInfo}
                disabled={!basicInfoSubmit}
                htmlType="submit"
              >
                {editMode ? "Save edit" : "Add"}
              </Button>
              {showFinishButtonBaseInfo && (
                <Button
                  onClick={() => {
                    onCancelEdit(showFinishButtonBaseInfo);
                    setShowFinishButtonBaseInfo(false);
                  }}
                  type="primary"
                  ghost
                  className="text-[#4B4B4B] min-w-[85px]"
                >
                  Finish
                </Button>
              )}
            </Flex>
          </Flex>
        </Col>
      </ProfitBasicWrapper>
    </Form>
  );
};
