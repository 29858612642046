import { FC } from "react";
import { OwnerHeader } from "src/modules/Owner/components/OwnerHeader";
import { ShipsDailyReport } from "src/modules/Owner";
import { ShipDailyReportProvider } from "src/modules/Owner/components/ShipsDailyReport/context";

export const ShipsDailyReportPage: FC = () => {
  return (
    <>
      <OwnerHeader />
      <ShipDailyReportProvider>
        <ShipsDailyReport />
      </ShipDailyReportProvider>
    </>
  );
};
