import { Flex } from "antd";
import styled from "styled-components";

export const ShipDailyReportHeaderStyle = styled(Flex)`
  height: 90px;
  background-color: #f2f7ff;
  padding: 16px;

  .ship-reports-header-name {
    font-weight: 700;
    font-size: 16px;
    color: #000;
  }
  .ship-reports-header-message {
    font-weight: 500;
    font-size: 14px;
    color: #001377;
    margin-left: 10px;
    color: #2c73fc;
  }
  .ship-report-header-divider {
    width: 4px;
    height: 37px;
    background-color: #dadeec;
  }
  .ship-reports-header-count {
    display: inline-block;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #ffb800;
    color: #fff;
    text-align: center;
  }
`;
