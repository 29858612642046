import { FC, Suspense } from "react";
import { AssignRoleToUser } from "src/modules/AssignRoleToUser";
import { AssignRoleToUserProvider } from "src/modules/AssignRoleToUser/context";
import { PreLoading } from "src/routes/Loading";

export const AssignRoleToUserPage: FC = () => {
  return (
    <Suspense fallback={<PreLoading />}>
      <AssignRoleToUserProvider>
        <AssignRoleToUser />
      </AssignRoleToUserProvider>
    </Suspense>
  );
};
