import { Divider, Modal, Row, Table, message } from "antd";
import { FC, useCallback, useEffect, useState } from "react";
import { EditInvoiceContainer } from "../styles/editInvoiceContainer";
import { AddAndEditRow } from "./addAndEditRow";
import { TableRowSelection } from "antd/es/table/interface";
import { EditArticlesFooter } from "./editArticlesFooter";
import { DeleteArticlesFooter } from "./DeleteRowsFooter";
import { InvoiceService } from "src/services/Invoice/Invoice.service";
import {
  IFinallyArticle,
  INewInvoiceArticle,
} from "src/services/Invoice/models";
import { useEditInvoice } from "../context";
import { useEditArticlesColumns } from "./columns";
import { DeleteArticleConfirm } from "./deleteArticleConfirm";
import * as XLSX from "xlsx";
import { IExcelData } from "src/modules/addInvoice/models";
import { PublicService } from "src/services/Public/Public.service";
import { DeleteArticlePath } from "src/services/Invoice/guardPath";

interface IProps {
  show: boolean;
  onCancel: () => void;
  header: string;
}

interface IDataTable extends IFinallyArticle {}
export const EditArticles: FC<IProps> = ({ onCancel, show, header }) => {
  const [isReadyToDelete, setIsReadyToDelete] = useState<boolean>(false);
  const [articles, setArticles] = useState<IDataTable[]>([]);
  const getRowKey = (record: any) => record.id;
  const [loading, setLoading] = useState<boolean>(false);

  const {
    value: {
      editInvoiceId,
      showEditArticles,
      showAddAndEditRow,
      selectedArticle,
      showDeleteArticle,
    },
    dispatches: {
      setShowAddAndEditRow,
      setSelectedArticle,
      setShowDeleteArticle,
    },
  } = useEditInvoice();
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const [uploadLoading, setUploadLoading] = useState<boolean>(false);
  const getArticles = async (id: number) => {
    try {
      setLoading(true);
      const { AllArticle } = new InvoiceService();
      const res = await AllArticle(id);
      if (res && res.data) {
        setArticles(
          res.data.map((article, index) => {
            article["#"] = index + 1;
            return article;
          })
        );
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  const refetch = useCallback(() => {
    if (editInvoiceId && showEditArticles) {
      getArticles(editInvoiceId);
    }
  }, [editInvoiceId, showEditArticles]);
  const { operationColumns, selectionColumns } =
    useEditArticlesColumns(refetch);

  useEffect(() => {
    refetch();
  }, [refetch]);
  const rowSelection: TableRowSelection<any> = {
    onChange: (selectedRowKeys: React.Key[]) => {
      setSelectedArticle(selectedRowKeys as number[]);
    },
    getCheckboxProps: (record: any) => ({
      name: record.operation,
    }),
    type: "checkbox",
    preserveSelectedRowKeys: true,
  };
  const confirmDelete = async () => {
    if (selectedArticle.length === 0 || !editInvoiceId) return;
    setDeleteLoading(true);
    try {
      const { DeleteArticle } = new InvoiceService();
      const res = await DeleteArticle(editInvoiceId, selectedArticle);
      if (res && res.status === 200) {
        refetch();
        setSelectedArticle([]);
        setIsReadyToDelete(false);
        setShowDeleteArticle(false);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setDeleteLoading(false);
    }
  };

  const readFile = (f: Blob) => {
    const reader = new FileReader();
    reader.onload = (evt: any) => {
      // evt = on_file_select event
      /* Parse data */
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_csv(ws);
      /* Update state */
      const convertedData: IExcelData[] = convertToJson(data);
      getCategoriesAndInsertToData(convertedData); // shows data in json format
    };
    reader.readAsBinaryString(f);
  };

  const convertToJson = (csv: any) => {
    var lines = csv.split("\n");

    var result = [];

    var headers = lines[0].split(",");

    for (var i = 1; i < lines.length; i++) {
      var obj: any = {};
      var currentline = lines[i].split(",");

      for (var j = 0; j < headers.length; j++) {
        obj[headers[j] as any] = currentline[j];
      }
      obj.key = i;
      result.push(obj);
    }

    const newResult: any = [];
    result.forEach((item) => {
      item["Amount"] = +(+item["Quantity"] * +item["Unit Price"]).toFixed(4);
      newResult.push(item);
    });

    return newResult.filter((item: IExcelData) => !!item["#"]);
  };
  const getCategoriesAndInsertToData = async (data: IExcelData[]) => {
    try {
      const costCodes: string[] = data.map((item) => item["Cost code"]);
      const { Category, Currency } = new PublicService();
      const currencyId = await Currency([data[0]["Currency"]])
        .then((res) => {
          if (res && res.data) {
            if (res.data[0] !== null) {
              return res.data[0].id;
            }
            return undefined;
          } else return undefined;
        })
        .catch((err) => {
          console.log(err);
          return undefined;
        });
      const res = await Category(costCodes);
      if (res && res.data) {
        const categories = res.data;
        const newData: IExcelData[] = data.map((item, index) => {
          item["Category"] = categories[index]?.fullName || "";
          // item["Sub category"] = categories[index]?.subCategory || "";
          item.costCodeId = categories[index]?.id;
          item.currencyId = currencyId || 0;
          return item;
        });
        const newArticlesBody: INewInvoiceArticle = {
          articles: newData.map((item) => ({
            amount: +item["Amount"],
            comment: item["Description"],
            costCodeId: item.costCodeId,
            quantity: +item["Quantity"],
            unitPrice: +item["Unit Price"],
            unitQuantity: item["Unit Quantity"],
          })),
        };
        if (!editInvoiceId) return message.error("missing invoice id");
        const { NewInvoiceArticle } = new InvoiceService();
        NewInvoiceArticle(editInvoiceId, newArticlesBody).then((res) => {
          if (res && res.status === 200) {
            refetch();
          }
        });
      }
    } catch (err) {
      console.log(err);
      return false;
    } finally {
      setUploadLoading(false);
    }
  };

  const onUploadExcel = (file: Blob) => {
    setUploadLoading(true);
    readFile(file);
  };
  return (
    <Modal
      width={"90%"}
      open={show}
      closable={false}
      maskClosable={false}
      styles={{ body: { padding: 24 } }}
      title={"Edit Articles"}
      style={{ top: 64, padding: 24 }}
      footer={
        <Row>
          <Divider />
          {isReadyToDelete ? (
            <DeleteArticlesFooter setIsReadyToDelete={setIsReadyToDelete} />
          ) : (
            <EditArticlesFooter
              onCancel={onCancel}
              setIsReadyToDelete={setIsReadyToDelete}
              setShowAddAndEditRow={setShowAddAndEditRow}
              articlesIsEmpty={!articles.length}
              onUploadExcel={onUploadExcel}
              uploadLoading={uploadLoading}
            />
          )}
        </Row>
      }
    >
      <EditInvoiceContainer>
        <header className="header">{header}</header>
        <Table
          columns={isReadyToDelete ? selectionColumns : operationColumns}
          rowKey={getRowKey}
          rowSelection={isReadyToDelete ? rowSelection : undefined}
          dataSource={articles}
          loading={loading}
        />
      </EditInvoiceContainer>
      <AddAndEditRow
        header={header}
        onCancel={() => {
          setShowAddAndEditRow(false);
        }}
        show={showAddAndEditRow}
        refetch={refetch}
      />
      <DeleteArticleConfirm
        open={showDeleteArticle}
        onCancel={() => setShowDeleteArticle(false)}
        onConfirm={confirmDelete}
        loading={deleteLoading}
        title="Article"
        deleteAction={DeleteArticlePath}
      />
    </Modal>
  );
};
