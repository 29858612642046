import { INewDailyReport } from "src/services/DailyReport/models/args.models";
import dayjs from "dayjs";
import { ReactNode } from "react";
import { INewAllShipDailyReportResult } from "src/services/DailyReport/models/result.model";
export enum IHeaderTabs {
  addNewDailyReport = "1",
  dailyReportsTable = "2",
}
export enum IAddNewDailyReport {
  Voyage = "1",
  Cargos = "2",
  Actions = "3",
  FuturePlan = "4",
  Instruction = "5",
}
export interface INewDailyReportValues
  extends Omit<INewDailyReport, "reportDate"> {
  reportDate: dayjs.Dayjs;
}

export enum IEditNewDailyReport {
  Voyage = "1",
  Cargos = "2",
  Actions = "3",
  FuturePlan = "4",
  Instruction = "5",
}

export interface IEditMode {
  Header: ReactNode;
  EditNewDailyReport: ReactNode;
}

export interface TableData extends INewAllShipDailyReportResult {
  "#": number;
  ship?: string;
  tools: ReactNode;
}
