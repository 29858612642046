import {
  FC,
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useNoonReportCTX } from "../../context";
import { NoonReportService } from "src/services/NoonReport/NoonReport.service";
import {
  IFOMGOCONS,
  IFOMGOROB,
  IFWInfo,
  ILOROBCons,
  IOtherInfo,
  ISpeedDistanceInfo,
} from "src/services/NoonReport/models";

interface IContext {
  value: {
    foMgoRobProps: { data: IFOMGOROB[]; loading: boolean };
    loMgoConsProps: { data: ILOROBCons[]; loading: boolean };
    foMgoConsProps: { data: IFOMGOCONS[]; loading: boolean };
    fwInfoProps: { data: IFWInfo | undefined; loading: boolean };
    speedDistanceIfoProps: {
      data: ISpeedDistanceInfo | undefined;
      loading: boolean;
    };
    otherIfoProps: { data: IOtherInfo | undefined; loading: boolean };
  };
  dispatch: {};
  func: {};
  forms: {};
}

export const ViewNoonReportCTX = createContext<IContext | undefined>(undefined);

export const ViewNoonReportProvider: FC<PropsWithChildren> = ({ children }) => {
  const [foMgoRobProps, setFoMgoRobProps] = useState<{
    data: IFOMGOROB[];
    loading: boolean;
  }>({ data: [], loading: false });

  const [loMgoConsProps, setLoMgoConsProps] = useState<{
    data: ILOROBCons[];
    loading: boolean;
  }>({ data: [], loading: false });

  const [foMgoConsProps, setFoMgoConsProps] = useState<{
    data: IFOMGOCONS[];
    loading: boolean;
  }>({ data: [], loading: false });

  const [fwInfoProps, setFwInfoProps] = useState<{
    data: IFWInfo | undefined;
    loading: boolean;
  }>({ data: undefined, loading: false });

  const [speedDistanceIfoProps, setSpeedDistanceInfoProps] = useState<{
    data: ISpeedDistanceInfo | undefined;
    loading: boolean;
  }>({ data: undefined, loading: false });

  const [otherIfoProps, setOtherInfoProps] = useState<{
    data: IOtherInfo | undefined;
    loading: boolean;
  }>({ data: undefined, loading: false });

  const {
    value: { selectedReport, mode },
  } = useNoonReportCTX();

  const getFOMGOROB = useCallback(async () => {
    if (!selectedReport) return;
    setFoMgoRobProps((prev) => ({ ...prev, loading: true }));
    try {
      const { GetFOMGOROB } = new NoonReportService();
      const response = await GetFOMGOROB(selectedReport.id);
      if (response && response.status === 200 && response.data) {
        setFoMgoRobProps((prev) => ({ ...prev, data: response.data }));
      }
    } catch (err) {
      console.log(err);
    } finally {
      setFoMgoRobProps((prev) => ({ ...prev, loading: false }));
      setFoMgoRobProps((prev) => ({ ...prev, data: [] }));
    }
  }, [selectedReport]);

  const getLOROBCons = useCallback(async () => {
    if (!selectedReport) return;
    setLoMgoConsProps((prev) => ({ ...prev, loading: true }));
    try {
      const { GetLOROBCons } = new NoonReportService();
      const response = await GetLOROBCons(selectedReport.id);
      if (response && response.status === 200 && response.data) {
        setLoMgoConsProps((prev) => ({ ...prev, data: response.data }));
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoMgoConsProps((prev) => ({ ...prev, loading: false }));
      setLoMgoConsProps((prev) => ({ ...prev, data: [] }));
    }
  }, [selectedReport]);

  const getFOMGOCONS = useCallback(async () => {
    if (!selectedReport) return;
    setFoMgoConsProps((prev) => ({ ...prev, loading: true }));
    try {
      const { GetFOMGOCONS } = new NoonReportService();
      const response = await GetFOMGOCONS(selectedReport.id);
      if (response && response.status === 200 && response.data) {
        setFoMgoConsProps((prev) => ({ ...prev, data: response.data }));
      }
    } catch (err) {
      console.log(err);
    } finally {
      setFoMgoConsProps((prev) => ({ ...prev, loading: false }));
      setFoMgoConsProps((prev) => ({ ...prev, data: [] }));
    }
  }, [selectedReport]);

  const getFwInfo = useCallback(async () => {
    if (!selectedReport) return;
    setFwInfoProps((prev) => ({ ...prev, loading: true }));
    try {
      const { GetFWInfo } = new NoonReportService();
      const response = await GetFWInfo(selectedReport.id);
      if (response && response.status === 200) {
        setFwInfoProps((prev) => ({ ...prev, data: response.data }));
      }
    } catch (err) {
      console.log(err);
    } finally {
      setFwInfoProps((prev) => ({ ...prev, loading: false }));
    }
  }, [selectedReport]);

  const getSpeedDistance = useCallback(async () => {
    if (!selectedReport) return;
    setSpeedDistanceInfoProps((prev) => ({ ...prev, loading: true }));
    try {
      const { GetSpeedDistanceInfo } = new NoonReportService();
      const response = await GetSpeedDistanceInfo(selectedReport.id);
      if (response && response.status === 200) {
        setSpeedDistanceInfoProps((prev) => ({ ...prev, data: response.data }));
      }
    } catch (err) {
      console.log(err);
    } finally {
      setSpeedDistanceInfoProps((prev) => ({ ...prev, loading: false }));
    }
  }, [selectedReport]);

  const getOtherInfo = useCallback(async () => {
    if (!selectedReport) return;
    setOtherInfoProps((prev) => ({ ...prev, loading: true }));
    try {
      const { GetOtherInfo } = new NoonReportService();
      const response = await GetOtherInfo(selectedReport.id);
      if (response && response.status === 200) {
        setOtherInfoProps((prev) => ({ ...prev, data: response.data }));
      }
    } catch (err) {
      console.log(err);
    } finally {
      setOtherInfoProps((prev) => ({ ...prev, loading: false }));
    }
  }, [selectedReport]);

  useEffect(() => {
    if (mode === "edit") getOtherInfo();
  }, [getOtherInfo, mode]);

  useEffect(() => {
    if (mode === "edit") getSpeedDistance();
  }, [getSpeedDistance, mode]);

  useEffect(() => {
    if (mode === "edit") getFwInfo();
  }, [getFwInfo, mode]);

  useEffect(() => {
    if (mode === "view") getFOMGOROB();
  }, [getFOMGOROB, mode]);

  useEffect(() => {
    if (mode === "view") getLOROBCons();
  }, [getLOROBCons, mode]);

  useEffect(() => {
    if (mode === "view") getFOMGOCONS();
  }, [getFOMGOCONS, mode]);

  const contextValue: IContext = {
    value: {
      foMgoRobProps,
      loMgoConsProps,
      foMgoConsProps,
      fwInfoProps,
      speedDistanceIfoProps,
      otherIfoProps,
    },
    dispatch: {},
    func: {},
    forms: {},
  };

  return (
    <ViewNoonReportCTX.Provider value={contextValue}>
      {children}
    </ViewNoonReportCTX.Provider>
  );
};

export const useViewNoonReport = () => useContext(ViewNoonReportCTX)!;
