import { FC } from "react";
import { Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";
import { TableUI } from "src/components/UiKit/table";
import { getRowKey } from "src/helpers/getRowKey";

import { IPort } from "src/services/BaseInfo/models";
import TableActions from "./TableActions";
import { PortTableContainer } from "../../styles";
import { usePorts } from "../../context";

export const PortsTable: FC = () => {
  const {
    value: { tableData, loadings, pagination },
    dispatch: { setPagination },
  } = usePorts()!;

  //--------------------------------------Table Columns-------------------------------------------
  const columns: ColumnsType<IPort> = [
    {
      title: "#",
      dataIndex: "#",
      key: "index",
      align: "center",
      render: (name: string, record: any, index: number) =>
        (pagination.Offset - 1) * pagination.Limit + (index + 1),
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
      align: "center",
      className: "whitespace-nowrap",
    },
    {
      title: "Port name",
      dataIndex: "name",
      key: "name",
      align: "center",
      className: "whitespace-nowrap",
    },
    {
      title: "Abbreviation ",
      dataIndex: "abbrivation",
      key: "abbrivation",
      align: "center",
    },
    {
      title: "Local time (UTC)",
      key: "utc",
      align: "center",
      className: "whitespace-nowrap",
      render: (text, record) => {
        const utc = record.localTimeS ? "+" : "-";
        return `${utc} ${record.localTimeH}:${record.localTimeM}`;
      },
    },
    {
      title: "Latitude/Longitude",
      key: "latLong",
      align: "center",
      className: "whitespace-nowrap",
      render: (text, record) => `${record.lat}/${record.long}`,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      align: "center",
      className: "whitespace-nowrap",
      render: (value) => (
        <div className="flex justify-center">
          <Tooltip
            placement="bottom"
            color="#4B4B4B"
            title={value ? value : "-"}
          >
            <span className="material-icons">folder</span>
          </Tooltip>
        </div>
      ),
    },
    {
      title: "Tools",
      dataIndex: "tools",
      key: "tools",
      width: 132,
      align: "center",
      render: (text, record) => <TableActions item={record} />,
    },
  ];

  return (
    <PortTableContainer>
      <TableUI
        columns={columns}
        tableType="primary"
        dataSource={tableData}
        rowKey={getRowKey}
        pagination={{
          position: ["bottomCenter"],
          showSizeChanger: true,
          total: pagination.total,
          current: pagination.Offset,
          pageSize: pagination.Limit,
          onChange: (page: number, pageSize: number) => {
            setPagination((prev) => ({
              ...prev,
              Offset: pageSize !== prev.Limit ? 1 : page,
              Limit: pageSize,
            }));
          },
        }}
        scroll={{ x: 800 }}
        loading={loadings.table}
      />
    </PortTableContainer>
  );
};
