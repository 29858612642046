import { Tabs, TabsProps } from "antd";
import { useSearchParams } from "react-router-dom";
import { FC } from "react";
import { TabsContainer } from "src/modules/Category/styles";
import { ICargoTypeTabs } from "../models";
import { AddNewCargoType } from "./addCargoType";
import { CargoTypeTable } from "./cargoTypeTable";

const item: TabsProps["items"] = [
  {
    key: ICargoTypeTabs.addNewCargoType,
    label: (
      <span>
        <span className="material-icons text-[10px] p-[8px]">lens</span>
        Add new cargo type
      </span>
    ),
    children: <AddNewCargoType />,
  },
  {
    key: ICargoTypeTabs.cargoTypeTable,
    label: (
      <span>
        <span className="material-icons text-[10px] p-[10px]">lens</span>
        Cargo type table
      </span>
    ),
    children: <CargoTypeTable />,
  },
];

export const CargoTypeTabs: FC = () => {
  const [params] = useSearchParams();
  const defaultKey =
    params.get("table") === "true"
      ? ICargoTypeTabs.cargoTypeTable
      : ICargoTypeTabs.addNewCargoType;
  return (
    <TabsContainer>
      <Tabs defaultActiveKey={defaultKey} items={item} />
    </TabsContainer>
  );
};
