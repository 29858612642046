import { Dispatch, FC, SetStateAction } from "react";
import { Col, Divider } from "antd";
import moment from "moment";
import { INewAllShipDailyReportResult } from "src/services/DailyReport/models/result.model";
import { TradeStatus } from "src/models/enums";
// import { useShipDailyReports } from "../../context";
import {
  // positionText,
  shipStatusText,
  tradeStatus,
} from "src/helpers/shipDailyReport";

interface IProps {
  report: INewAllShipDailyReportResult;
  openId: number;
  setOpenId: Dispatch<SetStateAction<number>>;
  onRead: (
    shipId: number,
    reportDate: string
  ) => Promise<INewAllShipDailyReportResult | undefined>;
  updateReports: (report: INewAllShipDailyReportResult) => void;
}

const ReportCard: FC<IProps> = ({
  report,
  openId,
  setOpenId,
  onRead,
  updateReports,
}) => {
  // const {
  //   func: { changeDailyReportReadStatus, updateReports },
  // } = useShipDailyReports();

  const handleOpenDetail = () => {
    if (report.id === openId) setOpenId(0);
    else {
      setOpenId(report.id);
      if (report.isSeen === false) {
        // setDetailLoading(true);
        onRead(report.shipId, report.reportDate).then((result) => {
          if (result) {
            updateReports(result);
          }
        });
        // .finally(() => setDetailLoading(false));
      }
    }
  };

  return (
    <Col
      span={23}
      className={`report-card ${
        openId !== report.id ? "bg-[#fff]" : "bg-[#DBE2E6]"
      }`}
      onClick={handleOpenDetail}
    >
      <div className="w-full h-[64px] flex items-center justify-between">
        <section className="flex items-center">
          <div>
            <Divider
              type="vertical"
              className="w-[4px] h-[40px] bg-[#DADEEC]"
            />
          </div>
          <div>
            <div className="flex items-center">
              <span className="text-[#3E4556] text-[12px] font-semibold">
                {report?.shipName}
              </span>
              <Divider
                type="vertical"
                className="w-[3px] h-[18px] bg-[#DADEEC]"
              />
              <span
                className={`text-[12px] font-normal ${
                  report?.tradeStatus === TradeStatus.InVoyage
                    ? "text-[#3E4556]"
                    : "text-[#EC2D30]"
                }`}
              >
                {tradeStatus(report.tradeStatus)}
              </span>
            </div>

            <div className="flex items-center">
              {/* <div className="flex items-center">
                <span className="material-icons text-[#5D6881] text-[12px]">
                  location_on
                </span>
                <span className="text-[#5D6881] text-[12px] font-normal ml-[5px]">
                  {positionText(report.position)}
                </span>
              </div> */}

              <div className="flex items-center text-[#5D6881] text-[12px] font-normal ml-[15px]">
                <span className="material-icons text-[#5D6881] text-[12px]">
                  oil_barrel
                </span>
                <span className="text-[#5D6881] text-[12px] font-normal ml-[5px]">
                  {shipStatusText(report.shipStatus)}
                </span>
              </div>
            </div>
          </div>
        </section>
        <div className="flex items-center justify-around">
          <span
            className={`text-[12px] font-semibold ${
              report?.isSeen ? "text-[#878787]" : "text-[#FFAD0D]"
            }`}
          >
            {report?.created
              ? moment(report?.reportDate).format("YYYY-MM-DD")
              : ""}
          </span>
          <span
            className={`material-icons text-[12px] ml-[5px] ${
              report?.isSeen ? "text-[#878787]" : "text-[#FFAD0D]"
            }`}
          >
            {openId === report.id ? "arrow_drop_up" : "arrow_drop_down"}
          </span>
        </div>
      </div>

      {openId === report?.id && (
        <div className="max-h-[669px]">
          <Divider className="bg-[#fff] m-0 p-0 mt-[10px]" />
          <div className="message">
            <div className="">
              <span className="material-icons text-[#5D6881] text-[12px]">
                directions_boat
              </span>
              <span className="text-[#5D6881] text-[12px] font-semibold ml-[10px]">
                Voyage
              </span>
            </div>
            <div className="flex items-center mt-[5px]">
              <Divider
                type="vertical"
                className="w-[3px] h-[30px] bg-[#A3AED0]"
              />
              <div>
                {report &&
                  report?.voyage &&
                  report?.voyage.split("\n").map((text, key) => {
                    return <div key={key}>{text}</div>;
                  })}
              </div>
            </div>
          </div>

          <div className="message">
            <div className="">
              <span className="material-icons text-[#5D6881] text-[12px]">
                data_exploration
              </span>
              <span className="text-[#5D6881] text-[12px] font-semibold ml-[10px]">
                Current Situation Report
              </span>
            </div>
            <div className="flex items-center mt-[5px]">
              <Divider
                type="vertical"
                className="w-[3px] h-[30px] bg-[#A3AED0]"
              />
              <div>
                {report &&
                  report?.currentSituationReport &&
                  report?.currentSituationReport
                    .split("\n")
                    .map((text, key) => {
                      return <div key={key}>{text}</div>;
                    })}
              </div>
            </div>
          </div>

          {/* <div className="message">
            <div className="">
              <span className="material-icons text-[#5D6881] text-[12px]">
                oil_barrel
              </span>
              <span className="text-[#5D6881] text-[12px] font-semibold ml-[10px]">
                ROB
              </span>
            </div>
            <div className="flex items-center mt-[5px]">
              <Divider
                type="vertical"
                className="w-[3px] h-[30px] bg-[#A3AED0]"
              />
              <div className="w-full flex justify-center items-around">
                <div className="p-[8px] w-[131px] h-[34px] flex justify-around items-center border border-solid rounded-lg border-[#DADEEC]">
                  <span className="text-[#6C7172] text-[12px] font-semibold">
                    VLSFO
                  </span>
                  <Divider type="vertical" className="w-[2px] h-[18px]" />
                  <span className="text-[#001377] text-[12px] font-medium">
                    {Number(report.vlsfo).toLocaleString()}
                  </span>
                  <span className="text-[#001377] text-[12px] font-medium">
                    MT
                  </span>
                </div>
                <div className="p-[8px] w-[131px] ml-3 h-[34px] flex justify-around items-center border border-solid rounded-lg border-[#DADEEC]">
                  <span className="text-[#6C7172] text-[12px] font-semibold">
                    MGO
                  </span>
                  <Divider type="vertical" className="w-[2px] h-[18px]" />
                  <span className="text-[#001377] text-[12px] font-medium">
                    {Number(report.mgo).toLocaleString()}
                  </span>
                  <span className="text-[#001377] text-[12px] font-medium">
                    MT
                  </span>
                </div>
              </div>
            </div>
          </div> */}

          <div className="message">
            <div className="">
              <span className="material-icons text-[#5D6881] text-[12px]">
                pending_actions
              </span>
              <span className="text-[#5D6881] text-[12px] font-semibold ml-[10px]">
                Actions
              </span>
            </div>
            <div className="flex items-center mt-[5px]">
              <Divider
                type="vertical"
                className="w-[3px] h-[30px] bg-[#A3AED0]"
              />
              <div>
                {report &&
                  report?.action &&
                  report?.action.split("\n").map((text, key) => {
                    return <div key={key}>{text}</div>;
                  })}
              </div>
            </div>
          </div>

          <div className="message">
            <div className="">
              <span className="material-icons text-[#5D6881] text-[12px]">
                app_registration
              </span>
              <span className="text-[#5D6881] text-[12px] font-semibold ml-[10px]">
                Future plan
              </span>
            </div>
            <div className="flex items-center mt-[5px]">
              <Divider
                type="vertical"
                className="w-[3px] h-[30px] bg-[#A3AED0]"
              />
              <div>
                {report &&
                  report?.futurePlan &&
                  report?.futurePlan.split("\n").map((text, key) => {
                    return <div key={key}>{text}</div>;
                  })}
              </div>
            </div>
          </div>
        </div>
      )}
    </Col>
  );
};

export default ReportCard;
