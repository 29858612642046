import { Button, Col, Input, Modal, Popover } from "antd";
import { FC, useEffect, useState } from "react";
import { CommentContainer } from "./styles";
import textAreaMaxLength from "src/constants/textAreaMaxLength";
import { Guard } from "src/components/Guard";
import { IPath } from "src/services/Identity/models";

interface IProps {
  referenceNumber: string;
  invoices?: string;
  show: boolean;
  onCancel: () => void;
  comment: string;
  onEdit: (comment: string) => void;
  onEditLoading?: boolean;
  editAction: IPath;
}
export const CommentModal: FC<IProps> = ({
  referenceNumber,
  invoices,
  show,
  onCancel,
  comment,
  onEdit,
  onEditLoading,
  editAction,
}) => {
  const [editMode, setEditMode] = useState<boolean>(false);
  const [newComment, setNewComment] = useState<string>(comment);
  const submitEditMode = () => {
    new Promise((resolve, reject) => {
      onEdit(newComment);
      resolve(true);
    }).then(() => {
      setEditMode(false);
    });
  };
  const changeToEditMode = () => {
    setEditMode(true);
  };

  useEffect(() => {
    if (show) {
      setNewComment(comment);
    } else {
      setNewComment("");
    }
  }, [comment, show]);
  return (
    <Modal
      open={show}
      width={invoices ? "50%" : "40%"}
      footer={null}
      closable={false}
      onCancel={onCancel}
    >
      <CommentContainer gutter={[0, 24]}>
        <Col span={24} className="flex justify-between gap-[15%] titleHolder">
          <div className="flex justify-start gap-[5px]">
            <span
              className="material-symbols-outlined !font-bold "
              style={{ color: "#A3AED0", fontSize: "32px" }}
            >
              mode_comment
            </span>
            <p className="description">Description</p>
          </div>
          <p className={`reference p-[7px] ${invoices ? "!right-[28%] " : ""}`}>
            {referenceNumber}
          </p>
          {invoices && invoices.length > 13 ? (
            <Popover title={invoices}>
              <p className="invoices flex  p-[7px]">
                {invoices?.substring(0, 10).concat("...")}
              </p>
            </Popover>
          ) : (
            <p className="invoices flex  p-[7px]">{invoices}</p>
          )}
        </Col>
        <Col span={24} className="commentInput">
          <Input.TextArea
            rows={6}
            bordered={false}
            autoSize={{ maxRows: 6, minRows: 6 }}
            style={{ height: "100%", background: "#f4f7fe" }}
            value={newComment}
            disabled={!editMode}
            onChange={(event) => setNewComment(event.target.value)}
            maxLength={textAreaMaxLength}
            showCount
          />
        </Col>
        <Col span={24} className="flex justify-center footerContainer">
          <Guard action={editAction}>
            <Button
              type="primary"
              htmlType="button"
              onClick={editMode ? submitEditMode : changeToEditMode}
              className="w-[25%] flex justify-center"
              loading={onEditLoading}
            >
              {editMode ? "Save" : "Edit"}
            </Button>
          </Guard>
          <Button
            htmlType="button"
            onClick={onCancel}
            className="w-[25%] justify-center"
          >
            Close
          </Button>
        </Col>
      </CommentContainer>
    </Modal>
  );
};
