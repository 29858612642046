import { FC, useState } from "react";
import { Button, Col, Flex, Form, Row, Select, TimePicker } from "antd";
import TextArea from "antd/es/input/TextArea";
import validator from "validator";
import { FormItem } from "src/components/UiKit/FormItem";
import { SInput } from "src/components/UiKit/Input";
import { SPopconfirm } from "src/components/UiKit/PopConfirm";
import SubmitButton from "./SubmitButton";
import { AddNewPortContainer } from "../../styles";
import { usePorts } from "../../context";
import { Guard } from "src/components/Guard";
import { NewPortPath, UpdatePortPath } from "src/services/BaseInfo/guardPath";

export const AddNewPort: FC = () => {
  const [submittable, setSubmittable] = useState<boolean>(false);

  const {
    value: { countryList, form, formMode, loadings },
    func: { onFinishForm, handleBack },
  } = usePorts();

  return (
    <AddNewPortContainer>
      <Form
        form={form}
        onFinish={onFinishForm}
        disabled={formMode === "view" ? true : false}
      >
        <Row>
          <Col span={24} className="titleHolder">
            <div className="pt-[16px] titleHolder">
              Please insert port information.
            </div>
          </Col>
          <Col span={24} className="pt-[40px] h-[115px]">
            <Row gutter={32}>
              <Col span={8}>
                <FormItem
                  name="countryId"
                  label="countryId"
                  rules={[{ required: true }]}
                  className="w-full"
                >
                  <Select
                    placeholder="select"
                    optionLabelProp="name"
                    showSearch
                    loading={loadings.country}
                    className="selectOption w-full"
                    optionFilterProp="label"
                    options={countryList.map((country) => ({
                      label: country.name,
                      name: country.abbrivation,
                      value: country.id,
                    }))}
                  />
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem
                  name="name"
                  label="Port name"
                  rules={[{ required: true }]}
                  className="w-full"
                >
                  <SInput placeholder="insert" />
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem
                  name="abbrivation"
                  label="Abbreviation"
                  rules={[{ required: true }]}
                  className="w-full"
                >
                  <SInput placeholder="insert" />
                </FormItem>
              </Col>
            </Row>
          </Col>

          <Col span={24} className="pt-[40px] h-[115px]">
            <Row gutter={32}>
              <Col span={8}>
                <div className="timeZone">
                  <label>Local time (UTC)</label>
                  <Flex className="wrapper">
                    <FormItem
                      name={"localTimeS"}
                      rules={[
                        { required: true, message: "Select UTC is required." },
                      ]}
                    >
                      <Select placeholder="Select">
                        <Select.Option value={true}>+ UTC</Select.Option>
                        <Select.Option value={false}>- UTC</Select.Option>
                      </Select>
                    </FormItem>
                    <FormItem
                      name="timeZone"
                      // label="Local time (UTC)"
                      rules={[
                        {
                          required: true,
                          message: "Select Local time is required.",
                        },
                      ]}
                      className="w-full "
                    >
                      <TimePicker
                        needConfirm={false}
                        showNow={false}
                        allowClear={false}
                        minuteStep={30}
                        format={"HH:mm"}
                        placeholder="Select"
                      />
                    </FormItem>
                  </Flex>
                </div>
              </Col>
              <Col span={8}>
                <FormItem
                  name="latLong"
                  label="Latitude/Longitude"
                  className="w-full"
                  rules={[
                    () => ({
                      validator(_, value) {
                        if (!value) {
                          return Promise.resolve();
                        }
                        const latLong = value.split("/");
                        if (latLong.length !== 2) {
                          return Promise.reject(
                            new Error("use / between Latitude/Longitude.")
                          );
                        }
                        const lat = latLong[0].trim();
                        const long = latLong[1].trim();
                        if (validator.isLatLong(`${lat},${long}`)) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error("Latitude/Longitude is not valid") //"Latitude/Longitude format in not correct."
                        );
                      },
                    }),
                  ]}
                >
                  <SInput placeholder="insert" allowClear />
                </FormItem>
              </Col>
            </Row>
          </Col>

          <Col span={24} className="mt-[24px]">
            <FormItem name="description" label="Description">
              <TextArea
                placeholder="Description"
                rows={4}
                style={{
                  height: 163,
                  resize: "none",
                  borderRadius: 12,
                  background: "#F4F7FE",
                }}
              />
            </FormItem>
          </Col>
        </Row>
      </Form>
      <Row>
        <Col span={24} className="flex justify-end footer gap-[16px]">
          {formMode === "add" ? (
            <Guard action={NewPortPath}>
              <SPopconfirm
                description="Are you sure you want to reset all the information you filled out?"
                onConfirm={() => {
                  form?.resetFields();
                }}
              >
                <Button
                  className="w-[100px]"
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                >
                  Reset
                </Button>
              </SPopconfirm>
            </Guard>
          ) : (
            <Button onClick={handleBack}>Back</Button>
          )}
          {(formMode === "add" || formMode === "edit") && (
            <Guard action={[NewPortPath, UpdatePortPath]}>
              <SubmitButton
                submittable={submittable}
                setSubmittable={setSubmittable}
              />
            </Guard>
          )}
        </Col>
      </Row>
    </AddNewPortContainer>
  );
};
