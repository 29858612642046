import { Col, Divider, Row, Skeleton } from "antd";
import { FC } from "react";
import { EditBaseInformationContainer } from "../styles/baseInformation";

export const EditBaseInformationSkeleton: FC = () => {
  return (
    <EditBaseInformationContainer>
      <Col span={24}>
        <div className="title">you can edit invoice’s basic information .</div>
        <Row gutter={32}>
          <Col span={24} className="mt-[24px]">
            <Row gutter={24}>
              <Col span={12}>
                <div>
                  <Skeleton active className="!w-[150px]" paragraph={false} />
                  <Skeleton.Input active className="!w-full" />
                </div>
              </Col>
              <Col span={12}>
                <div>
                  <Skeleton active className="!w-[150px]" paragraph={false} />
                  <Skeleton.Input active className="!w-full" />
                </div>
              </Col>
            </Row>
          </Col>

          <Col span={24}>
            <Divider />
          </Col>
          <Col span={24}>
            <div className="title">
              Please insert invoice’s base information .
            </div>
            <Row gutter={[32, 32]}>
              <Col span={12} className="mt-[16px]">
                <div>
                  <Skeleton active className="!w-[100px]" paragraph={false} />
                  <Skeleton.Input active className="!w-full" />
                </div>
                <div className="mt-[16px]">
                  <Skeleton active className="!w-[100px]" paragraph={false} />
                  <Skeleton.Input active className="!w-full" />
                </div>
                <div className="mt-[16px]">
                  <Skeleton active className="!w-[100px]" paragraph={false} />
                  <Skeleton.Input active className="!w-full" />
                </div>
              </Col>
              <Col span={12} className="mt-[16px]">
                <div>
                  <Skeleton active className="!w-[100px]" paragraph={false} />
                  <Skeleton.Input active className="!w-full" />
                </div>
                <div className="mt-[16px]">
                  <Skeleton active className="!w-[100px]" paragraph={false} />
                  <Skeleton.Input active className="!w-full" />
                </div>
                <div className="mt-[16px]">
                  <Skeleton active className="!w-[100px]" paragraph={false} />
                  <Skeleton.Input active className="!w-full" />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Divider className="mb-[32px]" />
      </Col>
      <Col span={24} className="flex justify-end gap-[24px]  footer">
        <Skeleton.Button active />

        <Skeleton.Button active />
      </Col>
    </EditBaseInformationContainer>
  );
};
