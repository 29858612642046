import { FC } from "react";
import { VoyageContainer } from "./styles";
import { ShipDirection } from "./components/shipDirection";
import { useGetDailyReport } from "../../hook";
import { StateDescription } from "./components/stateDescription";
import { Spin } from "antd";

export const Voyage: FC = () => {
  const { loading } = useGetDailyReport();
  if (loading) {
    return (
      <div className="flex justify-center items-center h-[90%]">
        <Spin />
      </div>
    );
  }
  return (
    <VoyageContainer>
      <ShipDirection />
      <StateDescription />
    </VoyageContainer>
  );
};
