import { FC, Suspense } from "react";
import { AgentsProvider } from "src/modules/agents/context";
import { AgentEdit } from "src/modules/agents/modules/edit";
import { PreLoading } from "src/routes/Loading";

export const AgentEditPage: FC = () => {
  return (
    <Suspense fallback={<PreLoading />}>
      <AgentsProvider>
        <AgentEdit />
      </AgentsProvider>
    </Suspense>
  );
};
