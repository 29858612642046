import styled from "styled-components";

export const DirectionProgressContainer = styled.div<{ percent: number }>`
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: relative;
  .circle {
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #a5c3fe;
  }
  .circle-active {
    background-color: #2c73fc;
  }
  .dash-line {
    position: absolute;
    height: 3px;
    border-top: 3px dashed #a5c3fe;
    width: calc(100% - 20px);
    left: 10px;
    top: 4px;
  }
  .triangle {
    width: 0;
    height: 0;
    border: 7px solid transparent;
    border-right: 0;
    border-left: 15px solid #2c73fc;
    z-index: 2;
    position: absolute;
    right: 0;
    top: -7.5px;
    transition: 0.4s;
  }
  .active-line {
    position: absolute;
    height: 3px;
    border-top: 3px solid #2c73fc;
    width: ${({ percent }) =>
      percent >= 100 ? "calc(100% - 20px)" : `${percent}%`};
    left: 10px;
    top: 4px;
    transition: 0.4s;
  }
`;
