import { styled } from "styled-components";

export const HeaderEditShipContainer = styled.div`
  border-bottom: 1px solid var(--shadow, #dadeec);
  margin-bottom: 25px;
  display: flex;
  height: 80px;
  padding: 0px 24px 19px 24px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
`;
