import { Button, Divider, Form, Input, Modal, message } from "antd";
import { FC, useEffect, useState } from "react";
import { ChangePasswordContainer } from "./changePassword.style";
import { useMediaQuery } from "src/hook";
import { FormItem } from "src/components/UiKit/FormItem";
import { breakPointsSm } from "src/constants/screen";
import { UserManagementService } from "src/services/UserManagement/UserManagement.service";
import { IUsersReport } from "src/services/UserManagement/models";
interface IProps {
  isModalOpen: boolean;
  onCancel: () => void;
  currentUser?: IUsersReport;
}
interface IResetPasswordForm {
  newPassword: string;
  repeatPassword?: string;
}
export const ResetPassword: FC<IProps> = ({
  isModalOpen,
  onCancel,
  currentUser,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const isMobileDevice = !useMediaQuery(breakPointsSm);
  const [submittable, setSubmittable] = useState(false);
  const values = Form.useWatch([], form);

  useEffect(() => {
    form.validateFields({ validateOnly: true }).then(
      () => {
        setSubmittable(true);
      },
      () => {
        setSubmittable(false);
      }
    );
  }, [form, values]);

  const onFinish = async (values: IResetPasswordForm) => {
    if (!currentUser) return;
    try {
      setLoading(true);
      const { ResetUserPassword } = new UserManagementService();
      if (values.newPassword === values.repeatPassword) {
        const result = await ResetUserPassword(
          currentUser.id,
          values.newPassword
        );
        if (result && result.status === 200) {
          onCancel();
          message.success("Password reset successful");
        }
        form.resetFields();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  const Title: FC = () => {
    return (
      <>
        Change user’s password
        <div className="text-[#5D6881] text-[14px] mt-[12px]">
          {currentUser?.userName}
        </div>
      </>
    );
  };
  return (
    <div>
      <Modal
        width={isMobileDevice ? "95%" : "34.7%"}
        title={<Title />}
        open={isModalOpen}
        onCancel={onCancel}
        footer={
          <div className="flex justify-center gap-[24px] footer  mt-[32px]">
            <Button
              className="w-[30%] flex items-center justify-center"
              htmlType="button"
              onClick={() => {
                onCancel();
                form.resetFields();
              }}
              icon={<span className="material-icons">close</span>}
            >
              Cancel
            </Button>
            <Button
              className="w-[30%] flex items-center justify-center"
              type="primary"
              htmlType="button"
              loading={loading}
              onClick={form.submit}
              disabled={!submittable}
              icon={<span className="material-icons">check_circle</span>}
            >
              Save
            </Button>
          </div>
        }
        afterClose={() => {
          form.resetFields();
        }}
        className="relative"
      >
        <ChangePasswordContainer>
          <Form className="w-[100%]" onFinish={onFinish} form={form}>
            <div>
              <Divider className="mt-0 mb-0" />
              <div className="nameHolder mt-[48px] mb-[48px]">
                Please insert User’s new password.
              </div>

              <FormItem
                label={"New password"}
                name="newPassword"
                rules={[{ required: true }]}
                required={false}
              >
                <Input.Password
                  placeholder="insert"
                  className="inputPassword"
                />
              </FormItem>
              <FormItem
                label={"Repeat password"}
                name="repeatPassword"
                style={{}}
                dependencies={["newPassword"]}
                rules={[
                  { required: true },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("newPassword") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          "The new password that you entered do not match!"
                        )
                      );
                    },
                  }),
                ]}
                required={false}
              >
                <Input.Password
                  placeholder="insert"
                  className="inputPassword"
                />
              </FormItem>
            </div>
          </Form>
        </ChangePasswordContainer>
        <Divider className="mt-[24px] mb-0" />
      </Modal>
    </div>
  );
};
