export enum ROUTES {
  home = "/",
  login = "/login",
  ships = "/ships",
  invoices = "/invoices",
  table = "/table",
  security = "/security",
  settings = "/settings",
  Report = "/report",
  ShipDailyReport = "/ship-daily-report",
  voyage = "/voyage",
  cargos = "/cargos",
  actions = "/actions",
  futurePlan = "/future-plan",
  instruction = "/instruction",
  CreateRole = "/settings/create-role",
  AssignActionToRole = "/settings/assign-action-to-role",
  CreateUser = "/settings/create-user",
  AssignRoleToUser = "/settings/assign-role-to-user",
  Category = "/category",
  Flow = "/flow",
  AddShips = "/add-ships",
  ShipManagement = "/ship-management",
  Supplier = "/supplier",
  SupplierView = "/supplier/view",
  SupplierEdit = "/supplier/edit",
  SupplierEditPage = "/supplier/edit/",
  SupplierViewPage = "/supplier/view/",
  Requester = "/requester",
  EditRequester = "/requester/edit/:id",
  EditRequesterPage = "/requester/edit/",
  FrontAccess = "/settings/front-access",
  Documentation = "/documentation",
  ShipDailyReportOwner = "/ships-daily-report",
  ShipDailyReportShips = "/ship-daily-report-vessels",
  ShipDailyReportCalendar = "/ship-daily-report-calendar",
  ShipDailyReports = "/ship-daily-reports",
  ShipDailyReportDetail = "/ship-daily-report-detail",
  NoonReport = "/noonReport",
  Port = "/port",
  Agent = "/agent",
  AgentView = "/agent/view",
  AgentEdit = "/agent/edit",
  ProfitReport = "/profit-report",
  Commercial = "/voyages/commercial",
  BaseInfo = "/baseInfo",
  Country = "/country",
  Currency = "/currency",
  CargoGroup = "/cargoGroup",
  CargoType = "/cargoType",
  Charterer = "/charterer",
  Receiver = "/receiver",
  Payer = "/payer",
}
