import { Row } from "antd";
import styled from "styled-components";

export const ProfitTradeWrapper = styled(Row)`
  .title {
    color: var(--color-Light-Mode-Text-Title, #001377);
    /* En/Headline/H6.SemiBold */
    font-family: "Plus Jakarta Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 0;
  }
  .subTitle {
    color: var(--color-Light-Mode-Text-Title, #001377);
    /* En/Button/B2.Medium */
    font-family: "Plus Jakarta Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;

export const ProfitTradeFormItemsWrapper = styled(Row)`
  .expenses {
    color: var(--color-Icon-Delete, #ec2d30);

    /* En/Headline/H6.SemiBold */
    font-family: "Plus Jakarta Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-block: 24px;
  }
  .income {
    color: var(--color-Icon-Edit, #0c9d61);

    /* En/Headline/H6.SemiBold */
    font-family: "Plus Jakarta Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-block: 24px;
  }
`;
