import { ColumnsType } from "antd/es/table";
import { FC, useEffect, useState } from "react";
import { TableUI } from "src/components/UiKit/table";
import { getRowKey } from "src/helpers/getRowKey";
import { useChangeParams } from "src/hook/useParamsChange";
import { ITablePagination } from "src/models/interfaces/pagination";
import { ShipManagementTableContainer } from "../../styles";
import { SortOrder } from "antd/es/table/interface";
import { useShipManagement } from "../../context";
import { IGetShipManagement } from "src/services/Ship/models";
import { ShipService } from "src/services/Ship/Ship.service";
import { message } from "antd";
import { SPopconfirm } from "src/components/UiKit/PopConfirm";
import { Guard } from "src/components/Guard";
import {
  DeletetShipManagementPath,
  EditShipManagementPath,
} from "src/services/Ship/guardPath";

export const ShipManagementTable: FC = () => {
  const [pagination, setPagination] = useState<ITablePagination>({
    current: 1,
    pageSize: 10,
    total: 10,
  });
  const onChangeParams = useChangeParams();
  const {
    dispatch: { setEditMode, setTableCurrentValue, setShowMode },
    value: { tableData, tableLoading },
    func: { fetchTableData },
  } = useShipManagement()!;
  const changeEditMode = (data: IGetShipManagement) => {
    setEditMode(true);
    setTableCurrentValue(data);
  };
  const changeShowMode = (data: IGetShipManagement) => {
    setShowMode(true);
    setTableCurrentValue(data);
  };
  const deleteManagement = async (id: number) => {
    try {
      const { DeletetShipManagement } = new ShipService();
      const response = await DeletetShipManagement(id);
      if (response && response.status === 200) {
        message.success("ShipManagement successfully deleted");
        fetchTableData();
      }
    } catch (err) {
      console.log(err);
    }
  };
  //--------------------------------------Table Columns-------------------------------------------
  const columns: ColumnsType<IGetShipManagement> = [
    {
      title: "#",
      dataIndex: "#",
      key: "index",
      width: 37,
      align: "center",
      // fixed: "left",
      render: (name: string, record: any, index: number) =>
        (pagination.current - 1) * pagination.pageSize + (index + 1),
    },
    {
      title: "Company name",
      dataIndex: "name",
      key: "name",
      width: 138,
      align: "center",
      // fixed: "left",
      className: "whitespace-nowrap",
    },
    {
      title: "Abbreviation ",
      dataIndex: "acronym",
      key: "acronym",
      width: 70,
      align: "center",
      className: "whitespace-nowrap",
    },
    {
      title: "Rank",
      dataIndex: "rank",
      key: "rank",
      width: 50,
      align: "center",
      sorter: (a: any, b: any) => a.ship?.length - b.ship?.length,
      sortDirections: ["descend"],
      sortIcon: (props: { sortOrder: SortOrder }) => (
        <span className="material-icons">sort</span>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: 212,
      align: "center",
      className: "whitespace-nowrap",
    },
    {
      title: "Phone number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      width: 140,
      align: "center",
      className: "whitespace-nowrap",
    },

    {
      title: "Country",
      dataIndex: "country",
      key: "country",
      width: 80,
      align: "center",
      className: "whitespace-nowrap",
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
      width: 60,
      align: "center",
      className: "whitespace-nowrap",
    },
    {
      title: "Website",
      dataIndex: "webSite",
      key: "webSite",
      width: 146,
      align: "center",
      className: "whitespace-nowrap",
    },
    {
      title: "Tools",
      dataIndex: "tools",
      key: "tools",
      width: 132,
      align: "center",
      render: (text, record) => (
        <div className="flex gap-[16px] px-[24px] py-[16px] justify-center items-center">
          <Guard action={EditShipManagementPath}>
            <span
              className="material-icons text-[#01C18F] text-[16px] cursor-pointer "
              onClick={() => changeEditMode(record)}
            >
              edit_note
            </span>
          </Guard>

          <span
            className="material-icons text-[#2C73FC] text-[16px] cursor-pointer"
            onClick={() => changeShowMode(record)}
          >
            remove_red_eye
          </span>
          <Guard action={DeletetShipManagementPath}>
            <SPopconfirm
              onConfirm={() => {
                deleteManagement(record.id);
              }}
              description="Are you sure you want to delete this shipManagement?"
              deleteClassName
            >
              <span className="material-icons text-[#FF1414] text-[16px] cursor-pointer">
                delete_forever
              </span>
            </SPopconfirm>
          </Guard>
        </div>
      ),
    },
  ];
  useEffect(() => {
    fetchTableData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <ShipManagementTableContainer>
      <TableUI
        columns={columns}
        tableType="primary"
        dataSource={tableData}
        rowKey={getRowKey}
        pagination={{
          position: ["bottomCenter"],
          showSizeChanger: true,
          total: pagination.total,
          current: pagination.current,
          pageSize: pagination.pageSize,
          onChange: (page: number, pageSize: number) => {
            onChangeParams("Limit", pageSize.toString());
            onChangeParams(
              "Offset",
              pageSize !== pagination.pageSize ? "1" : page.toString()
            );
            setPagination((prev) => ({
              ...prev,
              current: pageSize !== prev.pageSize ? 1 : page,
              pageSize,
            }));
          },
        }}
        scroll={{ x: 1065 }}
        loading={tableLoading}
        style={{}}
      />
    </ShipManagementTableContainer>
  );
};
