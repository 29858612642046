import { FC, useEffect, useState } from "react";
import { Col, Flex, Row, Spin } from "antd";
import useOwnerStore from "src/store/owner";
import { IShip } from "src/services/Public/models";
import { ROUTES } from "src/models/enums";
import { useNavigate } from "react-router-dom";
import { useShipDailyReportShips } from "../context";
import SkeletonImage from "antd/es/skeleton/Image";

const Ships: FC = () => {
  const { setSelectedShip, setOwnerViewDate } = useOwnerStore();
  const {
    value: { loading, shipsData },
  } = useShipDailyReportShips();
  const [ships, setShips] = useState<IShip[]>(shipsData);
  const navigate = useNavigate();

  const handleClickShipCard = (ship: IShip) => {
    setSelectedShip(ship);
    setOwnerViewDate(undefined);
    navigate(ROUTES.ShipDailyReports);
  };
  useEffect(() => {
    setShips(shipsData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shipsData, ships[0]?.image]);

  return (
    <Row gutter={[16, 16]}>
      {loading ? (
        <div className="w-full flex items-center justify-center">
          <Spin size="large" />
        </div>
      ) : (
        ships?.map((ship) => (
          <Col span={8} key={ship.id} onClick={() => handleClickShipCard(ship)}>
            <div className="bg-[#fff] h-[127px] w-[114px] rounded-lg ship-col">
              {ship.image ? (
                <img
                  src={
                    ship.image && ship.image !== "noImage"
                      ? ship.image
                      : "./assets/images/littleCardShip.svg"
                  }
                  alt="ship"
                  height={60}
                  width={98}
                  className="w-full rounded-[8px]"
                />
              ) : (
                <SkeletonImage style={{ height: 60, width: 98 }} active />
              )}
              <Flex vertical className="mt-[13px]">
                <span className="text-[12px] text-[#3E4556] font-bold ">
                  {ship.name}
                </span>
                <Flex align="center" className="!whitespace-nowrap">
                  <span className="material-icons text-[10px]">file_copy</span>
                  <span className="px-1 text-[10px] text-[#FFAD0D] font-bold">
                    {ship.countDailyReport}
                  </span>
                  <span className="text-[10px] text-[#A3AED0] font-medium whitespace-nowrap">
                    Unread reports
                  </span>
                </Flex>
              </Flex>
            </div>
          </Col>
        ))
      )}
    </Row>
  );
};

export default Ships;
