import { IPath } from "../Identity/models";

export const InvoiceGuardPath: IPath[] = [
  {
    path: "api/Invoice/NewAllRequest",
    httpMethod: "POST",
  },
  {
    path: "api/Invoice/AllRequest",
    httpMethod: "GET",
  },
  {
    path: "api/Invoice/{allrequestId}/UpdateAllRequest",
    httpMethod: "PUT",
  },
  {
    path: "api/Invoice/{allrequestId}/DeleteAllRequest",
    httpMethod: "DELETE",
  },
  {
    path: "api/Invoice/{allrequestId}/AllRequestFileChecker",
    httpMethod: "GET",
  },
  {
    path: "api/Invoice/{allrequestId}/ZipFile",
    httpMethod: "GET",
  },
  {
    path: "api/Invoice/{allrequestId}/ScreenFile",
    httpMethod: "GET",
  },
  {
    path: "api/Invoice/{AllRequestAttachmentId}/DeleteAllRequestAttachment",
    httpMethod: "DELETE",
  },
  {
    path: "api/Invoice/{allrequestId}/V3/AttachZipToAllRequest",
    httpMethod: "POST",
  },
  {
    path: "api/Invoice/{allrequestId}/V3/AttachScreenShotToAllRequest",
    httpMethod: "PATCH",
  },
  {
    path: "api/Invoice/{allrequestId}/V3/AllRequestZipDetail",
    httpMethod: "PATCH",
  },
  {
    path: "api/Invoice/{AllRequestAttachmentId}/V3/UpdateAllRequestZipDetail",
    httpMethod: "PUT",
  },
  {
    path: "api/Invoice/{allrequestId}/AllRequestZipAttachment",
    httpMethod: "GET",
  },
  {
    path: "api/Invoice/{AllRequestZipAttachmentId}/File",
    httpMethod: "GET",
  },
  {
    path: "api/Invoice/InitInvoiceInof",
    httpMethod: "GET",
  },
  {
    path: "api/Invoice/NewInvoice",
    httpMethod: "POST",
  },
  {
    path: "api/Invoice/{InvoiceId}/GetRelatedInvoice",
    httpMethod: "GET",
  },
  {
    path: "api/Invoice/{RelatedInvoiceId}/DeleteRelatedInvoice",
    httpMethod: "DELETE",
  },
  {
    path: "api/Invoice/{InvoiceId}/UpdateBasicInformationInvoice",
    httpMethod: "PUT",
  },
  {
    path: "api/Invoice/{InvoiceId}/UpdateTotalInformationInvoice",
    httpMethod: "PUT",
  },
  {
    path: "api/Invoice/{InvoiceId}/DeleteInvoice",
    httpMethod: "DELETE",
  },
  {
    path: "api/Invoice/{InvoiceId}/GetInvoice",
    httpMethod: "GET",
  },
  {
    path: "api/Invoice/InvoiceByInvoiceNumber",
    httpMethod: "GET",
  },
  {
    path: "api/Invoice/{InvoiceId}/NewInvoiceArticle",
    httpMethod: "POST",
  },
  {
    path: "api/Invoice/{InvoiceId}/AllArticle",
    httpMethod: "GET",
  },
  {
    path: "api/Invoice/{InvoiceId}/DeleteArticle",
    httpMethod: "DELETE",
  },
  {
    path: "api/Invoice/{InvoiceArticelId}/UpdateArticle",
    httpMethod: "PUT",
  },
  {
    path: "api/Invoice/{invoiceId}/InvoiceAttqachment",
    httpMethod: "PATCH",
  },
  {
    path: "api/Invoice/{invoiceId}/InvoiceAttachment",
    httpMethod: "GET",
  },
  {
    path: "api/Invoice/{invoiceId}/TotlaInvoice",
    httpMethod: "POST",
  },
  {
    path: "api/Invoice/{invoiceId}/PaymanetInformation",
    httpMethod: "POST",
  },
  {
    path: "api/Invoice/{invoiceId}/UpdateInvoiceComment",
    httpMethod: "PUT",
  },
  {
    path: "api/Invoice/{invoiceId}/CloseInvoice",
    httpMethod: "POST",
  },
  {
    path: "api/Invoice/{allRequestAttachmentId}/CloseAllRequestAttachment",
    httpMethod: "POST",
  },
  {
    path: "api/Invoice/{attachmentId}/InvoiceFileDetail",
    httpMethod: "GET",
  },
  {
    path: "api/Invoice/{invoiceId}/FinallyInvoice",
    httpMethod: "GET",
  },
  {
    path: "api/Invoice/AllRequestOPT",
    httpMethod: "GET",
  },
  {
    path: "api/Invoice/{AllRequestAttachmentId}/V3/UpdateAllRequestZipDetailInfp",
    httpMethod: "PUT",
  },
];

export const NewAllRequestPath: IPath = {
  path: "api/Invoice/NewAllRequest",
  httpMethod: "POST",
};
export const AllRequestPath: IPath = {
  path: "api/Invoice/AllRequest",
  httpMethod: "GET",
};
export const UpdateAllRequestPath: IPath = {
  path: "api/Invoice/{allrequestId}/UpdateAllRequest",
  httpMethod: "PUT",
};
export const DeleteAllRequestPath: IPath = {
  path: "api/Invoice/{allrequestId}/DeleteAllRequest",
  httpMethod: "DELETE",
};
export const AllRequestFileCheckerPath: IPath = {
  path: "api/Invoice/{allrequestId}/AllRequestFileChecker",
  httpMethod: "GET",
};
export const ZipFilePath: IPath = {
  path: "api/Invoice/{allrequestId}/ZipFile",
  httpMethod: "GET",
};
export const ScreenFilePath: IPath = {
  path: "api/Invoice/{allrequestId}/ScreenFile",
  httpMethod: "GET",
};
export const DeleteAllRequestAttachmentPath: IPath = {
  path: "api/Invoice/{AllRequestAttachmentId}/DeleteAllRequestAttachment",
  httpMethod: "DELETE",
};
export const AttachZipToAllRequestPath: IPath = {
  path: "api/Invoice/{allrequestId}/V3/AttachZipToAllRequest",
  httpMethod: "POST",
};
export const AttachScreenShotToAllRequestPath: IPath = {
  path: "api/Invoice/{allrequestId}/V3/AttachScreenShotToAllRequest",
  httpMethod: "PATCH",
};
export const AllRequestZipDetailPath: IPath = {
  path: "api/Invoice/{allrequestId}/V3/AllRequestZipDetail",
  httpMethod: "PATCH",
};
export const UpdateAllRequestZipDetailPath: IPath = {
  path: "api/Invoice/{AllRequestAttachmentId}/V3/UpdateAllRequestZipDetail",
  httpMethod: "PUT",
};
export const AllRequestZipAttachmentPath: IPath = {
  path: "api/Invoice/{allrequestId}/AllRequestZipAttachment",
  httpMethod: "GET",
};
export const FileGuardPath: IPath = {
  path: "api/Invoice/{AllRequestZipAttachmentId}/File",
  httpMethod: "GET",
};
export const InitInvoiceInofPath: IPath = {
  path: "api/Invoice/InitInvoiceInof",
  httpMethod: "GET",
};
export const NewInvoicePath: IPath = {
  path: "api/Invoice/NewInvoice",
  httpMethod: "POST",
};
export const GetRelatedInvoicePath: IPath = {
  path: "api/Invoice/{InvoiceId}/GetRelatedInvoice",
  httpMethod: "GET",
};
export const DeleteRelatedInvoicePath: IPath = {
  path: "api/Invoice/{RelatedInvoiceId}/DeleteRelatedInvoice",
  httpMethod: "DELETE",
};
export const UpdateBasicInformationInvoicePath: IPath = {
  path: "api/Invoice/{InvoiceId}/UpdateBasicInformationInvoice",
  httpMethod: "PUT",
};
export const UpdateTotalInformationInvoicePath: IPath = {
  path: "api/Invoice/{InvoiceId}/UpdateTotalInformationInvoice",
  httpMethod: "PUT",
};
export const DeleteInvoicePath: IPath = {
  path: "api/Invoice/{InvoiceId}/DeleteInvoice",
  httpMethod: "DELETE",
};
export const GetInvoicePath: IPath = {
  path: "api/Invoice/{InvoiceId}/GetInvoice",
  httpMethod: "GET",
};
export const InvoiceByInvoiceNumberPath: IPath = {
  path: "api/Invoice/InvoiceByInvoiceNumber",
  httpMethod: "GET",
};
export const NewInvoiceArticlePath: IPath = {
  path: "api/Invoice/{InvoiceId}/NewInvoiceArticle",
  httpMethod: "POST",
};
export const AllArticlePath: IPath = {
  path: "api/Invoice/{InvoiceId}/AllArticle",
  httpMethod: "GET",
};
export const DeleteArticlePath: IPath = {
  path: "api/Invoice/{InvoiceId}/DeleteArticle",
  httpMethod: "DELETE",
};
export const UpdateArticlePath: IPath = {
  path: "api/Invoice/{InvoiceArticelId}/UpdateArticle",
  httpMethod: "PUT",
};
export const InvoiceAttqachmentPath: IPath = {
  path: "api/Invoice/{invoiceId}/InvoiceAttqachment",
  httpMethod: "PATCH",
};
export const InvoiceAttachmentPath: IPath = {
  path: "api/Invoice/{invoiceId}/InvoiceAttachment",
  httpMethod: "GET",
};
export const TotlaInvoicePath: IPath = {
  path: "api/Invoice/{invoiceId}/TotlaInvoice",
  httpMethod: "POST",
};
export const PaymanetInformationPath: IPath = {
  path: "api/Invoice/{invoiceId}/PaymanetInformation",
  httpMethod: "POST",
};
export const UpdateInvoiceCommentPath: IPath = {
  path: "api/Invoice/{invoiceId}/UpdateInvoiceComment",
  httpMethod: "PUT",
};
export const CloseInvoicePath: IPath = {
  path: "api/Invoice/{invoiceId}/CloseInvoice",
  httpMethod: "POST",
};
export const CloseAllRequestAttachmentPath: IPath = {
  path: "api/Invoice/{allRequestAttachmentId}/CloseAllRequestAttachment",
  httpMethod: "POST",
};
export const InvoiceFileDetailPath: IPath = {
  path: "api/Invoice/{attachmentId}/InvoiceFileDetail",
  httpMethod: "GET",
};
export const FinallyInvoicePath: IPath = {
  path: "api/Invoice/{invoiceId}/FinallyInvoice",
  httpMethod: "GET",
};
export const AllRequestOPTPath: IPath = {
  path: "api/Invoice/AllRequestOPT",
  httpMethod: "GET",
};
export const UpdateAllRequestZipDetailInfpPath: IPath = {
  path: "api/Invoice/{AllRequestAttachmentId}/V3/UpdateAllRequestZipDetailInfp",
  httpMethod: "PUT",
};
