import { FC } from "react";
import { TableUI } from "src/components/UiKit/table";
import { ColumnProps } from "antd/lib/table";
import moment from "moment";
import { getRowKey } from "src/helpers/getRowKey";
import { INoonReport } from "src/services/NoonReport/models";
import { TableTools } from "./TableTools";
import { ShipStatus } from "src/models/enums";
import CurrentSituationText from "./CurrentSituation";
import { useNoonReportCTX } from "src/modules/NoonReport/context";
import { FlowTableContainer } from "../styles";

export const Table: FC = () => {
  const {
    value: { dataLoading, dataSource, pagination },
    dispatch: { setPagination },
  } = useNoonReportCTX();

  const columns: ColumnProps<INoonReport>[] = [
    {
      dataIndex: "#",
      key: "#",
      title: "#",
      render: (text, record, index) => index + 1,
    },
    {
      dataIndex: "date",
      key: "date",
      title: "Date",
      render: (text) => (text ? moment(text).format("YYYY-MM-DD") : ""),
    },
    {
      dataIndex: "vesselName",
      key: "vesselName",
      title: "Vessel name",
    },
    { dataIndex: "voyageName", key: "voyageName", title: "Voyage" },
    { dataIndex: "cargo", key: "cargo", title: "Cargo" },
    {
      dataIndex: "vesselStatus",
      key: "vesselStatus",
      title: "Vessel status",
      render: (value) => (value === ShipStatus.Laden ? "Laden" : "Ballast"),
    },
    {
      dataIndex: "currentSituation",
      key: "currentSituation",
      title: "Current situation",
      render: (value) => <CurrentSituationText situation={value} />,
    },
    {
      dataIndex: "tools",
      key: "tools",
      title: "Tools",
      render: (value, record) => <TableTools record={record} />,
    },
  ];

  return (
    <FlowTableContainer>
      <TableUI
        columns={columns}
        dataSource={dataSource}
        loading={dataLoading}
        pagination={{
          position: ["bottomCenter"],
          total: pagination.total,
          current: pagination.currentPage,
          pageSize: pagination.pageSize,
          showSizeChanger: true,
          onChange: (page, pageSize) => {
            setPagination((prev) => ({ ...prev, currentPage: page, pageSize }));
          },
          onShowSizeChange: (current, size) => {
            setPagination((prev) => ({
              ...prev,
              currentPage: current,
              pageSize: size,
            }));
          },
        }}
        rowKey={getRowKey}
      />
    </FlowTableContainer>
  );
};
