import { Row } from "antd";
import styled from "styled-components";

export const ProfitBasicWrapper = styled(Row)`
  .title {
    color: var(--color-Light-Mode-Text-Title, #001377);
    /* En/Headline/H6.SemiBold */
    font-family: "Plus Jakarta Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 0;
  }
  .subTitle {
    color: var(--color-Light-Mode-Text-Title, #001377);
    /* En/Button/B2.Medium */
    font-family: "Plus Jakarta Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;

export const ProfitBasicFormItemsWrapper = styled(Row)`
  .quantity {
    .ant-input-group-addon {
      padding: 0 !important;
      border: unset;
      border-radius: 12px 0px 0px 12px !important;
      border-right: 1px solid var(--color-Light-Mode-Border-3, #dadeec);
    }
    .ant-input-group {
      min-height: 48px;
    }
    .ant-input {
      height: 48px;
      border: unset;
      background: transparent;
    }
    .selectOption {
      .ant-select-selector {
        border-radius: 12px 0px 0px 12px !important;
      }
    }
  }
  .rangePicker {
    background: #f4f7fe !important;
    border-color: #f4f7fe !important;
    border-radius: 12px !important;
    height: 46px;
  }
  .rangePicker {
    input::placeholder {
      font-weight: 400;
    }
  }
`;
