import { FC } from "react";
import { ReceiverTabs } from "./components/receiverTabs";
import { useReceiver } from "./context";
import { ReceiverContainer } from "./style";
import { Col, Row } from "antd";
import { AddNewReceiver } from "./components/addReceiver";
import { Guard } from "src/components/Guard";
import {
  AllReceiversPath,
  NewReceiverPath,
  UpdateReceiverPath,
} from "src/services/Income/guardPath";

export const Receiver: FC = () => {
  const {
    value: { editMode },
    func: { onBack },
  } = useReceiver();

  return (
    <ReceiverContainer>
      {editMode ? (
        <Row>
          <Col span={24}>
            <div
              onClick={onBack}
              className="flex justify-between items-center py-[19px] px-[24px] borderBottom"
            >
              <div className="flex flex-row gap-[12px] items-center cursor-pointer">
                <span className="material-icons text-[#2C73FC] text-[32px]">
                  keyboard_backspace
                </span>
                <div className=" text-[#2C73FC] text-[18px] ">
                  Back to table
                </div>
              </div>
              <div className="flex flex-col ">
                <div className="text-[#001377] text-[18px] font-semibold">
                  Edit
                </div>
                <div className="text-[#5D6881] text-[14px] font-bold">
                  Receiver
                </div>
              </div>
            </div>
          </Col>
          <Col span={24}>
            <Guard action={UpdateReceiverPath}>
              <AddNewReceiver />
            </Guard>
          </Col>
        </Row>
      ) : (
        <Guard action={[NewReceiverPath, AllReceiversPath]} multiPath>
          <ReceiverTabs />
        </Guard>
      )}
    </ReceiverContainer>
  );
};
