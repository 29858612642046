import { FC } from "react";
import { TableContainer } from "../style";
import { TableUI } from "src/components/UiKit/table";
import { Col, Popconfirm } from "antd";
import { ColumnsType } from "antd/es/table";
import { getRowKey } from "src/helpers/getRowKey";

export const CargoTypeTable: FC = () => {
  const columns: ColumnsType<any> = [
    {
      title: "#",
      key: "id",
      align: "center",
      width: "55px",
      render: (name, record, index) => index + 1,
      // (pagination.current - 1) * pagination.pageSize + (index + 1),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      align: "center",
      width: "99px",
    },
    {
      title: "Cargo group",
      dataIndex: "cargoGroup",
      key: "cargoGroup",
      align: "center",
      width: "138px",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      align: "center",
      width: "464px",
    },
    {
      title: "Tools",
      key: "tools",
      align: "center",
      width: "130px",
      render: (text, record) => (
        <div className="flex justify-center items-center gap-[16px] text-[16px]">
          {/* <Guard action={UpdateAgentPath}> */}
          <span
            onClick={() => {
              //   onEdit(record);
            }}
            className="material-icons !text-[#0C9D61] text-[16px] cursor-pointer"
          >
            edit_note
          </span>
          {/* </Guard> */}

          {/* <Guard action={DeleteAgentPath}> */}
          <Popconfirm
            title="Delete country"
            // onConfirm={async () => {
            //   await onDelete(record.id);
            // }}
            description="Are you want to delete this?"
          >
            <span className="material-icons !text-[#EC2D30] text-[16px] cursor-pointer">
              delete_forever
            </span>
          </Popconfirm>
          {/* </Guard> */}
        </div>
      ),
    },
  ];

  return (
    <TableContainer justify="center">
      <Col span={15}>
        <TableUI
          style={{ padding: "none" }}
          columns={columns}
          rowKey={getRowKey}
          // dataSource={tableData}
          // loading={tableLoading}
          pagination={false}
        />
      </Col>
    </TableContainer>
  );
};
