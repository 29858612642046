import { FC } from "react";
import { ProfitTableContainer } from "./styles";
import { TableOfProfit } from "./components/table";

export const ProfitTable: FC = () => {
  return (
    <ProfitTableContainer>
      {/* <TotalInfo /> */}
      <TableOfProfit />
    </ProfitTableContainer>
  );
};
