import { ColumnsType } from "antd/es/table";
import { Table } from "antd";
import { IFinallyArticle } from "src/services/Invoice/models";
import { useEditInvoice } from "../context";

const defaultColumns: ColumnsType<IFinallyArticle> = [
  {
    key: "#",
    title: "#",
    dataIndex: "#",
    align: "center",
  },
  {
    title: "Description",
    key: "comment",
    dataIndex: "comment",
    width: 200,
    align: "center",
  },
  {
    title: "Quantity",
    key: "quantity",
    dataIndex: "quantity",
    // width: 91,
    align: "center",
  },
  {
    title: "Unit Quantity",
    key: "unitQuantity",
    dataIndex: "unitQuantity",
    // width: 121,
    align: "center",
  },
  {
    title: "Unit Price",
    key: "unitPrice",
    dataIndex: "unitPrice",
    // width: 94,
    align: "center",
  },
  {
    title: "Amount",
    key: "amount",
    dataIndex: "amount",
    // width: 104,
    align: "center",
  },
  {
    title: "Category",
    key: "categoryName",
    dataIndex: "categoryName",
    // width: 93,
    align: "center",
  },
  // {
  //   title: "Sub category",
  //   key: "subCategoryName",
  //   dataIndex: "subCategoryName",
  //   // width: 93,
  //   align: "center",
  // },
  {
    title: "Cost code",
    key: "costCode",
    dataIndex: "costCode",
    // width: 93,
    align: "center",
  },
];
export const useEditArticlesColumns = (refetch: () => void) => {
  const {
    dispatches: {
      setCurrentArticle,
      setShowDeleteArticle,
      setSelectedArticle,
      setShowAddAndEditRow,
      setShowFinishEdit,
    },
  } = useEditInvoice();
  const operationColumns: ColumnsType<IFinallyArticle> = [
    ...defaultColumns,
    {
      title: "operation",
      key: "operation",
      dataIndex: "operation",
      // width: 93,
      render: (text, record) => {
        return (
          <div className="flex justify-around items-center ">
            <span
              className="material-symbols-outlined text-[#FF1414] text-[20px] cursor-pointer"
              onClick={() => {
                setSelectedArticle([record.id]);
                setShowDeleteArticle(true);
                setShowFinishEdit(true);
                refetch();
              }}
            >
              delete_forever
            </span>
            <span
              className="material-symbols-outlined text-[#5D6881] text-[20px] cursor-pointer"
              onClick={() => {
                setShowAddAndEditRow(true);
                setCurrentArticle(record);
                setShowFinishEdit(true);
              }}
            >
              edit
            </span>
          </div>
        );
      },
    },
  ];
  const selectionColumns: ColumnsType<IFinallyArticle> = [
    ...defaultColumns,
    {
      title: "All",
      key: "all",
      dataIndex: "all",
      // width: 93,
    },
    Table.SELECTION_COLUMN,
  ];
  return {
    operationColumns,
    selectionColumns,
  };
};
