import { Navigate, RouteObject, NonIndexRouteObject } from "react-router-dom";
import { ROUTES } from "src/models/enums/routes";
import { AdminPanelLayout } from "src/templates/mainLayout";

import {
  HomePage,
  TablePage,
  ReportPage,
  ShipDailyReportPage,
  CreateRolePage,
  AssignActionToRolePage,
  CreateUserPage,
  AssignRoleToUserPage,
  CategoryPage,
  FlowPage,
  AddShipsPage,
  ShipManagementPage,
  AddNewSupplierPage,
  SupplierViewPage,
  SupplierEditPage,
  RequesterPage,
  FrontAccessPage,
  DocumentationPage,
  NoonReportPage,
  PortsPage,
  AgentsPage,
  AgentViewPage,
  AgentEditPage,
  ProfitReportPage,
  CommercialPage,
} from "src/pages/private";

import useRoutesAccessStore from "src/store/routesAccess";
import { CountryPage } from "src/pages/private/CountryPage";
import { CurrencyPage } from "src/pages/private/CurrencyPage";
import { CargoGroupPage } from "src/pages/private/CargoGroup";
import { CargoTypePage } from "src/pages/private/CargoType";
import { ChartererPage } from "src/pages/private/ChartererPage";
import { ReceiverPage } from "src/pages/private/ReceiverPage";
import { PayerPage } from "src/pages/private/PayerPage";

interface IRouteObject extends NonIndexRouteObject {
  access: boolean | undefined;
}
export const usePrivateRoutes = () => {
  const { routes } = useRoutesAccessStore();
  const privateRoutes: RouteObject[] = [
    {
      path: ROUTES.home,
      element: <AdminPanelLayout />,
    },

    // { path: "*", element: <Navigate to={ROUTES.home} /> },
  ];

  const childrenRoutes: RouteObject[] = [
    {
      path: ROUTES.home,
      element: <HomePage />,
    },
    {
      path: ROUTES.login,
      element: <Navigate to={ROUTES.home} />,
    },
    {
      path: ROUTES.Documentation,
      element: <DocumentationPage />,
    },

    {
      path: ROUTES.Agent,
      element: <AgentsPage />,
    },
    {
      path: ROUTES.AgentView,
      element: <AgentViewPage />,
    },
    {
      path: ROUTES.AgentEdit,
      element: <AgentEditPage />,
    },
  ];

  const childRouteWithAccess: IRouteObject[] = [
    {
      path: ROUTES.Category,
      element: <CategoryPage />,
      access: routes?.Category,
    },
    {
      path: ROUTES.table,
      element: <TablePage />,
      access: routes?.table,
    },
    {
      path: ROUTES.Report,
      element: <ReportPage />,
      access: routes?.Report,
    },
    {
      path: ROUTES.ShipDailyReport,
      element: <ShipDailyReportPage />,
      access: routes?.ShipDailyReport,
    },
    {
      path: ROUTES.AddShips,
      element: <AddShipsPage />,
      access: routes?.AddShips,
    },
    {
      path: ROUTES.ShipManagement,
      element: <ShipManagementPage />,
      access: routes?.ShipManagement,
    },
    {
      path: ROUTES.Supplier,
      element: <AddNewSupplierPage />,
      access: routes?.Supplier,
    },
    {
      path: ROUTES.SupplierView,
      element: <SupplierViewPage />,
      access: routes?.SupplierView,
    },
    {
      path: ROUTES.SupplierEdit,
      element: <SupplierEditPage />,
      access: routes?.SupplierEdit,
    },
    {
      path: ROUTES.Requester,
      element: <RequesterPage />,
      access: routes?.Requester,
    },
    {
      path: ROUTES.Flow,
      element: <FlowPage />,
      access: routes?.Flow,
    },
    {
      path: ROUTES.CreateRole,
      element: <CreateRolePage />,
      access: routes?.CreateRole,
    },
    {
      path: ROUTES.AssignActionToRole,
      element: <AssignActionToRolePage />,
      access: routes?.AssignActionToRole,
    },
    {
      path: ROUTES.CreateUser,
      element: <CreateUserPage />,
      access: routes?.CreateUser,
    },
    {
      path: ROUTES.AssignRoleToUser,
      element: <AssignRoleToUserPage />,
      access: routes?.AssignRoleToUser,
    },
    {
      path: ROUTES.FrontAccess,
      element: <FrontAccessPage />,
      access: routes?.FrontAccess,
    },
    {
      path: ROUTES.Port,
      element: <PortsPage />,
      access: routes?.Port,
    },
    {
      path: ROUTES.NoonReport,
      element: <NoonReportPage />,
      access: routes?.NoonReport,
    },
    {
      path: ROUTES.ProfitReport,
      element: <ProfitReportPage />,
      access: routes?.ProfitReport,
    },
    {
      path: ROUTES.Commercial,
      element: <CommercialPage />,
      access: routes?.Commercial,
    },
    {
      path: ROUTES.Country,
      element: <CountryPage />,
      access: routes?.Country,
    },
    {
      path: ROUTES.Currency,
      element: <CurrencyPage />,
      access: routes?.Currency,
    },
    {
      path: ROUTES.CargoGroup,
      element: <CargoGroupPage />,
      access: routes?.CargoGroup,
    },
    {
      path: ROUTES.CargoType,
      element: <CargoTypePage />,
      access: routes?.CargoType,
    },
    {
      path: ROUTES.Charterer,
      element: <ChartererPage />,
      access: routes?.Charterer,
    },
    {
      path: ROUTES.Receiver,
      element: <ReceiverPage />,
      access: routes?.Receiver,
    },
    {
      path: ROUTES.Payer,
      element: <PayerPage />,
      access: routes?.Payer,
    },
  ];

  childRouteWithAccess.forEach((route) => {
    if (route.access) {
      childrenRoutes.push(route);
    }
  });
  privateRoutes[0].children = childrenRoutes;
  return privateRoutes;
};
