import { FC } from "react";
import { TableUI } from "src/components/UiKit/table";
import { IInvoice } from "../../models";
import { IInvoices } from "./model";

export const Invoices: FC<IInvoices> = ({ columns, invoices }) => {
  const getRowKey = (record: IInvoice) => record.id;
  return (
    <div className="overflow-x-auto">
      <TableUI
        tableType="secondary"
        columns={columns}
        rowKey={getRowKey}
        dataSource={invoices}
        pagination={false}
        scroll={{ x: "auto" }}
        className="invoices-table"
      />
    </div>
  );
};
