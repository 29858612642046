import { checkActionPermission } from "src/helpers/checkPermissionFunc";
import {
  IAllProfit,
  IAllProfitParams,
  IEditProfit,
  IGetProfitFiles,
  IGetProfitShippingFiles,
  IGetProfitTradeFiles,
  INewProfit,
  INewProfitShipping,
  INewProfitTrade,
} from "./models";
import * as GuardPaths from "./guardPath";
import { permissionLog } from "src/helpers/permissionLog";
import axios from "axios";
import { ProfitUrls } from "./urls";
import { IDataModel } from "../models";

export class ProfitService {
  async NewProfit(data: INewProfit) {
    if (!checkActionPermission(GuardPaths.NewProfit)) {
      permissionLog(GuardPaths.NewProfit.path);
      return undefined;
    }
    try {
      const response = await axios.post<number>(ProfitUrls.newProfit, data);
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async NewProfitTrade(id: number, data: INewProfitTrade) {
    if (!checkActionPermission(GuardPaths.NewProfitTrade)) {
      permissionLog(GuardPaths.NewProfitTrade.path);
      return undefined;
    }
    try {
      const endpoint = ProfitUrls.profit
        .concat(`${id}`)
        .concat(ProfitUrls.newProfitTrade);
      const response = await axios.post<void>(endpoint, data);
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async NewProfitShipping(id: number, data: INewProfitShipping) {
    if (!checkActionPermission(GuardPaths.NewProfitShipping)) {
      permissionLog(GuardPaths.NewProfitShipping.path);
      return undefined;
    }
    try {
      const endpoint = ProfitUrls.profit
        .concat(`${id}`)
        .concat(ProfitUrls.newProfitShipping);
      const response = await axios.post<void>(endpoint, data);
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async AllProfit(params?: IAllProfitParams) {
    if (!checkActionPermission(GuardPaths.AllProfit)) {
      permissionLog(GuardPaths.AllProfit.path);
      return undefined;
    }
    let endpoint: string = ProfitUrls.allProfit;
    if (params) {
      const searchParams = new URLSearchParams(params);
      endpoint = endpoint.concat(`?${searchParams}`);
    }
    try {
      const response = await axios.get<IDataModel<IAllProfit[]>>(endpoint);
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async EditProfit(id: number, data: IEditProfit) {
    if (!checkActionPermission(GuardPaths.EditProfit)) {
      permissionLog(GuardPaths.EditProfit.path);
      return undefined;
    }
    try {
      const endpoint = ProfitUrls.profit
        .concat(`${id}`)
        .concat(ProfitUrls.editProfit);
      const response = await axios.put<void>(endpoint, data);
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async GetProfit(id: number) {
    if (!checkActionPermission(GuardPaths.GetProfit)) {
      permissionLog(GuardPaths.GetProfit.path);
      return undefined;
    }
    try {
      const endpoint = ProfitUrls.profit
        .concat(`${id}`)
        .concat(ProfitUrls.GetProfitInfo);
      const response = await axios.get<IAllProfit>(endpoint);
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async DeleteProfit(id: number) {
    if (!checkActionPermission(GuardPaths.DeleteProfit)) {
      permissionLog(GuardPaths.DeleteProfit.path);
      return undefined;
    }
    try {
      const endpoint = ProfitUrls.profit
        .concat(`${id}`)
        .concat(ProfitUrls.DeleteProfit);
      const response = await axios.delete<void>(endpoint);
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async GetProfitFile(id: number) {
    if (!checkActionPermission(GuardPaths.GetProfitFile)) {
      permissionLog(GuardPaths.GetProfitFile.path);
      return undefined;
    }
    try {
      const endpoint = ProfitUrls.profit
        .concat(`${id}`)
        .concat(ProfitUrls.GetProfitFile);
      const response = await axios.get<IGetProfitFiles[]>(endpoint);
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async GetProfitTradeFile(id: number) {
    if (!checkActionPermission(GuardPaths.GetProfitTradeFile)) {
      permissionLog(GuardPaths.GetProfitTradeFile.path);
      return undefined;
    }
    try {
      const endpoint = ProfitUrls.profit
        .concat(`${id}`)
        .concat(ProfitUrls.GetProfitTradeFile);
      const response = await axios.get<IGetProfitTradeFiles[]>(endpoint);
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async GetProfitShippingFile(id: number) {
    if (!checkActionPermission(GuardPaths.GetProfitShippingFile)) {
      permissionLog(GuardPaths.GetProfitShippingFile.path);
      return undefined;
    }
    try {
      const endpoint = ProfitUrls.profit
        .concat(`${id}`)
        .concat(ProfitUrls.GetProfitShippingFile);
      const response = await axios.get<IGetProfitShippingFiles[]>(endpoint);
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
}
