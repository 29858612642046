import { App, Button, Col, Form, Popconfirm, Row } from "antd";
import { ColumnsType } from "antd/es/table";
import { FC, useState } from "react";
import { FormItem } from "src/components/UiKit/FormItem";
import { SInput } from "src/components/UiKit/Input";
import { TableUI } from "src/components/UiKit/table";
import { getRowKey } from "src/helpers/getRowKey";
import { UseAgents } from "src/modules/agents/context";
import { IAddNewAgentTabs, IContacts } from "src/modules/agents/models";
import { BaseInfoService } from "src/services/BaseInfo/BaseInfo.service";
import {
  IAllAgentContact,
  INewAgentContact,
  NewAgentContactEnum,
  NewAgentContactType,
} from "src/services/BaseInfo/models";

export const ContactInfo: FC = () => {
  const {
    value: { newAgentId },
    dispatch: { setActiveKeyAddAgent },
    form: { EmailForm, PhoneForm },
    func: { getAgentTableData },
  } = UseAgents();

  const [phoneLoading, setPhoneLoading] = useState<boolean>(false);
  const [emailLoading, setEmailLoading] = useState<boolean>(false);
  const [phoneData, setPhoneData] = useState<IAllAgentContact[]>([]);
  const [emailData, setEmailData] = useState<IAllAgentContact[]>([]);
  const [tableLoading, setTableLoading] = useState<IContacts>({
    email: false,
    phone: false,
  });
  const columnsPhone: ColumnsType<IAllAgentContact> = [
    {
      title: "#",
      key: "id",
      align: "center",
      width: "56px",
      render: (name, record, index) => index + 1,
    },
    {
      title: "Phone",
      dataIndex: "value",
      key: "value",
      align: "center",
      width: "270px",
    },
    {
      title: "Tools",
      key: "tools",
      align: "center",
      width: "187px",
      render: (text, record) => (
        <div className="flex justify-center items-center gap-[16px] text-[16px]">
          {/* <span className="material-icons !text-[#0C9D61] text-[16px] cursor-pointer">
            edit_note
          </span> */}
          <Popconfirm
            title="Delete phone number"
            onConfirm={() => {}}
            description="Do you want to delete this?"
          >
            <span className="material-icons !text-[#EC2D30] text-[16px] cursor-pointer">
              delete_forever
            </span>
          </Popconfirm>
        </div>
      ),
    },
  ];

  const columnsEmail: ColumnsType<IAllAgentContact> = [
    {
      title: "#",
      key: "id",
      align: "center",
      width: "56px",
      render: (name, record, index) => index + 1,
    },
    {
      title: "Email",
      dataIndex: "value",
      key: "value",
      align: "center",
      width: "270px",
    },
    {
      title: "Tools",
      key: "tools",
      align: "center",
      width: "187px",
      render: (text, record) => (
        <div className="flex justify-center items-center gap-[16px] text-[16px]">
          {/* <span className="material-icons !text-[#0C9D61] text-[16px] cursor-pointer">
            edit_note
          </span> */}
          <Popconfirm
            title="Delete phone number"
            onConfirm={() => {}}
            description="Do you want to delete this?"
          >
            <span className="material-icons !text-[#EC2D30] text-[16px] cursor-pointer">
              delete_forever
            </span>
          </Popconfirm>
        </div>
      ),
    },
  ];

  const onBack = () => {
    setActiveKeyAddAgent(IAddNewAgentTabs.basicInformation);
  };

  const { message } = App.useApp();

  const PhoneOnFinish = async (value: { phoneNumber: string }) => {
    if (!newAgentId) return;
    try {
      setPhoneLoading(true);
      const { NewAgentContact } = new BaseInfoService();
      const reqBody: INewAgentContact = {
        type: NewAgentContactEnum.Phone,
        value: value.phoneNumber,
      };
      const response = await NewAgentContact(newAgentId, reqBody);
      if (response && response.status === 200) {
        message.success("phone number successfully added");
        PhoneForm?.resetFields();
        getEmailOrPhoneNumber(NewAgentContactEnum.Phone);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setPhoneLoading(false);
    }
  };

  const EmailOnFinish = async (value: { email: string }) => {
    if (!newAgentId) return;
    try {
      setEmailLoading(true);
      const { NewAgentContact } = new BaseInfoService();
      const reqBody: INewAgentContact = {
        type: NewAgentContactEnum.Email,
        value: value.email,
      };
      const response = await NewAgentContact(newAgentId, reqBody);
      if (response && response.status === 200) {
        message.success("email successfully added");
        EmailForm?.resetFields();
        getEmailOrPhoneNumber(NewAgentContactEnum.Email);
      }
    } catch (err) {
    } finally {
      setEmailLoading(false);
    }
  };

  const onFinish = () => {
    setActiveKeyAddAgent(IAddNewAgentTabs.basicInformation);
    getAgentTableData();
  };

  const getEmailOrPhoneNumber = async (type: NewAgentContactType) => {
    if (!newAgentId) return;
    try {
      setTableLoading((prev) => ({
        ...prev,
        [type === NewAgentContactEnum.Phone ? "phone" : "email"]: true,
      }));
      const { AllAgentContact } = new BaseInfoService();
      const result = await AllAgentContact(newAgentId, type);
      if (result && result.data) {
        if (type === NewAgentContactEnum.Phone) {
          setPhoneData(result.data);
        }
        if (type === NewAgentContactEnum.Email) {
          setEmailData(result.data);
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      setTableLoading((prev) => ({
        ...prev,
        [type === NewAgentContactEnum.Phone ? "phone" : "email"]: false,
      }));
    }
  };
  return (
    <>
      <div className="text-[16px] font-medium text-[#001377] py-[16px] mb-[24px]">
        Please insert agent contact information.
      </div>
      <Row gutter={24}>
        <Col span={12}>
          <Row gutter={24} className="p-[24px]">
            <Col
              span={24}
              className="py-[16px] text-[16px] font-medium text-[#001377] mb-[24px]"
            >
              Please insert phone information.
            </Col>
            <Form form={PhoneForm} onFinish={PhoneOnFinish}>
              <Col span={24}>
                <div className="middle">
                  <FormItem
                    name="phoneNumber"
                    label="Phone number"
                    rules={[{ required: true }]}
                    required={true}
                    className="w-full"
                  >
                    <SInput placeholder="insert" />
                  </FormItem>
                  <Button
                    htmlType="submit"
                    className="w-[100px] mb-[23px] br"
                    type="link"
                    loading={phoneLoading}
                  >
                    Add
                  </Button>
                </div>
              </Col>
            </Form>
            <Col span={24}>
              <TableUI
                columns={columnsPhone}
                dataSource={phoneData}
                // scroll={{ x: 1028 }}
                pagination={{
                  pageSize: 5,
                  position: ["bottomCenter"],
                }}
                rowKey={getRowKey}
                loading={tableLoading.phone}
              />
            </Col>
          </Row>
        </Col>
        <Col span={12}>
          <Row gutter={24} className="p-[24px]">
            <Col
              span={24}
              className="py-[16px] text-[16px] font-medium text-[#001377] mb-[24px]"
            >
              Please insert email information.
            </Col>
            <Form form={EmailForm} onFinish={EmailOnFinish}>
              <Col span={24}>
                <div className="middle">
                  <FormItem
                    name="email"
                    label="Email"
                    rules={[{ required: true }]}
                    required={true}
                    className="w-full"
                  >
                    <SInput placeholder="insert" />
                  </FormItem>
                  <Button
                    htmlType="submit"
                    className="w-[100px] mb-[23px] br"
                    type="link"
                    loading={emailLoading}
                  >
                    Add
                  </Button>
                </div>
              </Col>
            </Form>
            <Col span={24}>
              <TableUI
                columns={columnsEmail}
                dataSource={emailData}
                // scroll={{ x: 1028 }}
                pagination={{
                  pageSize: 5,
                  position: ["bottomCenter"],
                }}
                rowKey={getRowKey}
                loading={tableLoading.email}
              />
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <div className="flex justify-end items-end gap-[16px] footer mt-[24px]">
            <Button onClick={onBack} className="w-[100px]" type="default">
              back
            </Button>
            <Button onClick={onFinish} className="w-[100px]" type="primary">
              Finish
            </Button>
          </div>
        </Col>
      </Row>
    </>
  );
};
