import {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { IAddNewAgentTabs, IContacts } from "../models";
import { Form } from "antd";
import { FormInstance } from "antd/lib";
import { ITablePagination } from "src/models/interfaces/pagination";
import { SystemService } from "src/services/System/System.service";
import { IGetCountry } from "src/services/System/models";
import {
  IAllAgentContact,
  IAllAgents,
  IPort,
  NewAgentContactEnum,
  NewAgentContactType,
} from "src/services/BaseInfo/models";
import { BaseInfoService } from "src/services/BaseInfo/BaseInfo.service";

interface IAgentsContext {
  value: {
    activeKeyAddAgent: string;
    submitBasicInfo: boolean;
    submitBasicInfoEdit: boolean;
    loading: boolean;
    isOpenModalEmail: boolean;
    isOpenModalPhone: boolean;
    isOpenModalService: boolean;
    pagination: ITablePagination;
    countryList: IGetCountry[];
    portList: IPort[];
    newAgentId: number | undefined;
    dataSource: IAllAgents[];
    tableLoading: boolean;
    currentAgentId: IAllAgents | undefined;
    phoneData: IAllAgentContact[];
    email: IAllAgentContact[];
    loadingEmailOrPhone: IContacts;
    description: IAllAgents | undefined;
  };
  dispatch: {
    setActiveKeyAddAgent: Dispatch<SetStateAction<string>>;
    setSubmitBasicInfo: Dispatch<SetStateAction<boolean>>;
    setSubmitBasicInfoEdit: Dispatch<SetStateAction<boolean>>;
    setLoading: Dispatch<SetStateAction<boolean>>;
    setIsOpenModalEmail: Dispatch<SetStateAction<boolean>>;
    setIsOpenModalPhone: Dispatch<SetStateAction<boolean>>;
    setIsOpenModalService: Dispatch<SetStateAction<boolean>>;
    setPagination: Dispatch<SetStateAction<ITablePagination>>;
    setCountryList: Dispatch<SetStateAction<IGetCountry[]>>;
    setProtList: Dispatch<SetStateAction<IPort[]>>;
    setNewAgentId: Dispatch<SetStateAction<number | undefined>>;
    setDataSource: Dispatch<SetStateAction<IAllAgents[]>>;
    setTableLoading: Dispatch<SetStateAction<boolean>>;
    setCurrentAgentId: Dispatch<SetStateAction<IAllAgents | undefined>>;
    setPhoneDate: Dispatch<SetStateAction<IAllAgentContact[]>>;
    setEmail: Dispatch<SetStateAction<IAllAgentContact[]>>;
    setLoadingEmailOrPhone: Dispatch<SetStateAction<IContacts>>;
    setDescription: Dispatch<SetStateAction<IAllAgents | undefined>>;
  };
  form: {
    BasicInfoForm: FormInstance<any> | undefined;
    BasicInfoEditForm: FormInstance<any> | undefined;
    PhoneForm: FormInstance<any> | undefined;
    EmailForm: FormInstance<any> | undefined;
  };
  func: {
    openModalEmail: () => void;
    closeModalEmail: () => void;
    openModalPhone: () => void;
    closeModalPhone: () => void;
    openModalService: () => void;
    closeModalService: () => void;
    getCountry: () => void;
    getPorts: () => void;
    getAgentTableData: () => void;
    getAgentPhoneOrEmailData: (type: NewAgentContactType) => void;
  };
}

const defaultCtxValue: IAgentsContext = {
  value: {
    activeKeyAddAgent: "",
    submitBasicInfo: false,
    submitBasicInfoEdit: false,
    loading: false,
    isOpenModalEmail: false,
    isOpenModalPhone: false,
    isOpenModalService: false,
    countryList: [],
    pagination: { current: 1, pageSize: 10, total: 10 },
    portList: [],
    newAgentId: undefined,
    dataSource: [],
    tableLoading: false,
    currentAgentId: undefined,
    phoneData: [],
    email: [],
    loadingEmailOrPhone: { email: false, phone: false },
    description: undefined,
  },
  dispatch: {
    setActiveKeyAddAgent: () => {},
    setSubmitBasicInfo: () => {},
    setSubmitBasicInfoEdit: () => {},
    setLoading: () => {},
    setIsOpenModalEmail: () => {},
    setIsOpenModalPhone: () => {},
    setIsOpenModalService: () => {},
    setPagination: () => {},
    setCountryList: () => {},
    setProtList: () => {},
    setNewAgentId: () => {},
    setDataSource: () => {},
    setTableLoading: () => {},
    setCurrentAgentId: () => {},
    setPhoneDate: () => {},
    setEmail: () => {},
    setLoadingEmailOrPhone: () => {},
    setDescription: () => {},
  },
  form: {
    BasicInfoForm: undefined,
    BasicInfoEditForm: undefined,
    PhoneForm: undefined,
    EmailForm: undefined,
  },
  func: {
    openModalEmail: () => {},
    closeModalEmail: () => {},
    openModalPhone: () => {},
    closeModalPhone: () => {},
    openModalService: () => {},
    closeModalService: () => {},
    getCountry: () => {},
    getPorts: () => {},
    getAgentTableData: () => {},
    getAgentPhoneOrEmailData: () => {},
  },
};

export const AgentsContext = createContext<IAgentsContext>(defaultCtxValue);

export const AgentsProvider: FC<PropsWithChildren> = ({ children }) => {
  //------------------form------------------

  const [BasicInfoForm] = Form.useForm();
  const [BasicInfoEditForm] = Form.useForm();
  const [EmailForm] = Form.useForm();
  const [PhoneForm] = Form.useForm();

  //-----------------sate-------------------

  const [submitBasicInfo, setSubmitBasicInfo] = useState<boolean>(false);
  const [submitBasicInfoEdit, setSubmitBasicInfoEdit] =
    useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [activeKeyAddAgent, setActiveKeyAddAgent] = useState<string>(
    IAddNewAgentTabs.basicInformation
  );
  const [isOpenModalEmail, setIsOpenModalEmail] = useState<boolean>(false);
  const [isOpenModalPhone, setIsOpenModalPhone] = useState<boolean>(false);
  const [isOpenModalService, setIsOpenModalService] = useState<boolean>(false);
  const [countryList, setCountryList] = useState<IGetCountry[]>([]);
  const [portList, setProtList] = useState<IPort[]>([]);
  const [newAgentId, setNewAgentId] = useState<number>();
  const [tableLoading, setTableLoading] = useState<boolean>(false);
  const [dataSource, setDataSource] = useState<IAllAgents[]>([]);
  const [currentAgentId, setCurrentAgentId] = useState<IAllAgents>();
  const [phoneData, setPhoneDate] = useState<IAllAgentContact[]>([]);
  const [email, setEmail] = useState<IAllAgentContact[]>([]);
  const [description, setDescription] = useState<IAllAgents>();
  const [loadingEmailOrPhone, setLoadingEmailOrPhone] = useState<IContacts>({
    email: false,
    phone: false,
  });
  const [pagination, setPagination] = useState<ITablePagination>({
    current: 1,
    pageSize: 10,
    total: 10,
  });

  //----------------fun----------------

  const openModalEmail = () => {
    setIsOpenModalEmail(true);
  };
  const openModalPhone = () => {
    setIsOpenModalPhone(true);
  };
  const openModalService = () => {
    setIsOpenModalService(true);
  };

  const closeModalEmail = () => {
    setIsOpenModalEmail(false);
    setCurrentAgentId(undefined);
    setEmail([]);
  };
  const closeModalPhone = () => {
    setIsOpenModalPhone(false);
    setCurrentAgentId(undefined);
    setPhoneDate([]);
  };
  const closeModalService = () => {
    setIsOpenModalService(false);
    setDescription(undefined);
  };
  const getCountry = async () => {
    try {
      const { GetCountry } = new SystemService();
      const response = await GetCountry();
      if (response && response.status === 200) {
        setCountryList(response.data);
      }
    } catch (err) {
      console.log(err);
    } finally {
    }
  };
  const getPorts = async () => {
    try {
      const { GetPort } = new BaseInfoService();
      const response = await GetPort();
      if (response && response.status === 200) {
        setProtList(response.data.records);
      }
    } catch (err) {}
  };

  const getAgentTableData = useCallback(async () => {
    setTableLoading(true);
    try {
      const { AllAgents } = new BaseInfoService();
      const result = await AllAgents({
        Limit: pagination.pageSize,
        Offset: pagination.current,
      });
      if (result && result.status === 200) {
        setDataSource(result.data.records);
        setPagination((prev) => ({ ...prev, total: result.data.count }));
      }
    } catch (err) {
      console.log(err);
    } finally {
      setTableLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination.current, pagination.pageSize]);

  const getAgentPhoneOrEmailData = useCallback(
    async (type: NewAgentContactType) => {
      if (!currentAgentId) return;
      try {
        setLoadingEmailOrPhone((prev) => ({
          ...prev,
          [type === NewAgentContactEnum.Phone ? "phone" : "email"]: true,
        }));
        const { AllAgentContact } = new BaseInfoService();
        const result = await AllAgentContact(currentAgentId.id, type);
        if (result && result.data) {
          if (type === NewAgentContactEnum.Phone) {
            setPhoneDate(result.data);
          } else {
            setEmail(result.data);
          }
        }
      } catch (err) {
        throw console.log(err);
      } finally {
        setLoadingEmailOrPhone((prev) => ({
          ...prev,
          [type === NewAgentContactEnum.Phone ? "phone" : "email"]: false,
        }));
      }
    },
    [currentAgentId]
  );

  //-----------useEffect-------------------------

  useEffect(() => {
    getCountry();
  }, []);
  useEffect(() => {
    getPorts();
  }, []);
  const contextValue: IAgentsContext = {
    value: {
      activeKeyAddAgent,
      submitBasicInfo,
      submitBasicInfoEdit,
      loading,
      isOpenModalEmail,
      isOpenModalPhone,
      isOpenModalService,
      pagination,
      countryList,
      portList,
      newAgentId,
      dataSource,
      tableLoading,
      currentAgentId,
      phoneData,
      email,
      loadingEmailOrPhone,
      description,
    },
    dispatch: {
      setActiveKeyAddAgent,
      setSubmitBasicInfo,
      setSubmitBasicInfoEdit,
      setLoading,
      setIsOpenModalEmail,
      setIsOpenModalPhone,
      setIsOpenModalService,
      setPagination,
      setCountryList,
      setProtList,
      setNewAgentId,
      setDataSource,
      setTableLoading,
      setCurrentAgentId,
      setPhoneDate,
      setEmail,
      setLoadingEmailOrPhone,
      setDescription,
    },
    form: { BasicInfoForm, BasicInfoEditForm, EmailForm, PhoneForm },
    func: {
      openModalEmail,
      closeModalEmail,
      openModalPhone,
      closeModalPhone,
      openModalService,
      closeModalService,
      getCountry,
      getPorts,
      getAgentTableData,
      getAgentPhoneOrEmailData,
    },
  };

  return (
    <AgentsContext.Provider value={contextValue}>
      {children}
    </AgentsContext.Provider>
  );
};

export const UseAgents = () => useContext(AgentsContext);
