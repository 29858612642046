import { FC } from "react";
interface IProps {
  name: string;
  value: string | undefined;
  disable?: boolean;
}
export const ShipManagementInformationItems: FC<IProps> = ({
  name,
  value,
  disable,
}) => {
  return (
    <div className="flex py-[16px] justify-between h-[50px]">
      <div className="text-[#6B7280] text-[14px]">{name}</div>
      <div
        className={
          disable ? "text-[#A5C3FE] text-[14px]" : "text-[#001377] text-[14px]"
        }
      >
        {value}
        {disable}
      </div>
    </div>
  );
};
