import { FC, useEffect, useState } from "react";
import { useEditZipFileContext } from "../../context";
import { LoadingOutlined } from "@ant-design/icons";
import { UploadProps } from "antd/lib";
import { UploadChangeParam } from "antd/lib/upload";
import Upload, { RcFile, UploadFile } from "antd/es/upload";
import { getBase64 } from "src/helpers/getBase64";
import { Col, Typography, message } from "antd";
import { UploadEditedScreenshotContainer } from "../../styles/uploadScreenshot.style";
import { UploadedFileHolder } from "../../styles/zipUploaded.style";
import { USER_JWT_TOKEN } from "src/constants";
import { Guard } from "src/components/Guard";
import { AttachScreenShotToAllRequestPath } from "src/services/Invoice/guardPath";

export const UploadEditedScreenshot: FC = () => {
  const [loading, setLoading] = useState(false);

  const {
    value: {
      screenshots,
      screenShotFileList,
      currentAllZipFileId,
      imageUrl,
      screenShotLoading,
      fileCheckerLoading,
    },
    dispatch: { setScreenshots, setScreenShotFileList, setImageUrl },
    func: { screenShotModalHandler },
  } = useEditZipFileContext();

  const uploadButton = (
    <div>
      {loading ? (
        <LoadingOutlined color="#A3AED0" />
      ) : (
        <span className="material-icons text-[#A3AED0] text-[20px] ">add</span>
      )}
      <div className="text-[#A3AED0] text-[10px]">Upload</div>
    </div>
  );
  const handleChange: UploadProps["onChange"] = (
    info: UploadChangeParam<UploadFile>
  ) => {
    setScreenShotFileList([info.file]);
    getBase64(info.file.originFileObj as RcFile, (url) => {
      setImageUrl(url);
    });
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "error") {
      setLoading(false);
      message.error("upload screen shot failed");
    }
    if (info.file.status === "done") {
      setScreenshots(info.file.originFileObj);
      setLoading(false);
    }
  };

  const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload jpg/png file!");
      return;
    }

    return isJpgOrPng;
  };
  useEffect(() => {
    if (!screenshots) setImageUrl("");
  }, [screenshots, setImageUrl]);

  const props: UploadProps = {
    showUploadList: {
      showPreviewIcon: false,
      showRemoveIcon: false,
    },
  };
  return (
    <UploadEditedScreenshotContainer>
      <Col span={24}>
        <div className="attachFile">
          <Typography.Text className=" attachFileLabel">
            Upload Screenshot of the date.
          </Typography.Text>
          <Guard action={AttachScreenShotToAllRequestPath}>
            <div className="flex items-center gap-[32px] overflow-y-auto">
              <Upload
                {...props}
                listType="picture-card"
                className=" flex justify-center "
                showUploadList={true}
                beforeUpload={beforeUpload}
                onChange={handleChange}
                name="screenShot"
                action={
                  process.env.REACT_APP_API_URL?.concat(
                    `Invoice/${currentAllZipFileId}/V3/AttachScreenShotToAllRequest`
                  ) || ""
                }
                method="PATCH"
                headers={{
                  Authorization:
                    `Bearer ` + localStorage.getItem(USER_JWT_TOKEN)!,
                }}
                maxCount={screenShotFileList.length === 1 ? 0 : 1}
                fileList={screenShotFileList}
                disabled={screenShotFileList.length === 1}
                itemRender={(el, file, fileList) => {
                  return imageUrl ? (
                    <UploadedFileHolder>
                      <img
                        src={imageUrl}
                        alt="img"
                        onClick={screenShotModalHandler}
                        width={58}
                        height={58}
                        className="rounded-[8px]"
                      />
                    </UploadedFileHolder>
                  ) : screenShotLoading || fileCheckerLoading ? (
                    <UploadedFileHolder>
                      <span className="w-[58px] h-[58px] flex justify-center items-center">
                        <LoadingOutlined color="#A3AED0" />
                      </span>
                    </UploadedFileHolder>
                  ) : (
                    <div></div>
                  );
                }}
              >
                {uploadButton}
              </Upload>
            </div>
          </Guard>
        </div>
      </Col>
    </UploadEditedScreenshotContainer>
  );
};
