import { FC } from "react";
import { useCreateUserContext } from "./context";
import { Button, Col, Form, Input, Row, TreeSelect } from "antd";
import { CreateUserContainer } from "./styles";
import { FormItem } from "src/components/UiKit/FormItem";
import { SInput } from "src/components/UiKit/Input";
import { ConfirmModal } from "src/components/UiKit/ConfirmModal";

export const CreateUser: FC = () => {
  const {
    onFinish,
    onCancelConfirm,
    onConfirm,
    showConfirm,
    treeProps,
    confirmLoading,
    createUserForm,
  } = useCreateUserContext();

  return (
    <>
      <Form onFinish={onFinish} form={createUserForm}>
        <CreateUserContainer justify={"center"} gutter={[0, 24]}>
          <Col span={24} className="header">
            Create user
          </Col>
          <Col span={15} className="content">
            <Row gutter={[24, 0]}>
              <Col span={24} className="mb-[40px]">
                <div className="title">Please insert the user information.</div>
              </Col>
              <Col span={12}>
                <FormItem
                  name={"userName"}
                  label={"User name"}
                  required
                  rules={[{ required: true }]}
                >
                  <SInput placeholder="insert" />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label={"First name"} name={"firstName"}>
                  <SInput placeholder="insert" />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label={"Last name"} name={"lastName"}>
                  <SInput placeholder="insert" />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem
                  label={"Password"}
                  name={"password"}
                  required
                  rules={[{ required: true }]}
                >
                  <Input.Password
                    placeholder="insert"
                    className="input-password"
                  />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem
                  label={"Roles"}
                  name={"roles"}
                  required
                  rules={[{ required: true }]}
                >
                  <TreeSelect
                    {...treeProps}
                    allowClear
                    className="selectOption"
                    treeCheckable
                    showSearch
                    treeNodeFilterProp="label"
                  />
                </FormItem>
              </Col>
              <Col span={24} className="flex justify-end gap-[24px] footer">
                <Button type="primary" htmlType="submit">
                  Create
                </Button>
              </Col>
            </Row>
          </Col>
        </CreateUserContainer>
      </Form>
      <ConfirmModal
        open={showConfirm}
        onCancel={onCancelConfirm}
        onOk={onConfirm}
        text="Are you sure you want to Create this user?"
        title={"Create user"}
        confirmIcon="add_circle"
        okText="Create"
        confirmLoading={confirmLoading}
      />
    </>
  );
};
