import styled from "styled-components";

export const FoldersFilesContainer = styled.section`
  margin-top: 24px;
  border: solid 1px #dadeec;
  border-radius: 12px;
  padding: 24px;
  gap: 16px;
  min-height: 478px;
  @keyframes selectedBox {
    0% {
      width: 100%;
      height: 0;
      opacity: 0;
    }
    /* 25% {
      width: 25%;
      height: 25%;

    } */
    /* 50% {
      width: 50%;
      height: 50%;

    } */
    /* 75% {
      width: 75%;
      height: 75%;
    } */
    100% {
      width: 100%;
      height: 100%;
      opacity: 1;
    }
  }
  .selected-actions-wrapper {
    width: 0;
    height: 0;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    background-color: #2c73fca6;
    border-radius: 8px;
    border-color: #2c73fca6;
    display: none;
  }
  .selected-actions-wrapper.show {
    width: 100%;
    height: 100%;
    display: flex;
    animation-name: selectedBox;
    animation-duration: 700ms;
  }
  .selected-actions-wrapper.hide {
    display: none;
  }

  .selected-actions {
    display: inline-flex;
    width: 36px;
    height: 36px;
    border-radius: 8px;
    background-color: #fff;
    font-size: 16px;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    position: relative;
  }
`;
