import { Col, Row } from "antd";
import { FC } from "react";
import { FormItem } from "src/components/UiKit/FormItem";
import { SInput } from "src/components/UiKit/Input";
import { ProfitTradeFormItemsWrapper } from "./style";

export const ProfitTradeFormItems: FC = () => {
  return (
    <ProfitTradeFormItemsWrapper gutter={24}>
      <Col span={24}>
        <Row>
          <Col span={8}>
            <FormItem name="projectNumber" label="project No.">
              <SInput numbermode placeholder="Only number" />
            </FormItem>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <div className="expenses">EXPENSES INFO</div>
      </Col>
      <Col span={8}>
        <FormItem name="purchaseQuantity" label="Purchase quantity (MT)">
          <SInput />
        </FormItem>
      </Col>
      <Col span={8}>
        <FormItem name="purchaseUnitPrice" label="Purchase unit price (USD)">
          <SInput />
        </FormItem>
      </Col>
      <Col span={8}>
        <FormItem name="tTlPurchaseCargo" label="TTL Purchase cargo (USD)">
          <SInput numbermode placeholder="Only number" />
        </FormItem>
      </Col>
      <Col span={8}>
        <FormItem name="tradeOtherCost" label="Other costs (USD)">
          <SInput numbermode placeholder="Only number" />
        </FormItem>
      </Col>
      <Col span={24}>
        <div className="income">INCOME INFO</div>
      </Col>
      <Col span={8}>
        <FormItem name="saleQuantity" label="Sale quantity  (MT)">
          <SInput />
        </FormItem>
      </Col>
      <Col span={8}>
        <FormItem name="saleUnitPrice" label="Sale unit price (USD)">
          <SInput />
        </FormItem>
      </Col>
      <Col span={8}>
        <FormItem name="ttlSaleCargo" label="TTL Sale of cargo  (USD)">
          <SInput numbermode placeholder="Only number" />
        </FormItem>
      </Col>
      <Col span={8}>
        <FormItem name="loadingDemurage" label="Loading demurrage (USD)">
          <SInput numbermode placeholder="Only number" />
        </FormItem>
      </Col>
      <Col span={8}>
        <FormItem name="dischargeDemurage" label="Discharge demurrage (USD)">
          <SInput numbermode placeholder="Only number" />
        </FormItem>
      </Col>
    </ProfitTradeFormItemsWrapper>
  );
};
