import { FC, useEffect, useState } from "react";
import { ContentContainer } from "../style";
import { Button, Col, Flex, Form, Row } from "antd";
import { FormItem } from "src/components/UiKit/FormItem";
import { SInput } from "src/components/UiKit/Input";
import { useCurrency } from "../context";

export const AddNewCurrency: FC = () => {
  const {
    value: { addCurrencyLoading, editLoading, editMode, editData },
    form: { currencyForm },
    func: { onFinish, onBack },
  } = useCurrency();

  const [submit, setSubmit] = useState<boolean>(false);

  const formValue = Form.useWatch([], currencyForm);

  const onReset = () => {
    currencyForm?.resetFields();
  };

  useEffect(() => {
    currencyForm
      ?.validateFields({ validateOnly: true })
      .then(() => setSubmit(true))
      .catch(() => setSubmit(false));
  }, [currencyForm, formValue]);

  useEffect(() => {
    if (editMode) {
      currencyForm?.setFieldsValue(editData);
    }
  }, [editData, currencyForm, editMode]);

  return (
    <Form form={currencyForm} onFinish={onFinish}>
      <Row justify="center">
        <Col span={15}>
          <ContentContainer>
            <Col span={24} className="text-[16px] font-medium text-[#001377]">
              Please complete the requested information.
            </Col>
            <Col span={24} className="py-[16px]">
              <div className="h-[227px]">
                <Row gutter={24}>
                  <Col span={12}>
                    <FormItem
                      name="name"
                      label="Name"
                      rules={[{ required: true }]}
                      required={true}
                    >
                      <SInput placeholder="insert" allowClear />
                    </FormItem>
                  </Col>
                  <Col span={12}>
                    <FormItem
                      name="shortName"
                      label="Short name"
                      rules={[{ required: true }]}
                      required={true}
                    >
                      <SInput placeholder="insert" allowClear />
                    </FormItem>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col span={24} className="button">
              <Flex justify="end" gap={16} align="center">
                <Button onClick={editMode ? onBack : onReset}>
                  {editMode ? "cancel" : "Reset"}
                </Button>
                <Button
                  type="primary"
                  loading={editMode ? editLoading : addCurrencyLoading}
                  htmlType="submit"
                  disabled={!submit}
                >
                  {editMode ? "Save edit" : "Add"}
                </Button>
              </Flex>
            </Col>
          </ContentContainer>
        </Col>
      </Row>
    </Form>
  );
};
