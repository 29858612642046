import axios, { AxiosRequestConfig } from "axios";
import {
  IEditRequesterArg,
  IEditShipBasicInformation,
  IEditShipManagement,
  IEditShipManagmentContract,
  IGetShipInsuranceInformation,
  IGetShipManagement,
  IGetShipManagmentContract,
  INewRequesterArg,
  INewShipManagement,
  IShipBasicInformation,
  IShipInsuranceInformation,
  IShipManagmentContract,
  IShipOwnerInformation,
  IShipPriceInformation,
  IShipTechnicalInformation,
} from "./models";
import { ShipUrls } from "./urls";
import { Dayjs } from "dayjs";
import { Buffer } from "buffer";
import { checkActionPermission } from "src/helpers/checkPermissionFunc";
import * as GuardPaths from "./guardPath";
import { permissionLog } from "src/helpers/permissionLog";
export class ShipService {
  async ShipBasicInformation(data: IShipBasicInformation) {
    if (!checkActionPermission(GuardPaths.ShipBasicInformationPath)) {
      permissionLog(GuardPaths.ShipBasicInformationPath.path);
      return undefined;
    }
    try {
      const response = await axios.post<number>(
        ShipUrls.ShipBasicInformation,
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async ShipTechnicalInformation(id: number, data: IShipTechnicalInformation) {
    if (!checkActionPermission(GuardPaths.ShipTechnicalInformationPath)) {
      permissionLog(GuardPaths.ShipTechnicalInformationPath.path);
      return undefined;
    }
    try {
      const response = await axios.put<void>(
        ShipUrls.Ship.concat(`${id}${ShipUrls.ShipTechnicalInformation}`),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async ShipOwnerInformation(id: number, data: IShipOwnerInformation) {
    if (!checkActionPermission(GuardPaths.ShipOwnerInformationPath)) {
      permissionLog(GuardPaths.ShipOwnerInformationPath.path);
      return undefined;
    }
    try {
      const response = await axios.put<void>(
        ShipUrls.Ship.concat(`${id}${ShipUrls.ShipOwnerInformation}`),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async ShipPriceInformation(id: number, data: IShipPriceInformation) {
    if (!checkActionPermission(GuardPaths.ShipPriceInformationPath)) {
      permissionLog(GuardPaths.ShipPriceInformationPath.path);
      return undefined;
    }
    try {
      const response = await axios.put<void>(
        ShipUrls.Ship.concat(`${id}${ShipUrls.ShipPriceInformation}`),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async ShipInsuranceInformation(id: number, data: IShipInsuranceInformation) {
    if (!checkActionPermission(GuardPaths.PostShipInsuranceInformationPath)) {
      permissionLog(GuardPaths.PostShipInsuranceInformationPath.path);
      return undefined;
    }
    try {
      const response = await axios.post<void>(
        ShipUrls.Ship.concat(`${id}${ShipUrls.ShipInsuranceInformation}`),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async DeleteShipInsuranceInformation(insuranceId: number) {
    if (!checkActionPermission(GuardPaths.DeleteShipInsuranceInformationPath)) {
      permissionLog(GuardPaths.DeleteShipInsuranceInformationPath.path);
      return undefined;
    }
    try {
      const response = await axios.delete<void>(
        ShipUrls.Ship.concat(
          `${insuranceId}${ShipUrls.DeleteShipInsuranceInformation}`
        )
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async EditShipBasicInformation(id: number, data: IEditShipBasicInformation) {
    if (!checkActionPermission(GuardPaths.EditShipBasicInformationPath)) {
      permissionLog(GuardPaths.EditShipBasicInformationPath.path);
      return undefined;
    }
    try {
      const response = await axios.put<void>(
        ShipUrls.Ship.concat(`${id}${ShipUrls.EditShipBasicInformation}`),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async EditShipInsuranceInformation(
    id: number,
    data: IShipInsuranceInformation
  ) {
    if (!checkActionPermission(GuardPaths.EditShipInsuranceInformationPath)) {
      permissionLog(GuardPaths.EditShipInsuranceInformationPath.path);
      return undefined;
    }
    try {
      const response = await axios.put<void>(
        ShipUrls.Ship.concat(`${id}${ShipUrls.EditShipInsuranceInformation}`),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async Image(id: number) {
    if (!checkActionPermission(GuardPaths.GetShipImagePath)) {
      permissionLog(GuardPaths.GetShipImagePath.path);
      return undefined;
    }
    try {
      const config: AxiosRequestConfig<any> = {
        responseType: "arraybuffer",
        headers: {
          accept: "*/*",
        },
      };
      const response = await axios.get<Buffer>(
        ShipUrls.Ship.concat(`${id}`).concat(ShipUrls.Image),
        config
      );
      if (response) {
        let raw = Buffer.from(response.data).toString("base64");
        return "data:" + response.headers["content-type"] + ";base64," + raw;
      }
    } catch (err) {
      throw console.log(err);
    }
  }
  async GetShipInsuranceInformation(id: number, date?: Dayjs) {
    if (!checkActionPermission(GuardPaths.GetShipInsuranceInformationPath)) {
      permissionLog(GuardPaths.GetShipInsuranceInformationPath.path);
      return undefined;
    }
    try {
      const response = await axios.get<IGetShipInsuranceInformation[]>(
        ShipUrls.Ship.concat(`${id}${ShipUrls.ShipInsuranceInformation}`)
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async NewShipManagement(data: INewShipManagement) {
    if (!checkActionPermission(GuardPaths.NewShipManagementPath)) {
      permissionLog(GuardPaths.NewShipManagementPath.path);
      return undefined;
    }
    try {
      const response = await axios.post<void>(ShipUrls.NewShipManagement, data);
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async GetShipManagement() {
    if (!checkActionPermission(GuardPaths.GetShipManagementPath)) {
      permissionLog(GuardPaths.GetShipManagementPath.path);
      return undefined;
    }
    try {
      const response = await axios.get<IGetShipManagement[]>(
        ShipUrls.GetShipManagement
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async ShipManagmentContract(id: number, data: IShipManagmentContract) {
    if (!checkActionPermission(GuardPaths.PostShipManagmentContractPath)) {
      permissionLog(GuardPaths.PostShipManagmentContractPath.path);
      return undefined;
    }
    try {
      const response = await axios.post<void>(
        ShipUrls.Ship.concat(`${id}${ShipUrls.ShipManagmentContract}`),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async DeleteShip(id: number) {
    if (!checkActionPermission(GuardPaths.DeleteShipPath)) {
      permissionLog(GuardPaths.DeleteShipPath.path);
      return undefined;
    }
    try {
      const response = await axios.delete<void>(
        ShipUrls.Ship.concat(`${id}${ShipUrls.DeleteShip}`)
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async GetShipManagmentContract(id: number) {
    if (!checkActionPermission(GuardPaths.GetShipManagmentContractPath)) {
      permissionLog(GuardPaths.GetShipManagmentContractPath.path);
      return undefined;
    }
    try {
      const response = await axios.get<IGetShipManagmentContract[]>(
        ShipUrls.Ship.concat(`${id}${ShipUrls.ShipManagmentContract}`)
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async EditShipManagmentContract(
    id: number,
    data: IEditShipManagmentContract
  ) {
    if (!checkActionPermission(GuardPaths.EditShipManagmentContractPath)) {
      permissionLog(GuardPaths.EditShipManagmentContractPath.path);
      return undefined;
    }
    try {
      const response = await axios.put<void>(
        ShipUrls.Ship.concat(`${id}${ShipUrls.EditShipManagmentContract}`),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async EditShipManagement(id: number, data: IEditShipManagement) {
    if (!checkActionPermission(GuardPaths.EditShipManagementPath)) {
      permissionLog(GuardPaths.EditShipManagementPath.path);
      return undefined;
    }
    try {
      const response = await axios.put<void>(
        ShipUrls.Ship.concat(`${id}${ShipUrls.EditShipManagement}`),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async DeletetShipManagement(id: number) {
    if (!checkActionPermission(GuardPaths.DeletetShipManagementPath)) {
      permissionLog(GuardPaths.DeletetShipManagementPath.path);
      return undefined;
    }
    try {
      const response = await axios.delete<void>(
        ShipUrls.Ship.concat(`${id}${ShipUrls.DeletetShipManagement}`)
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async NewRequester(data: INewRequesterArg) {
    if (!checkActionPermission(GuardPaths.NewRequesterPath)) {
      permissionLog(GuardPaths.NewRequesterPath.path);
      return undefined;
    }
    try {
      const response = await axios.post<void>(ShipUrls.NewRequester, data);
      return response;
    } catch (err) {
      console.log(err);
    }
  }
  async DeleteRequester(id: number) {
    if (!checkActionPermission(GuardPaths.DeleteRequesterPath)) {
      permissionLog(GuardPaths.DeleteRequesterPath.path);
      return undefined;
    }
    try {
      const response = await axios.delete<void>(
        ShipUrls.Ship.concat(id.toString()).concat(ShipUrls.DeleteRequester)
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
  async EditRequester(id: number, data: IEditRequesterArg) {
    if (!checkActionPermission(GuardPaths.EditRequesterPath)) {
      permissionLog(GuardPaths.EditRequesterPath.path);
      return undefined;
    }
    try {
      const response = await axios.put<void>(
        ShipUrls.Ship.concat(id.toString()).concat(ShipUrls.EditRequester),
        data
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
}
