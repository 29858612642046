import { styled } from "styled-components";

export const ChangePasswordContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  .nameHolder {
    color: var(--text, #001377);
    text-align: center;
    font-family: DM Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .inputPassword {
    background: #f4f7fe;
    border-color: #f4f7fe;
    border-radius: 12px;
    min-height: 48px;
    input {
      background: transparent;
      width: 100%;
      display: block;
      color: #000;
    }
    svg {
      fill: #001377;
    }
  }
  .ant-form-item {
    width: 100% !important;
    display: flex;
    justify-content: center;
    /* gap: 24px; */
  }
  .footer {
    border-top: 1px solid #dadeec;
    padding-top: 16px;
    padding-inline: 24px;
    bottom: 32px;
    left: 24px;
    width: calc(100% - 48px);
    position: absolute;
  }
  .ant-form-item-row {
    width: 318px;
  }
  .css-dev-only-do-not-override-hqmoyx {
  }
`;
