import { FC } from "react";
import { AgentEditContainer } from "./style";
import { AgentEditTabs } from "./components/tabs";
import { Col, Row } from "antd";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "src/models/enums";

export const AgentEdit: FC = () => {
  const Navigate = useNavigate();
  const onBack = () => {
    Navigate(ROUTES.Agent.concat("?table=true"));
  };

  return (
    <AgentEditContainer>
      <Row>
        <Col span={24} className="mb-[32px]">
          <div
            onClick={onBack}
            className="flex justify-between items-center py-[19px] px-[24px] borderBottom"
          >
            <div className="flex flex-row gap-[12px] items-center cursor-pointer">
              <span className="material-icons text-[#2C73FC] text-[24px]">
                keyboard_backspace
              </span>
              <div className=" text-[#2C73FC] text-[18px] font-semibold ">
                Back to table
              </div>
            </div>
            <div className="flex flex-col">
              <div className="text-[#001377] text-[18px] font-semibold">
                Edit
              </div>
              <div className="text-[#5D6881] text-[14px] font-bold">agent</div>
            </div>
          </div>
        </Col>
      </Row>
      <AgentEditTabs />
    </AgentEditContainer>
  );
};
