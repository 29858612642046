import { Button, DatePicker, Flex, Popconfirm } from "antd";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { Icons } from "src/components/customIcons";
import { ShipReportStatus } from "src/models/enums";
import { IObject } from "src/models/interfaces";
import { ReportPrint } from "src/modules/Owner/components/ShipsDailyReport/components/ShipDailyReportHeader/ReportPrint";
import { DailyReportService } from "src/services/DailyReport/dailyReport.service";
import { INewAllShipDailyReportResult } from "src/services/DailyReport/models/result.model";
import { Dayjs } from "dayjs";
interface IFilterValue extends IObject {
  FromReportDate?: string;
  ToReportDate?: string;
  ShipName?: string;
  Stauts: number;
}
export const ShipDailyPdf: FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [reports, setReports] = useState<INewAllShipDailyReportResult[]>([]);
  const [selectDate, setSelectDate] = useState<Dayjs>();
  const [filterValue, setFilterValue] = useState<IFilterValue>({
    Stauts: ShipReportStatus.Accepted,
  });
  const AllShipDailyReport = useCallback(async () => {
    setLoading(true);
    const params = new URLSearchParams(filterValue);
    try {
      const { AllShipDailyReport } = new DailyReportService();
      const result = await AllShipDailyReport(params.toString());
      if (result && result.status === 200) {
        setReports(result.data.records);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }, [filterValue]);
  const onCancel = () => {
    setSelectDate(undefined);
    setFilterValue((prev) => ({
      ...prev,
      FromReportDate: undefined,
      ToReportDate: undefined,
    }));
    setReports([]);
  };
  const componentRef = useRef<HTMLDivElement>(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: onCancel,
  });
  useEffect(() => {
    if (filterValue.FromReportDate && filterValue.ToReportDate) {
      AllShipDailyReport();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AllShipDailyReport]);
  return (
    <>
      <Popconfirm
        placement="bottomRight"
        title="Select Report Date"
        rootClassName="ShipDailyPdf-popconfirm"
        description={
          <Flex
            justify="center"
            align="center"
            gap={32}
            className="w-full px-[24px] py-[8px] "
          >
            <DatePicker
              onChange={(date, dateString) => {
                setFilterValue((prev) => ({
                  ...prev,
                  FromReportDate: dateString as string,
                  ToReportDate: dateString as string,
                }));
                setSelectDate(date);
              }}
              allowClear
              className="w-full"
              value={selectDate}
            />
          </Flex>
        }
        okButtonProps={{
          loading,
          disabled:
            !reports.length ||
            !(filterValue.FromReportDate && filterValue.ToReportDate),
        }}
        onConfirm={handlePrint}
        okText="download"
        icon={""}
        onCancel={onCancel}
      >
        <Flex className="w-[50px] h-[50px] " justify="center" align="center">
          <Button
            type="text"
            // disabled={reports.length > 0 ? false : true}
            className=" p-0 w-[50px] h-[50px]"
            //   onClick={handlePrint}
          >
            <Icons name="pdfDownload" />
          </Button>
        </Flex>
      </Popconfirm>

      <div className="hidden">
        <ReportPrint ref={componentRef} reports={reports} />
      </div>
    </>
  );
};
