import styled from "styled-components";

export const SupplierViewContainer = styled.div`
  .borderBottom {
    border-bottom: 1px solid var(--shadow, #dadeec);
    margin-bottom: 24px;
  }
  .body {
    width: 1076px;
    padding: 24px;
    border-radius: 20px;
    background: var(--secondary-primary-white, #fff);

    /* 2 */
    box-shadow: 0px 4px 6px 0px rgba(113, 130, 183, 0.25);
  }
  .header {
    display: flex;
    padding-bottom: 16px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-bottom: 1px solid #f0f0f0;
  }
  .content {
    margin-top: 24px;
    padding: 24px;
    border-radius: 16px;
    border: 1px solid var(--shadow, #dadeec);
  }
  .fix {
    color: #6b7280;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .variable {
    color: var(--text, #001377);
    font-family: DM Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .activityP {
    margin-top: 8px;
    display: flex;
    height: 163px;
    padding: 16px 24px;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    border-radius: 12px;
    background: var(--5, #f2f7ff);
    p {
      margin-bottom: 0px;
      height: 108px;
      color: var(--text, #001377);
      font-family: DM Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
`;
