import styled from "styled-components";

export const AddNewSupplierContainer = styled.div`
  width: 100% !important;
  height: 730px;
  padding: 24px 32px;
  border-radius: 20px;
  background: var(--0, #fff);
  box-shadow: 0px 4px 6px 0px rgba(113, 130, 183, 0.25);
  .titleHolder {
    color: var(--text, #001377);
    font-family: DM Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .footerButton {
    display: flex;
    width: 100px;
    padding: 12px;
    justify-content: center;
    align-items: center;
  }
  .footer {
    border-top: 1px solid var(--shadow, #dadeec);
    padding-top: 24px;
    flex-direction: row-reverse;
  }
`;

export const SupplierTableContainer = styled.div`
  width: 100% !important;
  /* height: 676px; */
  /* padding: 24px 32px;
  border-radius: 20px;
  background: var(--0, #fff);
  box-shadow: 0px 4px 6px 0px rgba(113, 130, 183, 0.25); */
  .content {
    border-radius: 20px;
    background: var(--0, #fff);

    /* 2 */
    box-shadow: 0px 4px 6px 0px rgba(113, 130, 183, 0.25);
    padding: 24px 32px;
  }
  .ant-table-expanded-row {
    background: var(--shadow, #dadeec);
  }
  .ant-table-row-expand-icon-cell {
    padding: unset !important;
  }

  .ant-table-cell {
    border: unset !important;
  }
`;

export const ActionModalContainer = styled.div`
  display: flex;
  padding: 16px 16px 24px 16px;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-radius: 12px;
  background: var(--Background-1_VDR, #f4f7fe);

  .paragraph {
    color: var(--text-5, #3e4556);
    font-family: DM Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 114.286% */
    font-weight: 600;
  }
`;
export const ActionModalTitleContainer = styled.div`
  display: flex;
  padding: 8px 0px 24px 0px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-bottom: 1px solid var(--shadow, #dadeec);
  margin-bottom: 24px;
  .header {
    display: flex;
    height: 45px;
    justify-content: flex-start;
    gap: 75px;
    align-items: center;
    align-self: stretch;
  }
`;
