import {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { Form, message } from "antd";
import { FormInstance } from "antd/lib";
import {
  IEditFOMGOCONSArgs,
  IFOMGOCONS,
  IFOMGOCONSArgs,
} from "src/services/NoonReport/models";
import { NoonReportService } from "src/services/NoonReport/NoonReport.service";
import { useNoonReportCTX } from "src/modules/NoonReport/context";
import { useAddNewNoonReportCTX } from "../../context";
import { NewNoonReportTabs } from "../../model/newNoonReportTabs";
interface IContext {
  value: {
    loading: boolean;
    formName: FormInstance;
    dataSource: IFOMGOCONS[];
    dataLoading: boolean;
    selectedRow: IFOMGOCONS | undefined;
  };
  dispatch: {
    setSelectedRow: Dispatch<SetStateAction<IFOMGOCONS | undefined>>;
  };
  func: {
    onFinishForm: (values: IFOMGOCONSArgs) => void;
    reset: () => void;
  };
}

export const FOMGOCONSInfoCTX = createContext<IContext | undefined>(undefined);

export const FOMGOCONSInfoProvider: FC<PropsWithChildren> = ({ children }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [dataSource, setDataSource] = useState<IFOMGOCONS[]>([]);
  const [dataLoading, setDataLoading] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<IFOMGOCONS | undefined>(
    undefined
  );

  const {
    value: { activeTab },
  } = useAddNewNoonReportCTX();

  const {
    value: { selectedReport },
  } = useNoonReportCTX();

  const [formName] = Form.useForm();

  const onFinishForm = (values: IFOMGOCONSArgs) => {
    if (selectedRow) editData(values);
    else addData(values);
  };

  const addData = async (values: IFOMGOCONSArgs) => {
    if (!selectedReport) return;
    setLoading(true);
    try {
      const { NewFOMGOCONS } = new NoonReportService();
      const response = await NewFOMGOCONS(selectedReport.id, values);
      if (response && response.status === 200) {
        message.success("New FO, MGO Cons created successfully.");
        getFOMGOCONS();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const editData = async (values: IFOMGOCONSArgs) => {
    if (!selectedRow) return;
    const editBody: IEditFOMGOCONSArgs = {
      noonReportId: selectedRow.id,
      ...values,
    };
    setLoading(true);
    try {
      const { EditFOMGOCONS } = new NoonReportService();
      const response = await EditFOMGOCONS(selectedRow.id, editBody);
      if (response && response.status === 200) {
        message.success("FO, MGO Cons edited successfully.");
        getFOMGOCONS();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const getFOMGOCONS = useCallback(async () => {
    if (!selectedReport) return;
    try {
      const { GetFOMGOCONS } = new NoonReportService();
      const response = await GetFOMGOCONS(selectedReport.id);
      if (response && response.status === 200 && response.data) {
        setDataSource(response.data);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setDataLoading(false);
      setDataSource(dumpyData);
    }
  }, [selectedReport]);

  const reset = useCallback(() => {
    setSelectedRow(undefined);
    formName.resetFields();
  }, [formName]);

  useEffect(() => {
    if (activeTab === NewNoonReportTabs.FOMGOCONS) reset();
  }, [activeTab, reset]);

  useEffect(() => {
    if (selectedRow)
      formName.setFieldsValue({
        type: selectedRow.type,
        ME: selectedRow.ME,
        GE: selectedRow.GE,
        AE2: selectedRow.AE2,
        AE3: selectedRow.AE3,
        Boiler: selectedRow.Boiler,
      });
  }, [formName, selectedRow]);

  useEffect(() => {
    if (activeTab === NewNoonReportTabs.FOMGOCONS) {
      reset();
    }
  }, [activeTab, reset]);

  useEffect(() => {
    if (activeTab === NewNoonReportTabs.FOMGOCONS && selectedReport) {
      getFOMGOCONS();
    }
  }, [activeTab, getFOMGOCONS, selectedReport]);

  const contextValue: IContext = {
    value: { loading, formName, dataSource, dataLoading, selectedRow },
    dispatch: { setSelectedRow },
    func: {
      onFinishForm,
      reset,
    },
  };

  return (
    <FOMGOCONSInfoCTX.Provider value={contextValue}>
      {children}
    </FOMGOCONSInfoCTX.Provider>
  );
};

export const useFOMGOCONSInfo = () => useContext(FOMGOCONSInfoCTX)!;
const dumpyData: IFOMGOCONS[] = [
  {
    id: 1,
    noonReportId: 1,
    type: 1,
    ME: "200",
    GE: "300",
    AE2: "400",
    AE3: "500",
    Boiler: "100",
    Total: "800",
  },
];
