import { FC, useEffect, useRef, useState } from "react";
import {
  CategoriesTableContainer,
  CategoryContent,
  CategoryWrapper,
} from "./style";
import { Col, Flex, Spin } from "antd";
import { CategoriesChild } from "./components/CategoriesChild";
import { useCategory } from "../../context";

export const CategoriesTable: FC = () => {
  const {
    value: { category, categoryLoading, selectedCategories, levels },
    dispatch: { setSelectedCategories, setLevels },
  } = useCategory()!;
  const [selectedParentId, setSelectedParentId] = useState<number>();
  const ref = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    document
      .getElementById((levels.length - 1).toString())
      ?.scrollIntoView({ behavior: "smooth" });
  }, [levels.length]);
  if (categoryLoading) {
    return (
      <Flex className=" w-full h-full" justify="center" align="center">
        <Spin />
      </Flex>
    );
  }

  return (
    <CategoryContent>
      <CategoryWrapper gap={42}>
        <CategoriesTableContainer ref={ref}>
          <Col span={24} className="h-[87px] border flex flex-col gap-[16px]">
            <div className="titleHolder">Categories</div>
            <div className="flex gap-[12px] py-[12px] px-[8px] items-center">
              <span className="material-icons text-[#2C73FC] text-20px]">
                sailing
              </span>
              <div className="shipHolder">vessels</div>
            </div>
          </Col>

          <Col span={24}>
            <CategoriesChild
              title={"Level 1"}
              subTitle=""
              itemsCount={category.length}
              children={category.map((item) => ({
                ...item,
                title: item.name,
                subTitle: item.childeren.length,
              }))}
              onClick={(cat) => {
                setSelectedParentId(cat.id);
                setSelectedCategories([cat]);
                if (cat.childeren.length > 0) setLevels([cat]);
                else setLevels([]);
              }}
              selectedParentId={selectedParentId}
            />
          </Col>
        </CategoriesTableContainer>
        {levels.map((cat, index) => (
          <CategoriesTableContainer key={index} id={index.toString()}>
            <Col span={24}>
              <CategoriesChild
                title={"Level ".concat((+index + 2).toString())}
                subTitle=""
                itemsCount={cat.childeren.length}
                children={cat.childeren.map((item) => ({
                  ...item,
                  title: item.name,
                  subTitle: item.childeren.length,
                }))}
                selectedParentId={selectedCategories[index + 1]?.id}
                selectedChildren={selectedCategories}
                onClick={(newCat) => {
                  setSelectedCategories((prev) => {
                    const existCategory = prev.find(
                      (item) => item.id === newCat.id
                    );
                    const parent = prev.find(
                      (item) => item.parentId === newCat.parentId
                    );
                    const parentIndex = prev.findIndex(
                      (item) => item.parentId === newCat.parentId
                    );
                    if (existCategory) {
                      if (parent) {
                        const removedChildren = prev.filter(
                          (item, index) => index < parentIndex
                        );
                        const newData = removedChildren.filter(
                          (item) => item.id !== newCat.id
                        );
                        return [...newData];
                      } else {
                        const newData = prev.filter(
                          (item) => item.id !== newCat.id
                        );
                        return newData;
                      }
                    } else {
                      if (parent) {
                        const removedChildren = prev.filter(
                          (item, index) => index < parentIndex
                        );
                        const newData = removedChildren.filter(
                          (item) => item.id !== newCat.id
                        );
                        return [...newData, newCat];
                      } else {
                        return [...prev, newCat];
                      }
                    }
                  });
                  setLevels((prev) => {
                    const parent = prev.findIndex(
                      (item) => item.parentId === newCat.parentId
                    );
                    if (parent < 0) {
                      if (newCat.childeren.length > 0) {
                        return [...prev, newCat];
                      } else return prev;
                    } else {
                      const newData = prev.filter(
                        (item, index) => index < parent
                      );
                      return newData;
                    }
                  });
                }}
              />
            </Col>
          </CategoriesTableContainer>
        ))}
      </CategoryWrapper>
    </CategoryContent>
  );
};
