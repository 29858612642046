import {
  FC,
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { AddInvoiceModal } from ".";
import usePublicApiStore from "src/store/publicApi";
import { ShipService } from "src/services/Ship/Ship.service";
import { useQuery } from "react-query";
import { reactQueryEndPoints } from "src/constants/rectQueryEndPoints";
import { useTable } from "../Table/context";
import { IShip } from "src/services/Public/models";
import { useLocation } from "react-router-dom";
import { ROUTES } from "src/models/enums";
interface IContext {
  value: {
    isOpenModal: boolean;
    shipsLoading: boolean;
    shipsData: IShip[];
  };
  func: {
    closeModal: () => void;
    openModal: () => void;
  };
}
const defaultContext: IContext = {
  value: {
    isOpenModal: false,
    shipsLoading: false,
    shipsData: [],
  },
  func: {
    closeModal: () => {},
    openModal: () => {},
  },
};
export const AddInvoiceModalCTX = createContext<IContext>(defaultContext);
export const AddInvoiceModalProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const { ships } = usePublicApiStore();
  const [shipsData, setShipsData] = useState<IShip[]>(ships?.data || []);
  const [downloadedImages, setDownloadedImages] = useState<boolean>(false);
  const location = useLocation();
  const [loading, setLoading] = useState<boolean>(true);
  const {
    dispatches: { setShipId },
  } = useTable();
  const closeModal = () => {
    setIsOpenModal(false);
    setShipId(undefined);
  };
  const openModal = () => {
    setIsOpenModal(true);
  };
  const getShipsImage = useCallback(async () => {
    if (!ships || !ships.data) return;
    const { Image } = new ShipService();

    // const newShips: IShip[] = [];
    ships.data?.forEach(async (ship) => {
      await Image(ship.id)
        .then((image) => {
          if (image) {
            setShipsData((perv) =>
              perv.map((prevShip) => {
                if (prevShip.id === ship.id) prevShip.image = image;
                return prevShip;
              })
            );
          }
        })
        .catch((error) => {
          console.log(error);
          setShipsData((perv) =>
            perv.map((prevShip) => {
              if (prevShip.id === ship.id) prevShip.image = "noImage";
              return prevShip;
            })
          );
        });
    });
    setDownloadedImages(true);
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ships, ships?.data]);
  const { refetch } = useQuery({
    queryFn: getShipsImage,
    queryKey: reactQueryEndPoints.shipsImage,
    enabled: false,
  });
  useEffect(() => {
    if (
      ships &&
      ships.data &&
      location.pathname === ROUTES.home &&
      !downloadedImages
    ) {
      setShipsData(ships.data);

      refetch();
    }
  }, [refetch, ships, location.pathname, downloadedImages]);
  const value: IContext = {
    value: { isOpenModal, shipsLoading: loading, shipsData },
    func: {
      closeModal,
      openModal,
    },
  };
  return (
    <AddInvoiceModalCTX.Provider value={value}>
      {children}
      <AddInvoiceModal />
    </AddInvoiceModalCTX.Provider>
  );
};

export const useAddInvoiceModal = () => useContext(AddInvoiceModalCTX);
