export interface INewDailyReport {
  shipId: number | undefined;
  reportDate: string;
  shipStatus: IDailyReportShipStatus | undefined;
  tradeStatus: number | undefined;
  position: number | undefined;
  vlsfo: string;
  mgo: string;
  voyage: string;
  currentSituationReport: string;
  action: string;
  futurePlan: string;
}
export interface IEditDailyReport {
  shipStatus: IDailyReportShipStatus | undefined;
  tradeStatus: number | undefined;
  position: number | undefined;
  vlsfo: string;
  mgo: string;
  voyage: string;
  currentSituationReport: string;
  action: string;
  futurePlan: string;
}
export type ShipStateType = 1 | 2 | 3 | 4 | 5 | 6;
export enum IShipState {
  preArrival = 1,
  arrival = 2,
  portStay = 3,
  departure = 4,
  postDeparture = 5,
  repair = 6,
}
export enum IPosition {
  POL_Anchorage = 1,
  POL_OPL = 2,
  POL_Berthed = 3,
  Dry_Dock = 4,
  POD_Anchorage = 5,
  POD_OPL = 6,
  POD_Berthed = 7,
  RepairAndMaintenance = 8,
}
export enum IShipStatus {
  New = 1,
  Rejected = 2,
  Accepted = 3,
}
export enum IradeStatus {
  NoVoyageYet = 1,
  InVoyage = 2,
}
export enum IDailyReportShipStatus {
  Laden = 1,
  Ballast = 2,
}
