import { FC } from "react";
import { IItemsLittleCard } from "../constants/little-card-items";
import { LittleCardContainer } from "../styles/LittleCard.style";

interface IProps extends IItemsLittleCard {}
export const LittleCard: FC<IProps> = ({ des, icon, title, onClick }) => {
  return (
    <LittleCardContainer>
      <div>
        <div className="iconContainer ">
          <span className="material-icons">{icon}</span>
        </div>
      </div>
      <div className=" titleHolder">
        <div className="titleContainer ">{title}</div>
        <div className="desContainer"> {des}</div>
      </div>
      <div className="addWrapper" onClick={onClick}>
        <div className="addButton">
          <span className="material-icons text-[16px]">add</span>
        </div>
        <div className="text-center text-[#A3AED0] mt-[4px] text-[10px] cursor-pointer">
          Add
        </div>
      </div>
    </LittleCardContainer>
  );
};
