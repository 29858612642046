import { Flex } from "antd";
import styled from "styled-components";

export const AddInformationStyle = styled(Flex)`
  border-radius: 20px 20px 0px 0px;
  background: var(--color-Base-Color-White, #fff);
  box-shadow: 0px 4px 6px 0px rgba(113, 130, 183, 0.25);
  padding: 16px 24px;
  align-items: center;
  gap: 64px;
  align-self: stretch;
  justify-content: space-between;
`;
export const CardWrapper = styled(Flex)``;
export const CardStyle = styled.div<{ $disabled: boolean; $active: boolean }>`
  display: flex;
  width: 200px;
  padding: 12px 8px 8px 8px;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  border-radius: 8px;
  border: 1px solid var(--color-Light-Mode-Border-1, #a3aed0);
  background: var(--color-Base-Color-White, #fff);

  ${({ $disabled }) => {
    if ($disabled) {
      return `
        border: 1px solid var(--color-Light-Mode-Border-3, #DADEEC);
        background: var(--color-Gray-Gray-50, #FAFAFA);
        `;
    }
  }}
  ${({ $active }) => {
    if ($active) {
      return `
      border: 2px solid var(--color-Primary-Primary-600, #2C73FC);
      background: var(--color-Primary-Primary-50, #F4F8FF);
        `;
    }
  }}

  .title {
    color: var(--color-Light-Mode-Text-Title, #001377);
    /* En/Title/T4.SemiBold */
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: center;
  }
  .description {
    color: var(--color-Light-Mode-Text-Secondary-Text, #5d6881);
    text-align: center;
    /* En/Overline/O3.Regular */
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .textButton {
    color: var(--color-Primary-Primary-600, #2c73fc);
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;
    user-select: none;
    ${({ $disabled }) => {
      if ($disabled) {
        return `
        color:#95B9FE;
        cursor: not-allowed;
        `;
      }
    }}
  }
  .Adding {
    color: var(--color-Primary-Primary-400, #95b9fe);
    text-align: center;
    cursor: pointer;
    user-select: none;
    font-family: "Plus Jakarta Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .divider {
    width: 2px;
    height: 20px;
    border-radius: 16px;
    background: var(--color-Stroke-footer-buttons, #dadeec);
  }
`;
