import { FC } from "react";
import { AddNewAgentTabs } from "../../style";
import { Tabs, TabsProps } from "antd";
import { BasicInfo } from "./components/basicInfo";
import { IAddNewAgentTabs } from "../../models";
import { UseAgents } from "../../context";
import { ContactInfo } from "./components/contactInfo";

export const AddNewAgent: FC = () => {
  const {
    value: { activeKeyAddAgent },
    dispatch: { setActiveKeyAddAgent },
  } = UseAgents()!;
  const item: TabsProps["items"] = [
    {
      key: IAddNewAgentTabs.basicInformation,
      label: "Basic Information",
      children: <BasicInfo />,
      disabled: activeKeyAddAgent !== IAddNewAgentTabs.basicInformation,
    },
    {
      key: IAddNewAgentTabs.contactInformation,
      label: "Contact Information",
      children: <ContactInfo />,
      disabled: activeKeyAddAgent !== IAddNewAgentTabs.contactInformation,
    },
  ];
  const onChange = (key: string) => {
    setActiveKeyAddAgent(key);
  };
  return (
    <AddNewAgentTabs>
      <Tabs
        items={item}
        activeKey={activeKeyAddAgent}
        onChange={onChange}
        animated
      />
    </AddNewAgentTabs>
  );
};
