import { FC } from "react";
import { CurrentSituation } from "src/models/enums";

const CurrentSituationText: FC<{ situation: number }> = ({ situation }) => {
  const currentSituationText = () => {
    switch (situation) {
      case CurrentSituation.Sailing:
        return <div>Sailing</div>;
      case CurrentSituation.Drifting:
        return <div>Drifting</div>;
      case CurrentSituation.Loading:
        return <div>Loading</div>;
      case CurrentSituation.Discharging:
        return <div>Discharging</div>;
      case CurrentSituation.Anchorage:
        return <div>Anchorage</div>;
      case CurrentSituation.DryDock:
        return <div>DryDock</div>;
      default:
        return "";
    }
  };
  return <>{currentSituationText()}</>;
};

export default CurrentSituationText;
