import { styled } from "styled-components";

export const AddInvoiceContainer = styled.div`
  .header {
    position: absolute;
    top: 24px;
    left: 50%;
    transform: translateX(-50%);
    color: var(--text, #001377);
    font-family: DM Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
`;
