import { IObject } from "src/models/interfaces";
import { useTableProfit } from "../ProftTable/context";
import { OwnerShipTypeEnum } from "src/services/Ship/models";
import { chooseCharterType, chooseQuantityType } from "../../models";
import moment from "moment";

export const useCreateProfitData = () => {
  const {
    value: { viewData },
  } = useTableProfit();
  const EXPENSES_INFO = {
    label: "EXPENSES INFO",
    value: "EXPENSES_INFO",
    color: "#EC2D30",
  };
  const INCOME_INFO = {
    label: "INCOME INFO",
    value: "INCOME_INFO",
    color: "#0C9D61",
  };
  const data: IObject = {
    basicInfo: {
      leftItems: [
        {
          label: "Owner ship",
          value:
            viewData?.ownerShipType === OwnerShipTypeEnum.Own
              ? "Owner"
              : "Charterer",
        },
        {
          label: "Cargo",
          value: viewData?.cargo,
        },
        {
          label: "Freight (USD)",
          value: viewData?.freigth,
        },
        {
          label: "Laycan",
          value: viewData?.startLaycan
            ? `${moment(viewData?.startLaycan).format(
                "YYYY-MM-DD"
              )} \xa0\xa0 to \xa0\xa0 ${moment(viewData?.endLaycan).format(
                "YYYY-MM-DD"
              )}`
            : "",
        },
        {
          label: "Demurrage rate",
          value: viewData?.demurageRate,
        },
        {
          label: "Switch port",
          value: viewData?.switchPort,
        },
        {
          label: "Loading rate",
          value: viewData?.loadingRate,
        },
        {
          label: "Agent of LP",
          value: viewData?.agentLP,
        },
        {
          label: "Agent of DP",
          value: viewData?.agentDP,
        },
      ],
      rightItems: [
        {
          label: "Charter type",
          value: chooseCharterType(viewData?.charterType),
        },
        {
          label: "Quantity",
          value: viewData?.quantity
            .toString()
            .concat(`  ${chooseQuantityType(viewData?.quantityType)}`),
        },
        {
          label: "Hire rate ",
          value: viewData?.hireRate,
        },
        {
          label: "Laytime (HRS)",
          value: viewData?.laytime,
        },
        {
          label: "Loading port",
          value: viewData?.loadingPort,
        },
        {
          label: "Discharge port",
          value: viewData?.dischargePort,
        },
        {
          label: "Discharge rate",
          value: viewData?.dischargeRate,
        },
        {
          label: "Agent of SP",
          value: viewData?.agentSP,
        },
      ],
      title: "Basic information",
      minWidth: 140,
    },

    //   leftItems: [
    //     {
    //       label: "Type grade",
    //       value: "Example",
    //     },
    //     {
    //       label: "Purchase unit price (USD)",
    //       value: "Example",
    //     },
    //     {
    //       label: "Sale unit price (USD)",
    //       value: "Example",
    //     },
    //   ],
    //   rightItems: [
    //     {
    //       label: "Purchase quantity (MT)",
    //       value: "Example",
    //     },
    //     {
    //       label: "Sale quantity  (MT)",
    //       value: "Example",
    //     },
    //   ],
    //   title: "Cargo information",
    //   minWidth: 179,
    // },
    tradeInfo: {
      leftItems: [
        EXPENSES_INFO,
        {
          label: "Purchase quantity (MT)",
          value: viewData?.purchaseQuantity,
        },
        {
          label: "TTL Purchase cargo (USD)",
          value: viewData?.tTlPurchaseCargo,
        },
        INCOME_INFO,
        {
          label: "Sale quantity  (MT)",
          value: viewData?.saleQuantity,
        },
        {
          label: "TTL Sale of cargo  (USD)",
          value: viewData?.ttlSaleCargo,
        },
        {
          label: "Discharge demurrage (USD)",
          value: viewData?.dischargeDemurage,
        },
      ],
      rightItems: [
        EXPENSES_INFO,
        {
          label: "Purchase unit price (USD)",
          value: viewData?.purchaseUnitPrice,
        },
        {
          label: "Other costs (USD)",
          value: viewData?.tradeOtherCost,
        },
        INCOME_INFO,
        {
          label: "Sale unit price (USD)",
          value: viewData?.saleUnitPrice,
        },
        {
          label: "Loading demurrage (USD)",
          value: viewData?.loadingDemurage,
        },
      ],
      title: "Trade information",
      minWidth: 193,
    },
    shippingInfo: {
      leftItems: [
        EXPENSES_INFO,
        {
          label: "Opex / TC Cost (USD)",
          value: viewData?.opexTCCost,
        },
        {
          label: "Agency cost (USD) ",
          value: viewData?.agencyCost,
        },
        {
          label: "Heating cost (USD)",
          value: viewData?.heatingCost,
        },
        INCOME_INFO,
        {
          label: "Freight invoice (USD)",
          value: viewData?.freightInvoice,
        },
        {
          label: "Heating invoice (USD)",
          value: viewData?.heatingInvoie,
        },
      ],
      rightItems: [
        EXPENSES_INFO,
        {
          label: "Bunkering (USD)",
          value: viewData?.bunkering,
        },
        {
          label: "Brokrage Commission (USD)",
          value: viewData?.brokageCommission,
        },
        {
          label: "Other cost (USD)",
          value: viewData?.shippingOtherCost,
        },
        INCOME_INFO,
        {
          label: "Demurrage invoice (USD)",
          value: viewData?.demurageInvoice,
        },
      ],
      title: "Shipping information",
      minWidth: 193,
    },
  };
  const totalData = [
    {
      label: "Total combined revenue",
      value: viewData?.totalCombinedRevenue,
      colors:
        viewData?.totalCombinedRevenue && viewData?.totalCombinedRevenue > 0
          ? "success"
          : "danger",
    },
    {
      label: "Total shipping revenue",
      value: viewData?.totalShippingRevenue,
      colors:
        viewData?.totalShippingRevenue && viewData?.totalShippingRevenue > 0
          ? "success"
          : "danger",
    },

    {
      label: "Total trade revenue",
      value: viewData?.totalTradeRevenue,
      colors:
        viewData?.totalTradeRevenue && viewData?.totalTradeRevenue > 0
          ? "success"
          : "danger",
    },
  ];
  return { data, totalData };
};
