import { Button, Col } from "antd";
import { Dispatch, FC, SetStateAction } from "react";
import { useEditInvoice } from "../context";

interface IProps {
  setIsReadyToDelete: Dispatch<SetStateAction<boolean>>;
}
export const DeleteArticlesFooter: FC<IProps> = ({ setIsReadyToDelete }) => {
  const {
    dispatches: { setShowDeleteArticle },
  } = useEditInvoice();
  return (
    <Col span={24} className="flex justify-end gap-[24px]  footer">
      <Button
        onClick={() => setIsReadyToDelete(false)}
        htmlType="button"
        icon={<span className="material-icons"> close</span>}
        className="flex justify-center items-center "
      >
        Cancel
      </Button>

      <Button
        type="primary"
        danger
        htmlType="button"
        icon={<span className="material-symbols-outlined">delete_forever</span>}
        className="flex justify-center items-center "
        onClick={() => {
          setShowDeleteArticle(true);
        }}
      >
        Delete selected
      </Button>
    </Col>
  );
};
