import styled from "styled-components";

export const ConfirmContainer = styled.div`
  .content {
    display: flex;
    padding: 48px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 12px;
    border: 1px solid var(--shadow, #dadeec);
    color: var(--text, #001377);
    font-family: DM Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 32px;
    margin-bottom: 32px;
  }
`;
