import { App, Avatar, Button, Divider, Form, Modal, Popover } from "antd";
import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { EditProfileContainer } from "./editProfile.styles";
import useGlobalStore from "src/store/global";
import { UserOutlined } from "@ant-design/icons";
import { USER_DATA } from "src/constants";
import { SInput } from "src/components/UiKit/Input";
import { FormItem } from "src/components/UiKit/FormItem";
import { IdentityService } from "src/services/Identity/Identity.service";
import { IEditProfile } from "src/services/Identity/models";
import { useUserInfo } from "src/hook/useUserInfo";
import { useMediaQuery } from "src/hook";
import { breakPointsSm } from "src/constants/screen";
interface IProps {
  isModalOpen: boolean;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
}

export const EditProfile: FC<IProps> = ({ isModalOpen, setIsModalOpen }) => {
  const isMobileDevice = !useMediaQuery(breakPointsSm);
  const { message } = App.useApp();
  const showMessage = () => {
    message.success("Profile updated successfully");
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const { userInfo } = useGlobalStore();
  const [userRole, setUserRole] = useState<string[]>([]);
  useEffect(() => {
    const userRoleInLocal = localStorage.getItem(USER_DATA);
    if (userRoleInLocal) {
      setUserRole(JSON.parse(userRoleInLocal));
    }
  }, []);
  const { userInfoRefetch } = useUserInfo();
  const [loading, setLoading] = useState<boolean>(false);
  const [form] = Form.useForm();

  const onFinish = async (values: IEditProfile) => {
    try {
      setLoading(true);
      const { UpdateUserInfo } = new IdentityService();
      const result = await UpdateUserInfo(values);
      if (result && result.status === 200) {
        userInfoRefetch();
        // form.resetFields();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
      showMessage();
    }
  };
  return (
    <Modal
      width={isMobileDevice ? "95%" : "30%"}
      title="Edit profile"
      open={isModalOpen}
      onCancel={handleCancel}
      styles={{ body: { height: "610px" } }}
      footer={null}
      afterClose={() => {
        form.resetFields();
      }}
      className="relative"
    >
      <EditProfileContainer>
        <Form className="w-[100%] " form={form} onFinish={onFinish}>
          <Divider className="mt-0 mb-0" />
          <div className=" flex flex-col items-center gap-[16px] mt-[24px] ">
            <div className="flex items-end mt-[16px] relative">
              <div className="changePicture flex justify-center items-center absolute z-10">
                <span className="material-icons text-[#fff] text-[16px]">
                  flip_camera_ios
                </span>
              </div>

              <Avatar
                className="avatarBorder"
                size={88}
                icon={<UserOutlined />}
              />
            </div>
            <div className="flex flex-row items-center mb-[32px]">
              <div className="username">
                {userInfo?.firstName} {userInfo?.lastName}
              </div>
              <Divider type="vertical" dashed className="divider1" />
              {userRole && userRole.join(" , ").length > 30 ? (
                <Popover title={userRole.join(" , ")}>
                  <div className="userRole">
                    {userRole.join(" , ").substring(0, 30).concat("...")}
                  </div>
                </Popover>
              ) : (
                <div className="userRole">{userRole?.join(" , ")}</div>
              )}
            </div>
          </div>
          <div>
            <Divider className=" mb-[56px] mt-0" />
            <div className="nameHolder">Please insert your information.</div>
            <FormItem
              label="First name"
              name="firstName"
              rules={[{ required: true }]}
              required={false}
            >
              <SInput placeholder="insert" />
            </FormItem>
            <FormItem
              label="Last name"
              name="lastName"
              rules={[{ required: true }]}
              required={false}
            >
              <SInput placeholder="insert" />
            </FormItem>
          </div>
          <div className="flex justify-center gap-[24px] footer  mt-[24px] absolute ">
            <Button
              className="w-[30%]"
              htmlType="button"
              onClick={() => {
                handleCancel();
                form.resetFields();
              }}
            >
              Cancel
            </Button>
            <Button
              className="w-[30%]"
              type="primary"
              htmlType="submit"
              // onClick={form.submit}
              loading={loading}
            >
              Save
            </Button>
          </div>
        </Form>
      </EditProfileContainer>
    </Modal>
  );
};
