export enum WorkFlowUrls {
  NewWorkFlow = "WorkFlow/NewWorkFlow",
  NewWorkFlowStep = "WorkFlow/NewWorkFlowStep",
  WorkFlowStep = "/WorkFlowStep",
  WorkFlow = "Workflow/",
  AddRoleUser = "/AddRoleUser",
  WorkFlowStepRoleUser = "/WorkFlowStepRoleUser",
  EditWorkFlow = "/EditWorkFlow",
  EditWorkFlowStep = "/EditWorkFlowStep",
  DeleteWorkFlowStep = "/DeleteWorkFlowStep",
  DeleteWorkFlowStepRoleUser = "/DeleteWorkFlowStepRoleUser",
  GetWorkFlows = "WorkFlow/WorkFlow",
  DeleteWorkFlow = "/DeleteWorkFlow",
  AddAction = "/AddAction",
  GetWorkFlowActions = "/GetWorkFlowActions",
  DeleteWorkFlowStepAction = "/DeleteWorkFlowStepAction",
}
