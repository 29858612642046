import styled from "styled-components";

export const StylesContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  .ant-tabs-nav {
    height: 80px;
    padding-bottom: unset !important;
  }
  .ant-tabs-tab {
    color: var(--text-2, #a3aed0);
    font-family: DM Sans;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: unset;
  }
  .ant-tabs-ink-bar {
    height: 2px !important;
  }
`;
