import { FC, useCallback, useEffect, useState } from "react";
import { App, Button, Col, Divider, Form, Popconfirm, Row, Select } from "antd";
import { FormItem } from "src/components/UiKit/FormItem";

import { useAddNewFlowCTX } from "../../context";
import { TableUI } from "src/components/UiKit/table";
import { ColumnProps } from "antd/es/table";
import {
  IAddAction,
  IWorkFlowStepAction,
  IWorkFlowStepRoleUser,
} from "src/services/WorkFlow/models";
import { WorkFlowService } from "src/services/WorkFlow/WorkFlow.service";
import { FlowActionContainer } from "./styles";
import { SInput } from "src/components/UiKit/Input";
import { getRowKey } from "src/helpers/getRowKey";
import { useFlowCTX } from "src/modules/Flow/context";

export const FlowAction: FC = () => {
  const {
    value: { selectedRole, flowSteps },
    func: { getWorkFlowSteps, reset, onCancelAddNew, onCancelEditAndView },
    forms: { FlowActionForm },
  } = useAddNewFlowCTX();
  const {
    value: { mode },
  } = useFlowCTX();
  const [loading, setLoading] = useState<boolean>(false);
  const [getWorkFlowActionLoading, setGetWorkFlowActionLoading] =
    useState<boolean>(false);
  const [dataTable, setDataTable] = useState<IWorkFlowStepAction[]>([]);
  const { message } = App.useApp();
  const onFinishAssignRole = async (values: IAddAction) => {
    if (!selectedRole) return;
    try {
      setLoading(true);
      const { AddAction } = new WorkFlowService();
      const res = await AddAction(selectedRole.id, values);
      if (res && res.status === 200) {
        FlowActionForm?.resetFields();
        message.success("Successfully added action  to workflow step");
        getWorkFlowStepAction();
        getWorkFlowSteps(false);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  const deleteAction = async (record: IWorkFlowStepRoleUser) => {
    if (!selectedRole) return;
    try {
      const { DeleteWorkFlowStepAction } = new WorkFlowService();
      const res = await DeleteWorkFlowStepAction(record.id);
      if (res && res.status === 200) {
        message.success("Delete Work Flow Step Action successfully");
        getWorkFlowStepAction();
        getWorkFlowSteps(false);
        FlowActionForm?.resetFields();
      }
    } catch (err) {
      console.log(err);
    }
  };
  const getWorkFlowStepAction = useCallback(async () => {
    if (!selectedRole) return;
    try {
      setGetWorkFlowActionLoading(true);
      const { GetWorkFlowActions } = new WorkFlowService();
      const res = await GetWorkFlowActions(selectedRole.id);
      if (res && res.status === 200 && res.data) {
        setDataTable(res.data);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setGetWorkFlowActionLoading(false);
    }
  }, [selectedRole]);
  useEffect(() => {
    getWorkFlowStepAction();
  }, [getWorkFlowStepAction]);
  const columns: ColumnProps<IWorkFlowStepRoleUser>[] = [
    {
      key: "#",
      dataIndex: "#",
      title: "#",
      width: 56,
      render: (text, record, index) => index + 1,
    },
    {
      key: "actionName",
      dataIndex: "actionName",
      title: "Action name",
    },
    {
      key: "targetWorkFlowStepName",
      dataIndex: "targetWorkFlowStepName",
      title: "Step Target",
    },
    mode === "view"
      ? {}
      : {
          key: "tools",
          dataIndex: "tools",
          title: "Tools",
          render: (text, record, index) => (
            <Popconfirm
              title="Are you sure delete this record?"
              onConfirm={() => {
                deleteAction(record);
              }}
              rootClassName="deletePopconfirm"
              icon={null}
              placement="leftBottom"
            >
              <span className="material-icons text-[#FF1414] cursor-pointer text-[16px]">
                delete_forever
              </span>
            </Popconfirm>
          ),
        },
  ];
  if (!selectedRole) {
    return (
      <div className="h-[400px] flex justify-center items-center text-[24px] text-[#6684df] ">
        Please select a step
      </div>
    );
  }
  return (
    <FlowActionContainer gutter={[0, 24]}>
      {mode !== "view" && (
        <>
          <Col span={24}>
            <div className="title pt-[16px]">Add action to this step.</div>
          </Col>
          <Col span={24} className="content">
            <Form form={FlowActionForm} onFinish={onFinishAssignRole}>
              <Row gutter={32}>
                <Col span={9}>
                  <FormItem
                    name={"actionName"}
                    label={"Action name"}
                    required
                    rules={[{ required: true }]}
                  >
                    <SInput placeholder="insert" />
                  </FormItem>
                </Col>
                <Col span={9}>
                  <FormItem
                    label={"Step Target"}
                    name={"targetStepId"}
                    required
                    rules={[{ required: true }]}
                  >
                    <Select
                      className="selectOption"
                      placeholder="select"
                      showSearch
                      optionFilterProp="label"
                      options={flowSteps?.map((step) => ({
                        label: step.name,
                        value: step.id,
                        key: step.id,
                      }))}
                    />
                  </FormItem>
                </Col>
                <Col span={6}>
                  <Button
                    className="add-button"
                    onClick={FlowActionForm?.submit}
                    loading={loading}
                  >
                    Create Action
                  </Button>
                </Col>
                <Col span={24}>
                  <Divider className="mt-0 mb-[24px]" />
                </Col>
              </Row>
            </Form>
          </Col>
        </>
      )}
      <Col span={24}>
        <div className="title">Actions that assign to this step.</div>
        <TableUI
          containerStyle={{ padding: 0, paddingTop: 16 }}
          columns={columns}
          loading={getWorkFlowActionLoading}
          dataSource={dataTable}
          rowKey={getRowKey}
        />
      </Col>
      <Col span={24}>
        <div className="footer">
          <Button
            onClick={mode === "create" ? onCancelAddNew : onCancelEditAndView}
          >
            Cancel
          </Button>
          <Button type="primary" onClick={reset}>
            Finish
          </Button>
        </div>
      </Col>
    </FlowActionContainer>
  );
};
