import { FC } from "react";
import { Button, Divider } from "antd";
import { useFrontAccess } from "src/modules/frontAccess/context";

export const AssignRoleFooter: FC = () => {
  const {
    dispatch: { setMode },
  } = useFrontAccess();

  return (
    <div className="text-right">
      <Divider className="p-0 mt-6" />
      <Button
        type="text"
        className="bg-[#3B82F6] text-[#fff] hover:!bg-[#3B82F6] hover:!text-[#fff]"
        onClick={() => setMode("tab")}
      >
        Finish
      </Button>
    </div>
  );
};
