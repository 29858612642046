import { FC } from "react";
import useOwnerStore from "src/store/owner";
import { useGetDailyReport } from "../../hook";
import { ReportComponent } from "../ReportComponent";

export const OwnerInstruction: FC = () => {
  const { dailyReport } = useOwnerStore();
  useGetDailyReport();
  return (
    <ReportComponent title="Instructions" content={dailyReport?.instruction} />
  );
};
