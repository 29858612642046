import { Modal, Tabs, message } from "antd";
import { TabsProps } from "antd/lib";
import { FC, useState } from "react";
import { useEditZipFileContext } from "src/modules/editZipFileInformation/context";
import {
  EditZipFileInformationKeys,
  ZipFileItemsKey,
} from "src/modules/editZipFileInformation/models";
import { FilePreviewModal } from "./FilePreview";
import { ModalFooter } from "./ItemsModalFooter";
import { uploadFile } from "src/helpers/uploadZipFile";
import { InvoiceService } from "src/services/Invoice/Invoice.service";
import downloadZipFile from "src/helpers/downloadZipFile";
import { AxiosRequestConfig } from "axios";
import { getBase64 } from "src/helpers/getBase64";
import { getFileExtension } from "src/helpers/getFileExtension";
import { InvoiceUrls } from "src/services/Invoice/urls";

export const ZipFileItemsModal: FC = () => {
  const {
    value: {
      itemsActiveKey,
      isModalOpenItems,
      imageUrl,
      allRequestFiles,
      currentAllZipFileId,
      currentRequest,
    },
    dispatch: {
      setActiveKey,
      setZipFile,
      setIsModalOpenItems,
      setItemsActiveKey,
      setAllRequestFiles,
      setImageUrl,
    },
  } = useEditZipFileContext()!;
  const [zipFileProgress, setZipFileProgress] = useState<number>(0);
  const [zipFileLoading, setZipFileLoading] = useState<boolean>(false);
  const [zipDownloadProgress, setZipDownloadProgress] = useState<number>(0);
  const [downloadZipFileLoading, setDownloadZipFileLoading] =
    useState<boolean>(false);

  const [screenshotUploadProgress, setScreenshotUploadProgress] =
    useState<number>(0);
  const [screenshotUploadLoading, setScreenshotUploadLoading] =
    useState<boolean>(false);

  const onCancel = () => {
    setZipFile(undefined);
    setActiveKey(EditZipFileInformationKeys.AttachZip);
    setIsModalOpenItems(false);
  };

  const deleteZipFile = () => {
    setAllRequestFiles((prev) => {
      if (prev) return { ...prev, hasZipFile: false };
      else return { hasScreenShot: false, hasZipFile: false };
    });
  };
  const deleteScreenShot = () => {
    setAllRequestFiles((prev) => {
      if (prev) return { ...prev, hasScreenShot: false };
      else return { hasScreenShot: false, hasZipFile: false };
    });
  };
  const addFileOnChange = async (file: Blob | null) => {
    if (!file) return message.error("file not found");
    if (!currentAllZipFileId) return message.error("requestId not found");
    setZipFileLoading(true);
    try {
      setZipFileProgress(1);
      const uploaded = await uploadFile({
        newFile: file,
        setProgress: setZipFileProgress,
        callbackUrl: InvoiceUrls.Invoice.concat(
          `${currentAllZipFileId}`
        ).concat(InvoiceUrls.AttachZipToAllRequest),
      });
      if (uploaded) {
        message.success("file successfully uploaded");
        setAllRequestFiles((prev) => {
          if (prev) return { ...prev, hasZipFile: true };
          else return { hasScreenShot: false, hasZipFile: true };
        });
        return;
      }
    } catch (e) {
      console.log(e);
    } finally {
      setZipFileLoading(false);
    }
  };
  const addScreenOnChange = async (file: Blob | null) => {
    if (!file) return message.error("screenshot not found");
    if (!currentAllZipFileId) return message.error("requestId not found");
    try {
      const config: AxiosRequestConfig = {
        onUploadProgress: (event) => {
          const percent = Math.floor((event.loaded / (event.total || 0)) * 100);
          setScreenshotUploadProgress(percent);
          if (percent === 100) {
            setTimeout(() => setScreenshotUploadProgress(0), 100);
          }
        },
        timeout: 10000000,
      };
      setScreenshotUploadLoading(true);
      const { AttachScreenShotToAllRequest } = new InvoiceService();
      const formData = new FormData();
      formData.append("screenShot", file);
      const uploaded = await AttachScreenShotToAllRequest(
        currentAllZipFileId,
        formData,
        config
      );
      if (uploaded && uploaded.status === 200) {
        message.success("screenshot successfully uploaded");
        getBase64(file, (res) => {
          setImageUrl(res);
        });

        setAllRequestFiles((prev) => {
          if (prev) return { ...prev, hasScreenShot: true };
          else return { hasScreenShot: true, hasZipFile: true };
        });
        return;
      }
    } catch (e) {
      console.log(e);
    } finally {
      setScreenshotUploadLoading(false);
    }
  };
  const onDownloadZipFile = async () => {
    const { ZipFile } = new InvoiceService();
    if (!currentAllZipFileId) {
      message.error("all request id not found");
      return;
    }
    const config: AxiosRequestConfig = {
      onDownloadProgress: (progressEvent) => {
        const percentage = Math.round(
          (progressEvent.loaded * 100) / (progressEvent.total || 0)
        );
        setZipDownloadProgress(percentage);
        if (percentage === 100) {
          setTimeout(() => setZipDownloadProgress(0), 400);
        }
      },
    };
    try {
      setDownloadZipFileLoading(true);
      const res = await ZipFile(currentAllZipFileId, config);
      if (res) {
        downloadZipFile(res);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setDownloadZipFileLoading(false);
    }
  };
  const onDownloadScreenshot = () => {
    if (!imageUrl) return message.error("Download screenshot failed");
    const dlEl = document.createElement("a");
    const contentType = imageUrl.replace("data:", "");
    const extension = getFileExtension(contentType);
    dlEl.href = imageUrl;
    dlEl.download = "screenshot".concat(extension || ".jpg");
    dlEl.click();
  };
  const fileItems: TabsProps["items"] = [
    {
      key: ZipFileItemsKey.File,
      label: "File",
      children: (
        <FilePreviewModal
          referenceNumber={currentRequest?.uniqueCode}
          file={allRequestFiles?.hasZipFile}
          fileType="zip"
          progress={zipFileProgress}
          loading={zipFileLoading}
        />
      ),
    },

    {
      key: ZipFileItemsKey.Screenshot,
      label: "ScreenShot",
      children: (
        <FilePreviewModal
          referenceNumber={currentRequest?.uniqueCode}
          file={allRequestFiles?.hasScreenShot}
          fileType="screenshot"
          screenShot={imageUrl}
          progress={screenshotUploadProgress}
          loading={screenshotUploadLoading}
        />
      ),
    },
  ];

  return (
    <Modal
      width={"60%"}
      title={
        <div className="flex justify-between">
          <div className="flex">
            <span className="material-icons">attach_file</span>
            <div>Edit zip file information</div>
          </div>
          <div>{currentRequest?.uniqueCode}</div>
        </div>
      }
      open={isModalOpenItems}
      onCancel={onCancel}
      footer={
        <ModalFooter
          hasFile={
            (itemsActiveKey === ZipFileItemsKey.File
              ? allRequestFiles?.hasZipFile
              : allRequestFiles?.hasScreenShot) || false
          }
          onCancel={onCancel}
          addFileOnChange={
            itemsActiveKey === ZipFileItemsKey.File
              ? addFileOnChange
              : addScreenOnChange
          }
          onDeleteConfirm={
            itemsActiveKey === ZipFileItemsKey.File
              ? deleteZipFile
              : deleteScreenShot
          }
          onDownload={
            itemsActiveKey === ZipFileItemsKey.File
              ? onDownloadZipFile
              : onDownloadScreenshot
          }
          downloadLoading={downloadZipFileLoading}
          downloadProgress={zipDownloadProgress}
        />
      }
      styles={{ body: { height: "700px" } }}
      closable={false}
      maskClosable={false}
    >
      <Tabs
        activeKey={itemsActiveKey}
        items={fileItems}
        centered
        onChange={(key: string) => {
          setItemsActiveKey(key);
        }}
      />
    </Modal>
  );
};
