import { FC } from "react";
import { ShowShipManagementContainer } from "../../styles";
import { useShipManagement } from "../../context";
import { ShipManagementInformationItems } from "./components/shipInformationItem";
import { HeaderShowShipManagement } from "./components/headerShowShip";
import { TopBar } from "./components/topBar";

export const ShowShipManagement: FC = () => {
  const {
    value: { tableCurrentValue },
  } = useShipManagement()!;
  return (
    <ShowShipManagementContainer>
      <HeaderShowShipManagement />
      <div className="backgroundHolder">
        <TopBar />
        <div className="dataHolder">
          <ShipManagementInformationItems
            name="company name"
            value={tableCurrentValue?.name}
          />
          <ShipManagementInformationItems
            name="Abbreviation"
            value={tableCurrentValue?.acronym}
          />
          <ShipManagementInformationItems
            name="Ship management Rank"
            value={tableCurrentValue?.rank}
          />
          <ShipManagementInformationItems
            name="Website"
            value={tableCurrentValue?.webSite}
          />
          <ShipManagementInformationItems
            name="Email"
            value={tableCurrentValue?.email}
          />
          <ShipManagementInformationItems
            name="Country"
            value={tableCurrentValue?.country}
          />
          <ShipManagementInformationItems
            name="City"
            value={tableCurrentValue?.city}
          />
          <ShipManagementInformationItems
            name="Phone number"
            value={tableCurrentValue?.phoneNumber}
          />
          <ShipManagementInformationItems
            name="Address"
            value={tableCurrentValue?.address}
          />
        </div>
      </div>
    </ShowShipManagementContainer>
  );
};
