import { FC, useEffect, useState } from "react";
import { LoginContainer } from "./styles";
import { Button, Col, Form, Input, Skeleton } from "antd";
import { ILoginArgs } from "src/services/Identity/models";
import { IdentityService } from "src/services/Identity/Identity.service";
import { useLogin, useMediaQuery } from "src/hook";
import { breakPointsSm } from "src/constants/screen";

const Login: FC = () => {
  const { loginHandler } = useLogin();
  const [loading, setLoading] = useState<boolean>(false);
  const [captchaLoading, setCaptchaLoading] = useState<boolean>(true);
  const [captchaId, setCaptchaId] = useState<string>("");
  const [captchaImage, setCaptchaImage] = useState<string>("");
  const [form] = Form.useForm();
  const isMobileDevice = !useMediaQuery(breakPointsSm);
  const getCaptcha = async () => {
    setCaptchaLoading(true);
    setCaptchaImage("");
    setCaptchaId("");
    try {
      const { GetCaptcha } = new IdentityService();
      const res = await GetCaptcha();
      if (res && res.data) {
        setCaptchaId(res.data.captchaId);
        setCaptchaImage("data:png;base64," + res.data.image);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setCaptchaLoading(false);
    }
  };
  const onFinish = async (values: Omit<ILoginArgs, "captchaId">) => {
    try {
      setLoading(true);
      const { Login } = new IdentityService();
      const reqBody: ILoginArgs = { ...values, captchaId };
      const result = await Login(reqBody);
      if (result && result.data && result.status === 200) {
        loginHandler(
          result.data.accessToken,
          result.data.roles,
          result.data.paths
        );
      } else {
        getCaptcha();
        form.setFieldValue("captchaValue", "");
      }
    } catch (err) {
      console.log(err);
      getCaptcha();
      form.setFieldValue("captchaValue", "");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCaptcha();
  }, []);

  useEffect(() => {
    let timerId: any;
    let time = 0;
    timerId = setInterval(() => {
      if (time === 120) {
        getCaptcha();
        time = 0;
      } else {
        time += 1;
      }
    }, 1000);

    return () => {
      clearInterval(timerId);
    };
  }, []);
  return (
    <LoginContainer>
      <Col span={24} className="flex flex-col gap-[30px]">
        <div className="flex justify-center items-center">
          <img
            src="/assets/images/logo.svg"
            alt="logo"
            width={isMobileDevice ? 266 : "100%"}
          />
        </div>

        <Form wrapperCol={{ span: 24 }} onFinish={onFinish} form={form}>
          <Form.Item
            wrapperCol={{ span: 24 }}
            name={"userName"}
            rules={[{ required: true }]}
          >
            <Input
              placeholder="Email or Phone Number"
              className="input"
              onPressEnter={form.submit}
            />
          </Form.Item>
          <Form.Item name={"password"} rules={[{ required: true }]}>
            <Input.Password
              placeholder="Password"
              className="inputPassword"
              onPressEnter={form.submit}
            />
          </Form.Item>

          {captchaLoading ? (
            <Skeleton.Input className="h-[60px] !w-full" active />
          ) : (
            <div className="flex justify-between items-center">
              <img src={captchaImage} alt="captcha" />
              <div
                className="w-[32px] h-[32px] bg-[#ffffff] flex justify-center items-center rounded-[6px] cursor-pointer select-none"
                onClick={getCaptcha}
              >
                <span className="material-icons text-[#024a94] ">refresh</span>
              </div>
            </div>
          )}
          <Form.Item
            wrapperCol={{ span: 24 }}
            name={"captchaValue"}
            rules={[{ required: true }]}
            className="mt-[24px]"
          >
            <Input
              placeholder="Captcha"
              className="input"
              onPressEnter={form.submit}
            />
          </Form.Item>
          <Button
            className="button"
            htmlType="button"
            loading={loading}
            onClick={form.submit}
          >
            Login
          </Button>
        </Form>
      </Col>
    </LoginContainer>
  );
};
export default Login;
