import { FC, Suspense, useEffect, useMemo, useState } from "react";
import useAuthStore from "../store/auth";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { definitionRoutes } from "./types/definitionRoutes";
import useRoutesDefinition from "./definition";
import { useAxiosConfiguration } from "src/hook/useAxiosConfig";
import { USER_ACCESS_PATH, USER_DATA } from "src/constants";
import { useUserInfo } from "src/hook/useUserInfo";
import { PreLoading } from "./Loading";

export const Routers: FC = (): JSX.Element => {
  useAxiosConfiguration();
  const { token, setUserRole, userRole, setAccessPath } = useAuthStore();
  const { routesDefinition } = useRoutesDefinition();
  const existRoles = localStorage.getItem(USER_DATA);
  const existPaths = localStorage.getItem(USER_ACCESS_PATH);
  const { gotInfo } = useUserInfo(token);

  const [currentRoutes, setCurrentRoutes] = useState<definitionRoutes>();
  useEffect(() => {
    setUserRole(existRoles ? JSON.parse(existRoles) : []);
    setAccessPath(existPaths ? JSON.parse(existPaths) : []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [existRoles, setUserRole, existPaths]);
  useEffect(() => {
    if (existRoles) {
      setCurrentRoutes(
        JSON.parse(existRoles).includes("Owner")
          ? "owner"
          : token
          ? "private"
          : "public"
      );
    } else {
      setCurrentRoutes("public");
    }
  }, [existRoles, token, userRole]);

  const routes = useMemo(
    () =>
      currentRoutes &&
      createBrowserRouter([...routesDefinition[currentRoutes]]),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentRoutes, gotInfo]
  );
  if (!currentRoutes) {
    return <PreLoading />;
  } else {
    return (
      <Suspense fallback={<PreLoading />}>
        <RouterProvider router={routes!} />
      </Suspense>
    );
  }
};
