import { FC, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { ShipDailyReportStyle } from "./style";
import { ShipDailyReportSearch } from "../ShipDailyReportSearch";
import Reports from "./components/reports";
import { ReportPrint } from "./components/ShipDailyReportHeader/ReportPrint";
import { ShipDailyReportHeader } from "./components/ShipDailyReportHeader";
import { useShipDailyReport } from "./context";

export const ShipsDailyReport: FC = () => {
  const {
    value: { reports },
    dispatch: { setFilterValue },
  } = useShipDailyReport();

  const componentRef = useRef<HTMLDivElement>(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const onSearch = (value: string) => {
    setFilterValue((prev) => {
      const data = { ...prev };
      if (!value) {
        delete data.ShipName;
        return data;
      } else {
        return { ...data, ShipName: value };
      }
    });
  };

  return (
    <>
      <ShipDailyReportHeader handlePrint={handlePrint} />
      <ShipDailyReportSearch title="Reports" onSearch={onSearch} />
      <ShipDailyReportStyle>
        <Reports />
      </ShipDailyReportStyle>
      <div className="hidden">
        <ReportPrint ref={componentRef} reports={reports} />
      </div>
    </>
  );
};
