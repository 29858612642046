import { message } from "antd";
import {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { NoonReportService } from "src/services/NoonReport/NoonReport.service";
import { useNoonReportCTX } from "../../context";
import { useSearchParams } from "react-router-dom";

interface IContext {
  value: {
    loading: boolean;
  };
  dispatch: {};
  func: {
    deleteNoonReport: (id: number) => void;
  };
}

export const NoonReportTableCTX = createContext<IContext | undefined>(
  undefined
);

export const NoonReportTableProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  let [searchParams, setSearchParams] = useSearchParams();

  const {
    value: { pagination },
    dispatch: { setPagination },
    func: { getNoonReport },
  } = useNoonReportCTX();

  const deleteNoonReport = async (id: number) => {
    setLoading(true);
    try {
      const { DeleteNoonReport } = new NoonReportService();
      const response = await DeleteNoonReport(id);
      if (response && response.status === 200) {
        message.success("Noon Report successfully deleted");
        getNoonReport();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!searchParams.size) {
      setSearchParams({
        Limit: pagination.pageSize.toString(),
        Offset: pagination.currentPage.toString(),
      });
    } else {
      const Limit = searchParams.get("Limit");
      const Offset = searchParams.get("Offset");
      setPagination((prev) => ({
        ...prev,
        pageSize: Limit ? +Limit : 10,
        current: Offset ? +Offset : 1,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams.size]);
  useEffect(() => {
    if (searchParams.size) {
      getNoonReport();
    }
  }, [getNoonReport, searchParams.size]);

  const contextValue: IContext = {
    value: { loading },
    dispatch: {},
    func: { deleteNoonReport },
  };

  return (
    <NoonReportTableCTX.Provider value={contextValue}>
      {children}
    </NoonReportTableCTX.Provider>
  );
};

export const useNoonReportTable = () => useContext(NoonReportTableCTX)!;
