import { Progress, Spin } from "antd";
import { FC, ReactNode, useEffect, useState } from "react";

interface IProps {
  referenceNumber?: string;
  title?: string;
  footer?: ReactNode;
  closable?: boolean;
  width?: number;
  file: boolean | Blob | undefined;
  progress?: number | undefined;
  loading?: boolean;
  fileType: "pdf" | "doc" | "excel" | "zip" | "any" | "screenshot";
  screenShot?: string;
  fileBase64?: string;
}
export const FilePreviewModal: FC<IProps> = ({
  file,
  progress,
  loading,
  fileType,
  screenShot,
  fileBase64,
}) => {
  const [hasFile, setHasFile] = useState<boolean>(!!file);
  useEffect(() => {
    setHasFile(!!file);
  }, [file]);
  return (
    <div className="bg-[#DADEEC] flex justify-center items-center py-[41.5px]">
      <div className="bg-[#F4F7FE] w-[500px] h-[500px] rounded-[24px] flex flex-col justify-center items-center gap-[24px]">
        {loading || progress ? (
          <div className="flex flex-col items-center justify-center gap-[24px]">
            {loading ? <Spin size="large" /> : null}
            {progress ? (
              <div className="w-[200px]">
                <Progress percent={progress} />
              </div>
            ) : null}
          </div>
        ) : hasFile ? (
          screenShot || fileBase64 ? (
            <img
              src={screenShot || fileBase64}
              alt={fileType}
              width={"500px"}
              height={"500px"}
            />
          ) : (
            <img
              src={`/assets/images/filePreviewIcon/${fileType}.svg`}
              alt={fileType}
            />
          )
        ) : (
          <>
            <img
              src={`/assets/images/filePreviewIcon/${fileType}IsEmpty.svg`}
              alt={`${fileType}IsEmpty`}
            />
            <div className="text-[#5D6881] text-[24px]">
              Please attach new file
            </div>
          </>
        )}
      </div>
    </div>
  );
};
