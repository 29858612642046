import { IObject } from "src/models/interfaces";

export interface INewPort {
  name: string;
  abbrivation: string;
  countryId: number;
  localTimeS: boolean;
  localTimeH: number;
  localTimeM: number;
  lat?: number | null;
  long?: number | null;
  description?: string;
}

export interface IAllPortsParams extends IObject {
  AbbrivationName?: string;
  Offset?: number;
  Limit?: number;
}

export interface INewAgent {
  name: string;
  countryId?: number;
  company?: string;
  portId?: number;
  description?: string;
}

export type NewAgentContactType = 1 | 2;
export enum NewAgentContactEnum {
  Phone = 2,
  Email = 1,
}
export interface INewAgentContact {
  type: NewAgentContactType;
  value: string;
}

export interface IAllAgentsParams extends IObject {
  Name?: string;
  Offset?: number;
  Limit?: number;
}

export interface IUpdateAgent extends INewAgent {}
