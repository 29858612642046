import { FC } from "react";

import { ReportStyle } from "./style";
import { Flex, Spin } from "antd";
interface IProps {
  title: string;
  content?: string;
  subTitle?: string;
  minHeight?: string;
  loading?: boolean;
}
export const ReportComponent: FC<IProps> = ({
  title,
  content,
  subTitle,
  minHeight,
  loading,
}) => {
  return (
    <ReportStyle minHeight={minHeight}>
      <Flex justify="space-between">
        <label className="text-[#000] text-[16px] font-[700]">{title}</label>
        {subTitle && (
          <label className="text-[#5D6881] text-[14px] font-[500]">
            {subTitle}
          </label>
        )}
      </Flex>
      <div className="content  ">
        {loading ? (
          <div className="flex justify-center w-full">
            <Spin />
          </div>
        ) : (
          <p className="text-[#5D6881] text-[12px] font-[500]">{content}</p>
        )}
      </div>
    </ReportStyle>
  );
};
