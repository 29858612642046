import { FC } from "react";
import { ActionModalContainer, ActionModalTitleContainer } from "../styles";

export const ActionModal: FC = () => {
  return (
    <ActionModalContainer>
      <p className="paragraph">
        gas detection systems Water Ingress Detection System Bridge Navigation
        Watch Alarm System Electronic Chart Display and Information System
        Emissions Monitoring System
      </p>
    </ActionModalContainer>
  );
};

export const ActionModalTitle: FC = () => {
  return (
    <ActionModalTitleContainer>
      <div className="header">
        <div>
          <p className="!text-[18px] mb-[0px]">Glory Shipping</p>
          <span className="text-[14px] text-[#5D6881]">Qatar</span>
          <span className="mx-[8px] text-[#5D6881]">|</span>
          <span className="text-[14px] text-[#5D6881]">Doha</span>
        </div>
        <p className="text-[20px]">Activity</p>
      </div>
    </ActionModalTitleContainer>
  );
};
