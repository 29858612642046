import { Card } from "antd";
import { styled } from "styled-components";

export const BigCardContainer = styled(Card)`
  .ant-card-cover {
    padding: 8px;
  }
  .shipsName {
    color: var(--text, #001377);
    font-family: DM Sans;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width: 150px;
  }
  .shipManagement {
    color: #7182b7;
    font-family: DM Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: flex;
    padding: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 4px;
  }
  .titleContainer {
    color: var(--text, #001377);
    font-family: DM Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.56px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .divider1 {
    width: 1px;
    height: 32px;
    color: #a3aed0 !important;
    z-index: 0;
  }
  .divider2 {
    width: 1px;
    height: 16px;
    color: #a3aed0 !important;
    z-index: 1;
  }
`;
