import { FC } from "react";
import { StepListContainer } from "./styles";
import { Col, Spin } from "antd";
import { useAddNewFlowCTX } from "../../context";
import { StepsCard } from "./Cards";

export const StepList: FC = () => {
  const {
    value: { flowStepsCount, flowStepsLoading, selectedStep, flowSteps },
    dispatch: { setSelectedStep },
  } = useAddNewFlowCTX();

  return (
    <StepListContainer gutter={[0, 24]}>
      <Col span={24}>
        <div className="header">
          <p className="title">The list of created steps.</p>
          <p className="stepCount">{flowStepsCount} steps</p>
        </div>
      </Col>
      <Col span={24} className="wrapper">
        <div className="content">
          {flowStepsLoading ? (
            <div className="noItem">
              <Spin />
            </div>
          ) : flowSteps.length > 0 ? (
            flowSteps.map((item, index) => (
              <StepsCard
                key={index}
                {...item}
                selected={selectedStep?.id === item.id}
                onClick={() => {
                  setSelectedStep(item);
                }}
              />
            ))
          ) : (
            <div className="noItem">You have not added a step yet.</div>
          )}
        </div>
      </Col>
    </StepListContainer>
  );
};
