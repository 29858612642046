import { Popconfirm } from "antd";
import { FC, ReactElement } from "react";
import { useSupplier } from "../context";
interface ISPopconfirm {
  onConfirm: () => void;
  children: ReactElement;
  description: string;
}
export const DeletePopconfirm: FC<ISPopconfirm> = ({
  onConfirm,
  children,
  description,
}) => {
  const {
    value: { deleteLoading },
  } = useSupplier()!;

  return (
    <Popconfirm
      icon=""
      placement="topRight"
      title="Delete"
      description={description}
      onConfirm={onConfirm}
      rootClassName="deletePopconfirm"
      okText="Yes"
      cancelText="No"
      okButtonProps={{ loading: deleteLoading }}
    >
      {children}
    </Popconfirm>
  );
};
