import styled from "styled-components";

export const SelectStepContentStyle = styled.div`
  width: 100%;
  height: 644px;
  .content {
    border-radius: 16px;
    border: 2px solid var(--color-Primary-Primary-500, #568ffd);
    width: 100%;
    height: 100%;
  }
  .icon {
    font-size: 80px;
    color: #568ffd;
  }
  .description {
    color: #568ffd;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 426px;
  }
`;
