import { styled } from "styled-components";

export const AddInvoiceModalStyle = styled.div`
  tr {
    th {
      font-size: 13px;
    }
    th::before {
      content: unset !important;
    }
  }
  .invoices-table {
    tr > th {
      padding: 16px 14px !important;
      text-align: center !important;
    }
  }
`;
