import dayjs from "dayjs";
export enum EditZipFileInformationKeys {
  ZipInformation = "1",
  AttachZip = "2",
}

export interface IZipInformation {
  onEdit?: (comment: string | undefined) => void;
  onCancel?: () => void;
}
export interface IEditZipFileInformation {
  referenceNumber: string;
}

export enum ZipFileItemsKey {
  File = "1",
  Screenshot = "2",
  Noname = "3",
}

export interface IUpdateZipInformationForm {
  dueDate: dayjs.Dayjs;
  paymentCurrencyId: number;
  comment: string;
}
