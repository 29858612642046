import { Col, DatePicker, Select } from "antd";
import { FC } from "react";
import { FormItem } from "src/components/UiKit/FormItem";
import { SInput } from "src/components/UiKit/Input";
import { ProfitBasicFormItemsWrapper } from "./style";
import usePublicApiStore from "src/store/publicApi";
import { OwnerShipType } from "src/services/Ship/models";
import { CharterType, QuantityType } from "src/services/profit/models";
import { useProfitReport } from "src/modules/ProfitReport/context";
const { RangePicker } = DatePicker;

export const ProfitBasicFormItems: FC = () => {
  const { ships } = usePublicApiStore();
  const {
    value: { editMode },
  } = useProfitReport();

  return (
    <ProfitBasicFormItemsWrapper gutter={24}>
      <Col span={8}>
        <FormItem name="shipId" label="Vessel" rules={[{ required: true }]}>
          <Select
            disabled={editMode}
            options={ships?.data?.map((ship) => ({
              label: ship.name,
              value: ship.id,
              name: ship.abbreviation,
            }))}
            className="selectOption"
            placeholder="select"
            allowClear
            showSearch
            optionFilterProp="label"
            optionLabelProp="name"
          />
        </FormItem>
      </Col>
      <Col span={8}>
        <FormItem
          name="ownerShipType"
          label="Owner ship"
          rules={[{ required: true }]}
        >
          <Select
            options={OwnerShipType}
            className="selectOption"
            placeholder="select"
            allowClear
            showSearch
            optionFilterProp="label"
          />
        </FormItem>
      </Col>
      <Col span={8}>
        <FormItem
          name="voyageNumber"
          label="Voyage number"
          rules={[{ required: true }]}
        >
          <SInput />
        </FormItem>
      </Col>
      <Col span={8}>
        <FormItem
          name="charterType"
          label="Charter type"
          rules={[{ required: true }]}
        >
          <Select
            options={CharterType}
            className="selectOption"
            placeholder="select"
            allowClear
            showSearch
            optionFilterProp="label"
          />
        </FormItem>
      </Col>
      <Col span={8}>
        <FormItem name="cargo" label="Cargo" rules={[{ required: true }]}>
          <SInput />
        </FormItem>
      </Col>
      <Col span={8}>
        <FormItem
          name="quantity"
          label="Quantity"
          rules={[{ required: true }]}
          className="quantity"
        >
          <SInput
            placeholder="Insert"
            addonBefore={
              <FormItem
                name="quantityType"
                rules={[{ required: true }]}
                className="mb-0 w-[100px]"
              >
                <Select
                  options={QuantityType}
                  className="selectOption "
                  placeholder="select"
                  allowClear
                  showSearch
                  optionFilterProp="label"
                />
              </FormItem>
            }
          />
        </FormItem>
      </Col>
      <Col span={8}>
        <FormItem name={"laycan"} label="Laycan">
          <RangePicker
            style={{ width: "100%" }}
            className="rangePicker"
            placeholder={["Start", "End"]}
          />
        </FormItem>
      </Col>
      <Col span={8}>
        <FormItem name="laytime" label="Laytime (HRS)">
          <SInput numbermode placeholder="Only number" />
        </FormItem>
      </Col>
      <Col span={8}>
        <FormItem name="demurageRate" label="Demurrage rate (USD)">
          <SInput numbermode placeholder="Only number" />
        </FormItem>
      </Col>
      <Col span={8}>
        <FormItem
          name="freigth"
          label="Freight (USD)"
          rules={[{ required: true }]}
        >
          <SInput numbermode placeholder="Only number" />
        </FormItem>
      </Col>
      <Col span={8}>
        <FormItem name="hireRate" label="Hire rate ">
          <SInput numbermode placeholder="Only number" />
        </FormItem>
      </Col>
      <Col span={8}></Col>
      <Col span={8}>
        <FormItem name="loadingPort" label="Loading port">
          {/* <Select
            options={[]}
            className="selectOption"
            placeholder="select"
            allowClear
            showSearch
            optionFilterProp="label"
          /> */}
          <SInput />
        </FormItem>
      </Col>
      <Col span={8}>
        <FormItem name="switchPort" label="Switch port">
          {/* <Select
            options={[]}
            className="selectOption"
            placeholder="select"
            allowClear
            showSearch
            optionFilterProp="label"
          /> */}
          <SInput />
        </FormItem>
      </Col>
      <Col span={8}>
        <FormItem name="dischargePort" label="Discharge port">
          {/* <Select
            options={[]}
            className="selectOption"
            placeholder="select"
            allowClear
            showSearch
            optionFilterProp="label"
          /> */}
          <SInput />
        </FormItem>
      </Col>
      <Col span={8}>
        <FormItem name="loadingRate" label="Loading rate">
          <SInput numbermode placeholder="Only number" />
        </FormItem>
      </Col>
      <Col span={8}>
        <FormItem name="dischargeRate" label="Discharge rate">
          <SInput numbermode placeholder="Only number" />
        </FormItem>
      </Col>
      <Col span={8}></Col>

      <Col span={8}>
        <FormItem name="agentLP" label="Agent of LP">
          {/* <Select
            options={[]}
            className="selectOption"
            placeholder="select"
            allowClear
            showSearch
            optionFilterProp="label"
          /> */}
          <SInput />
        </FormItem>
      </Col>
      <Col span={8}>
        <FormItem name="agentSP" label="Agent of switch port">
          {/* <Select
            options={[]}
            className="selectOption"
            placeholder="select"
            allowClear
            showSearch
            optionFilterProp="label"
          /> */}
          <SInput />
        </FormItem>
      </Col>
      <Col span={8}>
        <FormItem name="agentDP" label="Agent of DP">
          {/* <Select
            options={[]}
            className="selectOption"
            placeholder="select"
            allowClear
            showSearch
            optionFilterProp="label"
          /> */}
          <SInput />
        </FormItem>
      </Col>
    </ProfitBasicFormItemsWrapper>
  );
};
