import { FC, useEffect, useState } from "react";
import { TotalInvoicesContainer } from "./styles";
import {
  Button,
  Col,
  Flex,
  Form,
  Progress,
  Row,
  Select,
  Space,
  Typography,
  Upload,
  UploadProps,
  message,
} from "antd";
import { useAddInvoices } from "../../context";
import { FormItem } from "src/components/UiKit/FormItem";
import { SInput } from "src/components/UiKit/Input";
import { AddInvoiceTabEnums, ITotalInvoiceInfo } from "../../models";
import { RcFile, UploadChangeParam, UploadFile } from "antd/es/upload";
import { LoadingOutlined } from "@ant-design/icons";
import { getBase64 } from "src/helpers/getBase64";
import { useWatch } from "antd/es/form/Form";
import { InvoiceService } from "src/services/Invoice/Invoice.service";
import { ITotalInvoice } from "src/services/Invoice/models";
import validator from "validator";
import { Guard } from "src/components/Guard";
import {
  InvoiceAttqachmentPath,
  TotlaInvoicePath,
} from "src/services/Invoice/guardPath";
import { uploadFile } from "src/helpers/uploadZipFile";
import { InvoiceUrls } from "src/services/Invoice/urls";
const { Option } = Select;
const beforeUpload = (file: RcFile) => {
  const isPdf = file.type === "application/pdf";
  console.log({ isPdf });
  if (!isPdf) {
    message.error("You can only upload PDF file!");
  }
  // const isLt2M = file.size / 1024 / 1024 < 2;
  // if (!isLt2M) {
  //   message.error("Image must smaller than 2MB!");
  // }
  return isPdf;
};
export const TotalInvoices: FC = () => {
  const {
    value: { excelData, TotalInvoicesForm, newInvoiceId, allCurrency },
    dispatch: { setActiveTab, setTotalInvoiceAttach, setTotalInvoiceInfo },
  } = useAddInvoices()!;

  const [imageUrl, setImageUrl] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);
  const Discount = useWatch("Discount", TotalInvoicesForm);
  const Tax = useWatch("Tax", TotalInvoicesForm);
  const TotalInvoiceAmount = useWatch("TotalInvoiceAmount", TotalInvoicesForm);
  const SupplierTotalAmount = useWatch(
    "SupplierTotalAmount",
    TotalInvoicesForm
  );
  const ShipManagementTotalAmount = useWatch(
    "ShipManagementTotalAmount",
    TotalInvoicesForm
  );
  const FinallyTotalInvoiceAmount = useWatch(
    "FinallyTotalInvoiceAmount",
    TotalInvoicesForm
  );

  const handleChange: UploadProps["onChange"] = (
    info: UploadChangeParam<UploadFile>
  ) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    setLoading(false);

    if (info.file.status === "done") {
      // Get this url from response in real world.
      setTotalInvoiceAttach(info.file.originFileObj);
      getBase64(info.file.originFileObj as RcFile, (url) => {
        setLoading(false);
        setImageUrl(url);
      });
    }
  };
  const onFinish = async (values: ITotalInvoiceInfo) => {
    if (!newInvoiceId) return message.error("cannot find invoice id");
    setSubmitLoading(true);
    const { TotlaInvoice } = new InvoiceService();

    try {
      const reqBody: ITotalInvoice = {
        diffrenceWithShipManagementTotla:
          +values.DifferenceWithShipManagementTotal,
        diffrenceWithSupplierTotla: +values.DifferenceWithSupplierTotal,
        discount: +values.Discount,
        finalyTotlaAmount: +values.FinallyTotalInvoiceAmount,
        shipManagementTotlaAmount: +values.ShipManagementTotalAmount,
        supplireToTalAmount: +values.SupplierTotalAmount,
        tax: +values.Tax,
        totalInvoiceAmount: +values.TotalInvoiceAmount,
        invoiceCurrency: +values.InvoiceCurrencyId,
        usdRate: +values.usdRate,
      };
      const result = await TotlaInvoice(newInvoiceId, reqBody);
      if (result && result.status === 200) {
        setActiveTab(AddInvoiceTabEnums.PaymentInformation);
        setTotalInvoiceInfo(values);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setSubmitLoading(false);
    }
  };
  useEffect(() => {
    let amount: number = 0;
    excelData.forEach((item) => {
      if (+item.Amount)
        amount = +(+(+item.Amount).toFixed(4) + amount).toFixed(4);
    });
    TotalInvoicesForm.setFieldValue("TotalInvoiceAmount", amount);
  }, [excelData, TotalInvoicesForm]);

  useEffect(() => {
    const total = +TotalInvoiceAmount;
    const isNumericTotal =
      TotalInvoiceAmount !== undefined &&
      validator.isNumeric(TotalInvoiceAmount?.toString());
    const isNumericTax =
      Tax !== undefined && validator.isNumeric(Tax?.toString() || "");

    const isNumericDiscount =
      Discount !== undefined && validator.isNumeric(Discount?.toString() || "");

    if (isNumericDiscount && isNumericTax && isNumericTotal) {
      TotalInvoicesForm.setFieldValue(
        "FinallyTotalInvoiceAmount",
        total - (+Discount || 0) + (+Tax || 0)
      );
    } else {
      if (isNumericTotal)
        TotalInvoicesForm.setFieldValue("FinallyTotalInvoiceAmount", +total);
    }
  }, [Discount, Tax, TotalInvoiceAmount, TotalInvoicesForm]);
  useEffect(() => {
    const isNumericSupplierTotalAmount =
      SupplierTotalAmount !== undefined &&
      validator.isNumeric(SupplierTotalAmount?.toString() || "");
    const isNumericFinallyTotalInvoiceAmount =
      FinallyTotalInvoiceAmount !== undefined &&
      validator.isNumeric(FinallyTotalInvoiceAmount?.toString() || "");

    if (isNumericSupplierTotalAmount && isNumericFinallyTotalInvoiceAmount) {
      TotalInvoicesForm.setFieldValue(
        "DifferenceWithSupplierTotal",
        (+FinallyTotalInvoiceAmount - +SupplierTotalAmount).toFixed(4)
      );
    } else {
      TotalInvoicesForm.setFieldValue("DifferenceWithSupplierTotal", null);
    }
  }, [FinallyTotalInvoiceAmount, SupplierTotalAmount, TotalInvoicesForm]);

  useEffect(() => {
    const isNumericShipManagementTotalAmount =
      ShipManagementTotalAmount !== undefined &&
      validator.isNumeric(ShipManagementTotalAmount?.toString());
    const isNumericFinallyTotalInvoiceAmount =
      FinallyTotalInvoiceAmount !== undefined &&
      validator.isNumeric(FinallyTotalInvoiceAmount?.toString());

    if (
      isNumericShipManagementTotalAmount &&
      isNumericFinallyTotalInvoiceAmount
    ) {
      TotalInvoicesForm.setFieldValue(
        "DifferenceWithShipManagementTotal",
        (+FinallyTotalInvoiceAmount - +ShipManagementTotalAmount).toFixed(4)
      );
    } else {
      TotalInvoicesForm.setFieldValue(
        "DifferenceWithShipManagementTotal",
        null
      );
    }
  }, [FinallyTotalInvoiceAmount, ShipManagementTotalAmount, TotalInvoicesForm]);
  const uploadButton = (
    <div>
      {loading ? (
        <LoadingOutlined color="#A3AED0" />
      ) : (
        <span className="material-icons text-[#A3AED0] ">attach_file</span>
      )}
      <div className="text-[#A3AED0]">Attach</div>
    </div>
  );

  return (
    <Form onFinish={onFinish} form={TotalInvoicesForm}>
      <TotalInvoicesContainer>
        <Col span={24}>
          <div className="invoice-base-info">
            Please insert invoice’s Total information .
          </div>
          <Row gutter={32}>
            <Col span={12} className="mt-[16px]">
              <FormItem
                name={"TotalInvoiceAmount"}
                label="Total invoice amount"
              >
                <SInput
                  disabled
                  className="text-center"
                  placeholder="total amount"
                  numbermode
                />
              </FormItem>
              <FormItem
                name={"InvoiceCurrencyId"}
                label="Invoice currency"
                rules={[{ required: true }]}
              >
                <Select
                  style={{ width: "100%" }}
                  placeholder="select"
                  optionLabelProp="label"
                  showSearch
                  className="selectOption"
                >
                  {allCurrency.map((item) => (
                    <Option
                      value={item.id}
                      label={item.shortName}
                      key={item.id}
                    >
                      <Space>{item.shortName}</Space>
                    </Option>
                  ))}
                </Select>
              </FormItem>
              <FormItem
                name={"Discount"}
                label="Discount"
                rules={[{ required: true }]}
              >
                <SInput placeholder="insert" numbermode />
              </FormItem>
              <FormItem name={"Tax"} label="Tax" rules={[{ required: true }]}>
                <SInput placeholder="insert" numbermode />
              </FormItem>
              <FormItem
                name={"FinallyTotalInvoiceAmount"}
                label="Finally Total invoice amount"
                rules={[{ required: true }]}
                required={false}
              >
                <SInput
                  placeholder="= Total - Discount +  Tax"
                  disabled
                  className="text-center"
                  numbermode
                />
              </FormItem>
            </Col>
            <Col span={12} className="mt-[16px]">
              <FormItem
                name={"SupplierTotalAmount"}
                label="Supplier total amount"
                rules={[{ required: true }]}
              >
                <SInput placeholder="insert" numbermode />
              </FormItem>
              <FormItem
                name={"DifferenceWithSupplierTotal"}
                label="Difference with Supplier total"
              >
                <SInput disabled className="text-center" numbermode />
              </FormItem>
              <FormItem
                name={"ShipManagementTotalAmount"}
                label="Requester total amount"
                rules={[{ required: true }]}
              >
                <SInput placeholder="insert" numbermode />
              </FormItem>
              <FormItem
                name={"DifferenceWithShipManagementTotal"}
                label="Difference with Requester total"
              >
                <SInput disabled className="text-center" numbermode />
              </FormItem>
              <FormItem
                name={"usdRate"}
                label="USD Rate (Invoice currency to USD)"
                rules={[{ required: true }]}
              >
                <SInput numbermode placeholder="1 invoice currency = ? USD" />
              </FormItem>
            </Col>
            <Col span={24} className="ps-[16px] ">
              <Guard action={InvoiceAttqachmentPath}>
                <div className="attachFile">
                  <Typography.Text className=" attachFileLabel">
                    Attachment file.
                  </Typography.Text>
                  <Flex
                    gap={32}
                    className="w-full"
                    align="center"
                    justify="start"
                  >
                    <Upload
                      name="invoiceAttachment"
                      listType="picture-card"
                      className=" flex justify-center upload !w-[50%]"
                      showUploadList={false}
                      beforeUpload={beforeUpload}
                      onChange={handleChange}
                      customRequest={async (options) => {
                        try {
                          uploadFile({
                            options,
                            setProgress,
                            callbackUrl: InvoiceUrls.Invoice.concat(
                              `${newInvoiceId}${InvoiceUrls.InvoiceAttqachment}`
                            ),
                            method: "PATCH",
                          });
                        } catch (err) {
                          console.log(err);
                        }
                      }}
                    >
                      {imageUrl ? (
                        <span className="material-icons text-[42px] text-[green]">
                          download_done
                        </span>
                      ) : (
                        uploadButton
                      )}
                    </Upload>
                    {loading && (
                      <div className="w-full block">
                        <Progress percent={progress} />
                      </div>
                    )}
                  </Flex>
                </div>
              </Guard>
            </Col>
          </Row>
        </Col>
        <Col
          span={24}
          className="flex justify-end gap-[24px] mt-[100px] footer"
        >
          <Button
            onClick={() => setActiveTab(AddInvoiceTabEnums.Description)}
            htmlType="button"
          >
            Cancel
          </Button>
          {
            <Guard action={TotlaInvoicePath}>
              <Button
                type="primary"
                htmlType="submit"
                loading={submitLoading}
                disabled={loading}
              >
                Next
              </Button>
            </Guard>
          }
        </Col>
      </TotalInvoicesContainer>
    </Form>
  );
};
