import { FC } from "react";
import { TableContainer } from "./styles";
import { useInvoiceReport } from "../../context";
import { TableUI } from "src/components/UiKit/table";
import { IReportAllRequest } from "src/services/InvoiceReport/models";
import { ColumnsType } from "antd/es/table";
import moment from "moment";
import { INVOICE_REPORT_TYPES } from "../../models/reportTypes";

export const AllRequestReportTable: FC = () => {
  const {
    value: {
      loadings,
      AllRequestData,
      AllRequestPagination,
      currentRequestValue,
    },
    dispatch: {
      setAllRequestPagination,
      setCommentModal,
      setReportType,
      setCurrentAllRequest,
    },
    func: { fetchAllRequest, fetchAttachment },
  } = useInvoiceReport();
  const columns: ColumnsType<IReportAllRequest> = [
    {
      title: "#",
      key: "key",
      className: "!font-bold",
      align: "center",
      render: (text, record, index) =>
        (AllRequestPagination.Offset - 1) * AllRequestPagination.Limit +
        (index + 1),
    },
    {
      title: "Zip code",
      dataIndex: "zipCode",
      key: "zipCode",
      className: "!font-bold",
      align: "center",
    },
    {
      title: "Ship",
      dataIndex: "shipName",
      key: "shipName",
      className: "!font-bold",
      align: "center",
    },
    {
      title: "Requester",
      dataIndex: "requesterName",
      key: "requesterName",
      className: "!font-bold",
      align: "center",
    },
    {
      title: "File attached",
      dataIndex: "attachmentCount",
      key: "attachmentCount",
      className: "!font-bold",
      align: "center",
    },
    {
      title: "Due Date",
      dataIndex: "duDate",
      key: "duDate",
      className: "!font-bold",
      align: "center",
      render: (text) => moment(text).format("YYYY-MM-DD"),
    },
    {
      title: "Requester Date",
      dataIndex: "requesterDate",
      key: "requesterDate",
      className: "!font-bold",
      align: "center",
      render: (text) => moment(text).format("YYYY-MM-DD"),
    },
    {
      title: "Created date",
      dataIndex: "created",
      key: "created",
      className: "!font-bold",
      align: "center",
      render: (text) => moment(text).format("YYYY-MM-DD"),
    },
    {
      title: "Payment currency",
      dataIndex: "paymentCurrencyName",
      key: "paymentCurrencyName",
      className: "!font-bold",
      align: "center",
    },
    {
      title: "Tools",
      key: "tools",
      className: "!font-bold ",
      align: "center",
      render: (text, record) => {
        return (
          <div className="flex justify-between items-center gap-[16px] w-[72px]">
            <span
              className="material-symbols-outlined cursor-pointer text-[16px]"
              onClick={() => {
                setCommentModal({
                  showModal: true,
                  title: record.zipCode,
                  description: record.comment,
                });
              }}
            >
              mode_comment
            </span>
            <div
              className="text-[#2C73FC] cursor-pointer select-none text-[12px]"
              onClick={() => {
                if (currentRequestValue) {
                  setCurrentAllRequest(record);
                  fetchAttachment(currentRequestValue, record.allRequestId);
                  setReportType(INVOICE_REPORT_TYPES.Attachment);
                }
              }}
            >
              Detail
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <TableContainer>
      <TableUI
        dataSource={AllRequestData}
        columns={columns}
        scroll={{ x: "auto" }}
        loading={loadings.allRequest}
        rowKey={(record) => record.allRequestId}
        pagination={{
          position: ["bottomCenter"],
          pageSize: AllRequestPagination.Limit,
          current: AllRequestPagination.Offset,
          total: AllRequestPagination.total,
          onChange: (page, pageSize) => {
            if (currentRequestValue) {
              fetchAllRequest(currentRequestValue, {
                Limit: pageSize,
                Offset: pageSize !== AllRequestPagination.Limit ? 1 : page,
              });
            }
            setAllRequestPagination((prev) => ({
              ...prev,
              Limit: pageSize,
              Offset: pageSize !== prev.Limit ? 1 : page,
            }));
          },
        }}
      />
    </TableContainer>
  );
};
