import {
  FC,
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { Form, message } from "antd";
import { FormInstance } from "antd/lib";
import { useAddNewNoonReportCTX } from "../../context";
import { NewNoonReportTabs } from "../../model/newNoonReportTabs";
import { useNoonReportCTX } from "src/modules/NoonReport/context";
import { NoonReportService } from "src/services/NoonReport/NoonReport.service";
import { IFWInfo, IFWInfoArg } from "src/services/NoonReport/models";

interface IContext {
  value: { loading: boolean; dataLoading: boolean };
  dispatch: {};
  func: {
    onFinishInfo: (values: any) => void;
  };
  forms: {
    formName: FormInstance;
  };
}

export const FWInfoCTX = createContext<IContext | undefined>(undefined);
export const FWInfoProvider: FC<PropsWithChildren> = ({ children }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [fwInfo, setFwInfo] = useState<IFWInfo | undefined>(undefined);
  const [dataLoading, setDataLoading] = useState<boolean>(false);
  const {
    value: { selectedReport, mode },
  } = useNoonReportCTX();
  const {
    dispatch: { setActiveTab },
  } = useAddNewNoonReportCTX();

  const [formName] = Form.useForm();

  const onFinishInfo = async (values: IFWInfoArg) => {
    if (!selectedReport) return;
    setLoading(false);

    try {
      const { FWInfo } = new NoonReportService();
      const response = await FWInfo(selectedReport.id, values);
      if (response && response.status === 200) {
        message.success("FW Info successfully registered.");
        if (mode === "create") setActiveTab(NewNoonReportTabs.Speed);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const setFormFields = useCallback(() => {
    if (fwInfo)
      formName.setFieldsValue({
        Production: fwInfo.Production,
        Consumption: fwInfo.Consumption,
        ROB: fwInfo.ROB,
      });
  }, [formName, fwInfo]);

  const getData = useCallback(async () => {
    if (!selectedReport) return;
    setDataLoading(true);
    try {
      const { GetFWInfo } = new NoonReportService();
      const response = await GetFWInfo(selectedReport.id);
      if (response && response.status === 200) {
        setFwInfo(response.data);
        setFormFields();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setDataLoading(false);
    }
  }, [selectedReport, setFormFields]);

  useEffect(() => {
    if (mode === "edit") getData();
  }, [getData, mode]);

  const contextValue: IContext = {
    value: { loading, dataLoading },
    dispatch: {},
    func: {
      onFinishInfo,
    },
    forms: {
      formName,
    },
  };

  return (
    <FWInfoCTX.Provider value={contextValue}>{children}</FWInfoCTX.Provider>
  );
};

export const useFWInfo = () => useContext(FWInfoCTX)!;
