import { checkActionPermission } from "src/helpers/checkPermissionFunc";
import { IDataModel } from "../models";
import {
  IAddRoleToUserArg,
  INewUserArg,
  IRole,
  IRoleOfUser,
  IUserOfRole,
  IUsersReport,
} from "./models";
import { UserManagementUrls } from "./urls";
import axios from "axios";
import * as GuardPaths from "./guardPath";
import { permissionLog } from "src/helpers/permissionLog";
export class UserManagementService {
  async Roles(id?: number) {
    if (!checkActionPermission(GuardPaths.RolesPath)) {
      permissionLog(GuardPaths.RolesPath.path);
      return undefined;
    }
    let endpoint: string = UserManagementUrls.Roles;
    if (id) endpoint = endpoint.concat(`?roleId=${id}`);
    try {
      const response = await axios.get<IRole[]>(endpoint);
      return response;
    } catch (err) {
      return console.log(err);
    }
  }
  async newUser(data: INewUserArg) {
    if (!checkActionPermission(GuardPaths.newUserPath)) {
      permissionLog(GuardPaths.newUserPath.path);
      return undefined;
    }
    let endpoint: string = UserManagementUrls.newUser;
    try {
      const response = await axios.post<void>(endpoint, data);
      return response;
    } catch (err) {
      return console.log(err);
    }
  }
  async userReport(queries?: string) {
    if (!checkActionPermission(GuardPaths.userReportPath)) {
      permissionLog(GuardPaths.userReportPath.path);
      return undefined;
    }
    let endpoint: string = UserManagementUrls.userReport;
    if (queries) endpoint = endpoint.concat(`?${queries}`);
    try {
      const response = await axios.get<IDataModel<IUsersReport[]>>(endpoint);
      return response;
    } catch (err) {
      return console.log(err);
    }
  }
  async RolesOfUser(id: number) {
    if (!checkActionPermission(GuardPaths.RolesOfUserPath)) {
      permissionLog(GuardPaths.RolesOfUserPath.path);
      return undefined;
    }
    try {
      const response = await axios.get<IRoleOfUser[]>(
        UserManagementUrls.UserManagement.concat(id.toString()).concat(
          UserManagementUrls.RolesOfUser
        )
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async AddUserRole(id: number, data: IAddRoleToUserArg) {
    if (!checkActionPermission(GuardPaths.AddUserRolePath)) {
      permissionLog(GuardPaths.AddUserRolePath.path);
      return undefined;
    }
    try {
      const response = await axios.post<void>(
        UserManagementUrls.UserManagement.concat(id.toString()).concat(
          UserManagementUrls.AddUserRole
        ),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async ResetUserPassword(id: number, data: string) {
    if (!checkActionPermission(GuardPaths.ResetUserPasswordPath)) {
      permissionLog(GuardPaths.ResetUserPasswordPath.path);
      return undefined;
    }
    try {
      const response = await axios.post<void>(
        UserManagementUrls.UserManagement.concat(id.toString()).concat(
          UserManagementUrls.ResetUserPassword
        ),
        JSON.stringify(data),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async Enable(id: number) {
    if (!checkActionPermission(GuardPaths.EnableUserPath)) {
      permissionLog(GuardPaths.EnableUserPath.path);
      return undefined;
    }
    try {
      const response = await axios.post<void>(
        UserManagementUrls.UserManagement.concat(id.toString()).concat(
          UserManagementUrls.Enable
        )
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async Disable(id: number) {
    if (!checkActionPermission(GuardPaths.DisableUserPath)) {
      permissionLog(GuardPaths.DisableUserPath.path);
      return undefined;
    }
    try {
      const response = await axios.post<void>(
        UserManagementUrls.UserManagement.concat(id.toString()).concat(
          UserManagementUrls.Disable
        )
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async UsersOfRole(roleId: number) {
    if (!checkActionPermission(GuardPaths.UsersOfRolePath)) {
      permissionLog(GuardPaths.UsersOfRolePath.path);
      return undefined;
    }
    try {
      const response = await axios.get<IUserOfRole[]>(
        UserManagementUrls.UserManagement.concat(roleId.toString()).concat(
          UserManagementUrls.UsersOfRole
        )
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
}
