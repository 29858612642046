import { FC } from "react";
import { OwnerHeader } from "src/modules/Owner/components/OwnerHeader";
import { ShipDailyReports, ShipDailyReportsHeader } from "src/modules/Owner";

export const ShipDailyReportsPage: FC = () => {
  return (
    <>
      <OwnerHeader backWithDiscardShip={false} />
      <ShipDailyReportsHeader />
      <ShipDailyReports />
    </>
  );
};
