import { FC, Suspense } from "react";
import { Documentation } from "src/modules/Documentation";
import { DocumentationProvider } from "src/modules/Documentation/context";
import { PreLoading } from "src/routes/Loading";

export const DocumentationPage: FC = () => {
  return (
    <Suspense fallback={<PreLoading />}>
      <DocumentationProvider>
        <Documentation />
      </DocumentationProvider>
    </Suspense>
  );
};
