import { Layout } from "antd";
import { ROUTES } from "src/models/enums";
import styled from "styled-components";

const { Header } = Layout;

export const HeaderContainer = styled(Header)`
  margin-top: 37px !important;
  margin-bottom: 20px;
  .content {
    height: 66px;
    padding-inline: 24px;
  }
  .hello {
    color: var(--secondary-grey-700, #707eae);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 171.429% */
    letter-spacing: -0.28px;
    margin-bottom: unset;
  }
  .title {
    color: var(--secondary-grey-900, #2b3674);
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 42px; /* 131.25% */
    letter-spacing: -0.64px;
    margin-bottom: unset;
  }
  .icons {
    color: #a3aed0;
    font-size: 40px;
  }
  .notice {
    display: flex;
    align-items: center;
    gap: 24px;
    justify-content: flex-end;
  }
  .avatar {
    /* width: 400px; */
    display: flex;
    gap: 16px;
    justify-content: flex-end;
  }
  .username {
    color: var(--gray-03, #6c7172);
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .userRole {
    color: #2c73fc;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .ant-scroll-number {
    margin: 6px 6px;
    box-shadow: unset;
  }
  .ant-avatar {
    border: 3px solid #2c73fc;
  }
`;
export const HeaderResponsiveContainer = styled(Header)<{ path: string }>`
  margin-top: 0 !important;
  background-color: #2c73fc !important;
  height: 56px !important;
  padding-top: ${({ path }) =>
    path === ROUTES.home ? "4px" : "16px"} !important;
  margin-bottom: 20px;

  .content {
    padding-inline: 24px;
  }
  .hello {
    color: var(--secondary-grey-700, #f4f7fe);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 171.429% */
    letter-spacing: -0.28px;
    margin-bottom: unset;
    text-align: center;
  }
  .menu {
    position: absolute;
    left: 0;
    top: 0px;
    color: #fff;
    cursor: pointer;
    user-select: none;
  }
  .title {
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: -0.64px;
    margin-bottom: unset;
    text-align: center;
  }
  .icons {
    color: #a3aed0;
    font-size: 40px;
  }
  .notice {
    display: flex;
    align-items: center;
    gap: 24px;
    justify-content: flex-end;
    position: absolute;
    right: 0;
    top: 0px;
  }
  .avatar {
    /* width: 400px; */
    display: flex;
    gap: 16px;
    justify-content: flex-end;
  }
  .username {
    color: var(--gray-03, #6c7172);
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .userRole {
    color: #2c73fc;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .ant-scroll-number {
    margin: 6px 6px;
    box-shadow: unset;
  }
`;
