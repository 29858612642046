import { FC, useState } from "react";
import { Button, Divider, Flex, Form, Modal } from "antd";
import { IObject } from "src/models/interfaces";
import { useDocumentation } from "../../context";
import { IModalProps } from "./interface";

interface DeleteFolderFileModalProps extends IModalProps {
  type?: "folder" | "file";
}

const DeleteFolderFileModal: FC<DeleteFolderFileModalProps> = ({
  open,
  onCancel,
}) => {
  const [loading, setLoading] = useState<boolean>(false);

  const {
    value: { selectedDoc, path },
    func: { deleteDocumentReq },
  } = useDocumentation();

  const pathName = () => {
    const pathArray = path.split("/");
    if (pathArray.length > 1) return pathArray[pathArray.length - 1];
    else return "Root";
  };

  const Title = () => {
    return (
      <>
        <Flex vertical>
          <span className="text-[#001377] text-[18px] font-medium">
            {`Delete ${selectedDoc?.isDirectory ? "Folder" : "Document"}`}
          </span>
          <span>
            <span className="text-[#001377] text-[12px] font-medium">
              {pathName()}
            </span>
            <Divider
              type="vertical"
              className="w-[2px] h-[19px] bg-[#DADEEC]"
            />
            <span className="text-[#5D6881] text-[12px] font-normal">
              {selectedDoc?.name.concat(
                selectedDoc?.isDirectory ? "" : selectedDoc?.extention
              )}
            </span>
          </span>
        </Flex>
        <Divider className="p-0" />
      </>
    );
  };

  const handleFinishCreateFolder = (values: IObject) => {
    setLoading(true);
    deleteDocumentReq()
      .then((res) => {
        if (res) {
          onCancel();
        }
      })
      .finally(() => setLoading(false));
  };
  return (
    <Modal
      open={open}
      closable={false}
      onCancel={onCancel}
      title={<Title />}
      footer={false}
    >
      <Form
        onFinish={handleFinishCreateFolder}
        className="w-full flex flex-col justify-center items-center"
      >
        <div className="mt-6 p-8 flex flex-col justify-center items-center border border-solid border-1 rounded-xl border-[#DADEEC]">
          <p className="text-[#001377] text-center text-[18px] font-medium w-full">
            {`Are you sure you want to delete this ${
              selectedDoc?.isDirectory ? "Folder" : "Document"
            }?`}
          </p>
        </div>

        <div className="mt-6 flex items-center justify-center">
          <Button
            className="mr-3 bg-[#F5F5F5] hover:!border-[#F5F5F5] text-[#4B4B4B] hover:!text-[#4B4B4B] flex items-center"
            icon={<span className="material-icons text-[14px]">cancel</span>}
            onClick={(event) => {
              event.stopPropagation();
              onCancel();
            }}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            icon={
              <span className="material-icons text-[14px]">delete_forever</span>
            }
            className="text-[#FFF] bg-[#F64C4C] hover:!text-[#FFF] hover:!bg-[#F64C4C] border-[#F64C4C] flex items-center"
          >
            Delete
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default DeleteFolderFileModal;
