import { FC } from "react";
import { Commercial } from "src/modules/Commercial";
import { CommercialProvider } from "src/modules/Commercial/context";

export const CommercialPage: FC = () => {
  return (
    <CommercialProvider>
      <Commercial />
    </CommercialProvider>
  );
};
