import { FC } from "react";
import { FlowInfoStyle } from "./styles";
import { Button, Col, Form, Select, Space } from "antd";
import { FormItem } from "src/components/UiKit/FormItem";
import { SInput } from "src/components/UiKit/Input";
import TextArea from "antd/es/input/TextArea";
import { useAddNewFlowCTX } from "../../context";
import { useFlowCTX } from "src/modules/Flow/context";
const { Option } = Select;
export const FlowInfo: FC = () => {
  const {
    value: { newWorkFlowLoading },
    func: { onFinishFlowInfo },
    forms: { FlowInfoForm },
  } = useAddNewFlowCTX();
  const {
    value: { mode },
  } = useFlowCTX();
  return (
    <Form
      onFinish={onFinishFlowInfo}
      disabled={mode === "view"}
      form={FlowInfoForm}
    >
      <FlowInfoStyle gutter={[32, 24]}>
        <Col span={24}>
          <div className="title pt-[16px]">
            Please insert the flow information.{" "}
          </div>
        </Col>
        <Col span={12}>
          <FormItem name={"name"} rules={[{ required: true }]} label="Name">
            <SInput placeholder="Insert" />
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem
            name={"workFlowSectionType"}
            label="Section"
            rules={[{ required: true }]}
            initialValue={1}
          >
            <Select
              style={{ width: "100%" }}
              placeholder="select"
              optionLabelProp="label"
              showSearch
              className="selectOption"
            >
              <Option value={1} label={"Financial"}>
                <Space>{"Financial"}</Space>
              </Option>
            </Select>
          </FormItem>
        </Col>
        <Col span={24}>
          <div className=" flex flex-col gap-[8px]">
            <div className="inputTitle pl-[12px]">Description</div>
            <FormItem>
              <TextArea
                placeholder="Type description"
                rows={10}
                className="textarea"
                autoSize={{ maxRows: 10, minRows: 10 }}
              />
            </FormItem>
          </div>
        </Col>
        <Col span={24}>
          <div className=" flex gap-[16px] footer">
            <Button
              type="primary"
              className="w-[100px]"
              onClick={FlowInfoForm?.submit}
              loading={newWorkFlowLoading}
              disabled={false}
            >
              Next
            </Button>
          </div>
        </Col>
      </FlowInfoStyle>
    </Form>
  );
};
