import { useSearchParams } from "react-router-dom";

export const useChangeParams = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let [searchParams, setSearchParams] = useSearchParams();

  const onChangeParams = (key: string, value?: string) => {
    setSearchParams((prev) => {
      if (!value) {
        prev.delete(key);
      } else {
        prev.delete(key);
        prev.append(key, value);
      }

      return prev;
    });
  };

  return onChangeParams;
};
