import { Row } from "antd";
import { styled } from "styled-components";

export const BasicInformationContainer = styled(Row)`
  input,
  .ant-select-selection-item,
  textarea {
    color: var(--text, #001377) !important;
    font-family: DM Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.24px;
  }
  .titleHolder {
    color: var(--text, #001377);
    font-family: DM Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .inputTitle {
    color: var(--text, #001377);
    font-family: DM Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .ant-input {
    border-width: 0 !important;
  }
  .ant-select-selector input {
    margin-top: unset !important;
  }
  .select-compact {
    .ant-select-selector {
      border-radius: 12px 0 0 12px !important;
    }
    border-right: 1px solid var(--text-2, #a3aed0);
  }
`;
export const UploadedScreenShoteHolder = styled.div`
  border-radius: 8px;
  border: 1px solid var(--functional-color-success, #25e3bf);
  display: flex;
  padding: 4px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  cursor: pointer;
  .wrapper {
    border-radius: 6px;
    background: var(--3, #f4f7fe);
    display: flex;
    width: 58px;
    height: 58px;
    padding: 4px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
`;
export const TechnicalInformationContainer = styled(Row)`
  input,
  .ant-select-selection-item,
  textarea {
    color: var(--text, #001377) !important;
    font-family: DM Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.24px;
  }
  .titleHolder {
    color: var(--text, #001377);
    font-family: DM Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .inputTitle {
    color: var(--text, #001377);
    font-family: DM Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .ant-input {
    border-width: 0 !important;
  }
  .ant-select-selector input {
    margin-top: unset !important;
  }
  .select-compact {
    .ant-select-selector {
      border-radius: 12px 0 0 12px !important;
    }
    border-right: 1px solid var(--text-2, #a3aed0);
  }
  .attachFile {
    display: flex;
    height: 156px;
    padding: 16px 24px;
    flex-direction: column;
    gap: 32px;
    border-radius: 8px;
    border: 1px dashed var(--shadow, #dadeec);
  }
  .attachFileLabel {
    color: var(--text, #001377);
    font-family: DM Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .ant-upload {
    width: 66px !important;
    height: 66px !important;
    margin: unset !important;
  }
  .ant-upload-list-item-container {
    width: 66px !important;
    height: 66px !important;
  }
`;
export const OwnerContainer = styled(Row)`
  input,
  .ant-select-selection-item,
  textarea {
    color: var(--text, #001377) !important;
    font-family: DM Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.24px;
  }
  .titleHolder {
    color: var(--text, #001377);
    font-family: DM Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .inputTitle {
    color: var(--text, #001377);
    font-family: DM Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .ant-input {
    border-width: 0 !important;
  }
  .ant-select-selector input {
    margin-top: unset !important;
  }
  .select-compact {
    .ant-select-selector {
      border-radius: 12px 0 0 12px !important;
    }
    border-right: 1px solid var(--text-2, #a3aed0);
  }
  .attachFile {
    display: flex;
    height: 156px;
    padding: 16px 24px;
    flex-direction: column;
    gap: 32px;
    border-radius: 8px;
    border: 1px dashed var(--shadow, #dadeec);
  }
  .attachFileLabel {
    color: var(--text, #001377);
    font-family: DM Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .ant-upload {
    width: 66px !important;
    height: 66px !important;
    margin: unset !important;
  }
  .ant-upload-list-item-container {
    width: 66px !important;
    height: 66px !important;
  }
`;
export const InsurancesContainer = styled(Row)`
  .enableEditMode {
    border: 1px solid #2c73fc !important;
    color: #2c73fc;
  }
  .enableEditMode:hover {
    border: 1px solid #2c73fc !important;
    color: #2c73fc !important;
    background-color: #2c73fc10 !important;
  }
  input,
  .ant-select-selection-item,
  textarea {
    color: var(--text, #001377) !important;
    font-family: DM Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.24px;
  }
  .titleHolder {
    color: var(--text, #001377);
    font-family: DM Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .inputTitle {
    color: var(--text, #001377);
    font-family: DM Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .ant-input {
    border-width: 0 !important;
  }
  .ant-select-selector input {
    margin-top: unset !important;
  }
  .select-compact {
    .ant-select-selector {
      border-radius: 12px 0 0 12px !important;
    }
    border-right: 1px solid var(--text-2, #a3aed0);
  }
  .attachFile {
    display: flex;
    height: 156px;
    padding: 16px 24px;
    flex-direction: column;
    gap: 32px;
    border-radius: 8px;
    border: 1px dashed var(--shadow, #dadeec);
  }
  .attachFileLabel {
    color: var(--text, #001377);
    font-family: DM Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .ant-upload {
    width: 66px !important;
    height: 66px !important;
    margin: unset !important;
  }
  .ant-upload-list-item-container {
    width: 66px !important;
    height: 66px !important;
  }
  .borderBottom {
    border-bottom: 1px solid var(--shadow, #dadeec);
  }
  .ant-picker-range {
    background: #f4f7fe !important;
    border-color: #f4f7fe !important;
    border-radius: 12px !important;
    min-height: 48px !important;
  }
  .ant-picker-disabled {
    background: #dadeec !important;
  }
  .ant-form-item {
    margin-bottom: unset !important;
  }
`;
export const DocumentationFileContainer = styled(Row)`
  .titleHolder {
    color: var(--text, #001377);
    font-family: DM Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .borderBottom {
    border-bottom: 1px solid var(--shadow, #dadeec);
  }
  .attachFile {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 32px;
    border-radius: 8px;
  }
  .ant-upload-list-item-container {
    width: fit-content !important;
    height: 48px !important;
  }
  .ant-upload-list-item-name {
    color: #2c73fc;
  }
  .ant-upload-list-item:hover {
    background-color: unset !important;
  }

  .typographyHolder {
    color: #001377;
    font-family: DM Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;
export const UploadedFileHolder = styled.div`
  .fileWrapper {
    border-radius: 8px;
    border: 1px solid var(--functional-color-success, #25e3bf);
    display: flex;
    padding: 4px;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: fit-content;
    height: 66px;
    cursor: pointer;
  }
`;
export const ShipsPictureContainer = styled(Row)`
  display: flex;
  gap: 24px;
  .titleHolder {
    color: var(--text, #001377);
    font-family: DM Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .attachFile {
    display: flex;
    height: 156px;
    padding: 16px 24px;
    flex-direction: column;
    gap: 32px;
    border-radius: 8px;
    border: 1px dashed var(--shadow, #dadeec);
  }
  .attachFileLabel {
    color: var(--text, #001377);
    font-family: DM Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .ant-upload {
    width: 66px !important;
    height: 66px !important;
    margin: unset !important;
  }
  .ant-upload-list-item-container {
    width: 66px !important;
    height: 66px !important;
  }
`;
export const ShipPriceContainer = styled(Row)`
  input,
  .ant-select-selection-item,
  textarea {
    color: var(--text, #001377) !important;
    font-family: DM Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.24px;
  }
  display: flex;
  gap: 24px;
  .titleHolder {
    color: var(--text, #001377);
    font-family: DM Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;
export const DocumentationTableContainer = styled.div`
  .ant-table-thead tr {
    th {
      color: #1b2559 !important;
      font-family: DM Sans;
      font-size: 12px;
      font-style: normal;
      line-height: normal;
      font-weight: bold !important;
    }
  }

  .ant-table-tbody td {
    color: var(--text-3, #5d6881);
    font-family: DM Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .sc-gTgyBf {
    padding: unset !important;
    padding-top: 16px !important;
  }
  .ant-table-cell {
    padding: 6px 6px !important;
  }
  .ant-table-row {
    height: 30px !important;
  }
`;
export const ShipManagementContainer = styled(Row)`
  input,
  .ant-select-selection-item,
  textarea {
    color: var(--text, #001377) !important;
    font-family: DM Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.24px;
  }
  .titleHolder {
    color: var(--text, #001377);
    font-family: DM Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .inputTitle {
    color: var(--text, #001377);
    font-family: DM Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .ant-input {
    border-width: 0 !important;
  }
  .ant-select-selector input {
    margin-top: unset !important;
  }
  .select-compact {
    .ant-select-selector {
      border-radius: 12px 0 0 12px !important;
    }
    border-right: 1px solid var(--text-2, #a3aed0);
  }
  .attachFile {
    display: flex;
    height: 156px;
    padding: 16px 24px;
    flex-direction: column;
    gap: 32px;
    border-radius: 8px;
    border: 1px dashed var(--shadow, #dadeec);
  }
  .attachFileLabel {
    color: var(--text, #001377);
    font-family: DM Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .ant-upload {
    width: 66px !important;
    height: 66px !important;
    margin: unset !important;
  }
  .ant-upload-list-item-container {
    width: 66px !important;
    height: 66px !important;
  }
  .borderBottom {
    border-bottom: 1px solid var(--shadow, #dadeec);
  }
  .ant-picker-range {
    background: #f4f7fe !important;
    border-color: #f4f7fe !important;
    border-radius: 12px !important;
    min-height: 48px !important;
  }
  .ant-form-item {
    margin-bottom: unset !important;
  }
`;
export const AddCountryModalContainer = styled.div``;
