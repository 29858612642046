import {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { NewFlowTabs } from "./model/newFlowTabs";
import { useFlowCTX } from "../../context";
import { ActiveContent } from "./model/activeContent";
import { WorkFlowService } from "src/services/WorkFlow/WorkFlow.service";
import {
  IEditWorkFlowStep,
  INewWorkFlowArg,
  INewWorkFlowStep,
  IWorkFlowStep,
  IWorkFlowStepRoleUser,
  IWorkFlows,
} from "src/services/WorkFlow/models";
import { UserManagementService } from "src/services/UserManagement/UserManagement.service";
import { IRole, IUserOfRole } from "src/services/UserManagement/models";
import { DefaultOptionType } from "antd/es/select";
import {
  Flex,
  Form,
  Modal,
  ModalProps,
  TreeSelect,
  TreeSelectProps,
} from "antd";

import { FormInstance } from "antd/lib";
import { IFlowTabs } from "../../models/tabs";
const { SHOW_PARENT } = TreeSelect;

interface IContext {
  value: {
    activeTab: string;
    flowSteps: IWorkFlowStep[];
    newWorkFlowId: number | undefined;
    newWorkFlowLoading: boolean;
    newStepsLoading: boolean;
    flowStepsCount: number;
    flowStepsLoading: boolean;
    treeProps: TreeSelectProps;
    allUser: IUserOfRole[];
    selectedStep: IWorkFlowStep | undefined;
    selectedRole: IWorkFlowStep | undefined;
    getUsersLoading: boolean;
    flowStepsRoleUserData: IWorkFlowStepRoleUser[];
    editWorkFlowInfo: IWorkFlows | undefined;
  };
  dispatch: {
    setActiveTab: Dispatch<SetStateAction<string>>;
    setFlowSteps: Dispatch<SetStateAction<IWorkFlowStep[]>>;
    setNewWorkFlowId: Dispatch<SetStateAction<number | undefined>>;
    setSelectedStep: Dispatch<SetStateAction<IWorkFlowStep | undefined>>;
    setSelectedRole: Dispatch<SetStateAction<IWorkFlowStep | undefined>>;
    setUsers: Dispatch<SetStateAction<IUserOfRole[]>>;
    setUsersLoading: Dispatch<SetStateAction<boolean>>;
    setFlowStepsRoleUserData: Dispatch<SetStateAction<IWorkFlowStepRoleUser[]>>;
    setEditWorkFlowInfo: Dispatch<SetStateAction<IWorkFlows | undefined>>;
  };
  func: {
    onFinishFlowInfo: (values: any) => void;
    onFinishFlowStep: (values: any) => Promise<boolean>;
    getWorkFlowSteps: (hasLoading?: boolean) => void;
    editStep: (data: IEditWorkFlowStep) => Promise<boolean>;
    reset: () => void;
    onCancelAddNew: () => void;
    onCancelEditAndView: () => void;
  };
  forms: {
    AssignRoleForm: FormInstance | undefined;
    FlowActionForm: FormInstance | undefined;
    FlowInfoForm: FormInstance | undefined;
    StepsForm: FormInstance | undefined;
  };
}

const defaultContext: IContext = {
  value: {
    activeTab: NewFlowTabs.FlowInfo,
    allUser: [],
    flowSteps: [],
    newWorkFlowId: undefined,
    newWorkFlowLoading: false,
    newStepsLoading: false,
    flowStepsCount: 0,
    flowStepsLoading: false,
    treeProps: {},
    selectedStep: undefined,
    selectedRole: undefined,
    getUsersLoading: false,
    flowStepsRoleUserData: [],
    editWorkFlowInfo: undefined,
  },
  dispatch: {
    setActiveTab: () => {},
    setFlowSteps: () => {},
    setNewWorkFlowId: () => {},
    setSelectedStep: () => {},
    setSelectedRole: () => {},
    setUsers: () => {},
    setUsersLoading: () => {},
    setFlowStepsRoleUserData: () => {},
    setEditWorkFlowInfo: () => {},
  },
  func: {
    onFinishFlowInfo: () => {},
    onFinishFlowStep: async () => false,
    getWorkFlowSteps: () => {},
    editStep: async () => false,
    reset: () => {},
    onCancelAddNew: () => {},
    onCancelEditAndView: () => {},
  },
  forms: {
    AssignRoleForm: undefined,
    FlowActionForm: undefined,
    FlowInfoForm: undefined,
    StepsForm: undefined,
  },
};
export const AddNewFlowCTX = createContext<IContext>(defaultContext);
export const AddNewFlowProvider: FC<PropsWithChildren> = ({ children }) => {
  const [activeTab, setActiveTab] = useState<string>(NewFlowTabs.FlowInfo);
  const [flowSteps, setFlowSteps] = useState<IWorkFlowStep[]>([]);
  const [flowStepsCount, setFlowStepsCount] = useState<number>(0);
  const [newWorkFlowId, setNewWorkFlowId] = useState<number>();
  const [newWorkFlowLoading, setNewWorkFlowLoading] = useState<boolean>(false);
  const [newStepsLoading, setStepsLoading] = useState<boolean>(false);
  const [flowStepsLoading, setFlowStepsLoading] = useState<boolean>(false);
  const [getRolesLoading, setRolesLoading] = useState<boolean>(false);
  const [roles, setRoles] = useState<IRole[]>([]);
  const [roleId, setRoleId] = useState<number>();
  const [selectedStep, setSelectedStep] = useState<IWorkFlowStep>();
  const [selectedRole, setSelectedRole] = useState<IWorkFlowStep>();
  const [users, setUsers] = useState<IUserOfRole[]>([]);
  const [getUsersLoading, setUsersLoading] = useState<boolean>(false);
  const [editWorkFlowInfo, setEditWorkFlowInfo] = useState<IWorkFlows>();
  const [showDiscards, setShowDiscards] = useState<boolean>(false);

  const [AssignRoleForm] = Form.useForm();
  const [FlowActionForm] = Form.useForm();
  const [FlowInfoForm] = Form.useForm();
  const [StepsForm] = Form.useForm();

  const [flowStepsRoleUserData, setFlowStepsRoleUserData] = useState<
    IWorkFlowStepRoleUser[]
  >([]);

  const {
    func: { OnChangeState, changeMode },
    value: { mode },
    dispatch: { setActiveKeyFlow },
  } = useFlowCTX();

  const getWorkFlowSteps: (hasLoading?: boolean) => void = useCallback(
    async (hasLoading = true) => {
      if (!newWorkFlowId) return;
      try {
        if (hasLoading) setFlowStepsLoading(true);
        const { WorkFlowStep } = new WorkFlowService();
        const res = await WorkFlowStep(newWorkFlowId);
        if (res && res.status === 200) {
          setFlowSteps(
            res.data.records.map((record) => ({
              ...record,
            }))
          );
          setFlowStepsCount(res.data.count);
        }
      } catch (err) {
        console.log(err);
      } finally {
        if (hasLoading) setFlowStepsLoading(false);
      }
    },
    [newWorkFlowId]
  );

  const onFinishFlowInfo = async (values: INewWorkFlowArg) => {
    setNewWorkFlowLoading(true);
    if (mode === "create") {
      await createWorkFlow(values)
        .then((res) => {
          if (res) {
            setActiveTab(NewFlowTabs.Steps);
            OnChangeState(ActiveContent.CenterAndRight);
          }
        })
        .finally(() => {
          setNewWorkFlowLoading(false);
        });
      return;
    }
    if (mode === "edit") {
      if (!newWorkFlowId) return;
      await updateWorkFlow(newWorkFlowId, values)
        .then((res) => {
          if (res) {
            setActiveTab(NewFlowTabs.Steps);
            OnChangeState(ActiveContent.CenterAndRight);
            setEditWorkFlowInfo((prev) =>
              prev
                ? { ...prev, ...values }
                : {
                    ...values,
                    created: new Date().toISOString(),
                    id: newWorkFlowId,
                    stepNumber: 0,
                  }
            );
          }
        })
        .finally(() => {
          setNewWorkFlowLoading(false);
        });
      return;
    }
    if (mode === "view") {
      setActiveTab(NewFlowTabs.Steps);
      OnChangeState(ActiveContent.CenterAndRight);
      setNewWorkFlowLoading(false);
      return;
    }
  };
  const createWorkFlow = async (data: INewWorkFlowArg) => {
    try {
      const { NewWorkFlow } = new WorkFlowService();
      const res = await NewWorkFlow(data);
      if (res && res.data && res.status === 200) {
        setNewWorkFlowId(res.data);
        return true;
      } else {
        return false;
      }
    } catch (err) {
      console.log(err);
    }
  };
  const updateWorkFlow = async (workflowId: number, data: INewWorkFlowArg) => {
    try {
      const { EditWorkFlow } = new WorkFlowService();
      const res = await EditWorkFlow(workflowId, data);
      if (res && res.status === 200) {
        return true;
      } else {
        return false;
      }
    } catch (err) {
      console.log(err);
    }
  };
  const createStep = async (data: Omit<INewWorkFlowStep, "workFlowId">) => {
    if (!newWorkFlowId) return false;
    try {
      setStepsLoading(true);
      const reqBody: INewWorkFlowStep = {
        ...data,
        workFlowId: newWorkFlowId,
      };

      const { NewWorkFlowStep } = new WorkFlowService();
      const res = await NewWorkFlowStep(reqBody);
      if (res && res.status === 200) {
        getWorkFlowSteps();
        return true;
      } else return false;
    } catch (err) {
      console.log(err);
      return false;
    } finally {
      setStepsLoading(false);
    }
  };
  const editStep = async (data: IEditWorkFlowStep) => {
    if (!selectedStep) return false;
    try {
      setStepsLoading(true);
      const { EditWorkFlowStep } = new WorkFlowService();
      const res = await EditWorkFlowStep(selectedStep?.id, data);
      if (res && res.status === 200) {
        getWorkFlowSteps();
        return true;
      } else return false;
    } catch (err) {
      console.log(err);
      return false;
    } finally {
      setStepsLoading(false);
    }
  };
  const createTree = (role: IRole) => {
    const newRole: DefaultOptionType = {
      label: role.roleName,
      value: role.id,
      key: role.id,
    };
    if (role.childeren.length > 0) {
      newRole.children = role.childeren.map((item) => createTree(item));
    }
    return newRole;
  };
  const onChangeRole = (newValue: number) => {
    setRoleId(newValue);
  };
  const treeData = roles.map((item) => createTree(item))[0]?.children;
  const treeProps: TreeSelectProps = useMemo(
    () => ({
      treeData: treeData,
      value: roleId,
      onChange: onChangeRole,
      treeCheckable: false,
      showCheckedStrategy: SHOW_PARENT,
      placeholder: "select",
      style: {
        width: "100%",
      },
      loading: getRolesLoading,
    }),
    [treeData, getRolesLoading, roleId]
  );
  const fetchRoles = async () => {
    setRolesLoading(true);
    getRoles()
      .then((roles) => {
        if (roles && roles.length) {
          setRoles(roles);
        }
      })
      .finally(() => {
        setRolesLoading(false);
      });
  };
  const reset = () => {
    AssignRoleForm.resetFields();
    FlowActionForm.resetFields();
    FlowInfoForm.resetFields();
    StepsForm.resetFields();
    setActiveTab(NewFlowTabs.FlowInfo);
    setFlowSteps([]);
    setFlowStepsCount(0);
    setNewWorkFlowId(undefined);
    setSelectedStep(undefined);
    setSelectedRole(undefined);
    setUsers([]);
    OnChangeState(ActiveContent.Center);
  };
  const onDelete = async () => {
    if (!newWorkFlowId) return;
    try {
      const { DeleteWorkFlow } = new WorkFlowService();
      const res = await DeleteWorkFlow(newWorkFlowId);
      if (res && res.status === 200) {
        reset();
        setShowDiscards(false);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const onCancelAddNew = () => {
    setShowDiscards(true);
  };
  const onCancelEditAndView = () => {
    changeMode("create");
    reset();
    setActiveKeyFlow(IFlowTabs.flowTable);
  };
  const contextValue: IContext = {
    value: {
      activeTab,
      flowSteps,
      newWorkFlowId,
      newWorkFlowLoading,
      newStepsLoading,
      flowStepsCount,
      flowStepsLoading,
      treeProps,
      allUser: users,
      selectedStep,
      selectedRole,
      getUsersLoading,
      flowStepsRoleUserData,
      editWorkFlowInfo,
    },
    dispatch: {
      setActiveTab,
      setFlowSteps,
      setNewWorkFlowId,
      setSelectedStep,
      setSelectedRole,
      setUsers,
      setUsersLoading,
      setFlowStepsRoleUserData,
      setEditWorkFlowInfo,
    },
    func: {
      onFinishFlowInfo,
      onFinishFlowStep: createStep,
      getWorkFlowSteps,
      editStep,
      reset,
      onCancelAddNew,
      onCancelEditAndView,
    },
    forms: {
      AssignRoleForm,
      FlowActionForm,
      FlowInfoForm,
      StepsForm,
    },
  };

  useEffect(() => {
    getWorkFlowSteps();
  }, [getWorkFlowSteps]);
  useEffect(() => {
    fetchRoles();
  }, []);

  return (
    <AddNewFlowCTX.Provider value={contextValue}>
      {children}
      <DiscardNewFlowModal
        onDelete={onDelete}
        open={showDiscards}
        onCancel={() => setShowDiscards(false)}
      />
    </AddNewFlowCTX.Provider>
  );
};

export const useAddNewFlowCTX = () => useContext(AddNewFlowCTX);
const getRoles = async () => {
  try {
    const { Roles } = new UserManagementService();
    const res = await Roles();
    if (res && res.status === 200 && res.data) {
      return res.data;
    } else {
      return [];
    }
  } catch (e) {
    console.log(e);
  }
};
interface IDiscardNewFlowModal extends ModalProps {
  onDelete: () => void;
}
const DiscardNewFlowModal: FC<IDiscardNewFlowModal> = (props) => {
  return (
    <Modal {...props} onOk={props.onDelete} okButtonProps={{ danger: true }}>
      <Flex justify="start" align="center" gap={16}>
        <div className="material-icons text-[#FF1414]">delete_forever</div>
        Are you sure discard this flow?
      </Flex>
    </Modal>
  );
};
