import { FC, Suspense } from "react";
import { Report } from "src/modules/Report";
import { ReportProvider } from "src/modules/Report/context";
import { PreLoading } from "src/routes/Loading";

export const ReportPage: FC = () => {
  return (
    <Suspense fallback={<PreLoading />}>
      <ReportProvider>
        <Report />
      </ReportProvider>
    </Suspense>
  );
};
