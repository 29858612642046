import {
  App,
  Button,
  Col,
  DatePicker,
  Divider,
  Flex,
  Form,
  Row,
  Select,
  Space,
} from "antd";
import { FC, useEffect, useState } from "react";
import { FormItem } from "src/components/UiKit/FormItem";
import { SInput } from "src/components/UiKit/Input";
import { InsurancesContainer } from "../styles";
import TextArea from "antd/es/input/TextArea";
import STag from "./tag";
import { useAddShip } from "src/modules/AddShips/context";
import { SPopconfirm } from "./popconfirm";
import {
  IShipInsuranceInformation,
  IShipInsuranceValues,
} from "src/services/Ship/models";
import { ShipService } from "src/services/Ship/Ship.service";
import { IAddShipsTabs } from "src/modules/AddShips/models";
const { RangePicker } = DatePicker;

interface IValues extends IShipInsuranceValues {
  id: number;
}
interface IFormValues {
  [key: string]: Partial<IValues>;
}
export const Insurances: FC = () => {
  const {
    dispatch: { setActiveKeyAddNewShip, setIsAddCurrencyModalOpen },
    forms: { InsurancesForm },
    value: { allCurrency, shipId, currencyLoading },
    func: { fetchAllCurrency, fetchShipInformation, deleteShip },
  } = useAddShip()!;
  const [submittable, setSubmittable] = useState<boolean>(false);
  const insurancesFormValues = Form.useWatch([], InsurancesForm);

  useEffect(() => {
    InsurancesForm?.validateFields({ validateOnly: true }).then(
      () => {
        setSubmittable(true);
      },
      () => {
        setSubmittable(false);
      }
    );
  }, [insurancesFormValues, InsurancesForm]);
  const onCancel = () => {};
  const { message } = App.useApp();
  const nextTab = () => {
    message.success("Added new ship successfully");
    setActiveKeyAddNewShip(IAddShipsTabs.shipsTable);
    fetchShipInformation();
  };

  const [tags, setTags] = useState<string[]>([]);
  const [selectedTag, setSelectedTag] = useState<number>();
  const [values, setValues] = useState<IFormValues>({});
  const [canAddNew, setCanAddNew] = useState<boolean>(true);
  const [addLoading, setAddLoading] = useState<boolean>(false);
  const onFinish = async (values: IShipInsuranceValues) => {
    if (selectedTag === undefined) {
      message.error("Please select a tag");
      return;
    }

    try {
      setAddLoading(true);
      const service = new ShipService();

      const reqBody: IShipInsuranceInformation = {
        comment: values.comment,
        currencyId: values.currencyId,
        insuranceCompany: values.insuranceCompany,
        price: values.price,
        tagName:
          selectedTag !== undefined && selectedTag >= 0
            ? tags[selectedTag]
            : "",
        startDate:
          values.insuranceDate?.length > 0
            ? values.insuranceDate[0]?.format("YYYY-MM-DD")
            : null,
        endDate:
          values.insuranceDate?.length >= 1
            ? values.insuranceDate[1]?.format("YYYY-MM-DD")
            : null,
      };
      setValues((prev) => {
        const activeTagName = tags[selectedTag];
        const newValue = { ...prev };
        newValue[activeTagName] = { ...newValue[activeTagName], ...values };
        return newValue;
      });
      const response = await service.ShipInsuranceInformation(shipId, reqBody);
      if (response && response.status === 200) {
        message.success("insurance information successfully created");
        setCanAddNew(true);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setAddLoading(false);
    }
  };

  //----------------------------------------------------------------tag Func----------------------------------------------------------------
  useEffect(() => {
    const newValue: IFormValues = { ...values };
    const initialValue: Partial<IValues> = {
      comment: "",
      price: undefined,
      insuranceDate: [],
      insuranceCompany: undefined,
      currencyId: undefined,
    };
    tags.forEach((tag, index) => {
      if (!newValue[tag]) {
        const id = index + 10001;
        newValue[tag] = { ...initialValue, id, tagName: tag };
      }
    });
    setValues(newValue);
    InsurancesForm?.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tags]);

  const onChangeTag = (selected: number) => {
    InsurancesForm?.setFieldsValue(values[tags[selected]]);
  };
  useEffect(() => {
    fetchAllCurrency();
  }, [fetchAllCurrency]);
  return (
    <Form
      form={InsurancesForm}
      onFinish={onFinish}
      className="flex flex-col justify-between"
    >
      <InsurancesContainer className="flex gap-[24px] h-[620px] content-start">
        <Col span={24}>
          <div className="titleHolder pt-[16px]">
            Please insert ship owner’s information.
          </div>
        </Col>
        <Col
          span={24}
          className="pt-[12px] pb-[24px] borderBottom overflow-x-auto"
        >
          <STag
            selected={selectedTag}
            setSelected={setSelectedTag}
            tags={tags}
            setTags={setTags}
            onChangeTag={onChangeTag}
            placeholder="Add new insurance"
            onConfirm={() => {
              setCanAddNew(false);
            }}
            disabled={!canAddNew}
          />
        </Col>
        {tags.length > 0 ? (
          <>
            <Col span={24} className="flex gap-[24px]">
              <FormItem
                name={"insuranceCompany"}
                label="Insurance company "
                rules={[{ required: true }]}
                className="w-[33%] !mb-0"
              >
                <SInput placeholder="insert" />
              </FormItem>

              <FormItem
                name="insuranceDate"
                label="Start - End Insurance Date"
                className="w-[33%]"
                rules={[{ required: true }]}
              >
                <RangePicker className="w-full" />
              </FormItem>
              <FormItem
                name={"price"}
                label="Insurance price"
                rules={[{ required: true }]}
                className="w-[32%] !mb-0"
              >
                <SInput placeholder="insert" numbermode />
              </FormItem>
            </Col>
            <Col span={24} className="flex gap-[24px] items-center">
              <FormItem
                name={"currencyId"}
                label="Currency"
                rules={[{ required: true }]}
                className="w-[32%] !mb-0"
              >
                <Select
                  style={{ width: "100%" }}
                  placeholder="select"
                  optionLabelProp="label"
                  showSearch
                  className="selectOption"
                  loading={currencyLoading}
                  dropdownRender={(menu) => (
                    <>
                      <Space style={{ padding: "16px 8px 4px" }}>
                        <div
                          onClick={() => setIsAddCurrencyModalOpen(true)}
                          className="cursor-pointer flex justify-center items-center"
                        >
                          <span className="material-icons text-[16px] text-[#001377]">
                            add
                          </span>
                          <div className="addHolder">Add Currency</div>
                        </div>
                      </Space>
                      <Divider style={{ margin: "8px 0" }} />
                      {menu}
                    </>
                  )}
                  options={allCurrency.map((currency) => ({
                    label: currency.shortName,
                    value: currency.id,
                  }))}
                />
              </FormItem>
            </Col>
            <Col span={24}>
              <FormItem name="comment" label="Description">
                <TextArea
                  placeholder="Type roll description"
                  rows={4}
                  style={{
                    height: 150,
                    resize: "none",
                    borderRadius: 12,
                    background: "#F4F7FE",
                  }}
                />
              </FormItem>

              <Button
                type="primary"
                className="w-[100px] mt-[12px]"
                onClick={InsurancesForm?.submit}
                loading={addLoading}
              >
                Add
              </Button>
            </Col>
            <Col span={24}></Col>
          </>
        ) : (
          <Flex className="w-full" justify="center" align="center">
            Please create a new tag
          </Flex>
        )}
      </InsurancesContainer>
      <Row>
        <Col span={24}>
          <div className="flex justify-between footer">
            <div>
              <Button
                onClick={() => deleteShip(shipId)}
                className="w-[148px] flex justify-center items-center"
              >
                Discard new ship
              </Button>
            </div>
            <div className="flex gap-[16px]">
              <SPopconfirm
                description="Are you sure you want to reset all the information you filled out?"
                onConfirm={() => {
                  InsurancesForm?.resetFields();
                }}
              >
                <Button onClick={onCancel} className="w-[100px]">
                  Reset
                </Button>
              </SPopconfirm>

              <Button
                type="primary"
                className="w-[100px]"
                onClick={nextTab}
                disabled={!submittable}
              >
                Finish
              </Button>
            </div>
          </div>
        </Col>
      </Row>
    </Form>
  );
};
