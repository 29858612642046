export enum DailyReportUrls {
  DailyReport = "DailyReport/",
  NewDailyReport = "DailyReport/NewDailyReport",
  AllShipDailyReport = "DailyReport/AllShipDailyReport",
  ConfirmDailyReport = "/ConfirmDailyReport",
  RejectDailyReport = "/RejectDailyReport",
  EditDailyReport = "/EditDailyReport",
  DeleteDailyReport = "/DeleteDailyReport",
  UnreadDate = "/UnreadDate",
  ShipDailyReport = "DailyReport/ShipDailyReport",
  GetOnAction = "DailyReport/GetOnAction",
}
