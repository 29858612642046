import { IPath } from "../Identity/models";

export const ProfitPath: IPath[] = [
  { path: "api/Profit/NewProfit", httpMethod: "POST" },
  { path: "api/Profit/{ProfitId}/EditProfit", httpMethod: "PUT" },
  { path: "api/Profit/{ProfitId}/NewProfitTrade", httpMethod: "POST" },
  { path: "api/Profit/{ProfitId}/NewProfitShipping", httpMethod: "POST" },
  { path: "api/Profit/AllProfit", httpMethod: "GET" },
  { path: "api/Profit/{ProfitId}/DeleteProfit", httpMethod: "DELETE" },
  { path: "api/Profit/{ProfitId}/GetProfitInfo", httpMethod: "GET" },
  { path: "api/Profit/{ProfitId}/NewProfitFile", httpMethod: "PATCH" },
  { path: "api/Profit/{ProfitId}/GetProfitFile", httpMethod: "GET" },
  { path: "api/Profit/{ProfitId}/NewProfitTradeFile", httpMethod: "PATCH" },
  { path: "api/Profit/{ProfitId}/GetProfitTradeFile", httpMethod: "GET" },
  { path: "api/Profit/{ProfitId}/NewProfitShippingFile", httpMethod: "PATCH" },
  { path: "api/Profit/{ProfitId}/GetProfitShippingFile", httpMethod: "GET" },
];

export const NewProfit: IPath = {
  path: "api/Profit/NewProfit",
  httpMethod: "POST",
};

export const EditProfit: IPath = {
  path: "api/Profit/{ProfitId}/EditProfit",
  httpMethod: "PUT",
};

export const NewProfitTrade: IPath = {
  path: "api/Profit/{ProfitId}/NewProfitTrade",
  httpMethod: "POST",
};

export const NewProfitShipping: IPath = {
  path: "api/Profit/{ProfitId}/NewProfitShipping",
  httpMethod: "POST",
};

export const AllProfit: IPath = {
  path: "api/Profit/AllProfit",
  httpMethod: "GET",
};

export const DeleteProfit: IPath = {
  path: "api/Profit/{ProfitId}/DeleteProfit",
  httpMethod: "DELETE",
};

export const GetProfit: IPath = {
  path: "api/Profit/{ProfitId}/GetProfitInfo",
  httpMethod: "GET",
};

export const NewProfitFile: IPath = {
  path: "api/Profit/{ProfitId}/NewProfitFile",
  httpMethod: "PATCH",
};

export const GetProfitFile: IPath = {
  path: "api/Profit/{ProfitId}/GetProfitFile",
  httpMethod: "GET",
};

export const NewProfitTradeFile: IPath = {
  path: "api/Profit/{ProfitId}/NewProfitTradeFile",
  httpMethod: "PATCH",
};

export const GetProfitTradeFile: IPath = {
  path: "api/Profit/{ProfitId}/GetProfitTradeFile",
  httpMethod: "GET",
};

export const NewProfitShippingFile: IPath = {
  path: "api/Profit/{ProfitId}/NewProfitShippingFile",
  httpMethod: "PATCH",
};

export const GetProfitShippingFile: IPath = {
  path: "api/Profit/{ProfitId}/GetProfitShippingFile",
  httpMethod: "GET",
};
