import {
  App,
  Button,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Table,
} from "antd";
import { FC, ReactNode, useEffect, useMemo, useState } from "react";
import { EditInvoiceContainer } from "../styles/editInvoiceContainer";
import { IObject } from "src/models/interfaces";
import { FormItem } from "src/components/UiKit/FormItem";
import { PublicService } from "src/services/Public/Public.service";
import { IPublicCategory } from "src/services/Public/models";
import { getRowKey } from "src/helpers/getRowKey";
import { useEditInvoice } from "../context";
import {
  INewInvoiceArticle,
  IUpdateArticle,
} from "src/services/Invoice/models";
import { InvoiceService } from "src/services/Invoice/Invoice.service";
import { Guard } from "src/components/Guard";
import {
  NewInvoiceArticlePath,
  UpdateArticlePath,
} from "src/services/Invoice/guardPath";
interface IProps {
  show: boolean;
  onCancel: () => void;
  header: string;
  refetch: () => void;
}
interface IDataType extends IObject {
  comment: string;
  quantity: number;
  unitQuantity: string;
  unitPrice: number;
  amount: number;
  costCode: string;
}

export const AddAndEditRow: FC<IProps> = ({
  header,
  onCancel,
  show,
  refetch,
}) => {
  const [dataSource, setDataSource] = useState<{ [key: string]: ReactNode }[]>(
    []
  );
  const [form] = Form.useForm();
  const { message } = App.useApp();
  const [currentCategory, setCurrentCategory] = useState<IPublicCategory>();
  const {
    value: { currentArticle, showAddAndEditRow, editInvoiceId },
    dispatches: { setCurrentArticle, setShowAddAndEditRow },
  } = useEditInvoice();
  const [loading, setLoading] = useState<boolean>(false);
  const costCodeChangeHandler = async (value: string) => {
    setDataSource((prev) => {
      if (prev.length > 0) {
        const newData = prev[0];
        delete newData.categoryName;
        delete newData.subCategoryName;
        return [newData];
      } else {
        return [];
      }
    });
    if (value.length >= 5) {
      try {
        form.setFields([{ name: "costCode", errors: [] }]);
        const { Category } = new PublicService();
        const res = await Category([value]);
        if (res && res.status === 200 && res.data) {
          if (res.data[0] === null)
            return form.setFields([
              { name: "costCode", errors: ["This costCode was not found."] },
            ]);
          const data = res.data[0];
          setCurrentCategory(data);
          setDataSource((prev) => {
            if (prev.length > 0) {
              const newData = prev[0];
              newData.categoryName = data.fullName;
              // newData.subCategoryName = data.subCategory;
              return [newData];
            } else {
              return [];
            }
          });
        }
      } catch (err) {
        console.log(err);
      }
    } else {
      return form.setFields([
        { name: "costCode", errors: ["insert 5 digits."] },
      ]);
    }
  };
  const onBeforeInput = (event: any) => {
    if (/[0-9]/.test(event.data) || event.data === ".") {
      if (event.data === "." && event.target.value.includes("."))
        event.preventDefault();
    } else event.preventDefault();
  };
  const inputs = useMemo(
    () => ({
      comment: (
        <FormItem
          name={"comment"}
          className="mb-0"
          rules={[{ required: true }]}
        >
          <Input.TextArea autoSize={{ minRows: 1, maxRows: 4 }} />
        </FormItem>
      ),
      quantity: (
        <FormItem
          className="mb-0"
          name={"quantity"}
          rules={[{ required: true }]}
        >
          <Input onBeforeInput={onBeforeInput} />
        </FormItem>
      ),
      unitQuantity: (
        <FormItem
          className="mb-0"
          name={"unitQuantity"}
          rules={[{ required: true }]}
        >
          <Input />
        </FormItem>
      ),
      unitPrice: (
        <FormItem
          className="mb-0"
          name={"unitPrice"}
          rules={[{ required: true }]}
        >
          <Input onBeforeInput={onBeforeInput} style={{ width: 100 }} />
        </FormItem>
      ),
      amount: (
        <FormItem className="mb-0" name={"amount"} rules={[{ required: true }]}>
          <Input onBeforeInput={onBeforeInput} />
        </FormItem>
      ),
      costCode: (
        <FormItem
          className="mb-0"
          name={"costCode"}
          rules={[{ required: true }]}
        >
          <Input
            onChange={(e) => {
              costCodeChangeHandler(e.target.value);
            }}
          />
        </FormItem>
      ),
    }),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentArticle, showAddAndEditRow]
  );
  useEffect(() => {
    setDataSource([inputs]);
  }, [inputs]);
  useEffect(() => {
    if (currentArticle) {
      form.setFieldsValue(currentArticle);
      costCodeChangeHandler(currentArticle.costCode);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentArticle, form]);
  const tableColumn = useMemo(() => {
    const cols = [
      {
        title: "Description",
        key: "comment",
        dataIndex: "comment",
        width: 300,
      },
      {
        title: "Quantity",
        key: "quantity",
        dataIndex: "quantity",
        width: 160,
      },
      {
        title: "Unit Quantity",
        key: "unitQuantity",
        dataIndex: "unitQuantity",
        width: 160,
      },
      {
        title: "Unit Price",
        key: "unitPrice",
        dataIndex: "unitPrice",
        width: 100,
      },
      {
        title: "Amount",
        key: "amount",
        dataIndex: "amount",
        width: 150,
      },
      {
        title: "Category",
        key: "categoryName",
        dataIndex: "categoryName",
        width: 130,
      },
      // {
      //   title: "Sub category",
      //   key: "subCategoryName",
      //   dataIndex: "subCategoryName",
      //   width: 130,
      // },
      {
        title: "Cost code",
        key: "costCode",
        dataIndex: "costCode",
        width: 150,
      },
    ];

    if (currentArticle && currentArticle["#"]) {
      return [
        {
          key: "#",
          title: "#",
          dataIndex: "#",
          width: 100,
        },
        ...cols,
      ];
    }
    return cols;
  }, [currentArticle]);

  useEffect(() => {
    if (currentArticle && currentArticle["#"])
      setDataSource((prev) => {
        if (prev.length > 0) {
          const newData = prev[0];
          newData["#"] = currentArticle["#"];
          return [newData];
        } else {
          return [];
        }
      });
  }, [currentArticle]);
  const reset = () => {
    form.resetFields();
    setCurrentArticle(undefined);
    setDataSource([]);
  };
  const updateArticle = async (values: IDataType) => {
    if (!currentArticle) return;
    try {
      setLoading(true);
      const reqBody: IUpdateArticle = {
        amount: +values.amount,
        comment: values.comment,
        quantity: +values.quantity,
        unitPrice: +values.unitPrice,
        unitQuantity: values.unitQuantity,
        costCodeId: currentCategory?.id || currentArticle.id,
      };
      const { UpdateArticle } = new InvoiceService();
      const res = await UpdateArticle(currentArticle.id, reqBody);
      if (res && res.status === 200) {
        message.success("Updated article successfully");
        reset();
        setShowAddAndEditRow(false);
        refetch();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const createNewArticle = async (values: IDataType) => {
    if (!currentCategory || !editInvoiceId) return;
    try {
      setLoading(true);
      const reqBody: INewInvoiceArticle = {
        articles: [
          {
            amount: +values.amount,
            comment: values.comment,
            quantity: +values.quantity,
            unitPrice: +values.unitPrice,
            unitQuantity: values.unitQuantity,
            costCodeId: currentCategory.id,
          },
        ],
      };
      const { NewInvoiceArticle } = new InvoiceService();
      const res = await NewInvoiceArticle(editInvoiceId, reqBody);
      if (res && res.status === 200) {
        message.success(" Create article successfully");
        reset();
        setShowAddAndEditRow(false);
        refetch();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  const onFinish = (value: IDataType) => {
    if (currentArticle) updateArticle(value);
    else createNewArticle(value);
  };

  return (
    <Modal
      width={"90%"}
      open={show}
      closable={false}
      maskClosable={false}
      styles={{ body: { padding: 24 } }}
      title={"Edit invoice"}
      style={{ top: 64, padding: 24 }}
      footer={
        <Row>
          <Divider />
          <Col span={24} className="flex justify-end gap-[24px]  footer">
            <Button
              onClick={() => {
                onCancel();
                reset();
              }}
              htmlType="button"
              icon={<span className="material-icons"> close</span>}
              className="flex justify-center items-center "
              disabled={loading}
            >
              Cancel
            </Button>
            <Guard
              action={[NewInvoiceArticlePath, UpdateArticlePath]}
              multiPath
            >
              <Button
                type="primary"
                htmlType="button"
                icon={<span className="material-icons"> check</span>}
                className="flex justify-center items-center "
                onClick={form.submit}
                loading={loading}
              >
                Save
              </Button>
            </Guard>
          </Col>
        </Row>
      }
    >
      <EditInvoiceContainer>
        <header className="header">{header}</header>
        <Form form={form} onFinish={onFinish}>
          <Table
            columns={tableColumn}
            dataSource={dataSource}
            rowKey={getRowKey}
            loading={loading}
            pagination={false}
          />
        </Form>
      </EditInvoiceContainer>
    </Modal>
  );
};
