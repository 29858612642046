import { ownerRoutes } from "./owner";
import { usePrivateRoutes } from "./private";
import { usePublicRoutes } from "./public";

const useRoutesDefinition = () => {
  const publicRoutes = usePublicRoutes();
  const privateRoutes = usePrivateRoutes();
  const routesDefinition = {
    public: publicRoutes,
    private: privateRoutes,
    owner: ownerRoutes,
  };

  return { routesDefinition };
};

export default useRoutesDefinition;
