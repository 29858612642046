import { Button, Col, Form, Input, Select, Space } from "antd";
import { FC } from "react";
import { FormItem } from "src/components/UiKit/FormItem";
import usePublicApiStore from "src/store/publicApi";
import { SDatePicker } from "src/components/UiKit/DatePicker";
import {
  // PositionConstant,
  TradeStatus,
  shipStatus,
} from "src/modules/ShipDailyReport/constant/shipState";
import { useDailyReport } from "src/modules/ShipDailyReport/context";
import { EditVoyageContainer } from "src/modules/ShipDailyReport/styles";
import dayjs from "dayjs";
import { IShipStatus } from "src/services/DailyReport/models/args.models";
import { Guard } from "src/components/Guard";
import { EditDailyReportPath } from "src/services/DailyReport/guardPath";

const { Option } = Select;
export const EditVoyage: FC = () => {
  const [form] = Form.useForm();
  const {
    value: { editLoading, tableCurrentValue, isEditClicked },
    func: { onFinishEditDailyReport },
    dispatch: { setIsEditClicked },
  } = useDailyReport()!;
  const { ships } = usePublicApiStore();
  const { TextArea } = Input;

  const onCancel = () => {
    setIsEditClicked(false);
    form.setFieldsValue(tableCurrentValue);
  };

  const onFinish = (values: { instruction: string }) => {
    onFinishEditDailyReport(values);
  };
  return (
    <Form form={form} onFinish={onFinish} initialValues={tableCurrentValue}>
      <EditVoyageContainer className="flex gap-[24px]">
        <Col span={24}>
          <div className="titleHolder ">
            Please insert the voyage ship information.
          </div>
        </Col>
        <Col span={24} className="flex gap-[24px] pt-[16px]">
          <FormItem
            name={"shipId"}
            label="Ship name"
            rules={[{ required: true }]}
            className="w-[33%] !mb-0"
          >
            <Select
              style={{ width: "100%" }}
              placeholder="select"
              optionLabelProp="label"
              showSearch
              className="selectOption"
              disabled={isEditClicked}
            >
              {ships?.data?.map((item, index) => (
                <Option key={index} value={item.id} label={item.abbreviation}>
                  <Space>{item.name}</Space>
                </Option>
              ))}
            </Select>
          </FormItem>
          <FormItem
            name={"reportDate"}
            label="Report date"
            rules={[{ required: true }]}
            className="w-[33%] !mb-0"
            initialValue={dayjs()}
          >
            <SDatePicker className="w-[100%]" disabled={isEditClicked} />
          </FormItem>
          <FormItem
            name={"tradeStatus"}
            label="Trade Status"
            className="w-[33%] !mb-0"
          >
            <Select
              style={{ width: "100%" }}
              placeholder="select"
              optionLabelProp="label"
              showSearch
              className="selectOption"
            >
              {TradeStatus?.map((item, index) => (
                <Option key={index} value={item.id} label={item.name}>
                  <Space>{item.name}</Space>
                </Option>
              ))}
            </Select>
          </FormItem>
        </Col>
        <Col span={24} className="flex gap-[24px] items-center">
          <FormItem
            name={"shipStatus"}
            label="Ship Status"
            className="w-[32%] !mb-0"
          >
            <Select
              style={{ width: "100%" }}
              placeholder="select"
              optionLabelProp="label"
              showSearch
              className="selectOption"
            >
              {shipStatus?.map((item, index) => (
                <Option key={index} value={item.id} label={item.name}>
                  <Space>{item.name}</Space>
                </Option>
              ))}
            </Select>
          </FormItem>
          {/* <FormItem
            name={"position"}
            label="Position"
            className="w-[32%] !mb-0"
          >
            <Select
              style={{ width: "100%" }}
              placeholder="select"
              optionLabelProp="label"
              showSearch
              className="selectOption"
            >
              {PositionConstant?.map((item, index) => (
                <Option key={index} value={item.id} label={item.name}>
                  <Space>{item.name}</Space>
                </Option>
              ))}
            </Select>
          </FormItem> */}
        </Col>
        {/* <Col span={24} className="flex gap-[24px] items-center">
          <FormItem name={"vlsfo"} label="VLSFO" className="w-[32%] !mb-0">
            <SInput placeholder="Insert" />
          </FormItem>
          <FormItem name={"mgo"} label="MGO" className="w-[32%] !mb-0">
            <SInput placeholder="Insert" />
          </FormItem>
        </Col> */}
        <Col span={24}>
          <div className="flex flex-row gap-[24px]">
            <FormItem name={"voyage"} label="Voyage" className="w-[50%] !mb-0">
              <TextArea
                placeholder="Description"
                rows={4}
                style={{
                  height: 94,
                  resize: "none",
                  borderRadius: 12,
                  background: "#F4F7FE",
                }}
              />
            </FormItem>
            <FormItem
              name={"currentSituationReport"}
              label="Current Situation Report"
              className="w-[50%] !mb-0"
            >
              <TextArea
                placeholder="Description"
                rows={4}
                style={{
                  height: 94,
                  resize: "none",
                  borderRadius: 12,
                  background: "#F4F7FE",
                }}
              />
            </FormItem>
          </div>
        </Col>
        <Col span={24}>
          <div className="flex flex-row gap-[24px]">
            <FormItem name={"action"} label="Action" className="w-[50%] !mb-0">
              <TextArea
                placeholder="Description"
                rows={4}
                style={{
                  height: 94,
                  resize: "none",
                  borderRadius: 12,
                  background: "#F4F7FE",
                }}
              />
            </FormItem>
            <FormItem
              name={"futurePlan"}
              label="Future plan"
              className="w-[50%] !mb-0"
            >
              <TextArea
                placeholder="Description"
                rows={4}
                style={{
                  height: 94,
                  resize: "none",
                  borderRadius: 12,
                  background: "#F4F7FE",
                }}
              />
            </FormItem>
          </div>
        </Col>
        <Guard action={EditDailyReportPath}>
          <Col span={24}>
            <div className="flex gap-[16px] footer">
              {isEditClicked ? (
                <div className="flex gap-[16px] ">
                  <Button
                    onClick={onCancel}
                    className="w-[100px] rounded-[16px]"
                  >
                    Cancel
                  </Button>
                  <Button
                    className="w-[100px] bg-[#fff] text-[#3B82F6] border-[#3B82F6] flex justify-center"
                    onClick={form.submit}
                    loading={editLoading}
                  >
                    Save edit
                  </Button>
                </div>
              ) : (
                <Button
                  className="w-[100px] flex justify-center"
                  onClick={() => setIsEditClicked(true)}
                  loading={editLoading}
                  type="primary"
                  disabled={tableCurrentValue?.stauts === IShipStatus.Accepted}
                >
                  Edit
                </Button>
              )}
            </div>
          </Col>
        </Guard>
      </EditVoyageContainer>
    </Form>
  );
};
