import {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { IObject } from "src/models/interfaces";
import { DailyReportService } from "src/services/DailyReport/dailyReport.service";
import { INewAllShipDailyReportResult } from "src/services/DailyReport/models/result.model";
import useOwnerStore from "src/store/owner";

interface IContext {
  value: {
    reports: INewAllShipDailyReportResult[];
    loading: boolean;
  };
  dispatch: {
    setFilterValue: Dispatch<SetStateAction<IFilterValue>>;
  };
  func: {
    changeDailyReportReadStatus: (
      shipId: number,
      reportDate: string
    ) => Promise<INewAllShipDailyReportResult | undefined>;
    updateReports: (report: INewAllShipDailyReportResult) => void;
  };
}
interface IFilterValue extends IObject {
  FromReportDate?: string;
  ToReportDate?: string;
  ShipId?: number;
}
export const ShipDailyReportDetailContext = createContext<IContext | undefined>(
  undefined
);

export const ShipDailyReportDetailProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const [reports, setReports] = useState<INewAllShipDailyReportResult[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { selectedShip, ownerViewDate } = useOwnerStore();
  const [filterValue, setFilterValue] = useState<IFilterValue>({
    ShipId: selectedShip?.id,
    FromReportDate: ownerViewDate && ownerViewDate.format("YYYY-MM-DD"),
    ToReportDate: ownerViewDate && ownerViewDate.format("YYYY-MM-DD"),
  });

  const AllShipDailyReport = useCallback(async () => {
    setLoading(true);
    let filterSearchParams = filterValue.toString().length < 1 ? "?" : "";
    Object.keys(filterValue).forEach((key) => {
      if (filterValue[key] !== undefined) {
        filterSearchParams = filterSearchParams
          .concat(filterSearchParams === "" ? "" : "&")
          .concat(`${key}=${filterValue[key]}`);
      }
    });
    try {
      const { AllShipDailyReport } = new DailyReportService();
      const result = await AllShipDailyReport(filterSearchParams);
      if (result && result.status === 200) {
        setReports(result.data.records);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }, [filterValue]);
  const changeDailyReportReadStatus = useCallback(
    async (shipId: number, reportDate: string) => {
      try {
        const { ShipDailyReport } = new DailyReportService();
        const result = await ShipDailyReport(shipId, reportDate);
        if (result && result.data) {
          return result.data;
        }
      } catch (err) {
        console.log("Error getting daily report", err);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedShip]
  );
  const updateReports = (report: INewAllShipDailyReportResult) => {
    setReports((prev) => {
      return prev.map((item) => {
        if (item.id === report.id) {
          return report;
        } else {
          return item;
        }
      });
    });
  };
  useEffect(() => {
    AllShipDailyReport();
  }, [AllShipDailyReport, filterValue]);

  const ContextValue: IContext = {
    value: { reports, loading },
    dispatch: { setFilterValue },
    func: {
      changeDailyReportReadStatus,
      updateReports,
    },
  };
  return (
    <ShipDailyReportDetailContext.Provider value={ContextValue}>
      {children}
    </ShipDailyReportDetailContext.Provider>
  );
};
export const useShipDailyReportDetail = () =>
  useContext(ShipDailyReportDetailContext)!;
