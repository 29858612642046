import { FC, useState } from "react";
import { Button, Col, Form, Select } from "antd";
import dayjs from "dayjs";
import { FormItem } from "src/components/UiKit/FormItem";
import { SPopconfirm } from "src/components/UiKit/PopConfirm";
import { SDatePicker } from "src/components/UiKit/DatePicker";
import { VoyageInfoStyle } from "./styles";
import SubmitButton from "../SubmitButton";
import { useNoonReportCTX } from "src/modules/NoonReport/context";
import { useVoyageInfo } from "./context";
import { CurrentSituation, ShipStatus } from "src/models/enums";
import usePublicApiStore from "src/store/publicApi";

const { Option } = Select;

export const VoyageForm: FC = () => {
  const [submittable, setSubmittable] = useState<boolean>(false);
  const { ships } = usePublicApiStore();
  const {
    value: { mode },
  } = useNoonReportCTX();

  const {
    value: { loading },
    func: { onFinishFlowInfo },
    forms: { voyageForm },
  } = useVoyageInfo();

  return (
    <Form form={voyageForm} onFinish={onFinishFlowInfo}>
      <VoyageInfoStyle gutter={[32, 24]}>
        <Col span={24}>
          <div className="titleHolder pt-[16px]">
            Please insert vessel’s report information.
          </div>
        </Col>
        <Col span={24} className="flex gap-[24px] pt-[16px] h-[103px]">
          <FormItem
            name={"vessel"}
            label="Vessel name"
            rules={[{ required: true }]}
            className="w-[33%] !mb-0"
          >
            <Select
              allowClear
              style={{ width: "100%" }}
              placeholder="select"
              optionFilterProp="children"
              showSearch
              className="selectOption"
            >
              {ships?.data?.map((ship) => (
                <Option value={ship.id}>{ship.name}</Option>
              ))}
            </Select>
          </FormItem>

          <FormItem
            name={"voyage"}
            label="Voyage"
            rules={[{ required: true }]}
            className="w-[33%] !mb-0"
          >
            <Select
              allowClear
              style={{ width: "100%" }}
              placeholder="select"
              optionFilterProp="children"
              showSearch
              className="selectOption"
            >
              <Option value={0}>No voyage</Option>
            </Select>
          </FormItem>
          <FormItem
            name={"date"}
            label="Date"
            rules={[{ required: true }]}
            className="w-[33%] !mb-0"
            initialValue={dayjs()}
          >
            <SDatePicker className="w-[100%]" />
          </FormItem>
        </Col>
        <Col span={24} className="flex gap-[24px] items-center">
          <FormItem name={"cargo"} label="Cargo" className="w-[32%] !mb-0">
            <Select
              allowClear
              style={{ width: "100%" }}
              placeholder="select"
              optionLabelProp="label"
              showSearch
              mode="multiple"
              className="selectOption"
            ></Select>
          </FormItem>
          <FormItem
            name={"VesselStatus"}
            label="Vessel status"
            className="w-[32%] !mb-0"
          >
            <Select
              allowClear
              style={{ width: "100%" }}
              placeholder="select"
              optionLabelProp="label"
              showSearch
              className="selectOption"
            >
              <Option value={ShipStatus.Laden}>Laden</Option>
              <Option value={ShipStatus.Ballast}>Ballast</Option>
            </Select>
          </FormItem>
          <FormItem
            name={"CurrentSituation"}
            label="Current situation"
            className="w-[32%] !mb-0"
          >
            <Select
              allowClear
              style={{ width: "100%" }}
              placeholder="select"
              optionLabelProp="label"
              className="selectOption"
            >
              <Option value={CurrentSituation.Sailing}>Sailing</Option>
              <Option value={CurrentSituation.Drifting}>Drifting</Option>
              <Option value={CurrentSituation.Loading}>Loading</Option>
              <Option value={CurrentSituation.Discharging}>Discharging</Option>
              <Option value={CurrentSituation.Anchorage}>Anchorage</Option>
              <Option value={CurrentSituation.DryDock}>DryDock</Option>
            </Select>
          </FormItem>
        </Col>

        <Col span={24}>
          <div className=" flex justify-end gap-[16px] footer">
            {mode === "create" && (
              <SPopconfirm
                description="Are you sure you want to reset all the information you filled out?"
                onConfirm={() => {
                  voyageForm.resetFields();
                }}
              >
                <Button className="w-[100px]">Reset</Button>
              </SPopconfirm>
            )}

            <SubmitButton
              submittable={submittable}
              setSubmittable={setSubmittable}
              form={voyageForm}
              loading={loading}
              buttonTitle={mode === "create" ? "Next" : "Save edit"}
            />
          </div>
        </Col>
      </VoyageInfoStyle>
    </Form>
  );
};
