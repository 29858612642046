import { FC } from "react";
import { IFlowTabs } from "./models/tabs";
import { TabsContainer } from "./styles";
import { EditFlow } from "./components/EditFlow";
import { useFlowCTX } from "./context";
import { Tabs } from "antd";
import { TabsProps } from "antd/lib";
import { AddFlow } from "./components/AddFlow";
import { FlowTable } from "./components/FlowTable";
import { Guard } from "src/components/Guard";
import { AddWorkFlowPath } from "src/components/Guard/permissions";
import { WorkFlowPath } from "src/services/WorkFlow/guardPath";

export const Flow: FC = () => {
  const {
    value: { mode, activeKeyFlow },
    dispatch: { setActiveKeyFlow },
  } = useFlowCTX();
  const onChange = (key: string) => {
    setActiveKeyFlow(key);
  };
  const items: TabsProps["items"] = [
    {
      key: IFlowTabs.addNewFlow,
      label: (
        <Guard action={AddWorkFlowPath} multiPath>
          <span>
            <span className="material-icons text-[10px] p-[8px]">lens</span>
            Add new flow
          </span>
        </Guard>
      ),
      children: (
        <Guard action={AddWorkFlowPath} multiPath>
          <AddFlow />
        </Guard>
      ),
    },
    {
      key: IFlowTabs.flowTable,
      label: (
        <Guard action={WorkFlowPath}>
          <span>
            <span className="material-icons text-[10px] p-[10px]">lens</span>
            Flow table
          </span>
        </Guard>
      ),
      children: (
        <Guard action={WorkFlowPath}>
          <FlowTable />
        </Guard>
      ),
    },
  ];
  return mode === "edit" || mode === "view" ? (
    <EditFlow />
  ) : (
    <TabsContainer>
      <Tabs
        items={items}
        onChange={onChange}
        activeKey={activeKeyFlow}
        animated
      />
    </TabsContainer>
  );
};
