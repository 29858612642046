import { Select } from "antd";
import { styled } from "styled-components";

export const SelectStyle = styled(Select)`
  border-radius: 12px;
  min-height: 48px;
  .ant-select-disabled {
    background-color: red !important;

    .ant-select-selector {
      background-color: red !important;
    }
  }
  .ant-select,
  .ant-select-selector {
    background-color: #f4f7fe !important;
    line-height: 48px;
  }

  .ant-select,
  .ant-select-selector,
  .ant-select-focused .ant-select-selector,
  .ant-select-selector:focus,
  .ant-select-selector:active,
  .ant-select-open .ant-select-selector {
    border: 0 !important;
    box-shadow: none !important;
    outline: none !important;
  }
`;
