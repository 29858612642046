import { Button } from "antd";
import { styled } from "styled-components";

const buttonStyles = `
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
`;

export const ZipInfoButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px !important;
  font-weight: bold;
  .icon {
    font-size: 16px;
    color: #2c73fc;
    /* margin-right: 8px; */
  }
`;
export const ZipFileInfoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold !important;
  .text {
    color: #5d6881;
    font-family: DM Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: default;
  }
`;
export const CommentButton = styled.div`
  width: 100%;
  ${buttonStyles} .commentWrapper {
    ${buttonStyles}
    &:hover {
      background-color: #5d688130;
      cursor: pointer;
    }
    padding: 0;
    width: 40px;
    height: 40px;
  }

  .icon {
    color: #5d6881;
    font-size: 16px;
  }
`;

export const OperationButton = styled(Button)`
  ${buttonStyles}
  color: #2C73FC;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0em;
`;

export const FileButton = styled(Button)`
  ${buttonStyles}
  padding: 0;
  width: 40px;
  height: 40px;

  .icon {
    color: #5d6881;
    font-size: 16px;
  }
`;

export const AddInvoiceButton = styled(Button)`
  ${buttonStyles}
  color: #2C73FC;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  padding-inline: 4px !important;
`;

export const SeeInvoiceButton = styled(Button)`
  ${buttonStyles}
  color: #2C73FC;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0em;
  width: 20px;
  height: 20px;
  padding: 12px !important;
  .icon {
    color: #5d6881;
    font-size: 16px;
  }
`;
export const ZipTableContainer = styled.div`
  //padding-left: 24px;
  .ant-table-row-expand-icon {
    color: #5d6881;
    border-color: #5d6881;
    border-radius: 50%;
    font-size: 16px;
    font-weight: bold;
  }
  tr {
    th {
      text-align: center !important;
    }
    td {
      text-align: center !important;
      button {
        width: 100%;
      }
    }
  }
  tr {
    th::before {
      content: unset !important;
    }
  }
`;
