import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Row,
  Select,
  Skeleton,
  Space,
  message,
} from "antd";
import { FC, useEffect, useState } from "react";
import { useAddShip } from "src/modules/AddShips/context";
import { FormItem } from "src/components/UiKit/FormItem";
import { SInput } from "src/components/UiKit/Input";
import TextArea from "antd/es/input/TextArea";
import { IAddNewShip } from "src/modules/AddShips/models";
import { ShipManagementContainer } from "../../AddNewShip/styles";
import { ShipService } from "src/services/Ship/Ship.service";
import {
  IEditShipManagmentContract,
  IEditShipManagmentContractValues,
} from "src/services/Ship/models";
import { SPopconfirm } from "../../AddNewShip/components/popconfirm";
const { RangePicker } = DatePicker;

export const EditShipManagement: FC = () => {
  const {
    dispatch: { setActiveKeyAddShip, setIsAddCurrencyModalOpen },
    forms: { ShipManagementForm },
    value: {
      allCurrency,
      shipManagement,
      shipManagementInfo,
      shipManagementLoading,
    },
    func: {
      fetchAllCurrency,
      fetchShipManagement,
      fetchShipManagementContract,
      fetchShipInformation,
    },
  } = useAddShip()!;
  const [loading, setLoading] = useState<boolean>(false);
  const nextTab = () => {
    setActiveKeyAddShip(IAddNewShip.shipPrice);
  };
  const onFinish = async (values: IEditShipManagmentContractValues) => {
    if (!shipManagementInfo) {
      return;
    }
    setLoading(true);
    try {
      const reqBody: IEditShipManagmentContract = {
        budget: +values.budget,
        comment: values.comment,
        currencyId: values.currencyId,
        endDate:
          values.insuranceDate.length > 0
            ? values.insuranceDate[1].format("YYYY-MM-DD")
            : null,
        startDate:
          values.insuranceDate.length > 0
            ? values.insuranceDate[0].format("YYYY-MM-DD")
            : null,
        shipManagementId: values.shipManagementId,
      };
      const { EditShipManagmentContract } = new ShipService();
      const response = await EditShipManagmentContract(
        shipManagementInfo?.shipManagmentContractId,
        reqBody
      );
      console.log(response, "response");
      if (response && response.status === 200) {
        message.success("ShipManagment updated successfully");
        nextTab();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchAllCurrency();
    fetchShipManagement();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    fetchShipManagementContract();
  }, [fetchShipManagementContract]);

  if (shipManagementLoading) {
    return (
      <div className="flex gap-[32px] flex-col h-[500px]">
        <div className="flex flex-col gap-[42px]">
          <div className="flex gap-[90px] mt-[74px]">
            <Skeleton.Input
              block
              active
              style={{ height: 50, borderRadius: 10 }}
            />
            <Skeleton.Input
              block
              active
              style={{ height: 50, borderRadius: 10 }}
            />
            <Skeleton.Input
              block
              active
              style={{ height: 50, borderRadius: 10 }}
            />
          </div>

          <div className="flex gap-[90px] mt-5">
            <Skeleton.Input
              active
              style={{ height: 50, borderRadius: 10, width: 290 }}
            />
          </div>
        </div>
        <Skeleton.Input
          active
          size="large"
          block
          style={{ height: 150, borderRadius: 10 }}
        />
      </div>
    );
  }
  return (
    <Form
      form={ShipManagementForm}
      onFinish={onFinish}
      className="flex flex-col justify-between h-[620px]"
    >
      <ShipManagementContainer className="flex gap-[24px] content-start">
        <Col span={24}>
          <div className="titleHolder pt-[16px]">
            Please insert ship management information
          </div>
        </Col>
        <Col span={24} className="flex gap-[24px]">
          <FormItem
            name={"shipManagementId"}
            label="Ship Management"
            rules={[{ required: true }]}
            className="w-[33%] !mb-0"
          >
            <Select
              style={{ width: "100%" }}
              placeholder="select"
              optionLabelProp="label"
              showSearch
              className="selectOption"
              //   loading={shipLoading}
              options={shipManagement?.map((management) => ({
                label: management.name,
                value: management.id,
                title: management.acronym,
              }))}
              allowClear
            />
          </FormItem>

          <FormItem
            name="insuranceDate"
            label="Start - End contract date "
            className="w-[33%]"
            rules={[{ required: true }]}
          >
            <RangePicker className="w-full" />
          </FormItem>
          <FormItem
            name={"budget"}
            label="Ship management Budget in contract "
            rules={[{ required: true }]}
            className="w-[32%] !mb-0"
          >
            <SInput placeholder="insert" />
          </FormItem>
        </Col>
        <Col span={24} className="flex gap-[24px] items-center">
          <FormItem
            name={"currencyId"}
            label="Currency"
            rules={[{ required: true }]}
            className="w-[32%] !mb-0"
          >
            <Select
              style={{ width: "100%" }}
              placeholder="select"
              optionLabelProp="label"
              showSearch
              className="selectOption"
              dropdownRender={(menu) => (
                <>
                  <Space style={{ padding: "16px 8px 4px" }}>
                    <div
                      onClick={() => setIsAddCurrencyModalOpen(true)}
                      className="cursor-pointer flex justify-center items-center"
                    >
                      <span className="material-icons text-[16px] text-[#001377]">
                        add
                      </span>
                      <div className="addHolder">Add Currency</div>
                    </div>
                  </Space>
                  <Divider style={{ margin: "8px 0" }} />
                  {menu}
                </>
              )}
              options={allCurrency.map((currency) => ({
                label: currency.shortName,
                value: currency.id,
              }))}
            />
          </FormItem>
        </Col>
        <Col span={24}>
          <FormItem name="comment" label="Description">
            <TextArea
              placeholder="description"
              rows={4}
              style={{
                height: 150,
                resize: "none",
                borderRadius: 12,
                background: "#F4F7FE",
              }}
            />
          </FormItem>
        </Col>
      </ShipManagementContainer>

      <Row>
        <Col span={24}>
          <div className=" flex gap-[16px] footer justify-end">
            <SPopconfirm
              description="Are you sure you want to reset all the information you filled out?"
              onConfirm={() => {
                fetchShipManagementContract();
                fetchShipInformation();
              }}
            >
              <Button className="w-[100px]">Reset</Button>
            </SPopconfirm>
            <Button
              type="primary"
              className="w-[100px]"
              onClick={ShipManagementForm?.submit}
              loading={loading}
            >
              Save Edit
            </Button>
          </div>
        </Col>
      </Row>
    </Form>
  );
};
