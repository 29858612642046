import { Col, Row } from "antd";
import { FC, useEffect, useState } from "react";
import { ShowBasicInformationContainer } from "../style";
import { ShipInformationItems } from "./shipInformationItem";
import TextArea from "antd/es/input/TextArea";
import { IGetShipInsuranceInformation } from "src/services/Ship/models";
import { ShipService } from "src/services/Ship/Ship.service";
import { useAddShip } from "src/modules/AddShips/context";
import moment from "moment";
const initialInsuranceInfo: IGetShipInsuranceInformation = {
  shipInsuranceId: 1,
  insuranceCompany: "",
  startDate: "",
  endDate: "",
  price: 0,
  tagName: "",
  currencyId: 1,
  currencyName: "",
  created: "",
  comment: "",
};
export const ShowInsurances: FC = () => {
  const {
    value: { tableCurrentValue },
  } = useAddShip()!;
  const [activeTag, setActiveTag] = useState<number>(-1);
  const [currentData, setCurrentData] = useState<
    IGetShipInsuranceInformation[]
  >([]);
  const [tagValues, setTagValues] =
    useState<IGetShipInsuranceInformation>(initialInsuranceInfo);

  const onClickTag = (key: number) => {
    setActiveTag(key);
  };
  // const onChange = (id: number) => {
  //   getInsurances(id);
  // };
  const getInsurances = async (id: number) => {
    try {
      const { GetShipInsuranceInformation } = new ShipService();
      const response = await GetShipInsuranceInformation(id);
      if (response) {
        setCurrentData(response.data);
        if (response.data.length > 0) {
          onClickTag(0);
          // onChange(response.data[0].currencyId);
          setTagValues(response.data[0]);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    if (tableCurrentValue) getInsurances(tableCurrentValue.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableCurrentValue]);
  return (
    <ShowBasicInformationContainer>
      <Col span={24} className="titleHolder">
        insurances information
      </Col>
      <Col span={24} className="overflow-x-auto flex gap-[16px] pb-[16px]">
        {currentData.map((item, index) => (
          <span
            key={index}
            className={activeTag === index ? "spanActiveHolder" : "spanHolder"}
            onClick={() => {
              onClickTag(index);
              // onChange(item.currencyId);
              setTagValues(item);
            }}
          >
            {item.tagName}
          </span>
        ))}
      </Col>

      <Col span={24} className="dataHolder">
        <Row gutter={60} justify={"space-between"}>
          <Col span={12}>
            <ShipInformationItems
              name="Insurance company"
              value={tagValues.insuranceCompany}
            />
            <ShipInformationItems
              name="Insurance price"
              value={tagValues.price.toLocaleString("en-US")}
            />
            <ShipInformationItems
              name="Currency"
              value={tagValues.currencyName}
            />
          </Col>
          <Col span={12}>
            <ShipInformationItems
              name="Insurance start date"
              value={
                tagValues.startDate
                  ? moment(tagValues.startDate).format("YYYY-MM-DD")
                  : ""
              }
            />
            <ShipInformationItems
              name="Insurance end date"
              value={
                tagValues.endDate
                  ? moment(tagValues.endDate).format("YYYY-MM-DD")
                  : ""
              }
            />
          </Col>
          <Col
            span={24}
            className="flex flex-col gap-[8px] py-[16px] pt-[24px]"
          >
            <div className="text-[#6B7280] text-[14px] py-[16px]">
              description
            </div>
            <TextArea
              name="description"
              style={{
                height: 150,
                resize: "none",
                borderRadius: 12,
                background: "#F4F7FE",
              }}
              disabled
              value={tagValues.comment}
            />
          </Col>
        </Row>
      </Col>
    </ShowBasicInformationContainer>
  );
};
