import { Form, FormInstance, message } from "antd";
import {
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useContext,
  useState,
} from "react";
import { IAllPayer, INewPayer } from "src/services/Income/models";
import { IPayerTabs } from "../models";
import { IncomeService } from "src/services/Income/Income.service";

interface IPayerContext {
  value: {
    payerLoading: boolean;
    tableLoading: boolean;
    editLoading: boolean;
    tableData: IAllPayer[];
    payerId: number | undefined;
    editMode: boolean;
    editData: IAllPayer | undefined;
    activeKey: string;
  };

  dispatch: {
    setPayerLoading: Dispatch<SetStateAction<boolean>>;
    setTableLoading: Dispatch<SetStateAction<boolean>>;
    setEditLoading: Dispatch<SetStateAction<boolean>>;
    setTableData: Dispatch<SetStateAction<IAllPayer[]>>;
    setPayerId: Dispatch<SetStateAction<number | undefined>>;
    setEditMode: Dispatch<SetStateAction<boolean>>;
    setEditData: Dispatch<SetStateAction<IAllPayer | undefined>>;
    setACtiveKey: Dispatch<SetStateAction<string>>;
  };
  func: {
    getPayer: () => void;
    onFinish: (value: INewPayer) => void;
    onBack: () => void;
    onDelete: (id: number) => void;
  };
  form: { PayerForm: FormInstance<any> | undefined };
}

const defaultValueContext: IPayerContext = {
  value: {
    payerLoading: false,
    tableLoading: false,
    editLoading: false,
    tableData: [],
    payerId: undefined,
    editMode: false,
    editData: undefined,
    activeKey: "",
  },
  dispatch: {
    setPayerLoading: () => {},
    setTableLoading: () => {},
    setEditLoading: () => {},
    setTableData: () => {},
    setPayerId: () => {},
    setEditMode: () => {},
    setEditData: () => {},
    setACtiveKey: () => {},
  },
  func: {
    getPayer: () => {},
    onFinish: () => {},
    onBack: () => {},
    onDelete: () => {},
  },
  form: { PayerForm: undefined },
};

export const PayerContext = createContext<IPayerContext>(defaultValueContext);

export const PayerProvider: FC<PropsWithChildren> = ({ children }) => {
  //---------------------------form------------------------------

  const [PayerForm] = Form.useForm();

  //---------------------------state-----------------------------

  const [payerLoading, setPayerLoading] = useState<boolean>(false);

  const [tableLoading, setTableLoading] = useState<boolean>(false);

  const [editLoading, setEditLoading] = useState<boolean>(false);

  const [tableData, setTableData] = useState<IAllPayer[]>([]);

  const [payerId, setPayerId] = useState<number | undefined>(undefined);

  const [editMode, setEditMode] = useState<boolean>(false);

  const [editData, setEditData] = useState<IAllPayer | undefined>(undefined);

  const [activeKey, setACtiveKey] = useState<string>(
    IPayerTabs.addNewPayer || IPayerTabs.payerTable
  );

  //---------------------------func------------------------------

  const getPayer = async () => {
    try {
      setTableLoading(true);
      const { AllPayers } = new IncomeService();
      const response = await AllPayers();
      if (response && response.status === 200) {
        setTableData(response.data.records);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setTableLoading(false);
    }
  };

  const onFinish = useCallback(
    async (value: INewPayer) => {
      if (editMode && payerId) {
        try {
          setEditLoading(true);
          const { UpdatePayer } = new IncomeService();
          const response = await UpdatePayer(payerId, value);
          if (response && response.status === 200) {
            message.success("Edit payer completed successfully");
            setEditMode(false);
            setEditData(undefined);
            setPayerId(undefined);
            PayerForm?.resetFields();
            getPayer();
          }
        } catch (err) {
          console.log(err);
        } finally {
          setEditLoading(false);
        }
      } else {
        try {
          setPayerLoading(true);
          const { NewPayer } = new IncomeService();
          const response = await NewPayer(value);
          if (response && response.status === 200) {
            message.success("Payer created successfully");
            getPayer();
            PayerForm?.resetFields();
          }
        } catch (err) {
          console.log(err);
        } finally {
          setPayerLoading(false);
        }
      }
    },
    [PayerForm, editMode, payerId]
  );

  const onBack = () => {
    setEditMode(false);
    setPayerId(undefined);
    setEditData(undefined);
    setACtiveKey(IPayerTabs.payerTable);
    PayerForm?.resetFields();
  };

  const onDelete = useCallback(async (id: number) => {
    try {
      const { DeletePayer } = new IncomeService();
      const response = await DeletePayer(id);
      if (response && response.status === 200) {
        message.success("Payer deleted successfully");
        getPayer();
      }
    } catch (err) {
      console.log(err);
    }
  }, []);

  const valueCtx: IPayerContext = {
    value: {
      payerLoading,
      tableLoading,
      editLoading,
      tableData,
      payerId,
      editMode,
      editData,
      activeKey,
    },
    dispatch: {
      setPayerLoading,
      setTableLoading,
      setEditLoading,
      setTableData,
      setPayerId,
      setEditMode,
      setEditData,
      setACtiveKey,
    },
    func: { getPayer, onFinish, onBack, onDelete },
    form: { PayerForm },
  };

  return (
    <PayerContext.Provider value={valueCtx}>{children}</PayerContext.Provider>
  );
};

export const usePayer = () => useContext(PayerContext);
