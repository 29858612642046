import { FC } from "react";
import { Table } from "./components/Table";
import { NoonReportTableProvider } from "./context";

export const NoonReportTable: FC = () => {
  return (
    <NoonReportTableProvider>
      <Table />
    </NoonReportTableProvider>
  );
};
