import { FC, Suspense } from "react";
import { ShipManagement } from "src/modules/shipManagement";
import { ShipManagementProvider } from "src/modules/shipManagement/context";
import { PreLoading } from "src/routes/Loading";

export const ShipManagementPage: FC = () => {
  return (
    <Suspense fallback={<PreLoading />}>
      <ShipManagementProvider>
        <ShipManagement />
      </ShipManagementProvider>
    </Suspense>
  );
};
