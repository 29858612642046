import axios from "axios";
import { IEditDailyReport, INewDailyReport } from "./models/args.models";
import { DailyReportUrls } from "./urls";
import {
  INewAllShipDailyReportResult,
  IUnReadDate,
} from "./models/result.model";
import { IDataModel } from "../models";
import { Dayjs } from "dayjs";
import { checkActionPermission } from "src/helpers/checkPermissionFunc";
import * as GuardPaths from "./guardPath";
import { permissionLog } from "src/helpers/permissionLog";
export class DailyReportService {
  async NewDailyReport(data: INewDailyReport) {
    if (!checkActionPermission(GuardPaths.NewDailyReportPath)) {
      permissionLog(GuardPaths.NewDailyReportPath.path);
      return undefined;
    }
    try {
      const response = await axios.post<void>(
        DailyReportUrls.NewDailyReport,
        data
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
  async AllShipDailyReport(queries?: string) {
    if (!checkActionPermission(GuardPaths.AllShipDailyReportPath)) {
      permissionLog(GuardPaths.AllShipDailyReportPath.path);
      return undefined;
    }
    let endpoint: string = DailyReportUrls.AllShipDailyReport;
    if (queries) endpoint = endpoint.concat(`?${queries}`);
    try {
      const response = await axios.get<
        IDataModel<INewAllShipDailyReportResult[]>
      >(endpoint);
      return response;
    } catch (err) {
      console.log(err);
    }
  }
  async ConfirmDailyReport(id: number) {
    if (!checkActionPermission(GuardPaths.ConfirmDailyReportPath)) {
      permissionLog(GuardPaths.ConfirmDailyReportPath.path);
      return undefined;
    }
    try {
      const response = await axios.put<void>(
        DailyReportUrls.DailyReport.concat(
          `${id}${DailyReportUrls.ConfirmDailyReport}`
        )
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async RejectDailyReport(id: number) {
    if (!checkActionPermission(GuardPaths.RejectDailyReportPath)) {
      permissionLog(GuardPaths.RejectDailyReportPath.path);
      return undefined;
    }
    try {
      const response = await axios.put<void>(
        DailyReportUrls.DailyReport.concat(
          `${id}${DailyReportUrls.RejectDailyReport}`
        )
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async EditDailyReport(data: IEditDailyReport, id: number) {
    if (!checkActionPermission(GuardPaths.EditDailyReportPath)) {
      permissionLog(GuardPaths.EditDailyReportPath.path);
      return undefined;
    }
    try {
      const response = axios.put<void>(
        DailyReportUrls.DailyReport.concat(
          `${id}${DailyReportUrls.EditDailyReport}`
        ),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async DeleteDailyReport(id: number) {
    if (!checkActionPermission(GuardPaths.DeleteDailyReportPath)) {
      permissionLog(GuardPaths.DeleteDailyReportPath.path);
      return undefined;
    }
    try {
      const response = await axios.delete<void>(
        DailyReportUrls.DailyReport.concat(
          `${id}${DailyReportUrls.DeleteDailyReport}`
        )
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async UnreadDate(id: number) {
    if (!checkActionPermission(GuardPaths.UnreadDatePath)) {
      permissionLog(GuardPaths.UnreadDatePath.path);
      return undefined;
    }
    try {
      const response = axios.get<IUnReadDate[]>(
        DailyReportUrls.DailyReport.concat(
          id.toString().concat(DailyReportUrls.UnreadDate)
        )
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
  async ShipDailyReport(shipId: number, date: string) {
    if (!checkActionPermission(GuardPaths.ShipDailyReportPath)) {
      permissionLog(GuardPaths.ShipDailyReportPath.path);
      return undefined;
    }
    try {
      const response = axios.post<INewAllShipDailyReportResult>(
        DailyReportUrls.ShipDailyReport,
        {
          shipId,
          reportDate: date,
        }
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
  async GetOnAction(shipId: number, date: Dayjs) {
    if (!checkActionPermission(GuardPaths.GetOnActionPath)) {
      permissionLog(GuardPaths.GetOnActionPath.path);
      return undefined;
    }
    try {
      const endpoint = DailyReportUrls.GetOnAction.concat(
        `?ShipId=${shipId}&ReportDate=${date.format("YYYY-MM-DD")}`
      );
      const response = axios.get<string>(endpoint);
      return response;
    } catch (err) {
      console.log(err);
    }
  }
}
