import styled from "styled-components";

export const OwnerFooterStyle = styled.div`
  height: 76px;
  border-top: 1px solid var(--shadow, #dadeec);
  background: var(--5, #fafcff);
  display: flex;
  justify-content: space-between;
  padding-inline: 16px;
`;
