import { FC, Suspense } from "react";
import { NoonReport } from "src/modules/NoonReport";
import { NoonReportProvider } from "src/modules/NoonReport/context";
import { PreLoading } from "src/routes/Loading";

export const NoonReportPage: FC = () => {
  return (
    <Suspense fallback={<PreLoading />}>
      <NoonReportProvider>
        <NoonReport />
      </NoonReportProvider>
    </Suspense>
  );
};
