import { FC } from "react";
import useOwnerStore from "src/store/owner";
import { ShipDailyReportHeaderStyle } from "./style";
import { Divider } from "antd";
import { Link } from "react-router-dom";
import { ROUTES } from "src/models/enums";

export const ShipDailyReportDetailHeader: FC = () => {
  const { selectedShip } = useOwnerStore();
  return (
    <ShipDailyReportHeaderStyle align="center">
      <span className="ship-reports-header-name">{selectedShip?.name}</span>
      <Divider type="vertical" className="ship-report-header-divider" />
      <div className="ship-report-header-date">
        <Link
          to={ROUTES.ShipDailyReports}
          className="ship-reports-header-message"
        >
          View All Reports
        </Link>
      </div>
    </ShipDailyReportHeaderStyle>
  );
};
