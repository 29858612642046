import { FC } from "react";
import { TitleStyles } from "./styles";

export const Title: FC = () => {
  return (
    <TitleStyles className="flex items-center justify-items-start">
      <span className="material-symbols-outlined">table_chart</span>
      <span className="text"> New Invoices Table </span>
    </TitleStyles>
  );
};
