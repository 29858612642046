import styled from "styled-components";

export const HeaderContainer = styled.div`
  .main-tabs .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab {
    color: var(--text-2, #a3aed0);
    font-family: DM Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 160% */
    padding: unset;
  }
  .main-tabs
    .ant-tabs-nav
    .ant-tabs-nav-wrap
    .ant-tabs-nav-list
    .ant-tabs-tab-active {
    color: var(--text-2, #2c73fc);
  }
  .ant-tabs-nav::before {
    border-bottom: 1px solid var(--shadow, #dadeec);
    padding: 24px;
  }

  .ant-tabs-nav {
    padding-bottom: 24px;
    margin-bottom: 24px !important;
  }

  .ant-tabs-tabpane-active {
  }
  .create-edit-tabs
    .ant-tabs-nav
    .ant-tabs-nav-wrap
    .ant-tabs-nav-list
    .ant-tabs-tab {
    color: var(--text-2, #95b9fe);
    font-family: DM Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: unset;
  }
  .create-edit-tabs
    .ant-tabs-nav
    .ant-tabs-nav-wrap
    .ant-tabs-nav-list
    .ant-tabs-tab-active {
    color: var(--text-2, #2c73fc);
  }
  .title {
    color: var(--text, #001377);
    font-family: DM Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .main-tabs .ant-tabs-ink-bar {
    height: unset !important;
  }
`;
