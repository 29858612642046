import { Row } from "antd";
import styled from "styled-components";

export const AddNewAgentTabs = styled.div`
  /* height: 766px; */
  /* width: 1076px; */
  border-radius: 20px;
  background: var(--0, #fff);
  box-shadow: 0px 4px 6px 0px rgba(113, 130, 183, 0.25);
  padding: 24px 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  display: flex;
  gap: 24px;

  .ant-tabs-nav {
    height: 60px;
    padding-bottom: unset !important;
    margin: 0px !important;
    border-bottom: 1px solid var(--color-Light-Mode-Border-3, #dadeec);
  }
  .footer {
    border-top: 1px solid var(--shadow, #dadeec);
    padding-top: 24px;
    margin-top: 24px;
  }
  .ant-tabs-tab {
    color: var(--text-2, #a3aed0);
    font-family: DM Sans;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: unset;
  }
  .ant-tabs-ink-bar {
    height: 2px !important;
  }
  .ant-tabs-nav-wrap {
    display: flex !important;
    justify-content: space-around !important;
  }
  .middle {
    display: flex;
    /* padding-bottom: 16px; */
    justify-content: space-between;
    align-items: flex-end;
    align-self: stretch;
    gap: 41px;
    margin-bottom: 24px;
    .br {
      border-radius: 8px;
      border: 1px solid var(--color-Primary-Primary-600, #2c73fc);
    }
  }
  .KNnDb {
    padding: 0px !important;
  }
  p {
    margin: 0px !important;
  }
`;

export const AgentsTableContainer = styled(Row)`
  /* width: 1130px; */
  /* padding: 24px; */
  border-radius: 16px;
  background: var(--color-Base-Color-White, #fff);
`;

export const AgentsModalContainer = styled.div`
  tr {
    th {
      font-size: 13px;
    }
    th::before {
      content: unset !important;
    }
  }
  .invoices-table {
    tr > th {
      padding: 16px 14px !important;
      text-align: center !important;
    }
  }
`;

export const MobileModalTitle = styled.div`
  border-bottom: 1px solid var(--color-Stroke-footer-buttons, #dadeec);
  padding-bottom: 16px;
`;
