import { FC } from "react";
import { Flex } from "antd";
import { useDocumentation } from "../../context";

const DocumentationHeader: FC = () => {
  const {
    value: { selectedDoc },
  } = useDocumentation();
  return (
    <Flex vertical className="h-[39px]">
      <span className="text-[#001377] text-[18px] font-medium">
        Please upload ship’s documentation file
      </span>
      <span className="text-[#3E4556] text-[12px] font-medium">
        {selectedDoc?.name.concat(
          selectedDoc?.isDirectory ? "" : ".".concat(selectedDoc?.extention)
        )}
      </span>
    </Flex>
  );
};

export default DocumentationHeader;
