import { FC, useEffect } from "react";
import { ShowBasicInformationContainer } from "../style";
import { Col, Row } from "antd";
import { ShipInformationItems } from "./shipInformationItem";
import TextArea from "antd/es/input/TextArea";
import { useAddShip } from "src/modules/AddShips/context";

import moment from "moment";
export const ShowShipManagement: FC = () => {
  const {
    value: { shipManagementInfo },
    func: { fetchShipManagementContract },
  } = useAddShip()!;

  const date1 = moment(shipManagementInfo?.startDate);
  const date2 = moment(shipManagementInfo?.endDate);
  const diffDateDays = date2.diff(date1, "days");
  const diffDateYears = date2.diff(date1, "years");

  useEffect(() => {
    fetchShipManagementContract();
  }, [fetchShipManagementContract]);
  return (
    <ShowBasicInformationContainer>
      <Col span={24} className="titleHolder">
        Ship Management contract information
      </Col>

      <Col span={24} className="dataHolder">
        <Row gutter={60} justify={"space-between"}>
          <Col span={12}>
            <ShipInformationItems
              name="Ship Management"
              value={shipManagementInfo?.shipManagement}
            />
            <ShipInformationItems
              name="Ship management Budget in contract"
              value={shipManagementInfo?.budget}
            />
            <ShipInformationItems
              name="End contract date"
              value={
                shipManagementInfo?.endDate
                  ? moment(shipManagementInfo.endDate).format("YYYY-MM-DD")
                  : undefined
              }
            />
          </Col>
          <Col span={12}>
            <ShipInformationItems
              name="Start contract date"
              value={
                shipManagementInfo?.startDate
                  ? moment(shipManagementInfo.startDate).format("YYYY-MM-DD")
                  : undefined
              }
            />
            <ShipInformationItems
              name="Currency"
              value={shipManagementInfo?.currency}
            />
            <ShipInformationItems
              name="Time left on the contract"
              value={
                diffDateYears > 0
                  ? diffDateYears + " Years" + diffDateDays + " days "
                  : diffDateDays + " days "
              }
            />
          </Col>
          <Col span={24} className="flex flex-col gap-[8px] pt-[24px]">
            <div className="text-[#6B7280] text-[14px] pt-[16px]">
              description
            </div>
            <TextArea
              name="description"
              style={{
                height: 163,
                resize: "none",
                borderRadius: 12,
                background: "#F4F7FE",
              }}
              disabled
              value={shipManagementInfo?.comment}
            />
          </Col>
        </Row>
      </Col>
    </ShowBasicInformationContainer>
  );
};
