import {
  FC,
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { Form, message } from "antd";
import { FormInstance } from "antd/lib";
import { useAddNewNoonReportCTX } from "../../context";
import { NewNoonReportTabs } from "../../model/newNoonReportTabs";
import { useNoonReportCTX } from "src/modules/NoonReport/context";
import { NoonReportService } from "src/services/NoonReport/NoonReport.service";
import { IOtherInfo, IOtherInfoArgs } from "src/services/NoonReport/models";

interface IContext {
  value: {
    loading: boolean;
    otherIfo: IOtherInfo | undefined;
    dataLoading: boolean;
  };
  dispatch: {};
  func: {
    onFinishInfo: (values: any) => void;
  };
  forms: {
    formName: FormInstance;
  };
}

export const OthersInfoCTX = createContext<IContext | undefined>(undefined);

export const OthersInfoProvider: FC<PropsWithChildren> = ({ children }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [otherIfo, setOtherInfo] = useState<IOtherInfo | undefined>(undefined);
  const [dataLoading, setDataLoading] = useState<boolean>(false);

  const {
    value: { selectedReport, mode },
    dispatch: { setSelectedReport },
  } = useNoonReportCTX();

  const {
    dispatch: { setActiveTab },
  } = useAddNewNoonReportCTX();

  const [formName] = Form.useForm();

  const onFinishInfo = async (values: IOtherInfoArgs) => {
    if (!selectedReport) return;
    setLoading(false);
    try {
      const { OtherInfo } = new NoonReportService();
      const response = await OtherInfo(selectedReport.id, values);
      if (response && response.status === 200) {
        message.success("FW Info successfully registered.");
        if (mode === "create") {
          setSelectedReport(undefined);
          setActiveTab(NewNoonReportTabs.Voyage);
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const setFormFields = useCallback(() => {
    if (otherIfo)
      formName.setFieldsValue({
        windForce: otherIfo.windForce,
        seaSwell: otherIfo.seaSwell,
        airPressureTemp: otherIfo.airPressureTemp,
        Course: otherIfo.Course,
        RPM: otherIfo.RPM,
        SLIP: otherIfo.SLIP,
        primaryKey: otherIfo.primaryKey,
        Remark: otherIfo.Remark,
      });
  }, [formName, otherIfo]);

  const getData = useCallback(async () => {
    if (!selectedReport) return;
    setDataLoading(true);
    try {
      const { GetOtherInfo } = new NoonReportService();
      const response = await GetOtherInfo(selectedReport.id);
      if (response && response.status === 200) {
        setOtherInfo(response.data);
        setFormFields();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setDataLoading(false);
    }
  }, [selectedReport, setFormFields]);

  useEffect(() => {
    if (mode === "edit") getData();
  }, [getData, mode]);

  const contextValue: IContext = {
    value: { loading, otherIfo, dataLoading },
    dispatch: {},
    func: {
      onFinishInfo,
    },
    forms: {
      formName,
    },
  };

  return (
    <OthersInfoCTX.Provider value={contextValue}>
      {children}
    </OthersInfoCTX.Provider>
  );
};

export const useOthersInfo = () => useContext(OthersInfoCTX)!;
