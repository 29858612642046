import { FC } from "react";
import { FOMGOROBInfoForm } from "./components/FOMGOROBInfoForm";
import FOMGOROBTable from "./components/FOMGOROBTable";
import { FOMGOROBInfoProvider } from "./context";
import { FOMGOROBInfoStyle } from "./styles";

export const FOMGOROBInfo: FC = () => {
  return (
    <FOMGOROBInfoProvider>
      <FOMGOROBInfoStyle gutter={[32, 24]}>
        <FOMGOROBInfoForm />
        <FOMGOROBTable />
      </FOMGOROBInfoStyle>
    </FOMGOROBInfoProvider>
  );
};
