import { ColumnsType } from "antd/es/table";
import { FC, useEffect } from "react";
import { AgentsTableContainer } from "../style";
import { Col, Popconfirm, message } from "antd";
import { TableUI } from "src/components/UiKit/table";
import { EmailModal } from "./agentsModals/emailModal";
import { PhoneModal } from "./agentsModals/phoneModal";
import { ServicesModal } from "./agentsModals/servicesModal";
import { UseAgents } from "../context";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "src/models/enums";
import { IAllAgents } from "src/services/BaseInfo/models";
import { BaseInfoService } from "src/services/BaseInfo/BaseInfo.service";
import { getRowKey } from "src/helpers/getRowKey";
import { Guard } from "src/components/Guard";
import {
  AllAgentsPath,
  DeleteAgentPath,
  UpdateAgentPath,
} from "src/services/BaseInfo/guardPath";

export const AgentsTable: FC = () => {
  const {
    value: { pagination, dataSource, tableLoading },
    dispatch: { setPagination, setCurrentAgentId, setDescription },
    func: {
      openModalService,
      openModalEmail,
      openModalPhone,
      getAgentTableData,
    },
  } = UseAgents();

  const Navigate = useNavigate();

  const onView = (record: IAllAgents) => {
    Navigate(ROUTES.AgentView, { state: record });
  };

  const onEdit = (record: IAllAgents) => {
    Navigate(ROUTES.AgentEdit, { state: record });
  };
  const onDelete = async (id: number) => {
    try {
      const { DeleteAgent } = new BaseInfoService();
      const response = await DeleteAgent(id);
      if (response && response.status === 200) {
        message.success("Successfully deleted");
        getAgentTableData();
      }
    } catch (err) {
      console.log(err);
    }
  };
  const columns: ColumnsType<IAllAgents> = [
    {
      title: "#",
      key: "id",
      align: "center",
      width: "61px",
      render: (name, record, index) =>
        (pagination.current - 1) * pagination.pageSize + (index + 1),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      align: "center",
      width: "121px",
    },
    {
      title: "Company",
      dataIndex: "company",
      key: "company",
      align: "center",
      width: "121px",
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
      align: "center",
      width: "121px",
    },
    {
      title: "Port",
      dataIndex: "port",
      key: "port",
      align: "center",
      width: "121px",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      align: "center",
      width: "121px",
      render: (text, record) => (
        <span
          onClick={() => {
            setCurrentAgentId(record);
            openModalPhone();
          }}
          className="material-icons !text-[#5D6881] text-[16px] cursor-pointer"
        >
          call
        </span>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      align: "center",
      width: "121px",
      render: (text, record) => (
        <span
          onClick={() => {
            setCurrentAgentId(record);
            openModalEmail();
          }}
          className="material-icons !text-[#5D6881] text-[16px] cursor-pointer"
        >
          alternate_email
        </span>
      ),
    },
    {
      title: "Services",
      dataIndex: "description",
      key: "description",
      align: "center",
      width: "121px",
      render: (text, record) => (
        <span
          onClick={() => {
            setDescription(record);
            openModalService();
          }}
          className="material-icons !text-[#5D6881] text-[16px] cursor-pointer"
        >
          chat_bubble_outline
        </span>
      ),
    },
    {
      title: "Tools",
      key: "tools",
      align: "center",
      width: "178px",
      render: (text, record) => (
        <div className="flex justify-center items-center gap-[16px] text-[16px]">
          <Guard action={UpdateAgentPath}>
            <span
              onClick={() => {
                onEdit(record);
              }}
              className="material-icons !text-[#0C9D61] text-[16px] cursor-pointer"
            >
              edit_note
            </span>
          </Guard>
          <Guard action={AllAgentsPath}>
            <span
              onClick={() => {
                onView(record);
              }}
              className="material-icons !text-[#001377] text-[16px] cursor-pointer"
            >
              visibility
            </span>
          </Guard>
          <Guard action={DeleteAgentPath}>
            <Popconfirm
              title="Delete phone number"
              onConfirm={async () => {
                await onDelete(record.id);
              }}
              description="Are you want to delete this?"
            >
              <span className="material-icons !text-[#EC2D30] text-[16px] cursor-pointer">
                delete_forever
              </span>
            </Popconfirm>
          </Guard>
        </div>
      ),
    },
  ];

  useEffect(() => {
    getAgentTableData();
  }, [getAgentTableData]);
  return (
    <AgentsTableContainer>
      <Col span={24}>
        <Guard action={AllAgentsPath}>
          <TableUI
            columns={columns}
            dataSource={dataSource}
            loading={tableLoading}
            rowKey={getRowKey}
            // scroll={{ x: 1028 }}
            pagination={{
              position: ["bottomCenter"],
              showSizeChanger: true,
              total: pagination.total,
              current: pagination.current,
              pageSize: pagination.pageSize,
              onChange: (page: number, pageSize: number) => {
                setPagination((prev) => ({
                  ...prev,
                  current: pageSize !== prev.pageSize ? 1 : page,
                  pageSize,
                }));
              },
            }}
          />
        </Guard>
        <ServicesModal />
        <EmailModal />
        <PhoneModal />
      </Col>
    </AgentsTableContainer>
  );
};
