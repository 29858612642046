import { ReactElement, ReactNode } from "react";
import { IGetSupplier } from "src/services/System/models";

export enum ISupplierTabs {
  addNewSupplier = "1",
  supplierTable = "2",
}

export interface ISupplierData {
  id: number;
  name: string;
  website: string;
  category: string;
  phoneNumber: number;
  email: string;
  country: string;
  city: string;
  address: string;
  tools: ReactElement;
  action: ReactElement;
}
export interface TableData extends IGetSupplier {
  // "#": number;
  ship?: string;
  tools: ReactNode;
}
