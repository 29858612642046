import { Flex } from "antd";
import styled from "styled-components";

export const ShipDailyReportSearchStyle = styled(Flex)`
  height: 64px;
  background-color: #f2f7ff;
  padding: 12px 16px;

  .search-title {
    color: #000;
    font-weight: 700;
    font-size: 16px;
  }
  .search-input {
    width: 235px;
  }
  .input {
    height: 46px;
    .ant-select-selector {
      border-radius: 12px !important;
    }
  }
`;
