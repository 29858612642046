import { FC } from "react";
import { ViewNoonReportProvider } from "./context";
import { ViewDetails } from "./components/ViewDetails";

export const ViewNoonReport: FC = () => {
  return (
    <ViewNoonReportProvider>
      <ViewDetails />
    </ViewNoonReportProvider>
  );
};
