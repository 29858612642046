import { FC } from "react";
import { Col, Divider } from "antd";
import { DocumentationContainer } from "./styles";
import DocumentationHeader from "./components/header";
import DocumentationActions from "./components/actions";
import FoldersFiles from "./components/foldersFiles";

export const Documentation: FC = () => {
  return (
    <>
      <Divider />
      <DocumentationContainer justify="center">
        <Col span={23} className="main-col">
          <DocumentationHeader />
          <div className="shadow">
            <DocumentationActions />
            <FoldersFiles />
          </div>
        </Col>
      </DocumentationContainer>
    </>
  );
};
