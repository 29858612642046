export enum ICategoryTabs {
  addCategory = "1",
  categoriesTable = "2",
}

export enum FrontPathType {
  Page = 1,
  Modal = 2,
}

export interface ICategoryParams {
  Name?: string;
  Code?: string;
}
