import { Tabs, TabsProps } from "antd";
import { FC } from "react";
import { IAddNewShip, IAddShipsTabs } from "./models";
import { AddShipsContainer } from "./styles";
import { AddNewShip } from "./components/AddNewShip";
import { useAddShip } from "./context";
import { EditShip } from "./components/EditShip";
import { ShipTable } from "./components/shipsTable";
import { ShowShip } from "./components/ShowShip";
import { Guard } from "src/components/Guard";
import { AddShipsPath } from "src/components/Guard/permissions";

export const AddShips: FC = () => {
  const {
    value: { isEditMode, isShowMode, activeKeyAddNewShip },
    dispatch: {
      setActiveKeyAddNewShip,
      setActiveKeyAddShip,
      setPictureFileList,
    },
  } = useAddShip()!;
  const items: TabsProps["items"] = [
    {
      key: IAddShipsTabs.addNewShip,
      label: (
        <Guard action={AddShipsPath} multiPath>
          <span>
            <span className="material-icons text-[10px] p-[8px]">lens</span>
            Add new ship
          </span>
        </Guard>
      ),
      children: (
        <Guard action={AddShipsPath} multiPath>
          <AddNewShip />
        </Guard>
      ),
    },
    {
      key: IAddShipsTabs.shipsTable,
      label: (
        <span>
          <span className="material-icons text-[10px] p-[10px]">lens</span>
          Ships table
        </span>
      ),
      children: <ShipTable />,
    },
  ];

  const onChange = (key: string) => {
    setActiveKeyAddNewShip(key);
    setActiveKeyAddShip(IAddNewShip.basicInformation);
    setPictureFileList([]);
  };

  return isEditMode ? (
    <EditShip />
  ) : isShowMode ? (
    <ShowShip />
  ) : (
    <AddShipsContainer>
      <Tabs items={items} onChange={onChange} activeKey={activeKeyAddNewShip} />
    </AddShipsContainer>
  );
};
