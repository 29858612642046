import { FC, useState } from "react";
import { Button, Col, Form, Spin } from "antd";
import TextArea from "antd/es/input/TextArea";
import { FormItem } from "src/components/UiKit/FormItem";
import { SPopconfirm } from "src/components/UiKit/PopConfirm";
import { SInput } from "src/components/UiKit/Input";
import { useNoonReportCTX } from "src/modules/NoonReport/context";
import { OthersInfoStyle } from "./styles";
import SubmitButton from "../SubmitButton";
import { useOthersInfo } from "./context";
import { NewNoonReportTabs } from "../../model/newNoonReportTabs";
import { useAddNewNoonReportCTX } from "../../context";

export const OthersInfoForm: FC = () => {
  const [submittable, setSubmittable] = useState<boolean>(false);

  const {
    value: { mode },
  } = useNoonReportCTX();

  const {
    value: { loading, dataLoading },
    func: { onFinishInfo },
    forms: { formName },
  } = useOthersInfo();

  const {
    dispatch: { setActiveTab },
  } = useAddNewNoonReportCTX();

  return (
    <Form form={formName} onFinish={onFinishInfo}>
      <OthersInfoStyle gutter={[32, 24]}>
        {dataLoading ? (
          <div className="w-full h-full flex items-center justify-center">
            <Spin size="large" />
          </div>
        ) : (
          <>
            <Col span={24}>
              <div className="titleHolder pt-[16px]">
                Please enter the information requested.
              </div>
            </Col>
            <Col span={24} className="flex gap-[24px] pt-[16px] h-[103px]">
              <FormItem
                name={"windForce"}
                label="Wind/Force"
                className="w-[33%] !mb-0"
              >
                <SInput placeholder="Insert" />
              </FormItem>

              <FormItem
                name={"seaSwell"}
                label="Sea/swell"
                className="w-[33%] !mb-0"
              >
                <SInput placeholder="Insert" />
              </FormItem>
              <FormItem
                name={"airPressureTemp"}
                label="Air pressure/temp"
                className="w-[33%] !mb-0"
              >
                <SInput placeholder="Insert" />
              </FormItem>
            </Col>

            <Col span={24} className="flex gap-[24px] pt-[16px] h-[103px]">
              <FormItem
                name={"Course"}
                label="Course"
                className="w-[33%] !mb-0"
              >
                <SInput placeholder="Insert" />
              </FormItem>
              <FormItem name={"RPM"} label="RPM" className="w-[33%] !mb-0">
                <SInput placeholder="Insert" />
              </FormItem>
              <FormItem name={"SLIP"} label="SLIP" className="w-[33%] !mb-0">
                <SInput placeholder="Insert" />
              </FormItem>
            </Col>

            <Col span={24} className="flex gap-[24px] pt-[16px] h-[103px]">
              <FormItem
                name={"primaryKey"}
                label="Primary key"
                className="w-[33%] !mb-0"
              >
                <SInput placeholder="Insert" />
              </FormItem>
            </Col>

            <Col span={24} className="">
              <FormItem name={"Remark"} label="Remark" className="w-full !mb-0">
                <TextArea
                  placeholder="Type description"
                  rows={10}
                  className="textarea"
                  autoSize={{ maxRows: 10, minRows: 10 }}
                />
              </FormItem>
            </Col>

            <Col span={24}>
              <div className=" flex justify-between gap-[16px] footer">
                <div>
                  {mode === "create" && (
                    <Button
                      className="w-[100px]"
                      onClick={() => setActiveTab(NewNoonReportTabs.Speed)}
                    >
                      Back
                    </Button>
                  )}
                </div>
                <div>
                  {mode === "create" && (
                    <SPopconfirm
                      description="Are you sure you want to reset all the information you filled out?"
                      onConfirm={() => {
                        formName.resetFields();
                      }}
                    >
                      <Button className="w-[100px] mr-4">Reset</Button>
                    </SPopconfirm>
                  )}
                  <SubmitButton
                    submittable={submittable}
                    setSubmittable={setSubmittable}
                    form={formName}
                    loading={loading}
                    buttonTitle={mode === "create" ? "Finish" : "Save edit"}
                  />
                </div>
              </div>
            </Col>
          </>
        )}
      </OthersInfoStyle>
    </Form>
  );
};
