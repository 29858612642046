import { Tabs, TabsProps } from "antd";
import { useSearchParams } from "react-router-dom";
import { FC } from "react";
import { TabsContainer } from "src/modules/Category/styles";
import { IChartererTabs } from "../models";
import { AddNewCharterer } from "./addCharterer";
import { ChartererTable } from "./chartererTable";

const item: TabsProps["items"] = [
  {
    key: IChartererTabs.addNewCharterer,
    label: (
      <span>
        <span className="material-icons text-[10px] p-[8px]">lens</span>
        Add new charterer
      </span>
    ),
    children: <AddNewCharterer />,
  },
  {
    key: IChartererTabs.chartererTable,
    label: (
      <span>
        <span className="material-icons text-[10px] p-[10px]">lens</span>
        Charterer table
      </span>
    ),
    children: <ChartererTable />,
  },
];

export const ChartererTabs: FC = () => {
  const [params] = useSearchParams();
  const defaultKey =
    params.get("table") === "true"
      ? IChartererTabs.chartererTable
      : IChartererTabs.addNewCharterer;
  return (
    <TabsContainer>
      <Tabs defaultActiveKey={defaultKey} items={item} />
    </TabsContainer>
  );
};
