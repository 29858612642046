import { FC, Suspense } from "react";
import Table from "src/modules/Table";
import { PreLoading } from "src/routes/Loading";

export const TablePage: FC = () => {
  return (
    <Suspense fallback={<PreLoading />}>
      <Table />
    </Suspense>
  );
};
