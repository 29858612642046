import { Row } from "antd";
import styled from "styled-components";

export const StepListContainer = styled(Row)`
  height: calc(100% - 24px);
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      color: #001377;
      font-family: DM Sans;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    .stepCount {
      color: var(--text-3, #5d6881);
      font-family: DM Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  .wrapper {
    height: calc(100% - 55px);
  }
  .content {
    border-radius: 16px;
    border: 2px solid var(--shadow, #dadeec);
    height: 760px;
    overflow-y: auto;
    padding: 24px;
    .noItem {
      color: var(--text-2, #a3aed0);
      text-align: center;
      font-family: DM Sans;
      font-size: 32px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      justify-content: center;
      align-items: center;
      display: flex;
      height: 100%;
    }
  }
`;
export const StepsCardStyle = styled.div<{
  $selected: boolean;
}>`
  display: flex;
  padding: 12px;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
  user-select: none;
  border: ${({ $selected }) =>
    $selected
      ? " 2px solid var(--2, #2C73FC)"
      : " 1px solid var(--shadow, #dadeec)"};
  margin-top: 12px;
  background: ${({ $selected }) => ($selected ? "#F2F7FF" : "#ffffff")};
  &:first-child {
    margin-top: 0;
  }
  &::before {
    content: "";
    padding: 2px;
    border-radius: 16px;
    background: var(--shadow, #dadeec);
    display: block;
    display: flex;
    align-self: stretch;
  }
  .wrapper {
    padding-left: 12px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
  }
  .name-status {
    display: flex;
    justify-content: flex-start;
  }
  .stepName {
    padding-right: 8px;
    color: var(--text, #001377);
    font-family: DM Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .status {
    padding-left: 8px;
    color: var(--text-3, #5d6881);
    font-family: DM Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-left: 1px solid #dadeec;
  }
  .description {
    display: flex;
    justify-content: space-between;
    gap: 12px;
  }
  .des-text {
    color: var(--text-2, #a3aed0);
    font-family: DM Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: flex;
    align-items: center;
    gap: 4px;
  }
  .rolesHolder {
    border-radius: 4px;
    background: var(--table, #dbe9ff);
    color: var(--text, #001377);
    font-family: DM Sans;
    font-size: 9px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    padding: 4px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    min-width: 47px;
  }
  .actionsHolder {
    border-radius: 4px;
    background: var(--BUY, #01c18f);
    color: var(--0, #fff);
    font-family: DM Sans;
    font-size: 9px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    padding: 4px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    min-width: 47px;
  }
`;
