import { FC } from "react";
import { styled } from "styled-components";

export interface IProps {
  title: string;
  onclose: () => void;
}
export const STag: FC<IProps> = ({ title, onclose }) => {
  return (
    <STagStyle>
      <div className="titleHolder">{title}</div>
      <span
        onClick={onclose}
        className="material-icons text-[14px] text-[#2C73FC] cursor-pointer"
      >
        close
      </span>
    </STagStyle>
  );
};

export const STagStyle = styled.div`
  display: flex;
  padding: 8px 12px;
  align-items: center;
  gap: 12px;
  border-radius: 8px;
  border: 1px solid var(--2, #2c73fc);
  min-width: auto !important;
  height: 32px;
  padding: 8px 12px;

  .titleHolder {
    color: var(--2, #2c73fc);
    font-family: DM Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    white-space: nowrap;
  }
`;
