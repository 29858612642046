import styled from "styled-components";

export const ShipDailyReportShipsStyle = styled.div`
  height: calc(100vh - 72px - 64px);
  background: var(--3, #f2f7ff);
  overflow-y: auto;
  padding: 24px 16px;
  border-top: solid 1px #dadeec;

  .ship-col {
    padding: 8px 8px 12px 8px;
  }
`;
