import { Row } from "antd";
import styled from "styled-components";

export const DocumentationContainer = styled(Row)`
  .ant-col.main-col {
    background-color: #fff;
    border-radius: 20px;
    min-height: 701px;
    padding: 24px 16px;
  }
  .shadow {
    padding: 24px 16px;
    border-radius: 16px;
    box-shadow: 0 4px 8px 0 #dadeec, 0 6px 20px 0 #dadeec;
    margin-top: 20px;
  }
  .ant-upload-wrapper,
  .ant-upload {
    border: none !important;
    background-color: transparent !important;
  }
  .doc-upload-btn:hover {
    background-color: transparent !important;
  }
`;

export const ModalFormWrapper = styled.div`
  .ant-form-item .ant-form-item-label > label.ant-form-item-required::before {
    display: none;
    content: "";
  }

  .create-file-name {
    border: dashed 1px #dbe2e6;
    border-radius: 8px;
    padding: 12px 16px;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
  }
  .file-img {
    width: 90%;
    height: 90%;
  }
  .ant-upload-wrapper.ant-upload-picture-card-wrapper
    .ant-upload.ant-upload-select,
  .ant-upload-wrapper.ant-upload-picture-circle-wrapper
    .ant-upload.ant-upload-select {
    width: 58px;
    height: 58px;
  }
  .ant-upload-wrapper {
    display: inline-block;
    width: 58px;
    height: 58px;
  }
`;
