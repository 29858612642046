import { Row } from "antd";
import { styled } from "styled-components";

export const DescriptionContainer = styled(Row)`
  margin-top: 8px;
  padding: 0 32px;
  .wrapper {
    border-radius: 8px;
    border: 1px dashed #dadeec;
    padding: 24px;
  }

  .title {
    color: #001377;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .ant-upload-wrapper {
    display: flex;
    justify-content: center;
  }
  .footer {
    border-top: 1px solid #dadeec;
    padding-top: 32px;
    padding-bottom: 16px;
  }
`;
