import { FC } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { OwnerFooter } from "./Footer/Footer";
import { ROUTES } from "src/models/enums";

export const OwnerLayout: FC = () => {
  const { pathname } = useLocation();
  return (
    <main className="overflow-hidden">
      <Outlet />
      {pathname !== ROUTES.home &&
        pathname !== ROUTES.ShipDailyReportOwner &&
        pathname !== ROUTES.ShipDailyReportShips &&
        pathname !== ROUTES.ShipDailyReportCalendar &&
        pathname !== ROUTES.ShipDailyReports &&
        pathname !== ROUTES.ShipDailyReportDetail && <OwnerFooter />}
    </main>
  );
};
