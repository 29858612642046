import { FC } from "react";
import { TabsProps } from "antd";
import { TableTabs } from "src/components/UiKit/table-tabs";
import { useTable } from "./context";
import { PdfFileInvoice, TopBar, ZipFiles } from "./components";
import { AllRequestZipAttachmentPath } from "src/services/Invoice/guardPath";

const Table: FC = () => {
  const {
    value: { tableState, zipFileTableColumns, expandLoading },
    dispatches: { setTableState },
    func: { onExpand },
  } = useTable();

  const tabs: TabsProps["items"] = [
    {
      key: "0",
      label: "Zip file invoice",
      children: (
        <div className="px-[24px]">
          <ZipFiles
            data={tableState.zipFiles}
            columns={zipFileTableColumns}
            onExpand={onExpand}
            expandLoading={expandLoading}
            expandedActions={AllRequestZipAttachmentPath}
          />
        </div>
      ),
    },
    {
      key: "1",
      label: "Pdf file invoice",
      children: (
        <div className="px-[24px]">
          <PdfFileInvoice loading={expandLoading} />
        </div>
      ),
      disabled: true,
    },
  ];

  const onChange = (key: string) => {
    setTableState((prevValue) => ({
      ...prevValue,
      activatedTabIndex: parseInt(key),
    }));
  };

  return (
    <>
      <TopBar />

      <TableTabs
        items={tabs}
        defaultActiveKey="0"
        activeKey={tableState.activatedTabIndex.toString()}
        onChange={onChange}
        centered
      />
    </>
  );
};
export default Table;
