import {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from "react";
import { NewNoonReportTabs } from "./model/newNoonReportTabs";
interface IContext {
  value: {
    activeTab: string;
  };
  dispatch: {
    setActiveTab: Dispatch<SetStateAction<string>>;
  };
  func: {
    reset: () => void;
  };
  forms: {};
}

const defaultContext: IContext = {
  value: {
    activeTab: NewNoonReportTabs.Voyage,
  },
  dispatch: {
    setActiveTab: () => {},
  },
  func: {
    reset: () => {},
  },
  forms: {},
};
export const AddNewNoonReportCTX = createContext<IContext>(defaultContext);
export const AddNewNoonReportProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const [activeTab, setActiveTab] = useState<string>(NewNoonReportTabs.Voyage);

  const reset = () => {
    setActiveTab(NewNoonReportTabs.Voyage);
  };

  const contextValue: IContext = {
    value: {
      activeTab,
    },
    dispatch: {
      setActiveTab,
    },
    func: {
      reset,
    },
    forms: {},
  };

  return (
    <AddNewNoonReportCTX.Provider value={contextValue}>
      {children}
    </AddNewNoonReportCTX.Provider>
  );
};

export const useAddNewNoonReportCTX = () => useContext(AddNewNoonReportCTX);
