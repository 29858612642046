import styled from "styled-components";

export const FlowTableContainer = styled.div`
  .ant-table-cell {
    text-align: center !important;
  }
`;

export const ToolsContainer = styled.div`
  display: flex;
  gap: 16px;
  justify-content: center;
  .material-icons {
    font-size: 16px;
    cursor: pointer;
    user-select: none;
  }
`;
