import { Checkbox, DatePicker, Input, Popconfirm, Space, Tabs } from "antd";
import type { GetProp } from "antd";
// import { CheckboxValueType } from "antd/es/checkbox/Group";
import { TabsProps } from "antd/lib";
import { ChangeEvent, FC, PropsWithChildren, useState } from "react";
import { DateContainer } from "src/modules/Report/styles";
import { TabStyle } from "./style";
import dayjs from "dayjs";
import { RangePickerProps } from "antd/es/date-picker";
import usePublicApiStore from "src/store/publicApi";
import { CurrentSituation } from "src/models/enums";
import { IShip } from "src/services/Public/models";
import { ShipTabContainer, VoyageTabContainer } from "../../styles";
import { useNoonReportCTX } from "src/modules/NoonReport/context";
import { useSearchParams } from "react-router-dom";
import { NoonReportService } from "src/services/NoonReport/NoonReport.service";
import _ from "lodash";
type CheckboxValueType = GetProp<typeof Checkbox.Group, "onChange">;
export const FilterPopUp: FC<PropsWithChildren> = ({ children }) => {
  const {
    value: { filterValue },
    dispatch: { setFilterValue, setPagination, setDataSource, setDataLoading },
    func: { getNoonReport },
  } = useNoonReportCTX();
  let [searchParams] = useSearchParams();
  const cancel = () => {
    setFilterValue({});
    getNoonReport();
  };

  const onFinishFilter = async () => {
    let filterSearchParams =
      searchParams.toString().length < 1 ? "?" : searchParams.toString();
    Object.keys(filterValue).forEach((key) => {
      if (_.isArray(filterValue[key])) {
        (filterValue[key] as number[]).forEach((item) => {
          filterSearchParams = filterSearchParams
            .concat(filterSearchParams === "?" ? "" : "&")
            .concat(`${key}=${item}`);
        });
      } else {
        if (filterValue[key] !== undefined) {
          filterSearchParams = filterSearchParams
            .concat(filterSearchParams === "?" ? "" : "&")
            .concat(`${key}=${filterValue[key]}`);
        }
      }
    });
    try {
      setDataLoading(true);
      const { AllNoonReport } = new NoonReportService();
      const result = await AllNoonReport(filterSearchParams);
      if (result && result.status === 200) {
        setDataSource(result.data.records);
        setPagination((prev) => ({ ...prev, total: result.data.count }));
      }
    } catch (err) {
      console.log(err);
    } finally {
      setDataLoading(false);
    }
  };

  const items: TabsProps["items"] = [
    {
      key: "ShipTab",
      label: "Vessel",
      children: <ShipTab />,
    },
    {
      key: "tradeStatus",
      label: "Voyage",
      children: <VoyageTab />,
    },
    {
      key: "cargo",
      label: "Cargo",
      children: <CargoTab />,
    },
    {
      key: "shipStatus",
      label: "Vessel status",
      children: <ShipStatus />,
    },
    {
      key: "currentSituation",
      label: "Current situation",
      children: <CurrentSituationTab />,
    },
    {
      key: "reportDate",
      label: "Report date",
      children: <Date />,
    },
  ];

  return (
    <Popconfirm
      placement="bottomLeft"
      title=""
      description={
        <TabStyle>
          <Tabs defaultActiveKey="1" items={items} />
        </TabStyle>
      }
      onConfirm={onFinishFilter}
      onCancel={cancel}
      okText="ok"
      cancelText="Reset"
      icon={""}
      rootClassName="filterPopUp"
    >
      {children}
    </Popconfirm>
  );
};

export const ShipTab: FC = () => {
  const {
    value: { filterValue },
    dispatch: { setFilterValue },
  } = useNoonReportCTX();
  const onChange: CheckboxValueType = (checkedValues) => {
    setFilterValue((perv) => ({ ...perv, ShipId: checkedValues as number[] }));
  };
  const { ships } = usePublicApiStore();
  const [filterShips, setFilterShips] = useState<IShip[]>(ships?.data || []);

  const onSearch = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (e.target.value && e.target.value !== "") {
      const filteredValue = ships?.data?.filter((ship) =>
        ship.name.toLowerCase().includes(e.target.value.toLowerCase())
      );
      setFilterShips(filteredValue ? filteredValue : []);
    } else {
      setFilterShips(ships?.data || []);
    }
  };

  return (
    <ShipTabContainer className="flex flex-col gap-[16px]">
      <Input
        placeholder="Search in filters"
        suffix={
          <span className="material-icons text-[#A3AED0] text-[16px]">
            search
          </span>
        }
        onChange={onSearch}
      />
      <Checkbox.Group
        style={{ width: "100%" }}
        onChange={onChange}
        className="flex flex-col h-[135px] py-[16px] gap-[8px] checkboxGroup"
        value={filterValue.ShipId}
      >
        {filterShips?.map((item, index) => (
          <Checkbox value={item.id} key={index}>
            <Space>{item.name}</Space>
          </Checkbox>
        ))}
      </Checkbox.Group>
    </ShipTabContainer>
  );
};
const VoyageTab: FC = () => {
  const {
    value: { filterValue },
    dispatch: { setFilterValue },
  } = useNoonReportCTX();
  const onChange: CheckboxValueType = (checkedValues) => {
    setFilterValue((perv) => ({
      ...perv,
      Voyage: checkedValues as number[],
    }));
  };
  return (
    <VoyageTabContainer>
      <Checkbox.Group
        onChange={onChange}
        className="flex flex-col pt-[16px] h-[135px] py-[16px] gap-[8px] flex-nowrap  checkboxGroup"
        value={filterValue.Voyage}
      >
        <Checkbox value={0}>No Voyage Yet</Checkbox>
      </Checkbox.Group>
    </VoyageTabContainer>
  );
};
const CurrentSituationTab: FC = () => {
  const {
    value: { filterValue },
    dispatch: { setFilterValue },
  } = useNoonReportCTX();
  const onChange: CheckboxValueType = (checkedValues) => {
    setFilterValue((perv) => ({
      ...perv,
      CurrentSituation: checkedValues as number[],
    }));
  };
  return (
    <VoyageTabContainer>
      <Checkbox.Group
        onChange={onChange}
        className="flex flex-col py-[16px] pt-[16px] gap-[8px] flex-nowrap borderBottom"
        value={filterValue.CurrentSituation}
      >
        <Checkbox value={CurrentSituation.Sailing}>Sailing</Checkbox>
        <Checkbox value={CurrentSituation.Drifting}>Drifting</Checkbox>
        <Checkbox value={CurrentSituation.Loading}>Loading</Checkbox>
        <Checkbox value={CurrentSituation.Discharging}>Discharging</Checkbox>
        <Checkbox value={CurrentSituation.Anchorage}>Anchorage</Checkbox>
        <Checkbox value={CurrentSituation.DryDock}>DryDock</Checkbox>
      </Checkbox.Group>
    </VoyageTabContainer>
  );
};
const CargoTab: FC = () => {
  const {
    value: { filterValue },
    dispatch: { setFilterValue },
  } = useNoonReportCTX();
  const onChange: CheckboxValueType = (checkedValues) => {
    setFilterValue((perv) => ({
      ...perv,
      Cargo: checkedValues as number[],
    }));
  };
  return (
    <VoyageTabContainer>
      <Checkbox.Group
        onChange={onChange}
        className="flex flex-col pt-[16px] gap-[8px] flex-nowrap borderBottom checkboxGroup"
        value={filterValue.Cargo}
      >
        <Checkbox value={1}>No Voyage Yet</Checkbox>
        <Checkbox value={2}>in Voyage</Checkbox>
      </Checkbox.Group>
    </VoyageTabContainer>
  );
};
const ShipStatus: FC = () => {
  const {
    value: { filterValue },
    dispatch: { setFilterValue },
  } = useNoonReportCTX();
  const onChange: CheckboxValueType = (checkedValues) => {
    setFilterValue((perv) => ({
      ...perv,
      ShipState: checkedValues as number[],
    }));
  };
  return (
    <VoyageTabContainer>
      <Checkbox.Group
        onChange={onChange}
        className="flex flex-col pt-[16px] gap-[8px] flex-nowrap borderBottom"
        value={filterValue.ShipState}
      >
        <Checkbox value={1}>Laden</Checkbox>
        <Checkbox value={2}>Ballast</Checkbox>
      </Checkbox.Group>
    </VoyageTabContainer>
  );
};

const Date: FC = () => {
  const { RangePicker } = DatePicker;
  const {
    value: { filterValue },
    dispatch: { setFilterValue },
  } = useNoonReportCTX();
  const onChange = (value: RangePickerProps["value"]) => {
    setFilterValue((perv) => ({
      ...perv,
      FromReportDate:
        value && value[0] ? value[0].format("YYYY-MM-DD") : undefined,
      ToReportDate:
        value && value[1] ? value[1]?.format("YYYY-MM-DD") : undefined,
    }));
  };
  return (
    <DateContainer>
      <div className="borderBottom">
        <div className="dateTitle ">Report date</div>
        <RangePicker
          className="datePicker "
          format={"YYYY-MM-DD"}
          onChange={onChange}
          value={
            filterValue.FromReportDate && filterValue.ToReportDate
              ? [
                  dayjs(filterValue.FromReportDate),
                  dayjs(filterValue.ToReportDate),
                ]
              : undefined
          }
        />
      </div>
    </DateContainer>
  );
};
