import { FC, PropsWithChildren } from "react";
import ConfigProviders from "./ConfigProviders";
import { AttachInvoiceFileProvider } from "../modules/AttachInvoiceFile/context";
import { AddInvoicesProvider } from "../modules/addInvoice/context";
import { AddInvoiceModalProvider } from "../modules/addInvoiceModal/context";
import { TableContextProvider } from "src/modules/Table/context";
import { usePublicApi } from "src/hook/publicApi";
import { AttachZipFileProvider } from "src/modules/home/context/attachZipFile";
import { EditInvoiceProvider } from "src/modules/EditInvoice/context";
import { EditZipFileProvider } from "src/modules/editZipFileInformation/context";
import { DailyReportProvider } from "src/modules/ShipDailyReport/context";
import { SupplierProvider } from "src/modules/addNewSupplier/context";

export const AppContextProviders: FC<PropsWithChildren> = ({ children }) => {
  usePublicApi();

  return (
    <ConfigProviders>
      <EditZipFileProvider>
        <AddInvoicesProvider>
          <AttachInvoiceFileProvider>
            <EditInvoiceProvider>
              <TableContextProvider>
                <AttachZipFileProvider>
                  <AddInvoiceModalProvider>
                    <DailyReportProvider>
                      <SupplierProvider> {children}</SupplierProvider>
                    </DailyReportProvider>
                  </AddInvoiceModalProvider>
                </AttachZipFileProvider>
              </TableContextProvider>
            </EditInvoiceProvider>
          </AttachInvoiceFileProvider>
        </AddInvoicesProvider>
      </EditZipFileProvider>
    </ConfigProviders>
  );
};
