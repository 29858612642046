import { Row } from "antd";
import styled from "styled-components";

export const ProfitTableContainer = styled.div`
  /* display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px; */
`;

export const TotalInfoContainer = styled.div`
  padding: 16px 24px;
  border-radius: 12px;
  align-items: center;
  background: var(--color-Base-Color-White, #fff);
  .card {
    min-width: 210px;
    display: flex;
    padding: 0px 3px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
    p {
      margin: 0px;
    }
  }
`;

export const TableContainer = styled(Row)`
  /* margin-top: 32px; */
  /* .KNnDb {
    padding: 24px;
  } */
  .success {
    color: #0c9d61;
  }
  .danger {
    color: #ec2d30;
  }
`;

export const PDFModalContainer = styled(Row)`
  background: #f7f8fa;
  padding: 24px 20px 32px 20px;
  p {
    margin: 0px;
  }
  .titre {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  .cardsModal {
    margin-top: 32px;
    padding: 16px 24px 16px 24px;
    border-radius: 8px;
    background: var(--color-Base-Color-White, #fff);

    /* 2 */
    box-shadow: 0px 4px 6px 0px rgba(113, 130, 183, 0.25);
  }
`;
