import { FC } from "react";
import useOwnerStore from "src/store/owner";
import { ShipDailyReportHeaderStyle } from "./style";
import { Divider, Flex } from "antd";

export const ShipDailyReportsHeader: FC = () => {
  const { selectedShip } = useOwnerStore();
  return (
    <ShipDailyReportHeaderStyle align="center">
      <span className="ship-reports-header-name">{selectedShip?.name}</span>
      <Divider type="vertical" className="ship-report-header-divider" />
      <Flex align="center">
        <span className="ship-reports-header-count">
          {selectedShip?.countDailyReport}
        </span>
        <span className="ship-reports-header-message">
          Reports that you have not been read
        </span>
      </Flex>
    </ShipDailyReportHeaderStyle>
  );
};
