import { Button, Col, Divider, Form, Row, Select, Space, message } from "antd";
import { FC, useState } from "react";
import { AddNewShipManagementContainer } from "../../styles";
import { FormItem } from "src/components/UiKit/FormItem";
import { SInput } from "src/components/UiKit/Input";
import { useShipManagement } from "../../context";
import { HeaderEditNewShipManagement } from "./components/headerEditNewShipManagement";
import { ShipService } from "src/services/Ship/Ship.service";
import { IEditShipManagement } from "src/services/Ship/models";
import { AddCountryModal } from "src/components/UiKit/AddCountryModal/AddCountryModal";

export const EditNewShipManagement: FC = () => {
  const [form] = Form.useForm();
  const {
    value: { tableCurrentValue, countryList },
    dispatch: { setEditMode },
    func: { getCountry },
  } = useShipManagement()!;
  const [editLoading, setEditLoading] = useState<boolean>(false);
  const [isAddCountryModalOpen, setIsAddCountryModalOpen] =
    useState<boolean>(false);
  const onFinish = async (data: IEditShipManagement) => {
    if (!tableCurrentValue) return;
    setEditLoading(true);

    try {
      const reqBody: IEditShipManagement = {
        ...data,
      };
      const { EditShipManagement } = new ShipService();
      const response = await EditShipManagement(tableCurrentValue?.id, reqBody);
      if (response && response.status === 200) {
        message.success("EditShipManagement successfully updated");
        setEditMode(false);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setEditLoading(false);
    }
  };

  return (
    <Form form={form} onFinish={onFinish} initialValues={tableCurrentValue}>
      <HeaderEditNewShipManagement />
      <AddNewShipManagementContainer>
        <Row>
          <Col span={24}>
            <div className="pt-[16px] titleHolder">
              Please insert Ship Management information.
            </div>
          </Col>
          <Col span={24} className=" pt-[40px] h-[115px]">
            <Row gutter={32}>
              <Col span={8}>
                <FormItem name="name" label="Company name">
                  <SInput placeholder="insert" />
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem name="acronym" label="Abbreviation ">
                  <SInput placeholder="insert" />
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem name="rank" label="Ship management Rank">
                  <SInput placeholder="insert" />
                </FormItem>
              </Col>
            </Row>
          </Col>
          <Col span={24} className="pt-[24px]">
            <Row gutter={32}>
              <Col span={8}>
                <FormItem name="webSite" label="Website">
                  <SInput placeholder="insert" />
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem name="email" label="Email ">
                  <SInput placeholder="e.g. info@website.com" />
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem name="countryId" label="Country">
                  <Select
                    style={{ width: "100%" }}
                    placeholder="select"
                    optionLabelProp="label"
                    showSearch
                    className="selectOption"
                    options={countryList.map((country) => ({
                      label: country.name,
                      value: country.id,
                    }))}
                    dropdownRender={(menu) => (
                      <>
                        <Space style={{ padding: "16px 8px 4px" }}>
                          <div
                            onClick={() => setIsAddCountryModalOpen(true)}
                            className="cursor-pointer flex justify-center items-center"
                          >
                            <span className="material-icons text-[16px] text-[#001377]">
                              add
                            </span>
                            <div className="addHolder"> Add Country</div>
                          </div>
                        </Space>
                        <Divider style={{ margin: "8px 0" }} />
                        {menu}
                      </>
                    )}
                  />
                </FormItem>
              </Col>
            </Row>
          </Col>
          <Col span={24} className="pt-[24px]">
            <Row gutter={32}>
              <Col span={8}>
                <FormItem name="city" label="City">
                  <SInput placeholder="insert" />
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem name="phoneNumber" label="Phone number ">
                  <SInput placeholder="e.g. +971-4-2965595" />
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem name="address" label="Address">
                  <SInput placeholder="insert" />
                </FormItem>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col span={24} className="flex justify-end footer gap-[32px]">
            <Button
              className="footerButton"
              //   loading={loading}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              className="footerButton"
              onClick={form.submit}
              loading={editLoading}
            >
              Finish
            </Button>
          </Col>
        </Row>
      </AddNewShipManagementContainer>
      <AddCountryModal
        getCountry={getCountry}
        isAddCountryModalOpen={isAddCountryModalOpen}
        setIsAddCountryModalOpen={setIsAddCountryModalOpen}
      />
    </Form>
  );
};
