import { styled } from "styled-components";

export const AddShipsContainer = styled.div`
  .ant-tabs-tab {
    color: var(--text-2, #a3aed0);
    font-family: DM Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 160% */
    padding: unset;
  }
  .ant-tabs-nav::before {
    border-bottom: 1px solid var(--shadow, #dadeec);
    padding: 24px;
  }
  .ant-tabs-ink-bar {
    height: unset !important;
  }
  .ant-tabs-nav {
    padding-bottom: 24px;
    margin-bottom: 24px !important;
    height: 80px;
  }
`;
export const AddNewShipContainer = styled.div`
  /* height: 766px; */
  border-radius: 20px;
  background: var(--0, #fff);
  box-shadow: 0px 4px 6px 0px rgba(113, 130, 183, 0.25);
  padding: 24px 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  display: flex;
  gap: 24px;
  .ant-tabs-nav {
    height: 60px;
    padding-bottom: unset !important;
  }
  .footer {
    border-top: 1px solid var(--shadow, #dadeec);
    padding-top: 24px;
  }
  .ant-tabs-tab {
    color: var(--text-2, #a3aed0);
    font-family: DM Sans;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: unset;
  }
  .ant-tabs-ink-bar {
    height: 2px !important;
  }
`;
