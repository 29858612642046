import { FC, useCallback, useMemo } from "react";
import { Button } from "antd";
import { useTable } from "../../../context";
import { Container } from "./styles";
import { ITabs } from "./model";
import { useAddInvoices } from "src/modules/addInvoice/context";

export const Tabs: FC<ITabs> = ({ zipFiles }) => {
  const {
    value: { tableState },
  } = useTable();
  const {
    value: { currentRequest },
  } = useAddInvoices()!;

  const filterZipFiles = useCallback(
    (selectedFileIndex: number) => {
      return zipFiles?.filter((item, index) => index === selectedFileIndex);
    },
    [zipFiles]
  );
  const files = useMemo(
    () => filterZipFiles(tableState.selectedFileIndex),
    [filterZipFiles, tableState.selectedFileIndex]
  );
  return (
    <Container>
      <ul className="tabs-list mb-0 ps-0 flex flex-row justify-start items-center w-100 overflow-x-auto">
        {files?.map((file, index) => {
          let zipReqTotalAmount = 0;
          let zipTotalExcAmount = 0;
          let paymentCurrency = currentRequest?.payementCurrencyShortName;
          file.files?.forEach((i) => {
            zipReqTotalAmount += i.reqTotalAmount;
            zipTotalExcAmount += i.totalExcAmount;
          });
          return (
            <li
              key={index}
              className={`tab-item list-none w-full ${
                index === tableState.selectedFileIndex ? "active" : ""
              } flex justify-between items-center`}
            >
              <Button
                type="text"
                className="flex justify-center items-center tab-button rounded"
                // onClick={() => changeTabHandler(index)}
              >
                <span className="material-symbols-outlined icon">layers</span>
                {file.referenceNumber}
              </Button>
              <div className="flex justify-between items-center w-full py-[12px] ps-[56px]">
                <div className="flex gap-[16px] items-center ">
                  <span className="text-[12px] text-[#5D6881] font-[600]">
                    Zip Req total amount P.C
                  </span>
                  <span className="text-[12px] text-[#001377] font-[400]">
                    {zipReqTotalAmount.toLocaleString()}
                  </span>
                </div>
                <div className="flex gap-[16px] items-center ">
                  <span className="text-[12px] text-[#5D6881] font-[600]">
                    Zip total Exc amount
                  </span>
                  <span className="text-[12px] text-[#001377] font-[400]">
                    {zipTotalExcAmount.toLocaleString()}
                  </span>
                </div>
                <div className="flex gap-[16px] items-center ">
                  <span className="text-[12px] text-[#5D6881] font-[600]">
                    Payment currency
                  </span>
                  <span className="text-[12px] text-[#001377] font-[400]">
                    {paymentCurrency}
                  </span>
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    </Container>
  );
};
