import { styled } from "styled-components";

export const TableContainer = styled.div`
  tr > td,
  tr > th {
    border-inline-end: unset !important;
    font-size: 12px;
  }
  tr > th {
    border-inline-end: unset !important;
    font-size: 14px;
    white-space: nowrap;
    text-align: center !important;
    background-color: #dbe9ff !important;
    color: #1b2559 !important;
  }

  tr > td {
    text-align: center;
    color: #5d6881;
  }
`;
