import { IPath } from "../Identity/models";

export const PublicGuardPath: IPath[] = [
  { path: "api/Public/Ship", httpMethod: "GET" },
  { path: "api/Public/Requester", httpMethod: "GET" },
  { path: "api/Public/{RequesterId}/RequesterById", httpMethod: "GET" },
  { path: "api/Public/Category", httpMethod: "POST" },
  { path: "api/Public/Currency", httpMethod: "GET" },
  { path: "api/Public/AllCurrency", httpMethod: "GET" },
  { path: "api/Public/UploadFile", httpMethod: "POST" },
];

export const ShipPath: IPath = { path: "api/Public/Ship", httpMethod: "GET" };
export const RequesterPath: IPath = {
  path: "api/Public/Requester",
  httpMethod: "GET",
};
export const RequesterByIdPath: IPath = {
  path: "api/Public/{RequesterId}/RequesterById",
  httpMethod: "GET",
};
export const CategoryPath: IPath = {
  path: "api/Public/Category",
  httpMethod: "POST",
};
export const CurrencyPath: IPath = {
  path: "api/Public/Currency",
  httpMethod: "GET",
};
export const AllCurrencyPath: IPath = {
  path: "api/Public/AllCurrency",
  httpMethod: "GET",
};
export const UploadFilePath: IPath = {
  path: "api/Public/UploadFile",
  httpMethod: "POST",
};
