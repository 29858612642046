import { FC } from "react";
import { SelectStepContentStyle } from "./style";
import { Flex } from "antd";

export const SelectStepContent: FC = () => {
  return (
    <SelectStepContentStyle>
      <Flex justify="center" align="center" vertical className="content">
        <div className="material-icons icon">directions_boat_filled</div>
        <div className="description ">
          For add report information please select the step and insert its
          information.
        </div>
      </Flex>
    </SelectStepContentStyle>
  );
};
