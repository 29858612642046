import axios from "axios";
import { NoonReportUrls } from "./urls";
import {
  IEditFOMGOCONSArgs,
  IEditFOMGOROBArgs,
  IEditLOROBConsArgs,
  IFOMGOCONS,
  IFOMGOCONSArgs,
  IFOMGOROB,
  IFOMGOROBArgs,
  IFWInfo,
  IFWInfoArg,
  ILOROBCons,
  ILOROBConsArgs,
  INoonReport,
  IOtherInfo,
  IOtherInfoArgs,
  ISpeedDistanceInfo,
  ISpeedDistanceInfoArgs,
  IVoyageInfoArg,
} from "./models";
import { IDataModel } from "../models";

export class NoonReportService {
  async AllNoonReport(queries?: string) {
    let endpoint: string = NoonReportUrls.AllNoonReport;
    if (queries) endpoint = endpoint.concat(`?${queries}`);

    try {
      const response = await axios.get<IDataModel<INoonReport[]>>(endpoint);
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async NewVoyageInfo(data: IVoyageInfoArg) {
    try {
      const response = await axios.post<number>(
        NoonReportUrls.NewVoyageInfo,
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async EditVoyageInfo(id: number, data: IVoyageInfoArg) {
    try {
      const response = await axios.put<void>(
        NoonReportUrls.NoonReport.concat(id.toString()).concat(
          NoonReportUrls.EditVoyageInfo
        ),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async DeleteNoonReport(id: number | string) {
    try {
      const response = await axios.delete<void>(
        NoonReportUrls.NoonReport.concat(id.toString()).concat(
          NoonReportUrls.DeleteNoonReport
        )
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async NewFOMGOROB(id: number, data: IFOMGOROBArgs) {
    try {
      const response = await axios.post<void>(
        NoonReportUrls.NoonReport.concat(`${id}${NoonReportUrls.NewFOMGOROB}`),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async EditFOMGOROB(id: number, data: IEditFOMGOROBArgs) {
    try {
      const response = await axios.put<void>(
        NoonReportUrls.NoonReport.concat(id.toString()).concat(
          NoonReportUrls.EditFOMGOROB
        ),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async GetFOMGOROB(id: number) {
    try {
      const response = await axios.get<IFOMGOROB[]>(
        NoonReportUrls.NoonReport.concat(`${id}${NoonReportUrls.GetFOMGOROB}`)
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async NewFOMGOCONS(id: number, data: IFOMGOCONSArgs) {
    try {
      const response = await axios.post<void>(
        NoonReportUrls.NoonReport.concat(`${id}${NoonReportUrls.NewFOMGOCONS}`),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async EditFOMGOCONS(id: number, data: IEditFOMGOCONSArgs) {
    try {
      const response = await axios.put<void>(
        NoonReportUrls.NoonReport.concat(id.toString()).concat(
          NoonReportUrls.EditFOMGOCONS
        ),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async GetFOMGOCONS(id: number) {
    try {
      const response = await axios.get<IFOMGOCONS[]>(
        NoonReportUrls.NoonReport.concat(`${id}${NoonReportUrls.GetFOMGOCONS}`)
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async NewLOROBCons(id: number, data: ILOROBConsArgs) {
    try {
      const response = await axios.post<void>(
        NoonReportUrls.NoonReport.concat(`${id}${NoonReportUrls.NewLOROBCons}`),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async EditLOROBCons(id: number, data: IEditLOROBConsArgs) {
    try {
      const response = await axios.put<void>(
        NoonReportUrls.NoonReport.concat(id.toString()).concat(
          NoonReportUrls.EditLOROBCons
        ),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async GetLOROBCons(id: number) {
    try {
      const response = await axios.get<ILOROBCons[]>(
        NoonReportUrls.NoonReport.concat(`${id}${NoonReportUrls.GetLOROBCons}`)
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async FWInfo(id: number, data: IFWInfoArg) {
    try {
      const response = await axios.put<void>(
        NoonReportUrls.NoonReport.concat(id.toString()).concat(
          NoonReportUrls.FWInfo
        ),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async GetFWInfo(id: number) {
    try {
      const response = await axios.get<IFWInfo>(
        NoonReportUrls.NoonReport.concat(
          `${id}${NoonReportUrls.SpeedDistanceInfo}`
        )
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async SpeedDistanceInfo(id: number, data: ISpeedDistanceInfoArgs) {
    try {
      const response = await axios.put<void>(
        NoonReportUrls.NoonReport.concat(id.toString()).concat(
          NoonReportUrls.SpeedDistanceInfo
        ),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async GetSpeedDistanceInfo(id: number) {
    try {
      const response = await axios.get<ISpeedDistanceInfo>(
        NoonReportUrls.NoonReport.concat(`${id}${NoonReportUrls.FWInfo}`)
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async OtherInfo(id: number, data: IOtherInfoArgs) {
    try {
      const response = await axios.put<void>(
        NoonReportUrls.NoonReport.concat(id.toString()).concat(
          NoonReportUrls.OtherInfo
        ),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async GetOtherInfo(id: number) {
    try {
      const response = await axios.get<IOtherInfo>(
        NoonReportUrls.NoonReport.concat(`${id}${NoonReportUrls.OtherInfo}`)
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
}
