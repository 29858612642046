import {
  FC,
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  ICurrentComment,
  IPDFFile,
  ITableContext,
  ITableState,
  IZipFile,
} from "../models";
import { ColumnsType } from "antd/es/table";
import { CommentButton, OperationButton } from "../styles";
import { useAttachInvoiceFileContext } from "src/modules/AttachInvoiceFile/context";
import { useQuery } from "react-query";
import { reactQueryEndPoints } from "src/constants/rectQueryEndPoints";
import { InvoiceService } from "src/services/Invoice/Invoice.service";
import { IPagination } from "src/models/interfaces/pagination";
import { defaultPagination } from "src/constants/defaultPagination";
import { CommentModal } from "../components/Comment-modal/Comment";
import { useAddInvoices } from "src/modules/addInvoice/context";
import {
  IInvoiceFileDetails,
  IUpdateAllRequest,
} from "src/services/Invoice/models";
import { EditZipFileInformation } from "src/modules/editZipFileInformation";
import { useEditZipFileContext } from "src/modules/editZipFileInformation/context";
import { IEditZipFileInformation } from "src/modules/editZipFileInformation/models";
import { useEditInvoice } from "src/modules/EditInvoice/context";
import { message } from "antd";
import { Guard } from "src/components/Guard";
import {
  AllRequestZipAttachmentPath,
  AllRequestZipDetailPath,
  AttachScreenShotToAllRequestPath,
  AttachZipToAllRequestPath,
  UpdateAllRequestPath,
  UpdateAllRequestZipDetailPath,
} from "src/services/Invoice/guardPath";

export const tableContextDefaultValue: ITableContext = {
  value: {
    tableState: {
      activatedTabIndex: 0,
      zipFiles: [],
      selectedFileIndex: 0,
    },
    zipFileTableColumns: [],
    zipFileLoading: true,
    pagination: defaultPagination,
    totalZipFile: 0,
    currentCommentData: {
      comment: "",
      invoice: "",
      reference: "",
    },
    editZipFileData: {
      referenceNumber: "",
    },
    expandLoading: false,
    currentPdfFileDetail: undefined,
  },
  dispatches: {
    setTableState: () => {},
    setPagination: () => {},
    setTotalZipFile: () => {},
    setCurrentCommentData: () => {},
    setEditZipFileData: () => {},
    setCurrentPdfFileDetail: () => {},
    setShipId: () => {},
  },
  func: {
    resetTableState: () => {},
    refetchZipFile: async () => {},
    hideComment: () => {},
    showComment: () => {},
    onExpand: async () => {},
    onExpandPdfFile: async () => {},
    confirmDeleteAllRequest: async () => {},
  },
};

export const TableContext = createContext<ITableContext>(
  tableContextDefaultValue
);

export const TableContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [tableState, setTableState] = useState<ITableState>(
    tableContextDefaultValue.value.tableState
  );
  const {
    value: { refreshZipFileDepends: editZipFileDep },
    dispatch: {
      setIsModalOpen,
      setCurrentAllZipFileId,
      setCurrentRequest: setCurrentRequestForEdit,
    },
  } = useEditZipFileContext()!;
  const {
    dispatch: { setIsModalOpenAttachInvoice, setZipFileCode },
    value: { refetchZipFileDependency },
  } = useAttachInvoiceFileContext()!;
  const {
    value: { currentRequest, refetchAllRequestDependency },
    dispatch: { setCurrentRequest },
  } = useAddInvoices()!;
  const {
    value: { refetchPdfZipFile, currentPdfFile },
  } = useEditInvoice();
  const { AllRequestZipAttachment, InvoiceFileDetail } = new InvoiceService();
  const [expandLoading, setExpandLoading] = useState<boolean>(false);
  const seeTableHandler = (index: number) => {
    setTableState((prev) => ({
      ...prev,
      activatedTabIndex: 1,
      selectedFileIndex: index,
    }));
  };
  const [pagination, setPagination] = useState<IPagination>(defaultPagination);
  const [totalZipFile, setTotalZipFile] = useState<number>(0);
  const [currentCommentData, setCurrentCommentData] = useState<ICurrentComment>(
    {
      comment: "",
      invoice: "",
      reference: "",
    }
  );
  const [editZipFileData, setEditZipFileData] =
    useState<IEditZipFileInformation>({
      referenceNumber: "",
    });
  const [currentPdfFileDetail, setCurrentPdfFileDetail] = useState<IPDFFile>();
  const [showCommentModal, setShowCommentModal] = useState<boolean>(false);
  const [shipId, setShipId] = useState<number>();
  const [editConfirmLoading, setEditConfirmLoading] = useState<boolean>(false);
  const showComment = () => {
    setShowCommentModal(true);
  };

  const hideComment = () => {
    setShowCommentModal(false);
  };

  const params = useMemo(() => {
    const search = new URLSearchParams();
    search.set("Offset", pagination.Offset.toString());
    search.set("Limit", pagination.Limit.toString());
    if (shipId) search.set("ShipId", shipId.toString());
    return `?${search.toString()}`;
  }, [pagination.Limit, pagination.Offset, shipId]);

  const {
    data: zipFileData,
    isFetching,
    refetch,
  } = useQuery({
    queryKey: reactQueryEndPoints.allRequest,
    queryFn: async () => {
      const { AllRequest } = new InvoiceService();
      return await AllRequest(params);
    },

    enabled: false,
  });

  const zipFileTableColumns: ColumnsType<IZipFile> = [
    {
      title: "Zip code No.",
      dataIndex: "referenceNumber",
      key: "referenceNumber",
      className: "!font-bold",
    },
    {
      title: "File No.",
      dataIndex: "fileNo",
      key: "fileNo",
      className: "!font-bold",
    },
    {
      title: "Comment",
      dataIndex: "comment",
      key: "comment",
      className: "!font-bold ",

      render: (_, record) => (
        <CommentButton
          onClick={() => {
            setCurrentCommentData({
              comment: record.comment,
              reference: record.referenceNumber,
            });
            setShowCommentModal(true);
          }}
        >
          <div className="commentWrapper">
            <span className="material-symbols-outlined icon !font-bold">
              mode_comment
            </span>
          </div>
        </CommentButton>
      ),
    },
    {
      title: "Payment currency",
      dataIndex: "payementCurrencyShortName",
      key: "payementCurrencyShortName",
      className: "!font-bold",
    },
    {
      title: "Operation",
      dataIndex: "files",
      key: "files",
      className: "!font-bold",

      render: (text, record, index) => {
        return (
          <div className="flex gap-[12px] justify-center items-center">
            {+record.fileNo > 0 ? (
              <Guard action={AllRequestZipAttachmentPath}>
                <div>
                  <OperationButton
                    type="link"
                    onClick={() => {
                      seeTableHandler(index);
                      onExpand(record);
                      setCurrentRequest(record);
                    }}
                    className="!font-bold p-0 "
                  >
                    See Table
                  </OperationButton>
                </div>
              </Guard>
            ) : (
              <Guard
                action={[
                  AllRequestZipDetailPath,
                  UpdateAllRequestZipDetailPath,
                ]}
                multiPath
              >
                <div>
                  <OperationButton
                    type="link"
                    className="p-0 !font-bold"
                    onClick={() => {
                      setIsModalOpenAttachInvoice(true);
                      setZipFileCode(record.referenceNumber);
                    }}
                  >
                    Attach File
                  </OperationButton>
                </div>
              </Guard>
            )}
            <Guard
              action={[
                UpdateAllRequestPath,
                AttachZipToAllRequestPath,
                AttachScreenShotToAllRequestPath,
              ]}
              multiPath
            >
              <div>
                <OperationButton
                  type="link"
                  onClick={() => {
                    setEditZipFileData({
                      referenceNumber: record.referenceNumber,
                    });
                    setIsModalOpen(true);
                    setCurrentAllZipFileId(record.id);
                    if (zipFileData?.data) {
                      setCurrentRequestForEdit(
                        zipFileData.data.records.find(
                          (item) => record.id === item.id
                        )
                      );
                    }
                  }}
                  className="!font-bold p-0 "
                >
                  Edit
                </OperationButton>
              </div>
            </Guard>
          </div>
        );
      },
    },
  ];

  const resetTableState = () => {
    setTableState({
      ...tableState,
      activatedTabIndex: 0,
      selectedFileIndex: 0,
    });
  };
  useEffect(() => {
    if (params) {
      refetch();
    }
  }, [params, refetch, refetchZipFileDependency, editZipFileDep]);
  const onExpand = async (record: IZipFile) => {
    // if (record.files && record.files.length > 0) return;
    setExpandLoading(true);
    try {
      const response = await AllRequestZipAttachment(record.id);
      if (response && response.status === 200 && response.data) {
        const files: IPDFFile[] = response.data.map((file) => ({
          invoiceAdded: file.invoiceCount,
          referenceNumber: record.referenceNumber,
          shipManagementFileName: file.fileName,
          link: "",
          ...file,
        }));
        setTableState((prev) => ({
          ...prev,
          zipFiles: prev.zipFiles.map((item) => {
            if (item.id === record.id) {
              item.files = files;
            }
            return item;
          }),
        }));
      }
    } catch (err) {
      console.log(err);
    } finally {
      setExpandLoading(false);
    }
  };
  const onExpandPdfFile = useCallback(
    async (record: IPDFFile) => {
      try {
        const response = await InvoiceFileDetail(record.id);
        if (response && response.status === 200 && response.data) {
          const details: IInvoiceFileDetails[] = response.data;
          setTableState((prev) => ({
            ...prev,
            zipFiles: prev.zipFiles.map((item) => {
              if (item.id === record.allRequestId) {
                item.files = item.files?.map((file) => {
                  if (file.id === record.id) {
                    file.invoices = details;
                  }
                  return file;
                });
              }
              return item;
            }),
          }));
        }
      } catch (err) {
        console.log(err);
      }
    },
    [InvoiceFileDetail]
  );
  const initialZipFileData = useCallback(() => {
    if (zipFileData?.data) {
      const {
        data: { records, count },
      } = zipFileData;
      setTotalZipFile(count);
      const data: IZipFile[] = records.map((item) => ({
        key: item.id.toString(),
        fileNo: item.fileCount,
        referenceNumber: item.uniqueCode,
        shipManagementFileName: "zip file",
        files: [],
        comment: item.comment,
        id: item.id,
        payementCurrencyShortName: item.payementCurrencyShortName,
      }));
      setTableState((prev) => ({
        ...prev,
        zipFiles: data,
      }));
    }
  }, [zipFileData]);
  useEffect(() => {
    initialZipFileData();
  }, [initialZipFileData]);
  useEffect(() => {
    if (refetchPdfZipFile > 1 && currentPdfFile) {
      onExpandPdfFile(currentPdfFile);
    }
  }, [refetchPdfZipFile, currentPdfFile, onExpandPdfFile]);

  const confirmDeleteAllRequest = async (record: IPDFFile) => {
    try {
      const { DeleteAllRequestAttachment } = new InvoiceService();
      const resp = await DeleteAllRequestAttachment(record.id);
      const parent = tableState.zipFiles.find(
        (item) => record.allRequestId === item.id
      );
      if (resp && resp.status === 200) {
        message.success("file deleted successfully");
        refetch();
        if (parent) onExpand(parent);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const onEditConfirm = async (value: string) => {
    const currentCommentRecord = zipFileData?.data.records.find(
      (item) => item.uniqueCode === currentCommentData.reference
    );
    if (!currentCommentRecord) return;

    try {
      setEditConfirmLoading(true);
      const { UpdateAllRequest } = new InvoiceService();
      const reqBody: IUpdateAllRequest = {
        comment: value,
        dueDate: currentCommentRecord.dueDate,
        paymentCurrencyId: currentCommentRecord.paymentCurrencyId,
      };
      const updatedRes = await UpdateAllRequest(
        currentCommentRecord.id,
        reqBody
      );
      if (updatedRes && updatedRes.status === 200) {
        message.success("Updated comment record successfully");
        refetch();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setEditConfirmLoading(false);
    }
  };
  useEffect(() => {
    if (refetchAllRequestDependency > 1 && currentRequest) {
      onExpand(currentRequest);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetchAllRequestDependency]);
  const contextValue: ITableContext = {
    value: {
      tableState,
      zipFileTableColumns,
      zipFileLoading: isFetching,
      pagination,
      totalZipFile,
      currentCommentData,
      editZipFileData,
      expandLoading,
      currentPdfFileDetail,
    },
    dispatches: {
      setTableState,
      setPagination,
      setTotalZipFile,
      setCurrentCommentData,
      setEditZipFileData,
      setCurrentPdfFileDetail,
      setShipId,
    },
    func: {
      resetTableState,
      refetchZipFile: refetch,
      showComment,
      hideComment,
      onExpand,
      onExpandPdfFile,
      confirmDeleteAllRequest,
    },
  };
  return (
    <TableContext.Provider value={contextValue}>
      {children}
      <CommentModal
        invoices={currentCommentData.invoice}
        referenceNumber={currentCommentData.reference}
        comment={currentCommentData.comment}
        onCancel={hideComment}
        onEdit={onEditConfirm}
        show={showCommentModal}
        onEditLoading={editConfirmLoading}
        editAction={UpdateAllRequestPath}
      />
      <EditZipFileInformation />
    </TableContext.Provider>
  );
};

export const useTable = () => useContext(TableContext);

export default TableContext;
