import { Button, Col, Divider, Form, Row, Select, Space } from "antd";
import { FC, useEffect, useState } from "react";
import { useAddShip } from "src/modules/AddShips/context";
import { IAddNewShip } from "src/modules/AddShips/models";
import { ShipPriceContainer } from "../styles";
import { FormItem } from "src/components/UiKit/FormItem";
import { SInput } from "src/components/UiKit/Input";
import { SDatePicker } from "src/components/UiKit/DatePicker";
import { SPopconfirm } from "./popconfirm";
import { IShipPriceInformation } from "src/services/Ship/models";
import { ShipService } from "src/services/Ship/Ship.service";
import { Dayjs } from "dayjs";
export const ShipPrice: FC = () => {
  const {
    dispatch: { setActiveKeyAddShip, setIsAddCurrencyModalOpen },
    forms: { ShipPriceForm },
    func: { fetchAllCurrency, deleteShip },
    value: { allCurrency, shipId, currencyLoading },
  } = useAddShip()!;
  const [loading, setLoading] = useState<boolean>(false);
  const [submittable, setSubmittable] = useState<boolean>(false);
  const values = Form.useWatch([], ShipPriceForm);

  useEffect(() => {
    ShipPriceForm?.validateFields({ validateOnly: true }).then(
      () => {
        setSubmittable(true);
      },
      () => {
        setSubmittable(false);
      }
    );
  }, [values, ShipPriceForm]);
  const onCancel = () => {};

  const nextTab = () => {
    setActiveKeyAddShip(IAddNewShip.insurances);
  };

  const onFinish = async (values: IShipPriceInformation) => {
    setLoading(true);
    const service = new ShipService();
    const reqBody: IShipPriceInformation = {
      currencyId: values.currencyId,
      price: values.price,
      date: values.date ? (values.date as Dayjs).format("YYYY-MM-DD") : null,
      datePrice: values.datePrice,
    };
    try {
      const response = await service.ShipPriceInformation(shipId, reqBody);
      if (response && response.status === 200) {
        ShipPriceForm?.resetFields();
        nextTab();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchAllCurrency();
  }, [fetchAllCurrency]);
  return (
    <Form onFinish={onFinish} form={ShipPriceForm}>
      <div className="flex flex-col h-[620px] justify-between">
        <ShipPriceContainer gutter={24}>
          <Col span={24}>
            <div className="titleHolder pt-[16px]">
              Please insert the ship price information at the time of purchase.
            </div>
          </Col>
          <Col span={8}>
            <FormItem name={"price"} label="Ship price" className="!mb-0">
              <SInput placeholder="insert" numbermode />
            </FormItem>
          </Col>
          <Col span={8}>
            <FormItem name={"currencyId"} label="Currency" className="!mb-0">
              <Select
                style={{ width: "100%" }}
                placeholder="select"
                optionLabelProp="label"
                showSearch
                className="selectOption"
                loading={currencyLoading}
                dropdownRender={(menu) => (
                  <>
                    <Space style={{ padding: "16px 8px 4px" }}>
                      <div
                        onClick={() => setIsAddCurrencyModalOpen(true)}
                        className="cursor-pointer flex justify-center items-center"
                      >
                        <span className="material-icons text-[16px] text-[#001377]">
                          add
                        </span>
                        <div className="addHolder">Add Currency</div>
                      </div>
                    </Space>
                    <Divider style={{ margin: "8px 0" }} />
                    {menu}
                  </>
                )}
                options={allCurrency.map((currency) => ({
                  label: currency.shortName,
                  value: currency.id,
                }))}
              />
            </FormItem>
          </Col>
          <Col span={24}>
            <div className="titleHolder pt-[16px]">
              Please insert current ship price information.
            </div>
          </Col>
          <Col span={8}>
            <FormItem name={"date"} label="Date" className="!mb-0">
              <SDatePicker
                placeholder="Select"
                className="w-[100%]"
                format={"YYYY.MM.DD"}
              />
            </FormItem>
          </Col>
          <Col span={8}>
            <FormItem
              name={"datePrice"}
              label="Ship price in date"
              className="!mb-0"
            >
              <SInput placeholder="insert" numbermode />
            </FormItem>
          </Col>
        </ShipPriceContainer>
        <Row>
          <Col span={24}>
            <div className="flex justify-between footer">
              <div>
                <Button
                  onClick={() => deleteShip(shipId)}
                  className="w-[148px] flex justify-center items-center"
                >
                  Discard new ship
                </Button>
              </div>
              <div className="flex gap-[16px]">
                <SPopconfirm
                  description="Are you sure you want to reset all the information you filled out?"
                  onConfirm={() => {
                    ShipPriceForm?.resetFields();
                  }}
                >
                  <Button onClick={onCancel} className="w-[100px]">
                    Reset
                  </Button>
                </SPopconfirm>

                <Button
                  type="primary"
                  className="w-[100px]"
                  onClick={ShipPriceForm?.submit}
                  loading={loading}
                  disabled={!submittable}
                >
                  Next
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </Form>
  );
};
