import { Col } from "antd";
import { FC } from "react";
import { UploadZip } from "./upload-zip";
import { UploadScreenShot } from "./upload-screenshot";
import { AttachZipFinallyContainer } from "../../styles/AttachZipFileStyles/attachZipFinally.style";

export const AttachZip: FC = () => {
  return (
    <AttachZipFinallyContainer gutter={32}>
      <Col span={24} className="ps-[50px] ">
        <div className="title  ">
          Please Attach zip file and screenshot of the date .
        </div>
      </Col>
      <Col span={24}>
        <UploadZip />
      </Col>
      <Col span={24}>
        <UploadScreenShot />
      </Col>
    </AttachZipFinallyContainer>
  );
};
