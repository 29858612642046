import { Col, DatePicker, Row } from "antd";
import { FC } from "react";
import { FormItem } from "src/components/UiKit/FormItem";
import { SInput } from "src/components/UiKit/Input";
import { ProfitShippingFormItemsWrapper } from "./style";
const { RangePicker } = DatePicker;

export const ProfitShippingFormItems: FC = () => {
  return (
    <ProfitShippingFormItemsWrapper gutter={24}>
      <Col span={24}>
        <Row>
          <Col span={8}>
            <FormItem name="voyageDuration" label="Voyage duration">
              <RangePicker
                style={{ width: "100%" }}
                className="rangePicker"
                placeholder={["Start", "End"]}
              />
            </FormItem>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <div className="expenses">EXPENSES INFO</div>
      </Col>
      <Col span={8}>
        <FormItem name="opexTCCost" label="Opex / TC Cost (USD)">
          <SInput numbermode placeholder="Only number" />
        </FormItem>
      </Col>
      <Col span={8}>
        <FormItem name="bunkering" label="Bunkering (USD)">
          <SInput numbermode placeholder="Only number" />
        </FormItem>
      </Col>
      <Col span={8}>
        <FormItem name="agencyCost" label="Agency cost (USD)">
          <SInput numbermode placeholder="Only number" />
        </FormItem>
      </Col>
      <Col span={8}>
        <FormItem name="brokageCommission" label="Brokerage Commission (USD)">
          <SInput numbermode placeholder="Only number" />
        </FormItem>
      </Col>
      <Col span={8}>
        <FormItem name="heatingCost" label="Heating cost (USD)">
          <SInput numbermode placeholder="Only number" />
        </FormItem>
      </Col>
      <Col span={8}>
        <FormItem name="shippingOtherCost" label="Other cost (USD)">
          <SInput numbermode placeholder="Only number" />
        </FormItem>
      </Col>
      <Col span={24}>
        <div className="income">INCOME INFO</div>
      </Col>

      <Col span={8}>
        <FormItem name="freightInvoice" label="Freight invoice (USD)">
          <SInput numbermode placeholder="Only number" />
        </FormItem>
      </Col>
      <Col span={8}>
        <FormItem name="demurageInvoice" label="Demurrage invoice (USD)">
          <SInput numbermode placeholder="Only number" />
        </FormItem>
      </Col>
      <Col span={8}>
        <FormItem name="heatingInvoie" label="Heating invoice (USD)">
          <SInput numbermode placeholder="Only number" />
        </FormItem>
      </Col>
    </ProfitShippingFormItemsWrapper>
  );
};
