import { InvoiceUrls } from "./urls";
import * as Models from "./models";
import axios, { AxiosRequestConfig } from "axios";
import { IDataModel } from "../models";
import { Buffer } from "buffer";
import { checkActionPermission } from "src/helpers/checkPermissionFunc";
import * as GuardPaths from "./guardPath";
import { permissionLog } from "src/helpers/permissionLog";

export class InvoiceService {
  async AllRequest(query: string) {
    if (!checkActionPermission(GuardPaths.AllRequestPath)) {
      permissionLog(GuardPaths.AllRequestPath.path);
      return undefined;
    }
    try {
      const response = await axios.get<IDataModel<Models.IAllRequest[]>>(
        InvoiceUrls.AllRequest.concat(query)
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async UpdateAllRequest(allrequestId: number, data: Models.IUpdateAllRequest) {
    if (!checkActionPermission(GuardPaths.UpdateAllRequestPath)) {
      permissionLog(GuardPaths.UpdateAllRequestPath.path);
      return undefined;
    }
    try {
      const response = await axios.put<IDataModel<Models.IAllRequest[]>>(
        InvoiceUrls.Invoice.concat(
          `${allrequestId}${InvoiceUrls.UpdateAllRequest}`
        ),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async NewAllRequest(data: Models.INewAllRequestArgs) {
    if (!checkActionPermission(GuardPaths.NewAllRequestPath)) {
      permissionLog(GuardPaths.NewAllRequestPath.path);
      return undefined;
    }
    try {
      const response = await axios.post<number>(
        InvoiceUrls.NewAllRequest,
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async AllRequestZipAttachment(id: number) {
    if (!checkActionPermission(GuardPaths.AllRequestZipAttachmentPath)) {
      permissionLog(GuardPaths.AllRequestZipAttachmentPath.path);
      return undefined;
    }
    try {
      const response = await axios.get<Models.IAllRequestZipAttachment[]>(
        InvoiceUrls.Invoice.concat(
          `${id}${InvoiceUrls.AllRequestZipAttachment}`
        )
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async ZipFile(id: number, config?: AxiosRequestConfig) {
    if (!checkActionPermission(GuardPaths.ZipFilePath)) {
      permissionLog(GuardPaths.ZipFilePath.path);
      return undefined;
    }
    const newConfig: AxiosRequestConfig<any> = {
      responseType: "arraybuffer",
      headers: {
        accept: "*/*",
      },
      ...config,
    };
    try {
      const response = await axios.get<Buffer>(
        InvoiceUrls.Invoice.concat(`${id}${InvoiceUrls.ZipFile}`),
        newConfig
      );

      return { buffer: response.data, type: response.headers["content-type"] };
    } catch (err) {
      throw console.log(err);
    }
  }
  async AllRequestZipAttachmentId(id: number, userConfig?: AxiosRequestConfig) {
    if (!checkActionPermission(GuardPaths.FileGuardPath)) {
      permissionLog(GuardPaths.FileGuardPath.path);
      return undefined;
    }
    const config: AxiosRequestConfig<any> = {
      ...userConfig,
      responseType: "arraybuffer",
      headers: {
        accept: "*/*",
      },
    };
    try {
      const response = await axios.get<Buffer>(
        InvoiceUrls.Invoice.concat(
          `${id}${InvoiceUrls.AllRequestZipAttachmentId}`
        ),
        config
      );
      if (response) {
        let raw = Buffer.from(response.data).toString("base64");
        return {
          base64: "data:" + response.headers["content-type"] + ";base64," + raw,
          type: response.headers["content-type"],
        };
      }
      // return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async NewInvoice(data: Models.INewInvoice) {
    if (!checkActionPermission(GuardPaths.NewInvoicePath)) {
      permissionLog(GuardPaths.NewInvoicePath.path);
      return undefined;
    }
    try {
      const response = await axios.post<number>(InvoiceUrls.NewInvoice, data);
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async InvoiceByInvoiceNumber(
    queryParam: string,
    options?: { ShipId?: number; InvoiceType?: Models.InvoiceType }
  ) {
    if (!checkActionPermission(GuardPaths.InvoiceByInvoiceNumberPath)) {
      permissionLog(GuardPaths.InvoiceByInvoiceNumberPath.path);
      return undefined;
    }
    try {
      let params = `?queryParam=${queryParam}`;
      if (options) {
        console.log({ options });

        if (options.ShipId) {
          params = params.concat(`&ShipId=${options.ShipId}`);
        }
        if (options.InvoiceType) {
          params = params.concat(`&InvoiceType=${options.InvoiceType}`);
        }
      }
      const response = await axios.get<Models.IInvoiceByInvoiceNumber[]>(
        InvoiceUrls.InvoiceByInvoiceNumber.concat(params)
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async NewInvoiceArticle(id: number, data: Models.INewInvoiceArticle) {
    if (!checkActionPermission(GuardPaths.NewInvoiceArticlePath)) {
      permissionLog(GuardPaths.NewInvoiceArticlePath.path);
      return undefined;
    }
    try {
      const response = await axios.post<number>(
        InvoiceUrls.Invoice.concat(`${id}${InvoiceUrls.NewInvoiceArticle}`),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async TotlaInvoice(id: number, data: Models.ITotalInvoice) {
    if (!checkActionPermission(GuardPaths.TotlaInvoicePath)) {
      permissionLog(GuardPaths.TotlaInvoicePath.path);
      return undefined;
    }
    try {
      const response = await axios.post<void>(
        InvoiceUrls.Invoice.concat(`${id}${InvoiceUrls.TotlaInvoice}`),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async PaymanetInformation(id: number, data: Models.IPaymanetInformation) {
    if (!checkActionPermission(GuardPaths.PaymanetInformationPath)) {
      permissionLog(GuardPaths.PaymanetInformationPath.path);
      return undefined;
    }
    try {
      const response = await axios.post<void>(
        InvoiceUrls.Invoice.concat(`${id}${InvoiceUrls.PaymanetInformation}`),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async InvoiceAttqachment(
    id: number,
    data: {
      fileName: string;
      count: number;
      extention: string;
      fileOrginalName: string;
    },
    config?: AxiosRequestConfig
  ) {
    if (!checkActionPermission(GuardPaths.InvoiceAttqachmentPath)) {
      permissionLog(GuardPaths.InvoiceAttqachmentPath.path);
      return undefined;
    }
    try {
      const response = await axios.patch<void>(
        InvoiceUrls.Invoice.concat(`${id}${InvoiceUrls.InvoiceAttqachment}`),
        data,
        config
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async AllRequestZipDetail(
    id: number,
    data: FormData,
    config?: AxiosRequestConfig
  ) {
    if (!checkActionPermission(GuardPaths.AllRequestZipDetailPath)) {
      permissionLog(GuardPaths.AllRequestZipDetailPath.path);
      return undefined;
    }
    try {
      const response = await axios.patch<void>(
        InvoiceUrls.Invoice.concat(`${id}${InvoiceUrls.AllRequestZipDetail}`),
        data,
        config
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async CloseAllRequestAttachment(AllRequestAttachmentId: number) {
    if (!checkActionPermission(GuardPaths.CloseAllRequestAttachmentPath)) {
      permissionLog(GuardPaths.CloseAllRequestAttachmentPath.path);
      return undefined;
    }
    try {
      const response = await axios.post<void>(
        InvoiceUrls.Invoice.concat(
          `${AllRequestAttachmentId}${InvoiceUrls.CloseAllRequestAttachment}`
        )
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async InvoiceFileDetail(attachmentId: number) {
    if (!checkActionPermission(GuardPaths.InvoiceFileDetailPath)) {
      permissionLog(GuardPaths.InvoiceFileDetailPath.path);
      return undefined;
    }
    try {
      const response = await axios.get<Models.IInvoiceFileDetails[]>(
        InvoiceUrls.Invoice.concat(
          `${attachmentId}${InvoiceUrls.InvoiceFileDetail}`
        )
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async FinallyInvoice(id: number) {
    if (!checkActionPermission(GuardPaths.FinallyInvoicePath)) {
      permissionLog(GuardPaths.FinallyInvoicePath.path);
      return undefined;
    }
    try {
      const response = await axios.get<Models.IFinallyInvoice>(
        InvoiceUrls.Invoice.concat(id.toString()).concat(
          InvoiceUrls.FinallyInvoice
        )
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async GetRelatedInvoice(id: number) {
    if (!checkActionPermission(GuardPaths.GetRelatedInvoicePath)) {
      permissionLog(GuardPaths.GetRelatedInvoicePath.path);
      return undefined;
    }
    try {
      const response = await axios.get<Models.IRelatedInvoice>(
        InvoiceUrls.Invoice.concat(id.toString()).concat(
          InvoiceUrls.GetRelatedInvoice
        )
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async InitInvoiceInof(AllRequestZipAttachmentId: number) {
    if (!checkActionPermission(GuardPaths.InitInvoiceInofPath)) {
      permissionLog(GuardPaths.InitInvoiceInofPath.path);
      return undefined;
    }
    try {
      const response = await axios.get<Models.IInitInvoiceInof>(
        InvoiceUrls.InitInvoiceInof.concat(
          `?AllRequestZipAttachmentId=${AllRequestZipAttachmentId}`
        )
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async AllRequestScreenAttachmentId(id: number) {
    if (!checkActionPermission(GuardPaths.ScreenFilePath)) {
      permissionLog(GuardPaths.ScreenFilePath.path);
      return undefined;
    }
    const config: AxiosRequestConfig<any> = {
      responseType: "arraybuffer",
      headers: {
        accept: "*/*",
      },
    };
    try {
      const response = await axios.get<Buffer>(
        InvoiceUrls.Invoice.concat(`${id}${InvoiceUrls.ScreenFile}`),
        config
      );
      if (response) {
        let raw = Buffer.from(response.data).toString("base64");
        return "data:" + response.headers["Content-Type"] + ";base64," + raw;
      }
    } catch (err) {
      throw console.log(err);
    }
  }
  async UpdateInvoice(id: number, data: Models.IUpdateInvoice) {
    if (!checkActionPermission(GuardPaths.UpdateTotalInformationInvoicePath)) {
      permissionLog(GuardPaths.UpdateTotalInformationInvoicePath.path);
      return undefined;
    }
    try {
      const response = await axios.put<void>(
        InvoiceUrls.Invoice.concat(`${id}${InvoiceUrls.UpdateInvoice}`),
        data
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
  async UpdateBasicInformationInvoice(
    id: number,
    data: Models.IUpdateBasicInformationInvoice
  ) {
    if (!checkActionPermission(GuardPaths.UpdateBasicInformationInvoicePath)) {
      permissionLog(GuardPaths.UpdateBasicInformationInvoicePath.path);
      return undefined;
    }
    try {
      const response = await axios.put<void>(
        InvoiceUrls.Invoice.concat(
          `${id}${InvoiceUrls.UpdateBasicInformationInvoice}`
        ),
        data
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
  async GetInvoice(id: number) {
    if (!checkActionPermission(GuardPaths.GetInvoicePath)) {
      permissionLog(GuardPaths.GetInvoicePath.path);
      return undefined;
    }
    try {
      const response = await axios.get<Models.IInvoice>(
        InvoiceUrls.Invoice.concat(`${id}${InvoiceUrls.GetInvoice}`)
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
  async AllArticle(id: number) {
    if (!checkActionPermission(GuardPaths.AllArticlePath)) {
      permissionLog(GuardPaths.AllArticlePath.path);
      return undefined;
    }
    try {
      const response = await axios.get<Models.IFinallyArticle[]>(
        InvoiceUrls.Invoice.concat(`${id}${InvoiceUrls.AllArticle}`)
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
  async UpdateArticle(id: number, data: Models.IUpdateArticle) {
    if (!checkActionPermission(GuardPaths.UpdateArticlePath)) {
      permissionLog(GuardPaths.UpdateArticlePath.path);
      return undefined;
    }
    try {
      const response = await axios.put<void>(
        InvoiceUrls.Invoice.concat(`${id}${InvoiceUrls.UpdateArticle}`),
        data
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
  async DeleteArticle(id: number, data: number[]) {
    if (!checkActionPermission(GuardPaths.DeleteArticlePath)) {
      permissionLog(GuardPaths.DeleteArticlePath.path);
      return undefined;
    }
    try {
      const response = await axios.delete<void>(
        InvoiceUrls.Invoice.concat(`${id}${InvoiceUrls.DeleteArticle}`),
        { data }
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
  async AllRequestFileChecker(id: number) {
    if (!checkActionPermission(GuardPaths.AllRequestFileCheckerPath)) {
      permissionLog(GuardPaths.AllRequestFileCheckerPath.path);
      return undefined;
    }
    try {
      const response = await axios.get<Models.IAllRequestFileChecker>(
        InvoiceUrls.Invoice.concat(`${id}${InvoiceUrls.AllRequestFileChecker}`)
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
  async GetInvoiceAttachment(id: number) {
    if (!checkActionPermission(GuardPaths.InvoiceAttachmentPath)) {
      permissionLog(GuardPaths.InvoiceAttachmentPath.path);
      return undefined;
    }
    const config: AxiosRequestConfig<any> = {
      responseType: "arraybuffer",
      headers: {
        accept: "*/*",
      },
    };
    try {
      const response = await axios.get<Buffer>(
        InvoiceUrls.Invoice.concat(`${id}${InvoiceUrls.InvoiceAttachment}`),
        config
      );
      if (response && response.data) {
        let raw = Buffer.from(response.data).toString("base64");

        return "data:" + response.headers["content-type"] + ";base64," + raw;
      }
    } catch (err) {
      console.log(err);
    }
  }
  async DeleteAllRequest(id: number) {
    if (!checkActionPermission(GuardPaths.DeleteAllRequestPath)) {
      permissionLog(GuardPaths.DeleteAllRequestPath.path);
      return undefined;
    }
    try {
      const response = await axios.delete(
        InvoiceUrls.Invoice.concat(`${id}${InvoiceUrls.DeleteAllRequest}`)
      );
      return response;
    } catch (error) {
      console.log(error);
    }
  }
  async DeleteInvoice(id: number) {
    if (!checkActionPermission(GuardPaths.DeleteInvoicePath)) {
      permissionLog(GuardPaths.DeleteInvoicePath.path);
      return undefined;
    }
    try {
      const response = await axios.delete(
        InvoiceUrls.Invoice.concat(`${id}${InvoiceUrls.DeleteInvoice}`)
      );
      return response;
    } catch (error) {
      console.log(error);
    }
  }
  async AttachScreenShotToAllRequest(
    id: number,
    data: FormData,
    config?: AxiosRequestConfig
  ) {
    if (!checkActionPermission(GuardPaths.AttachScreenShotToAllRequestPath)) {
      permissionLog(GuardPaths.AttachScreenShotToAllRequestPath.path);
      return undefined;
    }
    try {
      const response = await axios.patch(
        InvoiceUrls.Invoice.concat(
          `${id}${InvoiceUrls.AttachScreenShotToAllRequest}`
        ),
        data,
        config
      );
      return response;
    } catch (error) {
      console.log(error);
    }
  }
  async DeleteAllRequestAttachment(id: number) {
    if (!checkActionPermission(GuardPaths.DeleteAllRequestAttachmentPath)) {
      permissionLog(GuardPaths.DeleteAllRequestAttachmentPath.path);
      return undefined;
    }
    try {
      const response = await axios.delete(
        InvoiceUrls.Invoice.concat(
          `${id}${InvoiceUrls.DeleteAllRequestAttachment}`
        )
      );
      return response;
    } catch (error) {
      console.log(error);
    }
  }
  async UpdateInvoiceComment(id: number, comment: string) {
    if (!checkActionPermission(GuardPaths.UpdateInvoiceCommentPath)) {
      permissionLog(GuardPaths.UpdateInvoiceCommentPath.path);
      return undefined;
    }
    try {
      const response = await axios.put(
        InvoiceUrls.Invoice.concat(`${id}${InvoiceUrls.UpdateInvoiceComment}`),
        { comment }
      );
      return response;
    } catch (error) {
      console.log(error);
    }
  }
  async UpdateAllRequestZipDetail(
    id: number,
    data: Models.IUpdateAllRequestZipDetailArg
  ) {
    if (!checkActionPermission(GuardPaths.UpdateAllRequestZipDetailPath)) {
      permissionLog(GuardPaths.UpdateAllRequestZipDetailPath.path);
      return undefined;
    }
    try {
      const response = await axios.put(
        InvoiceUrls.Invoice.concat(
          `${id}${InvoiceUrls.UpdateAllRequestZipDetail}`
        ),
        data
      );
      return response;
    } catch (error) {
      console.log(error);
    }
  }
  async UpdateAllRequestZipDetailInfo(
    id: number,
    data: Models.IUpdateAllRequestZipDetailInfoArg
  ) {
    if (!checkActionPermission(GuardPaths.UpdateAllRequestZipDetailInfpPath)) {
      permissionLog(GuardPaths.UpdateAllRequestZipDetailInfpPath.path);
      return undefined;
    }
    try {
      const response = await axios.put(
        InvoiceUrls.Invoice.concat(
          `${id}${InvoiceUrls.UpdateAllRequestZipDetailInfo}`
        ),
        data
      );
      return response;
    } catch (error) {
      console.log(error);
    }
  }
  async AllRequestOPT(refrenceNumber?: string) {
    if (!checkActionPermission(GuardPaths.AllRequestOPTPath)) {
      permissionLog(GuardPaths.AllRequestOPTPath.path);
      return undefined;
    }
    const endpoint = refrenceNumber
      ? InvoiceUrls.AllRequestOPT.concat(`?RefrenceNumber=${refrenceNumber}`)
      : InvoiceUrls.AllRequestOPT;

    try {
      const response = await axios.get<Models.IAllRequestOPT[]>(endpoint);
      return response;
    } catch (error) {
      console.log(error);
    }
  }
}
