import axios from "axios";
import { OperationUrls } from "./urls";

import { checkActionPermission } from "src/helpers/checkPermissionFunc";
import * as GuardPaths from "./guardPath";
import { permissionLog } from "src/helpers/permissionLog";
import { IDataModel } from "../models";
import { IAllVoyage } from "./models/result.model";
import {
  IAllVoyageArg,
  IEditVoyageArg,
  INewVoyageArg,
} from "./models/args.models";
export class OperationService {
  async AllVoyage(search?: IAllVoyageArg) {
    if (!checkActionPermission(GuardPaths.AllVoyagePath)) {
      permissionLog(GuardPaths.AllVoyagePath.path);
      return undefined;
    }
    let endpoint: string = OperationUrls.AllVoyage;
    if (search) {
      Object.keys(search).forEach((key, i) => {
        const searchPath: string = `${key}=${search[key]}`;
        if (search[key]) {
          endpoint = endpoint.includes("?")
            ? endpoint + `&${searchPath}`
            : endpoint + `?${searchPath}`;
        }
      });
    }
    try {
      const response = await axios.get<IDataModel<IAllVoyage[]>>(endpoint);
      return response;
    } catch (err) {
      console.log(err);
    }
  }
  async NewVoyage(data: INewVoyageArg) {
    if (!checkActionPermission(GuardPaths.NewVoyagePath)) {
      permissionLog(GuardPaths.NewVoyagePath.path);
      return undefined;
    }
    let endpoint: string = OperationUrls.NewVoyage;

    try {
      const response = await axios.post<void>(endpoint, data);
      return response;
    } catch (err) {
      console.log(err);
    }
  }
  async EditVoyage(voyageId: number, data: IEditVoyageArg) {
    if (!checkActionPermission(GuardPaths.EditVoyagePath)) {
      permissionLog(GuardPaths.EditVoyagePath.path);
      return undefined;
    }
    let endpoint: string = OperationUrls.Operation.concat(`${voyageId}`).concat(
      OperationUrls.EditVoyage
    );

    try {
      const response = await axios.put<void>(endpoint, data);
      return response;
    } catch (err) {
      console.log(err);
    }
  }
  async DeleteVoyage(voyageId: number) {
    if (!checkActionPermission(GuardPaths.DeleteVoyagePath)) {
      permissionLog(GuardPaths.DeleteVoyagePath.path);
      return undefined;
    }
    let endpoint: string = OperationUrls.Operation.concat(`${voyageId}`).concat(
      OperationUrls.DeleteVoyage
    );

    try {
      const response = await axios.delete<void>(endpoint);
      return response;
    } catch (err) {
      console.log(err);
    }
  }
}
