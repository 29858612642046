import { FC, useCallback, useEffect, useState } from "react";
import { FlowAssignRoleContainer } from "./styles";
import {
  App,
  Button,
  Col,
  Divider,
  Form,
  Popconfirm,
  Row,
  Select,
  TreeSelect,
} from "antd";
import { FormItem } from "src/components/UiKit/FormItem";

import { useAddNewFlowCTX } from "../../context";
import { TableUI } from "src/components/UiKit/table";
import { ColumnProps } from "antd/es/table";
import {
  IAddRoleUser,
  IDeleteWorkFlowStepRoleUserArg,
  IWorkFlowStepRoleUser,
} from "src/services/WorkFlow/models";
import { WorkFlowService } from "src/services/WorkFlow/WorkFlow.service";
import { UserManagementService } from "src/services/UserManagement/UserManagement.service";
import { NewFlowTabs } from "../../model/newFlowTabs";
import { useFlowCTX } from "src/modules/Flow/context";
import { uniqueId } from "lodash";

export const FlowAssignRole: FC = () => {
  const {
    value: {
      selectedRole,
      treeProps,
      allUser,
      getUsersLoading,
      flowStepsRoleUserData,
    },
    dispatch: {
      setUsersLoading,
      setUsers,
      setFlowStepsRoleUserData,
      setActiveTab,
    },
    func: { getWorkFlowSteps, onCancelAddNew, onCancelEditAndView },
    forms: { AssignRoleForm },
  } = useAddNewFlowCTX();
  const {
    value: { mode },
  } = useFlowCTX();
  const [loading, setLoading] = useState<boolean>(false);
  const [getWorkFlowRoleLoading, setGetWorkFlowRoleLoading] =
    useState<boolean>(false);
  const { message } = App.useApp();
  const onFinishAssignRole = async ({ roleId, userId }: IAddRoleUser) => {
    if (!selectedRole) return;
    try {
      setLoading(true);
      const { AddRoleUser } = new WorkFlowService();
      const res = await AddRoleUser(selectedRole.id, {
        roleId,
        userId: userId || null,
      });
      if (res && res.status === 200) {
        AssignRoleForm?.resetFields();
        message.success("Successfully added role and user to workflow");
        getWorkFlowSteps(false);
        getWorkFlowStepRoleUser();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  const getWorkFlowStepRoleUser = useCallback(async () => {
    if (!selectedRole) return;
    try {
      setGetWorkFlowRoleLoading(true);
      const { WorkFlowStepRoleUser } = new WorkFlowService();
      const res = await WorkFlowStepRoleUser(selectedRole.id);
      if (res && res.status === 200 && res.data) {
        setFlowStepsRoleUserData(res.data);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setGetWorkFlowRoleLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRole]);
  const onChangeRole = (roleId: number) => {
    if (!roleId) {
      setUsers([]);
      return;
    }
    setUsersLoading(true);
    getUsers(roleId)
      .then((res) => {
        if (res) {
          setUsers(res);
        }
      })
      .finally(() => {
        setUsersLoading(false);
      });
  };
  useEffect(() => {
    getWorkFlowStepRoleUser();
  }, [getWorkFlowStepRoleUser]);
  const deleteRoleUser = async (record: IWorkFlowStepRoleUser) => {
    if (!selectedRole) return;
    try {
      const reqBody: IDeleteWorkFlowStepRoleUserArg = {
        stepRoleId: record.stepRoleId,
        stepUserId: record.stepUserId,
      };
      const { DeleteWorkFlowStepRoleUser } = new WorkFlowService();
      const res = await DeleteWorkFlowStepRoleUser(selectedRole.id, reqBody);
      if (res && res.status === 200) {
        message.success("Delete Work Flow Step Role User successfully");
        getWorkFlowStepRoleUser();
        getWorkFlowSteps(false);
        AssignRoleForm?.resetFields();
      }
    } catch (err) {
      console.log(err);
    }
  };
  const nextAction = () => {
    setActiveTab(NewFlowTabs.FlowAction);
  };
  const columns: ColumnProps<IWorkFlowStepRoleUser>[] = [
    {
      key: "#",
      dataIndex: "#",
      title: "#",
      width: 56,
      render: (text, record, index) => index + 1,
    },
    {
      key: "roleName",
      dataIndex: "roleName",
      title: "Roll",
    },
    {
      key: "userName",
      dataIndex: "userName",
      title: "User",
      render: (text) => (text ? text : "---"),
    },
    mode === "view"
      ? {}
      : {
          key: "tools",
          dataIndex: "tools",
          title: "Tools",
          render: (text, record, index) => (
            <Popconfirm
              title="Are you sure delete this record?"
              onConfirm={() => {
                deleteRoleUser(record);
              }}
              rootClassName="deletePopconfirm"
              icon={null}
              placement="leftBottom"
            >
              <span className="material-icons text-[#FF1414] cursor-pointer text-[16px]">
                delete_forever
              </span>
            </Popconfirm>
          ),
        },
  ];
  if (!selectedRole) {
    return (
      <div className="h-[400px] flex justify-center items-center text-[24px] text-[#6684df] ">
        Please select a step
      </div>
    );
  }
  return (
    <FlowAssignRoleContainer gutter={[0, 24]}>
      {mode !== "view" && (
        <>
          <Col span={24}>
            <div className="title pt-[16px]">
              Assign role & user to this step.
            </div>
          </Col>
          <Col span={24} className="content">
            <Form form={AssignRoleForm} onFinish={onFinishAssignRole}>
              <Row gutter={32}>
                <Col span={9}>
                  <FormItem
                    name={"roleId"}
                    label={"Role"}
                    required
                    rules={[{ required: true }]}
                  >
                    <TreeSelect
                      {...treeProps}
                      multiple={false}
                      allowClear
                      className="selectOption"
                      onChange={onChangeRole}
                    />
                  </FormItem>
                </Col>
                <Col span={9}>
                  <FormItem label={"Users"} name={"userId"}>
                    <Select
                      className="selectOption"
                      placeholder="select"
                      showSearch
                      optionFilterProp="label"
                      disabled={!allUser.length}
                      options={allUser?.map((user) => ({
                        label: user.firstName + " " + user.lastName,
                        value: user.userID,
                        key: user.userID,
                      }))}
                      loading={getUsersLoading}
                    />
                  </FormItem>
                </Col>
                <Col span={6}>
                  <Button
                    icon={<span className="material-icons">add</span>}
                    className="add-button"
                    onClick={AssignRoleForm?.submit}
                    loading={loading}
                  >
                    Add
                  </Button>
                </Col>
                <Col span={24}>
                  <Divider className="mt-0 mb-[24px]" />
                </Col>
              </Row>
            </Form>
          </Col>
        </>
      )}
      <Col span={24}>
        <div className="title">Roles & users that assign to this step.</div>
        <TableUI
          containerStyle={{ padding: 0, paddingTop: 16 }}
          columns={columns}
          loading={getWorkFlowRoleLoading}
          dataSource={flowStepsRoleUserData}
          rowKey={() => uniqueId()}
        />
      </Col>
      <Col span={24}>
        <div className="footer">
          <Button
            onClick={mode === "create" ? onCancelAddNew : onCancelEditAndView}
          >
            Cancel
          </Button>
          <Button type="primary" onClick={nextAction}>
            Next
          </Button>
        </div>
      </Col>
    </FlowAssignRoleContainer>
  );
};
const getUsers = async (roleId: number) => {
  if (!roleId) return;
  try {
    const { UsersOfRole } = new UserManagementService();
    const res = await UsersOfRole(roleId);
    if (res) return res.data;
  } catch (err) {
    console.log(err);
  }
};
