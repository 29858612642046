import {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import dayjs from "dayjs";

import {
  IAddNewDailyReport,
  IHeaderTabs,
  INewDailyReportValues,
  TableData,
} from "../models";
import { DailyReportService } from "src/services/DailyReport/dailyReport.service";
import {
  IEditDailyReport,
  INewDailyReport,
} from "src/services/DailyReport/models/args.models";
import { Form, message } from "antd";
import { FormInstance } from "antd/lib";
import { useSearchParams } from "react-router-dom";
import { Tools } from "../Components/DailyReportsTable/TableTools";
import { INewAllShipDailyReportResult } from "src/services/DailyReport/models/result.model";
import { ITablePagination } from "src/models/interfaces/pagination";
import { IObject } from "src/models/interfaces";
import Dayjs from "dayjs";
import _ from "lodash";
import { useChangeParams } from "src/hook/useParamsChange";
import { useCheckPermission } from "src/hook/checkRoutePermission";
import { NewDailyReportPath } from "src/services/DailyReport/guardPath";

interface ICustomReportData
  extends Omit<INewAllShipDailyReportResult, "created" | "reportDate"> {
  created: Dayjs.Dayjs;
  reportDate: Dayjs.Dayjs;
}
interface IFilterValue extends IObject {
  ShipId?: number[];
  FromReportDate?: string;
  ToReportDate?: string;
  Stauts?: number[];
  IsSeen?: boolean;
  ShipState?: number[];
  TradeStatus?: number[];
  Position?: number[];
}
interface IContext {
  value: {
    addDailyReportActiveKey: string;
    editDailyReportActiveKey: string;
    headerActiveKey: string;
    dailyReportValues: INewDailyReportValues;
    loading: boolean;
    tableLoading: boolean;
    editLoading: boolean;
    editMode: boolean;
    defaultValue: INewDailyReportValues;
    reportId: number;
    pagination: ITablePagination;
    dataSource: TableData[];
    filterValue: IFilterValue;
    seenFilter: string[];
    tableCurrentValue: INewAllShipDailyReportResult | undefined;
    isEditClicked: boolean;
    showMode: boolean;
  };
  dispatch: {
    setAddDailyReportActiveKey: Dispatch<SetStateAction<string>>;
    setEditDailyReportActiveKey: Dispatch<SetStateAction<string>>;
    setHeaderActiveKey: Dispatch<SetStateAction<string>>;
    setEditMode: Dispatch<SetStateAction<boolean>>;
    setDailyReportValues: Dispatch<SetStateAction<INewDailyReportValues>>;
    setFilterValue: Dispatch<SetStateAction<IFilterValue>>;
    setReportId: Dispatch<SetStateAction<number>>;
    setPagination: Dispatch<SetStateAction<ITablePagination>>;
    setDataSource: Dispatch<SetStateAction<TableData[]>>;
    setTableLoading: Dispatch<SetStateAction<boolean>>;
    setSeenFilter: Dispatch<SetStateAction<string[]>>;
    setShowMode: Dispatch<SetStateAction<boolean>>;
    setIsEditClicked: Dispatch<SetStateAction<boolean>>;
    setTableCurrentValue: Dispatch<
      SetStateAction<INewAllShipDailyReportResult | undefined>
    >;
  };
  func: {
    onChangeDailyValue: (value: Partial<INewDailyReportValues>) => void;
    onFinishDailyReport: (values: INewDailyReportValues) => Promise<void>;
    onFinishEditDailyReport: (value: { instruction: string }) => Promise<void>;
    reset: () => void;
    AllShipDailyReport: () => Promise<void>;
    createTableData: (
      data: INewAllShipDailyReportResult[] | undefined
    ) => TableData[];
    onChangeFilter: (value: Partial<INewDailyReportValues>) => void;
    createData: () => void;
    onFinishFilter: () => Promise<void>;
  };
  forms: {
    VoyageForm: FormInstance<any> | undefined;
    InstructionForm: FormInstance<any> | undefined;
    FuturePlanForm: FormInstance<any> | undefined;
    CargosForm: FormInstance<any> | undefined;
    ActionsForm: FormInstance<any> | undefined;
  };
}

const defaultValue = {
  reportDate: dayjs(),
  shipId: undefined,
  shipStatus: undefined,
  tradeStatus: undefined,
  position: undefined,
  vlsfo: "",
  mgo: "",
  voyage: "",
  currentSituationReport: "string",
  action: "",
  futurePlan: "",
};
const tableCurrentDefaultValue = {
  reportDate: "",
  shipStatus: 1,
  tradeStatus: 1,
  position: 1,
  vlsfo: "",
  mgo: "",
  voyage: "",
  currentSituationReport: "string",
  action: "",
  futurePlan: "",
  id: 1,
  shipId: 1,
  shipName: "",
  created: "",
  shipState: 1,
  stauts: 1,
  isSeen: false,
};
const defaultContextValue: IContext = {
  value: {
    addDailyReportActiveKey: "1",
    dailyReportValues: defaultValue,
    defaultValue,
    editDailyReportActiveKey: "1",
    editLoading: false,
    editMode: false,
    headerActiveKey: "1",
    loading: false,
    tableLoading: false,
    reportId: 0,
    pagination: { current: 1, pageSize: 10, total: 10 },
    dataSource: [],
    filterValue: {},
    seenFilter: [],
    tableCurrentValue: tableCurrentDefaultValue,
    isEditClicked: false,
    showMode: false,
  },
  dispatch: {
    setAddDailyReportActiveKey: () => {},
    setDailyReportValues: () => {},
    setEditDailyReportActiveKey: () => {},
    setEditMode: () => {},
    setHeaderActiveKey: () => {},
    setReportId: () => {},
    setPagination: () => {},
    setDataSource: () => {},
    setFilterValue: () => {},
    setTableLoading: () => {},
    setSeenFilter: () => {},
    setShowMode: () => {},
    setTableCurrentValue: () => {},
    setIsEditClicked: () => {},
  },
  func: {
    onChangeDailyValue: () => {},
    onFinishDailyReport: async () => {},
    onFinishEditDailyReport: async () => {},
    reset: () => {},
    AllShipDailyReport: async () => {},
    createTableData: () => [],
    onChangeFilter: () => {},
    createData: () => {},
    onFinishFilter: async () => {},
  },
  forms: {
    ActionsForm: undefined,
    CargosForm: undefined,
    FuturePlanForm: undefined,
    InstructionForm: undefined,
    VoyageForm: undefined,
  },
};

export const DailyReportContext = createContext<IContext>(defaultContextValue);

export const DailyReportProvider: FC<PropsWithChildren> = ({ children }) => {
  const { checkActionPermission } = useCheckPermission();
  const [addDailyReportActiveKey, setAddDailyReportActiveKey] =
    useState<string>(IAddNewDailyReport.Voyage);
  const [headerActiveKey, setHeaderActiveKey] = useState<string>(
    checkActionPermission(NewDailyReportPath)
      ? IHeaderTabs.addNewDailyReport
      : IHeaderTabs.dailyReportsTable
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [editLoading, setEditLoading] = useState<boolean>(false);
  const [editDailyReportActiveKey, setEditDailyReportActiveKey] =
    useState<string>(IAddNewDailyReport.Voyage);

  const [dailyReportValues, setDailyReportValues] =
    useState<INewDailyReportValues>(defaultValue);
  const [reportId, setReportId] = useState<number>(0);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [showMode, setShowMode] = useState<boolean>(false);
  const [dataSource, setDataSource] = useState<TableData[]>([]);
  let [searchParams] = useSearchParams();
  const limit = searchParams.get("Limit");
  const offset = searchParams.get("Offset");
  const [pagination, setPagination] = useState<ITablePagination>({
    current: offset ? parseInt(offset) : 1,
    pageSize: limit ? parseInt(limit) : 10,
    total: 10,
  });
  const [tableLoading, setTableLoading] = useState<boolean>(false);
  const [filterValue, setFilterValue] = useState<IFilterValue>({});
  const [seenFilter, setSeenFilter] = useState<string[]>([]);
  const [newData, setNewData] = useState<ICustomReportData>();
  const [tableCurrentValue, setTableCurrentValue] = useState<
    INewAllShipDailyReportResult | undefined
  >();
  const [isEditClicked, setIsEditClicked] = useState<boolean>(false);

  //----------------------------------------------------------------Forms----------------------------------------------------------------
  const [VoyageForm] = Form.useForm();
  const [InstructionForm] = Form.useForm();
  const [FuturePlanForm] = Form.useForm();
  const [CargosForm] = Form.useForm();
  const [ActionsForm] = Form.useForm();
  //----------------------------------------------------------------Function----------------------------------------------------------------
  const onChangeDailyValue = (value: Partial<INewDailyReportValues>) => {
    setDailyReportValues((prev) => ({ ...prev, ...value }));
  };

  const onFinishDailyReport = async (values: INewDailyReportValues) => {
    try {
      setLoading(true);
      const { NewDailyReport } = new DailyReportService();
      console.log({ values });
      const reqBody: INewDailyReport = {
        ...values,
        reportDate: values.reportDate.format("YYYY-MM-DD"),
      };
      const response = await NewDailyReport(reqBody);
      if (response && response.status === 200) {
        message.success("Daily report created successfully");
        reset();
        AllShipDailyReport();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const onFinishEditDailyReport = async (value: { instruction: string }) => {
    try {
      setEditLoading(true);
      const { EditDailyReport } = new DailyReportService();
      const reqBody: IEditDailyReport = {
        ...dailyReportValues,
        ...value,
      };
      const response = await EditDailyReport(reqBody, reportId);
      if (response && response.status === 200) {
        message.success("Daily report edited successfully");
        setEditMode(false);
        setHeaderActiveKey(IHeaderTabs.dailyReportsTable);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setEditLoading(false);
    }
  };
  const reset = () => {
    VoyageForm.resetFields();
    InstructionForm.resetFields();
    FuturePlanForm.resetFields();
    CargosForm.resetFields();
    ActionsForm.resetFields();
    setDailyReportValues(defaultValue);
  };

  const createTableData = (
    data: INewAllShipDailyReportResult[] | undefined
  ) => {
    if (!data) return [];
    const newData: TableData[] = data.map((report, i) => {
      const statusText = () => {
        switch (report.stauts) {
          case 1:
            return {
              icon: (
                <span className="material-icons text-[#FFB800] text-[12px]">
                  error
                </span>
              ),
              text: "New",
            };
          case 2:
            return {
              icon: (
                <span className="material-icons text-[#FD29B5] text-[12px]">
                  remove_circle
                </span>
              ),
              text: "Rejected",
            };
          case 3:
            return {
              icon: (
                <span className="material-icons text-[#1DCF0E] text-[12px]">
                  check_circle
                </span>
              ),
              text: "Accepted",
            };
          default:
            return { icon: <span></span>, text: "" };
        }
      };
      const tradeStatusText = () => {
        switch (report.tradeStatus) {
          case 1:
            return <div>NoVoyageYet</div>;
          case 2:
            return <div>InVoyage</div>;
          default:
            return "";
        }
      };
      const shipStatusText = () => {
        switch (report.shipStatus) {
          case 1:
            return <div>Laden</div>;
          case 2:
            return <div>Ballast</div>;
          default:
            return "";
        }
      };
      const positionText = () => {
        switch (report.position) {
          case 1:
            return <div>LP_Anchorage</div>;
          case 2:
            return <div>LP_OPL</div>;
          case 3:
            return <div>LP_Berthed</div>;
          case 4:
            return <div>Dry_Dock</div>;
          case 5:
            return <div>DP_Anchorage</div>;
          case 6:
            return <div>DP_OPL</div>;
          case 7:
            return <div>DP_Berthed</div>;
          case 8:
            return <div>Repair and Maintenance</div>;
          case 9:
            return <div>Sailing</div>;
          default:
            return "";
        }
      };
      return {
        "#": i + 1,
        ...report,
        tools: <Tools report={report} />,
        statusUI: (
          <div className="flex gap-[12px] items-center justify-center">
            {statusText().icon}
            <div> {statusText().text}</div>
          </div>
        ),
        positionText: positionText(),
        tradeStatusText: tradeStatusText(),
        shipStatusText: shipStatusText(),
      };
    });
    return newData;
  };
  const AllShipDailyReport = useCallback(async () => {
    let filterSearchParams =
      searchParams.toString().length < 1 ? "?" : searchParams.toString();
    Object.keys(filterValue).forEach((key) => {
      if (_.isArray(filterValue[key])) {
        (filterValue[key] as number[]).forEach((item) => {
          filterSearchParams = filterSearchParams
            .concat(filterSearchParams === "?" ? "" : "&")
            .concat(`${key}=${item}`);
        });
      } else {
        if (filterValue[key] !== undefined) {
          filterSearchParams = filterSearchParams
            .concat(filterSearchParams === "?" ? "" : "&")
            .concat(`${key}=${filterValue[key]}`);
        }
      }
    });
    try {
      setTableLoading(true);
      const { AllShipDailyReport } = new DailyReportService();
      const result = await AllShipDailyReport(filterSearchParams);
      if (result && result.status === 200) {
        setDataSource(createTableData(result.data.records));
        setPagination((prev) => ({ ...prev, total: result.data.count }));
      }
    } catch (err) {
      console.log(err);
    } finally {
      setTableLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);
  const onChangeParams = useChangeParams();

  const onFinishFilter = useCallback(async () => {
    onChangeParams("Offset", "1");
    let filterSearchParams =
      searchParams.toString().length < 1 ? "?" : searchParams.toString();
    Object.keys(filterValue).forEach((key) => {
      if (_.isArray(filterValue[key])) {
        (filterValue[key] as number[]).forEach((item) => {
          filterSearchParams = filterSearchParams
            .concat(filterSearchParams === "?" ? "" : "&")
            .concat(`${key}=${item}`);
        });
      } else {
        if (filterValue[key] !== undefined) {
          filterSearchParams = filterSearchParams
            .concat(filterSearchParams === "?" ? "" : "&")
            .concat(`${key}=${filterValue[key]}`);
        }
      }
    });
    try {
      setTableLoading(true);
      const { AllShipDailyReport } = new DailyReportService();
      const result = await AllShipDailyReport(filterSearchParams);
      if (result && result.status === 200) {
        setDataSource(createTableData(result.data.records));
        setPagination((prev) => ({ ...prev, total: result.data.count }));
      }
    } catch (err) {
      console.log(err);
    } finally {
      setTableLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValue]);
  const onChangeFilter = (value: Partial<INewDailyReportValues>) => {
    setFilterValue((prev) => ({ ...prev, ...value }));
  };

  const createData = useCallback(() => {
    const newD: IObject = { ...tableCurrentValue };
    newD.created = Dayjs(tableCurrentValue?.created);
    newD.reportDate = Dayjs(tableCurrentValue?.reportDate);
    setNewData(newD as ICustomReportData);
    VoyageForm.setFieldsValue(newData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [VoyageForm]);

  const ContextValue: IContext = {
    value: {
      addDailyReportActiveKey,
      dailyReportValues,
      loading,
      editDailyReportActiveKey,
      editMode,
      defaultValue,
      headerActiveKey,
      reportId,
      editLoading,
      pagination,
      dataSource,
      tableLoading,
      filterValue,
      seenFilter,
      tableCurrentValue,
      isEditClicked,
      showMode,
    },
    dispatch: {
      setAddDailyReportActiveKey,
      setEditDailyReportActiveKey,
      setEditMode,
      setDailyReportValues,
      setHeaderActiveKey,
      setReportId,
      setPagination,
      setDataSource,
      setFilterValue,
      setTableLoading,
      setSeenFilter,
      setShowMode,
      setTableCurrentValue,
      setIsEditClicked,
    },
    func: {
      onChangeDailyValue,
      onFinishDailyReport,
      onFinishEditDailyReport,
      reset,
      AllShipDailyReport,
      createTableData,
      onChangeFilter,
      createData,
      onFinishFilter,
    },
    forms: {
      ActionsForm,
      CargosForm,
      FuturePlanForm,
      InstructionForm,
      VoyageForm,
    },
  };

  useEffect(() => {
    if (!editMode) {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editMode]);
  return (
    <DailyReportContext.Provider value={ContextValue}>
      {children}
    </DailyReportContext.Provider>
  );
};

export const useDailyReport = () => useContext(DailyReportContext);
