export enum BaseInfoUrls {
  BaseInfo = "BaseInfo/",
  GetPort = "BaseInfo/AllPorts",
  NewPort = "BaseInfo/NewPort",
  DeletePort = "/DeletePort",
  EditPort = "/UpdatePort",
  NewAgent = "BaseInfo/NewAgent",
  NewAgentContact = "/NewAgentContactInfo",
  AllAgentContact = "/AllAgentContactInfo",
  AllAgents = "BaseInfo/AllAgents",
  editAgent = "/UpdateAgent",
  DeleteAgentContactInfo = "/DeleteAgentContactInfo",
  DeleteAgent = "/DeleteAgent",
}
