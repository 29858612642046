import { FC, Suspense } from "react";
import { Flow } from "src/modules/Flow";
import { AddNewFlowProvider } from "src/modules/Flow/components/AddFlow/context";
import { FlowProvider } from "src/modules/Flow/context";
import { PreLoading } from "src/routes/Loading";

export const FlowPage: FC = () => {
  return (
    <Suspense fallback={<PreLoading />}>
      <FlowProvider>
        <AddNewFlowProvider>
          <Flow />
        </AddNewFlowProvider>
      </FlowProvider>
    </Suspense>
  );
};
