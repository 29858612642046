import { message } from "antd";
import { FC, useState } from "react";
import { useFrontAccess } from "src/modules/frontAccess/context";
import { SystemService } from "src/services/System/System.service";
import { IFrontPath } from "src/services/System/models";
import { DeleteConfirmModal } from "../../DeleteConfirmModal";

export const Tools: FC<{ path: IFrontPath }> = ({ path }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [showConfirm, setShowConfirm] = useState<boolean>(false);

  const {
    dispatch: { setMode, setFrontAccessForEdit },
    func: { getFrontPath },
  } = useFrontAccess();

  //----------------------------------------------------------------async-func----------------------------------------------------------------
  const deleteFrontPath = async () => {
    if (!path) return;
    setLoading(true);
    try {
      const { DeleteFrontPath } = new SystemService();
      const response = await DeleteFrontPath(path.id);
      if (response && response.status === 200) {
        message.success("Delete front path successfully");
        getFrontPath();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
      setShowConfirm(false);
    }
  };

  const handleEdit = () => {
    setFrontAccessForEdit(path);
    setMode("edit");
  };

  const handleAssign = () => {
    setFrontAccessForEdit(path);
    setMode("assign");
  };

  const onCancelConfirm = () => {
    setShowConfirm(false);
  };

  return (
    <>
      <div className="flex gap-[16px] items-center justify-center">
        <span
          className="material-icons text-[16px] text-[#1DCF0E] cursor-pointer"
          onClick={handleEdit}
        >
          edit_note
        </span>
        <span
          className="material-icons text-[#F00] text-[16px] cursor-pointer"
          onClick={() => setShowConfirm(true)}
        >
          delete_forever
        </span>
        <span
          className="material-icons text-[#2C73FC] text-[16px] cursor-pointer"
          onClick={handleAssign}
        >
          mediation
        </span>
      </div>
      <DeleteConfirmModal
        open={showConfirm}
        onCancel={onCancelConfirm}
        onOk={deleteFrontPath}
        confirmLoading={loading}
        text={"Are you sure to remove front path?"}
        title={"Remove Front Path"}
      />
    </>
  );
};
