import { FC, useEffect, useState } from "react";
import { TabsContainer } from "./style";
import { useCheckPermission } from "src/hook/checkRoutePermission";
import { Tabs, TabsProps } from "antd";
import { ICommercialTabs } from "./models";
import { AllVoyagePath, NewVoyagePath } from "src/services/Operation/guardPath";
import { AddNewVoyage } from "./components/AddNewVoyage";
import { VoyageTable } from "./components/VoyageTable";
import { useCommercial } from "./context";
import { HeaderEditShip } from "./components/HeaderEdit";

export const Commercial: FC = () => {
  const [items, setItems] = useState<TabsProps["items"]>([]);
  const {
    value: { activeKey, editMode },
    dispatch: { setActiveKey },
  } = useCommercial();
  const { checkActionPermission } = useCheckPermission();
  const onChange = (key: string) => {
    setActiveKey(key);
  };
  useEffect(() => {
    const NewVoyagePathHasPermission = checkActionPermission(NewVoyagePath);
    const AllVoyagePathHasPermission = checkActionPermission(AllVoyagePath);

    if (AllVoyagePathHasPermission) {
      setItems((prev) => {
        const newTab = {
          key: ICommercialTabs.voyageTable,
          label: (
            <span>
              <span className="material-icons text-[10px] p-[10px]">lens</span>
              Voyage table
            </span>
          ),
          children: <VoyageTable />,
        };
        return prev ? [newTab, ...prev] : [newTab];
      });
      setActiveKey(ICommercialTabs.voyageTable);
    }
    if (NewVoyagePathHasPermission) {
      setItems((prev) => {
        const newItem = {
          key: ICommercialTabs.addVoyage,
          label: (
            <span>
              <span className="material-icons text-[10px] p-[8px]">lens</span>
              Add voyage
            </span>
          ),
          children: <AddNewVoyage />,
        };
        return prev ? [...prev, newItem] : [newItem];
      });
      !AllVoyagePathHasPermission && setActiveKey(ICommercialTabs.addVoyage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TabsContainer>
      {editMode && <HeaderEditShip />}
      <Tabs
        items={items}
        onChange={onChange}
        activeKey={activeKey}
        animated
        tabBarStyle={{ display: editMode ? "none" : "initial" }}
      />
    </TabsContainer>
  );
};
