import { FC, Suspense } from "react";
import { Ports } from "src/modules/ports";
import { PortsProvider } from "src/modules/ports/context";
import { PreLoading } from "src/routes/Loading";

export const PortsPage: FC = () => {
  return (
    <Suspense fallback={<PreLoading />}>
      <PortsProvider>
        <Ports />
      </PortsProvider>
    </Suspense>
  );
};
