import { FC } from "react";
import { useViewNoonReport } from "../../context";
import { Spin } from "antd";

export const SpeedDistanceIfo: FC = () => {
  const {
    value: { speedDistanceIfoProps },
  } = useViewNoonReport();
  return (
    <section className="px-[24px] mt-[60px]">
      <p className="text-[14px] text-[#001377] font-bold">Speed & Distance</p>
      <section className="rounded-lg bg-[#f2f7ff] py-[24px] px-[16px]">
        {speedDistanceIfoProps.loading ? (
          <Spin />
        ) : (
          <>
            <div className="flex items-center justify-between">
              <div className="w-[33%] flex items-center justify-between">
                <p className="w-[50%] text-left text-[14px] text-[#001377] font-medium">
                  DisRun 24h{" "}
                </p>
                <p className="w-[50%] text-left text-[14px] text-[#5d6881] font-normal">
                  {speedDistanceIfoProps?.data?.DisRun24 || "-"}
                </p>
              </div>

              <div className="w-[33%] flex items-center justify-between">
                <p className="w-[50%] text-left text-[14px] text-[#001377] font-medium">
                  TTL DisRun
                </p>
                <p className="w-[50%] text-left text-[14px] text-[#5d6881] font-normal">
                  {speedDistanceIfoProps?.data?.DisRunTTL || "-"}
                </p>
              </div>

              <div className="w-[33%] flex items-center justify-between">
                <p className="w-[50%] text-left text-[14px] text-[#001377] font-medium">
                  To Go (NM)
                </p>
                <p className="w-[50%] text-left text-[14px] text-[#5d6881] font-normal">
                  {speedDistanceIfoProps.data?.toGo || "-"}
                </p>
              </div>
            </div>

            <div className="flex items-center justify-between">
              <div className="w-[33%] flex items-center justify-between">
                <p className="w-[50%] text-left text-[14px] text-[#001377] font-medium">
                  AVSPD 24h
                </p>
                <p className="w-[50%] text-left text-[14px] text-[#5d6881] font-normal">
                  {speedDistanceIfoProps?.data?.AVSPD24 || "-"}
                </p>
              </div>

              <div className="w-[33%] flex items-center justify-between">
                <p className="w-[50%] text-left text-[14px] text-[#001377] font-medium">
                  TTL AVSPD (Kn)
                </p>
                <p className="w-[50%] text-left text-[14px] text-[#5d6881] font-normal">
                  {speedDistanceIfoProps?.data?.AVSPDTTl || "-"}
                </p>
              </div>

              <div className="w-[33%] flex items-center justify-between">
                <p className="w-[50%] text-left text-[14px] text-[#001377] font-medium">
                  POSN
                </p>
                <p className="w-[50%] text-left text-[14px] text-[#5d6881] font-normal">
                  {speedDistanceIfoProps.data?.POSN || "-"}
                </p>
              </div>
            </div>

            <div className="flex items-center justify-between">
              <div className="w-[33%] flex items-center justify-between">
                <p className="w-[50%] text-left text-[14px] text-[#001377] font-medium">
                  ATD
                </p>
                <p className="w-[50%] text-left text-[14px] text-[#5d6881] font-normal">
                  {speedDistanceIfoProps?.data?.ATD || "-"}
                </p>
              </div>

              <div className="w-[33%] flex items-center justify-between">
                <p className="w-[50%] text-left text-[14px] text-[#001377] font-medium">
                  ETA
                </p>
                <p className="w-[50%] text-left text-[14px] text-[#5d6881] font-normal">
                  {speedDistanceIfoProps?.data?.ETA || "-"}
                </p>
              </div>

              <div className="w-[33%] flex items-center justify-between">
                <p className="w-[50%] text-left text-[14px] text-[#001377] font-medium">
                  Next port
                </p>
                <p className="w-[50%] text-left text-[14px] text-[#5d6881] font-normal">
                  {speedDistanceIfoProps.data?.port || "-"}
                </p>
              </div>
            </div>
          </>
        )}
      </section>
    </section>
  );
};
