import { styled } from "styled-components";

export const HeaderContainer = styled.div`
  .ant-tabs-tab {
    color: var(--text-2, #a3aed0);
    font-family: DM Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 160% */
    padding: unset;
  }
  .ant-tabs-nav::before {
    border-bottom: 1px solid var(--shadow, #dadeec);
    padding: 24px;
  }
  .ant-tabs-ink-bar {
    height: unset !important;
  }
  .ant-tabs-nav {
    padding-bottom: 24px;
    margin-bottom: 24px !important;
  }
`;
