import { FC } from "react";
import { IWorkFlowStep } from "src/services/WorkFlow/models";
import { FlowSections } from "../../model/sectionTypes";
import { StepsCardStyle } from "./styles";
interface IProps extends IWorkFlowStep {
  selected: boolean;
  onClick: () => void;
}
export const StepsCard: FC<IProps> = (step) => {
  return (
    <StepsCardStyle $selected={step.selected} onClick={step.onClick}>
      <div className="wrapper">
        <div className="name-status">
          <div className="stepName">{step.name}</div>
          <div className="status">
            {step.state === FlowSections.start
              ? "Start"
              : step.state === FlowSections.inprogressEnd
              ? "In progress / End"
              : step.state === FlowSections.inprogress
              ? "In Progress"
              : step.state === FlowSections.end
              ? "End"
              : ""}
          </div>
        </div>
        <div className="description ">
          <div className="flex justify-start gap-[12px] ">
            <div className="des-text">
              <span
                className={`material-icons text-[12px] ${
                  step.hasAttachment ? "text-[#1DCF0E]" : ""
                }`}
              >
                check_box
              </span>
              <span>Has attachment</span>
            </div>
            <div className="des-text">
              <span
                className={`material-icons text-[12px] ${
                  step.commentMandatory ? "text-[#1DCF0E]" : ""
                }`}
              >
                disabled_by_default
              </span>
              <span>Comment is mandatory</span>
            </div>
          </div>
          <div className="des-text">
            <span
              className={`material-icons text-[12px] ${
                step.attachmentComment ? "text-[#1DCF0E]" : ""
              }`}
            >
              sms
            </span>
          </div>
        </div>
      </div>
    </StepsCardStyle>
  );
};
