import { Col, Row, Skeleton } from "antd";
import { FC } from "react";
import { ShowBasicInformationContainer } from "../style";
import { ShipInformationItems } from "./shipInformationItem";
import { useAddShip } from "src/modules/AddShips/context";

export const ShowTechnicalInformation: FC = () => {
  const {
    value: { tableCurrentValue, pictureFileList, picLoading },
  } = useAddShip()!;
  const cargoTypeName = (value?: number) => {
    switch (value) {
      case 1:
        return "Bulker";
      case 2:
        return "Small dry";
      case 3:
        return "Tanker";
    }
  };
  const sizeTypeName = (value?: number) => {
    switch (value) {
      case 1:
        return "SMALL DIRTY TNKR";
      case 2:
        return "SMALL CLEAN TNKR";
      case 3:
        return "PANAMAX";
      case 4:
        return "VLCC";
      case 5:
        return "SUEZMAX TNKR";
      case 6:
        return "MR2 TNKR";
    }
  };
  return (
    <ShowBasicInformationContainer>
      <Col span={24} className="pictureHolder">
        {picLoading ? (
          <Skeleton.Image active className="!w-[334px] !h-[165px]" />
        ) : (
          pictureFileList.map((item, index) => (
            <img
              src={item.url}
              key={index}
              width={334}
              height={165}
              alt={item.name}
              className="rounded-[16px]"
            />
          ))
        )}
      </Col>
      <Col span={24} className="titleHolder">
        Technical information
      </Col>
      <Col span={24} className="dataHolder">
        <Row gutter={60} justify={"space-between"}>
          <Col span={12}>
            <ShipInformationItems
              name="Builder"
              value={tableCurrentValue?.builder}
            />
            <ShipInformationItems
              name="Draft"
              value={tableCurrentValue?.draft}
            />
            <ShipInformationItems
              name="GRT ( Gross Tonnage )"
              value={tableCurrentValue?.grt}
            />
            <ShipInformationItems
              name="DWT ( Deadweight Tonnage )"
              value={tableCurrentValue?.dwt}
            />
            <ShipInformationItems
              name="Cargo Type"
              value={cargoTypeName(tableCurrentValue?.cargoType)}
            />
            <ShipInformationItems
              name="IMO ID"
              value={tableCurrentValue?.imo}
            />
          </Col>
          <Col span={12}>
            <ShipInformationItems
              name="year built"
              value={tableCurrentValue?.yearBuilt}
            />
            <ShipInformationItems name="Beam" value={tableCurrentValue?.beam} />

            <ShipInformationItems
              name="LOA ( Length Overall )"
              value={tableCurrentValue?.loa}
            />
            <ShipInformationItems
              name="Call Sign"
              value={tableCurrentValue?.callSign}
            />
            <ShipInformationItems
              name="Size Type"
              value={sizeTypeName(tableCurrentValue?.sizeType)}
            />
            <ShipInformationItems
              name="MMSI ID"
              value={tableCurrentValue?.mmsi}
            />
          </Col>
        </Row>
      </Col>
    </ShowBasicInformationContainer>
  );
};
