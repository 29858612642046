import { FC } from "react";
import { AddNewDailyReportContainer } from "../../styles";
import { Voyage } from "./Voyage";

export const AddNewDailyReport: FC = () => {
  return (
    <AddNewDailyReportContainer>
      <Voyage />
    </AddNewDailyReportContainer>
  );
};
