import { Button, Row } from "antd";
import { styled } from "styled-components";

export const AttachInvoiceFileContainer = styled(Row)`
  .header {
    color: var(--text, #001377);
    font-family: DM Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding-block: 16px;
  }
  .PaperClip {
    transform: rotate(-45deg);
  }
  .attachContainer {
    margin-top: 40px;
  }
  .attachFile {
    overflow-y: auto;
    display: flex;
    min-height: 228px;
    max-height: 372px;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
    border-radius: 8px;
    border: 1px dashed var(--shadow, #dadeec);
  }

  .footer {
    border-top: 1px solid #dadeec;
    padding-top: 32px;
    padding-bottom: 16px;
  }
`;

export const UploadButton = styled(Button)`
  border: 1px dashed #95b9fe !important;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff !important;
  color: #2c73fc;
`;
