import { FC } from "react";
import { useViewNoonReport } from "../../context";
import { Spin } from "antd";

export const OtherIfo: FC = () => {
  const {
    value: { otherIfoProps },
  } = useViewNoonReport();
  return (
    <>
      <section className="px-[24px] mt-[30px]">
        <p className="text-[14px] text-[#001377] font-bold">Other details</p>
        <section className="rounded-lg bg-[#f2f7ff] py-[24px] px-[16px]">
          {otherIfoProps.loading ? (
            <Spin />
          ) : (
            <>
              <div className="w-full flex items-center justify-between">
                <div className="w-[33%] flex items-center justify-between">
                  <p className="w-[50%] text-left text-[14px] text-[#001377] font-medium">
                    Wind/force
                  </p>
                  <p className="w-[50%] text-left text-[14px] text-[#5d6881] font-normal">
                    {otherIfoProps?.data?.windForce || "-"}
                  </p>
                </div>

                <div className="w-[33%] flex items-center justify-between">
                  <p className="w-[50%] text-left text-[14px] text-[#001377] font-medium">
                    Sea/swell
                  </p>
                  <p className="w-[50%] text-left text-[14px] text-[#5d6881] font-normal">
                    {otherIfoProps?.data?.seaSwell || "-"}
                  </p>
                </div>

                <div className="w-[33%] flex items-center justify-between">
                  <p className=" w-[60%] text-left text-[14px] text-[#001377] font-medium">
                    Air pressure/temp
                  </p>
                  <p className="w-[40%] text-left text-[14px] text-[#5d6881] font-normal">
                    {otherIfoProps.data?.airPressureTemp || "-"}
                  </p>
                </div>
              </div>

              <div className="w-full flex items-center justify-between">
                <div className="w-[33%] flex items-center justify-between">
                  <p className="w-[50%] text-left text-[14px] text-[#001377] font-medium">
                    Course
                  </p>
                  <p className="w-[50%] text-left text-[14px] text-[#5d6881] font-normal">
                    {otherIfoProps?.data?.Course || "-"}
                  </p>
                </div>

                <div className="w-[33%] flex items-center justify-between">
                  <p className="w-[50%] text-left text-[14px] text-[#001377] font-medium">
                    RPM
                  </p>
                  <p className="w-[50%] text-left text-[14px] text-[#5d6881] font-normal">
                    {otherIfoProps?.data?.RPM || "-"}
                  </p>
                </div>

                <div className="w-[33%] flex items-center justify-between">
                  <p className="w-[60%] text-left text-[14px] text-[#001377] font-medium">
                    SLIP
                  </p>
                  <p className="info-value w-[40%] text-left text-[14px] text-[#5d6881] font-normal">
                    {otherIfoProps.data?.SLIP || "-"}
                  </p>
                </div>
              </div>

              <div className="w-full flex items-center justify-between">
                <div className="w-[33%] flex items-center justify-between">
                  <p className="w-[50%] text-left text-[14px] text-[#001377] font-medium">
                    Primary key
                  </p>
                  <p className="w-[50%] text-left text-[14px] text-[#5d6881] font-normal">
                    {otherIfoProps?.data?.primaryKey || "-"}
                  </p>
                </div>
              </div>
            </>
          )}
        </section>
      </section>

      <section className="px-[24px] mt-[30px]">
        <p className="text-[14px] text-[#001377] font-bold">Remark</p>
        <section className="bg-[#f2f7ff]">
          {otherIfoProps.loading ? (
            <Spin />
          ) : (
            <p className="rounded-lg text-[12px] text-[#3E4556] font-medium py-[12px] px-[16px]">
              {otherIfoProps.data?.Remark || "-"}
            </p>
          )}
        </section>
      </section>
    </>
  );
};
