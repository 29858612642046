import { IObject } from "src/models/interfaces";

export interface INewProfit {
  shipId: number;
  ownerShipType: number;
  voyageNumber: string;
  charterType: number;
  cargo: string;
  quantityType: number;
  quantity: number;
  startLaycan?: string | null;
  endLaycan?: string | null;
  laytime?: number | null;
  demurageRate?: number | null;
  freigth: number;
  hireRate?: number | null;
  loadingPort?: string;
  switchPort?: string;
  dischargePort?: string;
  loadingRate?: number | null;
  dischargeRate?: number | null;
  agentLP?: string;
  agentSP?: string;
  agentDP?: string;
}
export interface IEditProfit {
  ownerShipType: number;
  voyageNumber: string;
  charterType: number;
  cargo: string;
  quantityType: number;
  quantity: number;
  startLaycan?: string | null;
  endLaycan?: string | null;
  laytime?: number | null;
  demurageRate?: number | null;
  freigth: number;
  hireRate?: number | null;
  loadingPort?: string;
  switchPort?: string;
  dischargePort?: string;
  loadingRate?: number | null;
  dischargeRate?: number | null;
  agentLP?: string;
  agentSP?: string;
  agentDP?: string;
}
export interface INewProfitTrade {
  projectNumber?: string;
  purchaseQuantity?: number | null;
  purchaseUnitPrice?: number | null;
  tTlPurchaseCargo?: number | null;
  otherCost?: number | null;
  saleQuantity?: number | null;
  saleUnitPrice?: number | null;
  ttlSaleCargo?: number | null;
  loadingDemurage?: number | null;
  dischargeDemurage?: number | null;
}

export interface INewProfitShipping {
  startVoyageDate?: string | null;
  endVoyageDate?: string | null;
  opexTCCost?: number | null;
  bunkering?: number | null;
  agencyCost?: number | null;
  brokageCommission?: number | null;
  heatingCost?: number | null;
  otherCost?: number | null;
  freightInvoice?: number | null;
  demurageInvoice?: number | null;
  heatingInvoie?: number | null;
}

export interface IAllProfitParams extends IObject {
  ShipId?: number;
  VoyageNumber?: string;
  Offset?: number;
  Limit?: number;
}

export const QuantityType = [
  { value: 1, label: "MT" },
  { value: 2, label: "BBL" },
  { value: 3, label: "LTR" },
  { value: 4, label: "KG" },
  { value: 5, label: "CBM" },
  { value: 6, label: "Tank" },
];
export const CharterType = [
  { value: 1, label: "TradeDepartman_TCH" },
  { value: 2, label: "TradeDepartman_VCH" },
  { value: 3, label: "Charter_VCH" },
  { value: 4, label: "Charter_TCH" },
];
export enum ProfitCharterType {
  "TradeDepartman_TCH" = 1,
  "TradeDepartman_VCH" = 2,
  "Charter_VCH" = 3,
  "Charter_TCH" = 4,
}
export interface IAttachFile {
  fileName: string;
  count?: number;
  extention?: string;
  fileOrginalName?: string;
  name: string;
  description?: string;
}
