import { FC, useState } from "react";
import { BigCard } from "./components/big-card";
import { Button, Col, Row } from "antd";
import { CardContainer } from "./styles/cardcontainerstyles";
import { LittleCard } from "./components/little-card";
import { useLittleCardItems } from "./constants/little-card-items";
import { useAddInvoiceModal } from "../addInvoiceModal/context";
import { useTable } from "../Table/context";
import { Guard } from "src/components/Guard";
import { ShipPath } from "src/services/Public/guardPath";

interface IProps {
  title?: string;
}

const Home: FC<IProps> = () => {
  const [isClicked, setIsClicked] = useState(false);
  const { littleCardItems } = useLittleCardItems();
  const {
    value: { shipsData },
    func: { openModal },
  } = useAddInvoiceModal();
  const {
    dispatches: { setShipId },
  } = useTable();
  const handleClick = () => {
    setIsClicked(!isClicked);
  };
  return (
    <CardContainer>
      <Col span={24} className=" gap-[16px]  littleCard ">
        {littleCardItems.map((item, index) => (
          <Guard action={item.actions} multiPath key={index}>
            <LittleCard {...item} />
          </Guard>
        ))}
      </Col>
      <Guard action={ShipPath}>
        <Row className="shiplistContainer ">
          <Col span={24} className="flex justify-between px-[24px]">
            <div className="shipList">Ship’s list</div>
            {/* <SearchBox /> */}
          </Col>
          <Col
            span={24}
            className={`shipContainer ps-[24px] pb-[15px] ${
              isClicked ? "clickedStyle" : ""
            }`}
          >
            {shipsData?.map((item, index) => (
              <BigCard
                title={item.abbreviation}
                key={index}
                addInvoiceFn={() => {
                  openModal();
                  setShipId(item.id);
                }}
                {...item}
              />
            ))}
          </Col>
          <Col span={24} className="flex justify-end py-[16px] ">
            <Button
              className="buttonContainer"
              onClick={handleClick}
              type="link"
            >
              {isClicked
                ? "Show In Line"
                : `See All ( ${shipsData?.length || 0} Ship )`}
            </Button>
          </Col>
        </Row>
      </Guard>
    </CardContainer>
  );
};
export default Home;
