import { FC } from "react";
import { AssignRoleHeader } from "./components/AssignRoleHeader";
import { AssignRoleAdd } from "./components/AssignRoleAdd";
import { AssignRoleTable } from "./components/AssignRoleTable";
import { AssignRoleFooter } from "./components/AssignRoleFooter";
import { AssignRoleToFrontContainer } from "./style";
import { Col } from "antd";

const AssignRoleToFront: FC = () => {
  return (
    <AssignRoleToFrontContainer gutter={[0, 16]} justify={"center"}>
      <Col xs={24} md={24} lg={20} className="assign-col">
        <AssignRoleHeader />
        <AssignRoleAdd />
        <AssignRoleTable />
        <AssignRoleFooter />
      </Col>
    </AssignRoleToFrontContainer>
  );
};

export default AssignRoleToFront;
