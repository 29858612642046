import { FC } from "react";
import { ShipDailyReportShipsStyle } from "./style";
import { ShipDailyReportSearch } from "../ShipDailyReportSearch";
import { useShipDailyReportShips } from "./context";
import Ships from "./components/Ships";

export const ShipDailyReportShips: FC = () => {
  const {
    value: { shipsData },
    dispatch: { setFilterValue },
  } = useShipDailyReportShips();

  const onSearch = (value: string) => {
    setFilterValue((prev) => {
      const data = { ...prev };
      if (!value) {
        delete data.Abbreviation;
        return data;
      } else {
        return { ...data, Abbreviation: value };
      }
    });
  };
  return (
    <>
      <ShipDailyReportSearch
        title="Ships"
        onSearch={onSearch}
        shipsData={shipsData}
      />
      <ShipDailyReportShipsStyle>
        <Ships />
      </ShipDailyReportShipsStyle>
    </>
  );
};
