import { Popconfirm } from "antd";
import { FC } from "react";
import { Guard } from "src/components/Guard";
import {
  DeleteProfit,
  EditProfit,
  GetProfit,
} from "src/services/profit/guardPath";

interface IProps {
  onEdit: () => void;

  onView: () => void;

  onDelete: () => void;
}
export const Tools: FC<IProps> = ({ onDelete, onEdit, onView }) => {
  return (
    <div className="flex gap-[10px] items-center justify-center">
      <Guard action={[GetProfit, EditProfit]} multiPath>
        <span
          onClick={onEdit}
          className="material-icons text-[#01C18F] text-[16px] cursor-pointer "
        >
          edit_note
        </span>
      </Guard>
      <Guard action={GetProfit}>
        <span
          onClick={onView}
          className="material-icons text-[#2C73FC] text-[16px] cursor-pointer"
        >
          remove_red_eye
        </span>
      </Guard>
      <Guard action={DeleteProfit}>
        <Popconfirm
          title="Delete phone number"
          onConfirm={onDelete}
          description="Are you want to delete this?"
        >
          <span className="material-icons !text-[#EC2D30] text-[16px] cursor-pointer">
            delete_forever
          </span>
        </Popconfirm>
      </Guard>
    </div>
  );
};
