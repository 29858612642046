import { Popconfirm } from "antd";
import { FC, ReactElement } from "react";
interface ISPopconfirm {
  onConfirm: () => void;
  children: ReactElement;
}
export const SPopconfirm: FC<ISPopconfirm> = ({ onConfirm, children }) => {
  return (
    <Popconfirm
      icon=""
      placement="topRight"
      title="Delete"
      description="Are you sure you want to delete this ship?"
      onConfirm={onConfirm}
      rootClassName="deletePopconfirm"
      okText="Yes"
      cancelText="No"
    >
      {children}
    </Popconfirm>
  );
};
