import {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from "react";
import { ProfitTabs } from "../constants/Tabs";
import {
  AddNewProfitStatus,
  AddNewProfitStatusTypeEnum,
  AddNewProfitStep,
} from "../models";
import { Form, FormInstance } from "antd";

const defaultStatus = {
  basic: AddNewProfitStatusTypeEnum.empty,
  shipping: AddNewProfitStatusTypeEnum.empty,
  trade: AddNewProfitStatusTypeEnum.empty,
};
interface IContext {
  value: {
    activeKey: string;
    editMode: boolean;
    status: AddNewProfitStatus;
    step: AddNewProfitStep | undefined;
    showAttachFile: boolean;
  };
  dispatch: {
    setActiveKey: Dispatch<SetStateAction<string>>;
    setEditMode: Dispatch<SetStateAction<boolean>>;
    setStatus: Dispatch<SetStateAction<AddNewProfitStatus>>;
    setStep: Dispatch<SetStateAction<AddNewProfitStep | undefined>>;
    setShowAttachFile: Dispatch<SetStateAction<boolean>>;
  };
  form: {
    basicInfoForm: FormInstance<any> | undefined;
    tradeInfoForm: FormInstance<any> | undefined;
    shippingInfoForm: FormInstance<any> | undefined;
  };
}
const defaultContextValue: IContext = {
  value: {
    activeKey: "1",
    editMode: false,
    status: defaultStatus,
    step: undefined,
    showAttachFile: false,
  },
  dispatch: {
    setActiveKey: () => {},
    setEditMode: () => {},
    setStatus: () => {},
    setStep: () => {},
    setShowAttachFile: () => {},
  },
  form: {
    basicInfoForm: undefined,
    tradeInfoForm: undefined,
    shippingInfoForm: undefined,
  },
};
export const ProfitReportCTX = createContext<IContext>(defaultContextValue);
export const ProfitReportProvider: FC<PropsWithChildren> = ({ children }) => {
  //------------------form----------------------

  const [basicInfoForm] = Form.useForm();

  const [tradeInfoForm] = Form.useForm();

  const [shippingInfoForm] = Form.useForm();

  //------------------state----------------------

  const [status, setStatus] = useState<AddNewProfitStatus>(defaultStatus);

  const [step, setStep] = useState<AddNewProfitStep>();

  const [activeKey, setActiveKey] = useState<string>(ProfitTabs.Add);

  const [editMode, setEditMode] = useState<boolean>(false);

  const [showAttachFile, setShowAttachFile] = useState<boolean>(false);

  const contextValue: IContext = {
    value: { activeKey, editMode, status, step, showAttachFile },
    dispatch: {
      setActiveKey,
      setEditMode,
      setStatus,
      setStep,
      setShowAttachFile,
    },
    form: { basicInfoForm, shippingInfoForm, tradeInfoForm },
  };
  return (
    <ProfitReportCTX.Provider value={contextValue}>
      {children}
    </ProfitReportCTX.Provider>
  );
};
export const useProfitReport = () => useContext(ProfitReportCTX);
