import {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { SystemService } from "src/services/System/System.service";
import { ITreeCategory } from "src/services/System/models";

interface IContext {
  value: {
    isEditMode: boolean;
    category: ITreeCategory[];
    categoryLoading: boolean;
    selectedCategories: ITreeCategory[];
    levels: ITreeCategory[];
    categoryForEdit: ITreeCategory | undefined;
  };
  dispatch: {
    setIsEditMode: Dispatch<SetStateAction<boolean>>;
    setSelectedCategories: Dispatch<SetStateAction<ITreeCategory[]>>;
    setLevels: Dispatch<SetStateAction<ITreeCategory[]>>;
    setCategoryForEdit: Dispatch<SetStateAction<ITreeCategory | undefined>>;
  };
  func: { getCategory: () => Promise<void> };
}
export const CategoryContext = createContext<IContext | undefined>(undefined);

export const CategoryProvider: FC<PropsWithChildren> = ({ children }) => {
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [category, setCategory] = useState<ITreeCategory[]>([]);
  const [categoryLoading, setCategoryLoading] = useState<boolean>(false);
  const [selectedCategories, setSelectedCategories] = useState<ITreeCategory[]>(
    []
  );
  const [categoryForEdit, setCategoryForEdit] = useState<ITreeCategory>();
  const [levels, setLevels] = useState<ITreeCategory[]>([]);

  const getCategory = async () => {
    try {
      setCategoryLoading(true);
      const { TreeCategory } = new SystemService();
      const res = await TreeCategory();
      if (res && res.status === 200 && res.data) {
        setCategory(res.data);
      } else {
        setCategory([]);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setCategoryLoading(false);
    }
  };
  useEffect(() => {
    getCategory();
  }, []);
  const ContextValue: IContext = {
    value: {
      isEditMode,
      category,
      categoryLoading,
      selectedCategories,
      levels,
      categoryForEdit,
    },
    dispatch: {
      setIsEditMode,
      setSelectedCategories,
      setLevels,
      setCategoryForEdit,
    },
    func: { getCategory },
  };
  return (
    <CategoryContext.Provider value={ContextValue}>
      {children}
    </CategoryContext.Provider>
  );
};
export const useCategory = () => useContext(CategoryContext);
