import styled from "styled-components";

export const EditFrontAccessContainer = styled.div`
  .borderBottom {
    border-bottom: 1px solid var(--shadow, #dadeec);
    margin-bottom: 25px;
  }
  .background {
    width: 100% !important;
    min-height: 492px;
    padding: 24px 32px;
    border-radius: 20px;
    background: var(--0, #fff);
    box-shadow: 0px 4px 6px 0px rgba(113, 130, 183, 0.25);
  }
  .titleHolder {
    color: var(--text, #001377);
    font-family: DM Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .ant-select-arrow {
    color: #001377 !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
  .anticon-search {
    color: #001377 !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ant-input {
    border-width: 0 !important;
  }
  .footerButton {
    display: flex;
    width: 100px;
    padding: 12px;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    border: 1px solid var(--info-info-600, #3b82f6);
    color: var(--info-info-600, #3b82f6);
    text-align: center;
    font-family: DM Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.7px;
  }
  .footer {
    border-top: 1px solid var(--shadow, #dadeec);
    padding-top: 24px;
  }
  .ant-input {
    background: #f4f7fe !important;
  }
  .anticon {
    color: #001377;
  }
  .ant-input-disabled {
    background: #dadeec !important;
  }
  .ant-select-selector {
    background: #dadeec !important;
  }
  .ant-select-selection-search-input {
    margin-top: unset !important;
  }
`;
