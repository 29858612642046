import { IObject } from "src/models/interfaces";
import { TRequesterType } from "./result.model";

export interface INewAllRequestArgs {
  shipId: number;
  requesterId: number;
  requesterType: TRequesterType;
  requesterDate: string;
  batch: string;
  comment: string;
  dueDate: string;
  paymentCurrencyId: number;
}
export enum IInvoiceType {
  Invoice = 1,
  PreInvoice = 2,
  ReserveFund = 3,
}
export type TInvoiceType = keyof typeof IInvoiceType;
export type InvoiceType = 1 | 2 | 3;
export interface INewInvoice {
  allRequestAttachmentId: number;
  invoiceType: 1 | 2 | 3;
  parentId: number[];
  supplierId: number;
  suppliernvoiceDate?: string | null;
  supplierInvoiceNumber: string;
  shmInvoiceNumber: string;
  shmInvoiceDate: string | null;
  ourDate: string | null;
  voyageId?: number | undefined;
}
export interface IArticle {
  comment: string;
  costCodeId: number;
  quantity: number;
  unitQuantity: string;
  unitPrice: number;
  amount: number;
}
export interface INewInvoiceArticle {
  articles: IArticle[];
}
export interface ITotalInvoice {
  totalInvoiceAmount: number;
  discount: number;
  tax: number;
  supplireToTalAmount: number;
  diffrenceWithSupplierTotla: number;
  shipManagementTotlaAmount: number;
  finalyTotlaAmount: number;
  diffrenceWithShipManagementTotla: number;
  invoiceCurrency: number;
  usdRate: number;
}
export interface IPaymanetInformation {
  tiaShipManagementPCurreccny: number;
  supervicorRate: number;
  excAmount: number;
  rateState: number;
  comment?: string;
}

export interface IUpdateInvoice extends IObject {
  shipManagementTotlaAmount: number;
  supplireToTalAmount: number;
  invoiceCurrencyId: number;
  tiaShipManagementPCurreccny: number;
  discount: number;
  tax: number;
  supervisorRate: number;
}
export interface IUpdateBasicInformationInvoice extends IObject {
  parentId?: number[];
  supplierId?: number;
  shmInvoiceNumber?: string;
  supplierInvoiceNumber?: string;
  shmInvoiceDate?: string | null;
  supplierInvoiceDate?: string | null;
  ourDate?: string | null;
  voyageId?: number | undefined;
  usdRate: number;
}
export interface IUpdateArticle extends IArticle {}
export interface IUpdateAllRequest {
  dueDate: string | null;
  paymentCurrencyId: number;
  comment: string;
}

export interface IUpdateAllRequestZipDetailArg {
  fileName: string;
  count?: number;
  extention?: string;
  fileOrginalName?: string;
  attachNumber?: string;
  attachDate?: string;
}
export interface IUpdateAllRequestZipDetailInfoArg {
  attachNumber?: string;
  attachDate?: string;
}
