import {
  Checkbox,
  Col,
  DatePicker,
  Input,
  Popconfirm,
  Row,
  Space,
  Tabs,
} from "antd";
import type { GetProp } from "antd";
import { TabsProps } from "antd/lib";
import { FC, PropsWithChildren } from "react";
import {
  ShipStateContainer,
  ShipTabContainer,
  StatusContainer,
} from "src/modules/ShipDailyReport/styles";

import { DateContainer } from "src/modules/Report/styles";
import { TabStyle } from "./style";
import dayjs from "dayjs";
import { RangePickerProps } from "antd/es/date-picker";
import { useDailyReport } from "src/modules/ShipDailyReport/context";
import usePublicApiStore from "src/store/publicApi";
import { PositionConstant } from "src/modules/ShipDailyReport/constant/shipState";
type CheckboxValueType = GetProp<typeof Checkbox.Group, "onChange">;

export const FilterPopUp: FC<PropsWithChildren> = ({ children }) => {
  const {
    dispatch: { setFilterValue, setSeenFilter },
    func: { onFinishFilter, AllShipDailyReport },
  } = useDailyReport();

  const cancel = (e: React.MouseEvent<HTMLElement> | undefined) => {
    setFilterValue({});
    setSeenFilter([]);
    AllShipDailyReport();
  };

  const items: TabsProps["items"] = [
    {
      key: "ShipTab",
      label: "ship",
      children: <ShipTab />,
    },
    {
      key: "tradeStatus",
      label: "Trade Status",
      children: <TradeStatus />,
    },
    {
      key: "shipStatus",
      label: "Ship Status",
      children: <ShipStatus />,
    },
    {
      key: "position",
      label: "Position",
      children: <Position />,
    },
    {
      key: "status",
      label: "Status",
      children: <Status />,
    },
    {
      key: "reportDate",
      label: "Report date",
      children: <Date />,
    },
    {
      key: "isSee",
      label: "Is see",
      children: <IsSee />,
    },
  ];

  return (
    <Popconfirm
      placement="bottomLeft"
      title=""
      description={
        <TabStyle>
          <Tabs defaultActiveKey="1" items={items} />
        </TabStyle>
      }
      onConfirm={onFinishFilter}
      onCancel={cancel}
      okText="ok"
      cancelText="Reset"
      icon={""}
      rootClassName="filterPopUp"
    >
      {children}
    </Popconfirm>
  );
};

export const ShipTab: FC = () => {
  const {
    value: { filterValue },
    dispatch: { setFilterValue },
  } = useDailyReport();
  const onChange: CheckboxValueType = (checkedValues) => {
    setFilterValue((perv) => ({ ...perv, ShipId: checkedValues as number[] }));
  };
  const { ships } = usePublicApiStore();
  return (
    <ShipTabContainer className="flex flex-col gap-[16px]">
      <Input
        placeholder="Search in filters"
        suffix={
          <span className="material-icons text-[#A3AED0] text-[16px]">
            search
          </span>
        }
      />
      <Checkbox.Group
        style={{ width: "100%" }}
        onChange={onChange}
        className="flex flex-col h-[135px] py-[16px] gap-[8px] checkboxGroup"
        value={filterValue.ShipId}
      >
        {ships?.data?.map((item, index) => (
          <Checkbox value={item.id} key={index}>
            <Space>{item.name}</Space>
          </Checkbox>
        ))}
      </Checkbox.Group>
    </ShipTabContainer>
  );
};

const Position: FC = () => {
  const {
    value: { filterValue },

    dispatch: { setFilterValue },
  } = useDailyReport();

  const onChange: CheckboxValueType = (checkedValues) => {
    setFilterValue((perv) => ({
      ...perv,
      Position: checkedValues as number[],
    }));
  };
  return (
    <ShipStateContainer className="flex flex-col gap-[16px]">
      <Checkbox.Group
        style={{ width: "100%" }}
        onChange={onChange}
        className="flex flex-col py-[16px] gap-[8px] checkboxGroup"
        value={filterValue.Position}
      >
        <Row>
          {PositionConstant.map((item, index) => (
            <Col span={24}>
              <Checkbox value={item.id} key={index}>
                {item.name}
              </Checkbox>
            </Col>
          ))}
        </Row>
      </Checkbox.Group>
    </ShipStateContainer>
  );
};
const Status: FC = () => {
  const {
    value: { filterValue },
    dispatch: { setFilterValue },
  } = useDailyReport();
  const onChange: CheckboxValueType = (checkedValues) => {
    setFilterValue((perv) => ({
      ...perv,
      Stauts: checkedValues as number[],
    }));
  };
  return (
    <StatusContainer>
      <Checkbox.Group
        onChange={onChange}
        className="flex flex-col pt-[16px] gap-[8px] flex-nowrap borderBottom"
        value={filterValue.Stauts}
      >
        <Checkbox value={1}>New</Checkbox>
        <Checkbox value={3}>Accepted</Checkbox>
        <Checkbox value={2}>Rejected</Checkbox>
      </Checkbox.Group>
    </StatusContainer>
  );
};
const TradeStatus: FC = () => {
  const {
    value: { filterValue },
    dispatch: { setFilterValue },
  } = useDailyReport();
  const onChange: CheckboxValueType = (checkedValues) => {
    setFilterValue((perv) => ({
      ...perv,
      TradeStatus: checkedValues as number[],
    }));
  };
  return (
    <StatusContainer>
      <Checkbox.Group
        onChange={onChange}
        className="flex flex-col pt-[16px] gap-[8px] flex-nowrap borderBottom"
        value={filterValue.TradeStatus}
      >
        <Checkbox value={1}>No Voyage Yet</Checkbox>
        <Checkbox value={2}>in Voyage</Checkbox>
      </Checkbox.Group>
    </StatusContainer>
  );
};
const ShipStatus: FC = () => {
  const {
    value: { filterValue },
    dispatch: { setFilterValue },
  } = useDailyReport();
  const onChange: CheckboxValueType = (checkedValues) => {
    setFilterValue((perv) => ({
      ...perv,
      ShipState: checkedValues as number[],
    }));
  };
  return (
    <StatusContainer>
      <Checkbox.Group
        onChange={onChange}
        className="flex flex-col pt-[16px] gap-[8px] flex-nowrap borderBottom"
        value={filterValue.ShipState}
      >
        <Checkbox value={1}>Laden</Checkbox>
        <Checkbox value={2}>Ballast</Checkbox>
      </Checkbox.Group>
    </StatusContainer>
  );
};

const Date: FC = () => {
  const { RangePicker } = DatePicker;
  const {
    value: { filterValue },
    dispatch: { setFilterValue },
  } = useDailyReport();
  const onChange = (value: RangePickerProps["value"]) => {
    setFilterValue((perv) => ({
      ...perv,
      FromReportDate:
        value && value[0] ? value[0].format("YYYY-MM-DD") : undefined,
      ToReportDate:
        value && value[1] ? value[1]?.format("YYYY-MM-DD") : undefined,
    }));
  };
  return (
    <DateContainer>
      <div className="borderBottom">
        <div className="dateTitle ">Report date</div>
        <RangePicker
          className="datePicker "
          format={"YYYY-MM-DD"}
          onChange={onChange}
          value={
            filterValue.FromReportDate && filterValue.ToReportDate
              ? [
                  dayjs(filterValue.FromReportDate),
                  dayjs(filterValue.ToReportDate),
                ]
              : undefined
          }
        />
      </div>
    </DateContainer>
  );
};

export const IsSee: FC = () => {
  const {
    value: { seenFilter },
    dispatch: { setFilterValue, setSeenFilter },
  } = useDailyReport();
  const onChange: CheckboxValueType = (checkedValues) => {
    setSeenFilter(checkedValues as string[]);
    if (checkedValues.includes("Seen") && checkedValues.includes("NotSeen")) {
      setFilterValue((perv) => ({
        ...perv,
        IsSeen: undefined,
      }));
      return;
    }
    if (!checkedValues.includes("Seen") && !checkedValues.includes("NotSeen")) {
      setFilterValue((perv) => ({
        ...perv,
        IsSeen: undefined,
      }));
      return;
    }
    if (checkedValues.includes("Seen")) {
      setFilterValue((perv) => ({
        ...perv,
        IsSeen: true,
      }));
      return;
    }
    if (checkedValues.includes("NotSeen")) {
      setFilterValue((perv) => ({
        ...perv,
        IsSeen: false,
      }));
      return;
    }
  };

  return (
    <StatusContainer>
      <Checkbox.Group
        onChange={onChange}
        className="flex flex-col pt-[16px] gap-[8px] flex-nowrap borderBottom"
        value={seenFilter}
      >
        <Checkbox value="Seen">Seen</Checkbox>
        <Checkbox value="NotSeen">Not seen</Checkbox>
      </Checkbox.Group>
    </StatusContainer>
  );
};
