export const atdOptions = [
  {
    value: 1,
    label: "ETD",
  },
  {
    value: 2,
    label: "ATD",
  },
];
export const ataOptions = [
  {
    value: 2,
    label: "ETA",
  },
  {
    value: 1,
    label: "ATA",
  },
];

export enum DailyReportDestinationTime {
  ATA = 1,
  ETA = 2,
}

export enum DailyReportOriginTime {
  ETD = 1,
  ATD = 2,
}
