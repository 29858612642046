import {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useState,
} from "react";
import { IAttachFile, IGetProfitFiles } from "src/services/profit/models";
import { useNewProfitReport } from "../../AddNewProfit/context";
import { ProfitService } from "src/services/profit/profit.service";
import { useProfitReport } from "src/modules/ProfitReport/context";
import { AddNewProfitStepEnum } from "src/modules/ProfitReport/models";
import { ProfitUrls } from "src/services/profit/urls";
import { uploadFile } from "src/helpers/uploadZipFile";
import { useTableProfit } from "../../ProftTable/context";
import { DocumentService } from "src/services/Document/Document.service";
import { App } from "antd";
import downloadZipFile from "src/helpers/downloadZipFile";

interface IContext {
  value: {
    attachFileLoading: boolean;
    fileName: string | undefined;
    tableLoading: boolean;
    tableDataSource: IGetProfitFiles[] | undefined;
    newFileLoading: boolean;
    progress: number;
  };
  dispatch: {
    setAttachFileLoading: Dispatch<SetStateAction<boolean>>;
    setFileName: Dispatch<SetStateAction<string | undefined>>;
    setTableLoading: Dispatch<SetStateAction<boolean>>;
    setTableDataSource: Dispatch<SetStateAction<IGetProfitFiles[] | undefined>>;
    setNewFileLoading: Dispatch<SetStateAction<boolean>>;
    setProgress: Dispatch<SetStateAction<number>>;
  };
  form: {};
  func: {
    getAttachFiles: () => void;
    NewProfitAttachFiles: (value: IAttachFile) => void;
    getProfitAttachFile: () => void;
    getProfitShippingFile: () => void;
    getProfitTradeFile: () => void;
    downloadFile: (id: string) => void;
    deleteFile: (id: string) => void;
  };
}

const defaultContextValue: IContext = {
  value: {
    attachFileLoading: false,
    fileName: "",
    tableLoading: false,
    tableDataSource: undefined,
    newFileLoading: false,
    progress: 0,
  },
  dispatch: {
    setAttachFileLoading: () => {},
    setFileName: () => {},
    setTableLoading: () => {},
    setTableDataSource: () => {},
    setNewFileLoading: () => {},
    setProgress: () => {},
  },
  form: {},
  func: {
    getAttachFiles: () => {},
    NewProfitAttachFiles: () => {},
    getProfitAttachFile: () => {},
    getProfitShippingFile: () => {},
    getProfitTradeFile: () => {},
    downloadFile: () => {},
    deleteFile: () => {},
  },
};

export const AttachFileContext = createContext<IContext>(defaultContextValue);

export const AttachFileProvider: FC<PropsWithChildren> = ({ children }) => {
  const {
    value: { step },
  } = useProfitReport();

  const {
    values: { newBasicInfoId },
  } = useNewProfitReport();

  const {
    value: { editId },
    form: { attachFileBaseInfoForm },
  } = useTableProfit();

  const { message } = App.useApp();
  //-------------------------form-----------------------

  //-------------------------state-----------------------

  const [attachFileLoading, setAttachFileLoading] = useState<boolean>(false);

  const [fileName, setFileName] = useState<string | undefined>(undefined);

  const [tableLoading, setTableLoading] = useState<boolean>(false);

  const [tableDataSource, setTableDataSource] = useState<
    IGetProfitFiles[] | undefined
  >(undefined);

  const [progress, setProgress] = useState<number>(0);

  const [newFileLoading, setNewFileLoading] = useState<boolean>(false);

  //-------------------------func-----------------------

  const NewProfitAttachFiles = async (value: any) => {
    const id = newBasicInfoId || editId;
    if (id) {
      const endpointBasic = ProfitUrls.profit
        .concat(`${id}`)
        .concat(ProfitUrls.NewProfitFile);

      const endpointShipping = ProfitUrls.profit
        .concat(`${id}`)
        .concat(ProfitUrls.NewProfitShippingFile);

      const endpointTrade = ProfitUrls.profit
        .concat(`${id}`)
        .concat(ProfitUrls.NewProfitTradeFile);

      try {
        setNewFileLoading(true);
        await uploadFile({
          newFile: value.files.file.originFileObj,
          callbackUrl: `${
            step === AddNewProfitStepEnum.basic
              ? endpointBasic
              : step === AddNewProfitStepEnum.shipping
              ? endpointShipping
              : endpointTrade
          }`,
          method: "PATCH",
          setProgress,
          extraData: {
            name: value.name,
            description: value.description,
          },
        }).finally(() => {
          attachFileBaseInfoForm?.resetFields();
          setFileName(undefined);
          getAttachFiles();
          setNewFileLoading(false);
        });
      } catch (err) {
        console.log(err);
      }
    }
  };

  const getAttachFiles = useCallback(async () => {
    if (step === AddNewProfitStepEnum.basic) {
      getProfitAttachFile();
    } else {
      if (step === AddNewProfitStepEnum.shipping) {
        getProfitShippingFile();
      } else {
        if (step === AddNewProfitStepEnum.trade) {
          getProfitTradeFile();
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step, newBasicInfoId]);

  const getProfitAttachFile = useCallback(async () => {
    const id = newBasicInfoId || editId;
    if (!id) return;
    try {
      setTableLoading(true);
      const { GetProfitFile } = new ProfitService();
      const response = await GetProfitFile(id);
      if (response && response.status === 200) {
        setTableDataSource(response.data);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setTableLoading(false);
    }
  }, [newBasicInfoId, editId]);

  const getProfitShippingFile = useCallback(async () => {
    const id = newBasicInfoId || editId;
    if (!id) return;
    try {
      setTableLoading(true);
      const { GetProfitShippingFile } = new ProfitService();
      const response = await GetProfitShippingFile(id);
      if (response && response.status === 200) {
        setTableDataSource(response.data);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setTableLoading(false);
    }
  }, [newBasicInfoId, editId]);

  const getProfitTradeFile = useCallback(async () => {
    const id = newBasicInfoId || editId;
    if (!id) return;
    try {
      setTableLoading(true);
      const { GetProfitTradeFile } = new ProfitService();
      const response = await GetProfitTradeFile(id);
      if (response && response.status === 200) {
        setTableDataSource(response.data);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setTableLoading(false);
    }
  }, [newBasicInfoId, editId]);

  const downloadFile = async (id: string) => {
    const { DownloadDocument } = new DocumentService();
    const result = await DownloadDocument(id);
    if (result) {
      message.success("successfully downloaded");
      downloadZipFile(result);
    }
  };

  const deleteFile = async (id: string) => {
    const { DeleteDocument } = new DocumentService();
    const response = await DeleteDocument(id);
    if (response && response.status === 200) {
      message.success("successfully deleted");
      getAttachFiles();
    }
  };

  const CTXValue: IContext = {
    value: {
      attachFileLoading,
      fileName,
      tableLoading,
      tableDataSource,
      newFileLoading,
      progress,
    },
    dispatch: {
      setAttachFileLoading,
      setFileName,
      setTableLoading,
      setTableDataSource,
      setNewFileLoading,
      setProgress,
    },
    form: {},
    func: {
      getAttachFiles,
      NewProfitAttachFiles,
      getProfitAttachFile,
      getProfitShippingFile,
      getProfitTradeFile,
      downloadFile,
      deleteFile,
    },
  };
  return (
    <AttachFileContext.Provider value={CTXValue}>
      {children}
    </AttachFileContext.Provider>
  );
};

export const useAttachFile = () => useContext(AttachFileContext);
