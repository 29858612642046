import { Row } from "antd";
import styled from "styled-components";

export const PayerContainer = styled.div`
  .borderBottom {
    border-bottom: 1px solid var(--shadow, #dadeec);
    margin-bottom: 24px;
  }
`;

export const ContentContainer = styled(Row)`
  height: 447px;
  padding: 24px 32px;
  border-radius: 20px;
  background: var(--color-Base-Color-White, #fff);
  box-shadow: 0px 4px 6px 0px rgba(113, 130, 183, 0.25);
  .button {
    border-top: 2px dashed #dadeec;
    padding-top: 24px;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
`;

export const TableContainer = styled(Row)``;
