export enum IAddShipsTabs {
  addNewShip = "1",
  shipsTable = "2",
}
export enum IAddNewShip {
  basicInformation = "1",
  shipsPicture = "2",
  technicalInformation = "3",
  Owner = "4",
  ShipManagement = "5",
  shipPrice = "6",
  insurances = "7",
  // documentationFile = "8",
}
export interface IDataSource {
  key: number;
  currentName: string;
  cargoType: number;
  sizeType: number;
  ownershipType: number;
  shipManagement: string;
  ownerCompany: string;
  ownerName: string;
  countryRegistration: string;
}

export const DocumentDataSource = [
  {
    key: "0",
    documentName: "Ship contract",
  },
  {
    key: "1",
    documentName: "Purchase documentation",
  },
  {
    key: "2",
    documentName: "Ship documentation",
  },
  {
    key: "3",
    documentName: "Owner documentation",
  },
];
