import { FC, useEffect } from "react";
import { useShipDailyReportDetail } from "../../context";
import { Flex, Row, Spin } from "antd";
import ReportDetail from "./ReportDetail";
import { useGetDailyReport } from "src/modules/Owner/hook";

const Report: FC = () => {
  useGetDailyReport();
  const {
    value: { loading, reports },
    func: { changeDailyReportReadStatus, updateReports },
  } = useShipDailyReportDetail();
  const changeStatus = async () => {
    for (const report of reports) {
      try {
        const res = await changeDailyReportReadStatus(
          report.shipId,
          report.reportDate
        );
        if (res) {
          updateReports(res);
        }
      } catch {}
    }
  };
  useEffect(() => {
    changeStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Row justify={"center"} gutter={[0, 16]}>
      {loading ? (
        <Flex align="center" justify="center" className="w-full h-full">
          <Spin size="large" />
        </Flex>
      ) : (
        reports?.map((report) => (
          <ReportDetail key={report.id} report={report} />
        ))
      )}
    </Row>
  );
};

export default Report;
