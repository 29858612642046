import { Col, DatePicker, Select, Space } from "antd";
import { FormItem } from "src/components/UiKit/FormItem";
import { FC } from "react";
import { GeneralContainer } from "../../styles";
import usePublicApiStore from "src/store/publicApi";
import { invoiceReports } from "../../models/reportTypes";
import { invoiceReportFormItemsName } from "../../constant/flterFormNames";
const { Option } = Select;
const { RangePicker } = DatePicker;
export const General: FC = () => {
  const { ships } = usePublicApiStore();

  return (
    <GeneralContainer gutter={24}>
      <Col span={8}>
        <FormItem
          name={invoiceReportFormItemsName["Report type"]}
          label="Report type"
          rules={[{ required: true }]}
          required={true}
          className="!mb-0"
        >
          <Select
            style={{ width: "100%" }}
            placeholder="Select"
            optionLabelProp="label"
            showSearch
            className="selectOption"
            allowClear
            //   loading={shipLoading}
          >
            {invoiceReports.map((item, index) => (
              <Option key={index} value={item.value} label={item.name}>
                <Space>{item.name}</Space>
              </Option>
            ))}
          </Select>
        </FormItem>
      </Col>
      <Col span={8}>
        <FormItem
          name={invoiceReportFormItemsName.Ship}
          label="Ship"
          className="!mb-0"
        >
          <Select
            style={{ width: "100%" }}
            placeholder="Select"
            optionLabelProp="label"
            showSearch
            className="selectOption"
            allowClear
          >
            {ships?.data?.map((item, index) => (
              <Option key={index} value={item.id} label={item.abbreviation}>
                <Space>{item.name}</Space>
              </Option>
            ))}
          </Select>
        </FormItem>
      </Col>
      <Col span={8}>
        <FormItem
          name={invoiceReportFormItemsName["Created date"]}
          label="Created date"
          className="!mb-0"
        >
          <RangePicker
            style={{ width: "100%" }}
            className="rangePicker"
            placeholder={["Start", "End"]}
          />
        </FormItem>
      </Col>
    </GeneralContainer>
  );
};
