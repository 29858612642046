import { Navigate, RouteObject } from "react-router-dom";
import { ROUTES } from "src/models/enums/routes";
import { DailyReportCalendar } from "src/modules/Owner";
import {
  ActionsPage,
  CargosPage,
  FuturePlanPage,
  InstructionPage,
  VoyagePage,
  OwnerDashboardPage,
  ShipsDailyReportPage,
  ShipDailyReportShipsPage,
  ShipDailyReportsPage,
  ShipDailyReportDetailPage,
} from "src/pages/owner";
import { OwnerLayout } from "src/templates/Responsive/Owner/layout";

export const ownerRoutes: RouteObject[] = [
  {
    path: ROUTES.home,
    element: <OwnerLayout />,
    children: [
      {
        path: ROUTES.home,
        element: <OwnerDashboardPage />,
      },
      {
        path: ROUTES.voyage,
        element: <VoyagePage />,
      },
      {
        path: ROUTES.cargos,
        element: <CargosPage />,
      },
      {
        path: ROUTES.actions,
        element: <ActionsPage />,
      },
      {
        path: ROUTES.futurePlan,
        element: <FuturePlanPage />,
      },
      {
        path: ROUTES.instruction,
        element: <InstructionPage />,
      },
      {
        path: ROUTES.ShipDailyReportOwner,
        element: <ShipsDailyReportPage />,
      },
      {
        path: ROUTES.ShipDailyReportShips,
        element: <ShipDailyReportShipsPage />,
      },
      {
        path: ROUTES.ShipDailyReportCalendar,
        element: <DailyReportCalendar />,
      },
      {
        path: ROUTES.ShipDailyReports,
        element: <ShipDailyReportsPage />,
      },
      {
        path: ROUTES.ShipDailyReportDetail,
        element: <ShipDailyReportDetailPage />,
      },
    ],
  },

  { path: "*", element: <Navigate to={ROUTES.home} /> },
];
