import { LoadingOutlined } from "@ant-design/icons";
import { App, Col, Typography, Upload } from "antd";
import { RcFile, UploadFile } from "antd/es/upload";
import { UploadProps } from "antd/lib";
import { UploadChangeParam } from "antd/lib/upload";
import { FC, useEffect, useState } from "react";
import { getBase64 } from "src/helpers/getBase64";
import { UploadZipContainer } from "../../styles/AttachZipFileStyles/uploadZip.style";
import { useAttachZipFileContext } from "../../context/attachZipFile";
import { USER_JWT_TOKEN } from "src/constants";

export const UploadScreenShot: FC = () => {
  const [imageUrl, setImageUrl] = useState<string>();
  const { message } = App.useApp();
  const [loading, setLoading] = useState(false);
  const {
    value: { screenshots, newRequestId },
    dispatch: { setScreenshots },
  } = useAttachZipFileContext()!;
  const uploadButton = (
    <div>
      {loading ? (
        <LoadingOutlined color="#A3AED0" />
      ) : (
        <span className="material-icons text-[#A3AED0] ">add </span>
      )}
      <div className="text-[#A3AED0]">Upload</div>
    </div>
  );

  const handleChange: UploadProps["onChange"] = (
    info: UploadChangeParam<UploadFile>
  ) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "error") {
      setLoading(false);
      message.error("Error uploading file");
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      setScreenshots(info.file.originFileObj);
      getBase64(info.file.originFileObj as RcFile, (url) => {
        setLoading(false);
        setImageUrl(url);
      });
    }
  };
  const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload jpg/png file!");
    }

    return isJpgOrPng;
  };
  useEffect(() => {
    if (!screenshots) setImageUrl(undefined);
  }, [screenshots]);
  return (
    <UploadZipContainer>
      <Col span={24}>
        <div className="attachFile">
          <Typography.Text className=" attachFileLabel">
            Upload Screenshot of the date.
          </Typography.Text>
          <Upload
            listType="picture-card"
            className=" flex justify-center upload"
            showUploadList={false}
            beforeUpload={beforeUpload}
            onChange={handleChange}
            name="screenShot"
            action={
              process.env.REACT_APP_API_URL?.concat(
                `Invoice/${newRequestId}/V3/AttachScreenShotToAllRequest`
              ) || ""
            }
            method="PATCH"
            headers={{
              Authorization: `Bearer ` + localStorage.getItem(USER_JWT_TOKEN)!,
            }}
          >
            {imageUrl ? (
              <img
                src={imageUrl}
                alt="avatar"
                style={{
                  width: "100%",
                  aspectRatio: "1",
                }}
              />
            ) : (
              uploadButton
            )}
          </Upload>
        </div>
      </Col>
    </UploadZipContainer>
  );
};
