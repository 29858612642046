import { FC, useEffect, useState } from "react";
import { OwnerHeaderComponent } from "./styles";
import { Avatar, Col, Divider, Drawer, Popover } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { USER_DATA } from "src/constants";
// import { MenuProps } from "antd/lib";
import useGlobalStore from "src/store/global";
import { useLogout } from "src/hook";
import { EditProfile } from "src/modules/editProfile";
import { ChangePassword } from "src/modules/changePassword";

export const OwnerHeader: FC = () => {
  const [userRole, setUserRole] = useState<string[]>([]);
  const { userInfo } = useGlobalStore();
  const [showEditProfile, setShowEditProfile] = useState<boolean>(false);
  const [showChangePassword, setShowChangePassword] = useState<boolean>(false);
  const { logoutHandler } = useLogout();
  const editProfileModalHandler = () => {
    setShowEditProfile(true);
  };
  const changePasswordModalHandler = () => {
    setShowChangePassword(true);
  };
  const [open, setOpen] = useState<boolean>(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    const userRoleInLocal = localStorage.getItem(USER_DATA);
    if (userRoleInLocal) {
      setUserRole(JSON.parse(userRoleInLocal));
    }
  }, []);
  return (
    <OwnerHeaderComponent>
      <Col span={22}>
        {/* <Dropdown menu={{ items }} trigger={["hover"]} placement="bottomLeft"> */}
        <div className="avatar cursor-pointer">
          <Avatar size={59} icon={<UserOutlined />} className="avatar-pic" />
          <div className="flex flex-col">
            <div className="username">
              {userInfo?.firstName} {userInfo?.lastName}
            </div>

            {userRole && userRole.join(" , ").length > 30 ? (
              <Popover title={userRole.join(" , ")}>
                <div className="userRole">
                  {userRole.join(" , ").substring(0, 30).concat("...")}
                </div>
              </Popover>
            ) : (
              <div className="userRole">{userRole?.join(" , ")}</div>
            )}
          </div>
        </div>
        <Drawer
          title={
            <div className="flex justify-between items-center">
              <h4>Menu</h4>
              <span
                className="material-icons text-[#A3AED0] cursor-pointer"
                onClick={onClose}
              >
                close
              </span>
            </div>
          }
          placement={"left"}
          closable={false}
          onClose={onClose}
          open={open}
          key={"1"}
          width={"80%"}
        >
          <div
            className="flex gap-[5px] py-[10px] items-center cursor-pointer select-none"
            onClick={editProfileModalHandler}
          >
            <span className="material-icons w-[24px] h-[24px] text-[#A3AED0]">
              person
            </span>
            <div> Edit Profile</div>
          </div>
          <Divider />
          <div
            className="flex gap-[5px] py-[10px] items-center cursor-pointer select-none"
            onClick={changePasswordModalHandler}
          >
            <span className="material-icons w-[24px] h-[24px] text-[#A3AED0]">
              lock
            </span>
            Change password
          </div>
          <Divider />
          <div
            onClick={logoutHandler}
            className="flex gap-[5px] py-[10px] items-center cursor-pointer select-none"
          >
            <span className="material-icons w-[24px] h-[24px] text-[#A3AED0]">
              logout
            </span>
            Log Out
          </div>
        </Drawer>
        {/* </Dropdown> */}
      </Col>
      <Col span={2}>
        <span
          className="material-icons text-[#A3AED0] cursor-pointer select-none"
          onClick={showDrawer}
        >
          segment
        </span>
      </Col>
      <EditProfile
        isModalOpen={showEditProfile}
        setIsModalOpen={setShowEditProfile}
      />
      <ChangePassword
        isModalOpen={showChangePassword}
        setIsModalOpen={setShowChangePassword}
      />
    </OwnerHeaderComponent>
  );
};
