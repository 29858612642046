import { Button, Col, Divider, Form, Row, Select, Space, message } from "antd";
import { FC, useEffect, useState } from "react";
import { AddNewShipManagementContainer } from "../../styles";
import { FormItem } from "src/components/UiKit/FormItem";
import { SInput } from "src/components/UiKit/Input";
import { INewShipManagement } from "src/services/Ship/models";
import { ShipService } from "src/services/Ship/Ship.service";
import { useShipManagement } from "../../context";
import { SPopconfirm } from "src/components/UiKit/PopConfirm";
import { usePublicApi } from "src/hook/publicApi";
import { AddCountryModal } from "src/components/UiKit/AddCountryModal/AddCountryModal";

export const AddNewShipManagement: FC = () => {
  const {
    value: { countryList },
    func: { getCountry, fetchTableData },
  } = useShipManagement()!;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [submittable, setSubmittable] = useState<boolean>(false);
  const [isAddCountryModalOpen, setIsAddCountryModalOpen] =
    useState<boolean>(false);
  const { requesterRefetch } = usePublicApi();
  const values = Form.useWatch([], form);
  useEffect(() => {
    form.validateFields({ validateOnly: true }).then(
      () => {
        setSubmittable(true);
      },
      () => {
        setSubmittable(false);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  useEffect(() => {
    getCountry();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const onFinish = async (values: INewShipManagement) => {
    setLoading(true);
    const service = new ShipService();
    const reqBody: INewShipManagement = {
      acronymName: values.acronymName,
      address: values.address,
      city: values.city,
      countryId: values.countryId,
      email: values.email,
      name: values.name,
      phoneNumber: values.phoneNumber,
      rank: values.rank,
      webSite: values.webSite,
    };
    try {
      const response = await service.NewShipManagement(reqBody);
      if (response && response.status === 200) {
        message.success(" Ship Management information successfully saved!");
        form.resetFields();
        fetchTableData();
        requesterRefetch();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Form form={form} onFinish={onFinish}>
      <AddNewShipManagementContainer>
        <Row>
          <Col span={24}>
            <div className="pt-[16px] titleHolder">
              Please insert Ship Management information.
            </div>
          </Col>
          <Col span={24} className=" pt-[40px] h-[115px]">
            <Row gutter={32}>
              <Col span={8}>
                <FormItem
                  name="name"
                  label="Company name"
                  rules={[{ required: true }]}
                  required={false}
                >
                  <SInput placeholder="insert" />
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem
                  name="acronymName"
                  label="Abbreviation"
                  rules={[{ required: true }]}
                  required={false}
                >
                  <SInput placeholder="insert" />
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem
                  name="rank"
                  label="Ship management Rank"
                  rules={[{ required: true }]}
                  required={false}
                >
                  <SInput placeholder="insert" />
                </FormItem>
              </Col>
            </Row>
          </Col>

          <Col span={24} className="pt-[24px]">
            <Row gutter={32}>
              <Col span={8}>
                <FormItem
                  name="webSite"
                  label="Website"
                  rules={[{ required: true }]}
                  required={false}
                >
                  <SInput placeholder="insert" />
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem
                  name="email"
                  label="Email"
                  rules={[{ required: true }, { type: "email" }]}
                  required={false}
                >
                  <SInput placeholder="e.g. info@website.com" />
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem
                  name="countryId"
                  label="Country"
                  rules={[{ required: true }]}
                  required={false}
                >
                  <Select
                    style={{ width: "100%" }}
                    placeholder="select"
                    optionLabelProp="label"
                    showSearch
                    optionFilterProp="label"
                    className="selectOption"
                    options={countryList.map((country) => ({
                      label: country.name,
                      value: country.id,
                    }))}
                    dropdownRender={(menu) => (
                      <>
                        <Space style={{ padding: "16px 8px 4px" }}>
                          <div
                            onClick={() => setIsAddCountryModalOpen(true)}
                            className="cursor-pointer flex justify-center items-center"
                          >
                            <span className="material-icons text-[16px] text-[#001377]">
                              add
                            </span>
                            <div className="addHolder"> Add Country</div>
                          </div>
                        </Space>
                        <Divider style={{ margin: "8px 0" }} />
                        {menu}
                      </>
                    )}
                  />
                </FormItem>
              </Col>
            </Row>
          </Col>
          <Col span={24} className="pt-[24px]">
            <Row gutter={32}>
              <Col span={8}>
                <FormItem
                  name="city"
                  label="City"
                  rules={[{ required: true }]}
                  required={false}
                >
                  <SInput placeholder="insert" />
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem
                  name="phoneNumber"
                  label="Phone number"
                  rules={[{ required: true }]}
                  required={false}
                >
                  <SInput placeholder="e.g. +971-4-2965595" />
                </FormItem>
              </Col>
              <Col span={24} className="pt-[24px]">
                <FormItem
                  name="address"
                  label="Address"
                  rules={[{ required: true }]}
                  required={false}
                >
                  <SInput placeholder="insert" />
                </FormItem>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col span={24} className="flex justify-end footer gap-[16px]">
            <SPopconfirm
              description="Are you sure you want to reset all the information you filled out?"
              onConfirm={() => {
                form?.resetFields();
              }}
            >
              <Button className="w-[100px]">Reset</Button>
            </SPopconfirm>
            <Button
              type="primary"
              className="footerButton"
              onClick={form.submit}
              loading={loading}
              disabled={!submittable}
            >
              Finish
            </Button>
          </Col>
        </Row>
      </AddNewShipManagementContainer>
      <AddCountryModal
        getCountry={getCountry}
        isAddCountryModalOpen={isAddCountryModalOpen}
        setIsAddCountryModalOpen={setIsAddCountryModalOpen}
      />
    </Form>
  );
};
