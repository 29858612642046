import { FC } from "react";
import { Select } from "antd";

import { SearchBoxStyles } from "./styles";
import usePublicApiStore from "src/store/publicApi";
import { useTable } from "src/modules/Table/context";

export const SearchBox: FC = () => {
  const { ships } = usePublicApiStore();
  const {
    dispatches: { setShipId },
  } = useTable();
  return (
    <SearchBoxStyles>
      <Select
        placeholder="select ship"
        allowClear
        className="search-box"
        optionFilterProp="label"
        optionLabelProp="title"
        options={ships?.data?.map((item) => ({
          label: item.name,
          value: item.id,
          title: item.abbreviation,
        }))}
        onChange={(value) => {
          setShipId(value);
        }}
      />
    </SearchBoxStyles>
  );
};
