import {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { ShipReportStatus } from "src/models/enums";
import { IObject } from "src/models/interfaces";
import { DailyReportService } from "src/services/DailyReport/dailyReport.service";
import { INewAllShipDailyReportResult } from "src/services/DailyReport/models/result.model";
import useOwnerStore from "src/store/owner";

interface IContext {
  value: {
    reports: INewAllShipDailyReportResult[];
    loading: boolean;
  };
  dispatch: {
    setFilterValue: Dispatch<SetStateAction<IFilterValue>>;
  };
  func: {
    getShipReportById: (id: number, date: string) => Promise<boolean>;
    changeDailyReportReadStatus: (
      shipId: number,
      reportDate: string
    ) => Promise<INewAllShipDailyReportResult | undefined>;
    updateReports: (report: INewAllShipDailyReportResult) => void;
  };
}
interface IFilterValue extends IObject {
  FromReportDate?: string;
  ToReportDate?: string;
  Stauts: number;
}
export const ShipDailyReportsContext = createContext<IContext | undefined>(
  undefined
);

export const ShipDailyReportsProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const [reports, setReports] = useState<INewAllShipDailyReportResult[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { selectedShip } = useOwnerStore();
  const [filterValue, setFilterValue] = useState<IFilterValue>({
    Stauts: ShipReportStatus.Accepted,
    ShipId: selectedShip?.id,
  });

  const AllShipDailyReport = useCallback(async () => {
    setLoading(true);
    let filterSearchParams = filterValue.toString().length < 1 ? "?" : "";
    Object.keys(filterValue).forEach((key) => {
      if (filterValue[key] !== undefined) {
        filterSearchParams = filterSearchParams
          .concat(filterSearchParams === "" ? "" : "&")
          .concat(`${key}=${filterValue[key]}`);
      }
    });
    try {
      const { AllShipDailyReport } = new DailyReportService();
      const result = await AllShipDailyReport(filterSearchParams);
      if (result && result.status === 200) {
        setReports(result.data.records);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValue]);

  const getShipReportById = async (
    id: number,
    date: string
  ): Promise<boolean> => {
    let filterSearchParams = `FromReportDate=${date.toString()}&ToReportDate=${date}`;
    if (selectedShip) {
      filterSearchParams = filterSearchParams.concat(
        `ShipId=${selectedShip.id}`
      );
    }
    try {
      const { AllShipDailyReport } = new DailyReportService();
      const result = await AllShipDailyReport(filterSearchParams);
      if (result && result.status === 200) {
        var index = reports.findIndex(function (report) {
          return report.id === id;
        });
        setReports((prev) => {
          prev[index].isSeen = true;
          return prev;
        });
        return true;
      }
    } catch (err) {
      console.log(err);
    }
    return false;
  };
  const changeDailyReportReadStatus = useCallback(
    async (shipId: number, reportDate: string) => {
      try {
        const { ShipDailyReport } = new DailyReportService();
        const result = await ShipDailyReport(shipId, reportDate);
        if (result && result.data) {
          return result.data;
        }
      } catch (err) {
        console.log("Error getting daily report", err);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedShip]
  );
  const updateReports = (report: INewAllShipDailyReportResult) => {
    setReports((prev) => {
      return prev.map((item) => {
        if (item.id === report.id) {
          return report;
        } else {
          return item;
        }
      });
    });
  };
  useEffect(() => {
    AllShipDailyReport();
  }, [AllShipDailyReport, filterValue]);

  const ContextValue: IContext = {
    value: { reports, loading },
    dispatch: { setFilterValue },
    func: { getShipReportById, changeDailyReportReadStatus, updateReports },
  };
  return (
    <ShipDailyReportsContext.Provider value={ContextValue}>
      {children}
    </ShipDailyReportsContext.Provider>
  );
};
export const useShipDailyReports = () => useContext(ShipDailyReportsContext)!;
