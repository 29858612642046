import { App, Button, Col, Form, Row } from "antd";
import { FC, useEffect, useState } from "react";
import { FormItem } from "src/components/UiKit/FormItem";
import { SInput } from "src/components/UiKit/Input";
import { SystemService } from "src/services/System/System.service";
import { INewFrontAccess } from "src/services/System/models";
import { SelectUiKit } from "src/components/UiKit/select";
import { EditFrontAccessContainer } from "./style";
import { useFrontAccess } from "../../context";
import { FrontPathType } from "../../models";

export const EditFrontAccess: FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const { message } = App.useApp();
  const [form] = Form.useForm();
  const {
    dispatch: { setMode },
    value: { frontAccessForEdit },
    func: { getFrontPath },
  } = useFrontAccess()!;

  const onFinish = async (value: INewFrontAccess) => {
    if (!frontAccessForEdit) return;
    try {
      setLoading(true);
      const { EditFrontPath } = new SystemService();

      const res = await EditFrontPath(frontAccessForEdit.id, value);
      if (res && res.status === 200) {
        message.success("Edit Category successfully updated");
        await getFrontPath().finally(() => {
          form.resetFields();
          setMode("tab");
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const headerEditFrontAccess = (
    <div className="flex justify-between items-center py-[19px] px-[24px] borderBottom">
      <div
        className="flex flex-row gap-[12px] items-center cursor-pointer"
        onClick={() => {
          setMode("tab");
        }}
      >
        <span className="material-icons text-[#2C73FC] text-[32px]">
          keyboard_backspace
        </span>
        <div className=" text-[#2C73FC] text-[24px] ">
          Back to front access table
        </div>
      </div>
      <div className="flex flex-col">
        <div className="text-[#001377] text-[18px]">Edit Front Access </div>
      </div>
    </div>
  );
  useEffect(() => {
    if (frontAccessForEdit) {
      form.setFieldsValue(frontAccessForEdit);
    }
  }, [form, frontAccessForEdit]);

  return (
    <Form form={form} onFinish={onFinish}>
      <EditFrontAccessContainer>
        {headerEditFrontAccess}
        <Row className="background" gutter={[8, 0]}>
          <Col span={24}>
            <div className="pt-[16px] titleHolder">
              Please add front access information.
            </div>
          </Col>
          <Col xs={24} md={12}>
            <FormItem
              name="name"
              label="Name"
              rules={[{ required: true }]}
              required={true}
            >
              <SInput placeholder="insert" />
            </FormItem>
          </Col>

          <Col xs={24} md={12}>
            <FormItem
              name="displayName"
              label="Display name"
              rules={[{ required: true }]}
              required={true}
            >
              <SInput placeholder="insert" />
            </FormItem>
          </Col>

          <Col xs={24} md={12}>
            <FormItem
              name="pathType"
              label="Path type"
              rules={[{ required: true }]}
              required={true}
            >
              <SelectUiKit
                options={[
                  { label: "Page", value: FrontPathType.Page },
                  { label: "Modal", value: FrontPathType.Modal },
                ]}
              />
            </FormItem>
          </Col>

          <Col span={24} className="flex justify-end footer">
            <Button
              className="footerButton"
              onClick={form.submit}
              loading={loading}
            >
              Save edit
            </Button>
          </Col>
        </Row>
      </EditFrontAccessContainer>
    </Form>
  );
};
