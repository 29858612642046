import "./styles/global.style.css";
import { Routers } from "./routes";
import { QueryClient, QueryClientProvider } from "react-query";
import { useEffect } from "react";
import { USER_ACCESS_PATH } from "./constants";
import { IPath } from "./services/Identity/models";
// import { ReactQueryDevtools } from "react-query/devtools";
const App: React.FC = (): JSX.Element => {
  const queryClient = new QueryClient();

  useEffect(() => {
    const paths = localStorage.getItem(USER_ACCESS_PATH);
    const arr: IPath[] = paths ? JSON.parse(paths) : [];
    const filtered = arr.filter((item) => item.path.includes("api/System"));
    console.log({ paths: filtered });
  }, []);
  return (
    <QueryClientProvider client={queryClient}>
      {/* <ReactQueryDevtools initialIsOpen={false} position="bottom-left" /> */}
      <Routers />
    </QueryClientProvider>
  );
};
export default App;
