import { FC, useMemo } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ROUTES } from "src/models/enums";
import { ShipDailyReportHeaderStyle } from "./style";
import { Icons } from "src/components/customIcons";
import useOwnerStore from "src/store/owner";
import { Button, Flex } from "antd";
import { useShipDailyReport } from "../../context";

interface IShipDailyReportHeaderProps {
  handlePrint: () => void;
}

export const ShipDailyReportHeader: FC<IShipDailyReportHeaderProps> = ({
  handlePrint,
}) => {
  const { setHasSelectShip } = useOwnerStore();
  const {
    value: { reports },
  } = useShipDailyReport();
  const [query] = useSearchParams();
  const queryDate = query.get("date");
  const date = useMemo(
    () => (queryDate ? new Date(queryDate) : new Date()),
    [queryDate]
  );
  const navigate = useNavigate();

  const handleClickShips = () => {
    navigate(ROUTES.ShipDailyReportShips);
  };

  const handleClickDate = () => {
    setHasSelectShip(true);
    navigate(ROUTES.ShipDailyReportCalendar);
  };

  return (
    <ShipDailyReportHeaderStyle justify="space-between" align="center">
      <Flex className="w-[50px] h-[50px] " justify="center" align="center">
        <div onClick={handleClickShips} className="ships-link ">
          ships
        </div>
      </Flex>
      <div className="ship-report-header-date" onClick={handleClickDate}>
        <span className="material-icons text-[14px]">calendar_month</span>
        <span className="current-date">
          {date.getDate()} {date.toLocaleString("en-EN", { month: "long" })}{" "}
          {date.getFullYear()}
        </span>
      </div>
      <Flex className="w-[50px] h-[50px] " justify="center" align="center">
        <Button
          type="text"
          disabled={reports.length > 0 ? false : true}
          className="bg-[#fff] hover:!bg-[#fff] p-0 w-[50px] h-[50px]"
          onClick={handlePrint}
        >
          <Icons name="pdfDownload" />
        </Button>
      </Flex>
    </ShipDailyReportHeaderStyle>
  );
};
