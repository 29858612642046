import { FC, useEffect } from "react";
import { AttachFileBaseInfoTableContainer } from "../style";
import { Col, Popconfirm, Typography } from "antd";
import { TableUI } from "src/components/UiKit/table";
import { getRowKey } from "src/helpers/getRowKey";
import { ColumnsType } from "antd/es/table";
import { IGetProfitFiles } from "src/services/profit/models";
import { useAttachFile } from "../context";

export const AttachFileBaseInfoTable: FC = () => {
  const {
    value: { tableDataSource, tableLoading },
    func: { getAttachFiles, deleteFile, downloadFile },
  } = useAttachFile();
  const columns: ColumnsType<IGetProfitFiles> = [
    {
      title: "#",
      key: "id",
      align: "center",
      width: "73px",
      render: (name, record, index) => index + 1,
    },
    {
      title: "File name",
      dataIndex: "name",
      key: "name",
      align: "center",
      width: "124px",
      className: "border-0 border-r-[1px] border-solid border-[#fff]",
    },
    {
      title: "Description",
      dataIndex: "comment",
      key: "comment",
      align: "center",
      width: "775px",
      className: "border-0 border-r-[1px] border-solid border-[#fff]",
    },
    {
      title: "Tools",
      key: "tools",
      align: "center",
      width: "110px",
      render: (text, record) => (
        <div className="flex justify-center items-center gap-[16px] text-[16px]">
          <span
            onClick={() => downloadFile(record.uniqueSystemName)}
            className="cursor-pointer"
          >
            <img src="assets/images/fileDownload.svg" alt="" />
          </span>

          {/* <span
            onClick={() => {}}
            className="material-icons !text-[#0C9D61] text-[16px] cursor-pointer"
          >
            edit_note
          </span> */}
          <Popconfirm
            title="Delete phone number"
            onConfirm={() => deleteFile(record.uniqueSystemName)}
            description="Are you want to delete this?"
          >
            <span className="material-icons !text-[#EC2D30] text-[16px] cursor-pointer">
              delete_forever
            </span>
          </Popconfirm>
        </div>
      ),
    },
  ];

  useEffect(() => {
    getAttachFiles();
  }, [getAttachFiles]);

  return (
    <AttachFileBaseInfoTableContainer>
      <Col span={24}>
        <Typography.Title className="title">
          Shipping files table
        </Typography.Title>
        <TableUI
          columns={columns}
          dataSource={tableDataSource}
          rowKey={getRowKey}
          loading={tableLoading}
          pagination={false}
        />
      </Col>
    </AttachFileBaseInfoTableContainer>
  );
};
