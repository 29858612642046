import { FC } from "react";
import { AddNoonReportContainer } from "./style";
import { AddNewNoonReport } from "./components/Tabs";
import { AddNewNoonReportProvider } from "./context";

export const AddNoonReport: FC = () => {
  return (
    <AddNewNoonReportProvider>
      <AddNoonReportContainer>
        <AddNewNoonReport />
      </AddNoonReportContainer>
    </AddNewNoonReportProvider>
  );
};
