import { Form } from "antd";
import { FormInstance } from "antd/lib";
import {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from "react";
import { IRequester } from "src/services/Public/models";

interface IContext {
  value: { editMode: boolean; requesterData: IRequester | undefined };
  dispatch: {
    setEditMode: Dispatch<SetStateAction<boolean>>;
    setRequesterData: Dispatch<SetStateAction<IRequester | undefined>>;
  };
  func: {};
  form: { formControllerAdd: FormInstance<any> | undefined };
}

export const RequesterContext = createContext<IContext | undefined>(undefined);

export const RequesterProvider: FC<PropsWithChildren> = ({ children }) => {
  const [editMode, setEditMode] = useState<boolean>(false);
  const [requesterData, setRequesterData] = useState<IRequester>();
  const [formControllerAdd] = Form.useForm();
  const ContextValue: IContext = {
    value: { editMode, requesterData },
    dispatch: { setEditMode, setRequesterData },
    func: {},
    form: { formControllerAdd },
  };
  return (
    <RequesterContext.Provider value={ContextValue}>
      {children}
    </RequesterContext.Provider>
  );
};

export const useRequester = () => useContext(RequesterContext);
