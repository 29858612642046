import { ReactNode } from "react";
import { IUserInfo } from "src/services/Identity/models";
import { create } from "zustand";

interface IAlert {
  title: string | ReactNode;
}

interface IStore {
  loading: boolean;
  alert?: IAlert;
  userInfo?: IUserInfo;
  showDrawer: boolean;
  setShowDrawer: (showDrawer: boolean) => void;
  setUserInfo?: (userInfo: IUserInfo) => void;
}

const useGlobalStore = create<IStore>((set) => ({
  loading: false,
  setAlertNotification: (alert?: IAlert) => set(() => ({ alert })),
  userInfo: undefined,
  setUserInfo: (userInfo) => set({ userInfo }),
  showDrawer: false,
  setShowDrawer: (showDrawer) => set({ showDrawer }),
}));

export default useGlobalStore;
