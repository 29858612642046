import { FC } from "react";
import { DeletePopconfirm } from "./popConfirm";
import { Guard } from "src/components/Guard";
import {
  DeletetSupplierPath,
  EditSupplierPath,
} from "src/services/System/guardPath";

export const Tools: FC<{
  onClickDelete: () => void;
  onEdit: () => void;
  onView: () => void;
}> = ({ onClickDelete, onEdit, onView }) => {
  return (
    <div className="flex justify-center items-center gap-[16px] text-[16px]">
      <Guard action={EditSupplierPath}>
        <span
          onClick={() => onEdit()}
          className="material-icons !text-[#01C18F] text-[16px] cursor-pointer"
        >
          edit_note
        </span>
      </Guard>
      <span
        onClick={onView}
        className="material-icons !text-[#5D6881] text-[16px] cursor-pointer"
      >
        visibility
      </span>
      <Guard action={DeletetSupplierPath}>
        <DeletePopconfirm
          onConfirm={onClickDelete}
          description="Are you sure you want to Delete this supplier?"
        >
          <span className="material-icons !text-[#FF1414] text-[16px] cursor-pointer">
            delete_forever
          </span>
        </DeletePopconfirm>
      </Guard>
    </div>
  );
};
