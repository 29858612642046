import { FC, useState } from "react";
import { OwnerHeaderContainer } from "./styles";
import { Col, DatePicker, Divider, Drawer, Flex } from "antd";
import useOwnerStore from "src/store/owner";
import { useLogout } from "src/hook";
import { EditProfile } from "src/modules/editProfile";
import { ChangePassword } from "src/modules/changePassword";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "src/models/enums";
import {
  PageTitleKeysType,
  usePageTitle,
} from "src/templates/mainLayout/hook/usePageTitle";
interface IOwnerHeaderProps {
  backWithDiscardShip?: boolean;
  backHandler?: () => void;
}
export const OwnerHeader: FC<IOwnerHeaderProps> = ({
  backWithDiscardShip = true,
  backHandler,
}) => {
  const { ownerViewDate, setOwnerViewDate, selectedShip, setSelectedShip } =
    useOwnerStore();
  const [showEditProfile, setShowEditProfile] = useState<boolean>(false);
  const [showChangePassword, setShowChangePassword] = useState<boolean>(false);
  let location = useLocation();
  const pageTitle = usePageTitle(location.pathname as PageTitleKeysType);
  const { logoutHandler } = useLogout();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const editProfileModalHandler = () => {
    setShowEditProfile(true);
  };
  const changePasswordModalHandler = () => {
    setShowChangePassword(true);
  };
  const [open, setOpen] = useState<boolean>(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const handleSubTitle = () => {
    let subTitle: string | undefined = "";
    if (pathname === ROUTES.ShipDailyReportCalendar && selectedShip) {
      subTitle = selectedShip?.name;
    } else {
      subTitle =
        pageTitle.split("/")[1] === "ship-name"
          ? selectedShip?.name
          : pageTitle.split("/")[1];
    }

    return subTitle;
  };

  const handleClickBack = () => {
    if (backWithDiscardShip) {
      setSelectedShip(undefined);
    }
    navigate(-1);
  };
  return (
    <OwnerHeaderContainer>
      <Col span={24} className="content">
        <div className="flex gap-[12px]">
          {/* <Icons name="ship" />
          <div className="flex items-center">
            <div className="shipName">{selectedShip?.name}</div>
          </div> */}
          <span
            className="material-icons text-[#fff] text-[24px] select-none cursor-pointer"
            onClick={backHandler ? backHandler : handleClickBack}
          >
            arrow_back_ios_new
          </span>
        </div>

        {pathname === ROUTES.voyage ? (
          <div>
            <DatePicker
              defaultValue={ownerViewDate}
              onChange={(value) => {
                if (value) setOwnerViewDate(value);
              }}
              className="picker"
            />
          </div>
        ) : (
          <Flex vertical align="center">
            <span className="text-[#16px] text-[#fff] font-bold">
              {pageTitle.split("/")[0]}
            </span>
            <span className="text-[#12px] text-[#DADEEC] font-bold">
              {handleSubTitle()}
            </span>
          </Flex>
        )}

        <div>
          <span
            className="material-icons text-[#fff] text-[24px] select-none cursor-pointer"
            onClick={showDrawer}
          >
            segment
          </span>
        </div>
      </Col>
      <Drawer
        title={
          <div className="flex justify-between items-center">
            <h4>Menu</h4>
            <span
              className="material-icons text-[#A3AED0] cursor-pointer "
              onClick={onClose}
            >
              close
            </span>
          </div>
        }
        placement={"left"}
        closable={false}
        onClose={onClose}
        open={open}
        key={"1"}
        width={"80%"}
      >
        <div
          className="flex gap-[5px] py-[10px] items-center"
          onClick={() => navigate(ROUTES.home)}
        >
          <span className="material-icons w-[24px] h-[24px] text-[#A3AED0]">
            dashboard
          </span>
          <div> Dashboard </div>
        </div>
        <Divider />
        <div
          className="flex gap-[5px] py-[10px] items-center"
          onClick={editProfileModalHandler}
        >
          <span className="material-icons w-[24px] h-[24px] text-[#A3AED0]">
            person
          </span>
          <div> Edit Profile</div>
        </div>
        <Divider />
        <div
          className="flex gap-[5px] py-[10px] items-center"
          onClick={changePasswordModalHandler}
        >
          <span className="material-icons w-[24px] h-[24px] text-[#A3AED0]">
            lock
          </span>
          Change password
        </div>
        <Divider />
        <div
          onClick={logoutHandler}
          className="flex gap-[5px] py-[10px] items-center"
        >
          <span className="material-icons w-[24px] h-[24px] text-[#A3AED0]">
            logout
          </span>
          Log Out
        </div>
      </Drawer>
      <EditProfile
        isModalOpen={showEditProfile}
        setIsModalOpen={setShowEditProfile}
      />
      <ChangePassword
        isModalOpen={showChangePassword}
        setIsModalOpen={setShowChangePassword}
      />
    </OwnerHeaderContainer>
  );
};
