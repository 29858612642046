import { FC } from "react";
import { useShipManagement } from "src/modules/shipManagement/context";

export const HeaderShowShipManagement: FC = () => {
  const {
    dispatch: { setShowMode },
    value: { tableCurrentValue },
  } = useShipManagement()!;
  return (
    <div className="flex justify-between items-center py-[19px] px-[24px] borderBottom">
      <div
        className="flex flex-row gap-[12px] items-center cursor-pointer"
        onClick={() => {
          setShowMode(false);
        }}
      >
        <span className="material-icons text-[#2C73FC] text-[32px]">
          keyboard_backspace
        </span>
        <div className=" text-[#2C73FC] text-[24px] ">Back to table</div>
      </div>
      <div className="flex flex-col items-end">
        <div className="text-[#001377] text-[18px]">
          ship management information
        </div>
        <div className="text-[#5D6881] text-[14px]">
          {tableCurrentValue?.name}
        </div>
      </div>
    </div>
  );
};
