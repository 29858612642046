import { FC, useState } from "react";
import { Button, Divider, Flex, Form, Modal } from "antd";
import { SInput } from "src/components/UiKit/Input";
import { IObject } from "src/models/interfaces";
import { mainPath, useDocumentation } from "../../context";
import { ModalFormWrapper } from "../../styles";
import { TextAreaUiKit } from "src/components/UiKit/TextArea";
import { IModalProps } from "./interface";

const CreateFolderModal: FC<IModalProps> = ({ open, onCancel }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [createFolderForm] = Form.useForm();

  const {
    value: { path },
    func: { newDirectoryReq },
  } = useDocumentation();

  const parentName = () => {
    if (path === mainPath) return path;
    const pathArray = path.split("/");
    return pathArray.slice(-1);
  };

  const Title = () => {
    return (
      <>
        <Flex vertical>
          <span className="text-[#001377] text-[18px] font-medium">
            Create Folder
          </span>
          <span>
            <span className="text-[#001377] text-[12px] font-medium">in</span>{" "}
            <span className="text-[#5D6881] text-[12px] font-normal">
              {parentName()}
            </span>
          </span>
        </Flex>
        <Divider className="p-0" />
      </>
    );
  };

  const handleFinishCreateFolder = (values: IObject) => {
    setLoading(true);
    newDirectoryReq(values)
      .then((res) => {
        if (res) {
          closeModal();
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const closeModal = () => {
    createFolderForm.setFieldsValue({
      name: null,
      comment: null,
    });
    onCancel();
  };
  return (
    <Modal
      open={open}
      closable={false}
      onCancel={closeModal}
      title={<Title />}
      footer={false}
    >
      <ModalFormWrapper>
        <Form
          form={createFolderForm}
          onFinish={handleFinishCreateFolder}
          layout="vertical"
          className="w-full flex flex-col justify-center items-center"
        >
          <div className="w-full p-[32px] mt-6 flex flex-col justify-center items-center border border-solid border-[#DADEEC] rounded-xl">
            <Form.Item
              name="name"
              label={
                <span className="text-[#001377] text-left text-[14px] font-medium ml-3">
                  Folder name
                </span>
              }
              rules={[{ required: true }]}
              className="w-full"
            >
              <SInput placeholder="Insert" />
            </Form.Item>
            <Form.Item
              name="comment"
              label={
                <span className="text-[#001377] text-left text-[14px] font-medium ml-3">
                  Description
                </span>
              }
              colon={false}
              className="w-full"
            >
              <TextAreaUiKit
                rows={10}
                placeholder="Description"
              ></TextAreaUiKit>
            </Form.Item>
          </div>

          <div className="mt-8 flex items-center justify-center">
            <Button
              className="mr-3 bg-[#F5F5F5] hover:!border-[#F5F5F5] text-[#8E8E8E] hover:!text-[#8E8E8E] flex items-center"
              onClick={closeModal}
              icon={<span className="material-icons text-[14px]">cancel</span>}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              className="text-[#fff] bg-[#2C73FC] hover:!text-[#fff] hover:!bg-[#2C73FC] border-[#2C73FC] flex items-center"
              icon={
                <span className="material-icons text-[14px]">add_circle</span>
              }
            >
              Create
            </Button>
          </div>
        </Form>
      </ModalFormWrapper>
    </Modal>
  );
};

export default CreateFolderModal;
