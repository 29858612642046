import {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from "react";
import { IShipManagementTabs } from "../models";
import { ShipService } from "src/services/Ship/Ship.service";
import { IGetShipManagement } from "src/services/Ship/models";
import { IGetCountry } from "src/services/System/models";
import { SystemService } from "src/services/System/System.service";
import { useCheckPermission } from "src/hook/checkRoutePermission";
import { NewShipManagementPath } from "src/services/Ship/guardPath";

interface IContext {
  value: {
    editMode: boolean;
    tableCurrentValue: IGetShipManagement | undefined;
    showMode: boolean;
    tableData: IGetShipManagement[];
    tableLoading: boolean;
    countryList: IGetCountry[];
    activeKey: string;
  };
  dispatch: {
    setEditMode: Dispatch<SetStateAction<boolean>>;
    setShowMode: Dispatch<SetStateAction<boolean>>;
    setTableCurrentValue: Dispatch<
      SetStateAction<IGetShipManagement | undefined>
    >;
    setActiveKey: Dispatch<SetStateAction<string>>;
  };
  func: {
    fetchTableData: () => void;
    getCountry: () => void;
  };
}

export const ShipManagementContext = createContext<IContext | undefined>(
  undefined
);

export const ShipManagementProvider: FC<PropsWithChildren> = ({ children }) => {
  const [editMode, setEditMode] = useState<boolean>(false);
  const [showMode, setShowMode] = useState<boolean>(false);
  const [tableCurrentValue, setTableCurrentValue] = useState<
    IGetShipManagement | undefined
  >();
  const { checkActionPermission } = useCheckPermission();
  const [activeKey, setActiveKey] = useState<string>(
    checkActionPermission(NewShipManagementPath)
      ? IShipManagementTabs.addNewShipManagement
      : IShipManagementTabs.shipManagementTable
  );
  //----------------------------------------------------------------Function----------------------------------------------------------------
  const [tableLoading, setTableLoading] = useState<boolean>(false);
  const [tableData, setTableData] = useState<IGetShipManagement[]>([]);
  const fetchTableData = async () => {
    setTableLoading(true);
    try {
      const { GetShipManagement } = new ShipService();
      const response = await GetShipManagement();
      if (response && response.status === 200 && response.data) {
        setTableData(response.data);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setTableLoading(false);
    }
  };

  const [countryList, setCountryList] = useState<IGetCountry[]>([]);
  const getCountry = async () => {
    try {
      const { GetCountry } = new SystemService();
      const response = await GetCountry();
      if (response && response.status === 200) {
        setCountryList(response.data);
      }
    } catch (err) {
      console.log(err);
    } finally {
    }
  };
  const ContextValue: IContext = {
    value: {
      editMode,
      tableCurrentValue,
      showMode,
      tableData,
      tableLoading,
      countryList,
      activeKey,
    },
    dispatch: { setEditMode, setTableCurrentValue, setShowMode, setActiveKey },
    func: { fetchTableData, getCountry },
  };
  return (
    <ShipManagementContext.Provider value={ContextValue}>
      {children}
    </ShipManagementContext.Provider>
  );
};

export const useShipManagement = () => useContext(ShipManagementContext);
