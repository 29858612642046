import { FC, useEffect, useMemo, useState } from "react";
import { OwnerDashboardContentContainer } from "./styles";
import { Calendar, Col, Flex } from "antd";
import dayjs from "dayjs";
import useOwnerStore from "src/store/owner";
import { DailyReportService } from "src/services/DailyReport/dailyReport.service";
import { ROUTES, ShipReportStatus } from "src/models/enums";
import { useNavigate } from "react-router-dom";
import { IUnReadDate } from "src/services/DailyReport/models/result.model";
import moment from "moment";

export const OwnerDashboardContent: FC = () => {
  const { selectedShip, ownerViewDate, setOwnerViewDate } = useOwnerStore();
  const [unReadDate, setUnreadDate] = useState<IUnReadDate[]>([]);
  const [refetchUnreadDate, setRefetchUnreadDate] = useState<number>(0);
  const navigate = useNavigate();
  const refetch = () => {
    setRefetchUnreadDate((prev) => prev + 1);
  };
  useEffect(() => {
    getAllUnReadDate(selectedShip?.id).then((res) => {
      if (res) setUnreadDate(res);
    });
  }, [selectedShip, refetchUnreadDate]);
  const hasReport = useMemo(
    () =>
      ownerViewDate &&
      unReadDate.find((item) =>
        item.reportDate.includes(ownerViewDate.format("YYYY-MM-DD"))
      ),
    [ownerViewDate, unReadDate]
  );

  return (
    <OwnerDashboardContentContainer>
      <Col className="content" span={24}>
        <div className="calendar">
          <Calendar
            style={{ width: "100%", height: "100%" }}
            fullscreen={false}
            onChange={(date) => {
              setOwnerViewDate(date);
            }}
            fullCellRender={(date) => {
              const today = dayjs();
              const report = unReadDate.find((item) =>
                item.reportDate.includes(date.format("YYYY-MM-DD"))
              );
              return (
                <Flex
                  className={`${
                    today.isSame(date, "date") && " !text-[#2C73FC] "
                  }`}
                  justify="center"
                  align="center"
                >
                  <Flex
                    justify="center"
                    align="center"
                    className={`day h-[32px] w-[32px] rounded-[16px] ${
                      report
                        ? report.isSeen
                          ? " bg-[#DADEEC] text-[#00000030] "
                          : " bg-[#FFB800] text-[#fff] "
                        : ""
                    }`}
                  >
                    {date.format("DD")}
                  </Flex>
                </Flex>
              );
            }}
            value={ownerViewDate}
            headerRender={({ value, onChange, onTypeChange, type }) => {
              return (
                <Flex
                  vertical
                  className="bg-[#f2f7ff] header w-full "
                  justify="center"
                  align="center"
                  gap={8}
                >
                  <div className="year">{value.format("YYYY")}</div>
                  <Flex
                    justify="space-around"
                    align="center"
                    gap={8}
                    className="w-full"
                  >
                    <span
                      className="material-icons select-none cursor-pointer"
                      onClick={() => {
                        onChange(
                          dayjs(
                            ownerViewDate
                              ? ownerViewDate
                                  .clone()
                                  .month(ownerViewDate.month() - 1)
                              : dayjs(new Date())
                                  .clone()
                                  .month(dayjs(new Date()).month() - 1)
                          )
                        );
                        refetch();
                      }}
                    >
                      keyboard_arrow_left
                    </span>
                    <div className="month">{value.format("MMMM")}</div>
                    <span
                      className="material-icons select-none cursor-pointer"
                      onClick={() => {
                        onChange(
                          dayjs(
                            ownerViewDate &&
                              ownerViewDate
                                .clone()
                                .month(ownerViewDate.month() + 1)
                          )
                        );
                        refetch();
                      }}
                    >
                      keyboard_arrow_right
                    </span>
                  </Flex>
                </Flex>
              );
            }}
          />
        </div>
      </Col>
      <Col span={24}>
        <Flex justify="center" align="center" gap={32} className="mt-[32px]">
          {ownerViewDate &&
          moment(new Date(ownerViewDate.toISOString())).format("YYYY-MM-DD") !==
            moment(new Date()).format("YYYY-MM-DD") ? (
            <div
              className="footer-button"
              onClick={() => {
                setOwnerViewDate(dayjs());
              }}
            >
              Go today
            </div>
          ) : (
            <div className="footer-button-disabled">Go today</div>
          )}
          <div
            className={`footer-button ${
              !hasReport && "footer-button-disabled"
            }`}
            onClick={() => {
              if (!!hasReport) {
                navigate(ROUTES.ShipDailyReportDetail);
              }
            }}
          >
            View report
          </div>
        </Flex>
        {selectedShip ? (
          <div
            className="footer-button text-center mt-6"
            onClick={() => {
              navigate(ROUTES.ShipDailyReports);
            }}
          >
            View all vessel’s reports
          </div>
        ) : null}
      </Col>
    </OwnerDashboardContentContainer>
  );
};

const getAllUnReadDate = async (shipId?: number) => {
  try {
    const { AllShipDailyReport } = new DailyReportService();
    let query = `Stauts=${ShipReportStatus.Accepted}`;
    if (shipId) query += `&ShipId=${shipId}`;
    const result = await AllShipDailyReport(query);
    if (result && result.data) {
      const unReads: IUnReadDate[] = result.data.records.map((report) => {
        return { reportDate: report.reportDate, isSeen: report.isSeen };
      });
      return unReads;
    }
  } catch (err) {
    console.log("error getting unread date", err);
  }
};
