export const TradeStatus = [
  { name: "No Voyage Yet", id: 1 },
  { name: "in Voyage", id: 2 },
];
export const shipStatus = [
  { name: "Laden", id: 1 },
  { name: "Ballast", id: 2 },
];
export const PositionConstant = [
  { name: "LP / Anchorage", id: 1 },
  { name: "LP / OPL", id: 2 },
  { name: "LP / Berthed", id: 3 },
  { name: "Dry Dock", id: 4 },
  { name: "DP / Anchorage", id: 5 },
  { name: "DP / OPL", id: 6 },
  { name: "DP / Berthed", id: 7 },
  { name: "Repair and Maintenance", id: 8 },
  { name: "Sailing", id: 9 },
];
export enum DailyReportPosition {
  LP_Anchorage = 1,
  LP_OPL = 2,
  LP_Berthed = 3,
  Dry_Dock = 4,
  DP_Anchorage = 5,
  DP_OPL = 6,
  DP_Berthed = 7,
  RepairAndMaintenance = 8,
  Sailing = 9,
}
