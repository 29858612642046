import { FC, useCallback } from "react";
import { RoleListContainer } from "./styles";
import { Col, Spin } from "antd";
import { useAddNewFlowCTX } from "../../context";
import { RoleAndActionCard } from "./Cards";
import { NewFlowTabs } from "../../model/newFlowTabs";

export const RoleAndActionList: FC = () => {
  const {
    value: { flowStepsLoading, selectedRole, flowSteps, activeTab },
    dispatch: { setSelectedRole },
  } = useAddNewFlowCTX();
  const getAllRoles = useCallback(() => {
    let roleCount = 0;
    flowSteps.forEach((step) => {
      roleCount = roleCount + (step.roleNumber || 0);
    });
    return roleCount;
  }, [flowSteps]);
  const getAllActions = useCallback(() => {
    let actionCount = 0;
    flowSteps.forEach((step) => {
      actionCount = actionCount + (step.actionNumber || 0);
    });
    return actionCount;
  }, [flowSteps]);
  return (
    <RoleListContainer gutter={[0, 24]}>
      <Col span={24}>
        <div className="header">
          <p className="title">
            Select step and assign{" "}
            {activeTab === NewFlowTabs.AssignRole ? ` role & user` : "action"}{" "}
            to its.
          </p>
          <div className="flex flex-col justify-center items-center gap-[8px]">
            <p className="stepCount ">{getAllRoles()} Roles</p>
            {activeTab === NewFlowTabs.FlowAction && (
              <p className="stepCount">{getAllActions()} Action</p>
            )}
          </div>
        </div>
      </Col>
      <Col span={24} className="wrapper">
        <div className="content">
          {flowStepsLoading ? (
            <div className="noItem">
              <Spin />
            </div>
          ) : flowSteps.length > 0 ? (
            flowSteps.map((item, index) => (
              <RoleAndActionCard
                key={index}
                {...item}
                selected={selectedRole?.id === item.id}
                onClick={() => {
                  setSelectedRole(item);
                }}
                focused={
                  activeTab === NewFlowTabs.AssignRole
                    ? item.roleNumber
                      ? item.roleNumber > 0
                      : false
                    : item.actionNumber
                    ? item.actionNumber > 0
                    : false
                }
              />
            ))
          ) : (
            <div className="noItem">You have not added a step yet.</div>
          )}
        </div>
      </Col>
    </RoleListContainer>
  );
};
