import { FC, useEffect, useState } from "react";
import useOwnerStore from "src/store/owner";
import { useGetDailyReport } from "../../hook";
import { ReportComponent } from "../ReportComponent";
import { DailyReportService } from "src/services/DailyReport/dailyReport.service";
import dayjs from "dayjs";
import { SelectActionDate } from "./components/selectActionDate";
export const OwnerActions: FC = () => {
  const { dailyReport, selectedShip } = useOwnerStore();
  useGetDailyReport();
  const [actionOn, setActionOn] = useState<string | undefined>(
    dailyReport?.actionOn
  );
  const [reportDate, setReportDate] = useState<dayjs.Dayjs>(
    dayjs(dailyReport?.reportDate)
  );
  const [loading, setLoading] = useState<boolean>(false);
  const getActionOn = async () => {
    if (!selectedShip) return;
    try {
      setLoading(true);
      const { GetOnAction } = new DailyReportService();
      const result = await GetOnAction(selectedShip.id, reportDate);
      if (result) {
        setActionOn(result.data);
      }
    } catch (err) {
      console.log("error getting action on", err);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getActionOn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportDate]);
  return (
    <>
      <SelectActionDate
        selectedDate={reportDate}
        setSelectedDate={setReportDate}
      />
      <ReportComponent
        title="Actions on"
        content={actionOn}
        loading={loading}
        subTitle={reportDate.format("MMMM Do")}
        minHeight="calc(
          100vh - 72px - 91px - 16px - 24px - 16px - 24px - 24px - 76px - 75px
        )"
      />
    </>
  );
};
