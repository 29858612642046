import { InvoiceType } from "./args.models";

export interface IAllRequest {
  id: number;
  uniqueCode: string;
  shipId: number;
  shipName: string;
  requesterId: number;
  requesterName: string;
  requesterType: TRequesterType;
  batch: string;
  requestDate: string;
  fileCount: number;
  comment: string;
  created: string;
  dueDate: string;
  paymentCurrencyId: number;
  payementCurrencyShortName: string;
}
export enum RequesterTypeEnum {
  ShipManagement = 1,
  Operation = 2,
  Trade = 3,
}
export const requesterTypeArr = [
  { name: "Ship management", value: 1 },
  { name: "Operation", value: 2 },
  { name: "Trade", value: 3 },
];
export type RequesterType = keyof typeof RequesterTypeEnum;
export type TRequesterType = 1 | 2 | 3;
export type ExtensionsType =
  | ".jpeg"
  | ".jpg"
  | ".png"
  | ".gif"
  | ".zip"
  | ".rar"
  | ".xls"
  | ".xlsx"
  | ".doc"
  | "docx"
  | ".pdf";
export interface IAllRequestZipAttachment {
  id: number;
  allRequestId: number;
  allRequestUniqueCode: string;
  fileName: string;
  extention: ExtensionsType;
  code: string;
  invoiceCount: number;
  created: string;
  comment: string | null;
  status: number;
  dueDate: string;
  paymentCurrencyId: number;
  paymentCurrencyShortName: string;
  reqTotalAmount: number;
  totalExcAmount: number;
  attachNumber: string;
  attachDate: string;
}

export interface IInvoiceByInvoiceNumber {
  id: number;
  invoiceNumber: string;
}

export interface IFinallyArticle {
  id: number;
  comment: string;
  // currencyShortname: string;
  quantity: number;
  unitQuantity: string;
  unitPrice: number;
  amount: number;
  categoryName: string;
  subCategoryName: string;
  costCode: string;
  "#"?: number;
}
export interface IFinallyInvoice {
  createdDate: string;
  shmInvoiceDate: string;
  suppliernvoiceDate: string;
  referenceNumber: string; // SOS_PTD_240218_A_1 _ 21
  shipAcronym: string;
  requesterAcronym: string;
  supplierName: string;
  supplierId: number;
  shmInvoiceNumber: string;
  supplierInvoiceNumber: string;
  articles: IFinallyArticle[];
  supplireToTalAmount: number;
  diffrenceWithSupplierTotla: number;
  shipManagementTotlaAmount: number;
  diffrenceWithShipManagementTotla: number;
  discount: number;
  tax: number;
  totalInvoiceAmount: number;
  tiaShipManagementPCurreccny: number;
  dueDate: string;
  supervicorRate: number;
  excAmount: number;
  rateState: number;
  invoiceComment: string;
  invoiceCurrencyShortname: string;
  paymentCurrencyShortname: string;
  invoiceType: InvoiceType;
  status: number;
  ourDate: string;
  voyageId: number | undefined;
  voyageNO: string | undefined;
}

export interface IInvoiceFileDetails {
  createdDate: string;
  shmInvoiceNumber: string;
  supplierName: string;
  supplierId: number;
  totalInvoiceAmount: number;
  diffrenceWithShipManagementTotla: number;
  diffrenceWithSupplierTotla: number;
  currencyShortname: string;
  invoiceComment: string;
  invoiceType: InvoiceType;
  id: number;
}

export interface IAllRequestInvoice {
  id: 0;
  uniqueCode: string;
  shipId: number;
  shipName: string;
  requesterId: 0;
  requesterName: string;
  requesterType: 1;
  batch: string;
  requestDate: string;
  fileCount: 0;
  comment: string;
  created: string;
}
export interface IInitInvoiceInof {
  shipName: string;
  requesterName: string;
  paymentCurrencyShortName: string;
  dueDate: string;
  shipId: number;
}

export interface IInvoice
  extends Omit<IFinallyInvoice, "articles" | "supervicorRate"> {
  supervisorRate: number;
  invoiceCurrencyId: number;
}

export interface IAllRequestFileChecker {
  hasZipFile: boolean;
  hasScreenShot: boolean;
}
export interface IRelatedInvoiceObject {
  relatedId: number;
  invoiceId: number;
  invoiceNumber: string;
  relateInvoiceId: number;
}
export interface IRelatedInvoice {
  preInvoices: IRelatedInvoiceObject[];
  reservedFund: IRelatedInvoiceObject[];
}

export interface IAllRequestOPT {
  id: number;
  refrenceNumber: string;
}
