import { Row } from "antd";
import { styled } from "styled-components";

export const FormContainer = styled(Row)`
  margin-top: 8px;
  padding: 0 32px;
  .title {
    color: #001377;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .radio {
    span {
      color: #001377;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  .relatedInvoice {
    color: #001377;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .invoice-base-info {
    color: #001377;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 24px;
  }
  .attachment {
    border-radius: 8px;
    border: 1px dashed var(--shadow, #dadeec);
    display: flex;
    width: 320px;
    padding: 16px 24px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 24px;
  }
  .attachmentBg {
    border-radius: 6px;
    background: var(--3, #f4f7fe);
    display: flex;
    width: 58px;
    height: 58px;
    padding: 4px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  .attachmentHolder {
    border-radius: 8px;
    border: 1px solid var(--functional-color-success, #25e3bf);
    display: flex;
    padding: 4px;
    align-items: flex-start;
    gap: 10px;
    cursor: pointer;
  }
  .formItem {
    label {
      color: #001377;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      height: unset;
    }
  }
  .footer {
    border-top: 1px solid #dadeec;
    padding-top: 32px;
    padding-bottom: 16px;
  }
  .ant-select-disabled {
    .ant-select-selector {
      background: #dadeec !important;
    }
  }
`;
