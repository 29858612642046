import { Row } from "antd";
import styled from "styled-components";

export const ProfitShippingWrapper = styled(Row)`
  .title {
    color: var(--color-Light-Mode-Text-Title, #001377);
    /* En/Headline/H6.SemiBold */
    font-family: "Plus Jakarta Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 0;
  }
  .subTitle {
    color: var(--color-Light-Mode-Text-Title, #001377);
    /* En/Button/B2.Medium */
    font-family: "Plus Jakarta Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;

export const ProfitShippingFormItemsWrapper = styled(Row)`
  .expenses {
    color: var(--color-Icon-Delete, #ec2d30);

    /* En/Headline/H6.SemiBold */
    font-family: "Plus Jakarta Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-block: 24px;
  }
  .income {
    color: var(--color-Icon-Edit, #0c9d61);

    /* En/Headline/H6.SemiBold */
    font-family: "Plus Jakarta Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-block: 24px;
  }
  .rangePicker {
    background: #f4f7fe !important;
    border-color: #f4f7fe !important;
    border-radius: 12px !important;
    height: 46px;
  }
  .rangePicker {
    input::placeholder {
      font-weight: 400;
    }
  }
`;
