export enum Position {
  POLAnchorage = 1,
  POLOPL = 2,
  POLBerthed = 3,
  DryDock = 4,
  PODAnchorage = 5,
  PODOPL = 6,
  PODBerthed = 7,
  RepairAndMaintenance = 8,
}
