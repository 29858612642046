import {
  IChangePassword,
  IEditProfile,
  ILoginArgs,
} from "./models/args.models";
import { IdentityUrls } from "./urls";
import {
  IChangePasswordResult,
  IGetCaptcha,
  ILoginResult,
  IUserInfo,
} from "./models";
import axios from "axios";

export class IdentityService {
  async Login(data: ILoginArgs) {
    try {
      const response = await axios.post<ILoginResult>(IdentityUrls.Login, data);
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async ChangePassword(data: IChangePassword) {
    try {
      const response = await axios.post<IChangePasswordResult>(
        IdentityUrls.Change,
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async UserInfo() {
    try {
      const response = await axios.get<IUserInfo>(IdentityUrls.UserInfo);
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async UpdateUserInfo(data: IEditProfile) {
    try {
      const response = await axios.put<void>(IdentityUrls.UpdateUserInfo, data);
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async GetCaptcha() {
    try {
      const response = await axios.get<IGetCaptcha>(IdentityUrls.GetCaptcha);
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
}
