import { Dispatch, FC, SetStateAction, useEffect } from "react";
import { Button, Form } from "antd";
import { FormInstance } from "antd/lib";

interface SubmitButtonProps {
  submittable: boolean;
  setSubmittable: Dispatch<SetStateAction<boolean>>;
  form?: FormInstance;
  loading: boolean;
  buttonTitle: string;
}

const SubmitButton: FC<React.PropsWithChildren<SubmitButtonProps>> = ({
  submittable,
  setSubmittable,
  form,
  loading,
  buttonTitle,
}) => {
  const values = Form.useWatch([], form);

  useEffect(() => {
    if (form)
      form
        .validateFields({ validateOnly: true })
        .then(() => setSubmittable(true))
        .catch(() => setSubmittable(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, values]);

  return (
    <Button
      type="primary"
      className="w-[100px] justify-center items-center"
      disabled={!submittable}
      loading={loading}
      onClick={() => form?.submit()}
    >
      {buttonTitle}
    </Button>
  );
};

export default SubmitButton;
