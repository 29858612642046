import { Row } from "antd";
import { styled } from "styled-components";

export const EditedAttachZipFileContainer = styled(Row)`
  margin-top: 32px;
  display: flex;
  gap: 24px;
  .title {
    color: var(--text, #001377);
    font-family: DM Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;
