import { FC } from "react";
import { useViewNoonReport } from "../../context";
import { Spin } from "antd";

export const FwInfo: FC = () => {
  const {
    value: { fwInfoProps },
  } = useViewNoonReport();
  return (
    <section className="px-[24px] mt-[30px]">
      <p className="text-[14px] text-[#001377] font-bold">F.W information</p>
      <section className="rounded-lg bg-[#f2f7ff] py-[24px] px-[16px]">
        {fwInfoProps.loading ? (
          <Spin />
        ) : (
          <div className="flex items-center justify-between">
            <div className="w-[33%] flex items-center justify-between">
              <p className="w-[50%] text-left text-[14px] text-[#001377] font-medium">
                Production
              </p>
              <p className="w-[50%] text-left text-[14px] text-[#5d6881] font-normal">
                {fwInfoProps?.data?.Production || "-"}
              </p>
            </div>

            <div className="w-[33%] flex items-center justify-between">
              <p className="w-[50%] text-left text-[14px] text-[#001377] font-medium">
                Consumption
              </p>
              <p className="w-[50%] text-left text-[14px] text-[#5d6881] font-normal">
                {fwInfoProps?.data?.Consumption || "-"}
              </p>
            </div>

            <div className="w-[33%] flex items-center justify-between">
              <p className="w-[50%] text-left text-[14px] text-[#001377] font-medium">
                R.O.B
              </p>
              <p className="w-[50%] text-left text-[14px] text-[#5d6881] font-normal">
                {fwInfoProps.data?.ROB || "-"}
              </p>
            </div>
          </div>
        )}
      </section>
    </section>
  );
};
