import styled from "styled-components";

export const SupplierEditContainer = styled.div`
  .borderBottom {
    border-bottom: 1px solid var(--shadow, #dadeec);
    margin-bottom: 24px;
  }
  .body {
    width: 100%;
    padding: 24px;
    border-radius: 20px;
    background: var(--secondary-primary-white, #fff);

    /* 2 */
    box-shadow: 0px 4px 6px 0px rgba(113, 130, 183, 0.25);
  }
  .footer {
    border-top: 1px solid var(--shadow, #dadeec);
    padding-top: 24px;
    flex-direction: row-reverse;
  }
`;
