export interface IReportAllRequest {
  allRequestId: number;
  created: string;
  zipCode: string;
  shipId: number;
  shipName: string;
  requesterId: number;
  requesterName: string;
  attachmentCount: number;
  duDate: string;
  requesterDate: string;
  paymentCurrencyId: number;
  paymentCurrencyName: string;
  comment: string;
}

export interface IReportAllRequestAttachment {
  attachmentId: number;
  created: string;
  shipName: string;
  attachmentCode: string;
  requesterFileName: string;
  invoiceCount: number;
  comment: string;
}
export interface IReportInvoice {
  invoiceId: number;
  created: number;
  invoiceNumber: string;
  invoiceType: number;
  invoiceStatus: number;
  requesterInvoiceNumber: string;
  supplierId: number;
  supplierName: string;
  totalAmount: number;
  difReqTotalAndOurAmount: number;
  difSUPTotalAndOurAmount: number;
  invoiceCurrencyId: number;
  invoiceCurrencyName: string;
  ourDate: number;
  comment: string;
}

export interface IReportInvoiceArticle {
  invoiceArticleId: number;
  created: string;
  invoiceNumber: string;
  quantity: number;
  unitQuantity: string;
  amount: number;
  category: string;
  categoryCode: string;
  comment: string;
}
export enum InvoiceStatus {
  Open = 1,
  Close = 2,
}
