import { Button, Form, Modal, message } from "antd";
import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { FormItem } from "src/components/UiKit/FormItem";
import { SInput } from "src/components/UiKit/Input";
import { SystemService } from "src/services/System/System.service";
import { INewCountry } from "src/services/System/models";
import { AddCountryModalContainer } from "../../../modules/AddShips/components/AddNewShip/styles";
import { Guard } from "src/components/Guard";
import { NewCountryPath } from "src/services/System/guardPath";

interface IProps {
  isAddCountryModalOpen: boolean;
  setIsAddCountryModalOpen: Dispatch<SetStateAction<boolean>>;
  getCountry: () => void;
}
export const AddCountryModal: FC<IProps> = ({
  getCountry,
  isAddCountryModalOpen,
  setIsAddCountryModalOpen,
}) => {
  const handleOk = () => {
    setIsAddCountryModalOpen(false);
  };

  const handleCancel = () => {
    setIsAddCountryModalOpen(false);
    form.resetFields();
  };
  const [loading, setLoading] = useState<boolean>(false);
  const [submittable, setSubmittable] = useState<boolean>(false);

  const [form] = Form.useForm();

  const values = Form.useWatch([]);

  useEffect(() => {
    form?.validateFields({ validateOnly: true }).then(
      () => {
        setSubmittable(true);
      },
      () => {
        setSubmittable(false);
      }
    );
  }, [values, form]);

  const onFinish = async (values: INewCountry) => {
    setLoading(true);
    const reqBody: INewCountry = {
      abbrivation: values.abbrivation,
      name: values.name,
    };
    try {
      const { NewCountry } = new SystemService();
      const response = await NewCountry(reqBody);
      if (response && response.status === 200) {
        message.success("Country created successfully");
        setIsAddCountryModalOpen(false);
        setSubmittable(false);
        getCountry();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Modal
      rootClassName="AddCountryModal"
      width={421}
      title={<div className="titleHolder">Add country</div>}
      open={isAddCountryModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      closable={false}
      afterClose={() => {
        form.resetFields();
      }}
      footer={
        <div className="flex justify-center content-center gap-[16px] footerHolder">
          <Button className="w-[90px]" onClick={handleCancel}>
            Cancel
          </Button>
          <Guard action={NewCountryPath}>
            <Button
              className="w-[90px]"
              onClick={form.submit}
              loading={loading}
              rootClassName={submittable ? "disable" : "enable"}
            >
              Create
            </Button>
          </Guard>
        </div>
      }
    >
      <AddCountryModalContainer>
        <Form
          form={form}
          onFinish={onFinish}
          className="flex justify-center flex-col content-center items-center gap-[16px] pb-[24px]"
        >
          <FormItem
            name={"name"}
            label="Name"
            rules={[{ required: true }]}
            required={false}
            className="!mb-0 w-[75%]"
          >
            <SInput placeholder="insert" />
          </FormItem>
          <FormItem
            name={"abbrivation"}
            label="Short Name"
            rules={[{ required: true }, { max: 3 }]}
            required={false}
            className="!mb-0 w-[75%]"
          >
            <SInput placeholder="insert" />
          </FormItem>
        </Form>
      </AddCountryModalContainer>
    </Modal>
  );
};
