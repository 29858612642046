import { Row } from "antd";
import styled from "styled-components";

export const OwnerHeaderContainer = styled(Row)`
  .content {
    height: 72px;
    background: var(--2, #2c73fc);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 24px;
    .picker {
      padding: 12px 12px 12px 8px !important;
      border-radius: 10px;
      background: var(--3, #f2f7ff);
    }
    .shipName {
      color: var(--0, #fff);
      font-family: DM Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    .shipDetail {
      color: var(--shadow, #dadeec);
      font-family: DM Sans;
      font-size: 10px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
`;
