import { FC } from "react";
import { OwnerFooterStyle } from "./footer.styles";
import { Link, useLocation } from "react-router-dom";
import { ROUTES } from "src/models/enums";

export const OwnerFooter: FC = () => {
  const items = [
    {
      icon: "directions_boat",
      href: ROUTES.voyage,
      name: "Voyage",
    },
    {
      icon: "oil_barrel",
      href: ROUTES.cargos,
      name: "Cargos",
    },
    {
      icon: "pending_actions",
      href: ROUTES.actions,
      name: "Actions",
    },
    {
      icon: "app_registration",
      href: ROUTES.futurePlan,
      name: "Future plan",
    },
    {
      icon: "lan",
      href: ROUTES.instruction,
      name: "Instruction",
    },
  ];

  return (
    <OwnerFooterStyle>
      {items.map((item, index) => (
        <FooterItem {...item} key={index} />
      ))}
    </OwnerFooterStyle>
  );
};
interface IFooterItemProps {
  href: string;
  icon: string;
  name: string;
}
const FooterItem: FC<IFooterItemProps> = ({ name, href, icon }) => {
  const { pathname } = useLocation();
  return (
    <Link
      to={href}
      className={`flex flex-col justify-center items-center ${
        pathname === href ? "text-[#2C73FC]" : "text-[#5D6881]"
      } `}
    >
      <span className="material-icons text-[24px]">{icon}</span>
      <span className="text-[11px]">{name}</span>
    </Link>
  );
};
