import {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useState,
} from "react";
import { useSearchParams } from "react-router-dom";
import { NoonReportModeType } from "../models/mode";
import { INoonReportTabs } from "../models/tabs";
import { INoonReport } from "src/services/NoonReport/models";
import { IObject } from "src/models/interfaces";
import { NoonReportService } from "src/services/NoonReport/NoonReport.service";

interface IContext {
  value: {
    mode: NoonReportModeType;
    activeKeyNoonReport: string;
    selectedReport: INoonReport | undefined;
    pagination: IPagination;
    filterValue: IFilterValue;
    dataSource: INoonReport[];
    dataLoading: boolean;
  };
  dispatch: {
    setActiveKeyNoonReport: Dispatch<SetStateAction<string>>;
    setSelectedReport: Dispatch<SetStateAction<INoonReport | undefined>>;
    setPagination: Dispatch<SetStateAction<IPagination>>;
    setFilterValue: Dispatch<SetStateAction<IFilterValue>>;
    setDataSource: Dispatch<SetStateAction<INoonReport[]>>;
    setDataLoading: Dispatch<SetStateAction<boolean>>;
  };
  func: {
    changeMode: (mode: NoonReportModeType) => void;
    getNoonReport: () => void;
  };
}

interface IPagination {
  total: number;
  pageSize: number;
  currentPage: number;
}
interface IFilterValue extends IObject {
  ShipId?: number[];
  FromReportDate?: string;
  ToReportDate?: string;
  CurrentSituation?: number[];
  ShipState?: number[];
  Voyage?: number[];
  Cargo?: number[];
}
const defaultContext: IContext = {
  value: {
    mode: "create",
    activeKeyNoonReport: INoonReportTabs.addNewNoonReport,
    selectedReport: undefined,
    dataSource: [],
    dataLoading: false,
    pagination: { total: 10, currentPage: 1, pageSize: 10 },
    filterValue: {
      ShipId: undefined,
      FromReportDate: undefined,
      ToReportDate: undefined,
      CurrentSituation: undefined,
      ShipState: undefined,
      Voyage: undefined,
      Cargo: undefined,
    },
  },
  dispatch: {
    setActiveKeyNoonReport: () => {},
    setSelectedReport: () => {},
    setPagination: () => {},
    setFilterValue: () => {},
    setDataSource: () => {},
    setDataLoading: () => {},
  },
  func: {
    changeMode: () => {},
    getNoonReport: () => {},
  },
};
export const NoonReportCTX = createContext<IContext>(defaultContext);
export const NoonReportProvider: FC<PropsWithChildren> = ({ children }) => {
  const [mode, setMode] = useState<NoonReportModeType>("create");
  const [activeKeyNoonReport, setActiveKeyNoonReport] = useState<string>(
    INoonReportTabs.addNewNoonReport
  );
  const [selectedReport, setSelectedReport] = useState<INoonReport | undefined>(
    undefined
  );
  const [filterValue, setFilterValue] = useState<IFilterValue>(
    defaultContext.value.filterValue
  );
  const [dataSource, setDataSource] = useState<INoonReport[]>([]);
  const [dataLoading, setDataLoading] = useState<boolean>(false);
  let [searchParams] = useSearchParams();
  const [pagination, setPagination] = useState<IPagination>({
    total: 10,
    currentPage: 1,
    pageSize: 10,
  });
  const changeMode = (mode: NoonReportModeType) => {
    setMode(mode);
  };
  const getNoonReport = useCallback(async () => {
    setDataLoading(true);

    try {
      const { AllNoonReport } = new NoonReportService();
      const response = await AllNoonReport(searchParams.toString());
      if (response && response.status === 200 && response.data) {
        setDataSource(response.data.records);
        setPagination((prev) => ({ ...prev, total: response.data.count }));
      }
    } catch (err) {
      console.log(err);
    } finally {
      setDataLoading(false);
    }
  }, [searchParams]);

  const ctxValue: IContext = {
    value: {
      mode,
      activeKeyNoonReport,
      selectedReport,
      pagination,
      filterValue,
      dataSource,
      dataLoading,
    },
    dispatch: {
      setActiveKeyNoonReport,
      setSelectedReport,
      setPagination,
      setFilterValue,
      setDataSource,
      setDataLoading,
    },
    func: { changeMode, getNoonReport },
  };
  return (
    <NoonReportCTX.Provider value={ctxValue}>{children}</NoonReportCTX.Provider>
  );
};
export const useNoonReportCTX = () => useContext(NoonReportCTX);
