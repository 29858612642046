import {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  EditZipFileInformationKeys,
  IUpdateZipInformationForm,
  ZipFileItemsKey,
} from "./models";
import { Form, UploadFile, message } from "antd";
import { InvoiceService } from "src/services/Invoice/Invoice.service";
import {
  IAllRequest,
  IAllRequestFileChecker,
  IUpdateAllRequest,
} from "src/services/Invoice/models";
import { FormInstance } from "antd/lib";
import { Buffer } from "buffer";
import { ZipFileItemsModal } from "./components/AttachZipFile/zipFileModals/zipFileItemsModal";
interface IContext {
  value: {
    activeKey: string;
    isModalOpen: boolean;
    loading: boolean;
    editMode: boolean;
    zipFile: Blob | undefined;
    screenshots: Blob | undefined;
    isModalOpenItems: boolean;
    itemsActiveKey: string;
    editModeItems: boolean;
    fileList: UploadFile[];
    currentAllZipFileId: number | undefined;
    screenShotLoading: boolean;
    deleteButton: boolean;
    openFilePreviewModal: boolean;
    showDeleteModal: boolean;
    fileCheckerLoading: boolean;
    deleteLoading: boolean;
    currentRequest: IAllRequest | undefined;
    updateInfoLoading: boolean;
    updateInfoForm: FormInstance | undefined;
    refreshZipFileDepends: number;
    allRequestFiles: IAllRequestFileChecker | undefined;
    screenShotFileList: UploadFile[];
    imageUrl: string;
    savedZipInfo: boolean;
  };
  dispatch: {
    setActiveKey: Dispatch<SetStateAction<string>>;
    setIsModalOpen: Dispatch<SetStateAction<boolean>>;
    setLoading: Dispatch<SetStateAction<boolean>>;
    setFileCheckerLoading: Dispatch<SetStateAction<boolean>>;
    setEditMode: Dispatch<SetStateAction<boolean>>;
    setZipFile: Dispatch<SetStateAction<Blob | undefined>>;
    setScreenshots: Dispatch<SetStateAction<Blob | undefined>>;
    setIsModalOpenItems: Dispatch<SetStateAction<boolean>>;
    setItemsActiveKey: Dispatch<SetStateAction<string>>;
    setEditModeItems: Dispatch<SetStateAction<boolean>>;
    setScreenShotLoading: Dispatch<SetStateAction<boolean>>;
    setDeleteLoading: Dispatch<SetStateAction<boolean>>;
    setFileList: Dispatch<SetStateAction<UploadFile[]>>;
    setScreenShotFileList: Dispatch<SetStateAction<UploadFile[]>>;
    setCurrentAllZipFileId: Dispatch<SetStateAction<number | undefined>>;
    setDeleteButton: Dispatch<SetStateAction<boolean>>;
    setOpenFilePreviewModal: Dispatch<SetStateAction<boolean>>;
    setShowDeleteModal: Dispatch<SetStateAction<boolean>>;
    setCurrentRequest: Dispatch<SetStateAction<IAllRequest | undefined>>;
    setAllRequestFiles: Dispatch<
      SetStateAction<IAllRequestFileChecker | undefined>
    >;
    setImageUrl: Dispatch<SetStateAction<string>>;
  };
  func: {
    getScreenshot: (id: number) => Promise<string | undefined>;
    onFinishUpdateZipInfo: (values: IUpdateZipInformationForm) => Promise<void>;
    refreshAllRequest: () => void;
    resetFiles: () => void;
    screenShotModalHandler: () => void;
    zipFileModalHandler: () => void;
    onCancelModal: () => void;
    confirmDeleteAllRequest: (id: number) => Promise<void>;
  };
}
const defaultValues: IContext = {
  value: {
    activeKey: "1",
    isModalOpen: false,
    loading: false,
    editMode: false,
    zipFile: undefined,
    screenshots: undefined,
    isModalOpenItems: false,
    itemsActiveKey: "1",
    editModeItems: false,
    fileList: [],
    currentAllZipFileId: undefined,
    screenShotLoading: false,
    deleteButton: false,
    openFilePreviewModal: false,
    showDeleteModal: false,
    fileCheckerLoading: false,
    deleteLoading: false,
    currentRequest: undefined,
    updateInfoLoading: false,
    updateInfoForm: undefined,
    refreshZipFileDepends: 1,
    allRequestFiles: undefined,
    screenShotFileList: [],
    imageUrl: "",
    savedZipInfo: false,
  },
  dispatch: {
    setActiveKey: () => {},
    setIsModalOpen: () => {},
    setLoading: () => {},
    setEditMode: () => {},
    setZipFile: () => {},
    setScreenshots: () => {},
    setIsModalOpenItems: () => {},
    setItemsActiveKey: () => {},
    setEditModeItems: () => {},
    setFileList: () => {},
    setCurrentAllZipFileId: () => {},
    setScreenShotLoading: () => {},
    setDeleteButton: () => {},
    setOpenFilePreviewModal: () => {},
    setShowDeleteModal: () => {},
    setFileCheckerLoading: () => {},
    setDeleteLoading: () => {},
    setCurrentRequest: () => {},
    setAllRequestFiles: () => {},
    setScreenShotFileList: () => {},
    setImageUrl: () => {},
  },
  func: {
    getScreenshot: async (id: number) => {
      return "";
    },
    onFinishUpdateZipInfo: async () => {},
    refreshAllRequest: () => {},
    resetFiles: () => {},
    screenShotModalHandler: () => {},
    zipFileModalHandler: () => {},
    onCancelModal: () => {},
    confirmDeleteAllRequest: async () => {},
  },
};
export const EditZipFileContext = createContext<IContext>(defaultValues);

export const EditZipFileProvider: FC<PropsWithChildren> = ({ children }) => {
  const [activeKey, setActiveKey] = useState<string>(
    EditZipFileInformationKeys.ZipInformation
  );
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [screenShotLoading, setScreenShotLoading] = useState<boolean>(true);
  const [fileCheckerLoading, setFileCheckerLoading] = useState<boolean>(true);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [zipFile, setZipFile] = useState<Blob>();
  const [screenshots, setScreenshots] = useState<Blob>();
  const [isModalOpenItems, setIsModalOpenItems] = useState(false);
  const [itemsActiveKey, setItemsActiveKey] = useState<string>(
    ZipFileItemsKey.File
  );
  const [editModeItems, setEditModeItems] = useState<boolean>(false);

  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [screenShotFileList, setScreenShotFileList] = useState<UploadFile[]>(
    []
  );
  const [currentAllZipFileId, setCurrentAllZipFileId] = useState<number>();
  const [deleteButton, setDeleteButton] = useState<boolean>(false);
  const [openFilePreviewModal, setOpenFilePreviewModal] =
    useState<boolean>(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const [currentRequest, setCurrentRequest] = useState<IAllRequest>();
  const [updateInfoLoading, setUpdateInfoLoading] = useState<boolean>(false);
  const [refreshZipFileDepends, setRefreshZipFileDepends] = useState<number>(1);
  const [allRequestFiles, setAllRequestFiles] =
    useState<IAllRequestFileChecker>();
  const [imageUrl, setImageUrl] = useState<string>("");
  const [savedZipInfo, setSaveZipInfo] = useState<boolean>(false);

  const [updateInfoForm] = Form.useForm();

  const refreshAllRequest = () => setRefreshZipFileDepends((prev) => prev + 1);
  const getScreenshot = async (id: number) => {
    try {
      setScreenShotLoading(true);
      const { AllRequestScreenAttachmentId } = new InvoiceService();
      const result = await AllRequestScreenAttachmentId(id);
      if (result) {
        return result;
      }
    } catch (err) {
      console.log(err);
    } finally {
      setScreenShotLoading(false);
    }
  };

  const fileChecker = useCallback(async (id: number) => {
    try {
      setFileCheckerLoading(true);
      const { AllRequestFileChecker } = new InvoiceService();
      const result = await AllRequestFileChecker(id);
      if (result && result.data) {
        setAllRequestFiles(result.data);
        const { hasScreenShot, hasZipFile } = result.data;
        if (hasZipFile) {
          setFileList([{ name: "zip file", uid: "-1" }]);
        }
        if (hasScreenShot) {
          setScreenShotFileList([
            {
              uid: "-1",
              name: "screenshot.png",
              status: "uploading",
            },
          ]);
          getScreenshot(id).then((res) => {
            if (res) {
              setImageUrl(res);
              setScreenShotFileList([
                {
                  uid: "-1",
                  name: "screenshot.png",
                  status: "done",
                  url: res,
                },
              ]);
              const byteCharacters = Buffer.from(res, "base64").toString(
                "latin1"
              );
              const byteNumbers = new Array(byteCharacters.length);
              for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
              }
              const byteArray = new Uint8Array(byteNumbers);
              const contentType = res.replace("data:", "").split(";base64,")[0];
              const blob = new Blob([byteArray], { type: contentType });

              setScreenshots(blob);
            }
          });
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      setFileCheckerLoading(false);
    }
  }, []);

  const confirmDeleteAllRequest = async (id: number) => {
    setDeleteLoading(true);
    try {
      const { DeleteAllRequest } = new InvoiceService();
      const resp = await DeleteAllRequest(id);
      if (resp && resp.status === 200) {
        onCancelModal();
        refreshAllRequest();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setDeleteLoading(false);
    }
  };

  const onFinishUpdateZipInfo = async (values: IUpdateZipInformationForm) => {
    if (!currentRequest) return;
    try {
      setUpdateInfoLoading(true);
      const { UpdateAllRequest } = new InvoiceService();
      const reqBody: IUpdateAllRequest = {
        comment: values.comment,
        dueDate: values.dueDate ? values.dueDate.format("YYYY-MM-DD") : null,
        paymentCurrencyId: values.paymentCurrencyId,
      };
      const res = await UpdateAllRequest(currentRequest.id, reqBody);
      if (res && res.status === 200) {
        setEditMode(false);
        message.success("Update successfully");
        refreshAllRequest();
        setSaveZipInfo(true);
      }
    } catch (err) {
      console.log(err);
      message.error("Update Failed");
    } finally {
      setEditMode(false);
      setUpdateInfoLoading(false);
    }
  };

  useEffect(() => {
    if (currentRequest && currentRequest.id) {
      fileChecker(currentRequest.id);
    }
  }, [currentRequest, currentRequest?.id, fileChecker]);

  const resetFiles = () => {
    setImageUrl("");
    setScreenShotFileList([]);
    setFileList([]);
    setAllRequestFiles(undefined);
    setCurrentRequest(undefined);
    setScreenshots(undefined);
  };
  const screenShotModalHandler = () => {
    setIsModalOpenItems(true);
    setItemsActiveKey(ZipFileItemsKey.Screenshot);
  };
  const zipFileModalHandler = () => {
    setIsModalOpenItems(true);
    setItemsActiveKey(ZipFileItemsKey.File);
  };
  const onCancelModal = () => {
    setIsModalOpen(false);
    setEditMode(false);
    setZipFile(undefined);
    setActiveKey(EditZipFileInformationKeys.ZipInformation);
    setDeleteButton(false);
    setIsModalOpenItems(false);
    resetFiles();
    setSaveZipInfo(false);
  };
  const contextValue: IContext = {
    value: {
      activeKey,
      isModalOpen,
      loading,
      editMode,
      zipFile,
      screenshots,
      isModalOpenItems,
      itemsActiveKey,
      editModeItems,
      fileList,
      currentAllZipFileId,
      screenShotLoading,
      deleteButton,
      openFilePreviewModal,
      showDeleteModal,
      fileCheckerLoading,
      deleteLoading,
      currentRequest,
      updateInfoLoading,
      updateInfoForm,
      refreshZipFileDepends,
      allRequestFiles,
      screenShotFileList,
      imageUrl,
      savedZipInfo,
    },
    dispatch: {
      setActiveKey,
      setIsModalOpen,
      setLoading,
      setEditMode,
      setZipFile,
      setScreenshots,
      setIsModalOpenItems,
      setItemsActiveKey,
      setEditModeItems,
      setFileList,
      setCurrentAllZipFileId,
      setScreenShotLoading,
      setDeleteButton,
      setOpenFilePreviewModal,
      setShowDeleteModal,
      setFileCheckerLoading,
      setDeleteLoading,
      setCurrentRequest,
      setAllRequestFiles,
      setScreenShotFileList,
      setImageUrl,
    },
    func: {
      getScreenshot,
      onFinishUpdateZipInfo,
      refreshAllRequest,
      resetFiles,
      confirmDeleteAllRequest,
      screenShotModalHandler,
      zipFileModalHandler,
      onCancelModal,
    },
  };
  return (
    <EditZipFileContext.Provider value={contextValue}>
      {children}
      <ZipFileItemsModal />
    </EditZipFileContext.Provider>
  );
};

export const useEditZipFileContext = () => useContext(EditZipFileContext);
