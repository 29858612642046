import { Tabs, TabsProps } from "antd";
import { FC } from "react";
import { TabsContainer } from "src/modules/Category/styles";
import { IPayerTabs } from "../models";
import { AddNewPayer } from "./addPayer";
import { PayerTable } from "./payerTable";
import { usePayer } from "../context";
import { Guard } from "src/components/Guard";
import { AllPayersPath, NewPayerPath } from "src/services/Income/guardPath";

const item: TabsProps["items"] = [
  {
    key: IPayerTabs.addNewPayer,
    label: (
      <span>
        <span className="material-icons text-[10px] p-[8px]">lens</span>
        Add new payer
      </span>
    ),
    children: (
      <Guard action={NewPayerPath}>
        <AddNewPayer />
      </Guard>
    ),
  },
  {
    key: IPayerTabs.payerTable,
    label: (
      <span>
        <span className="material-icons text-[10px] p-[10px]">lens</span>
        Payer table
      </span>
    ),
    children: (
      <Guard action={AllPayersPath}>
        <PayerTable />
      </Guard>
    ),
  },
];

export const PayerTabs: FC = () => {
  const {
    value: { activeKey },
    dispatch: { setACtiveKey },
  } = usePayer();

  const onChange = (key: string) => {
    setACtiveKey(key);
  };
  return (
    <TabsContainer>
      <Tabs onChange={onChange} activeKey={activeKey} animated items={item} />
    </TabsContainer>
  );
};
