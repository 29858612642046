import { FC, useMemo, useState } from "react";
import { AddNewRequesterContainer } from "../style";
import { App, Button, Col, Form, Row } from "antd";
import { FormItem } from "src/components/UiKit/FormItem";
import { SInput } from "src/components/UiKit/Input";
import { AddNewRequesterTable } from "../modules/addNewRequesterTable";
import { IEditRequesterArg, INewRequesterArg } from "src/services/Ship/models";
import { ShipService } from "src/services/Ship/Ship.service";
import { usePublicApi } from "src/hook/publicApi";
import usePublicApiStore from "src/store/publicApi";
import { useRequester } from "../context/context";
import { SPopconfirm } from "src/components/UiKit/PopConfirm";
import { Guard } from "src/components/Guard";
import { NewRequesterPath } from "src/services/Ship/guardPath";
import { RequesterPath } from "src/services/Public/guardPath";

export const AddRequester: FC = () => {
  const { message } = App.useApp();
  const {
    value: { editMode, requesterData },
    dispatch: { setEditMode },
    form: { formControllerAdd },
  } = useRequester()!;
  const { requesterRefetch } = usePublicApi();
  const { requesters } = usePublicApiStore();
  const [addLoading, setAddLoading] = useState<boolean>(false);

  const tableData = useMemo(() => {
    return requesters?.data?.records || [];
  }, [requesters?.data?.records]);

  const onDelete = async (id: number) => {
    try {
      const { DeleteRequester } = new ShipService();
      const res = await DeleteRequester(id);
      if (res && res.status === 200) {
        message.success("Successfully deleted requester ");
        requesterRefetch();
      }
    } catch (err) {
      console.log(err);
    }
  };
  const [editLoading, setEditLoading] = useState<boolean>(false);
  const onEdit = async (value: IEditRequesterArg) => {
    if (!requesterData?.id) return;
    setEditLoading(true);
    try {
      const { EditRequester } = new ShipService();
      const res = await EditRequester(requesterData?.id, value);
      if (res && res.status === 200) {
        message.success("Edit requester successfully");
        requesterRefetch();
        setEditMode(false);
        formControllerAdd?.resetFields();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setEditLoading(false);
    }
  };
  const onAdd = async (value: INewRequesterArg) => {
    try {
      setAddLoading(true);
      const { NewRequester } = new ShipService();
      const result = await NewRequester(value);
      if (result && result.status === 200) {
        message.success("Add request is successfully");
        formControllerAdd?.resetFields();
        requesterRefetch();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setAddLoading(false);
    }
  };
  return (
    <AddNewRequesterContainer>
      <Row>
        <Col span={24}>
          <Guard action={NewRequesterPath}>
            <Row gutter={24}>
              <Col span={24} className="br">
                <Form onFinish={onAdd} form={formControllerAdd}>
                  <Row gutter={24}>
                    <Col span={24} className="titleHolder pt-[16px]">
                      Please insert Requester information.
                    </Col>
                    <Col span={8} className="pt-[40px]">
                      <FormItem
                        name="name"
                        label="Name"
                        rules={[{ required: true }]}
                        required={true}
                        className="w-full"
                      >
                        <SInput placeholder="insert" />
                      </FormItem>
                    </Col>
                    <Col span={8} className="pt-[40px]">
                      <FormItem
                        name="acronymName"
                        label="Abbreviation "
                        rules={[{ required: true }]}
                        required={true}
                        className="w-full"
                      >
                        <SInput placeholder="insert" disabled={editMode} />
                      </FormItem>
                    </Col>
                    <Col span={8} className="pt-[75px]">
                      <div className="flex justify-end gap-[10px]">
                        {editMode ? (
                          <SPopconfirm
                            description="Are you sure you want to reset all the information you filled out?"
                            onConfirm={() => {
                              formControllerAdd?.resetFields();
                            }}
                          >
                            <Button
                              className="w-[100px] cancelButton"
                              onClick={() => {
                                setEditMode(false);
                                formControllerAdd?.resetFields();
                              }}
                            >
                              Cancel
                            </Button>
                          </SPopconfirm>
                        ) : (
                          ""
                        )}
                        <Button
                          className={editMode ? "saveButton" : "saveEditButton"}
                          onClick={
                            editMode
                              ? () =>
                                  onEdit(formControllerAdd?.getFieldsValue())
                              : () => onAdd(formControllerAdd?.getFieldsValue())
                          }
                          loading={editMode ? editLoading : addLoading}
                        >
                          {editMode ? "Save edit" : "Add"}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Guard>
        </Col>
        <Col span={24} className="tableTitle">
          List of registered requesters
        </Col>
        <Col span={24}>
          <Guard action={RequesterPath}>
            <AddNewRequesterTable
              tableData={tableData}
              onDelete={onDelete}
              loading={requesters?.isLoading}
            />
          </Guard>
        </Col>
        {/* <Col span={24}>
          <div className="footer">
            <Button onClick={onFinish} type="primary" className="w-[100px]">
              Finish
            </Button>
            <Button onClick={onCancel} className="w-[100px]">
              Cancel
            </Button>
          </div>
        </Col> */}
      </Row>
    </AddNewRequesterContainer>
  );
};
