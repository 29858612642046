import { forwardRef } from "react";
import { Col } from "antd";
import { VoyageDetails } from "./VoyageDetails";
import { DetailTables } from "./DetailTables";
import { FwInfo } from "./FwInfo";
import { OtherIfo } from "./OtherInfo";
import { SpeedDistanceIfo } from "./SpeedDistance";

interface IProps {}

export const Details = forwardRef<HTMLDivElement | null, IProps>(
  (props, ref) => {
    return (
      <Col span={24} ref={ref}>
        <VoyageDetails />
        <DetailTables />
        <FwInfo />
        <SpeedDistanceIfo />
        <OtherIfo />
      </Col>
    );
  }
);
