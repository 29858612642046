import { USER_ACCESS_PATH } from "src/constants";
import { IPath } from "src/services/Identity/models";

export const useCheckPermission = () => {
  const userAccessPath = localStorage.getItem(USER_ACCESS_PATH);
  const accessPath = userAccessPath ? JSON.parse(userAccessPath) : [];
  const checkRoutePermission = (checkValue: IPath[]) => {
    let hasPermission = false;
    checkValue.forEach(({ path, httpMethod }) => {
      for (const urlInfo of accessPath) {
        if (path.includes(urlInfo.path)) {
          if (urlInfo.httpMethod) {
            if (urlInfo.httpMethod === httpMethod) {
              hasPermission = true;
              break;
            }
          } else {
            hasPermission = true;
            break;
          }
        }
      }
    });
    return hasPermission;
  };
  const checkActionPermission = (checkValue: IPath) => {
    let hasPermission: boolean = false;
    const { path, httpMethod } = checkValue;
    for (const urlInfo of accessPath) {
      if (path.includes(urlInfo.path)) {
        if (urlInfo.httpMethod) {
          if (urlInfo.httpMethod === httpMethod) {
            hasPermission = true;
            break;
          }
        } else {
          hasPermission = true;
          break;
        }
      }
    }

    return hasPermission;
  };
  return { checkRoutePermission, checkActionPermission };
};
