import { IPath } from "../Identity/models";

export const WorkFlowGuardPath: IPath[] = [
  {
    path: "api/WorkFlow/NewWorkFlow",
    httpMethod: "POST",
  },
  {
    path: "api/WorkFlow/WorkFlow",
    httpMethod: "GET",
  },
  {
    path: "api/WorkFlow/{WorkFlowId}/EditWorkFlow",
    httpMethod: "PUT",
  },
  {
    path: "api/WorkFlow/{WorkFlowId}/DeleteWorkFlow",
    httpMethod: "DELETE",
  },
  {
    path: "api/WorkFlow/NewWorkFlowStep",
    httpMethod: "POST",
  },
  {
    path: "api/WorkFlow/{WorkFlowId}/WorkFlowStep",
    httpMethod: "GET",
  },
  {
    path: "api/WorkFlow/{WorkFlowStepId}/EditWorkFlowStep",
    httpMethod: "PUT",
  },
  {
    path: "api/WorkFlow/{WorkFlowStepId}/DeleteWorkFlowStep",
    httpMethod: "DELETE",
  },
  {
    path: "api/WorkFlow/{WorkFlowStepId}/AddRoleUser",
    httpMethod: "POST",
  },
  {
    path: "api/WorkFlow/{WorkFlowStepId}/WorkFlowStepRoleUser",
    httpMethod: "GET",
  },
  {
    path: "api/WorkFlow/{WorkFlowStepId}/DeleteWorkFlowStepRoleUser",
    httpMethod: "DELETE",
  },
  {
    path: "api/WorkFlow/{WorkFlowStepId}/AddAction",
    httpMethod: "POST",
  },
  {
    path: "api/WorkFlow/{WorkFlowStepId}/GetWorkFlowActions",
    httpMethod: "GET",
  },
  {
    path: "api/WorkFlow/{WorkFlowStepActionId}/DeleteWorkFlowStepAction",
    httpMethod: "DELETE",
  },
  {
    path: "api/WorkFlow/{WorkFlowStepActionId}/UpdateWorkFlowStepAction",
    httpMethod: "PUT",
  },
];

export const NewWorkFlowPath: IPath = {
  path: "api/WorkFlow/NewWorkFlow",
  httpMethod: "POST",
};
export const WorkFlowPath: IPath = {
  path: "api/WorkFlow/WorkFlow",
  httpMethod: "GET",
};
export const EditWorkFlowPath: IPath = {
  path: "api/WorkFlow/{WorkFlowId}/EditWorkFlow",
  httpMethod: "PUT",
};
export const DeleteWorkFlowPath: IPath = {
  path: "api/WorkFlow/{WorkFlowId}/DeleteWorkFlow",
  httpMethod: "DELETE",
};
export const NewWorkFlowStepPath: IPath = {
  path: "api/WorkFlow/NewWorkFlowStep",
  httpMethod: "POST",
};
export const WorkFlowStepPath: IPath = {
  path: "api/WorkFlow/{WorkFlowId}/WorkFlowStep",
  httpMethod: "GET",
};
export const EditWorkFlowStepPath: IPath = {
  path: "api/WorkFlow/{WorkFlowStepId}/EditWorkFlowStep",
  httpMethod: "PUT",
};
export const DeleteWorkFlowStepPath: IPath = {
  path: "api/WorkFlow/{WorkFlowStepId}/DeleteWorkFlowStep",
  httpMethod: "DELETE",
};
export const AddRoleUserPath: IPath = {
  path: "api/WorkFlow/{WorkFlowStepId}/AddRoleUser",
  httpMethod: "POST",
};
export const WorkFlowStepRoleUserPath: IPath = {
  path: "api/WorkFlow/{WorkFlowStepId}/WorkFlowStepRoleUser",
  httpMethod: "GET",
};
export const DeleteWorkFlowStepRoleUserPath: IPath = {
  path: "api/WorkFlow/{WorkFlowStepId}/DeleteWorkFlowStepRoleUser",
  httpMethod: "DELETE",
};
export const AddActionPath: IPath = {
  path: "api/WorkFlow/{WorkFlowStepId}/AddAction",
  httpMethod: "POST",
};
export const GetWorkFlowActionsPath: IPath = {
  path: "api/WorkFlow/{WorkFlowStepId}/GetWorkFlowActions",
  httpMethod: "GET",
};
export const DeleteWorkFlowStepActionPath: IPath = {
  path: "api/WorkFlow/{WorkFlowStepActionId}/DeleteWorkFlowStepAction",
  httpMethod: "DELETE",
};
export const UpdateWorkFlowStepActionPath: IPath = {
  path: "api/WorkFlow/{WorkFlowStepActionId}/UpdateWorkFlowStepAction",
  httpMethod: "PUT",
};
