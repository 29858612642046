import { FC } from "react";
import { HeaderContainer } from "./style";
import { FilterPopUp } from "../FrontAccessTable/FilterPopup";

export const TableHeaderItems: FC = () => {
  return (
    <HeaderContainer className="relative">
      <div className="absolute right-[1%] z-10 cursor-pointer">
        <FilterPopUp>
          <div className="flex gap-[16px] items-end">
            <span className="material-icons text-[24px] text-[#2C73FC] mb-[5px]">
              filter_alt
            </span>
            <div className="text-[20px] text-[#2C73FC]">Filter</div>
          </div>
        </FilterPopUp>
      </div>
    </HeaderContainer>
  );
};
