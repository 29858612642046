import { Form, FormInstance, message } from "antd";
import {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useState,
} from "react";
import { ITablePagination } from "src/models/interfaces/pagination";
import { useProfitReport } from "src/modules/ProfitReport/context";
import { AddNewProfitStatusTypeEnum } from "src/modules/ProfitReport/models";
import { IAllProfit, IGetProfit } from "src/services/profit/models";
import { ProfitService } from "src/services/profit/profit.service";

interface ITableProfit {
  value: {
    tableLoading: boolean;
    pagination: ITablePagination;
    dataSource: IAllProfit[];
    profitDeleteId: number | undefined;
    viewData: IAllProfit | undefined;
    editId: number | undefined;
    editData: IGetProfit | undefined;
    editLoading: boolean;
    fromTable: boolean;
  };
  dispatch: {
    setTableLoading: Dispatch<SetStateAction<boolean>>;
    setPagination: Dispatch<SetStateAction<ITablePagination>>;
    setDataSource: Dispatch<SetStateAction<IAllProfit[]>>;
    setProfitDeleteId: Dispatch<SetStateAction<number | undefined>>;
    setViewData: Dispatch<SetStateAction<IAllProfit | undefined>>;
    setEditId: Dispatch<SetStateAction<number | undefined>>;
    setEditData: Dispatch<SetStateAction<IGetProfit | undefined>>;
    setEditLoading: Dispatch<SetStateAction<boolean>>;
    setFromTable: Dispatch<SetStateAction<boolean>>;
  };
  func: {
    getDataTableProfit: () => void;
    DeleteProfit: (value: number) => void;
    getDataViewProfit: (value: number) => void;
    onCancelEdit: (doNotBack?: boolean) => void;
  };
  form: { attachFileBaseInfoForm: FormInstance<any> | undefined };
}

const defaultCtxValue: ITableProfit = {
  value: {
    tableLoading: false,
    pagination: {
      current: 1,
      pageSize: 10,
      total: 10,
    },
    dataSource: [],
    profitDeleteId: undefined,
    viewData: undefined,
    editId: undefined,
    editData: undefined,
    editLoading: false,
    fromTable: false,
  },
  dispatch: {
    setTableLoading: () => {},
    setPagination: () => {},
    setDataSource: () => {},
    setProfitDeleteId: () => {},
    setViewData: () => {},
    setEditId: () => {},
    setEditData: () => {},
    setEditLoading: () => {},
    setFromTable: () => {},
  },
  func: {
    getDataTableProfit: () => {},
    DeleteProfit: () => {},
    getDataViewProfit: () => {},
    onCancelEdit: () => {},
  },
  form: { attachFileBaseInfoForm: undefined },
};

export const TableProfitContext = createContext<ITableProfit>(defaultCtxValue);

export const TableProfitProvider: FC<PropsWithChildren> = ({ children }) => {
  const {
    dispatch: { setStatus, setStep, setShowAttachFile },
    form: { basicInfoForm, shippingInfoForm, tradeInfoForm },
  } = useProfitReport();

  //---------------form-----------------

  const [attachFileBaseInfoForm] = Form.useForm();

  //---------------state-----------------

  const [tableLoading, setTableLoading] = useState<boolean>(false);

  const [dataSource, setDataSource] = useState<IAllProfit[]>([]);

  const [viewData, setViewData] = useState<IAllProfit | undefined>();

  const [profitDeleteId, setProfitDeleteId] = useState<number | undefined>(
    undefined
  );

  const [editId, setEditId] = useState<number | undefined>(undefined);

  const [editData, setEditData] = useState<IGetProfit | undefined>(undefined);

  const [editLoading, setEditLoading] = useState<boolean>(false);

  const [fromTable, setFromTable] = useState<boolean>(false);

  const [pagination, setPagination] = useState<ITablePagination>({
    current: 1,
    pageSize: 10,
    total: 10,
  });

  //---------------func-----------------

  const getDataTableProfit = useCallback(async () => {
    try {
      setTableLoading(true);
      const { AllProfit } = new ProfitService();
      const result = await AllProfit({
        Limit: pagination.pageSize,
        Offset: pagination.current,
      });
      if (result && result.status === 200) {
        setDataSource(result.data.records);
        setPagination((prev) => ({ ...prev, total: result.data.count }));
      }
    } catch (err) {
      console.log(err);
    } finally {
      setTableLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination.current, pagination.pageSize]);

  const DeleteProfit = async (id: number) => {
    try {
      const { DeleteProfit } = new ProfitService();
      const response = await DeleteProfit(id);
      if (response && response.status === 200) {
        message.success("Successfully deleted");
        getDataTableProfit();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getDataViewProfit = async (id: number) => {
    try {
      const { GetProfit } = new ProfitService();
      const response = await GetProfit(id);

      if (response && response.status === 200) {
        setEditData(response.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const onCancelEdit = (doNotBack?: boolean) => {
    basicInfoForm?.resetFields();
    shippingInfoForm?.resetFields();
    tradeInfoForm?.resetFields();
    attachFileBaseInfoForm?.resetFields();
    setShowAttachFile(false);
    setStatus((prev) => ({
      ...prev,
      basic: doNotBack
        ? AddNewProfitStatusTypeEnum.done
        : AddNewProfitStatusTypeEnum.empty,
    }));
    setStep(undefined);
  };
  //---------------effect-----------------

  const ctxValue: ITableProfit = {
    value: {
      tableLoading,
      pagination,
      dataSource,
      profitDeleteId,
      viewData,
      editId,
      editData,
      editLoading,
      fromTable,
    },
    dispatch: {
      setTableLoading,
      setPagination,
      setDataSource,
      setProfitDeleteId,
      setViewData,
      setEditId,
      setEditData,
      setEditLoading,
      setFromTable,
    },
    func: { getDataTableProfit, DeleteProfit, getDataViewProfit, onCancelEdit },
    form: { attachFileBaseInfoForm },
  };

  return (
    <TableProfitContext.Provider value={ctxValue}>
      {children}
    </TableProfitContext.Provider>
  );
};

export const useTableProfit = () => useContext(TableProfitContext);
