import axios from "axios";
import { USER_JWT_TOKEN } from "src/constants";
import { responseHandler } from "src/services/responseHandler";
import { useLogout } from "./auth";
import { InvoiceUrls } from "src/services/Invoice/urls";

const noNeedShowErrorUrls = [InvoiceUrls.InvoiceAttachment];
export const useAxiosConfiguration = () => {
  axios.defaults.baseURL = process.env.REACT_APP_API_URL;
  const { logoutHandler } = useLogout();

  let hasError = true;

  axios.interceptors.response.use(
    (response) => {
      if (response?.status !== 200) {
        noNeedShowErrorUrls.forEach((url) => {
          if (response?.config?.url?.includes(url)) {
            hasError = false;
          }
        });
        if (hasError) {
          responseHandler(response);
        }
        if (response?.status === 401) {
          logoutHandler();
        }
      }
      return response;
    },
    (error) => {
      if (error?.response?.status === 401) {
        logoutHandler();
      }

      noNeedShowErrorUrls.forEach((url) => {
        if (error?.config?.url?.includes(url)) {
          hasError = false;
        }
      });
      if (hasError) {
        responseHandler(error);
      }
      return error;
    }
  );

  axios.interceptors.request.use(
    (config) => {
      const accessToken = localStorage.getItem(USER_JWT_TOKEN);
      if (accessToken && !config.url?.includes("Login")) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      }
      return config;
    },
    (error) => {
      console.log("here");
      responseHandler(error);
      throw Promise.reject(error);
    }
  );
};
