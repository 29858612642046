import { FC } from "react";
import { AddFlow } from "../AddFlow";
import { useFlowCTX } from "../../context";
import { useAddNewFlowCTX } from "../AddFlow/context";
import { Divider } from "antd";
import { IFlowTabs } from "../../models/tabs";

export const EditFlow: FC = () => {
  const {
    value: { mode },
    func: { changeMode },

    dispatch: { setActiveKeyFlow },
  } = useFlowCTX();
  const {
    func: { reset },
    value: { editWorkFlowInfo },
  } = useAddNewFlowCTX();
  return (
    <div>
      <div className="flex justify-between items-center  py-[19px]">
        <div
          className="select-none cursor-pointer text-[#2C73FC] flex justify-start items-center gap-[12px] "
          onClick={() => {
            reset();
            changeMode("create");
            setActiveKeyFlow(IFlowTabs.flowTable);
          }}
        >
          <span className="material-icons text-[24px]">keyboard_backspace</span>
          <span className="text-[18px]">Back to table</span>
        </div>
        <div>
          <div className="text-[18px] text-[#001377] font-[700] text-right">
            {mode === "edit" ? "Edit Flow" : "View Flow"}
          </div>
          <div className="text-[14px] text-[#5D6881] text-right">
            {editWorkFlowInfo?.name}
          </div>
        </div>
      </div>
      <Divider className="mb-[32px] mt-0" />
      <AddFlow />
    </div>
  );
};
