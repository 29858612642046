import { FC } from "react";
import { Col, Row } from "antd";
import { ColumnsType } from "antd/es/table";
import {
  IFOMGOCONS,
  IFOMGOROB,
  ILOROBCons,
} from "src/services/NoonReport/models";
import { FOMGOTypeName, LoTypeName } from "src/models/enums";
import { TableUI } from "src/components/UiKit/table";
import { useViewNoonReport } from "../../context";

export const DetailTables: FC = () => {
  const {
    value: { foMgoRobProps, loMgoConsProps, foMgoConsProps },
  } = useViewNoonReport();

  return (
    <Row gutter={[16, 32]} className="mt-[40px]">
      <Col span={12}>
        <span className="text-[14px] text-[#001377] font-bold pl-[24px]">
          FO,MGO ROB
        </span>
        <TableUI
          columns={FoMgoRobColumns}
          tableType="primary"
          dataSource={foMgoRobProps.data}
          loading={foMgoRobProps.loading}
        />
      </Col>
      <Col span={12}>
        <span className="text-[14px] text-[#001377] font-bold pl-[24px]">
          LO, ROB, Cons
        </span>
        <TableUI
          columns={loMgoConsColumns}
          tableType="primary"
          dataSource={loMgoConsProps.data}
          loading={loMgoConsProps.loading}
        />
      </Col>
      <Col span={24}>
        <span className="text-[14px] text-[#001377] font-bold pl-[24px]">
          FO,MGO Cons
        </span>
        <TableUI
          columns={foMgoConsColumns}
          tableType="primary"
          dataSource={foMgoConsProps.data}
          loading={foMgoConsProps.loading}
        />
      </Col>
    </Row>
  );
};

export const FoMgoRobColumns: ColumnsType<IFOMGOROB> = [
  {
    title: "FO/MGO type",
    dataIndex: "type",
    key: "type",
    align: "center",
    render: (value) => FOMGOTypeName[value - 1],
  },
  {
    title: "Last day ROB",
    dataIndex: "LastDayROB",
    key: "LastDayROB",
    align: "center",
  },
  {
    title: "ROB",
    dataIndex: "ROB",
    key: "ROB",
    align: "center",
  },
];

export const loMgoConsColumns: ColumnsType<ILOROBCons> = [
  {
    title: "LO type",
    dataIndex: "type",
    key: "type",
    align: "center",
    render: (value) => LoTypeName[value - 1],
  },
  {
    title: "Cons",
    dataIndex: "Cons",
    key: "Cons",
    align: "center",
  },
  {
    title: "ROB",
    dataIndex: "ROB",
    key: "ROB",
    align: "center",
  },
];

export const foMgoConsColumns: ColumnsType<IFOMGOCONS> = [
  {
    title: "FO/MGO  type",
    dataIndex: "type",
    key: "type",
    align: "center",
    render: (value) => FOMGOTypeName[value - 1],
  },
  {
    title: "M/E",
    dataIndex: "ME",
    key: "ME",
    align: "center",
  },
  {
    title: "G/E",
    dataIndex: "GE",
    key: "GE",
    align: "center",
  },
  {
    title: "A/E2",
    dataIndex: "AE2",
    key: "AE2",
    align: "center",
  },
  {
    title: "A/E3",
    dataIndex: "AE3",
    key: "AE3",
    align: "center",
  },
  {
    title: "Boiler",
    dataIndex: "Boiler",
    key: "AE2",
    align: "center",
  },
  {
    title: "Total",
    dataIndex: "Total",
    key: "Total",
    align: "center",
  },
];
