export enum NoonReportUrls {
  NoonReport = "NoonReport/",
  AllNoonReport = "NoonReport/all",
  NewVoyageInfo = "NoonReport/NewVoyageInfo",
  EditVoyageInfo = "/VoyageInfo",
  DeleteNoonReport = "/DeleteNoonReport",
  NewFOMGOROB = "/NewFOMGOROB",
  GetFOMGOROB = "/GetFOMGOROB",
  NewFOMGOCONS = "/NewFOMGOCONS",
  GetFOMGOCONS = "/GetFOMGOCONS",
  NewLOROBCons = "/NewLOROBCons",
  GetLOROBCons = "/GetLOROBCons",
  EditFOMGOROB = "/EditFOMGOROB",
  EditFOMGOCONS = "/EditFOMGOCONS",
  EditLOROBCons = "/EditLOROBCons",
  FWInfo = "/FWInfo",
  SpeedDistanceInfo = "/SpeedDistanceInfo",
  OtherInfo = "/OtherInfo",
}
