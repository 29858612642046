import { styled } from "styled-components";

export const SearchBoxStyles = styled.div`
  width: 240px;
  .ant-select {
    height: 48px;
    background: var(--0, #fff);
    border-radius: 12px;
  }
  .search-box {
    width: 100%;
    display: flex;
    align-items: center;
  }
  input {
    height: 100% !important;
  }
  .ant-select-selector {
    display: flex;
    align-items: center;
    border-radius: 12px;
  }
  .ant-select-arrow {
    top: unset;
  }
  .ant-input-affix-wrapper {
    width: 240px;
    height: 40px;
    border: unset;
  }

  .ant-input,
  .ant-input-prefix {
    border: none;
  }

  .ant-input-prefix {
    padding: 0;
    border-radius: 10px 0 0 10px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;

    .material-icons {
      font-size: 20px;
      color: #1b2559;
    }
  }

  .ant-input {
    padding-left: 0;
    border-radius: 0 10px 10px 0;

    /* &:focus {
      box-shadow: none !important;
    } */
  }
`;
