import { FC, useEffect } from "react";
import { UseAgents } from "../../context";
import { ColumnsType } from "antd/es/table";
import { AgentsModalContainer, MobileModalTitle } from "../../style";
import { Button, Modal } from "antd";
import { TableUI } from "src/components/UiKit/table";
import { getRowKey } from "src/helpers/getRowKey";
import {
  IAllAgentContact,
  NewAgentContactEnum,
} from "src/services/BaseInfo/models";

export const PhoneModal: FC = () => {
  const {
    value: { isOpenModalPhone, loadingEmailOrPhone, phoneData, currentAgentId },
    func: { closeModalPhone, getAgentPhoneOrEmailData },
  } = UseAgents();
  const columns: ColumnsType<IAllAgentContact> = [
    {
      title: "#",
      key: "id",
      align: "center",
      width: "61px",
      render: (name, record, index) => index + 1,
    },
    {
      title: "Phone",
      dataIndex: "value",
      key: "phoneNumber",
      align: "center",
    },
  ];
  useEffect(() => {
    if (isOpenModalPhone) {
      getAgentPhoneOrEmailData(NewAgentContactEnum.Phone);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAgentPhoneOrEmailData]);
  return (
    <AgentsModalContainer className="flex justify-center items-center gap-[16px] text-[16px]">
      <Modal
        open={isOpenModalPhone}
        closeIcon={false}
        onCancel={closeModalPhone}
        title={
          <MobileModalTitle>
            <p className="text-[18px] font-semibold text-[#001377] mb-[2px] mt-[0px] ">
              Agent
            </p>
            <span className="text-[12px] text-[#3E4556] font-medium pr-[8px] border-0 border-r-[2px] border-solid border-[#DADEEC]">
              {currentAgentId?.name}
            </span>
            <span className="text-[12px] text-[#878787] font-normal ml-[8px]">
              Phone
            </span>
          </MobileModalTitle>
        }
        footer={() => (
          <div className="flex justify-center ">
            <Button
              type="default"
              onClick={closeModalPhone}
              className="flex gap-[12px] justify-center font-semibold text-[#4B4B4B] text-[16px]"
            >
              <span className="material-icons text-[24px]">highlight_off</span>
              Cancel
            </Button>
          </div>
        )}
      >
        <div className="flex justify-center items-center rounded-[12px] border border-solid border-[#DADEEC] my-[32px]">
          <TableUI
            columns={columns}
            loading={loadingEmailOrPhone.phone}
            dataSource={phoneData}
            pagination={false}
            rowKey={getRowKey}
          />
        </div>
      </Modal>
    </AgentsModalContainer>
  );
};
