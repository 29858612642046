import { Button, Divider, Modal, Tabs } from "antd";
import { FC, useCallback, useMemo, useRef } from "react";
import { AddInvoiceContainer } from "./styles";
import { TabsProps } from "antd";
import { useAddInvoices } from "./context";
import { AddInvoiceTabEnums } from "./models";
import { BaseInformation } from "./components/BaseInformation";
import { Description } from "./components/Description";
import { ExcelFileTable } from "./components/Description/ExcelTable";
import { TotalInvoices } from "./components/TotalInvoices";
import { PaymentInformation } from "./components/PaymentInformation";
import { FinallyInvoice } from "./components/FinallyInvoice";
import ReactToPrint, { useReactToPrint } from "react-to-print";

export const AddInvoice: FC = () => {
  const {
    value: {
      activeTab,
      showModal,
      fileIsUploaded,
      showInvoice,
      currentAttachment,
    },
    func: { onCancelModal, reset },
  } = useAddInvoices()!;

  const items: TabsProps["items"] = useMemo(
    () => [
      {
        key: AddInvoiceTabEnums.BaseInformation,
        label: "Base information",
        children: <BaseInformation />,
        // disabled: activeTab !== AddInvoiceTabEnums.BaseInformation,
      },
      {
        key: AddInvoiceTabEnums.Description,
        label: "Description",
        children: fileIsUploaded ? <ExcelFileTable /> : <Description />,
        // disabled: activeTab !== AddInvoiceTabEnums.Description,
      },
      {
        key: AddInvoiceTabEnums.TotalInvoice,
        label: "Total invoice",
        children: <TotalInvoices />,
        // disabled: activeTab !== AddInvoiceTabEnums.TotalInvoice,
      },
      {
        key: AddInvoiceTabEnums.PaymentInformation,
        label: "Payment information",
        children: <PaymentInformation />,
        // disabled: activeTab !== AddInvoiceTabEnums.PaymentInformation,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeTab, fileIsUploaded]
  );

  const componentRef = useRef<HTMLDivElement>(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const reactToPrintContent = useCallback(() => {
    return componentRef.current;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [componentRef.current]);

  return (
    <Modal
      open={showModal}
      closable={true}
      onCancel={onCancelModal}
      footer={null}
      width={
        fileIsUploaded && activeTab === AddInvoiceTabEnums.Description
          ? "100%"
          : 870
      }
      maskClosable={false}
      title={showInvoice ? null : "Add invoice"}
      style={{ top: 24, padding: showInvoice ? 0 : 24, paddingTop: 0 }}
      styles={{ body: { padding: showInvoice ? 0 : 24 } }}
      wrapClassName={showInvoice ? "unset-modal-padding" : ""}
    >
      {showInvoice ? (
        <>
          <ReactToPrint
            content={reactToPrintContent}
            documentTitle="Invoice"
            removeAfterPrint
          />
          <FinallyInvoice ref={componentRef} />
          <Divider />
          <div className="flex justify-center gap-[24px] pb-[32px] ">
            <Button
              onClick={handlePrint}
              icon={<span className="material-symbols-outlined">print</span>}
              className="flex items-center border-solid border-[#3B82F6]"
              type="link"
            >
              Print
            </Button>
            <Button
              onClick={() => {
                reset();
              }}
              icon={<span className="material-icons">close</span>}
              className="flex items-center border-solid border-[#3B82F6]"
              type="link"
            >
              Close
            </Button>
          </div>
        </>
      ) : (
        <AddInvoiceContainer>
          <header className="header">{currentAttachment?.code}</header>
          <Tabs
            activeKey={activeTab}
            items={items}
            centered
            className="h-full"
          />
        </AddInvoiceContainer>
      )}
    </Modal>
  );
};
