import { LoadingOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Form,
  Modal,
  Row,
  Skeleton,
  Typography,
  Upload,
  message,
} from "antd";
import { RcFile, UploadFile } from "antd/es/upload";
import { UploadProps } from "antd/lib";
import { UploadChangeParam } from "antd/lib/upload";
import { FC, useEffect, useState } from "react";
import { useAddShip } from "src/modules/AddShips/context";
import { IAddNewShip } from "src/modules/AddShips/models";
import { ShipsPictureContainer } from "../../AddNewShip/styles";
import { ShipUrls } from "src/services/Ship/urls";
import useAuthStore from "src/store/auth";
import type { GetProp } from "antd";

export const EditShipsPicture: FC = () => {
  const {
    dispatch: { setPictureFileList, setActiveKeyEditShip },
    value: { pictureFileList, tableCurrentValue, picLoading },
    forms: { ShipsPictureForm },
    func: { fetchImage },
  } = useAddShip()!;
  const [previewOpen, setPreviewOpen] = useState<boolean>(false);
  const [previewImage, setPreviewImage] = useState<string>("");
  const [previewTitle, setPreviewTitle] = useState<string>("");

  type FileType = Parameters<GetProp<UploadProps, "beforeUpload">>[0];
  const getBase64 = (file: FileType): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file as Blob);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });
  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as FileType);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1)
    );
  };

  const { token } = useAuthStore();
  const [loading, setLoading] = useState(false);
  const uploadButton = (
    <div className="w-[66px] h-[66px] flex justify-center items-center flex-col">
      {loading ? (
        <LoadingOutlined color="#A3AED0" />
      ) : (
        <span className="material-icons text-[#A3AED0] text-[20px] ">add</span>
      )}
      <div className="text-[#A3AED0] text-[10px]">Upload</div>
    </div>
  );
  //----------------------------------------------------------------Upload Func -----------------------------------------------------------
  const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload jpg/png file!");

      return;
    }

    return isJpgOrPng;
  };

  const handleChange: UploadProps["onChange"] = async (
    info: UploadChangeParam<UploadFile>
  ) => {
    setPictureFileList(info.fileList);
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      setLoading(false);
      return;
    }
    if (info.file.status === "error") {
      setLoading(false);
      message.error("upload screen shot failed");
      return;
    }
  };

  const nextTab = () => {
    setActiveKeyEditShip(IAddNewShip.technicalInformation);
  };

  const onFinish = () => {
    nextTab();
  };
  useEffect(() => {
    fetchImage();
  }, [fetchImage]);
  return (
    <Form form={ShipsPictureForm} onFinish={onFinish}>
      <div className="flex flex-col justify-between  h-[620px]">
        <ShipsPictureContainer>
          <Col span={24}>
            <div className="titleHolder pt-[16px]">
              Please upload pictures of vessel
            </div>
          </Col>
          <Col span={24} className="w-[100%]">
            <div className="attachFile">
              <Typography.Text className=" attachFileLabel">
                Upload picture of vessel.
              </Typography.Text>
              <div className="flex items-center gap-[32px]">
                <Upload
                  onPreview={handlePreview}
                  listType="picture-card"
                  className=" flex justify-center"
                  showUploadList={true}
                  beforeUpload={beforeUpload}
                  onChange={handleChange}
                  fileList={pictureFileList}
                  disabled={loading}
                  action={process.env.REACT_APP_API_URL?.concat(
                    `${ShipUrls.Ship}${tableCurrentValue?.id}${ShipUrls.Image}`
                  )}
                  headers={{ Authorization: `Bearer ${token}` }}
                  method="PATCH"
                  name="image"
                  maxCount={1}
                >
                  {picLoading ? (
                    <>
                      <Skeleton.Image active className="!w-[66px] !h-[66px]" />
                    </>
                  ) : (
                    uploadButton
                  )}
                </Upload>
                <Modal
                  open={previewOpen}
                  title={previewTitle}
                  footer={null}
                  onCancel={handleCancel}
                >
                  <img
                    alt="example"
                    style={{ width: "100%" }}
                    src={previewImage}
                  />
                </Modal>
              </div>
            </div>
          </Col>
        </ShipsPictureContainer>
        <Row className="flex pt-[24px]">
          <Col span={24}>
            <div className=" flex gap-[16px] footer justify-end">
              <Button
                type="primary"
                className="w-[100px]"
                onClick={() => nextTab}
              >
                Save Edit
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    </Form>
  );
};
