import { FC } from "react";
import { OwnerHeader } from "src/modules/Owner/components/OwnerHeader";
import { ShipDailyReportShips } from "src/modules/Owner";
import { ShipDailyReportShipsProvider } from "src/modules/Owner/components/ShipDailyReportShips/context";

export const ShipDailyReportShipsPage: FC = () => {
  return (
    <>
      <OwnerHeader />
      <ShipDailyReportShipsProvider>
        <ShipDailyReportShips />
      </ShipDailyReportShipsProvider>
    </>
  );
};
