import _ from "lodash";
import Swal from "sweetalert2";

export const responseHandler = (res: any) => {
  const { response } = res;

  console.log({ response });
  if (response.data) {
    Object.keys(response.data).forEach((item: string) => {
      if (item) {
        return Swal.fire({
          text: `${
            _.isArray(item)
              ? response.data[item].join(",")
              : response.data[item]
          }`,
          title: "Error",
          color: "#F04849",
          confirmButtonText: "Close",
          background: "#F4F7FE",
          iconHtml:
            '<img src="/assets/images/sweet-alert/sweetalert-error.png" width="53px" height="53px">',
        });
      }
    });
  }
  if (!response && res.code === "ERR_NETWORK") {
    Swal.fire({
      text: "Internet connection error, try again.",
      title: "Error",
      color: "#F04849",
      confirmButtonText: "Close",
      background: "#F4F7FE",
      iconHtml:
        '<img src="/assets/images/sweet-alert/sweetalert-error.png" width="53px" height="53px">',
    });
    return false;
  }

  if (response?.status === 404) {
    Swal.fire({
      text: "Not fount ",
      title: "Error 404",
      color: "#F04849",
      confirmButtonText: "Close",
      background: "#F4F7FE",
      iconHtml:
        '<img src="/assets/images/sweet-alert/sweetalert-error.png" width="53px" height="53px">',
    });

    return 401;
  }
  if (response?.status === 401 || response?.status === 403) {
    Swal.fire({
      text: "You do not have permission to access.",
      title: "Error",
      color: "#F04849",
      confirmButtonText: "Close",
      background: "#F4F7FE",
      iconHtml:
        '<img src="/assets/images/sweet-alert/sweetalert-error.png" width="53px" height="53px">',
    });

    return 401;
  }
  if (response?.status >= 500) {
    Swal.fire({
      text: "Response error from the server side",
      title: "Error",
      color: "#F04849",
      confirmButtonText: "Close",
      background: "#F4F7FE",
      iconHtml:
        '<img src="/assets/images/sweet-alert/sweetalert-error.png" width="53px" height="53px">',
    });
    return false;
  }

  return response;
};
