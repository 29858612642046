import { FC, Suspense } from "react";
import { AgentsProvider } from "src/modules/agents/context";
import { AgentView } from "src/modules/agents/modules/view";
import { PreLoading } from "src/routes/Loading";

export const AgentViewPage: FC = () => {
  return (
    <Suspense fallback={<PreLoading />}>
      <AgentsProvider>
        <AgentView />
      </AgentsProvider>
    </Suspense>
  );
};
