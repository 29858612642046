import { FC } from "react";
import { TopBarStyles } from "./styles";
import { Title } from "./title";
import { useLittleCardItems } from "src/modules/home/constants/little-card-items";
import { LittleCard } from "src/modules/home/components/little-card";
import { SearchBox } from "src/components/UiKit/search-box";
import { Guard } from "src/components/Guard";

export const TopBar: FC = () => {
  const { littleCardItems } = useLittleCardItems();

  return (
    <TopBarStyles>
      <div className="gap-[16px] littleCard ">
        {littleCardItems.map((item, index) => (
          <Guard action={item.actions} multiPath key={index}>
            <LittleCard {...item} />
          </Guard>
        ))}
      </div>
      <div className="flex flex-row !justify-between p-[24px]">
        <Title />
        <SearchBox />
      </div>
    </TopBarStyles>
  );
};
