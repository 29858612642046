import { IPath } from "../Identity/models";

export enum ShipUrls {
  Ship = "Ship/",
  Image = "/Image",
  ShipBasicInformation = "Ship/ShipBasicInformation",
  ShipTechnicalInformation = "/ShipTechnicalInformation",
  ShipOwnerInformation = "/ShipOwnerInformation",
  ShipPriceInformation = "/ShipPriceInformation",
  ShipInsuranceInformation = "/ShipInsuranceInformation",
  NewRequester = "Ship/NewRequester",
  DeleteRequester = "/DeleteRequester",
  EditRequester = "/EditRequester",
  EditShipBasicInformation = "/EditShipBasicInformation",
  EditShipInsuranceInformation = "/EditShipInsuranceInformation",
  NewShipManagement = "Ship/NewShipManagement",
  GetShipManagement = "Ship/GetShipManagement",
  ShipManagmentContract = "/ShipManagmentContract",
  DeleteShip = "/DeleteShip",
  EditShipManagmentContract = "/EditShipManagmentContract",
  DeleteShipInsuranceInformation = "/DeleteShipInsuranceInformation",
  EditShipManagement = "/EditShipManagement",
  DeletetShipManagement = "/DeletetShipManagement",
}

export const ShipApiUrls: IPath[] = [
  {
    path: "api/Ship/NewOwnerCompany",
    httpMethod: "POST",
  },
  {
    path: "api/Ship/NewOwnerShip",
    httpMethod: "POST",
  },
  {
    path: "api/Ship/NewShip",
    httpMethod: null,
  },
  {
    path: "api/Ship/{shipId}/Image",
    httpMethod: "PATCH",
  },
  {
    path: "api/Ship/{shipId}/Image",
    httpMethod: "GET",
  },
  {
    path: "api/Ship/NewShipManagement",
    httpMethod: "POST",
  },
  {
    path: "api/Ship/NewOperator",
    httpMethod: "POST",
  },
  {
    path: "api/Ship/NewTrade",
    httpMethod: "POST",
  },
  {
    path: "api/Ship/NewRequester",
    httpMethod: "POST",
  },
  {
    path: "api/Ship/{RequesterId}/DeleteRequester",
    httpMethod: "DELETE",
  },
  {
    path: "api/Ship/{RequesterId}/EditRequester",
    httpMethod: "PUT",
  },
  {
    path: "api/Ship/ShipBasicInformation",
    httpMethod: "POST",
  },
  {
    path: "api/Ship/{shipId}/EditShipBasicInformation",
    httpMethod: "PUT",
  },
  {
    path: "api/Ship/{shipId}/ShipTechnicalInformation",
    httpMethod: "PUT",
  },
  {
    path: "api/Ship/{shipId}/ShipOwnerInformation",
    httpMethod: "PUT",
  },
  {
    path: "api/Ship/{shipId}/ShipPriceInformation",
    httpMethod: "PUT",
  },
  {
    path: "api/Ship/{shipId}/ShipInsuranceInformation",
    httpMethod: "POST",
  },
  {
    path: "api/Ship/{shipId}/ShipInsuranceInformation",
    httpMethod: "GET",
  },
  {
    path: "api/Ship/{ShipInsuranceId}/EditShipInsuranceInformation",
    httpMethod: "PUT",
  },
  {
    path: "api/Ship/{ShipInsuranceId}/DeleteShipInsuranceInformation",
    httpMethod: "DELETE",
  },
  {
    path: "api/Ship/{shipId}/ShipManagmentContract",
    httpMethod: "POST",
  },
  {
    path: "api/Ship/{shipId}/ShipManagmentContract",
    httpMethod: "GET",
  },
  {
    path: "api/Ship/{ShipManagmentContractId}/EditShipManagmentContract",
    httpMethod: "PUT",
  },
  {
    path: "api/Ship/{ShipManagmentContractId}/DeleteShipManagmentContract",
    httpMethod: "DELETE",
  },
  {
    path: "api/Ship/GetShipManagement",
    httpMethod: "GET",
  },
  {
    path: "api/Ship/{shipId}/DeleteShip",
    httpMethod: "DELETE",
  },
  {
    path: "api/Ship/{ShipManagementId}/EditShipManagement",
    httpMethod: "PUT",
  },
  {
    path: "api/Ship/{ShipManagementId}/DeletetShipManagement",
    httpMethod: "DELETE",
  },
];
