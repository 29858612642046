import {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { UploadFile } from "antd/es/upload";
import { IObject } from "src/models/interfaces";
import { DocumentService } from "src/services/Document/Document.service";
import {
  IDocument,
  IEditDocument,
  INewDirectory,
} from "src/services/Document/models";
import { uploadFile } from "../helpers/uploadFile";
import { message } from "antd";
import { RcFile } from "antd/lib/upload";
import { AxiosRequestConfig } from "axios";
import downloadZipFile from "src/helpers/downloadZipFile";

export const mainPath = "Root";
interface IContext {
  value: {
    path: string;
    documents: IDocument[];
    selectedParentId: number[];
    selectedDoc: IDocument | undefined;
    filterValue: IFilterValue;
    dataLoading: boolean;
    fileList: UploadFile[];
    createdId: number;
    progress: number;
    uploadProgress: number;
  };
  dispatch: {
    setPath: Dispatch<SetStateAction<string>>;
    setSelectedParentId: Dispatch<SetStateAction<number[]>>;
    setSelectedDoc: Dispatch<SetStateAction<IDocument | undefined>>;
    setFilterValue: Dispatch<SetStateAction<IFilterValue>>;
    setFileList: Dispatch<SetStateAction<UploadFile[]>>;
    setCreatedId: Dispatch<SetStateAction<number>>;
  };
  func: {
    newDirectoryReq: (values: IObject) => Promise<boolean>;
    editDocumentReq: (values: IObject) => Promise<boolean>;
    deleteDocumentReq: () => Promise<boolean>;
    newFileReq: (values: IObject) => Promise<boolean>;
    downloadDocumentReq: () => Promise<void>;
  };
}
interface IFilterValue extends IObject {
  ParentId?: number | undefined;
  Name?: string;
}
export const DocumentationContext = createContext<IContext | undefined>(
  undefined
);

export const DocumentationProvider: FC<PropsWithChildren> = ({ children }) => {
  const [path, setPath] = useState<string>(mainPath);
  const [documents, setDocuments] = useState<IDocument[]>([]);
  const [selectedParentId, setSelectedParentId] = useState<number[]>([]);
  const [selectedDoc, setSelectedDoc] = useState<IDocument | undefined>(
    undefined
  );
  const [dataLoading, setDataLoading] = useState<boolean>(false);
  const [filterValue, setFilterValue] = useState<IFilterValue>({});
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [createdId, setCreatedId] = useState<number>(0);
  const [progress, setProgress] = useState<number>(0);
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const downloadDocumentReq = async () => {
    if (!selectedDoc) return;
    const config: AxiosRequestConfig = {
      onDownloadProgress: (progressEvent) => {
        const percentage = Math.round(
          (progressEvent.loaded * 100) / (progressEvent.total || 0)
        );
        setProgress(percentage);
        if (percentage === 100) {
          setTimeout(() => setProgress(0), 400);
        }
      },
    };
    try {
      const { DownloadDocument } = new DocumentService();
      const result = await DownloadDocument(selectedDoc?.id, config);
      if (result) {
        downloadZipFile(result);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setProgress(0);
    }
  };
  const newFileReq = async (values: IObject) => {
    try {
      let res;

      res = await uploadFile({
        callbackUrl: `Document/NewFile`,
        newFile: fileList[0].originFileObj as RcFile,
        method: "POST",
        parentId: filterValue.ParentId ? filterValue.ParentId : null,
        name: values.name,
        comment: values.comment,
        setProgress: setUploadProgress,
      });

      if (Number(res) > 0) {
        message.success("File Created SuccessFully.");
        setCreatedId(res);
        getDocument();
        return true;
      } else {
        setCreatedId(0);
      }
    } catch (err) {
      console.log("upload excel file error", err);
    }
    return false;
  };

  const deleteDocumentReq = async () => {
    if (!selectedDoc) return false;
    const { DeleteDocument } = new DocumentService();
    try {
      const response = await DeleteDocument(selectedDoc.id);
      if (response && response.status === 200) {
        getDocument();
        return true;
      }
    } catch (err) {
      console.log(err);
    }
    return false;
  };

  const editDocumentReq = async (values: IObject) => {
    if (!selectedDoc) return false;
    const { EditDocument } = new DocumentService();
    const reqBody: IEditDocument = {
      name: values.name,
      comment: values.comment,
    };
    try {
      const response = await EditDocument(selectedDoc?.id, reqBody);
      if (response && response.status === 200) {
        getDocument();
        return true;
      }
    } catch (err) {
      console.log(err);
    }
    return false;
  };

  const newDirectoryReq = async (values: IObject) => {
    const { NewDirectory } = new DocumentService();
    const reqBody: INewDirectory = {
      name: values.name,
      parentId: filterValue.ParentId ? filterValue.ParentId : null,
      comment: values.comment,
    };
    try {
      const response = await NewDirectory(reqBody);
      if (response && response.status === 200) {
        setCreatedId(response.data);
        getDocument();
        return true;
      } else {
        setCreatedId(0);
      }
    } catch (err) {
      console.log(err);
    }
    return false;
  };

  const getDocument = useCallback(async () => {
    setSelectedDoc(undefined);
    try {
      setDataLoading(true);
      const { GetDocument } = new DocumentService();
      let filterSearchParams = filterValue.toString().length < 1 ? "?" : "";
      Object.keys(filterValue).forEach((key) => {
        if (filterValue[key] !== undefined) {
          filterSearchParams = filterSearchParams
            .concat(filterSearchParams === "?" ? "" : "&")
            .concat(`${key}=${filterValue[key]}`);
        }
      });
      const res = await GetDocument(filterSearchParams);
      if (res && res.status === 200 && res.data) {
        setDocuments(res.data);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setDataLoading(false);
    }
  }, [filterValue]);

  useEffect(() => {
    getDocument();
  }, [filterValue, getDocument]);

  const ContextValue: IContext = {
    value: {
      path,
      documents,
      selectedParentId,
      selectedDoc,
      filterValue,
      dataLoading,
      fileList,
      createdId,
      progress,
      uploadProgress,
    },
    dispatch: {
      setPath,
      setSelectedParentId,
      setSelectedDoc,
      setFilterValue,
      setFileList,
      setCreatedId,
    },
    func: {
      newDirectoryReq,
      editDocumentReq,
      deleteDocumentReq,
      newFileReq,
      downloadDocumentReq,
    },
  };
  return (
    <DocumentationContext.Provider value={ContextValue}>
      {children}
    </DocumentationContext.Provider>
  );
};
export const useDocumentation = () => useContext(DocumentationContext)!;
