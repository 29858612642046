import { FC, useEffect, useState } from "react";
import { AddInformationStyle } from "./style";
import { Button, Flex } from "antd";
import { AddInfoCards } from "./Cards";
import { useProfitReport } from "src/modules/ProfitReport/context";
import { AddNewProfitStatusTypeEnum } from "src/modules/ProfitReport/models";
import { useTableProfit } from "../../../ProftTable/context";

export const AddInformation: FC = () => {
  const {
    value: { status },
    dispatch: { setStatus, setStep, setEditMode },
  } = useProfitReport();

  const {
    dispatch: { setEditData, setEditId },
  } = useTableProfit();

  const [submit, setSubmit] = useState<boolean>(false);

  const newReport = () => {
    setEditMode(false);
    setEditId(undefined);
    setEditData(undefined);
    setStatus({
      basic: AddNewProfitStatusTypeEnum.empty,
      shipping: AddNewProfitStatusTypeEnum.empty,
      trade: AddNewProfitStatusTypeEnum.empty,
    });
    setStep(undefined);
  };

  useEffect(() => {
    if (status.basic === AddNewProfitStatusTypeEnum.done) {
      setSubmit(true);
    } else {
      setSubmit(false);
    }
  }, [status]);

  return (
    <AddInformationStyle>
      <AddInfoCards />
      <Flex className="h-[90px]" vertical justify="end" align="self-end">
        <Button type="primary" disabled={!submit} onClick={newReport}>
          Add new report
        </Button>
      </Flex>
    </AddInformationStyle>
  );
};
