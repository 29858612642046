import { styled } from "styled-components";

export const PortsContainer = styled.div`
  .ant-tabs-tab {
    color: var(--text-2, #a3aed0);
    font-family: DM Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 160% */
    padding: unset;
  }
  .ant-tabs-nav::before {
    border-bottom: 1px solid var(--shadow, #dadeec);
    padding: 24px;
  }
  .ant-tabs-ink-bar {
    height: unset !important;
  }
  .ant-tabs-nav {
    padding-bottom: 24px;
    margin-bottom: 24px !important;
  }
`;
export const AddNewPortContainer = styled.div`
  display: flex;
  height: 631px;
  padding: 24px;
  flex-direction: column;
  justify-content: space-between;
  gap: 24px;
  border-radius: 20px;
  background: var(--Secondary-Primary-White, #fff);
  box-shadow: 0px 4px 6px 0px rgba(113, 130, 183, 0.25);
  .titleHolder {
    color: var(--text, #001377);
    font-family: DM Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .ant-form-item {
    margin-bottom: unset !important;
  }
  .ant-picker-range {
    background: #f4f7fe !important;
    border-color: #f4f7fe !important;
    border-radius: 12px !important;
    min-height: 48px !important;
  }

  &.ant-picker-disabled {
    background: #dadeec !important;
    border-color: #dadeec !important;
  }

  .timeZone {
    display: flex;
    flex-direction: column;

    label {
      color: #001377 !important;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      position: relative;
      display: inline-flex;
      align-items: center;
      max-width: 100%;
      height: 32px;
      font-size: 14px;
      padding-bottom: unset;
      padding-left: 12px;
    }
    label::before {
      display: inline-block;
      margin-inline-end: 4px;
      color: #ff4d4f;
      font-size: 14px;
      font-family: SimSun, sans-serif;
      line-height: 1;
      content: "*";
    }
    .wrapper {
      height: 48px;
      border-radius: 12px;
      background-color: #f2f7ff;
      width: 100%;
      display: inline-flex;
      .ant-form-item-control-input-content {
        height: 100%;
      }
      .ant-picker {
        border: 0;
        outline: none;
        box-shadow: none;
        background-color: transparent;
        width: 100%;
        border-radius: 0 12px 12px 0;
        height: 100%;
      }
      .ant-select {
        height: 48px;
      }
      .ant-form-item-row,
      .ant-form-item-control-input {
        height: 100%;
      }
      .ant-select-selector {
        border: 0;
        outline: none;
        box-shadow: none;
        background-color: transparent;
        height: 48px;
        border-radius: 12px 0 0 12px;
        border-right: solid 1px #dadeec;
      }
      .ant-select-disabled {
        .ant-select-selector {
          border-color: #001377 !important;
        }
      }

      .ant-select-focused .ant-select-selector,
      .ant-select-selector:hover,
      .ant-select-selector:focus,
      .ant-select-selector:active,
      .ant-select-open .ant-select-selector {
        border: 0;
        border-right: solid 1px #dadeec !important;
        box-shadow: none !important;
        outline: none !important;
        background-color: transparent !important;
      }
      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px white inset !important;
      }
      .ant-select-arrow {
        top: 20px !important;
      }
    }
  }
  .footer {
    border-top: 1px solid var(--shadow, #dadeec);
    padding: 32px 0px 16px 0px;
  }
  /* .utc-container {
    height: 48px;
    border-radius: 12px;
    background-color: #f2f7ff;
    width: 100%;
    display: inline-flex;
    .ant-picker {
      border: 0;
      outline: none;
      box-shadow: none;
      background-color: transparent;
      width: 70%;
    }
    .ant-select {
      width: 30%;
      height: 48px;
    }
    .ant-select-selector {
      border: 0;
      outline: none;
      box-shadow: none;
      background-color: transparent;
      height: 48px;
      border-radius: 0;
      border-right: solid 1px #dadeec;
    }

    .ant-select-focused .ant-select-selector,
    .ant-select-selector:hover,
    .ant-select-selector:focus,
    .ant-select-selector:active,
    .ant-select-open .ant-select-selector {
      border: 0;
      border-right: solid 1px #dadeec !important;
      box-shadow: none !important;
      outline: none !important;
      background-color: transparent !important;
    }
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px white inset !important;
    }
    .ant-select-arrow {
      top: 20px !important;
    }
  } */
`;

export const PortTableContainer = styled.div`
  .ant-table-thead tr {
    th {
      color: #1b2559 !important;
      font-family: DM Sans;
      font-size: 12px;
      font-style: normal;
      line-height: normal;
      font-weight: bold !important;
    }
  }
  .ant-table-cell {
    padding: 14px 12px !important;
  }
  .ant-table-tbody td {
    color: var(--text-3, #5d6881);
    font-family: DM Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .ant-table-column-sorters {
    display: flex;
    gap: 16px;
    width: 60px !important;
  }
  .ant-table-column-sorters::after {
    width: 50px !important;
  }
`;
