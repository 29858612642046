import { FC } from "react";
import { StylesContent } from "./styles";
import { Tabs } from "antd";
import { TabsProps } from "antd/lib";
import { NewFlowTabs } from "../../model/newFlowTabs";
import { useAddNewFlowCTX } from "../../context";
import { FlowInfo } from "../FlowInfo";
import { useFlowCTX } from "src/modules/Flow/context";
import { ActiveContent } from "../../model/activeContent";
import { FlowSteps } from "../Steps";
import { FlowAssignRole } from "../AssignRole";
import { FlowAction } from "../FlowAction";

export const AddNewFlow: FC = () => {
  const {
    value: { activeTab },
    dispatch: { setActiveTab },
  } = useAddNewFlowCTX();
  const {
    value: { mode },
    func: { OnChangeState },
  } = useFlowCTX();
  const items: TabsProps["items"] = [
    {
      key: NewFlowTabs.FlowInfo,
      label: "Flow info",
      children: <FlowInfo />,
      disabled: mode === "create" && activeTab !== NewFlowTabs.FlowInfo,
    },
    {
      key: NewFlowTabs.Steps,
      label: "Steps",
      children: <FlowSteps />,
      disabled: mode === "create" && activeTab !== NewFlowTabs.Steps,
    },
    {
      key: NewFlowTabs.AssignRole,
      label: "Assign role",
      children: <FlowAssignRole />,
      disabled: mode === "create" && activeTab !== NewFlowTabs.AssignRole,
    },
    {
      key: NewFlowTabs.FlowAction,
      label: "Flow action",
      children: <FlowAction />,
      disabled: mode === "create" && activeTab !== NewFlowTabs.FlowAction,
    },
  ];

  const onChange = (key: string) => {
    setActiveTab(key);
    switch (key) {
      case NewFlowTabs.FlowInfo:
        OnChangeState(ActiveContent.Center);
        break;
      case NewFlowTabs.Steps:
        OnChangeState(ActiveContent.CenterAndRight);
        break;
      default:
        OnChangeState(ActiveContent.CenterAndLeft);
    }
  };
  return (
    <StylesContent>
      <Tabs items={items} onChange={onChange} activeKey={activeTab} centered />
    </StylesContent>
  );
};
