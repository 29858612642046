import { FC, useEffect, useState } from "react";
import { SupplierEditContainer } from "./style";
import { App, Button, Col, Divider, Form, Row, Select, Space } from "antd";
import { FormItem } from "src/components/UiKit/FormItem";
import { SInput } from "src/components/UiKit/Input";
import TextArea from "antd/es/input/TextArea";
import { ROUTES } from "src/models/enums";
import { useLocation, useNavigate } from "react-router-dom";
import { useSupplier } from "../../context";
import { INewSupplier } from "src/services/System/models";
import { SystemService } from "src/services/System/System.service";
import { AddCountryModal } from "src/components/UiKit/AddCountryModal/AddCountryModal";
import { Guard } from "src/components/Guard";
import { EditSupplierPath } from "src/services/System/guardPath";

export const SupplierEdit: FC = () => {
  const {
    value: { countryList },
    func: { fetchCountry },
  } = useSupplier()!;
  const location = useLocation();
  const navigate = useNavigate();
  const { message } = App.useApp();
  const onCancel = () => {
    navigate(ROUTES.home);
  };
  const [isAddCountryModalOpen, setIsAddCountryModalOpen] =
    useState<boolean>(false);
  const [formController] = Form.useForm();
  const [editLoading, setEditLoading] = useState<boolean>(false);
  const onFinish = async (values: INewSupplier) => {
    try {
      setEditLoading(true);
      const { EditSupplier } = new SystemService();
      const response = await EditSupplier(
        formController.getFieldValue("id"),
        values
      );
      if (response && response.status === 200) {
        message.success("Edit supplier successfully");
        formController.resetFields();
        navigate(ROUTES.Supplier);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setEditLoading(false);
    }
  };

  const onBack = () => {
    navigate(ROUTES.Supplier.concat("?table=true"));
  };
  useEffect(() => {
    fetchCountry();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    formController.setFieldsValue(location.state);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state]);
  return (
    <SupplierEditContainer>
      <Row>
        <Col span={24}>
          <div
            onClick={onBack}
            className="flex justify-between items-center py-[19px] px-[24px] borderBottom"
          >
            <div className="flex flex-row gap-[12px] items-center cursor-pointer">
              <span className="material-icons text-[#2C73FC] text-[32px]">
                keyboard_backspace
              </span>
              <div className=" text-[#2C73FC] text-[24px] ">
                Back to suppliers table
              </div>
            </div>
            <div className="flex flex-col">
              <div className="text-[#001377] text-[18px]">edit supplier</div>
              <div className="text-[#5D6881] text-[14px]">
                {formController.getFieldValue("name")}
              </div>
            </div>
          </div>
        </Col>
        <Form onFinish={onFinish} form={formController}>
          <Col span={24} className="body">
            <Row gutter={24}>
              <Col span={24} className="pt-[16px]">
                <p className="text-[#001377] text-[18px] mb-[0px]">
                  Please edit Supplier information.
                </p>
              </Col>
              <Col span={8} className="pt-[40px]">
                <FormItem
                  name="name"
                  label="Supplier name"
                  rules={[{ required: true }]}
                  required={true}
                  className="w-full"
                >
                  <SInput placeholder="insert" />
                </FormItem>
              </Col>
              <Col span={8} className="pt-[40px]">
                <FormItem name="webSite" label="Website" className="w-full">
                  <SInput placeholder="e.g. website.com" />
                </FormItem>
              </Col>
              <Col span={8} className="pt-[40px]">
                <FormItem
                  name="phoneNumber"
                  label="Phone number"
                  rules={[{ required: true }]}
                  required={false}
                  className="w-full"
                >
                  <SInput placeholder="e.g. +971-4-2965595" />
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem
                  name="email"
                  label="Email"
                  rules={[{ required: true }, { type: "email" }]}
                  required={false}
                  className="w-full"
                >
                  <SInput placeholder="e.g. website.com" />
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem
                  name="countryId"
                  label="Country"
                  rules={[{ required: true }]}
                  required={false}
                  className="w-full"
                >
                  <Select
                    style={{ width: "100%" }}
                    placeholder="select"
                    optionLabelProp="label"
                    showSearch
                    className="selectOption"
                    optionFilterProp="label"
                    options={countryList?.map((country) => ({
                      label: country.name,
                      value: country.id,
                    }))}
                    dropdownRender={(menu) => (
                      <>
                        <Space style={{ padding: "16px 8px 4px" }}>
                          <div
                            onClick={() => setIsAddCountryModalOpen(true)}
                            className="cursor-pointer flex justify-center items-center"
                          >
                            <span className="material-icons text-[16px] text-[#001377]">
                              add
                            </span>
                            <div className="addHolder"> Add Country</div>
                          </div>
                        </Space>
                        <Divider style={{ margin: "8px 0" }} />
                        {menu}
                      </>
                    )}
                  />
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem
                  name="city"
                  label="City"
                  rules={[{ required: true }]}
                  required={false}
                  className="w-full"
                >
                  <SInput placeholder="Insert" />
                </FormItem>
              </Col>
              <Col span={24}>
                <FormItem
                  name="address"
                  label="Address"
                  rules={[{ required: true }]}
                  required={false}
                  className="w-full"
                >
                  <SInput placeholder="Insert" />
                </FormItem>
              </Col>
              <Col span={24}>
                <FormItem name="comment" label="Description">
                  <TextArea
                    placeholder="Description"
                    rows={4}
                    style={{
                      height: 334,
                      resize: "none",
                      borderRadius: 12,
                      background: "#F4F7FE",
                    }}
                  />
                </FormItem>
              </Col>
              <Col span={24}>
                <div className=" flex gap-[16px] footer">
                  <Guard action={EditSupplierPath}>
                    <Button
                      type="primary"
                      className="w-[100px]"
                      htmlType="submit"
                      loading={editLoading}
                    >
                      Save edit
                    </Button>
                  </Guard>

                  <Button onClick={onCancel} className="w-[100px]">
                    Cancel
                  </Button>
                </div>
              </Col>
            </Row>
          </Col>
        </Form>
      </Row>
      <AddCountryModal
        getCountry={fetchCountry}
        isAddCountryModalOpen={isAddCountryModalOpen}
        setIsAddCountryModalOpen={setIsAddCountryModalOpen}
      />
    </SupplierEditContainer>
  );
};
