import styled from "styled-components";

export const ShipDailyReportFilterStyle = styled.div`
  height: 64px;
  background-color: #fff;
  padding: 12px 16px;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;

  .date {
    width: 40px;
    height: 40px;
    border-top: 10px;
    padding: 12px;
    background-color: #f2f7ff;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
  }

  .filter-btn {
    display: inline-flex;
    justify-content: space-around;
    align-items: center;
    .ant-btn {
      font-weight: 400;
      font-size: 14px;
      border-radius: 10px;
    }
  }
  .center-btn {
    margin: 0 5px;
  }
  .inactive-filter-btn {
    background-color: #f2f7ff;
    color: #000000;
  }
  .active-filter-btn {
    background-color: #2c73fc !important;
    color: #fff !important;
  }

  .ship-reports-filter-divider {
    height: 1px;
    width: 145px;
    background-color: #a3aed0;
    margin-left: 10px;
  }
`;
