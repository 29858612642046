import { Tabs } from "antd";
import { FC } from "react";
import { useAddShip } from "src/modules/AddShips/context";
import { IAddNewShip } from "../../models";
import { TabsProps } from "antd/lib";
import { AddNewShipContainer } from "../../styles";
import { HeaderEditShip } from "./components/header";
import { EditBasicInformation } from "./components/BasicInformation";
import { EditInsurances } from "./components/Insurances";
import { EditOwner } from "./components/Owner";
import { EditTechnicalInformation } from "./components/TechnicalInformation";
import { EditShipPrice } from "./components/ShipPrice";
import { EditShipsPicture } from "./components/ShipsPicture";
import { EditShipManagement } from "./components/ShipManagement";

export const EditShip: FC = () => {
  const {
    value: { activeKeyEditShip },
    dispatch: { setActiveKeyEditShip },
  } = useAddShip()!;

  const items: TabsProps["items"] = [
    {
      key: IAddNewShip.basicInformation,
      label: "Basic Information",
      children: <EditBasicInformation />,
      //   disabled: activeKeyAddShip !== IAddNewShip.basicInformation,
    },
    {
      key: IAddNewShip.shipsPicture,
      label: "Ships picture",
      children: <EditShipsPicture />,
      //   disabled: activeKeyAddShip !== IAddNewShip.basicInformation,
    },
    {
      key: IAddNewShip.technicalInformation,
      label: "Technical information",
      children: <EditTechnicalInformation />,
      //   disabled: activeKeyAddShip !== IAddNewShip.technicalInformation,
    },
    {
      key: IAddNewShip.Owner,
      label: "Owner",
      children: <EditOwner />,
      //   disabled: activeKeyAddShip !== IAddNewShip.Owner,
    },
    {
      key: IAddNewShip.ShipManagement,
      label: "Ship Management contract",
      children: <EditShipManagement />,
      //   disabled: activeKeyAddShip !== IAddNewShip.Owner,
    },
    {
      key: IAddNewShip.shipPrice,
      label: "Ship price",
      children: <EditShipPrice />,
      //   disabled: activeKeyAddShip !== IAddNewShip.Owner,
    },
    {
      key: IAddNewShip.insurances,
      label: "insurances",
      children: <EditInsurances />,
      //   disabled: activeKeyAddShip !== IAddNewShip.insurances,
    },
  ];

  const onChange = (key: string) => {
    setActiveKeyEditShip(key);
  };
  return (
    <>
      <HeaderEditShip />
      <AddNewShipContainer>
        <Tabs
          items={items}
          onChange={onChange}
          activeKey={activeKeyEditShip}
          animated
        />
      </AddNewShipContainer>
    </>
  );
};
