import { FC } from "react";
import { Modal, Button, Row } from "antd";
import { ConfirmModalBodyContainer } from "./style";
import { usePorts } from "src/modules/ports/context";

interface IDeleteConfirmModalProps {
  onOk: () => void;
}

const DeleteConfirmModal: FC<IDeleteConfirmModalProps> = ({ onOk }) => {
  const {
    value: { showConfirm, loadings },
    dispatch: { setShowConfirm },
  } = usePorts();

  return (
    <Modal
      title="Delete"
      open={showConfirm}
      onOk={onOk}
      onCancel={() => setShowConfirm(false)}
      footer={false}
    >
      <ConfirmModalBodyContainer>
        <p className="description">
          Are you sure you want to delete this port?
        </p>

        <Row gutter={[12, 12]} justify={"end"}>
          <Button
            type="text"
            onClick={() => setShowConfirm(false)}
            className="btn-cancel"
          >
            No
          </Button>
          <Button
            loading={loadings.delete}
            type="text"
            onClick={onOk}
            className="btn-ok"
          >
            Yes
          </Button>
        </Row>
      </ConfirmModalBodyContainer>
    </Modal>
  );
};

export default DeleteConfirmModal;
