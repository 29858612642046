import { FC } from "react";
import { OwnerFuturePlan } from "src/modules/Owner";
import { OwnerContentStyle } from "./content.style";
import { OwnerHeader } from "src/modules/Owner/components/OwnerHeader";
import { SelectShip } from "src/modules/Owner/components/SelectShip";

export const FuturePlanPage: FC = () => {
  return (
    <>
      <OwnerHeader />
      <OwnerContentStyle>
        <SelectShip />

        <OwnerFuturePlan />
      </OwnerContentStyle>
    </>
  );
};
