import _ from "lodash";
import { FC, PropsWithChildren, useEffect, useState } from "react";
import { useCheckPermission } from "src/hook/checkRoutePermission";
import { IPath } from "src/services/Identity/models";

interface IProps extends PropsWithChildren {
  action: IPath | IPath[] | undefined;
  multiPath?: boolean;
}

export const Guard: FC<IProps> = ({ action, children, multiPath }) => {
  const [hasPermission, setHasPermission] = useState<boolean>(false);
  const { checkActionPermission, checkRoutePermission } = useCheckPermission();
  useEffect(() => {
    if (multiPath || _.isArray(action)) {
      setHasPermission(checkRoutePermission(action as IPath[]));
    } else {
      if (action) setHasPermission(checkActionPermission(action));
      else setHasPermission(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action]);
  return hasPermission ? <> {children} </> : <></>;
};
