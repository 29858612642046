import styled from "styled-components";

export const UploadedFileHolder = styled.div`
  border-radius: 8px;
  border: 1px solid var(--functional-color-success, #25e3bf);
  display: flex;
  padding: 4px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  cursor: pointer;
  .wrapper {
    border-radius: 6px;
    background: var(--3, #f4f7fe);
    display: flex;
    width: 58px;
    height: 58px;
    padding: 4px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
`;
