import { FC } from "react";
import { Tabs } from "antd";
import type { TabsProps } from "antd";
import { TabsContainer } from "src/modules/Category/styles";
import { AddNewSupplier } from "./addNewSupplier";
import { SupplierTable } from "./supplierTable";
import { ISupplierTabs } from "../models";
import { useSearchParams } from "react-router-dom";
import { Guard } from "src/components/Guard";
import {
  GetSupplierPath,
  NewSupplierPath,
} from "src/services/System/guardPath";
import { useCheckPermission } from "src/hook/checkRoutePermission";

const items: TabsProps["items"] = [
  {
    key: ISupplierTabs.addNewSupplier,
    label: (
      <Guard action={NewSupplierPath}>
        <span>
          <span className="material-icons text-[10px] p-[8px]">lens</span>
          Add new supplier
        </span>
      </Guard>
    ),
    children: (
      <Guard action={NewSupplierPath}>
        <AddNewSupplier />
      </Guard>
    ),
  },
  {
    key: ISupplierTabs.supplierTable,
    label: (
      <Guard action={GetSupplierPath}>
        <span>
          <span className="material-icons text-[10px] p-[10px]">lens</span>
          Supplier table
        </span>
      </Guard>
    ),
    children: (
      <Guard action={GetSupplierPath}>
        <SupplierTable />
      </Guard>
    ),
  },
];

export const AddNewSupplierTabs: FC = () => {
  const [params] = useSearchParams();
  const { checkActionPermission } = useCheckPermission();
  const defaultKey =
    params.get("table") === "true"
      ? ISupplierTabs.supplierTable
      : checkActionPermission(NewSupplierPath)
      ? ISupplierTabs.addNewSupplier
      : ISupplierTabs.supplierTable;
  return (
    <TabsContainer>
      <Tabs
        defaultActiveKey={defaultKey}
        items={items}
        // onChange={onChange}
      />
    </TabsContainer>
  );
};
