import { Row } from "antd";
import { styled } from "styled-components";

export const ShowShipContainer = styled.div`
  /* height: 766px; */
  border-radius: 20px;
  background: var(--0, #fff);
  box-shadow: 0px 4px 6px 0px rgba(113, 130, 183, 0.25);
  padding: 24px 32px;
  display: flex;
  flex-direction: column;
  display: flex;
  gap: 24px;
  .ant-tabs-nav {
    height: 44px;
    padding-bottom: unset !important;
    margin-bottom: unset !important;
  }
  .footer {
    border-top: 1px solid var(--shadow, #dadeec);
    padding-top: 24px;
    /* justify-content: flex-end; */
  }
  .ant-tabs-tab {
    color: var(--text-2, #a3aed0);
    font-family: DM Sans;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: unset;
  }
  .ant-tabs-ink-bar {
    height: 2px !important;
  }
`;
export const ShowBasicInformationContainer = styled(Row)`
  display: flex;
  /* gap: 24px; */
  .pictureHolder {
    display: flex;
    padding: 24px 0px;
    flex-direction: row;
    align-items: center;
    gap: 23px;
    height: 213px;
    margin-top: 24px;
    overflow-x: auto;
  }
  .titleHolder {
    margin-top: 24px;
    color: var(--1, #000);
    font-family: DM Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: flex;
    padding: 0px 0px 16px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    /* gap: 130px; */
  }
  .dataHolder {
    padding: 32px;
    border-radius: 16px;
    border: 1px solid var(--shadow, #dadeec);
  }
  .ant-row {
    margin: unset;
  }
  .spanHolder {
    display: flex;
    padding: 12px 16px;
    align-items: flex-start;
    gap: 18px;
    border-radius: 8px;
    background: var(--Background-1_VDR, #f4f7fe);
    color: var(--text, #001377);
    cursor: pointer;
    user-select: none;
  }
  .spanActiveHolder {
    display: flex;
    padding: 12px 16px;
    align-items: flex-start;
    gap: 18px;
    border-radius: 8px;
    background: var(--Blue_VDR, #2c73fc);
    color: var(--0, #fff);
    cursor: pointer;
    user-select: none;
  }
  .fileHolder {
    display: flex;
    padding: 24px;
    flex-direction: row;
    align-items: flex-start;
    gap: 16px;
    border-radius: 8px;
    border: 1px dashed var(--text-2, #a3aed0);
    height: 114px;
  }
  .ant-input {
    padding: 16px 24px;
  }
`;
