import { ColumnsType } from "antd/es/table";
import { FC, useState } from "react";
import { TableUI } from "src/components/UiKit/table";
import { useFrontAccess } from "src/modules/frontAccess/context";
import { IRolesOfFrontPath } from "src/services/System/models";
import { DeleteConfirmModal } from "../../DeleteConfirmModal";
import { uniqueId } from "lodash";

export const AssignRoleTable: FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [accessId, setAccessId] = useState<number | undefined>(undefined);

  const {
    value: { rolesPath, tableLoading },
    func: { deleteFrontPathAccess },
  } = useFrontAccess();

  const deleteAccess = async () => {
    setLoading(true);
    if (accessId)
      deleteFrontPathAccess(accessId).finally(() => {
        setAccessId(undefined);
        setLoading(false);
      });
  };

  const onCancelConfirm = () => {
    setAccessId(undefined);
  };

  const columns: ColumnsType<IRolesOfFrontPath> = [
    {
      title: " #",
      dataIndex: "#",
      key: "index",
      align: "center",
      render: (name: string, record: any, index: number) => index + 1,
    },
    {
      title: "Role",
      dataIndex: "roleName",
      key: "roleName",

      align: "center",
    },
    {
      title: "Tools",
      dataIndex: "tools",
      key: "tools",
      align: "center",
      render: (_, record) => (
        <span
          className="material-icons text-[#FF1414] text-[16px] cursor-pointer"
          onClick={() => setAccessId(record.id)}
        >
          delete_forever
        </span>
      ),
    },
  ];

  return (
    <div className="mt-6">
      <p className="font-medium text-[14px] text-[#001377]">
        Roles that assign to this name.
      </p>

      <TableUI
        columns={columns}
        tableType="primary"
        dataSource={rolesPath}
        rowClassName={(record, index) =>
          index % 2 !== 0 ? "bg-[#F2F7FF]" : ""
        }
        scroll={{ x: 500 }}
        loading={tableLoading}
        rowKey={() => uniqueId()}
      />

      <DeleteConfirmModal
        open={accessId ? true : false}
        onCancel={onCancelConfirm}
        onOk={deleteAccess}
        confirmLoading={loading}
        text={"Are you sure to remove access?"}
        title={"Remove access"}
      />
    </div>
  );
};
