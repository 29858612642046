import { InvoiceReportUrls } from "./urls";
import {
  IInvoiceReportsArgs,
  IReportAllRequest,
  IReportAllRequestAttachment,
  IReportInvoice,
  IReportInvoiceArticle,
} from "./models";
import axios from "axios";
import { IDataModel } from "../models";
import { checkActionPermission } from "src/helpers/checkPermissionFunc";
import { permissionLog } from "src/helpers/permissionLog";
import * as GuardPaths from "./guardPath";
export class InvoiceReportService {
  async ReportAllRequest(data: Partial<IInvoiceReportsArgs>) {
    if (!checkActionPermission(GuardPaths.ReportAllRequestPath)) {
      permissionLog(GuardPaths.ReportAllRequestPath.path);
      return undefined;
    }
    try {
      const response = await axios.post<IDataModel<IReportAllRequest[]>>(
        InvoiceReportUrls.ReportAllRequest,
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async ReportAllRequestAttachment(data: Partial<IInvoiceReportsArgs>) {
    if (!checkActionPermission(GuardPaths.ReportAllRequestAttachmentPath)) {
      permissionLog(GuardPaths.ReportAllRequestAttachmentPath.path);
      return undefined;
    }
    try {
      const response = await axios.post<
        IDataModel<IReportAllRequestAttachment[]>
      >(InvoiceReportUrls.ReportAllRequestAttachment, data);
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async ReportInvoice(data: Partial<IInvoiceReportsArgs>) {
    if (!checkActionPermission(GuardPaths.ReportInvoicePath)) {
      permissionLog(GuardPaths.ReportInvoicePath.path);
      return undefined;
    }
    try {
      const response = await axios.post<IDataModel<IReportInvoice[]>>(
        InvoiceReportUrls.ReportInvoice,
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async ReportInvoiceArticle(data: Partial<IInvoiceReportsArgs>) {
    if (!checkActionPermission(GuardPaths.ReportInvoiceArticlePath)) {
      permissionLog(GuardPaths.ReportInvoiceArticlePath.path);
      return undefined;
    }
    try {
      const response = await axios.post<IDataModel<IReportInvoiceArticle[]>>(
        InvoiceReportUrls.ReportInvoiceArticle,
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
}
