import {
  AllAgentContactInfoPath,
  AllAgentsPath,
  AllPortsPath,
  DeleteAgentContactInfoPath,
  DeleteAgentPath,
  DeletePortPath,
  NewAgentContactInfoPath,
  NewAgentPath,
  NewPortPath,
  UpdateAgentPath,
  UpdatePortPath,
} from "src/services/BaseInfo/guardPath";
import { IPath } from "src/services/Identity/models";
import {
  AllPayersPath,
  AllReceiversPath,
  DeletePayerPath,
  DeleteReceiverPath,
  NewPayerPath,
  NewReceiverPath,
  UpdatePayerPath,
  UpdateReceiverPath,
} from "src/services/Income/guardPath";
import { AllRequestPath } from "src/services/Invoice/guardPath";
import { AllVoyagePath, NewVoyagePath } from "src/services/Operation/guardPath";
import { AllCurrencyPath, RequesterPath } from "src/services/Public/guardPath";
import {
  DeleteRequesterPath,
  DeleteShipInsuranceInformationPath,
  DeleteShipManagmentContractPath,
  DeletetShipManagementPath,
  EditRequesterPath,
  EditShipBasicInformationPath,
  EditShipInsuranceInformationPath,
  EditShipManagementPath,
  EditShipManagmentContractPath,
  GetShipManagementPath,
  NewOwnerCompanyPath,
  NewOwnerShipPath,
  NewShipManagementPath,
  PostShipInsuranceInformationPath,
  PostShipManagmentContractPath,
  ShipBasicInformationPath,
  ShipPriceInformationPath,
  ShipTechnicalInformationPath,
  UploadShipImagePath,
} from "src/services/Ship/guardPath";
import {
  AddRolToFrontPathGuardPath,
  DeleteCategoryPath,
  DeleteFrontPathGuardPath,
  DeletetSupplierPath,
  EditCategoryPath,
  EditCountryPath,
  EditCurrencyPath,
  EditFrontPathGuardPath,
  EditSupplierPath,
  GetCountryPath,
  GetFrontPathGuardPath,
  GetRoleOfFrontPathGuardPath,
  GetSupplierPath,
  NewCategoryPath,
  NewCountryPath,
  NewCurrencyPath,
  NewFrontPathGuardPath,
  NewSupplierPath,
  TreeCategoryPath,
} from "src/services/System/guardPath";
import {
  AddRoleUserPath,
  NewWorkFlowPath,
  NewWorkFlowStepPath,
} from "src/services/WorkFlow/guardPath";
import {
  AllProfit,
  DeleteProfit,
  EditProfit,
  GetProfit,
  GetProfitFile,
  GetProfitShippingFile,
  GetProfitTradeFile,
  NewProfit,
  NewProfitFile,
  NewProfitShipping,
  NewProfitShippingFile,
  NewProfitTrade,
  NewProfitTradeFile,
} from "src/services/profit/guardPath";

export const categoryApiPath: IPath[] = [
  TreeCategoryPath,
  NewCategoryPath,
  DeleteCategoryPath,
  EditCategoryPath,
];
export const tableApiPath: IPath[] = [AllRequestPath];
export const AddShipsPath: IPath[] = [
  ShipBasicInformationPath,
  UploadShipImagePath,
  ShipTechnicalInformationPath,
  NewOwnerShipPath,
  ShipPriceInformationPath,
  PostShipManagmentContractPath,
  PostShipInsuranceInformationPath,
  NewOwnerCompanyPath,
  NewOwnerShipPath,
];
export const EditShipsPath: IPath[] = [
  NewOwnerCompanyPath,
  NewOwnerShipPath,
  ShipBasicInformationPath,
  UploadShipImagePath,
  ShipTechnicalInformationPath,
  NewOwnerShipPath,
  ShipPriceInformationPath,
  PostShipManagmentContractPath,
  PostShipInsuranceInformationPath,
  EditShipBasicInformationPath,
  EditShipInsuranceInformationPath,
  DeleteShipInsuranceInformationPath,
  EditShipManagmentContractPath,
  DeleteShipManagmentContractPath,
];

export const ShipManagementPath = [
  NewShipManagementPath,
  EditShipManagementPath,
  DeletetShipManagementPath,
  GetShipManagementPath,
];
export const SupplierPath: IPath[] = [
  NewSupplierPath,
  GetSupplierPath,
  EditSupplierPath,
  DeletetSupplierPath,
];
export const RequesterGuardPath: IPath[] = [
  RequesterPath,
  EditRequesterPath,
  DeleteRequesterPath,
];

export const AddWorkFlowPath: IPath[] = [
  NewWorkFlowPath,
  NewWorkFlowStepPath,
  AddRoleUserPath,
];
export const FrontAccessPath: IPath[] = [
  NewFrontPathGuardPath,
  GetFrontPathGuardPath,
  EditFrontPathGuardPath,
  DeleteFrontPathGuardPath,
  AddRolToFrontPathGuardPath,
  GetRoleOfFrontPathGuardPath,
];
export const PortPagePath: IPath[] = [
  NewPortPath,
  AllPortsPath,
  DeletePortPath,
  UpdatePortPath,
];
export const AgentPath: IPath[] = [
  NewAgentPath,
  AllAgentsPath,
  DeleteAgentPath,
  UpdateAgentPath,
  NewAgentContactInfoPath,
  AllAgentContactInfoPath,
  DeleteAgentContactInfoPath,
];

export const ProfitPath: IPath[] = [
  NewProfit,
  EditProfit,
  NewProfitTrade,
  NewProfitShipping,
  AllProfit,
  DeleteProfit,
  GetProfit,
  NewProfitFile,
  GetProfitFile,
  NewProfitTradeFile,
  GetProfitTradeFile,
  NewProfitShippingFile,
  GetProfitShippingFile,
];
export const CommercialPath: IPath[] = [AllVoyagePath, NewVoyagePath];

export const CountryApiPath: IPath[] = [
  EditCountryPath,
  GetCountryPath,
  NewCountryPath,
];

export const CurrencyApiPath: IPath[] = [
  NewCurrencyPath,
  EditCurrencyPath,
  AllCurrencyPath,
];

export const PayerApiPath: IPath[] = [
  NewPayerPath,
  AllPayersPath,
  DeletePayerPath,
  UpdatePayerPath,
];

export const ReceiverApiPath: IPath[] = [
  NewReceiverPath,
  AllReceiversPath,
  DeleteReceiverPath,
  UpdateReceiverPath,
];
