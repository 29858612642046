import { styled } from "styled-components";

export const ShipManagementContainer = styled.div`
  .ant-tabs-tab {
    color: var(--text-2, #a3aed0);
    font-family: DM Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 160% */
    padding: unset;
  }
  .ant-tabs-nav::before {
    border-bottom: 1px solid var(--shadow, #dadeec);
    padding: 24px;
  }
  .ant-tabs-ink-bar {
    height: unset !important;
  }
  .ant-tabs-nav {
    padding-bottom: 24px;
    margin-bottom: 24px !important;
  }
`;
export const AddNewShipManagementContainer = styled.div`
  display: flex;
  height: 766px;
  padding: 24px;
  flex-direction: column;
  justify-content: space-between;
  gap: 24px;
  border-radius: 20px;
  background: var(--Secondary-Primary-White, #fff);
  box-shadow: 0px 4px 6px 0px rgba(113, 130, 183, 0.25);
  .titleHolder {
    color: var(--text, #001377);
    font-family: DM Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .ant-form-item {
    margin-bottom: unset !important;
  }
  .ant-picker-range {
    background: #f4f7fe !important;
    border-color: #f4f7fe !important;
    border-radius: 12px !important;
    min-height: 48px !important;
  }

  &.ant-picker-disabled {
    background: #dadeec !important;
    border-color: #dadeec !important;
  }
  .footer {
    border-top: 1px solid var(--shadow, #dadeec);
    padding: 32px 0px 16px 0px;
  }
`;

export const ShipManagementTableContainer = styled.div`
  .ant-table-thead tr {
    th {
      color: #1b2559 !important;
      font-family: DM Sans;
      font-size: 12px;
      font-style: normal;
      line-height: normal;
      font-weight: bold !important;
    }
  }
  .ant-table-cell {
    padding: 14px 12px !important;
  }
  .ant-table-tbody td {
    color: var(--text-3, #5d6881);
    font-family: DM Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .ant-table-column-sorters {
    display: flex;
    gap: 16px;
    width: 60px !important;
  }
  .ant-table-column-sorters::after {
    width: 50px !important;
  }
`;
export const ShowShipManagementContainer = styled.div`
  .topBarHolder {
    border-bottom: 1px solid #f0f0f0;
    padding-bottom: 16px;
  }
  .backgroundHolder {
    display: flex;
    padding: 24px;
    flex-direction: column;
    gap: 24px;
    flex-shrink: 0;
    border-radius: 20px;
    background: var(--Secondary-Primary-White, #fff);
    box-shadow: 0px 4px 6px 0px rgba(113, 130, 183, 0.25);
  }
  .dataHolder {
    display: block;
    padding: 24px;
    border-radius: 16px;
    border: 1px solid var(--shadow, #dadeec);
  }
`;
