import { FC, useState } from "react";
import { DescriptionContainer } from "./styles";
import { Button, Col } from "antd";
import { useAddInvoices } from "../../context";
import * as XLSX from "xlsx";

import { IExcelData } from "../../models";
import Upload, {
  RcFile,
  UploadChangeParam,
  UploadFile,
  UploadProps,
} from "antd/es/upload";
import { App } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { dummyRequest } from "src/helpers/dummyRequest";
import { PublicService } from "src/services/Public/Public.service";
import { Guard } from "src/components/Guard";
import { CategoryPath } from "src/services/Public/guardPath";

export const Description: FC = () => {
  const {
    value: { excelFile, fileName },
    dispatch: { setExcelData, setFileIsUploaded, setExcelFile, setFileName },
  } = useAddInvoices()!;
  const [getCategoriesLoading, setGetCategoriesLoading] =
    useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const { message } = App.useApp();
  const beforeUpload = (file: RcFile) => {
    const isExcelFile =
      file.type ===
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    if (!isExcelFile) {
      message.error("You can only upload EXCEL file!");
    }
    return isExcelFile;
  };
  const handleChange: UploadProps["onChange"] = (
    info: UploadChangeParam<UploadFile>
  ) => {
    setExcelFile([info.file]);

    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      setFileName(info.file.originFileObj?.name || "");
      readFile(info.file.originFileObj!);
    }
  };
  const readFile = (f: RcFile) => {
    const reader = new FileReader();
    reader.onload = (evt: any) => {
      // evt = on_file_select event
      /* Parse data */
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_csv(ws);
      /* Update state */
      const convertedData: IExcelData[] = convertToJson(data);
      getCategoriesAndInsertToData(convertedData); // shows data in json format
    };
    reader.readAsBinaryString(f);
  };

  const convertToJson = (csv: any) => {
    var lines = csv.split("\n");

    var result = [];

    var headers = lines[0].split(",");

    for (var i = 1; i < lines.length; i++) {
      var obj: any = {};
      var currentline = lines[i].split(",");

      for (var j = 0; j < headers.length; j++) {
        obj[headers[j] as any] = currentline[j];
      }
      obj.key = i;
      result.push(obj);
    }

    const newResult: any = [];
    result.forEach((item) => {
      item["Amount"] = +(+item["Quantity"] * +item["Unit Price"]).toFixed(4);
      newResult.push(item);
    });

    return newResult.filter((item: IExcelData) => !!item["#"]);
  };
  const getCategoriesAndInsertToData = async (data: IExcelData[]) => {
    try {
      setGetCategoriesLoading(true);
      const costCodes: string[] = data.map((item) => item["Cost code"]);
      const { Category } = new PublicService();

      const res = await Category(costCodes);
      if (res && res.data) {
        const categories = res.data;
        const newData: IExcelData[] = data.map((item, index) => {
          item["Category"] = categories[index]?.fullName || "";
          // item["Sub category"] = categories[index]?.subCategory || "";
          item.costCodeId = categories[index]?.id;
          // item.currencyId = currencyId || 0;
          return item;
        });
        setExcelData(newData);
        return true;
      }
      return false;
    } catch (err) {
      console.log(err);
      return false;
    } finally {
      setLoading(false);
      setGetCategoriesLoading(false);
    }
  };
  const uploadButton = (
    <div>
      {loading ? (
        <LoadingOutlined color="#A3AED0" />
      ) : (
        <span className="material-icons text-[#A3AED0] ">attach_file</span>
      )}
      <div className="text-[#A3AED0]">Attach</div>
    </div>
  );
  return (
    <DescriptionContainer justify={"center"}>
      <Col
        span={12}
        className="flex flex-col items-center gap-[24px]  wrapper "
      >
        <div className="title">Please Attach invoice’s exel file.</div>
        <Upload
          name="excel-file"
          listType="picture-card"
          className=" flex justify-center upload"
          showUploadList={false}
          beforeUpload={beforeUpload}
          onChange={handleChange}
          customRequest={dummyRequest}
          fileList={excelFile}
        >
          {fileName ? (
            <div className="flex flex-col justify-center items-center">
              <img
                src={"/assets/images/excelIcon.png"}
                alt="excel"
                style={{ width: "50%" }}
              />
              <div className="text-[12px]">{fileName}</div>
            </div>
          ) : (
            uploadButton
          )}
        </Upload>
        <div>
          <a href="/assets/sample/Sample.xlsx" download={"Sample.xlsx"}>
            Download sample file
          </a>
        </div>
      </Col>

      <Col span={24} className="flex justify-end gap-[24px] mt-[394px] footer">
        {/* <Button
          onClick={() => {
            setActiveTab(AddInvoiceTabEnums.BaseInformation);
          }}
          htmlType="button"
        >
          Back
        </Button> */}
        <Guard action={CategoryPath}>
          <Button
            type="primary"
            htmlType="submit"
            onClick={() => {
              message.success("file was successfully uploaded.");
              setFileIsUploaded(true);
            }}
            disabled={excelFile?.length === 0}
            loading={getCategoriesLoading}
          >
            Upload
          </Button>
        </Guard>
      </Col>
    </DescriptionContainer>
  );
};
