import { FC, useState } from "react";
import { useEditZipFileContext } from "../../context";
import { LoadingOutlined } from "@ant-design/icons";
import { UploadChangeParam } from "antd/lib/upload";
import { UploadFile, RcFile } from "antd/es/upload";
import { UploadProps } from "antd/lib";
import { Col, Typography, message, Upload } from "antd";
import { UploadEditedZipContainer } from "../../styles/uploadZip.styles";
import { Icons } from "src/components/customIcons";
import { UploadedFileHolder } from "../../styles/zipUploaded.style";
import { uploadFile } from "src/helpers/uploadZipFile";
import { InvoiceUrls } from "src/services/Invoice/urls";
import { Guard } from "src/components/Guard";
import { AttachZipToAllRequestPath } from "src/services/Invoice/guardPath";

export const UploadEditedZip: FC = () => {
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState<number>(0);
  const {
    value: { fileList, currentAllZipFileId },
    dispatch: { setZipFile, setFileList },
    func: { zipFileModalHandler },
  } = useEditZipFileContext();
  const uploadButton = (
    <div>
      {loading ? (
        <>
          <LoadingOutlined color="#A3AED0" />
        </>
      ) : (
        <>
          <span className="material-icons text-[#A3AED0] text-[20px]">
            attach_file
          </span>
        </>
      )}
      <div className="text-[#A3AED0] text-[10px]">Attach</div>
    </div>
  );

  const handleChange: UploadProps["onChange"] = (
    info: UploadChangeParam<UploadFile>
  ) => {
    setFileList([info.file]);
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }

    if (info.file.status === "done") {
      setZipFile(info.file.originFileObj);
      setLoading(false);
    }
    if (info.file.status === "error") {
      setLoading(false);
    }
  };

  const beforeUpload = (file: RcFile) => {
    const isZipFile =
      file.type.includes("zip") ||
      file.name.includes(".zip") ||
      file.name.includes(".rar");
    if (!isZipFile) {
      message.error("You can only upload zip file!");
    }
    return isZipFile;
  };

  const props: UploadProps = {
    showUploadList: {
      showPreviewIcon: false,
      showRemoveIcon: false,
    },
  };

  if (!currentAllZipFileId) {
    return <div>404</div>;
  }

  return (
    <UploadEditedZipContainer>
      <Col span={24}>
        <div className="attachFile">
          <Typography.Text className=" attachFileLabel">
            Attach Zip file.
          </Typography.Text>
          <Guard action={AttachZipToAllRequestPath}>
            <div className="flex items-center gap-[32px] overflow-y-auto">
              <Upload
                {...props}
                name="zipAttachment"
                listType="picture-card"
                className=" flex justify-center "
                showUploadList={true}
                beforeUpload={beforeUpload}
                onChange={handleChange}
                customRequest={(options) =>
                  uploadFile({
                    options,
                    setProgress,
                    callbackUrl: InvoiceUrls.Invoice.concat(
                      `${currentAllZipFileId}`
                    ).concat(InvoiceUrls.AttachZipToAllRequest),
                  })
                }
                maxCount={1}
                fileList={fileList}
                disabled={fileList.length === 1}
                itemRender={(el, file, fileList) => {
                  return (
                    <UploadedFileHolder>
                      <div className="wrapper" onClick={zipFileModalHandler}>
                        <Icons name="zipFile32" />
                        {loading && (
                          <div>
                            <div>{Math.round(progress)}%</div>
                          </div>
                        )}
                      </div>
                    </UploadedFileHolder>
                  );
                }}
              >
                {uploadButton}
              </Upload>
            </div>
          </Guard>
        </div>
      </Col>
    </UploadEditedZipContainer>
  );
};
