import { FC, ReactNode } from "react";
import { ReportCardStyle } from "./style";
import { Col, Flex, Row } from "antd";
interface IItems {
  label: string;
  value: ReactNode;
  color?: string;
}

interface IProps {
  title: string;
  leftItems: IItems[];
  rightItems: IItems[];
  minWidth?: number;
}
export const ReportCard: FC<IProps> = ({
  title,
  leftItems,
  rightItems,
  minWidth,
}) => {
  return (
    <ReportCardStyle $minWidth={minWidth}>
      <Col span={24} className="title">
        {title}
      </Col>
      <Col span={24}>
        <Row gutter={[24, 24]} className="items !mx-0">
          <Col span={12}>
            <Flex gap={24} vertical>
              {/* {
          label: "INCOME INFO",
          value: "INCOME_INFO",
        }, */}
              {leftItems.map((item, index) =>
                item.value === "INCOME_INFO" ||
                item.value === "EXPENSES_INFO" ? (
                  <div
                    style={{
                      color: item.color,
                      fontSize: 16,
                      fontWeight: 500,
                      height: 26,
                    }}
                  >
                    {item.value}
                  </div>
                ) : (
                  <Flex key={index}>
                    <div className="label">{item.label}</div>
                    <div className="value">{item.value}</div>
                  </Flex>
                )
              )}
            </Flex>
          </Col>
          <Col span={12}>
            <Flex gap={24} vertical>
              {rightItems.map((item, index) =>
                item.value === "INCOME_INFO" ||
                item.value === "EXPENSES_INFO" ? (
                  <div className="h-[26px]"></div>
                ) : (
                  <Flex key={index}>
                    <div className="label">{item.label}</div>
                    <div className="value">{item.value}</div>
                  </Flex>
                )
              )}
            </Flex>
          </Col>
        </Row>
      </Col>
    </ReportCardStyle>
  );
};
