import { TreeSelect, TreeSelectProps, message } from "antd";
import { DefaultOptionType } from "antd/es/select";
import {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useSearchParams } from "react-router-dom";
import { IObject } from "src/models/interfaces";
import { ITablePagination } from "src/models/interfaces/pagination";
import { SystemService } from "src/services/System/System.service";
import { IFrontPath, IRolesOfFrontPath } from "src/services/System/models";
import { UserManagementService } from "src/services/UserManagement/UserManagement.service";
import { IRole } from "src/services/UserManagement/models";
const { SHOW_PARENT } = TreeSelect;
interface IContext {
  value: {
    mode: "tab" | "edit" | "assign";
    tableLoading: boolean;
    frontAccessForEdit: IFrontPath | undefined;
    frontPath: IFrontPath[];
    pagination: ITablePagination;
    treeProps: TreeSelectProps;
    rolesPath: IRolesOfFrontPath[];
  };
  dispatch: {
    setMode: Dispatch<SetStateAction<"tab" | "edit" | "assign">>;
    setFrontAccessForEdit: Dispatch<SetStateAction<IFrontPath | undefined>>;
    setPagination: Dispatch<SetStateAction<ITablePagination>>;
    setTableLoading: Dispatch<SetStateAction<boolean>>;
    setFrontPath: Dispatch<SetStateAction<IFrontPath[]>>;
  };
  func: {
    getFrontPath: (values?: IObject) => Promise<void>;
    addRoleToFront: () => Promise<boolean>;
    deleteFrontPathAccess: (id: number) => Promise<void>;
  };
}
export const FrontAccessContext = createContext<IContext | undefined>(
  undefined
);

export const FrontAccessProvider: FC<PropsWithChildren> = ({ children }) => {
  const [mode, setMode] = useState<"tab" | "edit" | "assign">("tab");
  const [frontPath, setFrontPath] = useState<IFrontPath[]>([]);
  const [tableLoading, setTableLoading] = useState<boolean>(false);
  const [roles, setRoles] = useState<IRole[]>([]);
  const [frontAccessForEdit, setFrontAccessForEdit] = useState<IFrontPath>();
  const [getRolesLoading, setRolesLoading] = useState<boolean>(false);
  const [value, setValue] = useState<number>();
  const [rolesPath, setRolesPath] = useState<IRolesOfFrontPath[]>([]);
  let [searchParams] = useSearchParams();
  const [pagination, setPagination] = useState<ITablePagination>({
    current: 1,
    pageSize: 10,
    total: 10,
  });

  const onChange = (newValue: number) => {
    setValue(newValue);
  };

  const createTree = (role: IRole) => {
    const newRole: DefaultOptionType = {
      label: role.roleName,
      value: role.id,
      key: role.id,
    };
    if (role.childeren.length > 0) {
      newRole.children = role.childeren.map((item) => createTree(item));
    }
    return newRole;
  };
  const data = roles.map((item) => createTree(item))[0]?.children;
  const treeProps: TreeSelectProps = useMemo(
    () => ({
      treeData: data,
      value,
      onChange,
      treeCheckable: false,
      showCheckedStrategy: SHOW_PARENT,
      placeholder: "select",
      style: {
        width: "100%",
      },
      loading: getRolesLoading,
    }),
    [data, getRolesLoading, value]
  );

  const deleteFrontPathAccess = async (id: number) => {
    if (!id) return;
    try {
      const { DeleteFrontPathAccess } = new SystemService();
      const response = await DeleteFrontPathAccess(id);
      if (response && response.status === 200) {
        message.success("Delete front path access successfully");
        getRolesOfFrontPath();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const fetchRoles = async () => {
    setRolesLoading(true);
    getRoles()
      .then((roles) => {
        if (roles && roles.length) {
          setRoles(roles);
        }
      })
      .finally(() => {
        setRolesLoading(false);
      });
  };

  const addRoleToFront = async (): Promise<boolean> => {
    if (!frontAccessForEdit || !value) return false;

    try {
      const { AddRolToFrontPath } = new SystemService();
      const res = await AddRolToFrontPath({
        frontPathId: Number(frontAccessForEdit?.id),
        roleId: Number(value),
      });
      if (res && res.status === 200) {
        setValue(undefined);
        message.success("Role successfully added to Front path.");
        getRolesOfFrontPath();
      } else message.error("Error in add role to front path");
    } catch (e) {
      console.log(e);
    }
    return true;
  };

  const getRolesOfFrontPath = useCallback(async () => {
    if (!frontAccessForEdit) return;
    setTableLoading(true);
    try {
      const { GetRoleOfFrontPath } = new SystemService();
      const result = await GetRoleOfFrontPath(frontAccessForEdit.id);
      if (result && result.status === 200) {
        setRolesPath(result.data);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setTableLoading(false);
    }
  }, [frontAccessForEdit]);

  const getFrontPath = useCallback(
    async (values?: IObject) => {
      setTableLoading(true);
      let filterSearchParams =
        searchParams.toString().length < 1 ? "?" : searchParams.toString();
      if (values) {
        Object.keys(values).forEach((key) => {
          if (values[key] !== undefined) {
            filterSearchParams = filterSearchParams
              .concat(filterSearchParams === "?" ? "" : "&")
              .concat(`${key}=${values[key]}`);
          }
        });
      }
      try {
        const { GetFrontPath } = new SystemService();
        const result = await GetFrontPath(filterSearchParams);
        if (result && result.status === 200) {
          setFrontPath(result.data.records);
          setPagination((prev) => ({ ...prev, total: result.data.count }));
        }
      } catch (err) {
        console.log(err);
      } finally {
        setTableLoading(false);
      }
    },
    [searchParams]
  );

  useEffect(() => {
    if (mode === "assign") fetchRoles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]);

  useEffect(() => {
    if (mode === "assign" && frontAccessForEdit) getRolesOfFrontPath();
  }, [mode, frontAccessForEdit, getRolesOfFrontPath]);

  const ContextValue: IContext = {
    value: {
      mode,
      tableLoading,
      frontAccessForEdit,
      frontPath,
      pagination,
      treeProps,
      rolesPath,
    },
    dispatch: {
      setMode,
      setFrontAccessForEdit,
      setPagination,
      setTableLoading,
      setFrontPath,
    },
    func: { getFrontPath, addRoleToFront, deleteFrontPathAccess },
  };
  return (
    <FrontAccessContext.Provider value={ContextValue}>
      {children}
    </FrontAccessContext.Provider>
  );
};
export const useFrontAccess = () => useContext(FrontAccessContext)!;

const getRoles = async () => {
  try {
    const { Roles } = new UserManagementService();
    const res = await Roles();
    if (res && res.status === 200 && res.data) {
      return res.data;
    } else {
      return [];
    }
  } catch (e) {
    console.log(e);
  }
};
