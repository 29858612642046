import { IPath } from "../Identity/models";

export const SystemGuardPath: IPath[] = [
  {
    path: "api/System/NewCategory",
    httpMethod: "POST",
  },
  {
    path: "api/System/AllCategory",
    httpMethod: "GET",
  },
  {
    path: "api/System/TreeCategory",
    httpMethod: "GET",
  },
  {
    path: "api/System/{CategoryId}/EditCategory",
    httpMethod: "PUT",
  },
  {
    path: "api/System/{CategoryId}/DeleteCategory",
    httpMethod: "DELETE",
  },
  {
    path: "api/System/NewCountry",
    httpMethod: "POST",
  },
  {
    path: "api/System/GetCountry",
    httpMethod: "GET",
  },
  {
    path: "api/System/NewCurrency",
    httpMethod: "POST",
  },
  {
    path: "api/System/AllActions",
    httpMethod: "GET",
  },
  {
    path: "api/System/NewRole",
    httpMethod: "POST",
  },
  {
    path: "api/System/AssignRoleToAction",
    httpMethod: "POST",
  },
  {
    path: "api/System/{ActionId}/RolesOfAction",
    httpMethod: "GET",
  },
  {
    path: "api/System/NewFrontPath",
    httpMethod: "POST",
  },
  {
    path: "api/System/GetFrontPath",
    httpMethod: "GET",
  },
  {
    path: "api/System/{FrontPathId}/EditFrontPath",
    httpMethod: "PUT",
  },
  {
    path: "api/System/{FrontPathId}/DeleteFrontPath",
    httpMethod: "DELETE",
  },
  {
    path: "api/System/AddRolToFrontPath",
    httpMethod: "POST",
  },
  {
    path: "api/System/{FronPathId}/GetRoleOfFrontPath",
    httpMethod: "GET",
  },
  {
    path: "api/System/{FrontPathAccesRoleId}/DeleteFrontPathAccess",
    httpMethod: "DELETE",
  },
  {
    path: "api/System/NewSupplier",
    httpMethod: "POST",
  },
  {
    path: "api/System/{SupplierId}/EditSupplier",
    httpMethod: "PUT",
  },
  {
    path: "api/System/{SupplierId}/DeletetSupplier",
    httpMethod: "DELETE",
  },
  {
    path: "api/System/GetSupplier",
    httpMethod: "GET",
  },
];

export const NewCategoryPath: IPath = {
  path: "api/System/NewCategory",
  httpMethod: "POST",
};
export const AllCategoryPath: IPath = {
  path: "api/System/AllCategory",
  httpMethod: "GET",
};
export const TreeCategoryPath: IPath = {
  path: "api/System/TreeCategory",
  httpMethod: "GET",
};
export const EditCategoryPath: IPath = {
  path: "api/System/{CategoryId}/EditCategory",
  httpMethod: "PUT",
};
export const DeleteCategoryPath: IPath = {
  path: "api/System/{CategoryId}/DeleteCategory",
  httpMethod: "DELETE",
};
export const NewCountryPath: IPath = {
  path: "api/System/NewCountry",
  httpMethod: "POST",
};
export const GetCountryPath: IPath = {
  path: "api/System/GetCountry",
  httpMethod: "GET",
};
export const EditCountryPath: IPath = {
  path: "api/System/{CountryId}/EditCountry",
  httpMethod: "PUT",
};
export const NewCurrencyPath: IPath = {
  path: "api/System/NewCurrency",
  httpMethod: "POST",
};
export const EditCurrencyPath: IPath = {
  httpMethod: "PUT",
  path: "api/System/{CurrencyId}/EditCurrency",
};
export const AllActionsPath: IPath = {
  path: "api/System/AllActions",
  httpMethod: "GET",
};
export const NewRolePath: IPath = {
  path: "api/System/NewRole",
  httpMethod: "POST",
};
export const AssignRoleToActionPath: IPath = {
  path: "api/System/AssignRoleToAction",
  httpMethod: "POST",
};
export const RolesOfActionPath: IPath = {
  path: "api/System/{ActionId}/RolesOfAction",
  httpMethod: "GET",
};
export const NewFrontPathGuardPath: IPath = {
  path: "api/System/NewFrontPath",
  httpMethod: "POST",
};
export const GetFrontPathGuardPath: IPath = {
  path: "api/System/GetFrontPath",
  httpMethod: "GET",
};
export const EditFrontPathGuardPath: IPath = {
  path: "api/System/{FrontPathId}/EditFrontPath",
  httpMethod: "PUT",
};
export const DeleteFrontPathGuardPath: IPath = {
  path: "api/System/{FrontPathId}/DeleteFrontPath",
  httpMethod: "DELETE",
};
export const AddRolToFrontPathGuardPath: IPath = {
  path: "api/System/AddRolToFrontPath",
  httpMethod: "POST",
};
export const GetRoleOfFrontPathGuardPath: IPath = {
  path: "api/System/{FronPathId}/GetRoleOfFrontPath",
  httpMethod: "GET",
};
export const DeleteFrontPathAccessPath: IPath = {
  path: "api/System/{FrontPathAccesRoleId}/DeleteFrontPathAccess",
  httpMethod: "DELETE",
};
export const NewSupplierPath: IPath = {
  path: "api/System/NewSupplier",
  httpMethod: "POST",
};
export const EditSupplierPath: IPath = {
  path: "api/System/{SupplierId}/EditSupplier",
  httpMethod: "PUT",
};
export const DeletetSupplierPath: IPath = {
  path: "api/System/{SupplierId}/DeletetSupplier",
  httpMethod: "DELETE",
};
export const GetSupplierPath: IPath = {
  path: "api/System/GetSupplier",
  httpMethod: "GET",
};
