import { Button, Divider, Flex, Modal } from "antd";
import { FC, useCallback, useRef } from "react";
import { ProfitAndLossModal, ProfitModalFooter } from "./style";
import { ReportCard } from "./ReportCard";
import { useCreateProfitData } from "./createData";
import { TotalCard } from "./totalCard";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import { useTableProfit } from "../ProftTable/context";

interface IProps {
  open: boolean;
  onClose: () => void;
}
export const ProfitAndLoss: FC<IProps> = ({ onClose, open }) => {
  const { data, totalData } = useCreateProfitData();
  const {
    value: { viewData },
  } = useTableProfit();

  const componentRef = useRef<HTMLDivElement>(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const reactToPrintContent = useCallback(() => {
    return componentRef.current;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [componentRef.current]);

  return (
    <Modal
      open={open}
      onCancel={onClose}
      width={850}
      style={{ top: 32, padding: 0 }}
      styles={{ content: { padding: 0 } }}
      closeIcon={null}
      footer={null}
      rootClassName="p-0"
    >
      <ReactToPrint
        content={reactToPrintContent}
        documentTitle="Profit and loss"
        removeAfterPrint
      />
      <ProfitAndLossModal ref={componentRef} className="p-[24px] pt-[32px]">
        <div className="relative">
          <Divider
            style={{ height: 41, background: "#2C73FC", marginTop: 0 }}
            orientation="right"
            className=""
          >
            <div className="bg-white h-[41px] flex justify-center items-center">
              <span className="text-[40px] text-[#FE9B0E] mx-[8px]">
                Profit & Loss Report
              </span>
            </div>
          </Divider>
          <div className="text-white text-[18px] flex items-center gap-[40px] absolute top-[7px] left-[32px]">
            <span>{viewData?.shipName}</span>
            <span>{viewData?.voyageNumber}</span>
          </div>
        </div>
        <Flex gap={32} vertical>
          {Object.keys(data).map((item, index) => (
            <ReportCard {...data[item]} key={index} />
          ))}
          <TotalCard title="Total financial" values={totalData} />
        </Flex>
      </ProfitAndLossModal>
      <ProfitModalFooter justify="space-between" align="center">
        <img
          src="/assets/images/downloadPdf.svg"
          alt=""
          onClick={handlePrint}
          className="select-none cursor-pointer"
        />
        <Button
          onClick={onClose}
          type="primary"
          className="!h-[40px] !rounded-[8px] w-[69px] px-[8px]"
        >
          Close
        </Button>
      </ProfitModalFooter>
    </Modal>
  );
};
