import { FC, Suspense } from "react";
import { ShipDailyReport } from "src/modules/ShipDailyReport";
import { DailyReportProvider } from "src/modules/ShipDailyReport/context";
import { PreLoading } from "src/routes/Loading";

export const ShipDailyReportPage: FC = () => {
  return (
    <Suspense fallback={<PreLoading />}>
      <DailyReportProvider>
        <ShipDailyReport />
      </DailyReportProvider>
    </Suspense>
  );
};
