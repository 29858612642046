import { styled } from "styled-components";

export const FinallyInvoiceContainer = styled.div`
  margin-top: 16px;
  .headerInfo {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 24px;
  }
  .headerText {
    color: var(--text, #001377);
    font-family: DM Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .headerValue {
    color: var(--text-3, #5d6881);
    text-align: right;
    font-family: DM Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .invoiceHash {
    color: var(--text, #001377);
    font-family: DM Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
  }
  .item {
    display: flex;
    gap: 16px;
    justify-content: flex-start;
    color: var(--text, #001377);
    font-family: "DM Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .itemValue {
    color: var(--text-3, #5d6881);
    font-family: DM Sans;
    font-style: normal;
    line-height: normal;
    display: flex;
    justify-content: flex-start;
    font-size: 14px;
    font-weight: 400;
  }
  .itemKey {
    width: 110px;
    white-space: nowrap;
    color: var(--text, #001377);
    font-family: "DM Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .totalInvValue {
    color: var(--text-3, #5d6881);
    font-family: "DM Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .relatedItem {
    display: flex;
    gap: 10px;
    justify-content: flex-start;

    .relatedValue {
      border-radius: 6px;
      border: 1px solid var(--color-Stroke-Box-Dash, #dbe2e6);
      color: var(--color-Light-Mode-Text-Secondary-Text, #5d6881);
      display: flex;
      padding: 4px 8px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      /* En/Subtitle/S3.SemiBold */
      font-family: "Plus Jakarta Sans";
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    .relatedKey {
      width: 175.92px;
      white-space: nowrap;
      color: var(--text, #001377);
      font-family: "DM Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
  .article-table {
    thead {
      tr {
        th {
          border-bottom: 1px solid #000;
        }
      }
    }
  }
  tr > th::before {
    content: unset !important;
  }
  tr > th {
    background-color: #f2f7ff !important;
    height: 60px;
    text-align: center !important;
    color: var(--secondary-dark-grey-900, #1b2559) !important;
    font-family: DM Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  tr > td {
    color: var(--text-3, #5d6881);
    text-align: center;
    font-family: DM Sans;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .comment {
    color: var(--text, #001377);
    font-family: DM Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 8px;
  }
`;
