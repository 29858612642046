import {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { IObject } from "src/models/interfaces";
import { PublicService } from "src/services/Public/Public.service";
import { IShip } from "src/services/Public/models";
import { ShipService } from "src/services/Ship/Ship.service";

interface IContext {
  value: {
    shipsData: IShip[];
    loading: boolean;
  };
  dispatch: {
    setFilterValue: Dispatch<SetStateAction<IFilterValue>>;
  };
}
interface IFilterValue extends IObject {
  Abbreviation?: string;
}
export const ShipDailyReportShipsContext = createContext<IContext | undefined>(
  undefined
);

export const ShipDailyReportShipsProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const [shipsData, setShipsData] = useState<IShip[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [filterValue, setFilterValue] = useState<IFilterValue>({});

  const getShips = useCallback(async () => {
    setLoading(true);
    try {
      const { Ship } = new PublicService();
      let filterSearchParams = filterValue.toString().length < 1 ? "?" : "";
      Object.keys(filterValue).forEach((key) => {
        if (filterValue[key] !== undefined) {
          filterSearchParams = filterSearchParams
            .concat(filterSearchParams === "?" ? "" : "&")
            .concat(`${key}=${filterValue[key]}`);
        }
      });
      const res = await Ship(filterSearchParams);
      if (res && res.status === 200 && res.data) {
        setShipsData(res.data);
        return res.data;
      }
    } catch (err) {
      console.log(err);
    }
  }, [filterValue]);

  const getShipsImage = useCallback(async (res: IShip[]) => {
    if (!res) return;
    const { Image } = new ShipService();

    res?.forEach(async (ship) => {
      await Image(ship.id)
        .then((image) => {
          if (image) {
            setShipsData((perv) =>
              perv.map((prevShip) => {
                if (prevShip.id === ship.id) prevShip.image = image;
                return prevShip;
              })
            );
          }
        })
        .catch((error) => {
          setShipsData((perv) =>
            perv.map((prevShip) => {
              if (prevShip.id === ship.id) prevShip.image = "noImage";
              return prevShip;
            })
          );
        });
    });
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    getShips().then((res) => {
      if (res) {
        getShipsImage(res);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValue, getShips]);
  const ContextValue: IContext = {
    value: { shipsData, loading },
    dispatch: { setFilterValue },
  };
  return (
    <ShipDailyReportShipsContext.Provider value={ContextValue}>
      {children}
    </ShipDailyReportShipsContext.Provider>
  );
};
export const useShipDailyReportShips = () =>
  useContext(ShipDailyReportShipsContext)!;
