import { Button, Col, Form, Row, message } from "antd";
import { FC, useState } from "react";
import { FormItem } from "src/components/UiKit/FormItem";
import { SInput } from "src/components/UiKit/Input";
import { useAddShip } from "src/modules/AddShips/context";
import { OwnerContainer } from "../../AddNewShip/styles";
import { IShipOwnerInformation } from "src/services/Ship/models";
import { ShipService } from "src/services/Ship/Ship.service";
import { SPopconfirm } from "../../AddNewShip/components/popconfirm";

export const EditOwner: FC = () => {
  const {
    forms: { OwnerForm },
    func: { fetchShipInformation },
    value: { tableCurrentValue, newData },
  } = useAddShip()!;
  const [loading, setLoading] = useState<boolean>(false);

  const onFinish = async (values: IShipOwnerInformation) => {
    if (!tableCurrentValue) return;
    setLoading(true);
    try {
      const service = new ShipService();
      const reqBody: IShipOwnerInformation = {
        ownerCompany: values.ownerCompany,
        ownerName: values.ownerName,
      };
      const response = await service.ShipOwnerInformation(
        tableCurrentValue?.id,
        reqBody
      );
      if (response && response.status === 200) {
        message.success("Owner information update successfully");
        fetchShipInformation();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Form
      form={OwnerForm}
      onFinish={onFinish}
      className="flex flex-col h-[620px] justify-between"
    >
      <OwnerContainer className="flex gap-[24px]">
        <Col span={24}>
          <div className="titleHolder pt-[16px]">
            Please insert ship owner’s information.
          </div>
        </Col>
        <Col span={24} className="flex gap-[24px] pt-[16px]">
          <FormItem
            name={"ownerCompany"}
            label="Owner’s company"
            required={false}
            className="w-[33%] !mb-0"
          >
            <SInput placeholder="insert" />
          </FormItem>
          <FormItem
            name={"ownerName"}
            label="Owner’s name"
            required={false}
            className="w-[33%] !mb-0"
          >
            <SInput placeholder="insert" />
          </FormItem>
        </Col>
      </OwnerContainer>
      <Row>
        <Col span={24}>
          <div className=" flex gap-[16px] footer justify-end">
            <SPopconfirm
              description="Are you sure you want to reset all the information you filled out?"
              onConfirm={() => {
                OwnerForm?.setFieldsValue(newData);
                fetchShipInformation();
              }}
            >
              <Button className="w-[100px]">Reset</Button>
            </SPopconfirm>
            <Button
              type="primary"
              className="w-[100px]"
              onClick={OwnerForm?.submit}
              loading={loading}
            >
              Save Edit
            </Button>
          </div>
        </Col>
      </Row>
    </Form>
  );
};
