import { Flex, Tabs, TabsProps } from "antd";
import { FC } from "react";
import { IHeaderTabs } from "../../models";
import { HeaderContainer } from "../../styles";
import { AddNewDailyReport } from "../AddNewDailyReport";
import { DailyReportsTable } from "../DailyReportsTable";
import { FilterPopUp } from "../DailyReportsTable/FilterPopup";
import { useDailyReport } from "../../context";
import { Guard } from "src/components/Guard";
import {
  AllShipDailyReportPath,
  NewDailyReportPath,
} from "src/services/DailyReport/guardPath";
import { ShipDailyPdf } from "./PdfDownload";

export const Header: FC = () => {
  const {
    value: { headerActiveKey },
    dispatch: { setHeaderActiveKey },
  } = useDailyReport()!;

  const items: TabsProps["items"] = [
    {
      key: IHeaderTabs.addNewDailyReport,
      label: (
        <Guard action={NewDailyReportPath}>
          <span>
            <span className="material-icons text-[10px] p-[8px]">lens</span>
            Add new daily report
          </span>
        </Guard>
      ),
      children: (
        <Guard action={NewDailyReportPath}>
          <AddNewDailyReport />
        </Guard>
      ),
    },
    {
      key: IHeaderTabs.dailyReportsTable,
      label: (
        <Guard action={AllShipDailyReportPath}>
          <span>
            <span className="material-icons text-[10px] p-[10px]">lens</span>
            Daily reports table
          </span>
        </Guard>
      ),
      children: (
        <Guard action={AllShipDailyReportPath}>
          <DailyReportsTable />
        </Guard>
      ),
    },
  ];

  const onChange = (key: string) => {
    setHeaderActiveKey(key);
  };

  return (
    <HeaderContainer className="relative">
      {headerActiveKey === IHeaderTabs.dailyReportsTable ? (
        <div className="absolute right-[1%] z-10 cursor-pointer headerTools">
          <Flex gap={24} justify="center" align="center">
            <ShipDailyPdf />
            <FilterPopUp>
              <div className="flex gap-[16px] items-end">
                <span className="material-icons text-[24px] text-[#2C73FC] mb-[5px]">
                  filter_alt
                </span>
                <div className="text-[20px] text-[#2C73FC]">Filter</div>
              </div>
            </FilterPopUp>
          </Flex>
        </div>
      ) : (
        ""
      )}
      <Tabs items={items} onChange={onChange} activeKey={headerActiveKey} />
    </HeaderContainer>
  );
};
