import { FC } from "react";
import { AgentsModalContainer } from "../../style";
import { UseAgents } from "../../context";
import { Button, Modal } from "antd";

export const ServicesModal: FC = () => {
  const {
    value: { isOpenModalService, description },
    func: { closeModalService },
  } = UseAgents();
  return (
    <AgentsModalContainer className="flex justify-center items-center gap-[16px] text-[16px]">
      <Modal
        title={
          <div className="pb-[16px] border-0 border-b-[1px] border-solid border-[#DADEEC]">
            <p className="text-[18px] font-semibold text-[#001377] mb-[2px] mt-[0px]">
              Agent
            </p>
            <span className="text-[12px] text-[#3E4556] font-medium pr-[8px] border-0 border-r-[2px] border-solid border-[#DADEEC]">
              {description?.name}
            </span>
            <span className="text-[12px] text-[#878787] font-normal ml-[8px]">
              Services
            </span>
          </div>
        }
        closeIcon={false}
        open={isOpenModalService}
        onCancel={closeModalService}
        footer={() => (
          <div className="flex justify-center ">
            <Button
              type="default"
              onClick={closeModalService}
              className="flex gap-[12px] justify-center font-semibold text-[#4B4B4B] text-[16px]"
            >
              <span className="material-icons text-[24px]">highlight_off</span>
              Cancel
            </Button>
          </div>
        )}
      >
        <div className="flex justify-center items-center p-[16px] rounded-[12px] border border-solid border-[#DADEEC] my-[32px]">
          <div className="p-[16px] flex justify-center items-center bg-[#f2f7ff] rounded-[12px] h-full w-full">
            <p>{description?.description}</p>
          </div>
        </div>
      </Modal>
    </AgentsModalContainer>
  );
};
