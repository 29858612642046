import { FC } from "react";
import { Header } from "./Components/Header";
import { useDailyReport } from "./context";
import { EditNewDailyReport } from "./Components/DailyReportsTable/EditDailyReport";

export const ShipDailyReport: FC = () => {
  const {
    value: { editMode },
  } = useDailyReport()!;
  return !editMode ? <Header /> : <EditNewDailyReport />;
};
