export enum INVOICE_REPORT_TYPES {
  AllRequest = 1,
  Attachment = 2,
  Invoice = 3,
  Article = 4,
}
export type InvoiceReportType = 1 | 2 | 3 | 4;
export type InvoiceReportKeys = keyof typeof INVOICE_REPORT_TYPES;
export const invoiceReports = [
  { name: "All Request", value: 1 },
  { name: "Attachment", value: 2 },
  { name: "Invoice", value: 3 },
  { name: "Article", value: 4 },
];
