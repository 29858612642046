import { Button, Col, Form, Row } from "antd";
import { FC, useEffect, useState } from "react";
import { FormItem } from "src/components/UiKit/FormItem";
import { SInput } from "src/components/UiKit/Input";
import { OwnerContainer } from "../styles";
import { useAddShip } from "src/modules/AddShips/context";
import { IAddNewShip } from "src/modules/AddShips/models";
import { SPopconfirm } from "./popconfirm";
import { ShipService } from "src/services/Ship/Ship.service";
import { IShipOwnerInformation } from "src/services/Ship/models";

export const Owner: FC = () => {
  const {
    value: { shipId },
    dispatch: { setActiveKeyAddShip },
    forms: { OwnerForm },
    func: { deleteShip },
  } = useAddShip()!;
  const [loading, setLoading] = useState<boolean>(false);
  const [submittable, setSubmittable] = useState<boolean>(false);
  const values = Form.useWatch([], OwnerForm);

  useEffect(() => {
    OwnerForm?.validateFields({ validateOnly: true }).then(
      () => {
        setSubmittable(true);
      },
      () => {
        setSubmittable(false);
      }
    );
  }, [values, OwnerForm]);
  const onCancel = () => {};

  const nextTab = () => {
    setActiveKeyAddShip(IAddNewShip.ShipManagement);
  };

  const onFinish = async (values: IShipOwnerInformation) => {
    setLoading(true);
    const service = new ShipService();
    const reqBody: IShipOwnerInformation = {
      ownerCompany: values.ownerCompany,
      ownerName: values.ownerName,
    };
    try {
      const response = await service.ShipOwnerInformation(shipId, reqBody);
      if (response && response.status === 200) {
        OwnerForm?.resetFields();
        nextTab();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Form form={OwnerForm} onFinish={onFinish}>
      <div className="flex flex-col h-[620px] justify-between">
        <OwnerContainer className="flex gap-[24px]">
          <Col span={24}>
            <div className="titleHolder pt-[16px]">
              Please insert ship owner’s information.
            </div>
          </Col>
          <Col span={24} className="flex gap-[24px] pt-[16px]">
            <FormItem
              name={"ownerCompany"}
              label="Owner’s company"
              className="w-[33%] !mb-0"
            >
              <SInput placeholder="insert" />
            </FormItem>
            <FormItem
              name={"ownerName"}
              label="Owner’s name"
              className="w-[33%] !mb-0"
            >
              <SInput placeholder="insert" />
            </FormItem>
          </Col>
        </OwnerContainer>
        <Row>
          <Col span={24}>
            <div className="flex justify-between footer">
              <div>
                <Button
                  onClick={() => deleteShip(shipId)}
                  className="w-[148px] flex justify-center items-center"
                >
                  Discard new ship
                </Button>
              </div>
              <div className="flex gap-[16px]">
                <SPopconfirm
                  description="Are you sure you want to reset all the information you filled out?"
                  onConfirm={() => {
                    OwnerForm?.resetFields();
                  }}
                >
                  <Button onClick={onCancel} className="w-[100px]">
                    Reset
                  </Button>
                </SPopconfirm>

                <Button
                  type="primary"
                  className="w-[100px]"
                  onClick={OwnerForm?.submit}
                  loading={loading}
                  disabled={!submittable}
                >
                  Next
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </Form>
  );
};
