import {
  Button,
  Col,
  Form,
  Modal,
  Progress,
  Select,
  Space,
  Upload,
} from "antd";
import { useAttachInvoiceFileContext } from "./context";
import { AttachInvoiceFileContainer, UploadButton } from "./style";
import { FormItem } from "src/components/UiKit/FormItem";
import { useEffect, useState } from "react";
import { UploadChangeParam } from "antd/es/upload";
import { InvoiceUrls } from "src/services/Invoice/urls";
import { uploadFile } from "src/helpers/uploadZipFile";
import { SInput } from "src/components/UiKit/Input";
import { SDatePicker } from "src/components/UiKit/DatePicker";
import { dummyRequest } from "src/helpers/dummyRequest";
import { TableUI } from "src/components/UiKit/table";
import dayjs, { Dayjs } from "dayjs";
import { InvoiceService } from "src/services/Invoice/Invoice.service";
const { Option } = Select;
export const AttachInvoiceFile = () => {
  const {
    value: {
      isModalOpenAttachInvoice,
      currentRefNumber,
      defaultFileList,
      zipFileCode,
      form,
      dataSource,
      tableColumn,
      tableLoading,
      editMode,
      currentEditRecord,
      allReferenceNumber,
      pagination,
    },
    dispatch: {
      setIsModalOpenAttachInvoice,
      setCurrentRefNumber,
      setDefaultFileList,
      setEditMode,
      setCurrentEditRecord,
      setPagination,
    },
    func: { onSearch, fetchAllRequestZipAttachment, refetchZipFile },
  } = useAttachInvoiceFileContext()!;
  const [uploading, setUploading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);

  const handleCancel = () => {
    setIsModalOpenAttachInvoice(false);
    form?.resetFields();
    setDefaultFileList([]);
    setCurrentRefNumber(undefined);
  };
  function onChange({ file, fileList }: UploadChangeParam) {
    const newFileList = fileList;
    setDefaultFileList(newFileList);
  }
  useEffect(() => {
    if (currentRefNumber) {
      form?.setFieldValue("allrequestId", currentRefNumber);
    }
    if (!isModalOpenAttachInvoice) {
      setCurrentRefNumber(undefined);
    }
  }, [currentRefNumber, form, isModalOpenAttachInvoice, setCurrentRefNumber]);
  useEffect(() => {
    if (zipFileCode) {
      const refNumber = allReferenceNumber?.find(
        (item) => item.refrenceNumber === zipFileCode
      );

      if (refNumber) {
        form?.setFieldValue("allrequestId", +refNumber.id);
        setCurrentRefNumber(refNumber.id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allReferenceNumber, zipFileCode]);
  const onFinish = async (values: any) => {
    if (!defaultFileList.length) return;
    setLoading(true);
    const done = () => {
      fetchAllRequestZipAttachment();
      form?.resetFields(["attachNumber", "attachDate"]);
      setProgress(0);
      setDefaultFileList([]);
      refetchZipFile();
    };
    if (editMode) {
      const file = defaultFileList[0];
      if (!currentEditRecord) return;
      if (!file) return;
      if (+file.uid === currentEditRecord.id) {
        try {
          const { UpdateAllRequestZipDetailInfo } = new InvoiceService();
          const res = await UpdateAllRequestZipDetailInfo(
            currentEditRecord.id,
            {
              attachDate: values.attachDate
                ? dayjs(values.attachDate).format("YYYY-MM-DD")
                : undefined,
              attachNumber: values.attachNumber,
            }
          );
          if (res && res.status === 200) {
            done();
            setEditMode(false);
          }
        } catch (err) {
          console.log(err);
        } finally {
          setUploading(false);
          setLoading(false);
        }
      } else {
        setUploading(true);
        uploadFile({
          setProgress,
          callbackUrl: InvoiceUrls.Invoice.concat(
            `${currentEditRecord.id}`
          ).concat(InvoiceUrls.UpdateAllRequestZipDetail),
          method: "PUT",
          newFile: defaultFileList[0].originFileObj,
          extraData: {
            attachNumber: values.attachNumber,
            attachDate: (values.attachDate as Dayjs).format("YYYY-MM-DD"),
          },
        })
          .then(() => {
            done();
            setEditMode(false);
          })
          .finally(() => {
            setUploading(false);
            setLoading(false);
          });
      }
    } else {
      setUploading(true);

      uploadFile({
        setProgress,
        callbackUrl: InvoiceUrls.Invoice.concat(`${currentRefNumber}`).concat(
          InvoiceUrls.AllRequestZipDetailV3
        ),
        method: "PATCH",
        newFile: defaultFileList[0].originFileObj,
        extraData: {
          attachNumber: values.attachNumber ? values.attachNumber : null,
          attachDate: values.attachDate
            ? (values.attachDate as Dayjs).format("YYYY-MM-DD")
            : null,
        },
      })
        .then(() => {
          done();
        })
        .finally(() => {
          setUploading(false);
          setLoading(false);
        });
    }

    refetchZipFile();
    // handleCancel();
  };

  return (
    <Modal
      width={"58%"}
      title={
        <div className="flex justify-between align-middle !h-[42px]">
          <span className="text-[#001377] text-[16px]  block">
            {"Attach invoice file"}
          </span>
          <span
            className="material-icons cursor-pointer select-none "
            onClick={handleCancel}
          >
            close
          </span>
        </div>
      }
      open={isModalOpenAttachInvoice}
      onCancel={handleCancel}
      footer={null}
      styles={{
        content: { padding: "24px 32px" },
        header: { marginBottom: 0 },
      }}
      style={{
        color: "#001377",
        fontSize: "16px",
        fontWeight: 700,
        top: 24,
      }}
      closeIcon={null}
    >
      <Form form={form} onFinish={onFinish}>
        <AttachInvoiceFileContainer
          gutter={[16, 16]}
          align={"middle"}
          justify={"center"}
        >
          <Col span={24} className="mt-[24px]">
            <div className="header">
              Please select zip file reference No. and upload its files.
            </div>
          </Col>
          <Col span={8}>
            <Form form={form} onFinish={onFinish}>
              <FormItem name={"allrequestId"} label={"zip file reference No."}>
                <Select
                  placeholder="select"
                  optionLabelProp="label"
                  showSearch
                  optionFilterProp="label"
                  className="selectOption"
                  allowClear
                  onChange={(value) => setCurrentRefNumber(+value)}
                  value={currentRefNumber}
                  onSearch={onSearch}
                >
                  {allReferenceNumber?.map((item) => (
                    <Option
                      value={item.id}
                      label={item.refrenceNumber}
                      key={item.id}
                    >
                      <Space>{item.refrenceNumber}</Space>
                    </Option>
                  ))}
                </Select>
              </FormItem>
            </Form>
          </Col>
          <Col span={8}>
            <FormItem label="Attach No." name={"attachNumber"}>
              <SInput />
            </FormItem>
          </Col>
          <Col span={8}>
            <FormItem label="Attach date" name={"attachDate"}>
              <SDatePicker className="w-full" />
            </FormItem>
          </Col>
          <Col span={24} className="mt-[19px]">
            <Upload
              onChange={onChange}
              fileList={
                defaultFileList.length > 0
                  ? [defaultFileList[defaultFileList.length - 1]]
                  : []
              }
              multiple={false}
              onRemove={() => setDefaultFileList([])}
              disabled={
                uploading || !currentRefNumber || defaultFileList.length > 0
              }
              customRequest={dummyRequest}
              showUploadList={{ showRemoveIcon: false }}
              itemRender={(originNode, file, fileList, { remove }) => {
                return (
                  <div className=" flex items-center gap-[8px] bg-[#F4F7FE] rounded-[4px] px-[16px] py-[8px] min-w-[446px]">
                    <span className="material-icons PaperClip text-[16px] text-[#5D6881]">
                      attachment
                    </span>
                    <div className="w-full">
                      <div className="flex items-center gap-[8px]">
                        <span className="text-[14px] text-[#2C73FC]">
                          {file.name}
                        </span>
                        <span
                          className="material-symbols-outlined text-[20px] text-[#EC2D30] cursor-pointer select-none"
                          onClick={remove}
                        >
                          delete_forever
                        </span>
                      </div>
                      {uploading && <Progress percent={progress} />}
                    </div>
                  </div>
                );
              }}
              className="flex items-center gap-[24px]"
            >
              <UploadButton
                type="dashed"
                className="upload-button"
                icon={<span className="material-icons">attach_file</span>}
                disabled={
                  uploading || !currentRefNumber || defaultFileList.length > 0
                }
              >
                Upload attachment file
              </UploadButton>
            </Upload>
          </Col>

          <Col span={24} className="flex justify-end gap-[24px]  footer">
            <Button
              onClick={() => {
                if (editMode) {
                  setEditMode(false);
                  setCurrentEditRecord(undefined);
                }
                form?.resetFields();
                setDefaultFileList([]);
              }}
              htmlType="button"
            >
              {editMode ? "Cancel" : "Reset"}
            </Button>
            <Button type="primary" onClick={form?.submit} loading={loading}>
              {editMode ? "Edit" : "Add"}
            </Button>
          </Col>
          <Col span={24}>
            <TableUI
              columns={tableColumn}
              dataSource={dataSource}
              pagination={{
                position: ["bottomCenter"],
                total: pagination.total,
                current: pagination.current,
                pageSize: pagination.pageSize,
                onChange: (page: number, pageSize: number) => {
                  setPagination((prev) => ({
                    ...prev,
                    current: pageSize !== prev.pageSize ? 1 : page,
                    pageSize,
                  }));
                },
              }}
              loading={tableLoading}
            />
          </Col>
          <Col span={24} className="flex justify-end gap-[24px]  footer">
            <Button type="primary" onClick={handleCancel}>
              Finish
            </Button>
          </Col>
        </AttachInvoiceFileContainer>
      </Form>
    </Modal>
  );
};
