export const OwnerShipType = [
  { value: 1, label: "Owner" },
  { value: 2, label: "Charterer" },
];
export enum OwnerShipTypeEnum {
  Own = 1,
  Charterer = 2,
}
export const CargoType = [
  { value: 1, label: "Bulker" },
  { value: 2, label: "Small dry" },
  { value: 3, label: "Tanker" },
];
export const SizeType = [
  { value: 1, label: "SMALL DIRTY TNKR" },
  { value: 2, label: "SMALL CLEAN TNKR" },
  { value: 3, label: "PANAMAX" },
  { value: 4, label: "VLCC" },
  { value: 5, label: "SUEZMAX TNKR" },
  { value: 6, label: "MR2 TNKR" },
];
export interface INewRequesterArg {
  name: string;
  acronymName: string;
}

export interface IEditRequesterArg {
  name: string;
}
