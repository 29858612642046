import { IPath } from "../Identity/models";

export const ShipGuardPath: IPath[] = [
  {
    path: "api/Ship/NewOwnerCompany",
    httpMethod: "POST",
  },
  {
    path: "api/Ship/NewOwnerShip",
    httpMethod: "POST",
  },
  {
    path: "api/Ship/{shipId}/DeleteShip",
    httpMethod: "DELETE",
  },
  {
    path: "api/Ship/ShipBasicInformation",
    httpMethod: "POST",
  },
  {
    path: "api/Ship/{shipId}/EditShipBasicInformation",
    httpMethod: "PUT",
  },
  {
    path: "api/Ship/{shipId}/Image",
    httpMethod: "PATCH",
  },
  {
    path: "api/Ship/{shipId}/Image",
    httpMethod: "GET",
  },
  {
    path: "api/Ship/{shipId}/ShipTechnicalInformation",
    httpMethod: "PUT",
  },
  {
    path: "api/Ship/{shipId}/ShipOwnerInformation",
    httpMethod: "PUT",
  },
  {
    path: "api/Ship/{shipId}/ShipPriceInformation",
    httpMethod: "PUT",
  },
  {
    path: "api/Ship/{shipId}/ShipInsuranceInformation",
    httpMethod: "POST",
  },
  {
    path: "api/Ship/{shipId}/ShipInsuranceInformation",
    httpMethod: "GET",
  },
  {
    path: "api/Ship/{ShipInsuranceId}/EditShipInsuranceInformation",
    httpMethod: "PUT",
  },
  {
    path: "api/Ship/{ShipInsuranceId}/DeleteShipInsuranceInformation",
    httpMethod: "DELETE",
  },
  {
    path: "api/Ship/{shipId}/ShipManagmentContract",
    httpMethod: "POST",
  },
  {
    path: "api/Ship/{shipId}/ShipManagmentContract",
    httpMethod: "GET",
  },
  {
    path: "api/Ship/{ShipManagmentContractId}/EditShipManagmentContract",
    httpMethod: "PUT",
  },
  {
    path: "api/Ship/{ShipManagmentContractId}/DeleteShipManagmentContract",
    httpMethod: "DELETE",
  },
  {
    path: "api/Ship/NewShipManagement",
    httpMethod: "POST",
  },
  {
    path: "api/Ship/{ShipManagementId}/EditShipManagement",
    httpMethod: "PUT",
  },
  {
    path: "api/Ship/{ShipManagementId}/DeletetShipManagement",
    httpMethod: "DELETE",
  },
  {
    path: "api/Ship/GetShipManagement",
    httpMethod: "GET",
  },
  {
    path: "api/Ship/NewOperator",
    httpMethod: "POST",
  },
  {
    path: "api/Ship/NewTrade",
    httpMethod: "POST",
  },
  {
    path: "api/Ship/NewRequester",
    httpMethod: "POST",
  },
  {
    path: "api/Ship/{RequesterId}/DeleteRequester",
    httpMethod: "DELETE",
  },
  {
    path: "api/Ship/{RequesterId}/EditRequester",
    httpMethod: "PUT",
  },
];

export const NewOwnerCompanyPath: IPath = {
  path: "api/Ship/NewOwnerCompany",
  httpMethod: "POST",
};
export const NewOwnerShipPath: IPath = {
  path: "api/Ship/NewOwnerShip",
  httpMethod: "POST",
};
export const DeleteShipPath: IPath = {
  path: "api/Ship/{shipId}/DeleteShip",
  httpMethod: "DELETE",
};
export const ShipBasicInformationPath: IPath = {
  path: "api/Ship/ShipBasicInformation",
  httpMethod: "POST",
};
export const EditShipBasicInformationPath: IPath = {
  path: "api/Ship/{shipId}/EditShipBasicInformation",
  httpMethod: "PUT",
};
export const UploadShipImagePath: IPath = {
  path: "api/Ship/{shipId}/Image",
  httpMethod: "PATCH",
};
export const GetShipImagePath: IPath = {
  path: "api/Ship/{shipId}/Image",
  httpMethod: "GET",
};
export const ShipTechnicalInformationPath: IPath = {
  path: "api/Ship/{shipId}/ShipTechnicalInformation",
  httpMethod: "PUT",
};
export const ShipOwnerInformationPath: IPath = {
  path: "api/Ship/{shipId}/ShipOwnerInformation",
  httpMethod: "PUT",
};
export const ShipPriceInformationPath: IPath = {
  path: "api/Ship/{shipId}/ShipPriceInformation",
  httpMethod: "PUT",
};
export const PostShipInsuranceInformationPath: IPath = {
  path: "api/Ship/{shipId}/ShipInsuranceInformation",
  httpMethod: "POST",
};
export const GetShipInsuranceInformationPath: IPath = {
  path: "api/Ship/{shipId}/ShipInsuranceInformation",
  httpMethod: "GET",
};
export const EditShipInsuranceInformationPath: IPath = {
  path: "api/Ship/{ShipInsuranceId}/EditShipInsuranceInformation",
  httpMethod: "PUT",
};
export const DeleteShipInsuranceInformationPath: IPath = {
  path: "api/Ship/{ShipInsuranceId}/DeleteShipInsuranceInformation",
  httpMethod: "DELETE",
};
export const PostShipManagmentContractPath: IPath = {
  path: "api/Ship/{shipId}/ShipManagmentContract",
  httpMethod: "POST",
};
export const GetShipManagmentContractPath: IPath = {
  path: "api/Ship/{shipId}/ShipManagmentContract",
  httpMethod: "GET",
};
export const EditShipManagmentContractPath: IPath = {
  path: "api/Ship/{ShipManagmentContractId}/EditShipManagmentContract",
  httpMethod: "PUT",
};
export const DeleteShipManagmentContractPath: IPath = {
  path: "api/Ship/{ShipManagmentContractId}/DeleteShipManagmentContract",
  httpMethod: "DELETE",
};
export const NewShipManagementPath: IPath = {
  path: "api/Ship/NewShipManagement",
  httpMethod: "POST",
};
export const EditShipManagementPath: IPath = {
  path: "api/Ship/{ShipManagementId}/EditShipManagement",
  httpMethod: "PUT",
};
export const DeletetShipManagementPath: IPath = {
  path: "api/Ship/{ShipManagementId}/DeletetShipManagement",
  httpMethod: "DELETE",
};
export const GetShipManagementPath: IPath = {
  path: "api/Ship/GetShipManagement",
  httpMethod: "GET",
};
export const NewOperatorPath: IPath = {
  path: "api/Ship/NewOperator",
  httpMethod: "POST",
};
export const NewTradePath: IPath = {
  path: "api/Ship/NewTrade",
  httpMethod: "POST",
};
export const NewRequesterPath: IPath = {
  path: "api/Ship/NewRequester",
  httpMethod: "POST",
};
export const DeleteRequesterPath: IPath = {
  path: "api/Ship/{RequesterId}/DeleteRequester",
  httpMethod: "DELETE",
};
export const EditRequesterPath: IPath = {
  path: "api/Ship/{RequesterId}/EditRequester",
  httpMethod: "PUT",
};
