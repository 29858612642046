import { FC, useState } from "react";
import { Button, Col, Form, Select, Spin } from "antd";
import { FormItem } from "src/components/UiKit/FormItem";
import { SPopconfirm } from "src/components/UiKit/PopConfirm";
import { SInput } from "src/components/UiKit/Input";
import { useNoonReportCTX } from "src/modules/NoonReport/context";
import { SDatePicker } from "src/components/UiKit/DatePicker";
import { FWInfoStyle } from "./styles";
import SubmitButton from "../SubmitButton";
import { useSpeedDistance } from "./context";
import { useAddNewNoonReportCTX } from "../../context";
import { NewNoonReportTabs } from "../../model/newNoonReportTabs";

export const SpeedDistanceForm: FC = () => {
  const [submittable, setSubmittable] = useState<boolean>(false);

  const {
    value: { mode },
  } = useNoonReportCTX();

  const {
    value: { loading, dataLoading },
    func: { onFinishInfo },
    forms: { formName },
  } = useSpeedDistance();

  const {
    dispatch: { setActiveTab },
  } = useAddNewNoonReportCTX();

  return (
    <Form form={formName} onFinish={onFinishInfo}>
      <FWInfoStyle gutter={[32, 24]}>
        {dataLoading ? (
          <div className="w-full h-full flex items-center justify-center">
            <Spin size="large" />
          </div>
        ) : (
          <>
            <Col span={24}>
              <div className="titleHolder pt-[16px]">
                Please insert speed & distance information.
              </div>
            </Col>
            <Col span={24} className="flex gap-[24px] pt-[16px] h-[103px]">
              <FormItem
                name={"DisRun24"}
                label="DisRun 24h"
                className="w-[33%] !mb-0"
              >
                <SInput placeholder="Insert" />
              </FormItem>

              <FormItem
                name={"DisRunTTL"}
                label="TTL DisRun"
                className="w-[33%] !mb-0"
              >
                <SInput placeholder="Insert" />
              </FormItem>
              <FormItem
                name={"toGo"}
                label="To Go (NM)"
                className="w-[33%] !mb-0"
              >
                <SInput placeholder="Insert" />
              </FormItem>
            </Col>

            <Col span={24} className="flex gap-[24px] pt-[16px] h-[103px]">
              <FormItem
                name={"AVSPD24"}
                label="AVSPD 24h"
                className="w-[33%] !mb-0"
              >
                <SInput placeholder="Insert" />
              </FormItem>

              <FormItem
                name={"AVSPDTTl"}
                label="TTL AVSPD (Kn)"
                className="w-[33%] !mb-0"
              >
                <SInput placeholder="Insert" />
              </FormItem>
              <FormItem name={"POSN"} label="POSN" className="w-[33%] !mb-0">
                <SInput placeholder="Insert" />
              </FormItem>
            </Col>

            <Col span={24} className="flex gap-[24px] pt-[16px] h-[103px]">
              <FormItem name={"ATD"} label="ATD" className="w-[33%] !mb-0">
                <SDatePicker
                  placeholder="Select date & Time"
                  showTime
                  className="w-[100%]"
                />
              </FormItem>

              <FormItem name={"ETA"} label="ETA" className="w-[33%] !mb-0">
                <SDatePicker
                  placeholder="Select date & Time"
                  showTime
                  className="w-[100%]"
                />
              </FormItem>
              <FormItem
                name={"port"}
                label="Next port"
                className="w-[33%] !mb-0"
              >
                <Select
                  allowClear
                  style={{ width: "100%" }}
                  placeholder="select"
                  optionFilterProp="children"
                  showSearch
                  className="selectOption"
                ></Select>
              </FormItem>
            </Col>

            <Col span={24}>
              <div className=" flex justify-between gap-[16px] footer">
                <div>
                  {mode === "create" && (
                    <Button
                      className="w-[100px]"
                      onClick={() => setActiveTab(NewNoonReportTabs.FW)}
                    >
                      Back
                    </Button>
                  )}
                </div>

                <div>
                  {mode === "create" && (
                    <SPopconfirm
                      description="Are you sure you want to reset all the information you filled out?"
                      onConfirm={() => {
                        formName.resetFields();
                      }}
                    >
                      <Button className="w-[100px] mr-4">Reset</Button>
                    </SPopconfirm>
                  )}
                  <SubmitButton
                    submittable={submittable}
                    setSubmittable={setSubmittable}
                    form={formName}
                    loading={loading}
                    buttonTitle={mode === "create" ? "Next" : "Save edit"}
                  />
                </div>
              </div>
            </Col>
          </>
        )}
      </FWInfoStyle>
    </Form>
  );
};
