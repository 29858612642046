import { Flex, Row } from "antd";
import styled from "styled-components";

export const ProfitAndLossModal = styled.div`
  .ant-divider-horizontal.ant-divider-with-text-right::before {
    width: 40%;
  }
`;

export const ReportCardStyle = styled(Row)<{ $minWidth?: number }>`
  .title {
    color: var(--color-Light-Mode-Text-Title, #001377);

    /* En/Title/T2.bold */
    font-family: "Plus Jakarta Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .items {
    border-radius: 8px;
    background: var(--color-Light-Mode-BG-Background-1, #f2f7ff);
    padding: 24px 16px;
    margin-top: 16px;
  }
  .label {
    min-width: ${({ $minWidth }) => $minWidth || 193}px;
    padding-right: 10px;
    color: var(--color-Light-Mode-Text-Title, #001377);
    /* En/Subtitle/S2.Medium */
    font-family: "Plus Jakarta Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .value {
    color: var(--color-Light-Mode-Text-Secondary-Text, #5d6881);
    /* En/Paragraph/P3.Regular */
    font-family: "Plus Jakarta Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;
export const TotalCardStyle = styled(Row)`
  .title {
    color: var(--color-Light-Mode-Text-Title, #001377);

    /* En/Title/T2.bold */
    font-family: "Plus Jakarta Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .items {
    border-radius: 12px;
  }
  .label {
    display: flex;
    height: 60px;
    padding: 14px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background: var(--color-Light-Mode-BG-table-Header, #dbe9ff);
    border-radius: 12px 12px 0 0;
    color: var(--color-Light-Mode-Text-Title, #001377);
    /* En/Title/T4.SemiBold */
    font-family: "Plus Jakarta Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .value {
    display: flex;
    height: 50px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 0 0 12px 12px;
    border-bottom: 1px solid var(--color-Stroke-Box-Dash, #dbe2e6);
    background: var(--color-Light-Mode-BG-Background-1, #f2f7ff);
    color: var(--color-Light-Mode-Text-Secondary-Text, #5d6881);
    font-family: "Plus Jakarta Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .success {
    color: #0c9d61;
  }
  .danger {
    color: #ec2d30;
  }
`;

export const ProfitModalFooter = styled(Flex)`
  padding: 24px 24px 32px 24px;
  border-top: 1px solid var(--color-Light-Mode-Border-3, #dadeec);
  margin-top: 32px;
`;
