import { FC } from "react";
import { CreateRoleContainer } from "./styles";
import { Button, Col, Form, Input, Row, TreeSelect } from "antd";
import { FormItem } from "src/components/UiKit/FormItem";
import { SInput } from "src/components/UiKit/Input";
import { useCreateRoleContext } from "./context";

import { ConfirmModal } from "src/components/UiKit/ConfirmModal";

export const CreateRole: FC = () => {
  const {
    onFinish,
    onCancelConfirm,
    onConfirm,
    showConfirm,
    treeProps,
    confirmLoading,
    createRoleForm,
  } = useCreateRoleContext();

  return (
    <>
      <Form onFinish={onFinish} form={createRoleForm}>
        <CreateRoleContainer justify={"center"} gutter={[0, 24]}>
          <Col span={24} className="header">
            Create role
          </Col>
          <Col span={15} className="content">
            <Row gutter={[24, 0]}>
              <Col span={24} className="mb-[40px]">
                <div className="title">Please insert the role information.</div>
              </Col>
              <Col span={12}>
                <FormItem
                  name={"name"}
                  label={"Roll name"}
                  required
                  rules={[{ required: true }]}
                >
                  <SInput />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label={"Senior role"} name={"parentId"}>
                  <TreeSelect
                    {...treeProps}
                    multiple={false}
                    allowClear
                    className="selectOption"
                  />
                </FormItem>
              </Col>
              <Col span={24}>
                <FormItem
                  label={"Description"}
                  required
                  name={"comment"}
                  rules={[{ required: true }]}
                >
                  <Input.TextArea
                    autoSize={{ minRows: 15, maxRows: 15 }}
                    className="s-textarea py-[16px] px-[12px]"
                    placeholder="Type role description"
                  />
                </FormItem>
              </Col>
              <Col span={24} className="flex justify-end gap-[24px] footer">
                <Button type="primary" htmlType="submit">
                  Create
                </Button>
              </Col>
            </Row>
          </Col>
        </CreateRoleContainer>
      </Form>
      <ConfirmModal
        open={showConfirm}
        onCancel={onCancelConfirm}
        onOk={onConfirm}
        text="Are you sure you want to Create this role?"
        title={"Create role"}
        confirmIcon="add_circle"
        okText="Create"
        confirmLoading={confirmLoading}
      />
    </>
  );
};
