import { FC, useEffect, useState } from "react";
import { TableContainer } from "../styles";
import { Col } from "antd";
import { TableUI } from "src/components/UiKit/table";
import { ColumnsType } from "antd/es/table";
import { Tools } from "./tools";
import { ProfitAndLoss } from "../../ProfitAndLossReport";
import { useTableProfit } from "../context";
import { IAllProfit, ProfitCharterType } from "src/services/profit/models";
import { useProfitReport } from "src/modules/ProfitReport/context";
import { ProfitTabs } from "src/modules/ProfitReport/constants/Tabs";
import {
  AddNewProfitStatusTypeEnum,
  chooseCharterType,
} from "src/modules/ProfitReport/models";
import { useNewProfitReport } from "../../AddNewProfit/context";
import { uniqueId } from "lodash";
import { Guard } from "src/components/Guard";
import { AllProfit } from "src/services/profit/guardPath";

export const TableOfProfit: FC = () => {
  const [openRecordPrint, setOpenRecordPrint] = useState<boolean>(false);

  const {
    value: { dataSource, pagination, tableLoading },
    dispatch: { setPagination, setViewData, setEditId, setFromTable },
    func: { getDataTableProfit, DeleteProfit, getDataViewProfit },
  } = useTableProfit();

  const {
    dispatch: { setActiveKey, setEditMode, setStatus, setStep },
  } = useProfitReport();

  const {
    dispatches: { setNewBasicInfoId },
  } = useNewProfitReport();

  const onEdit = (id: number, data: IAllProfit) => {
    setEditId(id);
    setNewBasicInfoId(id);
    setActiveKey(ProfitTabs.Add);
    setStatus({
      basic: AddNewProfitStatusTypeEnum.done,
      shipping: data.haseProfitShipping
        ? AddNewProfitStatusTypeEnum.done
        : AddNewProfitStatusTypeEnum.empty,
      trade: data.haseProfitTrade
        ? AddNewProfitStatusTypeEnum.done
        : AddNewProfitStatusTypeEnum.empty,
    });
    setStep(undefined);
    setEditMode(true);
    getDataViewProfit(id);
    setFromTable(true);
  };

  const columns: ColumnsType<IAllProfit> = [
    {
      title: "#",
      key: "id",
      align: "center",
      width: "73px",
      render: (name, record, index) =>
        (pagination.current - 1) * pagination.pageSize + (index + 1),
    },
    {
      title: "Vessel",
      dataIndex: "shipName",
      key: "shipName",
      align: "center",
      width: "104px",
      className: "border-0 border-r-[1px] border-solid border-[#fff]",
    },
    {
      title: "Voyage number",
      dataIndex: "voyageNumber",
      key: "voyageNumber",
      align: "center",
      width: "214px",
    },
    {
      title: "Charter type",
      key: "charterType",
      align: "center",
      width: "214px",
      render: (name, record, index) => chooseCharterType(record.charterType),
    },
    {
      title: "Total combined revenue",
      key: "totalCombinedRevenue",
      align: "center",
      width: "153px",
      render: (name, record, index) => (
        <>
          <span
            className={`${
              record.totalCombinedRevenue > 0
                ? "success"
                : record.totalCombinedRevenue < 0
                ? " danger"
                : ""
            }`}
          >
            {record.totalCombinedRevenue}
          </span>
        </>
      ),
    },
    {
      title: "Total shipping revenue",
      dataIndex: "totalShippingRevenue",
      key: "totalShippingRevenue",
      align: "center",
      width: "142px",
      render: (name, record, index) => (
        <span
          className={`${
            record.totalShippingRevenue > 0
              ? "success"
              : record.totalShippingRevenue < 0
              ? " danger"
              : ""
          }`}
        >
          {record.totalShippingRevenue}
        </span>
      ),
    },
    {
      title: "Total trade revenue",
      key: "totalTradeRevenue",
      align: "center",
      width: "120px",
      render: (text, record) => {
        if (
          record.charterType === ProfitCharterType.TradeDepartman_TCH ||
          record.charterType === ProfitCharterType.TradeDepartman_VCH
        ) {
          return (
            <span
              className={`${
                record.totalTradeRevenue > 0
                  ? "success"
                  : record.totalTradeRevenue < 0
                  ? " danger"
                  : ""
              }`}
            >
              {record.totalTradeRevenue}
            </span>
          );
        } else return "---";
      },
    },
    {
      title: "Tools",
      key: "tools",
      align: "center",
      width: "110px",
      className: "border-0 border-l-[1px] border-solid border-[#fff]",
      render: (name, record, index) => (
        <Tools
          onDelete={async () => await DeleteProfit(record.profitId)}
          onEdit={() => onEdit(record.profitId, record)}
          onView={() => {
            setOpenRecordPrint(true);
            setViewData(record);
          }}
        />
      ),
    },
  ];

  useEffect(() => {
    getDataTableProfit();
  }, [getDataTableProfit]);
  return (
    <TableContainer>
      <Col span={24}>
        <Guard action={AllProfit}>
          <TableUI
            loading={tableLoading}
            columns={columns}
            dataSource={dataSource}
            rowKey={() => uniqueId()}
            pagination={{
              position: ["bottomCenter"],
              showSizeChanger: true,
              total: pagination.total,
              pageSize: pagination.pageSize,
              current: pagination.current,
              onChange: (page: number, pageSize: number) => {
                setPagination((prev) => ({
                  ...prev,
                  current: pageSize !== prev.pageSize ? 1 : page,
                  pageSize,
                }));
              },
            }}
          />
        </Guard>
      </Col>
      <ProfitAndLoss
        open={openRecordPrint}
        onClose={() => {
          setOpenRecordPrint(false);
        }}
      />
    </TableContainer>
  );
};
