import { Row } from "antd";
import styled from "styled-components";

export const AttachFileBaseInfoContainer = styled.div`
  /* border-radius: 0px 0px 20px 20px;
  background: var(--color-Base-Color-White, #fff); */

  /* 2 */
  /* box-shadow: 0px 4px 6px 0px rgba(113, 130, 183, 0.25); */
  .title {
    color: var(--color-Light-Mode-Text-Title, #001377);

    /* En/Title/T3.SemiBold */
    font-family: "Plus Jakarta Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;

export const ContentContainer = styled(Row)`
  border-radius: 12px;
  border: 1px solid var(--color-Stroke-footer-buttons, #dadeec);
  padding: 28px 16px 24px 16px;

  img {
    display: block;
  }
  .ant-upload-picture-card-wrapper {
    width: auto;
  }
`;

export const AttachFileBaseInfoTableContainer = styled(Row)`
  margin-top: 36px;
`;
