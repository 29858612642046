import { Col, Row, Skeleton } from "antd";
import { FC, useEffect } from "react";
import { ShowBasicInformationContainer } from "../style";
import { ShipInformationItems } from "./shipInformationItem";
import { useAddShip } from "src/modules/AddShips/context";
import moment from "moment";

export const ShowBasicInformation: FC = () => {
  const {
    value: { tableCurrentValue, pictureFileList, picLoading },
    func: { fetchImage },
  } = useAddShip()!;
  useEffect(() => {
    fetchImage();
  }, [fetchImage]);
  const ownerShipTypeName = (value?: number) => {
    switch (value) {
      case 1:
        return "Own";
      case 2:
        return "Charterer";
    }
  };
  return (
    <ShowBasicInformationContainer>
      <Col span={24} className="pictureHolder">
        {picLoading ? (
          <Skeleton.Image active className="!w-[334px] !h-[165px]" />
        ) : (
          pictureFileList.map((item, index) => (
            <img
              src={item.url}
              key={index}
              width={334}
              height={165}
              alt={item.name}
              className="rounded-[16px]"
            />
          ))
        )}
      </Col>
      <Col span={24} className="titleHolder">
        Ship information
      </Col>
      <Col span={24} className="dataHolder">
        <Row gutter={60} justify={"space-between"}>
          <Col span={12}>
            <ShipInformationItems
              name="Previous names"
              value={tableCurrentValue?.pN1}
            />
            <ShipInformationItems
              name="Abbreviation "
              value={tableCurrentValue?.abbreviation}
            />

            <ShipInformationItems
              name="purchase date"
              value={
                tableCurrentValue?.purchaseDate
                  ? moment(tableCurrentValue?.purchaseDate).format("YYYY-MM-DD")
                  : ""
              }
            />
            <ShipInformationItems
              name="Country of registration "
              value={tableCurrentValue?.countryRegister}
            />
          </Col>
          <Col span={12}>
            <ShipInformationItems
              name="Current name"
              value={tableCurrentValue?.name}
            />
            <ShipInformationItems
              name="Ownership type"
              value={ownerShipTypeName(tableCurrentValue?.ownerShipType)}
            />

            <ShipInformationItems
              name="Delivery date"
              value={
                tableCurrentValue?.deliveryDate
                  ? moment(tableCurrentValue?.deliveryDate).format("YYYY-MM-DD")
                  : ""
              }
            />
          </Col>
        </Row>
      </Col>
    </ShowBasicInformationContainer>
  );
};
