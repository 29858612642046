import { Flex } from "antd";
import { FC, useEffect, useState } from "react";
import { TradeStatus } from "src/modules/ShipDailyReport/constant/shipState";
import useOwnerStore from "src/store/owner";
import { StateDescriptionStyle } from "./style";

export const StateDescription: FC = () => {
  const { dailyReport } = useOwnerStore();

  const [shipStateName, setShipStateName] = useState<string>("");

  useEffect(() => {
    if (dailyReport) {
      TradeStatus.forEach((state) => {
        if (state.id === dailyReport.shipState) {
          setShipStateName(state.name);
        }
      });
    }
  }, [dailyReport]);
  return (
    <StateDescriptionStyle>
      <Flex justify="space-between">
        <label className="text-[#000] text-[16px] font-[700]">
          State Description
        </label>
        <label className="text-[#2C73FC] text-[16px] font-[700]">
          {shipStateName}
        </label>
      </Flex>
      <div className="content min-h-[290px] !items-start">
        <p className="text-[#5D6881] text-[12px] font-[500]">
          {dailyReport?.stateDescription}
        </p>
      </div>
    </StateDescriptionStyle>
  );
};
