import { FC, useMemo } from "react";
import { SupplierViewContainer } from "./style";
import { Col, Row } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "src/models/enums";
import { IGetSupplier } from "src/services/System/models";
import { EditSupplierPath } from "src/services/System/guardPath";
import { Guard } from "src/components/Guard";

export const SupplierView: FC = () => {
  const navigate = useNavigate();
  const onBack = () => {
    navigate(ROUTES.Supplier.concat("?table=true"));
  };

  const location = useLocation();
  const viewData = useMemo(() => {
    if (location.state) {
      return location.state as IGetSupplier;
    }
  }, [location.state]);

  return (
    <SupplierViewContainer>
      <Row>
        <Col span={24}>
          <div
            onClick={onBack}
            className="flex justify-between items-center py-[19px] px-[24px] borderBottom"
          >
            <div className="flex flex-row gap-[12px] items-center cursor-pointer">
              <span className="material-icons text-[#2C73FC] text-[32px]">
                keyboard_backspace
              </span>
              <div className=" text-[#2C73FC] text-[24px] ">
                Back to suppliers table
              </div>
            </div>
            <div className="flex flex-col">
              <div className="text-[#001377] text-[18px]">
                supplier information
              </div>
              <div className="text-[#5D6881] text-[14px]">{viewData?.name}</div>
            </div>
          </div>
        </Col>
        <Col span={24} className="body">
          <Row>
            <Col span={24} className="header">
              <Col span={6}>
                <p className=" text-[#001377] text-[18px] font-bold mb-[0px]">
                  supplier information
                </p>
                <span className="text-[14px] text-[#5D6881]">
                  {viewData?.name}
                </span>
              </Col>
              <Col span={2}>
                <Guard action={EditSupplierPath}>
                  <span
                    className="material-icons flex justify-end text-[32px] text-[#001377]"
                    onClick={() => {
                      navigate(ROUTES.SupplierEdit, { state: viewData });
                    }}
                  >
                    edit_note
                  </span>
                </Guard>
              </Col>
            </Col>
          </Row>
          <Row>
            <Col span={24} className="content">
              <div className=" flex justify-between py-[16px]">
                <span className="fix">Supplier name</span>
                <span className="variable">{viewData?.name}</span>
              </div>
              <div className=" flex justify-between py-[16px]">
                <span className="fix">Website</span>
                <span className="variable">{viewData?.webSite}</span>
              </div>
              <div className=" flex justify-between py-[16px]">
                <span className="fix">Phone number</span>
                <span className="variable">{viewData?.phoneNumber}</span>
              </div>
              <div className=" flex justify-between py-[16px]">
                <span className="fix">Email</span>
                <span className="variable">{viewData?.email}</span>
              </div>
              <div className=" flex justify-between py-[16px]">
                <span className="fix">Country</span>
                <span className="variable">{viewData?.countryName}</span>
              </div>
              <div className=" flex justify-between py-[16px]">
                <span className="fix">City</span>
                <span className="variable">{viewData?.city}</span>
              </div>
              <div className=" flex justify-between py-[16px]">
                <span className="fix">Address</span>
                <span className="variable">{viewData?.address} </span>
              </div>
              {/* <div className=" flex justify-between py-[16px]">
                <span className="fix">Activity</span>
                <span className="variable">{viewData?.comment} </span>
              </div> */}
            </Col>
          </Row>
        </Col>
      </Row>
    </SupplierViewContainer>
  );
};
