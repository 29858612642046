import {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useState,
} from "react";
import {
  IReportAllRequest,
  IReportAllRequestAttachment,
  IReportInvoice,
  IReportInvoiceArticle,
} from "src/services/InvoiceReport/models";
import { IReportFormValues } from "../models/formValues";
import { createInvoiceReportReqBody } from "../helper/createInvoiceReportReqBody";
import { INVOICE_REPORT_TYPES } from "../models/reportTypes";
import { InvoiceReportService } from "src/services/InvoiceReport/InvoiceReport.service";
import { IPagination } from "src/models/interfaces/pagination";
import { Form, Modal } from "antd";
import { FormInstance } from "antd/lib";

interface ILoadings {
  allRequest: boolean;
  attachment: boolean;
  article: boolean;
  invoice: boolean;
}
interface ICommentModal {
  showModal: boolean;
  title: string;
  description: string;
}
interface IContext {
  value: {
    loading: boolean;
    AllRequestData: IReportAllRequest[];
    AllRequestPagination: IPagination;
    ArticleData: IReportInvoiceArticle[];
    ArticlePagination: IPagination;
    AttachmentData: IReportAllRequestAttachment[];
    AttachmentPagination: IPagination;
    InvoiceData: IReportInvoice[];
    InvoicePagination: IPagination;
    reportType: number | undefined;
    currentRequestValue: Partial<IReportFormValues> | undefined;
    loadings: ILoadings;
    commentModal: ICommentModal;
    currentAllRequest: IReportAllRequest | undefined;
    currentAttachment: IReportAllRequestAttachment | undefined;
    currentInvoice: IReportInvoice | undefined;
    defaultPagination: IPagination;
    invoiceReportForm: FormInstance | undefined;
  };
  dispatch: {
    setReportType: Dispatch<SetStateAction<number | undefined>>;
    setAllRequestPagination: Dispatch<SetStateAction<IPagination>>;
    setArticlePagination: Dispatch<SetStateAction<IPagination>>;
    setAttachmentPagination: Dispatch<SetStateAction<IPagination>>;
    setInvoicePagination: Dispatch<SetStateAction<IPagination>>;
    setLoadings: Dispatch<SetStateAction<ILoadings>>;
    setCommentModal: Dispatch<SetStateAction<ICommentModal>>;
    setCurrentAllRequest: Dispatch<
      SetStateAction<IReportAllRequest | undefined>
    >;
    setCurrentAttachment: Dispatch<
      SetStateAction<IReportAllRequestAttachment | undefined>
    >;
    setCurrentInvoice: Dispatch<SetStateAction<IReportInvoice | undefined>>;
    setCurrentRequestValue: Dispatch<
      SetStateAction<Partial<IReportFormValues> | undefined>
    >;
  };
  func: {
    setFilter: (values: Partial<IReportFormValues>) => void;
    fetchAllRequest: (
      values: Partial<IReportFormValues>,
      pagination?: IPagination
    ) => void;
    fetchArticle: (
      values: Partial<IReportFormValues>,
      invoiceId?: number,
      pagination?: IPagination
    ) => void;
    fetchAttachment: (
      values: Partial<IReportFormValues>,
      allRequestId?: number,
      pagination?: IPagination
    ) => void;
    fetchInvoice: (
      values: Partial<IReportFormValues>,
      attachmentId?: number,
      pagination?: IPagination
    ) => void;
  };
}
const defaultPagination: IPagination = {
  Limit: 10,
  Offset: 1,
};
const defaultLoadings: ILoadings = {
  allRequest: false,
  article: false,
  attachment: false,
  invoice: false,
};
const defaultCommentModal: ICommentModal = {
  showModal: false,
  title: "",
  description: "",
};
const defaultContextValue: IContext = {
  value: {
    loading: false,
    AllRequestData: [],
    AllRequestPagination: defaultPagination,
    ArticleData: [],
    ArticlePagination: defaultPagination,
    AttachmentData: [],
    AttachmentPagination: defaultPagination,
    InvoiceData: [],
    InvoicePagination: defaultPagination,
    reportType: undefined,
    currentRequestValue: undefined,
    loadings: defaultLoadings,
    commentModal: defaultCommentModal,
    currentAllRequest: undefined,
    currentAttachment: undefined,
    currentInvoice: undefined,
    defaultPagination,
    invoiceReportForm: undefined,
  },
  dispatch: {
    setReportType: () => {},
    setAllRequestPagination: () => {},
    setArticlePagination: () => {},
    setAttachmentPagination: () => {},
    setInvoicePagination: () => {},
    setLoadings: () => {},
    setCommentModal: () => {},
    setCurrentAllRequest: () => {},
    setCurrentAttachment: () => {},
    setCurrentInvoice: () => {},
    setCurrentRequestValue: () => {},
  },
  func: {
    setFilter: () => {},
    fetchAllRequest: () => {},
    fetchArticle: () => {},
    fetchAttachment: () => {},
    fetchInvoice: () => {},
  },
};
export const ReportContext = createContext<IContext>(defaultContextValue);

export const ReportProvider: FC<PropsWithChildren> = ({ children }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [loadings, setLoadings] = useState<ILoadings>(defaultLoadings);
  const [invoiceReportForm] = Form.useForm();

  const [commentModal, setCommentModal] =
    useState<ICommentModal>(defaultCommentModal);
  const [currentRequestValue, setCurrentRequestValue] =
    useState<Partial<IReportFormValues>>();
  const [AllRequestPagination, setAllRequestPagination] = useState<IPagination>(
    { Limit: 10, Offset: 1 }
  );
  const [AllRequestData, setAllRequestData] = useState<IReportAllRequest[]>([]);
  const [ArticlePagination, setArticlePagination] =
    useState<IPagination>(defaultPagination);
  const [ArticleData, setArticleData] = useState<IReportInvoiceArticle[]>([]);

  const [AttachmentData, setAttachmentData] = useState<
    IReportAllRequestAttachment[]
  >([]);
  const [AttachmentPagination, setAttachmentPagination] =
    useState<IPagination>(defaultPagination);
  const [InvoiceData, setInvoiceData] = useState<IReportInvoice[]>([]);
  const [InvoicePagination, setInvoicePagination] =
    useState<IPagination>(defaultPagination);
  const [reportType, setReportType] = useState<number>();
  const [currentAllRequest, setCurrentAllRequest] =
    useState<IReportAllRequest>();
  const [currentAttachment, setCurrentAttachment] =
    useState<IReportAllRequestAttachment>();
  const [currentInvoice, setCurrentInvoice] = useState<IReportInvoice>();
  const {
    ReportAllRequest,
    ReportAllRequestAttachment,
    ReportInvoice,
    ReportInvoiceArticle,
  } = new InvoiceReportService();
  const fetchAllRequest = useCallback(
    async (values: Partial<IReportFormValues>, pagination?: IPagination) => {
      setLoadings((prev) => ({ ...prev, allRequest: true }));
      const reqBody = createInvoiceReportReqBody({
        values,
        limit: pagination ? pagination.Limit : AllRequestPagination.Limit,
        offset: pagination ? pagination.Offset : AllRequestPagination.Offset,
      });

      await ReportAllRequest(reqBody)
        .then((res) => {
          if (res && res.data) {
            setAllRequestData(res.data.records);
            setAllRequestPagination((prev) => ({
              ...prev,
              total: res.data.count,
            }));
          }
        })
        .catch((err) => console.log(err))
        .finally(() => {
          setLoadings((prev) => ({ ...prev, allRequest: false }));
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [AllRequestPagination]
  );
  const fetchArticle = useCallback(
    async (
      values: Partial<IReportFormValues>,
      invoiceId?: number,
      pagination?: IPagination
    ) => {
      setLoadings((prev) => ({ ...prev, article: true }));
      const reqBody = createInvoiceReportReqBody({
        values,
        limit: pagination ? pagination.Limit : ArticlePagination.Limit,
        offset: pagination ? pagination.Offset : ArticlePagination.Offset,
      });
      if (invoiceId) {
        reqBody.invoiceId = invoiceId;
      }
      await ReportInvoiceArticle(reqBody)
        .then((res) => {
          if (res && res.data) {
            setArticleData(res.data.records);
            setArticlePagination((prev) => ({
              ...prev,
              total: res.data.count,
            }));
          }
        })
        .catch((err) => console.log(err))
        .finally(() => {
          setLoadings((prev) => ({ ...prev, article: false }));
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ArticlePagination]
  );
  const fetchAttachment = useCallback(
    async (
      values: Partial<IReportFormValues>,
      allRequestId?: number,
      pagination?: IPagination
    ) => {
      setLoadings((prev) => ({ ...prev, attachment: true }));
      const reqBody = createInvoiceReportReqBody({
        values,
        limit: pagination ? pagination.Limit : AttachmentPagination.Limit,
        offset: pagination ? pagination.Offset : AttachmentPagination.Offset,
      });
      if (allRequestId) {
        reqBody.allRequestId = allRequestId;
      }
      await ReportAllRequestAttachment(reqBody)
        .then((res) => {
          if (res && res.data) {
            setAttachmentData(res.data.records);
            setAttachmentPagination((prev) => ({
              ...prev,
              total: res.data.count,
            }));
          }
        })
        .catch((err) => console.log(err))
        .finally(() => {
          setLoadings((prev) => ({ ...prev, attachment: false }));
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [AttachmentPagination]
  );
  const fetchInvoice = useCallback(
    async (
      values: Partial<IReportFormValues>,
      attachmentId?: number,
      pagination?: IPagination
    ) => {
      setLoadings((prev) => ({ ...prev, invoice: true }));
      console.log({ values });
      const reqBody = createInvoiceReportReqBody({
        values,
        limit: pagination ? pagination.Limit : InvoicePagination.Limit,
        offset: pagination ? pagination.Offset : InvoicePagination.Offset,
      });
      if (attachmentId) {
        reqBody.attachmentId = attachmentId;
      }
      await ReportInvoice(reqBody)
        .then((res) => {
          if (res && res.data) {
            setInvoiceData(res.data.records);
            setInvoicePagination((prev) => ({
              ...prev,
              total: res.data.count,
            }));
          }
        })
        .catch((err) => console.log(err))
        .finally(() => {
          setLoadings((prev) => ({ ...prev, invoice: false }));
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [InvoicePagination]
  );
  const setFilter = async (values: Partial<IReportFormValues>) => {
    setLoading(true);
    setCurrentRequestValue(values);
    try {
      setReportType(values.reportType);
      switch (values.reportType) {
        case INVOICE_REPORT_TYPES.AllRequest:
          fetchAllRequest(values);
          return;
        case INVOICE_REPORT_TYPES.Article:
          fetchArticle(values);
          return;
        case INVOICE_REPORT_TYPES.Attachment:
          fetchAttachment(values);
          return;
        case INVOICE_REPORT_TYPES.Invoice:
          fetchInvoice(values);
          return;
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  const contextValue: IContext = {
    value: {
      loading,
      AllRequestData,
      AllRequestPagination,
      ArticleData,
      ArticlePagination,
      AttachmentData,
      AttachmentPagination,
      InvoiceData,
      InvoicePagination,
      reportType,
      currentRequestValue,
      loadings,
      commentModal,
      currentAllRequest,
      currentAttachment,
      currentInvoice,
      defaultPagination,
      invoiceReportForm,
    },
    dispatch: {
      setReportType,
      setAllRequestPagination,
      setArticlePagination,
      setAttachmentPagination,
      setInvoicePagination,
      setLoadings,
      setCommentModal,
      setCurrentAllRequest,
      setCurrentAttachment,
      setCurrentInvoice,
      setCurrentRequestValue,
    },
    func: {
      setFilter,
      fetchAllRequest,
      fetchArticle,
      fetchAttachment,
      fetchInvoice,
    },
  };
  return (
    <ReportContext.Provider value={contextValue}>
      {children}
      <Modal
        open={commentModal.showModal}
        onCancel={() => setCommentModal(defaultCommentModal)}
        footer={null}
        width={720}
        title={
          <div
            className="flex justify-between "
            style={{
              borderBottom: "1px solid var(--shadow, #DADEEC)",
              padding: "8px 0 24px",
            }}
          >
            <div className="w-[122px] flex items-center gap-[8px]">
              <span className="material-symbols-outlined cursor-default text-[24px]">
                mode_comment
              </span>
              Description
            </div>
            <div>{commentModal.title}</div>
            <div className="w-[122px]"></div>
          </div>
        }
      >
        <div
          className={
            "px-[8px] py-[16px] bg-[#F4F7FE] text-[13px] rounded-[12px] mt-[24px]"
          }
        >
          {commentModal.description || "There is no comment"}
        </div>
      </Modal>
    </ReportContext.Provider>
  );
};

export const useInvoiceReport = () => useContext(ReportContext);
