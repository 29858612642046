import {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from "react";
import { ActiveContent } from "../components/AddFlow/model/activeContent";
import { FlowModeType } from "../models/mode";
import { IFlowTabs } from "../models/tabs";
import { useCheckPermission } from "src/hook/checkRoutePermission";
import { AddWorkFlowPath } from "src/components/Guard/permissions";

interface IContext {
  value: {
    state: number;
    mode: FlowModeType;
    activeKeyFlow: string;
  };
  dispatch: {
    setState: Dispatch<SetStateAction<number>>;
    setActiveKeyFlow: Dispatch<SetStateAction<string>>;
  };
  func: {
    OnChangeState: (value: number) => void;
    changeMode: (mode: FlowModeType) => void;
  };
}
const defaultContext: IContext = {
  value: { state: 1, mode: "create", activeKeyFlow: IFlowTabs.addNewFlow },
  dispatch: {
    setState: () => {},
    setActiveKeyFlow: () => {},
  },
  func: {
    OnChangeState: () => {},
    changeMode: () => {},
  },
};
export const FlowCTX = createContext<IContext>(defaultContext);
export const FlowProvider: FC<PropsWithChildren> = ({ children }) => {
  const [state, setState] = useState<number>(ActiveContent.Center);
  const [mode, setMode] = useState<FlowModeType>("create");
  const { checkRoutePermission } = useCheckPermission();
  const [activeKeyFlow, setActiveKeyFlow] = useState<string>(
    checkRoutePermission(AddWorkFlowPath)
      ? IFlowTabs.addNewFlow
      : IFlowTabs.flowTable
  );

  const OnChangeState = (value: number) => {
    setState(value);
    var audio = new Audio("/assets/sound/onClick.m4a");
    audio.play();
  };
  const changeMode = (mode: FlowModeType) => {
    setMode(mode);
  };
  const ctxValue: IContext = {
    value: { state, mode, activeKeyFlow },
    dispatch: { setState, setActiveKeyFlow },
    func: { OnChangeState, changeMode },
  };
  return <FlowCTX.Provider value={ctxValue}>{children}</FlowCTX.Provider>;
};
export const useFlowCTX = () => useContext(FlowCTX);
