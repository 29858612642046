import { FC, useEffect, useState } from "react";
import { Badge, Spin } from "antd";
import { PublicService } from "src/services/Public/Public.service";
import useOwnerStore from "src/store/owner";
import { useLocation } from "react-router-dom";
import { SelectShipStyle } from "./style";

export const SelectShip: FC = () => {
  const [shipsLoading, setShipsLoading] = useState<boolean>(false);
  const { setShips, ships, selectedShip, setSelectedShip } = useOwnerStore();
  const { pathname } = useLocation();
  const getShips = async () => {
    try {
      setShipsLoading(true);
      const { Ship } = new PublicService();
      const res = await Ship();
      if (res && res.status === 200 && res.data) {
        //setSelectedShip(res.data[0]);
        setShips(res.data);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setShipsLoading(false);
    }
  };

  useEffect(() => {
    if (!ships.length) getShips();
    if (selectedShip) {
      const selectedEl = document.getElementById(selectedShip.id.toString());
      selectedEl?.scrollIntoView({ behavior: "auto", inline: "center" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ships, pathname]);

  const shipsUnreadReportCount = () => {
    const sum = ships.reduce((accumulator, ship) => {
      return accumulator + ship.countDailyReport;
    }, 0);
    return sum;
  };

  if (shipsLoading) {
    return (
      <div className="min-h-[90px] flex justify-center items-center overflow-x-auto">
        <Spin />
      </div>
    );
  }
  return (
    <SelectShipStyle id="shipsHolder">
      <Badge count={shipsUnreadReportCount()} color="#FFB800">
        <span
          className={`item ${
            selectedShip && selectedShip?.id ? "" : "selected"
          }`}
          onClick={() => {
            setSelectedShip(undefined);
          }}
          id={"0"}
        >
          All
        </span>
      </Badge>
      {ships.map((ship, index) => (
        <Badge count={ship.countDailyReport} color="#FFB800" key={index}>
          <span
            className={`item ${selectedShip?.id === ship.id ? "selected" : ""}`}
            onClick={() => {
              setSelectedShip(ship);
              //navigate(ROUTES.home);
            }}
            id={ship.id.toString()}
            key={index}
          >
            {ship.name}
          </span>
        </Badge>
      ))}
    </SelectShipStyle>
  );
};
