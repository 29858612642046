import { FC, Suspense } from "react";
import { FrontAccess } from "src/modules/frontAccess";
import { FrontAccessProvider } from "src/modules/frontAccess/context";
import { PreLoading } from "src/routes/Loading";

export const FrontAccessPage: FC = () => {
  return (
    <Suspense fallback={<PreLoading />}>
      <FrontAccessProvider>
        <FrontAccess />
      </FrontAccessProvider>
    </Suspense>
  );
};
