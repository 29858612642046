import { styled } from "styled-components";

export const TabStyle = styled.div`
  .ant-tabs-tab {
    margin-left: 12px !important;
    color: var(--text-2, #a3aed0);
    font-size: 12px;
    font-weight: 500;
  }
`;
